import React, { Fragment, useState, useContext } from 'react';

import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import { withTheme } from '@material-ui/core/styles';

import imageCompression from 'browser-image-compression';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

const MAX_IMAGE_SIZE = 8 * 1024 * 1024; // 8 MB

const options = {
    maxSizeMB: 8, // 8 MB
    useWebWorker: true
};

const logoPlaceHolders = lang => [
    {
        text: isEXPRegion() ? loc('dashboardCharityLogo2EXP', lang) : loc('dashboardCharityLogo2', lang),
        color: colors.grey[400]
    },
    {
        text:
            'Your logo was not uploaded. Please make sure that it is a valid file type with a size of 8MB or smaller.',
        color: colors.red[500],
        icon: 'cancel'
    },
    {
        text:
            'Your logo was succesfully uploaded (Preview unavailable for this file type). Press the submit button to continue!',
        color: colors.green[500],
        icon: 'check_circle'
    }
];
const genericPlaceHolders = lang => [
    { text: 'Upload your image', color: colors.grey[400] },
    {
        text:
            'Your image was not uploaded. Please make sure that it is a valid file type with a size of 8MB or smaller.',
        color: colors.red[500],
        icon: 'cancel'
    },
    {
        text:
            'Your image was succesfully uploaded (Preview unavailable for this file type). Press the submit button to continue!',
        color: colors.green[500],
        icon: 'check_circle'
    }
];

function HighResImageWidget(props) {
    const { theme, handleUpload, handleUploadDimensions, imageMode = false } = props;

    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [placeHolderIndex, setPlaceHolderIndex] = useState(0);

    const { lang } = useContext(LocalizationContext);
    let placeHolders = imageMode ? genericPlaceHolders(lang) : logoPlaceHolders(lang);
    const handlePhotoUpload = async e => {
        // Reset upload + dimensions (image, height, width)
        handleUpload(null, 0, 0);
        setImagePreviewUrl(null);
        setPlaceHolderIndex(0);

        const reader = new FileReader();

        const file = e.target.files[0];
        const fileSize = _.get(file, 'size', MAX_IMAGE_SIZE);
        const fileType = _.get(file, 'type', '');

        // If the user upload an image file type
        if (fileType.includes('image/')) {
            const imageFile = await imageCompression(file, options);

            reader.onload = () => {
                const image = new Image();
                image.src = reader.result;
                image.onload = () => {
                    handleUploadDimensions(image.height, image.width);
                };
            };

            reader.onloadend = () => {
                handleUpload(imageFile);
                setImagePreviewUrl(reader.result);
            };

            if (!_.isNil(imageFile)) {
                reader.readAsDataURL(imageFile);
            }

            return;
        }

        if (fileSize >= MAX_IMAGE_SIZE) {
            setPlaceHolderIndex(1);
        } else {
            handleUpload(file, 0, 0);
            setPlaceHolderIndex(2);
        }
    };

    return (
        <Fragment>
            <div
                style={{
                    margin: 'auto',
                    border: '1px solid rgba(0, 0, 0, 0.26)',
                    borderRadius: theme.spacing.unit / 2,
                    maxWidth: 420,
                    minHeight: 320,
                    textAlign: 'center',
                    backgroundColor: colors.grey[50],
                    backgroundImage: `url(${imagePreviewUrl})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                {_.isNil(imagePreviewUrl) && (
                    <div
                        style={{
                            height: 320,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {!_.isNil(placeHolders[placeHolderIndex].icon) && (
                            <Icon fontSize="large" style={{ color: placeHolders[placeHolderIndex].color }}>
                                {placeHolders[placeHolderIndex].icon}
                            </Icon>
                        )}
                        <Typography
                            style={{
                                color: placeHolders[placeHolderIndex].color,
                                margin: theme.spacing.unit * 2
                            }}
                            variant="subtitle2"
                        >
                            {placeHolders[placeHolderIndex].text}
                        </Typography>
                    </div>
                )}
            </div>
            <Grid
                container
                spacing={theme.spacing.unit}
                style={{ margin: '0 auto', marginTop: theme.spacing.unit, maxWidth: 420 + theme.spacing.unit }}
            >
                <Grid item xs={12}>
                    <FormControl fullWidth data-cy="upload-photo-input">
                        <input
                            accept={'image/*, application/pdf, application/postscript'}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple={false}
                            type="file"
                            onChange={handlePhotoUpload}
                        />
                        <label htmlFor="raised-button-file">
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                component="span"
                                style={{ width: '100%', marginTop: theme.spacing.unit }}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>cloud_upload</Icon>{' '}
                                {loc('dashboardCharityLogo4', lang)}
                            </Button>
                        </label>
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default withTheme()(HighResImageWidget);
