import React, { useContext, useState } from 'react';

import { deviceHelper } from 'utils/misc';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { Paper, Button } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import QuickRegisterFormDialog from 'components/Dialogs/QuickRegister';
import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';

import _ from 'lodash';

import { grey } from '@material-ui/core/colors';

import { _user } from 'std';

import { Icon, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';

import useQuickRegister from 'containers/Operators/QuickRegistration/hooks/useQuickRegister';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';
import { useEffect } from 'react';
// Manually set form and form errors shapes

const headers = [
    { name: 'Actions' },
    { name: 'Name^' },
    { name: 'ID' },
    { name: 'Email^' },
    { name: 'Phone' },
    { name: 'Address^' },
    { name: 'Next Pickup' },
    { name: 'Day of Week' },
    { name: 'Recurring Fees' }, // Bins
    { name: 'Type' },
    { name: 'Business Name' }
];

function QuickRegistration({ theme, google, collectors, initialCollector, promosEnabled }) {
    const onSnackbar = useContext(SnackbarContext);
    const history = useHistory();
    const http = useContext(HttpContext);

    const { defaultCoordinates } = useDefaultCoordinates(http);

    const { showAll, collector, handleChange: handleChangeCollectorFilter, collectorObj } = useCollectorDropDown({
        collectors,
        receivers: [],
        saveStateInURL: true,
        showAll: false,
        initialCollector
    });

    const [queryObj, setQueryObj] = useState({ accountType: 'Customer', collector: collector });
    const [allowSpecialCharactersInEmail, setAllowSpecialCharactersInEmail] = useState(true);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/users',
        key: 'customers',
        headers: headers.map(h => h.name),
        queryObj
    });

    const { quickRegisterFormOpen, setQuickRegisterFormOpen, handleRegisterUser } = useQuickRegister({
        http,
        history,
        collector,
        reloadUsers: getData,
        onSnackbar
    });

    // TODO: not a great way to do this, but good enough for now
    const handleChangeCollectorFilterAndRefresh = e => {
        handleChangeCollectorFilter(e);
        setQueryObj({ accountType: 'Customer', collector: e.target.value });
    };

    const handleView = customer_id => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/customers/${customer_id}`);
        } else {
            window.open(`/customers/${customer_id}`, '_blank');
        }
    };

    const getRegistrationConfig = async () => {
        const resRegistrationConfig = await http.getJSON('/registrationConfig', false, true);

        if (resRegistrationConfig.ok) {
            setAllowSpecialCharactersInEmail(_.get(resRegistrationConfig, 'data.allowSpecialCharactersInEmail', true));
        }
    };
    useEffect(() => {
        getRegistrationConfig();
    }, []);

    const cellStyle = { whiteSpace: 'nowrap', padding: '8px 10px' };

    return (
        <>
            <Paper
                style={{
                    margin: theme.spacing.unit * 2,
                    marginBottom: theme.spacing.unit,
                    padding: theme.spacing.unit * 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <CollectorsSelectDropdown
                    showAll={showAll}
                    id="collector-dropdown"
                    value={collector}
                    collectors={collectors}
                    cypress_id="trip-list-collector-dropdown"
                    onChange={handleChangeCollectorFilterAndRefresh}
                />
                <Button variant="contained" color="primary" size="large" onClick={() => setQuickRegisterFormOpen(true)}>
                    Register Customer
                </Button>
            </Paper>

            <Table
                title="Customers"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        const { _id, uniqueID, email, phone, location, recurringFees, nextPickup } = user;

                        const nextPickupDate = _.get(nextPickup, 'date', null);

                        // const bins = (recurringFees || []).reduce((numberOfBins, fee) => {
                        //     if (fee.description === 'Bin fee') {
                        //         return (numberOfBins += 1);
                        //     } else {
                        //         return numberOfBins;
                        //     }
                        // }, 0);

                        return (
                            <TableRow key={_id}>
                                {filteredHeaders.includes('Actions') && (
                                    <TableCell style={cellStyle}>
                                        <>
                                            <IconButton onClick={() => handleView(_id)}>
                                                <Icon>visibility</Icon>
                                            </IconButton>
                                        </>
                                    </TableCell>
                                )}
                                {filteredHeaders.includes('Name^') && (
                                    <TableCell style={cellStyle}>{_user.getNameFull(user)}</TableCell>
                                )}

                                {filteredHeaders.includes('ID') && <TableCell style={cellStyle}>{uniqueID}</TableCell>}

                                {filteredHeaders.includes('Email^') && <TableCell style={cellStyle}>{email}</TableCell>}

                                {filteredHeaders.includes('Phone') && (
                                    <TableCell style={cellStyle}>
                                        {phone || <span style={{ color: grey['500'] }}>N/A</span>}
                                    </TableCell>
                                )}

                                {filteredHeaders.includes('Address^') && (
                                    <TableCell
                                        style={{
                                            ...cellStyle,
                                            maxWidth: 250,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <Tooltip title={_.get(location, 'description', null)} interactive>
                                            <span>{_.get(location, 'description', null)}</span>
                                        </Tooltip>
                                    </TableCell>
                                )}

                                {filteredHeaders.includes('Next Pickup') && (
                                    <TableCell style={cellStyle}>
                                        {!_.isNil(nextPickupDate)
                                            ? moment(nextPickupDate).format('MMM DD')
                                            : 'No pickup set!'}
                                    </TableCell>
                                )}

                                {filteredHeaders.includes('Day of Week') && (
                                    <TableCell style={cellStyle}>
                                        {!_.isNil(nextPickupDate) ? moment(nextPickupDate).format('ddd') : null}
                                    </TableCell>
                                )}
                                {filteredHeaders.includes('Recurring Fees') && (
                                    <TableCell style={cellStyle}>{recurringFees}</TableCell>
                                )}
                                {filteredHeaders.includes('Type') && (
                                    <TableCell style={cellStyle}>{_.get(nextPickup, 'pickupType', null)}</TableCell>
                                )}

                                {filteredHeaders.includes('Business Name') && (
                                    <TableCell style={cellStyle}>{_.get(location, 'businessName', null)}</TableCell>
                                )}
                            </TableRow>
                        );
                    })}
            </Table>

            <QuickRegisterFormDialog
                open={quickRegisterFormOpen}
                google={google}
                collectorObj={collectorObj}
                onClose={() => setQuickRegisterFormOpen(false)}
                onSubmitForm={handleRegisterUser}
                defaultCoordinates={defaultCoordinates}
                promosEnabled={promosEnabled}
                allowSpecialCharactersInEmail={allowSpecialCharactersInEmail}
            />
        </>
    );
}

export default withTheme()(QuickRegistration);
