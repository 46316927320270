import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment-timezone';

import * as terms from 'localizations/terms';
import { validate, initFormErrors, containsErrors } from 'utils/validate';

import LogoUpload from 'components/LogoUpload/LogoUpload';
import GMapsAutocomplete from 'components/GMapsAutocomplete';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import CharityManageAccessDialog from '../../../containers/Operators/Charities/Dialogs/CharityManageAccessDialog';

import { getEmptyLocation, createLocation } from 'helpers/locationHelper';
import { maxFileSize, maxFileSizeMB, maxCharityImageWidthAndHeight } from 'constants.js';

import * as colors from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { withTheme } from '@material-ui/core/styles';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import useGetJSON from 'utils/hooks/useGetJSON';
import GoogleContext from 'utils/contexts/GoogleContext';
import { getPlaceDetails } from 'utils/mapfunc';
import { getNameOfClosestRegion } from 'utils/latlngFunctions';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { Avatar, List, ListItem, ListItemText } from '@material-ui/core';
import { getUserAccountStatus, isEXPRegion } from 'utils/misc';
import imageCompression from 'browser-image-compression';

const formShape = {
    charityRegistrationNumber: '',
    name: '',
    website: '',
    mission: '',
    redemptionEmail: '',
    location: getEmptyLocation(),
    hasCloseDate: false,
    closeDate: moment().add(1, 'year')
};

const errors = {
    charityRegistrationNumber: [process.env.REACT_APP_CHARITY_NUMBER_VALIDATION_RULE],
    name: ['required', 'max-length:50'],
    website: ['url'],
    mission: ['required', 'max-length:300'],
    redemptionEmail: ['email'],
    location: {
        description: ['required'],
        lat: ['required', 'latCoordinate'],
        lng: ['required', 'lngCoordinate'],
        place_id: ['required']
    }
};
const TODAY = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);

const CURRENT_YEAR = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .year();

const CURRENT_YEAR_END = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .endOf('year');

const trackedFields = {};

function CharityProfile(props) {
    const { charity, reloadCharity, customer, reloadCustomer, history, google, regions, adminView, theme } = props;
    const { lang } = useContext(LocalizationContext);

    const {
        loading: defaultMinTaxReceiptAmountLoading,
        error: defaultMinTaxReceiptAmountError,
        data: { defaultMinTaxReceiptAmount = 0 }
    } = useGetJSON(`/system/minTaxReceiptAmount`);

    const [saving, setSaving] = useState(false);
    const [savingTaxReceiptSettings, setSavingTaxReceiptSettings] = useState(false);
    const [form, setForm] = useState(_.cloneDeep(formShape));
    const [formErrors, setFormErrors] = useState(initFormErrors(formShape));
    const [allowSpecialCharactersInEmail, setAllowSpecialCharactersInEmail] = useState(true);
    const [file, setFile] = useState(undefined);
    const [taxReceiptsIssued, setTaxReceiptIssued] = useState(false);
    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [suggestedCoordinates, setSuggestedCoordinates] = useState(null);

    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [helpDialogTitle, setHelpDialogTitle] = useState('');
    const [helpDialogText, setHelpDialogText] = useState('');

    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);
    const { placesService } = useContext(GoogleContext);
    const [charityEndOfTaxYear, setCharityEndOfTaxYear] = useState(charity.taxReceiptsEndOfYear);

    const [manageAccessDialog, setManageAccessDialog] = useState(false);

    let endOfTaxYear = moment(charityEndOfTaxYear || CURRENT_YEAR_END)
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .set('year', CURRENT_YEAR)
        .endOf('day');

    // If the last tax periods creep into this year but has already passed, set end of current tax year to next year
    if (moment(endOfTaxYear).isBefore(TODAY)) {
        endOfTaxYear = moment(endOfTaxYear).set('year', CURRENT_YEAR + 1);
    }

    const lastTaxableYear = moment(endOfTaxYear).year();

    const [endDate, setEndDate] = useState(endOfTaxYear);
    const [minTaxReceiptAmount, setMinTaxReceiptAmount] = useState(
        _.get(charity, 'minTaxReceiptAmount', defaultMinTaxReceiptAmount) / 100
    );

    const validateForm = (keys, hasTaxReceiptsIssued = taxReceiptsIssued) => {
        const errorsUpdated = _.cloneDeep(errors);
        if (!allowSpecialCharactersInEmail) {
            errorsUpdated.redemptionEmail.push('noSpecialCharacters');
        }
        if (_.isEmpty(keys)) {
            keys = _.keys(errorsUpdated);
        }

        let formErrorsUpdated = _.cloneDeep(formErrors);

        if (!hasTaxReceiptsIssued) {
            keys = _.without(keys, 'charityRegistrationNumber');
            formErrorsUpdated['charityRegistrationNumber'] = { fail: false, reason: null };
        }

        let isGroupAcct = !hasTaxReceiptsIssued;

        for (let key of keys) {
            if (isEXPRegion() && isGroupAcct && ['website', 'mission'].includes(key) && _.isEmpty(form[key].trim())) {
                // Not required on Express GROUP accounts
                formErrorsUpdated[key] = { fail: false, reason: null };
                continue;
            }
            if (_.isPlainObject(form[key])) {
                for (let inner_key in form[key]) {
                    if ((inner_key === 'description' || inner_key === 'place_id') && form.location.isCustomGPS) {
                        formErrorsUpdated[key][inner_key] = { fail: false, reason: null };
                    } else {
                        formErrorsUpdated[key][inner_key] = validate(
                            errorsUpdated[key][inner_key],
                            form[key][inner_key],
                            lang
                        );
                    }
                }
            } else {
                formErrorsUpdated[key] = validate(errorsUpdated[key], form[key], lang);
            }
        }

        setFormErrors(formErrorsUpdated);
        return formErrorsUpdated;
    };

    useEffect(() => {
        const loadData = async () => {
            const { name, logo, website, mission, location, charityRegistrationNumber, redemptionEmail } = charity;

            setLoading(true);
            await reloadCharity();
            const resRegistrationConfig = await http.getJSON('/registrationConfig', false, true);
            setLoading(false);
            if (resRegistrationConfig.ok) {
                setAllowSpecialCharactersInEmail(
                    _.get(resRegistrationConfig, 'data.allowSpecialCharactersInEmail', true)
                );
            }

            let formUpdated = {
                name,
                website,
                logo,
                mission,
                location,
                charityRegistrationNumber: charityRegistrationNumber || '',
                redemptionEmail,
                hasCloseDate: _.get(charity, 'hasCloseDate', false),
                closeDate: moment(_.get(charity, 'closeDate', moment().add(1, 'year')))
            };

            setTaxReceiptIssued(!_.isNil(charityRegistrationNumber));

            // Set fields that will trigger re-approval warning if changed:
            trackedFields.name = name;
            trackedFields.redemptionEmail = redemptionEmail;
            //trackedFields.taxReceiptsIssued = taxReceiptsIssued;
            trackedFields.charityRegistrationNumber = charityRegistrationNumber;

            if (
                _.isEmpty(location.description) &&
                !_.get(location, 'isCustomGPS', false) &&
                !_.isNaN(location.lat) &&
                !_.isNaN(location.lng)
            ) {
                formUpdated.location.isCustomGPS = true;
            }
            setForm(formUpdated);
        };

        loadData();
    }, []);

    useEffect(() => {
        validateForm([], taxReceiptsIssued);
    }, [form, taxReceiptsIssued]);

    useEffect(() => {
        setMinTaxReceiptAmount(_.get(charity, 'minTaxReceiptAmount', defaultMinTaxReceiptAmount) / 100);
    }, [defaultMinTaxReceiptAmount]);

    const handleHelpDialog = (open, title, text) => {
        setHelpDialogTitle(title);
        setHelpDialogText(text);
        setHelpDialogOpen(open);
    };

    const handleToggleTaxReceiptsIssued = () => {
        setTaxReceiptIssued(!taxReceiptsIssued);
    };

    const handleInfoChange = e => {
        const { name, value, checked } = e.target;
        if (name === 'hasCloseDate') {
            if (!moment(form.closeDate).isValid()) {
                setForm({ ...form, [name]: checked, closeDate: moment().add(1, 'year') });
            } else {
                setForm({ ...form, [name]: checked });
            }
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleInitInfoSave = () => {
        if (adminView) {
            return handleInfoSave();
        } else {
            let formAdj =
                !taxReceiptsIssued || _.isEmpty(form.charityRegistrationNumber)
                    ? _.set(form, 'charityRegistrationNumber', null)
                    : form;

            for (let prop in trackedFields) {
                if (trackedFields[prop] !== formAdj[prop]) {
                    return handleWarningDialog(true)();
                }
            }

            return handleInfoSave();
        }
    };

    const handleTaxReceiptSave = async () => {
        if (minTaxReceiptAmount < 0) {
            onSnackbar(loc('charitySnackbar14', lang), 'error');
            return;
        }

        setSavingTaxReceiptSettings(true);
        let resEndDate = await http.post(`/charities/${charity._id}/setTaxReceiptsEndOfYear`, { date: endDate });
        if (resEndDate.ok) {
            onSnackbar(loc('dashboardSetting30', lang));
            history.push(`/customers/${customer._id}/${charity._id}/charity`);
        } else {
            onSnackbar(resEndDate.errorMessage, resEndDate.variant);
            return;
        }
        let resMinDonation = await http.post(`/charities/${charity._id}/setMinimumTaxReceiptAmount`, {
            amount: minTaxReceiptAmount * 100
        });
        if (resMinDonation.ok) {
            onSnackbar(loc('dashboardSetting30', lang));
            history.push(`/customers/${customer._id}/${charity._id}/charity`);
        } else {
            onSnackbar(resMinDonation.errorMessage, resMinDonation.variant);
            return;
        }

        await reloadCustomer();
        await reloadCharity();
        setSavingTaxReceiptSettings(false);
    };

    const handleWarningDialog = state => () => {
        setWarningDialogOpen(state);
    };

    const handleDrop = async e => {
        const options = {
            maxSizeMB: maxFileSizeMB,
            maxWidthOrHeight: maxCharityImageWidthAndHeight,
            fileType: 'image/png', //converts all images to png
            useWebWorker: true
        };
        const img = e.target.files[0];

        let validImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/heif', 'image/heic', 'image/gif'];
        let validImageExtensions = ['.jpeg', '.jpg', '.png', '.webp', '.heif', '.heic', '.gif'];
        let isValidExt = validImageExtensions.some(
            type => _.endsWith(img.name, type) || _.endsWith(img.name, _.toUpper(type))
        );

        if (_.includes(validImageTypes, _.get(img, 'type')) && isValidExt) {
            const imageFile = await imageCompression(img, options);
            setFile(imageFile);
        } else {
            onSnackbar(loc('invalidImageFormat', lang), 'error');
        }
    };

    const handleCancel = () => {
        history.push(`/customers/${customer._id}/${charity._id}/charity`);
    };

    const handleInfoSave = async () => {
        let charityErrorsLatest = validateForm();
        if (containsErrors(charityErrorsLatest)) {
            return;
        }

        let formAdjusted = _.cloneDeep(form);

        if (!taxReceiptsIssued || _.isEmpty(formAdjusted.charityRegistrationNumber)) {
            formAdjusted.charityRegistrationNumber = null;
        }

        formAdjusted.location.closestRegion = getNameOfClosestRegion(formAdjusted.location, regions);

        setSaving(true);

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        formData.append('logo', file);
        formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body

        let res = await http.post(`/charities/${charity._id}/update`, formData, true, true);
        if (res.ok) {
            onSnackbar(
                loc('dashboardSetting29', lang, {
                    organization: terms.ORGANIZATION_NAME.toLowerCase()
                })
            );
            history.push(`/customers/${customer._id}/${charity._id}/charity`);
        } else {
            onSnackbar(res.errorMessage, res.variant);
        }

        await reloadCustomer();
        await reloadCharity();
        setSaving(false);
    };

    const handleAddressChange = () => {
        setSuggestedCoordinates(null);
        setForm({ ...form, location: getEmptyLocation() });
    };

    const googlePlace = async () => {
        const { location } = form;
        if (_.get(location, 'place_id', undefined)) {
            return await getPlaceDetails(
                placesService,
                location.place_id,
                google.maps.places.PlacesServiceStatus.OK,
                http
            );
        }
        return undefined;
    };

    const toggleGPSOverride = async () => {
        const { location } = form;
        let locationUpdated = _.cloneDeep(location);

        if (_.get(location, 'isCustomGPS', false) && !_.isNil(suggestedCoordinates)) {
            locationUpdated.lat = _.get(suggestedCoordinates, 'lat', '');
            locationUpdated.lng = _.get(suggestedCoordinates, 'lng', '');
        } else if (_.get(location, 'isCustomGPS', false) && _.isNil(suggestedCoordinates)) {
            const place = await googlePlace();
            if (place) {
                locationUpdated.lat = place.geometry.location.lat();
                locationUpdated.lng = place.geometry.location.lng();
                setSuggestedCoordinates({ lat: locationUpdated.lat, lng: locationUpdated.lng });
            }
        }

        locationUpdated.isCustomGPS = !_.get(location, 'isCustomGPS', false);
        setForm({ ...form, location: locationUpdated });
    };

    const handleCoordinateChange = e => {
        let path = e.target.name;
        let value = e.target.value;

        const locationUpdated = { ...form.location, [path]: value };
        setForm({ ...form, location: locationUpdated });
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        let locationUpdated = createLocation(
            place,
            suggestion.description,
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        setSuggestedCoordinates({ lat: locationUpdated.lat, lng: locationUpdated.lng });
        setForm({ ...form, location: locationUpdated });
    };

    const widgetStyle = {
        width: `calc(100% - ${theme.spacing.unit * 4}px)`,
        margin: theme.spacing.unit * 2,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
    };

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    return (
        <React.Fragment>
            {charity.administrators && !_.isEmpty(charity.administrators) && (
                <Paper
                    style={{
                        width: 'calc(100% - 32px)',
                        margin: 16,
                        padding: '16px 24px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: theme.spacing.unit,
                            alignItems: 'center'
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginBottom: theme.spacing.unit
                                }}
                            >
                                <Icon
                                    style={{
                                        color: 'red',
                                        marginRight: theme.spacing.unit,
                                        marginTop: theme.spacing.unit / 2,
                                        fontSize: 25
                                    }}
                                >
                                    error
                                </Icon>
                                <Typography variant="h6">{loc('dashboardSetting34', lang)}</Typography>
                            </div>
                            <Typography variant="body2">{loc('accountSettings27', lang)}</Typography>
                        </div>
                        <div
                            style={{
                                borderLeft: '1px solid ' + theme.palette.text.hint,
                                margingLeft: theme.spacing.unit,
                                paddingLeft: theme.spacing.unit
                            }}
                        >
                            <IconButton onClick={() => setManageAccessDialog(!manageAccessDialog)}>
                                <Icon>people_alt</Icon>
                            </IconButton>
                        </div>
                    </div>
                    <List
                        style={{
                            margin: 0
                        }}
                    >
                        {charity.administrators.map((user, index) => {
                            return (
                                <ListItem
                                    style={{ paddingLeft: 0, paddingBottom: 0, opacity: user.disabled ? 0.7 : 1 }}
                                >
                                    <Avatar
                                        style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white'
                                        }}
                                    >
                                        {_.get(user, 'name.first[0]', '?')}
                                    </Avatar>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 'inherit'
                                                }}
                                            >
                                                {`${_.get(user, 'name.first', 'N/A')} ${_.get(user, 'name.last', '')}`}
                                            </span>
                                        }
                                        style={{
                                            whiteSpace: 'normal',
                                            paddingRight: theme.spacing.unit * 3,
                                            position: 'relative'
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Paper>
            )}

            <Paper style={widgetStyle}>
                {loading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Typography variant="h6">
                            {terms.ORGANIZATION_NAME}
                            {charity.closed ? ` (${loc('closed', lang)})` : ''}
                        </Typography>
                        <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }} />
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                data-cy="charity-profile-name"
                                value={form.name}
                                helperText={formErrors['name'].reason}
                                error={formErrors['name'].fail}
                                label={loc('dashboardSetting2', lang)}
                                name="name"
                                fullWidth
                                onChange={handleInfoChange}
                                disabled={charity.closed}
                            />
                        </FormControl>
                        <FormGroup row style={{}}>
                            <FormControlLabel
                                disabled={charity.closed}
                                control={
                                    <Switch checked={taxReceiptsIssued} onChange={handleToggleTaxReceiptsIssued} />
                                }
                                label={loc('dashboardSetting3', lang, {
                                    organization: terms.ORGANIZATION_NAME.toLowerCase()
                                })}
                            />
                        </FormGroup>
                        <Collapse in={taxReceiptsIssued}>
                            <FormControl fullWidth style={formControlStyle}>
                                <TextField
                                    disabled={charity.closed}
                                    data-cy="charity-profile-crn"
                                    value={form.charityRegistrationNumber}
                                    helperText={formErrors.charityRegistrationNumber.reason}
                                    error={formErrors.charityRegistrationNumber.fail}
                                    label={terms.CHARITY_NUMBER_NAME}
                                    name="charityRegistrationNumber"
                                    onChange={handleInfoChange}
                                />
                            </FormControl>
                        </Collapse>
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                disabled={charity.closed}
                                data-cy="charity-profile-email"
                                value={form.redemptionEmail}
                                helperText={formErrors['redemptionEmail'].reason}
                                error={formErrors['redemptionEmail'].fail}
                                label={loc('dashboardSetting5', lang, { organization: terms.ORGANIZATION_NAME })}
                                name="redemptionEmail"
                                fullWidth
                                onChange={handleInfoChange}
                            />
                        </FormControl>
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                disabled={charity.closed}
                                data-cy="charity-profile-website"
                                value={form.website}
                                helperText={formErrors['website'].reason}
                                error={formErrors['website'].fail}
                                label={loc('dashboardCharityInfo2', lang)}
                                name="website"
                                fullWidth
                                style={{ marginTop: theme.spacing.unit }}
                                onChange={handleInfoChange}
                            />
                        </FormControl>
                        <FormControl fullWidth style={formControlStyle}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={handleInfoChange}
                                        value={form.hasCloseDate}
                                        checked={form.hasCloseDate}
                                        name="hasCloseDate"
                                    />
                                }
                                label={loc('registrationOrg20', lang)}
                            />
                        </FormControl>
                        <Collapse in={form.hasCloseDate}>
                            <FormControl fullWidth style={formControlStyle}>
                                <DatePicker
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    value={form.closeDate}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    name="closeDate"
                                    onChange={date => setForm({ ...form, closeDate: date.endOf('day') })}
                                    disablePast
                                    minDate={moment().tz(process.env.REACT_APP_REGION_TIMEZONE)}
                                />
                            </FormControl>
                        </Collapse>
                        <GMapsAutocomplete
                            disabled={charity.closed}
                            data-cy="registration-address-input"
                            google={google}
                            location={form.location}
                            label={loc('address', lang)}
                            placeholder={`Enter ${terms.ORGANIZATION_NAME.toLowerCase()} address`}
                            selectedValue={form.location.description}
                            types={['address']}
                            style={{ ...formControlStyle }}
                            variant="standard"
                            error={formErrors.location['description']}
                            onChange={handleAddressChange}
                            onSuggestionSelected={handleSuggestionSelected}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography
                                variant="caption"
                                style={{ color: charity.closed ? theme.palette.text.disabled : 'auto' }}
                            >
                                {loc('dashboardSetting8', lang)}
                            </Typography>
                            <Switch
                                disabled={charity.closed}
                                checked={_.get(form.location, 'isCustomGPS', false)}
                                onChange={toggleGPSOverride}
                            />
                        </div>
                        {_.get(form.location, 'isCustomGPS', false) && (
                            <>
                                <TextField
                                    disabled={charity.closed}
                                    fullWidth
                                    style={formControlStyle}
                                    name="lat"
                                    label={loc('latitude', lang)}
                                    value={_.get(form, 'location.lat', '')}
                                    onChange={handleCoordinateChange}
                                    helperText={formErrors.location.lat.reason}
                                    error={formErrors.location.lat.fail}
                                />
                                <TextField
                                    disabled={charity.closed}
                                    fullWidth
                                    style={formControlStyle}
                                    name="lng"
                                    label={loc('longitude', lang)}
                                    value={_.get(form, 'location.lng', '')}
                                    onChange={handleCoordinateChange}
                                    helperText={formErrors.location.lng.reason}
                                    error={formErrors.location.lng.fail}
                                />
                                <Typography variant="caption" style={{ padding: theme.spacing.unit }}>
                                    {loc('dashboardSetting9', lang)}
                                </Typography>
                            </>
                        )}
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                            <LogoUpload
                                disabled={charity.closed}
                                theme={theme}
                                file={file}
                                handleDrop={handleDrop}
                                name={terms.ORGANIZATION_NAME}
                                lang={lang}
                            />
                        </FormControl>
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                disabled={charity.closed}
                                data-cy="charity-profile-mission"
                                value={form.mission}
                                helperText={formErrors['mission'].reason}
                                error={formErrors['mission'].fail}
                                label={loc('dashboardSetting13', lang)}
                                name="mission"
                                multiline
                                fullWidth
                                style={{ marginTop: theme.spacing.unit }}
                                onChange={handleInfoChange}
                            />
                        </FormControl>
                        <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                            <Button color="primary" disabled={saving} onClick={handleCancel}>
                                {loc('cancel', lang)}
                            </Button>
                            <Button
                                data-cy="charity-profile-save-button"
                                disabled={containsErrors(formErrors) || saving}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: theme.spacing.unit * 2 }}
                                onClick={handleInitInfoSave}
                            >
                                {loc('save', lang)}{' '}
                                <CircularProgress
                                    size={22}
                                    thickness={4.8}
                                    style={{
                                        display: saving ? '' : 'none',
                                        marginLeft: 4,
                                        color: 'white',
                                        verticalAlign: 'bottom'
                                    }}
                                />
                            </Button>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </>
                )}
            </Paper>

            {!loading && charity.taxReceiptsIssued && (
                <Paper style={widgetStyle}>
                    <Typography variant="h6">{loc('dashboardSetting14', lang)}</Typography>
                    <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }} />
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                name="minTaxReceiptAmount"
                                label={loc('dashboardSetting15', lang)}
                                type="number"
                                value={minTaxReceiptAmount}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={e => setMinTaxReceiptAmount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ paddingLeft: theme.spacing.unit / 2 }}>
                            <IconButton
                                style={formControlStyle}
                                onClick={() =>
                                    handleHelpDialog(
                                        true,
                                        loc('dashboardSetting15', lang),
                                        loc('dashboardSetting16', lang)
                                    )
                                }
                            >
                                <Icon>help</Icon>
                            </IconButton>
                        </Grid>

                        <Grid item xs={10} style={{ marginTop: theme.spacing.unit * 2 }}>
                            <FormControl fullWidth>
                                <DatePicker
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    data-cy="charity-profile-tax-receipt-date-picker" // TODO: fill it out
                                    label={loc('dashboardSetting17', lang)}
                                    name="taxReceiptsEndOfYear"
                                    format="MMMM Do, YYYY"
                                    value={endDate}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{ margin: theme.spacing.unit * 2 }}
                                    onChange={date => setEndDate(date.endOf('day'))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{ marginTop: theme.spacing.unit * 2, paddingLeft: theme.spacing.unit / 2 }}
                        >
                            <IconButton
                                onClick={() =>
                                    handleHelpDialog(
                                        true,
                                        loc('dashboardSetting17', lang),
                                        loc('dashboardSetting18', lang)
                                    )
                                }
                            >
                                <Icon>help</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                        <Button color="primary" disabled={savingTaxReceiptSettings} onClick={handleCancel}>
                            {loc('cancel', lang)}
                        </Button>
                        <Button
                            data-cy="charity-profile-tax-teceipt-save-button"
                            disabled={savingTaxReceiptSettings}
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: theme.spacing.unit * 2 }}
                            onClick={handleTaxReceiptSave}
                        >
                            {loc('save', lang)}{' '}
                            <CircularProgress
                                size={22}
                                thickness={4.8}
                                style={{
                                    display: savingTaxReceiptSettings ? '' : 'none',
                                    marginLeft: 4,
                                    color: 'white',
                                    verticalAlign: 'bottom'
                                }}
                            />
                        </Button>
                    </div>
                    <div style={{ clear: 'both' }} />
                </Paper>
            )}

            <Dialog open={warningDialogOpen} fullWidth onClose={handleWarningDialog(false)}>
                <DialogTitle>
                    <Icon style={{ position: 'relative', marginRight: 4, left: 2, top: 4, color: colors.amber[500] }}>
                        warning
                    </Icon>{' '}
                    {loc('dashboardSetting27', lang)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {loc('dashboardSetting28', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleWarningDialog(false)}>
                        {loc('no', lang)}
                    </Button>
                    <Button color="primary" onClick={handleInfoSave} data-cy="charity-profile-confirm-re-approval">
                        {loc('yes', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={helpDialogOpen} fullWidth onClose={() => handleHelpDialog(false, '', '')}>
                <DialogTitle>{helpDialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{helpDialogText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleHelpDialog(false, '', '')}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            {manageAccessDialog && (
                <CharityManageAccessDialog
                    open={Boolean(manageAccessDialog)}
                    theme={theme}
                    http={http}
                    customer={customer}
                    reloadMethod={reloadCharity}
                    selectedCharityID={charity._id}
                    charityManagers={charity.administrators}
                    setManageAccessDialog={setManageAccessDialog}
                />
            )}
        </React.Fragment>
    );
}
export default withTheme()(withRouter(CharityProfile));
