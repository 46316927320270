import React, { useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { withTheme, Typography, CircularProgress, Grid, Avatar, Icon, ListItemText } from '@material-ui/core';

import { List, ListItem } from '@material-ui/core';

import { loc } from '../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function DriverIncidentsWidget(props) {
    const { lang } = useContext(LocalizationContext);

    const { theme, loading, height, noPadding, lateDriverTrips } = props;

    return loading ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 200,
                height: height ? height : undefined,
                padding: theme.spacing.unit
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <div
            style={{
                width: '100%',
                maxHeight: 200,
                height: height ? height : undefined,
                overflowY: 'auto'
            }}
        >
            <List dense>
                {_.isEmpty(lateDriverTrips) ? (
                    <ListItem>
                        <Typography>{loc('noDriverIncidents', lang)}</Typography>
                    </ListItem>
                ) : (
                    lateDriverTrips.map((trip, index) => {
                        return <DriverIncident index={index} trip={trip} lang={lang} theme={theme} />;
                    })
                )}
            </List>
        </div>
    );
}

export default withTheme()(DriverIncidentsWidget);

// Currently only incident is late driver
function DriverIncident({ trip, index, lang, theme }) {
    const driverName = `${_.get(trip, 'transporter.name.first', 'Unknown')} ${_.get(
        trip,
        'transporter.name.last',
        'Driver'
    )}`;

    const lateReason = _.get(trip, 'lateReason', 'N/A');
    const dateOfTrip = moment(_.get(trip, 'actualStartTime', null)).format('YYYY-MM-DD');

    return (
        <ListItem key={index}>
            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
                <Icon>hourglass_top</Icon>
            </Avatar>
            <ListItemText
                style={{ fontSize: '0.875rem' }}
                primary={`"${lateReason} - ${driverName}"`}
                secondary={loc('pickupDriverLate', lang) + ` - ${moment(dateOfTrip).format('MMM DD, YYYY')}`}
            />
        </ListItem>
    );
}
