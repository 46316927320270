import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import moment from 'moment-timezone';
import DropAndGo from 'components/Dialogs/PickupBooking/screens/DropAndGo';
import { getClosestDropLocation, getClosestReturnSite, getDistanceFromLatLonInKm } from 'utils/latlngFunctions';
import { getOpenStatusFromAvailability, getQuickDropName, isEXPRegion, isMXDRegion } from 'utils/misc';

import {
    Button,
    DialogContent,
    DialogContentText,
    DialogActions,
    withTheme,
    colors,
    DialogTitle,
    Typography,
    Chip,
    IconButton,
    Icon
} from '@material-ui/core';
import QuickdropInfoAndCancel from 'components/QuickdropInfoAndCancel/index';

import { getPlaceDetails } from 'utils/mapfunc';
import { useContext } from 'react';
import GoogleContext from 'utils/contexts/GoogleContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { red, amber, green } from '@material-ui/core/colors';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

const defaultAvailability = [
    { day: 'Monday', hours: '8am–6pm' },
    { day: 'Tuesday', hours: '8am–6pm' },
    { day: 'Wednesday', hours: '8am–6pm' },
    { day: 'Thursday', hours: '8am–6pm' },
    { day: 'Friday', hours: '8am–6pm' },
    { day: 'Saturday', hours: '8am–6pm' },
    { day: 'Sunday', hours: '8am–6pm' }
];

function Location({
    http,
    date,
    // selectedLocationId,
    // selectedDropLocation,
    dropAndGoCollector,
    dropAndGoTechnologies,
    onDropLocationChange,
    dropLocations,
    collectors,
    customer,
    onBack,
    onBook,
    onNext,
    onClose,
    inProgress,
    setInProgress,
    handleDateChange,
    lastIncompleteQuickdrop,
    customerLocation,
    onReloadCustomer,
    GPSLoading,
    multiBagDrops,
    theme,
    dropOffType,
    onRefreshLocation,
    charityEnabled,
    isSmUp,
    setReturnSiteLookupOpen,
    onSetPreferredReturnSite,
    onQRDialog,
    onAccessDoor
    // allowRequestLabels
}) {
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    // useEffect(() => {
    //     onRefreshLocation();
    // }, []);

    let quickDropType = getQuickDropName(lang);

    const { placesService, google } = useContext(GoogleContext);

    //console.log('lastIncompleteQuickdrop', lastIncompleteQuickdrop);
    const [isOpen, setIsOpen] = useState(true);
    const [openStatus, setOpenStatus] = useState();
    const [openStatusText, setOpenStatusText] = useState();
    const [distanceToDropLocation, setDistanceToDropLocation] = useState(0);
    const [isClosestReturnSite, setIsClosestReturnSite] = useState(0);
    const [loadingCollectorHours, setLoadingCollectorHours] = useState(true);
    const [collectorCommodities, setCollectorCommodities] = useState([]);
    const [collectorOtherMaterials, setCollectorOtherMaterials] = useState([]);
    const [collectorWarnings, setCollectorWarnings] = useState([]);
    const [loadingCollectorCommodities, setLoadingCollectorCommodities] = useState(true);
    const [isPreferredReturnSite, setIsPreferredReturnSite] = useState(false);

    useEffect(() => {
        const preferredReturnSite = _.get(customer, 'preferredReturnSite');
        if (
            !_.isNil(preferredReturnSite) &&
            (_.get(preferredReturnSite, 'description', '') === _.get(dropAndGoCollector, 'location.description') ||
                _.some(
                    dropAndGoTechnologies,
                    location =>
                        _.get(preferredReturnSite, 'description', '') === _.get(location, 'location.description')
                ))
        ) {
            setIsPreferredReturnSite(true);
        } else {
            setIsPreferredReturnSite(false);
        }
    }, [customer, dropAndGoCollector, dropAndGoTechnologies]);

    const inHouseBookings = _.get(
        dropAndGoCollector,
        'configuration.inHouseTimeSlotBookings',
        _.get(_.first(dropAndGoTechnologies), 'collector.configuration.inHouseTimeSlotBookings', false)
    );
    const contactPhone = _.first(
        _.get(dropAndGoCollector, 'phones', _.get(_.first(dropAndGoTechnologies), 'collector.phones', []))
    );

    const getCommoditiesForCollector = async () => {
        setLoadingCollectorCommodities(true);
        if (!_.isNil(dropAndGoCollector)) {
            const res = await http.getJSON(`/collectors/${dropAndGoCollector._id}/getCommodities`);
            if (res.ok) {
                setCollectorCommodities(_.get(res.data, 'commodities', []));
            }
            const res2 = await http.getJSON(`/collectors/${dropAndGoCollector._id}/getOtherMaterials`);
            if (res2.ok) {
                setCollectorOtherMaterials(_.get(res2.data, 'otherMaterials', []));
            }
            const res3 = await http.getJSON(`/collectors/${dropAndGoCollector._id}/getCollectorWarnings`);
            if (res3.ok) {
                setCollectorWarnings(_.get(res3.data, 'collectorWarnings', []));
            }
        }

        setTimeout(() => {
            setLoadingCollectorCommodities(false);
        }, 300);
    };

    const getOpeningHours = async () => {
        // EXP Regions relies on getting the opening hours from the integration.
        // So we dont bother retrieving googles stored open hours.

        if (isEXPRegion()) {
            setLoadingCollectorHours(false);
        } else {
            setLoadingCollectorHours(true);
            const { place_id } = _.isNil(dropAndGoCollector)
                ? _.first(dropAndGoTechnologies).location
                : dropAndGoCollector.location;
            if (!_.isNil(placesService)) {
                const place = await getPlaceDetails(
                    placesService,
                    place_id,
                    google.maps.places.PlacesServiceStatus.OK,
                    http,
                    null,
                    ['opening_hours'] //NOTE: this field is not free
                );
                if (place) {
                    setOpenStatus(place.opening_hours);
                }
            }

            setTimeout(() => {
                setLoadingCollectorHours(false);
            }, 300);
        }
    };

    useEffect(() => {
        if (!_.isNil(dropAndGoCollector) || !_.isEmpty(dropAndGoTechnologies)) {
            getOpeningHours();
            setDistanceToDropLocation(
                getDistanceFromLatLonInKm(
                    customerLocation.lat,
                    customerLocation.lng,
                    _.get(dropAndGoCollector, 'location.lat', _.get(_.first(dropAndGoTechnologies), 'location.lat', 0)),
                    _.get(dropAndGoCollector, 'location.lng', _.get(_.first(dropAndGoTechnologies), 'location.lng', 0))
                )
            );
        }
        if (!_.isNil(customerLocation)) {
            const closestLocationInfo = getClosestReturnSite(
                { lat: customerLocation.lat, lng: customerLocation.lng },
                dropLocations,
                collectors
            );
            if (
                _.get(closestLocationInfo, 'collector._id', '') === _.get(dropAndGoCollector, '_id', '') ||
                (!_.isEmpty(dropAndGoTechnologies) &&
                    closestLocationInfo.dropLocations
                        .map(location => location._id)
                        .includes(_.first(dropAndGoTechnologies)._id))
            ) {
                setIsClosestReturnSite(true);
            } else {
                setIsClosestReturnSite(false);
            }
            // const closestLocation = getClosestDropLocation(
            //     { lat: customerLocation.lat, lng: customerLocation.lng },
            //     dropLocations
            // );
            // if (closestLocation._id === selectedLocationId) {
            //     setIsClosestReturnSite(true);
            // } else {
            //     setIsClosestReturnSite(false);
            // }
        }
        getCommoditiesForCollector();
    }, [dropAndGoCollector, dropAndGoTechnologies]);

    useEffect(() => {
        const timezone = _.get(
            dropAndGoCollector,
            'timezone',
            _.get(
                _.first(dropAndGoTechnologies),
                'collector.timezone',
                _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
            )
        );

        // OpenStatus is retrieved from Google services. It will get the opening hours google stores on the location.
        // If google has found opening hours 'openStatus' will not be null. If its null we will use the
        // collector or technologies availability.

        if (_.isNil(openStatus)) {
            let openStatusObj = getOpenStatusFromAvailability(
                _.get(
                    dropAndGoCollector,
                    'availability',
                    _.get(_.first(dropAndGoTechnologies), 'availability', defaultAvailability)
                ),
                timezone,
                lang
            );
            setOpenStatusText(openStatusObj.openStatusText);
            setIsOpen(openStatusObj.open);
            return;
        }
        const today = moment(new Date()).day();
        if (openStatus.isOpen()) {
            setIsOpen(true);
            let closesAt = parseInt(_.get(openStatus, `periods[${today}].close.time`));

            closesAt = closesAt.toString();
            let closeTime = moment(new Date())
                .tz(timezone)
                .startOf('day')
                .add(parseInt(closesAt.slice(0, closesAt.length - 2)), 'hours')
                .add(parseInt(closesAt.slice(closesAt.length - 2)), 'minutes');
            if (Math.abs(moment(new Date()).diff(closeTime)) < 60 * 60 * 1000) {
                setOpenStatusText(loc('pickupDialogDrop7b', lang));
            } else {
                setOpenStatusText(loc('pickupDialogDrop7', lang));
            }
        } else if (!openStatus.isOpen()) {
            setIsOpen(false);
            let opensAt = isEXPRegion()
                ? openStatus.opensAt
                : parseInt(_.get(openStatus, `periods[${today}].open.time`));
            opensAt = opensAt.toString();
            let openTime = moment(new Date())
                .tz(timezone)
                .startOf('day')
                .add(parseInt(opensAt.slice(0, opensAt.length - 2)), 'hours')
                .add(parseInt(opensAt.slice(opensAt.length - 2)), 'minutes');
            if (Math.abs(moment(new Date()).diff(openTime)) < 60 * 60 * 1000) {
                setOpenStatusText(loc('pickupDialogDrop8b', lang));
            } else {
                setOpenStatusText(loc('pickupDialogDrop8', lang));
            }
        }
    }, [openStatus, dropAndGoCollector, dropAndGoTechnologies]);

    let content;
    if (!multiBagDrops && !_.isNil(lastIncompleteQuickdrop)) {
        content = (
            <>
                <DialogContentText style={{ textAlign: 'center', marginBottom: theme.spacing.unit }}>
                    <div style={{ marginBottom: theme.spacing.unit }}>
                        {loc('pickupDialogDrop26', lang, { type: quickDropType })}
                    </div>
                    <QuickdropInfoAndCancel
                        customer={customer}
                        quickdropBulk={lastIncompleteQuickdrop}
                        http={http}
                        onCancel={() => {
                            onReloadCustomer(true);
                        }}
                        charityEnabled={charityEnabled}
                    />
                </DialogContentText>
            </>
        );
    } else if (!_.isEmpty(dropLocations)) {
        content = (
            <DropAndGo
                http={http}
                date={date}
                handleDateChange={handleDateChange}
                customer={customer}
                quickDropType={quickDropType}
                // selectedLocationId={selectedDropLocation._id}
                onDropLocationChange={onDropLocationChange}
                dropLocations={dropLocations}
                helperText={''}
                loadingCollectorHours={loadingCollectorHours}
                loadingCollectorCommodities={loadingCollectorCommodities}
                collectorCommodities={collectorCommodities}
                collectorOtherMaterials={collectorOtherMaterials}
                collectorWarnings={collectorWarnings}
                GPSLoading={GPSLoading}
                openStatus={openStatus}
                // selectedDropLocation={selectedDropLocation}
                inHouseBookings={inHouseBookings}
                setInProgress={setInProgress}
                // allowRequestLabels={allowRequestLabels}
                contactPhone={contactPhone}
                onNext={onNext}
                dropAndGoCollector={dropAndGoCollector}
                dropAndGoTechnologies={dropAndGoTechnologies}
                dropOffType={dropOffType}
                isSmUp={isSmUp}
                onQRDialog={onQRDialog}
                onAccessDoor={onAccessDoor}
                isOpen={isOpen}
            />
        );
    } else {
        content = (
            <DialogContentText style={{ textAlign: 'center', marginBottom: theme.spacing.unit }}>
                {loc('pickupDialogDrop27', lang, { type: quickDropType })}
            </DialogContentText>
        );
    }
    const titleContent = (
        <div>
            {/* {!_.isNil(openStatusText) && !_.isEmpty(openStatusText) && (
                <Typography
                    variant="body1"
                    style={{
                        maxWidth: '100%',
                        color: 'white',
                        fontWeight: 500,
                        textTransform: 'uppercase'
                    }}
                >
                    {openStatusText}
                </Typography>
            )} */}
            <Typography
                data-cy="quickdrop-name"
                variant="h5"
                style={{ maxWidth: '100%', color: theme.palette.primary.contrastText, fontWeight: 500 }}
            >
                {_.get(dropAndGoCollector, 'name', _.get(_.first(dropAndGoTechnologies), 'name', 'N/A'))}
            </Typography>
            {!_.isNil(distanceToDropLocation) && (
                <Typography
                    variant="body1"
                    style={{
                        maxWidth: '100%',
                        color: theme.palette.primary.contrastText,
                        fontWeight: 500,
                        fontSize: '90%'
                    }}
                    data-cy="droplocation-header"
                >
                    {_.round(distanceToDropLocation, 1)} km{' '}
                    {!_.isNil(openStatusText) && !_.isEmpty(openStatusText) && (
                        <Chip
                            label={openStatusText.charAt(0).toUpperCase() + openStatusText.slice(1)}
                            style={{
                                margin: theme.spacing.unit / 2,
                                padding: 0,
                                borderRadius: 2,
                                backgroundColor:
                                    openStatusText === loc('pickupDialogDrop7', lang)
                                        ? green[500]
                                        : openStatusText === loc('pickupDialogDrop8', lang)
                                        ? red[500]
                                        : amber[500],
                                fontSize: '75%',
                                fontWeight: 400,
                                height: 20
                            }}
                        />
                    )}
                    {isClosestReturnSite && (
                        <Chip
                            label={
                                isClosestReturnSite ? loc('pickupDialogDrop24b', lang) : '' //loc('pickupDialogDrop25b', lang)
                            }
                            style={{
                                margin: theme.spacing.unit / 2,
                                padding: 0,
                                borderRadius: 2,
                                backgroundColor: isClosestReturnSite ? green[500] : amber[500],
                                fontSize: '75%',
                                fontWeight: 400,
                                height: 20
                            }}
                        />
                    )}
                    {!_.isNil(_.get(dropAndGoCollector, 'name', _.get(_.first(dropAndGoTechnologies), 'name'))) && (
                        <IconButton
                            style={{ color: colors.amber[500] }}
                            onClick={() => {
                                warnAction(() => {
                                    onSetPreferredReturnSite(
                                        isPreferredReturnSite
                                            ? null
                                            : _.get(
                                                  dropAndGoCollector,
                                                  'location',
                                                  _.get(_.first(dropAndGoTechnologies), 'location', {})
                                              )
                                    );
                                }, loc(isPreferredReturnSite ? 'pickupDialogDrop50' : 'pickupDialogDrop49', lang, { name: _.get(dropAndGoCollector, 'name', _.get(_.first(dropAndGoTechnologies), 'name', 'N/A')) }));
                            }}
                        >
                            <Icon>{isPreferredReturnSite ? 'star' : 'star_border'}</Icon>
                        </IconButton>
                    )}
                    <IconButton
                        onClick={() => setReturnSiteLookupOpen(true)}
                        style={{ padding: 5 }}
                        data-cy="quickdrop-location-search-btn"
                    >
                        <Icon>search</Icon>
                    </IconButton>
                </Typography>
            )}
        </div>
    );

    return (
        <>
            {isSmUp ? (
                <DialogTitlePrimary closeButtonShown textVariant={'body1'} onClose={onClose}>
                    {titleContent}
                </DialogTitlePrimary>
            ) : (
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: `max(${theme.spacing.unit * 3}px, var(--custom-safe-area-top, ${theme.spacing.unit *
                            3}px))`,
                        paddingBottom: theme.spacing.unit * 3,
                        background: theme.palette.primary.main
                    }}
                >
                    {titleContent}
                </DialogTitle>
            )}

            <DialogContent>{content}</DialogContent>
            {/* <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                {!_.isNil(onBack) && (
                    <Button color="primary" disabled={inProgress} onClick={onBack}>
                        {loc('back', lang)}
                    </Button>
                )}
                <Button
                    color="primary"
                    onClick={inHouseBookings ? onBook : onNext}
                    data-cy="quickdrop-steps-next"
                    disabled={
                        _.isNil(selectedDropLocation) ||
                        inProgress ||
                        (!inHouseBookings && openStatus && !openStatus.isOpen) ||
                        loadingCollectorHours ||
                        GPSLoading ||
                        (!multiBagDrops && !_.isNil(lastIncompleteQuickdrop))
                    }
                >
                    {inHouseBookings ? 'Book' : loc('next', lang)}
                </Button>
                )
            </DialogActions> */}
        </>
    );
}

export default withTheme()(Location);
