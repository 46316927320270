import _ from 'lodash';
import moment from 'moment-timezone';

import {
    getCustomerName,
    getBulkCompletionDate,
    formatAsCurrency,
    formatAsPhoneNumber,
    formatAsAddress,
    wait
} from 'utils/misc';

import { getPlaceDetails } from 'utils/mapfunc';

import { _pickup } from 'std';
import { locDate } from 'localizations/localizationHandler';

export function getCompleteRedemptions(redemptions) {
    return _.filter(redemptions, redemption => redemption.complete);
}

export function getDonationsFromRedemptions(
    donatedRedemptions,
    redemptions,
    redemptionSelected,
    csv,
    taxReceiptsIssued,
    teamsColumnVisible,
    lang
) {
    let startDate, endDate;

    let currentRedemption = redemptions.find(redemption => redemption._id === redemptionSelected);
    if (_.isNil(currentRedemption)) {
        endDate = new Date(2100, 1, 1).getTime();
    } else {
        endDate = new Date(currentRedemption.createdAt).getTime();
    }

    let previousIndex = !_.isNil(currentRedemption)
        ? redemptions.map(x => x._id).indexOf(redemptionSelected) - 1
        : redemptions.length - 1;
    let previousRedemption = previousIndex >= 0 ? redemptions[previousIndex] : undefined;
    if (_.isNil(previousRedemption)) {
        startDate = new Date(0).getTime();
    } else {
        startDate = new Date(previousRedemption.createdAt).getTime();
    }

    // Customers who requested tax receipts:
    /*let bulkGroup = _.filter(
        bulks,
        bulk => getCountedDate(bulk) > startDate && getCountedDate(bulk) < endDate && bulk.taxReceipt.requested
    );*/
    let redemptionGroup = _.filter(
        donatedRedemptions,
        donatedRedemption =>
            new Date(donatedRedemption.createdAt) > startDate &&
            new Date(donatedRedemption.createdAt) < endDate &&
            donatedRedemption.taxReceiptRequested
    );
    // let bulkGroup = _.filter(bulks, bulk => getCountedDate(bulk) > startDate && getCountedDate(bulk) < endDate); // DEBUG: for summing up the rows

    // Customers who did not request tax receipts (not included in .csv):
    // #3611 - updated to show all donations
    // if (!csv || !taxReceiptsIssued) {
    /*bulkGroup = bulkGroup.concat(
            _.filter(
                bulks,
                bulk => getCountedDate(bulk) > startDate && getCountedDate(bulk) < endDate && !bulk.taxReceipt.requested
            )
        );*/
    redemptionGroup = redemptionGroup.concat(
        _.filter(
            donatedRedemptions,
            donatedRedemption =>
                new Date(donatedRedemption.createdAt) >= startDate &&
                new Date(donatedRedemption.createdAt) <= endDate &&
                !donatedRedemption.taxReceiptRequested
        )
    );
    // }

    /*bulkGroup = bulkGroup.map(bulk => {
        let taxReceiptRequested = bulk.taxReceipt.requested;
        let obj = {
            _id: bulk.owner._id,
            name: getCustomerName(bulk.owner),
            amount: formatAsCurrency(bulkHelper.getCustomerAmount(bulk))
        };
        if (taxReceiptsIssued) {
            obj.email = taxReceiptRequested ? bulk.owner.email : 'N/A';
            obj.phone = taxReceiptRequested ? formatAsPhoneNumber(bulk.owner.phone) : 'N/A';
            obj.address = taxReceiptRequested ? formatAsAddress(bulk.owner.location, true) || 'N/A' : 'N/A';
            obj.postal_code = taxReceiptRequested ? bulk.owner.location.postalCode : undefined;
            obj.place_id = taxReceiptRequested ? bulk.owner.location.place_id : undefined;
        }
        if (teamsColumnVisible) {
            obj.team = bulk.subdivision;
        }
        if (!csv) {
            obj.disabled = taxReceiptsIssued ? !taxReceiptRequested : false;
        }

        return obj;
    });*/

    redemptionGroup = redemptionGroup.map(redemption => {
        // let taxReceiptRequested = redemption.taxReceiptRequested;
        let taxReceiptRequested = _.get(redemption.claimant, 'receiveTaxReceipts');
        let obj = {
            _id: redemption.claimant._id,
            name: getCustomerName(redemption.claimant),
            amount: formatAsCurrency(redemption.amount)
        };
        if (taxReceiptsIssued) {
            obj.email = taxReceiptRequested ? redemption.claimant.email : 'N/A';
            obj.phone = taxReceiptRequested ? formatAsPhoneNumber(redemption.claimant.phone) : 'N/A';
            obj.address = taxReceiptRequested ? formatAsAddress(redemption.claimant.location, true) || 'N/A' : 'N/A';
            obj.postal_code = taxReceiptRequested ? redemption.claimant.location.postalCode : undefined;
            obj.place_id = taxReceiptRequested ? redemption.claimant.location.place_id : undefined;
        }
        const completedBulkPickups = _(redemption.bulks)
            .filter(bulk => _.get(bulk, 'datePickedUp') && _.get(bulk, 'bulkType') === 'pickup')
            .value();
        if (!_.isEmpty(completedBulkPickups)) {
            // obj.date = taxReceiptRequested ? _.last(completedBulkPickups).datePickedUp : undefined;
            obj.date = _.last(completedBulkPickups).datePickedUp;
        }
        if (teamsColumnVisible) {
            obj.team = redemption.subdivision;
        }
        if (!csv) {
            obj.disabled = taxReceiptsIssued ? !taxReceiptRequested : false;
        }

        return obj;
    });

    /*bulkGroup = _.sortBy(bulkGroup, bulk => bulk.name);
    bulkGroup = mergeRowsByUser(bulkGroup);

    return bulkGroup;*/

    redemptionGroup = _.sortBy(redemptionGroup, redemption => redemption.name);
    redemptionGroup = mergeRowsByUser(redemptionGroup);

    return redemptionGroup;
}

export function getBulksForMonth(bulks, monthSelected) {
    const startDate = moment(monthSelected).startOf('month');
    const endDate = moment(monthSelected).endOf('month');

    const beverageBulks = _.filter(bulks, bulk => bulk.skuType === 'beverage' || bulk.skuType === 'collectionbins');

    // 2.10 changed bulk.dateCounted to dateCompleted
    const bulksForMonth = _.filter(
        beverageBulks,
        bulk => moment(bulk.dateCompleted) <= endDate && moment(bulk.dateCompleted) >= startDate
    );

    const small = '0-1L';
    const large = '>1L';
    //object where keys are the material type and the values are objects containing the
    //counts for large and small containers
    let materialTypeCounts = {};

    //converts array of bulks to a 1 dimensional array of count items
    const items = _.flattenDeep(bulksForMonth.map(bulk => bulk.counts.map(count => count.items)));

    items.forEach(item => {
        let materialCounts = materialTypeCounts[item.materialType];
        if (_.isNil(materialCounts)) {
            materialTypeCounts[item.materialType] = { small: 0, large: 0 };
        }
        materialTypeCounts[item.materialType][item.size === small ? 'small' : 'large'] += item.quantity;
    });

    let rows = Object.keys(materialTypeCounts).map(key => {
        return {
            material: key,
            small: materialTypeCounts[key].small,
            large: materialTypeCounts[key].large,
            total: materialTypeCounts[key].small + materialTypeCounts[key].large
        };
    });

    let totalsRow = {
        name: '',
        small: 'Total: ' + _.sumBy(rows, 'small'),
        large: 'Total: ' + _.sumBy(rows, 'large'),
        total: 'Total: ' + _.sumBy(rows, 'total')
    };
    rows.push(totalsRow);
    return rows;
}

function mergeRowsByUser(array) {
    if (_.isEmpty(array)) {
        return [];
    }

    let rows = _.sortBy(_.cloneDeep(array), row => row.email);
    for (let i = 1; i < rows.length; i++) {
        let curr = rows[i];
        let prev = rows[i - 1];
        if (isSameExceptAmount(curr, prev)) {
            curr.amount = formatAsCurrency(
                (parseFloat(curr.amount.replace(/,/g, '').substr(1)) +
                    parseFloat(prev.amount.replace(/,/g, '').substr(1))) *
                    100
            );
            delete rows[i - 1];
        }
    }

    return _.filter(rows, row => !_.isNil(row));

    function isSameExceptAmount(curr, prev) {
        let first = _.omit(curr, 'amount');
        let second = _.omit(prev, 'amount');
        return _.isEqual(first, second);
    }
}

export async function addPostalCodes(http, google, placesService, data) {
    let dataUpdated = _.cloneDeep(data);

    for (let i = 0; i < dataUpdated.length; i++) {
        let row = dataUpdated[i];
        if (!_.isNil(row.place_id) && !_.isEmpty(row.place_id)) {
            if (_.isNil(row.postal_code)) {
                try {
                    let details = await getPlaceDetails(
                        placesService,
                        row.place_id,
                        google.maps.places.PlacesServiceStatus.OK,
                        http
                    );
                    let postalCodeComponent;
                    if (details) {
                        postalCodeComponent = _.find(
                            details.address_components,
                            component => component.types[0] === 'postal_code'
                        );
                    }

                    let postalCode = !_.isNil(postalCodeComponent) ? postalCodeComponent.short_name : undefined;
                    row.postal_code = postalCode || 'N/A';
                    // Upload postal code to the customer's location:
                    await http.post(`/users/${row._id}/updatePostalCode`, { postalCode });
                } catch (err) {
                    await wait(1000);
                    i--;
                }
            }
            delete row.place_id;
        } else {
            row.postal_code = 'N/A';
        }
    }

    return dataUpdated;
}

function getCountedDate(bulk) {
    let lastCount = _.last(bulk.counts);
    if (_.isNil(lastCount)) {
        return new Date(getBulkCompletionDate(bulk)).getTime(); // should never happen
    }

    let timestamp = lastCount._id.toString().substring(0, 8);
    let date = new Date(parseInt(timestamp, 16) * 1000);
    return date.getTime();
}
