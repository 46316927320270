import { useState } from 'react';
import useQueryDate from 'utils/hooks/useQueryDate';
import moment from 'moment-timezone';
import _ from 'lodash';
import { _time } from 'std';

import useQueryString from 'utils/hooks/useQueryString';
import { today, thisWeek, thisIsoWeek, thisMonth, thisQuarter, last21Days, allTime } from '../TimePeriods/TimePeriods';

const selectedWindowValues = {
    today,
    thisWeek,
    thisIsoWeek,
    thisMonth,
    thisQuarter,
    last21Days,
    allTime
};

function useDateRangePicker({
    timezones = [process.env.REACT_APP_REGION_TIMEZONE],
    initStartVal,
    initEndVal,
    saveStateInURL = true,
    initDateWindow = 'thisWeek',
    allowFutureDates = false
}) {
    const [timezone, setTimezone] = useQueryString(
        'timezone',
        getInitialTimezoneState(timezones),
        saveStateInURL,
        timezone => {
            return isValidTimezone(timezones, timezone);
        }
    );

    const [startDate, setStartDate] = useQueryDate({
        key: 'startDate',
        initVal: !_.isNil(initStartVal)
            ? initStartVal
            : initDateWindow === 'thisIsoWeek'
            ? _time.getStartOfIsoWeek(timezone)
            : _time.getStartOfWeek(timezone),
        enabled: saveStateInURL,
        initToNull: false,
        timezone
    });

    const [endDate, setEndDate] = useQueryDate({
        key: 'endDate',
        initVal: !_.isNil(initEndVal) ? initEndVal : _time.getEndOfDay(timezone),
        enabled: saveStateInURL,
        initToNull: false,
        timezone
    });

    const [isCustomDate, setIsCustomDate] = useState(false);
    const [customIncrementAmount, setCustomIncerementAmount] = useState(7);

    const [dateWindow, setDateWindow] = useQueryString('window', initDateWindow, true);

    const handleChangeStartDate = date => {
        let newCustomIncrementAmount = Math.abs(endDate.diff(date.startOf('day'), 'days'));
        if (newCustomIncrementAmount === 0) {
            newCustomIncrementAmount = 1;
        }
        if (date.isSameOrBefore(endDate)) {
            setStartDate(date);
        } else {
            let newStartDate = endDate.startOf('day');
            let newEndDate = date.endOf('day');

            newCustomIncrementAmount = Math.abs(newEndDate.diff(newStartDate, 'days'));
            if (newCustomIncrementAmount === 0) {
                newCustomIncrementAmount = 1;
            }
            setStartDate(newStartDate);
            setEndDate(newEndDate);

            setIsCustomDate(true);
            setCustomIncerementAmount(newCustomIncrementAmount);
        }
    };

    const handleChangeEndDate = date => {
        let newCustomIncrementAmount = Math.abs(date.diff(startDate, 'days'));
        if (newCustomIncrementAmount === 0) {
            newCustomIncrementAmount = 1;
        }

        if (date.isSameOrAfter(startDate)) {
            setEndDate(date.endOf('day'));
        } else {
            let newStartDate = date.startOf('day');
            let newEndDate = startDate.endOf('day');

            newCustomIncrementAmount = Math.abs(newEndDate.diff(newStartDate, 'days'));
            if (newCustomIncrementAmount === 0) {
                newCustomIncrementAmount = 1;
            }

            setStartDate(newStartDate);
            setEndDate(startDate);

            setIsCustomDate(true);
            setCustomIncerementAmount(newCustomIncrementAmount);
        }
    };

    const handleChangeBothDates = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate.endOf('day'));
    };

    const handleGoForwards = () => {
        const TODAY = _time.getEndOfDay(timezone);
        const selectedWindowObj = _.get(selectedWindowValues, dateWindow);
        let newStartDate, newEndDate;
        if (isCustomDate) {
            newStartDate = startDate
                .clone()
                .add(customIncrementAmount, 'day')
                .startOf('day');
            if (customIncrementAmount === 1) {
                newEndDate = newStartDate.clone().endOf('day');
            } else {
                newEndDate = newStartDate
                    .clone()
                    .add(customIncrementAmount, 'day')
                    .endOf('day');
            }
        } else if (dateWindow === 'last21Days') {
            newStartDate = startDate
                .clone()
                .add(21, 'day')
                .startOf(selectedWindowObj.increment);
            newEndDate = endDate
                .clone()
                .add(21, 'day')
                .startOf(selectedWindowObj.increment);
        } else {
            newStartDate = startDate
                .clone()
                .add(1, selectedWindowObj.increment === 'isoWeek' ? 'week' : selectedWindowObj.increment) //add(1,'isoWeek') doesn't work
                .startOf(selectedWindowObj.increment);
            if (moment(newStartDate).isAfter(TODAY) && !allowFutureDates) newStartDate = startDate;

            newEndDate = newStartDate.clone().endOf(selectedWindowObj.increment);
        }

        if (moment(newStartDate).isAfter(TODAY) && !allowFutureDates) {
            newStartDate = TODAY;
        }
        if (moment(newEndDate).isAfter(TODAY) && !allowFutureDates) {
            newEndDate = TODAY;
        }
        if (dateWindow === 'last21Days' && !allowFutureDates && moment(newEndDate).isSameOrAfter(TODAY)) {
            newStartDate = newEndDate
                .clone()
                .subtract(21, 'day')
                .startOf(selectedWindowObj.increment);
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleGoBackwards = () => {
        const selectedWindowObj = _.get(selectedWindowValues, dateWindow);
        let newStartDate, newEndDate;
        if (isCustomDate) {
            newStartDate = startDate
                .clone()
                .subtract(customIncrementAmount, 'day')
                .startOf('day');
            if (customIncrementAmount === 1) {
                newEndDate = newStartDate.clone().endOf('day');
            } else {
                newEndDate = newStartDate
                    .clone()
                    .add(customIncrementAmount, 'day')
                    .endOf('day');
            }
        } else if (dateWindow === 'last21Days') {
            newStartDate = startDate
                .clone()
                .subtract(21, 'day')
                .startOf(selectedWindowObj.increment);
            newEndDate = endDate
                .clone()
                .subtract(21, 'day')
                .startOf(selectedWindowObj.increment);
        } else {
            newStartDate = startDate
                .clone()
                .subtract(1, selectedWindowObj.increment === 'isoWeek' ? 'week' : selectedWindowObj.increment) //subtract(1,'isoWeek') doesn't work
                .startOf(selectedWindowObj.increment);
            newEndDate = newStartDate.clone().endOf(selectedWindowObj.increment);
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleWindowSelect = e => {
        const { value } = e.target;
        const window = selectedWindowValues[value];

        setDateWindow(value);

        setStartDate(
            value === 'allTime' || value === 'last21Days'
                ? moment(window.startDate).tz(timezone)
                : moment()
                      .tz(timezone)
                      .startOf(window.increment)
        );
        setEndDate(
            allowFutureDates
                ? moment()
                      .tz(timezone)
                      .endOf(window.increment)
                : moment().tz(timezone)
        );

        setIsCustomDate(false);
    };

    const handleChangeTimezone = e => {
        const newTimezone = e.target.value;
        setTimezone(newTimezone);
        const newStartOfDay = _time.convertToNewStartOfDay(newTimezone, startDate);
        setStartDate(newStartOfDay);
        setEndDate(
            moment(newStartOfDay)
                .tz(timezone)
                .endOf('day')
        );
    };

    return {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeBothDates,
        handleChangeTimezone,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    };
}

export default useDateRangePicker;

function getInitialTimezoneState(timezones) {
    if (_.isNil(timezones) || _.isEmpty(timezones)) {
        return process.env.REACT_APP_REGION_TIMEZONE;
    } else if (timezones.length === 1) {
        return _.first(timezones);
    } else {
        return process.env.REACT_APP_REGION_TIMEZONE;
    }
}

function isValidTimezone(timezoneArr, timezone) {
    return timezoneArr.includes(timezone);
}
