import React, { useContext } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import {
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    withTheme,
    Grid,
    InputAdornment,
    Tooltip,
    Icon,
    Typography,
    FormControl,
    colors,
    FormHelperText
} from '@material-ui/core';

import { getTextInput } from '../helperFunctions';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { maxFileSize } from '../../../constants';
import { useState } from 'react';

function PinTypesForm(props) {
    const { open, loading, onClose, onSubmit, theme, selectedPinType } = props;

    const imageFileTypes = [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'bmp',
        'ico',
        'cur',
        'tif'
    ];

    const { lang } = useContext(LocalizationContext);

    const [markerFile, setMarkerFile] = useState(null);
    const [markerFileError, setMarkerFileError] = useState(false);
    const [markerFileErrorText, setMarkerFileErrorText] = useState('');

    const handleSubmit = values => {
        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        if (!_.isNil(markerFile)) {
            formData.append('markerFile', markerFile); //will show up in req.files
        } else {
            if (_.isEmpty(values.url)) {
                setMarkerFileError(true);
                setMarkerFileErrorText('You must provide a pin image', 'error');
                return;
            }
        }
        formData.append('form', JSON.stringify(values));
        onSubmit(formData);
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(selectedPinType, '_id', null),
            type: _.get(selectedPinType, 'type', ''),
            url: _.get(selectedPinType, 'url', ''),
            height: _.get(selectedPinType, 'height', null),
            width: _.get(selectedPinType, 'width', null)
        },
        validationSchema: Yup.object({
            type: Yup.string().required('You must enter a value')
        }),
        onSubmit: handleSubmit
    });

    const handleMarkerFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                formik.setFieldValue('height', image.height);
                formik.setFieldValue('width', image.width);
            };
            setMarkerFile(e.target.files[0]);
            setMarkerFileError(false);
            setMarkerFileErrorText('');
        } else {
            setMarkerFileError(true);
            setMarkerFileErrorText(loc('fileTooLarge', lang), 'error');
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={12}>
                        {getTextInput(
                            theme,
                            'type',
                            'Pin Type',
                            formik,
                            'text',
                            getInfoIcon('Type', 'Short description to be used when editing collector', theme)
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {_.isNil(markerFile) && _.isEmpty(formik.values.url) ? (
                            <FormControl fullWidth data-cy="upload-marker-input">
                                <input
                                    accept={'image/*'}
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={handleMarkerFile}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="span"
                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </FormControl>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                {!_.isNil(markerFile) ? (
                                    <img
                                        src={URL.createObjectURL(markerFile)}
                                        style={{ maxWidth: '100%' }}
                                        alt={`${formik.values.type} Pin`}
                                    />
                                ) : !markerFileError && imageFileTypes.includes(formik.values.url.split('.').pop()) ? (
                                    <img
                                        src={formik.values.url}
                                        alt="Pin File"
                                        onError={() => setMarkerFileError(true)}
                                        style={{
                                            maxWidth: '64px',
                                            maxHeight: '64px'
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                            check_circle
                                        </Icon>
                                        <Typography
                                            style={{
                                                color: colors.green[500],
                                                margin: theme.spacing.unit * 2,
                                                textAlign: 'center'
                                            }}
                                            variant="subtitle2"
                                        >
                                            Image Uploaded
                                        </Typography>
                                    </div>
                                )}
                                <FormControl fullWidth data-cy="upload-photo-input">
                                    <input
                                        accept={'image/*'}
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple={false}
                                        type="file"
                                        onChange={handleMarkerFile}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            style={{
                                                width: '100%',
                                                marginTop: theme.spacing.unit
                                            }}
                                        >
                                            Replace Image
                                        </Button>
                                    </label>
                                </FormControl>
                            </div>
                        )}
                        {markerFileError && (
                            <FormHelperText style={{ color: colors.red[500] }}>{markerFileErrorText}</FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading} onClick={formik.handleSubmit} data-cy="pin-type-submit">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(PinTypesForm);

function getInfoIcon(title, infoText, theme) {
    return {
        endAdornment: (
            <InputAdornment position="end">
                {!_.isEmpty(infoText) && (
                    <Tooltip
                        title={infoText}
                        data-cy={`config-${_.kebabCase(title)}-info-text`}
                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                    >
                        <Icon data-cy={`config-${_.kebabCase(title)}-info-icon`}>info</Icon>
                    </Tooltip>
                )}
            </InputAdornment>
        )
    };
}
