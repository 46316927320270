import React, { useRef, useState, useContext } from 'react';

import _ from 'lodash';
import { _user } from 'std';
import moment from 'moment-timezone';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Icon,
    Button,
    List,
    ListItem,
    ListItemText,
    Avatar,
    IconButton
} from '@material-ui/core';
import MDIcon from '@mdi/react';
import { mdiMoleculeCo2 } from '@mdi/js';
import { withTheme } from '@material-ui/core/styles';
import { deviceHelper } from 'utils/misc';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    RedditIcon,
    EmailIcon
} from 'react-share';
import SwipeableViews from 'react-swipeable-views';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import useGetJSON from 'utils/hooks/useGetJSON';
import useWindowSize from 'utils/hooks/useWindowSize';
import HouseIllustration from 'icons/HouseIllustration';

function ShareScreen(props) {
    const {
        customer,
        iconSize,
        iconStyle,
        description,
        message,
        subject,
        url,
        hashtags,
        onBack,
        onClose,
        onShare,
        onNativeShare,
        onNextScreen,
        environmentalStats,
        theme,
        customerFinancialStats,
        showSingleStep,
        onSubmit,
        isLastScreen,
        isAdmin
    } = props;
    const { lang } = useContext(LocalizationContext);
    const shareRef = useRef(null);
    const [width, height] = useWindowSize();
    let mobileMode = width <= 500;

    const [userShared, setUserShared] = useState(false);
    const [swipeableIndex, setSwipeableIndex] = useState(0);

    const {
        data: {
            waterAmount = 6.686,
            energyAmount = 1.551,
            landfillAmount = 0.07,
            carbonAmount = 0.145,
            waterUnit = 'Liters',
            energyUnit = 'Megajoules',
            landfillUnit = 'Kgs',
            carbonUnit = 'Kgs'
        }
    } = useGetJSON(`/system/impactReportConfig`);

    const scrollToBottom = () => {
        if (shareRef.current) {
            shareRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleShareClick = platform => {
        setUserShared(true);
        onShare(platform);
    };

    const handleNativeShareClick = () => {
        setUserShared(true);
        onNativeShare(subject, shareMessage, url);
    };

    const listItemStyles = {
        width: mobileMode ? width / 3.6 : 175,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: mobileMode ? 190 : null
    };

    // Source: https://www.epa.gov/greenvehicles/greenhouse-gas-emissions-typical-passenger-vehicle.
    // 4.6 tons per year per car
    // 0.38 tons per month = 380kg per month
    const carEquivalent = Math.round(_.get(environmentalStats, 'emissions', 0) / 380);
    const statsMessage = loc('environmentalStats1', lang, {
        landfillStat: environmentalStats.landfill.toLocaleString(undefined, {
            maximumSignificantDigits: 3
        }),
        energyStat: environmentalStats.energy.toLocaleString(undefined, {
            maximumSignificantDigits: 3
        }),
        emissionStat: environmentalStats.emissions.toLocaleString(undefined, {
            maximumSignificantDigits: 3
        }),
        brand: process.env.REACT_APP_BRAND_NAME
    });
    const shareMessage = environmentalStats.emissions > 0 ? statsMessage : message;
    const impactStats = [
        <ListItem style={listItemStyles}>
            <>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <MDIcon path={mdiMoleculeCo2} size={1} color={theme.palette.background.default} />
                </Avatar>
                <Typography style={{ marginTop: 10, textAlign: 'center', fontSize: 12 }}>
                    {loc('impactReport4', lang)}
                </Typography>
            </>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ marginTop: 8, textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                    {(customerFinancialStats.totalQuantity * carbonAmount).toLocaleString(undefined, {
                        maximumSignificantDigits: 3
                    })}
                </Typography>
                <Typography style={{ textAlign: 'center', fontSize: 12 }}>{carbonUnit}</Typography>
            </div>
        </ListItem>,
        <ListItem style={listItemStyles}>
            <>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>landscape</Icon>
                </Avatar>
                <Typography style={{ marginTop: 10, textAlign: 'center', fontSize: 12 }}>
                    {loc('impactReport6', lang)}
                </Typography>
            </>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ marginTop: 8, textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                    {(customerFinancialStats.totalQuantity * landfillAmount).toLocaleString(undefined, {
                        maximumSignificantDigits: 3
                    })}
                </Typography>
                <Typography style={{ textAlign: 'center', fontSize: 12 }}>{landfillUnit}</Typography>
            </div>
        </ListItem>,
        <ListItem style={listItemStyles}>
            <>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>flash_on</Icon>
                </Avatar>
                <Typography style={{ marginTop: 10, textAlign: 'center', fontSize: 12 }}>
                    {loc('impactReport5', lang)}
                </Typography>
            </>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ marginTop: 8, textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                    {(customerFinancialStats.totalQuantity * energyAmount).toLocaleString(undefined, {
                        maximumSignificantDigits: 3
                    })}
                </Typography>
                <Typography style={{ textAlign: 'center', fontSize: 12 }}>{energyUnit}</Typography>
            </div>
        </ListItem>,
        <ListItem style={listItemStyles}>
            <>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>water_drop</Icon>
                </Avatar>
                <Typography style={{ marginTop: 10, textAlign: 'center', fontSize: 12 }}>
                    {loc('impactReport17', lang)}
                </Typography>
            </>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ marginTop: 8, textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                    {(customerFinancialStats.totalQuantity * waterAmount).toLocaleString(undefined, {
                        maximumSignificantDigits: 3
                    })}
                </Typography>
                <Typography style={{ textAlign: 'center', fontSize: 12 }}>{waterUnit}</Typography>
            </div>
        </ListItem>
    ];
    return (
        <>
            <DialogTitle disableTypography style={{ textAlign: 'center', justifyContent: 'center' }}>
                <Typography variant="h5" data-cy="impact-report-title">
                    <b>{loc('impactReport1', lang)}</b>
                </Typography>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', justifyContent: 'center' }}>
                <HouseIllustration theme={theme} />

                <Typography paragraph>
                    <b>{loc('impactReport2a', lang, { user: _user.getNameFirst(customer) })}</b>
                    {loc('impactReport2b', lang)} {loc('impactReport3a', lang)}
                    <b>{locDate(moment(), 'MMM Do, YYYY', lang)}</b>
                    {loc('impactReport3b', lang)}
                </Typography>

                {mobileMode ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton
                            disabled={swipeableIndex === 0}
                            style={{ padding: 0 }}
                            onClick={() => setSwipeableIndex(index => index - 1)}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                        </IconButton>
                        <SwipeableViews
                            onChangeIndex={index => {
                                setSwipeableIndex(index);
                            }}
                            index={swipeableIndex}
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                        >
                            {impactStats.reduce((acc, curr, index) => {
                                if (index % 2 === 0 && index + 1 < impactStats.length) {
                                    acc.push(
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginBottom: theme.spacing.unit
                                            }}
                                        >
                                            <div style={{ marginLeft: theme.spacing.unit }}>{impactStats[index]}</div>
                                            <div style={{ marginRight: theme.spacing.unit }}>
                                                {impactStats[index + 1]}
                                            </div>
                                        </div>
                                    );
                                }
                                return acc;
                            }, [])}
                        </SwipeableViews>
                        <IconButton
                            disabled={swipeableIndex === 1}
                            style={{ padding: 0 }}
                            onClick={() => setSwipeableIndex(index => index + 1)}
                        >
                            <Icon>keyboard_arrow_right</Icon>
                        </IconButton>
                    </div>
                ) : (
                    <List style={{ display: 'flex', justifyContent: 'center' }}>{impactStats}</List>
                )}
                <div>
                    <Button
                        color="primary"
                        onClick={
                            deviceHelper.isNativeApp() || !_.isNil(window.navigator.share)
                                ? () => handleNativeShareClick()
                                : scrollToBottom
                        }
                    >
                        {loc('impactReport7', lang)}
                    </Button>
                </div>

                {!deviceHelper.isNativeApp() && _.isNil(window.navigator.share) && (
                    <div style={{ display: 'inline-flex' }}>
                        <FacebookShareButton
                            style={iconStyle}
                            url={url}
                            hashtag={`#${hashtags[0]}`}
                            quote={shareMessage}
                            onShareWindowClose={() => handleShareClick('Facebook')}
                        >
                            <FacebookIcon size={iconSize} round={true} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                            style={iconStyle}
                            url={url}
                            title={shareMessage}
                            description={description}
                            onShareWindowClose={() => handleShareClick('LinkedIn')}
                        >
                            <LinkedinIcon size={iconSize} round={true} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                            style={iconStyle}
                            url={url}
                            title={shareMessage}
                            hashtags={hashtags}
                            via={`${process.env.REACT_APP_BRAND_NAME} App`}
                            onShareWindowClose={() => handleShareClick('Twitter')}
                        >
                            <TwitterIcon size={iconSize} round={true} />
                        </TwitterShareButton>
                        <RedditShareButton
                            style={iconStyle}
                            url={url}
                            title={shareMessage}
                            onShareWindowClose={() => handleShareClick('Reddit')}
                        >
                            <RedditIcon size={iconSize} round={true} />
                        </RedditShareButton>
                        <EmailShareButton
                            style={iconStyle}
                            url={url}
                            subject={subject}
                            body={shareMessage}
                            onShareWindowClose={() => handleShareClick('Email')}
                        >
                            <EmailIcon size={iconSize} round={true} />
                        </EmailShareButton>
                        <div ref={shareRef} />
                    </div>
                )}
            </DialogContent>

            {isLastScreen ? (
                <DialogActions
                    style={{
                        justifyContent: showSingleStep ? 'right' : 'space-between',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    {!showSingleStep && <Button onClick={onBack}>{loc('back', lang)}</Button>}

                    <Button
                        data-cy="share-and-feedback-dialog-no-thanks-button"
                        onClick={
                            showSingleStep
                                ? onNextScreen
                                : () => {
                                      onSubmit();
                                  }
                        }
                    >
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions
                    style={{
                        justifyContent: showSingleStep ? 'right' : 'space-between',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    {!showSingleStep && <Button onClick={onBack}>{loc('back', lang)}</Button>}

                    {(isAdmin || showSingleStep) && <Button onClick={onClose}>{loc('close', lang)}</Button>}
                    {!showSingleStep && (
                        <Button data-cy="share-and-feedback-next-button" onClick={onNextScreen}>
                            {loc('next', lang)}
                        </Button>
                    )}
                </DialogActions>
            )}
        </>
    );
}

export default withTheme()(ShareScreen);
