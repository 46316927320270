import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import { withTheme, Typography, Dialog, CircularProgress, ListItem } from '@material-ui/core';

import { List } from '@material-ui/core';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import { loc } from '../../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import Complaint from './Complaint.js';

function ComplaintsWidget(props) {
    const {
        operator,
        pickupsWithIssues,
        bulksWithIssues,
        systemComplaints,
        theme,
        history,
        loading,
        isDriver = false,
        height,
        noPadding,
        unviewedComplaintIdsState,
        unviewedComplaintBulkIdsState
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [complaintImages, setComplaintImages] = useState(null);
    const [allComplaints, setAllComplaints] = useState([]);

    useEffect(() => {
        const complaintsList = buildComplaintsList(
            pickupsWithIssues,
            bulksWithIssues,
            systemComplaints,
            unviewedComplaintIdsState,
            unviewedComplaintBulkIdsState,
            lang
        );

        setAllComplaints(complaintsList);
    }, [
        pickupsWithIssues,
        bulksWithIssues,
        systemComplaints,
        unviewedComplaintIdsState,
        unviewedComplaintBulkIdsState
    ]);

    return loading ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 200,
                height: height ? height : undefined,
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <>
            <div
                style={{
                    width: '100%',
                    maxHeight: 200,
                    height: height ? height : undefined,
                    overflowY: 'auto'
                }}
            >
                <List dense>
                    {_.isEmpty(allComplaints) ? (
                        <ListItem>
                            <Typography>{loc('noComplaints', lang)}</Typography>
                        </ListItem>
                    ) : (
                        allComplaints.map(complaint => (
                            <Complaint
                                complaint={complaint}
                                lang={lang}
                                operator={operator}
                                isDriver={isDriver}
                                setComplaintImages={setComplaintImages}
                                history={history}
                            />
                        ))
                    )}
                </List>
            </div>
            <Dialog open={!_.isNil(complaintImages)} fullWidth onClose={() => setComplaintImages(null)}>
                <ImageViewer
                    displayImages={complaintImages}
                    disableDelete={true}
                    innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
        </>
    );
}

export default withTheme()(ComplaintsWidget);

const buildComplaintsList = (
    pickupsWithIssues = [],
    bulksWithIssues = [],
    systemComplaints = [],
    unviewedComplaintIdsState = null,
    unviewedComplaintBulkIdsState = null,
    lang
) => {
    const complaintsList = [];

    pickupsWithIssues.forEach(pickup => {
        if (!_.isNil(unviewedComplaintIdsState) && !unviewedComplaintIdsState.includes(pickup._id)) {
            return;
        }

        complaintsList.push({
            complaintId: pickup._id,
            complaintItemType: 'pickup',
            date: pickup.completionDate,
            unresolved: false, // No way to resolve atm
            issueDescription: pickup.customerIssues.issueDescription
        });
    });

    bulksWithIssues.forEach(bulk => {
        if (!_.isNil(unviewedComplaintBulkIdsState) && !unviewedComplaintBulkIdsState.includes(bulk._id)) {
            return;
        }

        const customerIssues = _.get(bulk, 'customerIssues', []);
        if (!_.isEmpty(customerIssues)) {
            complaintsList.push({
                complaintId: bulk._id,
                complaintItemType: 'bulk',
                date: bulk.dateCompleted,
                unresolved: _.isNil(bulk.customerIssues.dateResolved),
                issueDescription: bulk.customerIssues.issueDescription
            });
        }

        const counterIssues = _.get(bulk, 'driverIssues.issues', []);
        if (!_.isEmpty(counterIssues)) {
            let issueDescription = bulk.driverIssues.issues.join(', ');
            if (!_.isNil(bulk.driverIssues.description)) {
                issueDescription += ', ' + bulk.driverIssues.description;
            }
            if (
                !_.isNil(_.get(bulk, 'driverIssues.reporter.name.first', null)) &&
                !_.isNil(_.get(bulk, 'driverIssues.reporter.name.last', null))
            ) {
                issueDescription +=
                    ' - ' + bulk.driverIssues.reporter.name.first + ' ' + bulk.driverIssues.reporter.name.last;
            }
            complaintsList.push({
                complaintId: bulk._id,
                complaintItemType: 'bulk',
                date: bulk.datePickedUp,
                unresolved: null, // No way to resolve atm
                issueDescription: issueDescription
                // issueImages: bulk.driverIssues.images
            });
        }
    });

    systemComplaints.forEach(complaint => {
        if (!_.isNil(unviewedComplaintIdsState) && !unviewedComplaintIdsState.includes(complaint._id)) {
            return;
        }

        let issueDescription = _.get(complaint, `description.${lang}`, 'N/A');

        if (_.isNil(issueDescription) || _.isEmpty(issueDescription)) {
            issueDescription = 'N/A';
        }

        if (
            !_.isNil(_.get(complaint, 'offender.name.first', null)) &&
            !_.isNil(_.get(complaint, 'offender.name.last', null))
        ) {
            issueDescription += ' - ' + complaint.offender.name.first + ' ' + complaint.offender.name.last;
        }
        complaintsList.push({
            complaintItemType: 'system',
            date: complaint.createdAt,
            unresolved: null, // No way to resolve atm
            issueDescription: issueDescription
        });
    });

    sortComplaints(complaintsList);

    return complaintsList;
};

const sortComplaints = complaintsList => {
    //sort by unresolved/resolved then by date

    complaintsList.sort((a, b) => {
        if (a.unresolved && !b.unresolved) {
            return -1;
        } else if (!a.unresolved && b.unresolved) {
            return 1;
        } else {
            if (a.date > b.date) {
                return -1;
            } else if (a.date < b.date) {
                return 1;
            } else {
                return 0;
            }
        }
    });
};
