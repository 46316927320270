import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getQuickDropName } from 'utils/misc';

import HttpContext from 'utils/contexts/HttpContext';
import { toDollars } from 'utils/misc';
import { _user } from 'std';
import moment from 'moment-timezone';

import { getSwitch, getTextInput, getCheckbox, getSelect, getTimePicker } from 'components/CRUDTables/helperFunctions';

import {
    Button,
    Paper,
    Typography,
    Grid,
    withTheme,
    CircularProgress,
    TextField,
    Icon,
    IconButton,
    Collapse,
    Dialog,
    colors,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Tooltip,
    InputAdornment,
    FormControlLabel,
    FormControl
} from '@material-ui/core';

import SocialShareInput from 'components/CRUDTables/Inputs/SocialShareInput';
import CharityTaskInput from 'components/CRUDTables/Inputs/CharityTaskInput';

import StringListInput from 'components/CustomInputs/StringListInput';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ObjectTextInput from 'components/CRUDTables/Inputs/ObjectTextInput';
import ComplaintsForm from 'components/CRUDTables/Forms/ComplaintsForm';
import useComplaintsForm from './hooks/useComplaintsForm';
import {
    TIPPING_MODEL_INDIVIDUAL,
    TIPPING_MODEL_POOL,
    RESIDENTIAL,
    COMMERCIAL,
    CONDO,
    maxFileSize
} from 'constants.js';

import { loc } from 'localizations/localizationHandler';

import { isAUSRegion } from 'utils/misc';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useRef } from 'react';
import { Switch } from '@material-ui/core';

import { lightTheme, darkTheme, adminTheme } from 'theme';
import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';
import { validate } from 'utils/validate';

import { AVAILABLE_LANGS } from '../../../constants';
import { useMemo } from 'react';

function Configuration(props) {
    const { theme, twoFactorAuthenticationWarning, operator } = props;

    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);
    const [configLoading, setConfigLoading] = useState(false);
    const [configUpdating, setConfigUpdating] = useState(false);
    const [config, setConfig] = useState({});
    const [localization, setLocalization] = useState({});
    const [errorDialogText, setErrorDialogText] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [allCommodities, setAllCommodities] = useState([]);
    const [quickDropPromo, setQuickDropPromo] = useState({});
    const [questionnaires, setQuestionnaires] = useState([]);
    const [colorOverrides, setColorOverrides] = useState({
        light: {
            primary: false,
            secondary: false
        },
        dark: {
            primary: false,
            secondary: false
        },
        admin: {
            primary: false,
            secondary: false
        }
    });
    const [dropLocationMarkerFile, setDropLocationMarkerFile] = useState(undefined);
    const [dropLocationMarkerHeight, setDropLocationMarkerHeight] = useState(64);
    const [dropLocationMarkerWidth, setDropLocationMarkerWidth] = useState(48);
    const [dropLocationImageError, setDropLocationImageError] = useState(false);

    const [charityMarkerFile, setCharityMarkerFile] = useState(undefined);
    const [charityMarkerHeight, setCharityMarkerHeight] = useState(64);
    const [charityMarkerWidth, setCharityMarkerWidth] = useState(48);
    const [charityImageError, setCharityImageError] = useState(false);

    const [brandLogoFile, setBrandLogoFile] = useState(undefined);
    const [brandImageError, setBrandImageError] = useState(false);

    const securityQuestionError = question => {
        if (question.length > 40) {
            //setSecurityQuestionErr("This must be 40 characters or less");
            return true;
        }
        const specialCharacters = ['-', '<', '>', '%', '{', '}', '[', ']', '&', '#', '\\', '“'];
        if (_.some(specialCharacters, char => question.includes(char))) {
            return true;
        }

        const specialStrings = ['http:', 'https:', 'javascript', 'function', 'return', 'www'];
        if (_.some(specialStrings, string => question.includes(string))) {
            return true;
        }
        return false;
    };

    const securityQuestionErrorMessage = question => {
        if (question.length > 40) {
            return 'This must be 40 characters or less';
        }
        const specialCharacters = ['-', '<', '>', '%', '{', '}', '[', ']', '&', '#', '\\', '"'];
        for (let char of specialCharacters) {
            if (question.includes(char)) return `The character ${char} is not allowed.`;
        }

        const specialStrings = ['http:', 'https:', 'javascript', 'function', 'return', 'www'];
        for (let specialString of specialStrings) {
            if (question.includes(specialString)) return `The word "${specialString}" is not allowed.`;
        }
        return false;
    };

    const imageFileTypes = [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'bmp',
        'ico',
        'cur',
        'tif'
    ];

    const {
        initialObj,
        complaintType,
        complaintFormOpen,
        setComplaintFormOpen,
        handleOpenComplaintsForm
    } = useComplaintsForm(_.get(config, 'complaints', {}));

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);

    const loadConfig = async () => {
        setConfigLoading(true);
        const res = await http.getJSON('/system/allConfigs');
        if (res.ok) {
            setConfig(res.data.config);
        }
        const res2 = await http.getJSON('/system/allLocalizations');
        if (res.ok) {
            setLocalization(res2.data.localization);
        }
        const res3 = await http.getJSON('/promos/quickDrop');
        if (res3.ok) {
            setQuickDropPromo(res3.data.promo);
        }

        setColorOverrides({
            light: {
                primary: !_.isNil(_.get(res.data.config, 'theme.light.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.light.secondary'))
            },
            dark: {
                primary: !_.isNil(_.get(res.data.config, 'theme.dark.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.dark.secondary'))
            },
            admin: {
                primary: !_.isNil(_.get(res.data.config, 'theme.admin.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.admin.secondary'))
            }
        });

        setConfigLoading(false);
    };
    const fetchCommodities = async () => {
        const commodityRes = await http.getJSON('/commodities/getAllEnabledCommodities');
        if (commodityRes.ok) {
            let newCommodityList = Array.from(commodityRes.data.commodities, commoditiy => {
                return commoditiy.skuType;
            });
            setAllCommodities(newCommodityList);
        }
    };
    const fetchQuestionnaires = async () => {
        const res = await http.getJSON('/questionnaires');
        if (res.ok) {
            setQuestionnaires(res.data.questionnaires);
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ..._.omit(config, 'expressSignIn', 'sync'),
            localization: {
                en: {
                    promoCodeCustomer: _.get(localization, `en.promoCodeCustomer`),
                    ratingDialogText: _.get(localization, `en.ratingDialogText`),
                    CRUDDefaults: _.get(localization, `en.CRUDDefaults`),
                    ratesPageConfig: _.get(localization, `en.ratesPageConfig`),
                    complaints: _.get(localization, `en.complaints`),
                    informationForms: _.get(localization, `en.informationForms`)
                },
                fr: {
                    promoCodeCustomer: _.get(localization, `fr.promoCodeCustomer`),
                    ratingDialogText: _.get(localization, `fr.ratingDialogText`),
                    CRUDDefaults: _.get(localization, `fr.CRUDDefaults`),
                    ratesPageConfig: _.get(localization, `fr.ratesPageConfig`),
                    complaints: _.get(localization, `fr.complaints`),
                    informationForms: _.get(localization, `fr.informationForms`)
                }
            },
            promoCodeCustomer: {
                ..._.get(config, 'promoCodeCustomer'),
                customerSplit: !_.isNil(_.get(config, 'promoCodeCustomer.customerSplit'))
                    ? _.get(config, 'promoCodeCustomer.customerSplit') * 100
                    : '',
                fixedRateKickback: !_.isNil(_.get(config, 'promoCodeCustomer.fixedRateKickback'))
                    ? toDollars(_.get(config, 'promoCodeCustomer.fixedRateKickback'))
                    : '',
                kickbackThreshold: !_.isNil(_.get(config, 'promoCodeCustomer.kickbackThreshold'))
                    ? toDollars(_.get(config, 'promoCodeCustomer.kickbackThreshold'))
                    : ''
            },
            charityTaxReceipts: {
                defaultMinTaxReceiptAmount: _.get(config, 'charityTaxReceipts.defaultMinTaxReceiptAmount', 2000) / 100
            },
            customerHistory: {
                allowViewReceipt: _.get(config, 'customerHistory.allowViewReceipt', false)
            },
            dropAndGo: {
                promoCode: _.get(config, 'dropAndGo.promoCode', ''),
                walkInPromoCode: _.get(config, 'dropAndGo.walkInPromoCode', ''),
                // displayQRCode: _.get(config, 'dropAndGo.displayQRCode', false),
                // allowRequestLabels: _.get(config, 'dropAndGo.allowRequestLabels', false),
                multiBagDrops: _.get(config, 'dropAndGo.multiBagDrops', false)
            },
            login: {
                showThirdPartyLogins: _.get(config, 'login.showThirdPartyLogins', true)
            },
            inactiveCustomerConfiguration: {
                firstMessage: _.get(config, 'inactiveCustomerConfiguration.firstMessage'),
                secondMessage: {
                    daysSinceFirst: _.get(config, 'inactiveCustomerConfiguration.secondMessage.daysSinceFirst'),
                    promo: _.get(config, 'inactiveCustomerConfiguration.secondMessage.promo.code'),
                    shortUrl: _.get(config, 'inactiveCustomerConfiguration.secondMessage.shortUrl')
                },
                thirdMessage: {
                    daysSinceFirst: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.daysSinceFirst'),
                    promo: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.promo.code'),
                    shortUrl: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.shortUrl')
                },
                staleCustomerThreshold: _.get(config, 'inactiveCustomerConfiguration.staleCustomerThreshold', 2 * 365)
            },
            adjustments: {
                maxDailyAmountPerUser: _.get(config, 'adjustments.maxDailyAmountPerUser', 0) / 100,
                enableAmountCapping: _.get(config, 'adjustments.enableAmountCapping', false)
            },
            counterScreen: {
                refreshInterval: _.get(config, 'counterScreen.refreshInterval', 0) / 1000
            },
            trips: {
                ..._.get(config, 'trips'),
                defaultEtaStartTime: _.get(
                    config,
                    'trips.defaultEtaStartTime',
                    moment()
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .startOf('day')
                        .add(8, 'hours')
                ),
                defaultEtaEndTime: _.get(
                    config,
                    'trips.defaultEtaEndTime',
                    moment()
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .startOf('day')
                        .add(17, 'hours')
                ),
                etaUpdateLimitThresholdPercentage: _.get(config, 'trips.etaUpdateLimitThresholdPercentage', 0) * 100,
                etaUpdateImprovementPercentage: _.get(config, 'trips.etaUpdateImprovementPercentage', 0) * 100
            },
            collectors: {
                defaultFixedCosts: _.get(config, 'collectors.defaultFixedCosts', [])
            },
            audit: {
                bagDifference: {
                    enabled: _.get(config, 'audit.bagDifference.enabled', false),
                    threshold: _.get(config, 'audit.bagDifference.threshold', 1),
                    enabledCommodities: _.get(config, 'audit.bagDifference.enabledCommodities', [])
                },
                unitDifference: {
                    enabled: _.get(config, 'audit.unitDifference.enabled', false),
                    percentThreshold: _.get(config, 'audit.unitDifference.percentThreshold', 5),
                    enabledCommodities: _.get(config, 'audit.unitDifference.enabledCommodities', [])
                },
                driverComplaint: {
                    enabled: _.get(config, 'audit.driverComplaint.enabled', false),
                    threshold: _.get(config, 'audit.driverComplaint.threshold', 1),
                    timeWindow: _.get(config, 'audit.driverComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.driverComplaint.enabledCommodities', [])
                },
                counterComplaint: {
                    enabled: _.get(config, 'audit.counterComplaint.enabled', false),
                    threshold: _.get(config, 'audit.counterComplaint.threshold', 3),
                    timeWindow: _.get(config, 'audit.counterComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.counterComplaint.enabledCommodities', [])
                },
                customerRedemptionEmail: {
                    enabled: _.get(config, 'audit.customerRedemptionEmail.enabled', false)
                },
                customerRedemptionAmount: {
                    enabled: _.get(config, 'audit.customerRedemptionAmount.enabled', false),
                    threshold: _.get(config, 'audit.customerRedemptionAmount.threshold', 1000),
                    timeWindow: _.get(config, 'audit.customerRedemptionAmount.timeWindow', 12)
                },
                ipConflict: {
                    enabled: _.get(config, 'audit.ipConflict.enabled', true),
                    excludePastIPs: _.get(config, 'audit.ipConflict.excludePastIPs', false),
                    compareAllUsers: _.get(config, 'audit.ipConflict.compareAllUsers', false)
                }
            },
            emailConfig: {
                enableCompletedPickupSurvey: _.get(config, 'emailConfig.enableCompletedPickupSurvey', false),
                completedPickupSurveyURL: _.get(config, 'emailConfig.completedPickupSurveyURL', ''),
                completedPickupSurveyLabel: _.get(config, 'emailConfig.completedPickupSurveyLabel', '')
                // showServiceFeeInEmailReceipts: _.get(config, 'emailConfig.showServiceFeeInEmailReceipts', true)
            },
            dashboard: {
                showDepot: _.get(config, 'dashboard.showDepot', true),
                showInvoicing: _.get(config, 'dashboard.showInvoicing', true),
                showChecksAndBalances: _.get(config, 'dashboard.showChecksAndBalances', true),
                showDrivers: _.get(config, 'dashboard.showDrivers', true),
                consolidateCommission: _.get(config, 'dashboard.consolidateCommission', false),
                showFivePercentRefund: _.get(config, 'dashboard.showFivePercentRefund', true),
                showTotalInactiveUsers: _.get(config, 'dashboard.showTotalInactiveUsers', true),
                showContractorCost: _.get(config, 'dashboard.showContractorCost', false),
                inactiveBeforeNumMonths: _.get(config, 'dashboard.inactiveBeforeNumMonths', 12)
            },
            menuOptions: {
                showDefaultCollectorOptions: _.get(config, 'menuOptions.showDefaultCollectorOptions', false),
                showAccounting: _.get(config, 'menuOptions.showAccounting', false),
                showQuickbooks: _.get(config, 'menuOptions.showQuickbooks', false),
                showReconciliation: _.get(config, 'menuOptions.showReconciliation', false),
                showAgingBalances: _.get(config, 'menuOptions.showAgingBalances', false)
            },
            quickDropPromo: {
                label: _.get(quickDropPromo, 'label'),
                description: _.get(quickDropPromo, 'description'),
                serviceFee: _.round((1 - _.get(quickDropPromo, 'variables.customerSplit', 0.95)) * 100, 2)
            },
            registration: {
                questionnaire: _.get(config, 'registration.questionnaire', ''),
                tagline: _.get(config, 'registration.tagline', ''),
                securityQuestions: _.get(config, 'registration.securityQuestions', ''),
                tooltips: _.get(config, 'registration.tooltips', ''),
                options: {
                    regCustomer: _.get(config, 'registration.options.regCustomer', false),
                    regBusiness: _.get(config, 'registration.options.regBusiness', false),
                    regOrganization: _.get(config, 'registration.options.regOrganization', false),
                    bottleDrive: _.get(config, 'registration.options.bottleDrive', false)
                },
                emailVerificationRequired: _.get(config, 'registration.emailVerificationRequired', false)
            },
            charityNameSimilarityThreshold: {
                charityNameSimilarityThreshold: _.get(
                    config,
                    'charityNameSimilarityThreshold.charityNameSimilarityThreshold',
                    0.8
                )
            },
            pickups: {
                ..._.get(config, 'pickups', {}),
                minFrequency: _.get(config, 'pickups.minFrequency', 1),
                minFrequencyPickupTypes: _.get(config, 'pickups.minFrequencyPickupTypes', []),
                minPickupFee: _.get(config, 'pickups.minPickupFee', 0) / 100
            },
            impactReport: {
                waterAmount: _.get(config, 'impactReport.waterAmount', 6.686),
                energyAmount: _.get(config, 'impactReport.energyAmount', 1.551),
                landfillAmount: _.get(config, 'impactReport.landfillAmount', 0.07),
                carbonAmount: _.get(config, 'impactReport.carbonAmount', 0.145),
                waterUnit: _.get(config, 'impactReport.waterUnit', 'Liters'),
                energyUnit: _.get(config, 'impactReport.energyUnit', 'Megajoules'),
                landfillUnit: _.get(config, 'impactReport.landfillUnit', 'Kgs'),
                carbonUnit: _.get(config, 'impactReport.carbonUnit', 'Kgs')
            },
            aiCounts: {
                quality: _.get(config, 'aiCounts.quality', 100),
                maxDimensions: _.get(config, 'aiCounts.maxDimensions', 0)
            },
            customerInvoicing: {
                defaultFeeInvoicePaymentWindow: isAUSRegion()
                    ? _.get(config, 'customerInvoicing.defaultFeeInvoicePaymentWindow', 30)
                    : null
            },
            theme: {
                light: {
                    primary: _.get(config, 'theme.light.primary'),
                    secondary: _.get(config, 'theme.light.secondary'),
                    env: _.get(config, 'theme.light.env')
                },
                dark: {
                    primary: _.get(config, 'theme.dark.primary'),
                    secondary: _.get(config, 'theme.dark.secondary'),
                    env: _.get(config, 'theme.dark.env')
                },
                admin: {
                    primary: _.get(config, 'theme.admin.primary'),
                    secondary: _.get(config, 'theme.admin.secondary'),
                    env: _.get(config, 'theme.admin.env')
                }
            },
            dropLocationMarker: {
                ..._.get(config, 'dropLocationMarker', {})
            },
            charityMarker: {
                ..._.get(config, 'charityMarker', {})
            },
            brandLogo: {
                ..._.get(config, 'brandLogo', {})
            }
            // imageConfig: {
            //     maxImageUploads: _.get(config, 'imageConfig.maxImageUploads', 5)
            // }
        },
        validationSchema: Yup.object({
            localization: Yup.object({
                [lang]: Yup.object({
                    promoCodeCustomer: Yup.object({
                        title: Yup.string().required('You must enter a value'),
                        text: Yup.string().required('You must enter a value'),
                        codeDisplayLabel: Yup.string().required('You must enter a value'),
                        referralURLDisplayLabel: Yup.string().required('You must enter a value'),
                        primaryActionLabel: Yup.string().required('You must enter a value'),
                        rulesDialogHandle: Yup.string().required('You must enter a value'),
                        rulesTitle: Yup.string().required('You must enter a value'),
                        rules: Yup.string().required('You must enter a value')
                    }),
                    ratingDialogText: Yup.object({
                        rateUsTitle: Yup.string().required('You must enter a value'),
                        rateUsContent: Yup.string().required('You must enter a value'),
                        thanksForRatingTitle: Yup.string().required('You must enter a value'),
                        thanksForRatingContent: Yup.string().required('You must enter a value'),
                        ratedUsBeforeContent: Yup.string().required('You must enter a value'),
                        ratedUsBeforeTitle: Yup.string().required('You must enter a value')
                    }),
                    complaints: Yup.object({
                        customerComplaints: Yup.object({
                            resolutions: Yup.array().of(
                                Yup.object().shape({
                                    code: Yup.string()
                                        .required('Code should be auto generated')
                                        .max(4, 'Must be less than 4 characters'),
                                    label: Yup.string()
                                        .required('Must include a label')
                                        .min(5, 'Must be more than 4 characters'),
                                    description: Yup.string().required(
                                        'Must include a description text for customer email'
                                    )
                                })
                            )
                        }),
                        counterComplaints: Yup.object({
                            options: Yup.array().of(
                                Yup.object().shape({
                                    code: Yup.string()
                                        .required('Code should be auto generated')
                                        .max(4, 'Must be less than 4 characters'),
                                    label: Yup.string()
                                        .required('Must include a label')
                                        .min(5, 'Must be more than 4 characters')
                                })
                            )
                        }),
                        driverComplaints: Yup.object({
                            options: Yup.array().of(
                                Yup.object().shape({
                                    code: Yup.string()
                                        .required('Code should be auto generated')
                                        .max(4, 'Must be less than 4 characters'),
                                    label: Yup.string()
                                        .required('Must include a label')
                                        .min(5, 'Must be more than 4 characters')
                                })
                            )
                        })
                    })
                })
            }),
            bulkCounter: Yup.object({
                hourWindow: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
            }),
            pickups: Yup.object({
                lowVolumeContainerThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                abortThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                postponeThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                afterCompleteDialogEnabled: Yup.boolean(),
                afterCompleteDialogTitle: Yup.string().required('You must enter a value'),
                afterCompleteDialogContent: Yup.string().required('You must enter a value'),
                afterCompleteDialogExamples: Yup.array()
                    .of(Yup.string())
                    .min(1, 'You must enter a value'),
                completePickupImageTitle: Yup.string().required('You must enter a value'),
                completePickupImageInstructions: Yup.array()
                    .of(Yup.string())
                    .min(1, 'You must enter a value'),
                abortPickupImageTitle: Yup.string().required('You must enter a value'),
                abortPickupImageInstructions: Yup.array()
                    .of(Yup.string())
                    .min(1, 'You must enter a value'),
                sendPickupCompleteEmail: Yup.boolean(),
                sendPickupCompleteAndRecurringEmail: Yup.boolean(),
                minPickupFee: Yup.number().min(0, 'This must be 0 or more'),
                minPickupFeeEnabled: Yup.boolean(),
                minFrequency: Yup.number().min(1, 'This must be 1 or more'),
                minFrequencyPickupTypes: Yup.array().of(Yup.string())
            }),
            trips: Yup.object({
                stopBaseDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                stopPerBagDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaOptimizationWindow: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateDataPointScaleValue: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateLimitThresholdPercentage: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateImprovementPercentage: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                dropOffBaseDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                dropOffPerBagDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaNotificationWindow: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                useEtaWindow: Yup.boolean(),
                maxCustomerEtaNotifications: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                customerEtaNotifications: Yup.boolean(),
                generalOffloadReminders: Yup.array()
                    .of(Yup.string())
                    .min(1, 'You must enter a value'),
                startingTripTasks: Yup.array()
                    .of(Yup.string())
                    .min(1, 'You must enter a value'),
                maxEarlyStartRouteTime: Yup.number().min(1, 'Must be greater than 0'),
                maxDistanceFromStartLocation: Yup.number().min(0, 'Must be greater than 0'),
                lastTripOffloadReminders: Yup.array().of(Yup.string()),
                maxOffloadTime: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                // maxStartTripTime: Yup.number().min(1, 'Must be greater than 0'),
                clusterTimeOfDayPreferences: Yup.boolean(),
                clusterZones: Yup.boolean(),
                prioritizeRescheduled: Yup.boolean(),
                capPickups: Yup.boolean(),
                bagEstimateOOSStop: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                warningDistanceFromDepot: Yup.number().min(1, 'Must be greater than 0'),
                maxGasPayout: Yup.number().min(1, 'Must be greater than 0')
            }),
            twoFactorAuthentication: Yup.object({
                limitCodeRequests: Yup.boolean(),
                limitAttempts: Yup.boolean(),
                maxCodeRequests: Yup.number().when('limitCodeRequests', {
                    is: value => value === true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                maxAuthenticationAttempts: Yup.number().when('limitAttempts', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                minutesToLockAccount: Yup.number().when(['limitAttempts', 'limitCodeRequests'], {
                    is: (limitAttempts, limitCodeRequests) => {
                        return limitAttempts || limitCodeRequests;
                    },
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                secondsBetweenCodeRequests: Yup.number().min(0, 'Must be zero or more')
            }),
            driverTipping: Yup.object({
                enableDriverTipping: Yup.boolean(),
                tippingModel: Yup.string()
            }),
            serviceableAreaLink: Yup.object({
                isEnabled: Yup.boolean(),
                link: Yup.string().required('You must enter a value'),
                text: Yup.string().required('You must enter a value')
            }),
            redemptionOptions: Yup.object({
                charity: Yup.object({
                    methods: Yup.object({
                        directDeposit: Yup.boolean(),
                        cheque: Yup.boolean(),
                        etransfer: Yup.boolean(),
                        cash: Yup.boolean(),
                        bankTransfer: Yup.boolean()
                    }),
                    enableEtransferLimit: Yup.boolean(),
                    chequeMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    bankTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    eTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    etransferLimit: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    cashMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more')
                }),
                customer: Yup.object({
                    methods: Yup.object({
                        directDeposit: Yup.boolean(),
                        cheque: Yup.boolean(),
                        etransfer: Yup.boolean(),
                        cash: Yup.boolean(),
                        bankTransfer: Yup.boolean()
                    }),
                    enableEtransferLimit: Yup.boolean(),
                    chequeMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    bankTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    eTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    etransferLimit: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    cashMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more')
                }),
                redemptionFee: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                securityQuestions: Yup.object().required('You must enter a value')
            }),
            intuit: Yup.object({
                company_id: Yup.string().required('You must enter a value'),
                accounts: Yup.object({
                    depositsReceivable: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    customerLiability: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    serviceIncome: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    taxLiability: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    referrals: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    serviceFees: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    redemptionsPayment: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    adSpend: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    })
                })
            }),
            inactiveCustomerConfiguration: Yup.object({
                firstMessage: Yup.object({
                    maxCustomers: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    registeredNoPickup: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    lastPickup: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                secondMessage: Yup.object({
                    daysSinceFirst: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    promo: Yup.string().required('You must enter a value'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                thirdMessage: Yup.object({
                    daysSinceFirst: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    promo: Yup.string().required('You must enter a value'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                staleCustomerThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must by greater than 0')
            }),
            charityTaskConfiguration: Yup.object({
                minimumVerifiedTasksForFeatured: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
            }),
            sendGridConfiguration: Yup.object({
                templateIds: Yup.object({
                    inactiveFirstMessage: Yup.string().required('You must enter a value'),
                    inactiveSecondMessage: Yup.string().required('You must enter a value'),
                    inactiveThirdMessage: Yup.string().required('You must enter a value'),
                    notFeaturedReminder: Yup.string().required('You must enter a value'),
                    unfeaturedCharity: Yup.string().required('You must enter a value'),
                    featuredCharityEmail: Yup.string().required('You must enter a value'),
                    expiredCharityTask: Yup.string().required('You must enter a value')
                }),
                unsubscribeGroups: Yup.object({
                    inactiveCustomers: Yup.string().required('You must enter a value'),
                    charities: Yup.string().required('You must enter a value')
                })
            }),
            shareAndFeedbackDialogConfig: Yup.object({
                ratingScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                }),
                referralScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                }),
                shareScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                })
            }),
            promoCodeCustomer: Yup.object({
                customerSplit: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
                    .max(100, 'This cannot be more than 100%'),
                fixedRateKickback: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive'),
                kickbackThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
            }),
            googleApi: Yup.object({
                mapsCoordsKey: Yup.string().required('You must enter a value')
            }),
            defaultCoordinates: Yup.object({
                latitude: Yup.number()
                    .required('You must enter a value')
                    .min(-90, 'Cannot be less than -90')
                    .max(90, 'Cannot be greater than 90'),
                longitude: Yup.number()
                    .required('You must enter a value')
                    .min(-180, 'Cannot be less than -180')
                    .max(180, 'Cannot be greater than 180')
            }),
            charityTaxReceipts: Yup.object({
                defaultMinTaxReceiptAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be greater than zero')
            }),
            emailAddresses: Yup.object({
                service: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address'),
                payments: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address'),
                orders: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address')
            }),
            phoneNumbers: Yup.object({
                service: Yup.string().test('validPhoneNumber', 'Phone number invalid', value => {
                    if (!value) return false;
                    let errorsObj = validate(['phone'], value, lang);
                    return !errorsObj.fail;
                })
            }),
            complaints: Yup.object({
                customerComplaints: Yup.object({
                    resolutions: Yup.array().of(
                        Yup.object().shape({
                            code: Yup.string()
                                .required('Code should be auto generated')
                                .max(4, 'Must be less than 4 characters'),
                            label: Yup.string(),
                            description: Yup.string(),
                            emailToCustomer: Yup.boolean()
                        })
                    )
                }),
                counterComplaints: Yup.object({
                    options: Yup.array().of(
                        Yup.object().shape({
                            code: Yup.string()
                                .required('Code should be auto generated')
                                .max(4, 'Must be less than 4 characters'),
                            label: Yup.string(),
                            emailToCustomer: Yup.boolean()
                        })
                    )
                }),
                driverComplaints: Yup.object({
                    options: Yup.array().of(
                        Yup.object().shape({
                            code: Yup.string()
                                .required('Code should be auto generated')
                                .max(4, 'Must be less than 4 characters'),
                            label: Yup.string(),
                            emailToCustomer: Yup.boolean()
                        })
                    )
                })
            }),
            counterScreen: Yup.object({
                refreshInterval: Yup.number().test('min-5', 'Must be minimum 5 seconds', value => {
                    return value === 0 || value === undefined || value >= 5;
                })
            }),
            audit: Yup.object({
                bagDifference: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                unitDifference: Yup.object({
                    percentThreshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                driverComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                counterComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                customerRedemptionAmount: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                })
            }),
            dashboard: Yup.object({
                inactiveBeforeNumMonths: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
            }),
            quickDropPromo: Yup.object({
                label: Yup.string().required('You must enter a value'),
                description: Yup.string().required('You must enter a value'),
                serviceFee: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
                    .max(100, 'This must be 100 or less')
            }),
            registration: Yup.object({
                questionnaire: Yup.string(),
                tagline: Yup.object().required('You must enter a value'),
                options: Yup.object()
            }),
            charityNameSimilarityThreshold: Yup.object({
                charityNameSimilarityThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
                    .max(1, 'This must be 1 or less')
            }),
            impactReport: Yup.object({
                waterAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more'),
                energyAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more'),
                landfillAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more'),
                carbonAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more'),
                waterUnit: Yup.string().required('You must enter a value'),
                energyUnit: Yup.string().required('You must enter a value'),
                landfillUnit: Yup.string().required('You must enter a value'),
                carbonUnit: Yup.string().required('You must enter a value')
            }),
            aiCounts: Yup.object({
                quality: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'This must be 1 or more'),
                maxDimensions: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
            }),
            customerInvoicing: Yup.object({
                defaultFeeInvoicePaymentWindow: isAUSRegion() ? Yup.number().min(1, 'Must be 1 or more') : null
            })
            // dropAndGo: Yup.object({
            //     promoCode: Yup.string().required('You must enter a value (ex. DROP&GO)'),
            //     walkInPromoCode: Yup.string().required('You must enter a value (ex. WALKIN)')
            // })
            // imageConfig: Yup.object({
            //     maxImageUploads: Yup.number()
            //         .required('You must enter a value')
            //         .min(0, 'This must be positive')
            // })
        }),
        onSubmit: async values => {
            // Update counter complaint variables according to changes
            const counterComplaints = values.complaints.counterComplaints;
            counterComplaints.options.forEach(option => {
                if (option.driverIssue) {
                    option.emailToCustomer = false;
                }
            });

            values.theme.light.primary = colorOverrides.light.primary ? values.theme.light.primary : undefined;
            values.theme.light.secondary = colorOverrides.light.secondary ? values.theme.light.secondary : undefined;
            values.theme.dark.primary = colorOverrides.dark.primary ? values.theme.dark.primary : undefined;
            values.theme.dark.secondary = colorOverrides.dark.secondary ? values.theme.dark.secondary : undefined;
            values.theme.admin.primary = colorOverrides.admin.primary ? values.theme.admin.primary : undefined;
            values.theme.admin.secondary = colorOverrides.admin.secondary ? values.theme.admin.secondary : undefined;

            //convert to ratio
            values.promoCodeCustomer.customerSplit /= 100;

            //convert to cents
            values.promoCodeCustomer.fixedRateKickback *= 100;
            values.promoCodeCustomer.kickbackThreshold *= 100;
            values.pickups.minPickupFee *= 100;
            values.charityTaxReceipts.defaultMinTaxReceiptAmount *= 100;
            values.adjustments.maxDailyAmountPerUser *= 100;
            values.counterScreen.refreshInterval *= 1000;
            values.trips.etaUpdateLimitThresholdPercentage /= 100;
            values.trips.etaUpdateImprovementPercentage /= 100;

            values.dropLocationMarker.height = dropLocationMarkerHeight;
            values.dropLocationMarker.width = dropLocationMarkerWidth;
            values.charityMarker.height = charityMarkerHeight;
            values.charityMarker.width = charityMarkerWidth;

            const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
            if (!_.isNil(dropLocationMarkerFile)) {
                formData.append('dropLocationMarkerFile', dropLocationMarkerFile); //will show up in req.files
            }
            if (!_.isNil(charityMarkerFile)) {
                formData.append('charityMarkerFile', charityMarkerFile); //will show up in req.files
            }
            if (!_.isNil(brandLogoFile)) {
                formData.append('brandLogoFile', brandLogoFile); //will show up in req.files
            }
            formData.append('form', JSON.stringify(_.omit(values, 'quickDropPromo')));

            setConfigUpdating(true);
            const configRes = await http.postJSON('/system/updateConfigs', formData, true, true);
            if (configRes.ok) {
                setConfig(configRes.data.config);
                setDropLocationMarkerHeight(_.get(configRes.data.config, 'dropLocationMarker.height', 64));
                setDropLocationMarkerWidth(_.get(configRes.data.config, 'dropLocationMarker.width', 48));
                setCharityMarkerHeight(_.get(configRes.data.config, 'charityMarker.height', 64));
                setCharityMarkerWidth(_.get(configRes.data.config, 'charityMarker.width', 48));
            } else {
                onSnackbar(configRes.errorMessage, 'error');
                setConfigUpdating(false);
                return;
            }

            const localizationRes = await http.postJSON(
                '/system/updateLocalizations',
                { localization: values.localization },
                true
            );
            if (localizationRes.ok) {
                setLocalization(localizationRes.data.localization);
            } else {
                onSnackbar('Failed to update localizations.', 'error');
                setConfigUpdating(false);
                return;
            }

            const promoRes = await http.postJSON('/promos/quickDrop/update', { promo: values.quickDropPromo }, true);
            if (promoRes.ok) {
                onSnackbar('All values updated successfully');
                setQuickDropPromo(promoRes.data.promo);
            } else {
                onSnackbar(
                    `Failed to update ${getQuickDropName('en')} promo. All other configs have been saved`,
                    'error'
                );
            }
            setConfigUpdating(false);
        }
    });

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue(`theme.${e.target.name}`, e.target.value);
            }
        }, 100)
    );
    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik, e.target.name);
    };

    const handleColorOverride = (name, checked) => {
        let colorOverridesCopy = _.cloneDeep(colorOverrides);
        _.set(colorOverridesCopy, name, checked);
        setColorOverrides(colorOverridesCopy);
    };

    useEffect(() => {
        loadConfig();
        fetchCommodities();
        fetchQuestionnaires();
    }, []);

    useEffect(() => {
        formik.handleReset();
    }, [config]);

    useEffect(() => {
        resetLocalizationValues();
    }, [lang]);

    const handleDragEndCustomer = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.customerComplaints.resolutions`);
        // let complaintsArray = config.complaints.customerComplaints.resolutions;
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, resolution => resolution.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(config, `complaints.customerComplaints.resolutions`, newComplaints);
        _.set(formik.values, `localization.${lang}.complaints.customerComplaints.resolutions`, newComplaints);
    };
    const handleDragEndCounter = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.counterComplaints.options`);
        // let complaintsArray = config.complaints.counterComplaints.options;
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, option => option.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(config, `complaints.counterComplaints.options`, newComplaints);
        _.set(formik.values, `localization.${lang}.complaints.counterComplaints.options`, newComplaints);
    };
    const handleDragEndDriver = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.driverComplaints.options`);
        // let complaintsArray = config.complaints.driverComplaints.options;
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, option => option.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(config, `complaints.driverComplaints.options`, newComplaints);
        _.set(formik.values, `localization.${lang}.complaints.driverComplaints.options`, newComplaints);
    };

    useEffect(() => {
        if (!formik.isSubmitting && !formik.isValid) {
            let errorText = '';
            let errors = flattenObject(formik.errors);
            let errorCount = 0;
            for (let key in errors) {
                errorText += `${errorCount > 0 ? ', ' : ''}${key}: ${errors[key]}`;
                errorCount++;
                _.set(formik.touched, key, true);
            }
            if (errorCount > 3) {
                setErrorDialogText(errorText);
                setErrorDialogOpen(true);
            } else if (errorCount > 0) {
                onSnackbar(errorText, 'error');
            }
        }
    }, [formik.isSubmitting]);

    const resetLocalizationValues = () => {
        formik.setFieldValue('localization', {
            en: {
                promoCodeCustomer: _.get(localization, `en.promoCodeCustomer`),
                ratingDialogText: _.get(localization, `en.ratingDialogText`),
                CRUDDefaults: _.get(localization, `en.CRUDDefaults`),
                ratesPageConfig: _.get(localization, `en.ratesPageConfig`),
                complaints: _.get(localization, `en.complaints`),
                informationForms: _.get(localization, `en.informationForms`)
            },
            fr: {
                promoCodeCustomer: _.get(localization, `fr.promoCodeCustomer`),
                ratingDialogText: _.get(localization, `fr.ratingDialogText`),
                CRUDDefaults: _.get(localization, `fr.CRUDDefaults`),
                ratesPageConfig: _.get(localization, `fr.ratesPageConfig`),
                complaints: _.get(localization, `fr.complaints`),
                informationForms: _.get(localization, `fr.informationForms`)
            }
        });
    };

    const handleRecoverGPSOption = () => {
        let length = _.get(localization, 'en.complaints.driverComplaints.options', []).length;
        let DefaultGPSOption = {
            code: 'D6',
            label: 'GPS location inaccurate',
            suggestions: [
                'Please confirm your address and send pin location using your favorite maps application to {{serviceEmail}}.',
                'The driver had difficulties locating your location due to a google maps GPS error.'
            ],
            emailToCustomer: true
        };
        formik.setFieldValue(`localization.en.complaints.driverComplaints.options.[${length}]`, DefaultGPSOption);
        formik.setFieldValue(`localization.fr.complaints.driverComplaints.options.[${length}]`, DefaultGPSOption);
        formik.handleSubmit();
    };

    const isMissingGPSOption =
        _.get(localization, 'en.complaints.driverComplaints.options', []).findIndex(option => option.code === 'D6') ===
        -1;

    const handleDropLocationMarkerFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                setDropLocationMarkerHeight(image.height);
                setDropLocationMarkerWidth(image.width);
                _.set(formik.values, 'dropLocationMarker.height', image.height);
                _.set(formik.values, 'dropLocationMarker.width', image.width);
            };
            setDropLocationMarkerFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleCharityMarkerFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                setCharityMarkerHeight(image.height);
                setCharityMarkerWidth(image.width);
                _.set(formik.values, 'charityMarker.height', image.height);
                _.set(formik.values, 'charityMarker.width', image.width);
            };
            setCharityMarkerFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleBrandLogoFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            setBrandLogoFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    return (
        <div style={{ padding: theme.spacing.unit * 2 }}>
            <div style={{ paddingTop: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={lang}
                    exclusive
                    onChange={(e, lang) => {
                        if (!_.isEmpty(lang)) setLang(lang);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} value="en">
                        English
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="fr">
                        French
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {configLoading ? (
                <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                    <div
                        style={{
                            margin: theme.spacing.unit * 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </div>
                </Paper>
            ) : (
                <>
                    <Grid container spacing={theme.spacing.unit}>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Contact"
                            icon="contact_phone"
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                            error={
                                (_.get(formik.touched, 'emailAddresses', false) &&
                                    _.get(formik.errors, 'emailAddresses', false)) ||
                                (_.get(formik.touched, 'phoneNumbers', false) &&
                                    _.get(formik.errors, 'phoneNumbers', false))
                            }
                        >
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Contact Emails
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'emailAddresses.service',
                                    'Service Email',
                                    formik,
                                    'text',
                                    getInfoIcon('Service Email', 'Email for handling general customer concerns', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'emailAddresses.payments',
                                    'Payments Email',
                                    formik,
                                    'text',
                                    getInfoIcon('Payments Email', 'Email for handling customer payment concerns', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'emailAddresses.orders',
                                    'Orders Email',
                                    formik,
                                    'text',
                                    getInfoIcon('Orders Email', 'Email for handling customer counts concerns', theme)
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Contact Phone
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'phoneNumbers.service',
                                    'Service Phone Number',
                                    formik,
                                    'tel',
                                    getInfoIcon(
                                        'Service Phone Number',
                                        'Phone number for handling customer counts concerns',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Email"
                            icon="email"
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                            error={
                                (_.get(formik.touched, 'sendGridConfiguration', false) &&
                                    _.get(formik.errors, 'sendGridConfiguration', false)) ||
                                (_.get(formik.touched, 'inactiveCustomerConfiguration.firstMessage', false) &&
                                    _.get(formik.errors, 'inactiveCustomerConfiguration.firstMessage', false)) ||
                                (_.get(formik.touched, 'inactiveCustomerConfiguration.secondMessage', false) &&
                                    _.get(formik.errors, 'inactiveCustomerConfiguration.secondMessage', false)) ||
                                (_.get(formik.touched, 'inactiveCustomerConfiguration.thirdMessage', false) &&
                                    _.get(formik.errors, 'inactiveCustomerConfiguration.thirdMessage', false))
                            }
                        >
                            <Grid item md={4} xs={12}>
                                <StringListInput
                                    label="Finance Report Recipient Emails"
                                    values={_.get(formik.values, 'emailAddresses.financeReportRecipients')}
                                    onChange={values =>
                                        formik.setFieldValue('emailAddresses.financeReportRecipients', values)
                                    }
                                    error={email => {
                                        let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                        return !(email === '') && !regexEmail.test(email.toLowerCase());
                                    }}
                                    errorMessage="Please enter a valid email address."
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    infoText="Email list for receiving monthly financial report"
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    SendGrid Configuration
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Template Ids
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.inactiveFirstMessage',
                                    'Inactive First Email',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Inactive First Email',
                                        'Template ID of first email that is sent to inactive customers',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.inactiveSecondMessage',
                                    'Inactive Second Email',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Inactive Second Email',
                                        'Template ID of second email that is sent to inactive customers',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.inactiveThirdMessage',
                                    'Inactive Third Email',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Inactive Third Email',
                                        'Template ID of third email that is sent to inactive customers',
                                        theme
                                    )
                                )}

                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.notFeaturedReminder',
                                    'Not Featured Reminder',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Not Featured Reminder',
                                        `Template ID of email reminding organizations that they are not featured`,
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.unfeaturedCharity',
                                    'Unfeatured Email',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Unfeatured Email',
                                        'Template ID of email informing organizations that they are no longer featured',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.featuredCharityEmail',
                                    'Featured Email',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Featured Email',
                                        'Template ID of email informing organizations that they have become featured',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.templateIds.expiredCharityTask',
                                    'Expired Task',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Expired Task',
                                        'Template ID of email informing organizations that a task has expired',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Unsubscribe Groups
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.unsubscribeGroups.inactiveCustomers',
                                    'Inactive Customers',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Inactive Customers',
                                        'Email for handling general contact reasons',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'sendGridConfiguration.unsubscribeGroups.charities',
                                    'Charities',
                                    formik,
                                    'text',
                                    getInfoIcon('Charities', 'Email for handling general contact reasons', theme)
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Inactive Mailout Configuration
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    First Email
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.firstMessage.maxCustomers',
                                    'Max Customers',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max Customers',
                                        'Maximum number of inactive customers to email at once',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.firstMessage.registeredNoPickup',
                                    'Registered N Days Ago And No Pickup',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Registered N Days Ago And No Pickup',
                                        'Number of days without a pickup before sending an inactive email',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.firstMessage.lastPickup',
                                    'Last Pickup N Days Ago',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Last Pickup N Days Ago',
                                        'Number of days since last pickup before sending an inactive email',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.firstMessage.shortUrl',
                                    'Url',
                                    formik,
                                    'text',
                                    getInfoIcon('Url', 'Link to include in first inactive email', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Second Email
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.secondMessage.daysSinceFirst',
                                    'Days Since First Message',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Days Since First Message',
                                        'Number of days since first inactive email before sending second email',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.secondMessage.promo',
                                    'Promo',
                                    formik,
                                    'text',
                                    getInfoIcon('Promo', 'Promo to include in second email', theme)
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.secondMessage.shortUrl',
                                    'Url',
                                    formik,
                                    'text',
                                    getInfoIcon('Url', 'Link to include in second email', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Third Email
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.thirdMessage.daysSinceFirst',
                                    'Days Since First Message',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Days Since First Message',
                                        'Number of days since first inactive email before sending third email',
                                        theme
                                    )
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.thirdMessage.promo',
                                    'Promo',
                                    formik,
                                    'text',
                                    getInfoIcon('Promo', 'Promo to include in third email', theme)
                                )}
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.thirdMessage.shortUrl',
                                    'Url',
                                    formik,
                                    'text',
                                    getInfoIcon('Url', 'Link to include in third email', theme)
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Completed Pickup Emails
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'pickups.sendPickupCompleteEmail',
                                    'Send Pickup Completed Email',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Send emails to customers informing them that their pickup has been completed'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'pickups.sendPickupCompleteAndRecurringEmail',
                                    'Send Recurring Pickup Completed Email',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Send emails to customers informing them that their pickup has been completed and a new pickup has been scheduled'
                                )}
                            </Grid>
                            {/* <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Completed Counts Emails
                                </Typography>
                            </Grid> */}
                            {/* <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'emailConfig.showServiceFeeInEmailReceipts',
                                    'Show service fees in email receipts',
                                    formik
                                )}
                            </Grid> */}

                            {isAUSRegion() && (
                                <>
                                    <Grid xs={12}>
                                        <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Completed Pickup Survey
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {getSwitch(
                                            theme,
                                            'emailConfig.enableCompletedPickupSurvey',
                                            'Show Survey On Completed Pickup Emails',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Include a link to a customer survey in the pickup completed email'
                                        )}
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        {getTextInput(
                                            theme,
                                            'emailConfig.completedPickupSurveyLabel',
                                            'Survey Button Label',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Survey Button Label',
                                                'Label shown on customer survey button',
                                                theme
                                            )
                                        )}
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        {getTextInput(
                                            theme,
                                            'emailConfig.completedPickupSurveyURL',
                                            'Survey URL',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Survey URL',
                                                'Url to direct customer to complete survey',
                                                theme
                                            )
                                        )}
                                    </Grid>
                                </>
                            )}
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Pickups"
                            icon="local_shipping"
                            error={_.get(formik.touched, 'pickups', false) && _.get(formik.errors, 'pickups', false)}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'pickups.lowVolumeContainerThreshold',
                                    'Low Volume Container Threshold',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Low Volume Container Threshold',
                                        'Minimum intended number of containers to collect per pickup',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'pickups.abortThreshold',
                                    'Abort Threshold',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Abort Threshold',
                                        'Number of times a pickup can be aborted before being cancelled',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'pickups.postponeThreshold',
                                    'Postpone Threshold',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Postpone Threshold',
                                        'Number of times a pickup can be postponed before being cancelled',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getSwitch(theme, 'pickups.minPickupFeeEnabled', 'Minimum Pickup Fee Enabled', formik)}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'pickups.minPickupFee',
                                    'Minimum Pickup Fee ($)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Minimum Pickup Fee',
                                        'Fee applied to pickups under the threshold',
                                        theme
                                    ),
                                    {},
                                    null,
                                    !_.get(formik.values, 'pickups.minPickupFeeEnabled', false)
                                )}
                            </Grid>
                            <Grid xs={12} />
                            <Grid item md={4} xs={12}>
                                {getSelect(
                                    theme,
                                    'pickups.minFrequencyPickupTypes',
                                    'Min. Frequency Pickup Types',
                                    [RESIDENTIAL, COMMERCIAL, CONDO],
                                    formik,
                                    null,
                                    null,
                                    null,
                                    true
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'pickups.minFrequency',
                                    'Minimum Pickup Frequency (# weeks)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Minimum Pickup Frequency',
                                        'Minimum recurring pickup frequency in number of weeks (1 means no minimum)',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Driver Reminders
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {getSwitch(
                                        theme,
                                        'pickups.afterCompleteDialogEnabled',
                                        'Enable Driver Reminder Dialog After Pickup',
                                        formik,
                                        {},
                                        {},
                                        undefined,
                                        'Display a configurable reminder dialog to the driver after they complete each pickup'
                                    )}
                                    {getTextInput(
                                        theme,
                                        'pickups.afterCompleteDialogTitle',
                                        'After Complete Dialog Title',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'After Complete Dialog Title',
                                            'Title of the reminder dialog that is shown to drivers after completing a pickup',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'pickups.afterCompleteDialogContent',
                                        'After Complete Dialog Content',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'After Complete Dialog Content',
                                            'Text of the reminder dialog that is shown to drivers after completing a pickup',
                                            theme
                                        )
                                    )}
                                    <StringListInput
                                        label="After Complete Dialog Examples"
                                        values={_.get(formik.values, 'pickups.afterCompleteDialogExamples')}
                                        onChange={values =>
                                            formik.setFieldValue('pickups.afterCompleteDialogExamples', values)
                                        }
                                        error={inputValue => {
                                            return (
                                                _.isEmpty(inputValue) &&
                                                _.get(formik.errors, 'pickups.afterCompleteDialogExamples', false)
                                            );
                                        }}
                                        errorMessage={
                                            _.get(formik.errors, 'pickups.afterCompleteDialogExamples', false)
                                                ? _.get(formik.errors, 'pickups.afterCompleteDialogExamples', '123')
                                                : '321'
                                        }
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        longStringFormat
                                        infoText="List of examples to include in the reminder dialog that is shown to drivers after completing a pickup"
                                    />
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {getTextInput(
                                        theme,
                                        'pickups.completePickupImageTitle',
                                        'Complete Pickup Picture Title',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'Complete Pickup Picture Title',
                                            'Title of screen that prompts driver to capture an image when completing a pickup',
                                            theme
                                        )
                                    )}
                                    <StringListInput
                                        label="Complete Pickup Picture Instructions"
                                        values={_.get(formik.values, 'pickups.completePickupImageInstructions')}
                                        onChange={values =>
                                            formik.setFieldValue('pickups.completePickupImageInstructions', values)
                                        }
                                        error={inputValue => {
                                            return (
                                                _.isEmpty(inputValue) &&
                                                _.get(formik.errors, 'pickups.completePickupImageInstructions', false)
                                            );
                                        }}
                                        errorMessage={
                                            _.get(formik.errors, 'pickups.completePickupImageInstructions', false)
                                                ? _.get(formik.errors, 'pickups.completePickupImageInstructions', '123')
                                                : '321'
                                        }
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        longStringFormat
                                        infoText="List of instructions for image capture when completing a pickup"
                                    />
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {getTextInput(
                                        theme,
                                        'pickups.abortPickupImageTitle',
                                        'Abort Pickup Picture Title',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'Abort Pickup Picture Title',
                                            'Title of screen that prompts driver to capture an image when aborting a pickup',
                                            theme
                                        )
                                    )}
                                    <StringListInput
                                        label="Abort Pickup Picture Instructions"
                                        values={_.get(formik.values, 'pickups.abortPickupImageInstructions')}
                                        onChange={values =>
                                            formik.setFieldValue('pickups.abortPickupImageInstructions', values)
                                        }
                                        error={inputValue => {
                                            return (
                                                _.isEmpty(inputValue) &&
                                                _.get(formik.errors, 'pickups.abortPickupImageInstructions', false)
                                            );
                                        }}
                                        errorMessage={
                                            _.get(formik.errors, 'pickups.abortPickupImageInstructions', false)
                                                ? _.get(formik.errors, 'pickups.abortPickupImageInstructions', '123')
                                                : '321'
                                        }
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        longStringFormat
                                        infoText="List of instructions for image capture when aborting a pickup"
                                    />
                                </div>
                            </Grid>
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Trips"
                            icon="route"
                            error={_.get(formik.touched, 'trips', false) && _.get(formik.errors, 'trips', false)}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid xs={12}>
                                <Typography variant="h6">ETA Variables</Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.stopBaseDuration',
                                    'Default ETA Pickup Base Duration (sec)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Default ETA Pickup Base Duration',
                                        'Default estimated base stop duration per pickup used to calculate ETA values',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.stopPerBagDuration',
                                    'Default ETA Pickup Per Bag Duration (sec/bag)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Default ETA Pickup Per Bag Duration',
                                        'Default estimated per bag time used to calculate ETA values',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.dropOffBaseDuration',
                                    'ETA Drop off Base Duration (sec)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'ETA Drop off Base Duration',
                                        'Default estimated base duration for unloading bags at the depot',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.dropOffPerBagDuration',
                                    'ETA Drop off Per Bag Duration (sec/bag)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'ETA Drop off Per Bag Duration',
                                        'Default estimated per bag time for unloading bags at the depot',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.etaOptimizationWindow',
                                    'ETA Variable Moving Average Window (months)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'ETA Variable Moving Average Window',
                                        'Period of time to include when attempting to calculate optimal ETA variables for each driver',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.etaUpdateDataPointScaleValue',
                                    'Min Data Points for Full ETA Update',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Min Data Points for Full ETA Update',
                                        'Minimum number of pickup data points needed for reliable ETA variable optimization',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.etaUpdateLimitThresholdPercentage',
                                    'Threshold Change to Limit ETA Update (%)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Threshold Change to Limit ETA Update',
                                        'Threshold of change beyond which the new ETA variable are limited by the number of available data points',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.etaUpdateImprovementPercentage',
                                    'ETA Target Reduction (%)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'ETA Target Reduction',
                                        'Fixed percent reduction in ETA optimization to encourage faster collection times',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.segregateETAVariablesByZone',
                                    'Segregate ETA Variables By Zone',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Segregate pickup times by zone in order to calculate optimal per zone ETA variable values'
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6">ETA Notifications</Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.etaNotificationWindow',
                                    'Pickup ETA Window (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Pickup ETA Window',
                                        'Window of time on either side of calculated ETA shown to customer',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'trips.useEtaWindow',
                                    'Use ETA Window in Customer Communication',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Show customer ETA window instead of specific calculated ETA'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxCustomerEtaNotifications',
                                    'Max ETA Update Notifications',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max ETA Update Notifications',
                                        'Maximum number of times to notify a customer about changes to their ETA',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'trips.customerEtaNotifications',
                                    'Send ETA Updates',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Notify customer when their ETA changes'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTimePicker(
                                    theme,
                                    'trips.defaultEtaStartTime',
                                    'Default ETA Start Hour',
                                    formik,
                                    moment(formik.values.trips.defaultEtaStartTime).tz(
                                        process.env.REACT_APP_REGION_TIMEZONE
                                    ),
                                    time => {
                                        const hour = time.get('hour');
                                        const minute = time.get('minute');
                                        formik.setFieldValue(
                                            'trips.defaultEtaStartTime',
                                            moment()
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .set({ hour, minute })
                                                .toDate()
                                        );
                                    }
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTimePicker(
                                    theme,
                                    'trips.defaultEtaEndTime',
                                    'Default ETA End Hour',
                                    formik,
                                    moment(formik.values.trips.defaultEtaEndTime).tz(
                                        process.env.REACT_APP_REGION_TIMEZONE
                                    ),
                                    time => {
                                        const hour = time.get('hour');
                                        const minute = time.get('minute');
                                        formik.setFieldValue(
                                            'trips.defaultEtaEndTime',
                                            moment()
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .set({ hour, minute })
                                                .toDate()
                                        );
                                    }
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6">Clock out Options</Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <StringListInput
                                    label="General Offload Instructions"
                                    values={_.get(formik.values, 'trips.generalOffloadReminders')}
                                    onChange={values => formik.setFieldValue('trips.generalOffloadReminders', values)}
                                    error={inputValue => {
                                        return (
                                            _.isEmpty(inputValue) &&
                                            _.get(formik.errors, 'trips.generalOffloadReminders', false)
                                        );
                                    }}
                                    errorMessage={
                                        _.get(formik.errors, 'trips.generalOffloadReminders', false)
                                            ? _.get(formik.errors, 'trips.generalOffloadReminders', '123')
                                            : '321'
                                    }
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    longStringFormat
                                    infoText="List of instructions to display to driver at the end of every trip"
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <StringListInput
                                    label="Last Trip Offload Instructions"
                                    values={_.get(formik.values, 'trips.lastTripOffloadReminders')}
                                    onChange={values => formik.setFieldValue('trips.lastTripOffloadReminders', values)}
                                    error={() => {}}
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    longStringFormat
                                    infoText="List of instructions to display to driver at the end of their last trip of the day"
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxOffloadTime',
                                    'Max Allowable Offload Time (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max Allowable Offload Time',
                                        'Maximum cap on time required to offload all bags at depot',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6">Clock in Options</Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <StringListInput
                                    label="Starting Trip Tasks"
                                    values={_.get(formik.values, 'trips.startingTripTasks')}
                                    onChange={values => formik.setFieldValue('trips.startingTripTasks', values)}
                                    error={inputValue => {
                                        return (
                                            _.isEmpty(inputValue) &&
                                            _.get(formik.errors, 'trips.startingTripTasks', false)
                                        );
                                    }}
                                    errorMessage={
                                        _.get(formik.errors, 'trips.startingTripTasks', false)
                                            ? _.get(formik.errors, 'trips.startingTripTasks', '')
                                            : ''
                                    }
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    longStringFormat
                                    infoText="List of tasks a driver should complete before starting their trip"
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxEarlyStartRouteTime',
                                    'Max Early Start Trip Time (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max Early Start Trip Time',
                                        'Maximum amount of time before official start time a driver can start their trip',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxDistanceFromStartLocation',
                                    'Max Distance from Start Location (km)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max Distance from Start Location',
                                        'Maximum GPS distance from start location from which a driver can start their trip',
                                        theme
                                    )
                                )}
                            </Grid>
                            {/* <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxStartTripTime',
                                    'Max Allowable Start Trip Time (min)',
                                    formik,
                                    'number'
                                )}
                            </Grid> */}
                            {/* <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.enableStartScreen',
                                    'Enable Trip Start Screen',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Display Start Trip Screen to driver before starting trip'
                                )}
                            </Grid> */}

                            <Grid xs={12}>
                                <Typography variant="h6">Routing</Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.bagEstimateOOSStop',
                                    'Est. Capacity Reduction from OOS Stop (bags/hr)', //e.g. 50 -> 1 hr stop = est. 50 bags, 1.5 hr stop = est. 75 bags
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Capacity Reduction from OOS Stop',
                                        'Estimated conversion rate from stop time to number of bags collected used for balancing bag capacity',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.clusterTimeOfDayPreferences',
                                    'Cluster by AM/PM Preference',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Separate morning and afternoon pickups into their own routing clusters at the start and end of each trip'
                                )}
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.prioritizeRescheduled',
                                    'Prioritize Rescheduled Pickups',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Prioritize completion of rescheduled pickups in automatic trip routing'
                                )}
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.clusterZones',
                                    'Split Clusters by Zone',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Separate pickups in different zones into distinct routing clusters'
                                )}
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.capPickups',
                                    'Cap Trip at Pickup Capacity',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Use driver pickup capacity in addition to bag capacity to attempt to balance driver load'
                                )}
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6">Other</Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.warningDistanceFromDepot',
                                    'Expected Max Distance Between Start Location and Depot (km)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Expected max distance between start location and depot',
                                        'Toggles a warning on the edit trip dialog if a start location is at a distance that is too far from the depot',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxGasPayout',
                                    'Max Gas Payout per Trip',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max Gas Payout per Trip',
                                        'Caps the maximum amount that can be paid out to drivers for gas for a single trip',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Collectors"
                            icon="business"
                            error={
                                _.get(formik.touched, 'collectors', false) && _.get(formik.errors, 'collectors', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Fixed Cost Options
                                </Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <StringListInput
                                    label="Default Fixed Costs"
                                    values={_.get(formik.values, 'collectors.defaultFixedCosts')}
                                    onChange={values => formik.setFieldValue('collectors.defaultFixedCosts', values)}
                                    error={inputValue => {
                                        return (
                                            _.isEmpty(inputValue) &&
                                            _.get(formik.errors, 'collectors.defaultFixedCosts', false)
                                        );
                                    }}
                                    errorMessage={
                                        _.get(formik.errors, 'collectors.defaultFixedCosts', false)
                                            ? _.get(formik.errors, 'collectors.defaultFixedCosts', '123')
                                            : '321'
                                    }
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    longStringFormat
                                    infoText="List of default fixed cost options for collectors"
                                />
                            </Grid>
                        </ConfigWidget>
                        {_user.isSystemAdmin(operator) && (
                            <ConfigWidget
                                theme={theme}
                                formik={formik}
                                title="Role Permissions"
                                icon="admin_panel_settings"
                                error={
                                    _.get(formik.touched, 'permissions', false) &&
                                    _.get(formik.errors, 'permissions', false)
                                }
                                isSubmitting={formik.isSubmitting}
                                isValid={formik.isValid}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6">CSR</Typography>
                                </Grid>
                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Account Access</Typography>
                                    {getCheckbox(theme, 'permissions[0].accounts.customer', 'Customer', formik)}
                                    {getCheckbox(theme, 'permissions[0].accounts.charity', 'Charity', formik)}
                                    {getCheckbox(theme, 'permissions[0].accounts.driver', 'Driver', formik)}
                                    {getCheckbox(theme, 'permissions[0].accounts.collector', 'Collector', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].accounts.systemAdministrator',
                                        'System Administrator',
                                        formik
                                    )}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Pickups</Typography>
                                    {getCheckbox(theme, 'permissions[0].pickups.today', 'Today', formik)}
                                    {getCheckbox(theme, 'permissions[0].pickups.completed', 'Completed', formik)}
                                    {getCheckbox(theme, 'permissions[0].pickups.calendar', 'Calendar', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].pickups.tripStatistics',
                                        'Trip Statistics',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].pickups.commodity', 'Commodity', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].pickups.assetTracking',
                                        'Asset Tracking',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].pickups.import', 'Import', formik)}
                                    {getCheckbox(theme, 'permissions[0].pickups.bottleDrives', 'Bottle Drives', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Orders</Typography>
                                    {getCheckbox(theme, 'permissions[0].orders.counter', 'Counter', formik)}
                                    {getCheckbox(theme, 'permissions[0].orders.clerkReport', 'Clerk Report', formik)}
                                    {getCheckbox(theme, 'permissions[0].orders.history', 'History', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Accounting</Typography>
                                    {getCheckbox(theme, 'permissions[0].accounting.redemptions', 'Redemptions', formik)}
                                    {getCheckbox(theme, 'permissions[0].accounting.invoicing', 'Invoicing', formik)}
                                    {getCheckbox(theme, 'permissions[0].accounting.quickbooks', 'Quickbooks', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].accounting.agingBalancesReport',
                                        'Aging Report',
                                        formik
                                    )}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Users</Typography>
                                    {getCheckbox(theme, 'permissions[0].users.admins', 'Admins', formik)}
                                    {getCheckbox(theme, 'permissions[0].users.customers', 'Customers', formik)}
                                    {getCheckbox(theme, 'permissions[0].users.organizations', 'Organizations', formik)}
                                    {getCheckbox(theme, 'permissions[0].users.employees', 'Employees', formik)}
                                    {getCheckbox(theme, 'permissions[0].users.collectors', 'Collectors', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Tools</Typography>
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].configuration.announcements',
                                        'Announcements',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].pickups.assetTracking',
                                        'Asset Tracking',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].pickups.bottleDrives', 'Bottle Drives', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].system.imgCaptureStatus',
                                        'Image Capture Device Status',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].pickups.import', 'Import', formik)}

                                    {getCheckbox(
                                        theme,
                                        'permissions[0].configuration.questionnaires',
                                        'Questionnaires',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].system.sendOuts', 'Single Sends', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Usage</Typography>
                                    {getCheckbox(theme, 'permissions[0].usage.trends', 'Trends', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].usage.questionnaires',
                                        'Questionnaires',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].usage.promoUsage', 'Promo Usage', formik)}
                                    {getCheckbox(theme, 'permissions[0].usage.donations', 'Donations', formik)}
                                    {getCheckbox(theme, 'permissions[0].usage.fees', 'Fees', formik)}
                                    {getCheckbox(theme, 'permissions[0].usage.tips', 'Tips', formik)}
                                    {getCheckbox(theme, 'permissions[0].usage.complaints', 'Complaints', formik)}
                                    {getCheckbox(theme, 'permissions[0].usage.stops', 'Stops', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Configuration</Typography>
                                    {getCheckbox(theme, 'permissions[0].configuration.promos', 'Promos', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].configuration.commodities',
                                        'Commodities',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].configuration.rates', 'Rates', formik)}
                                    {getCheckbox(theme, 'permissions[0].configuration.regions', 'Regions', formik)}
                                    {getCheckbox(theme, 'permissions[0].configuration.fees', 'Fees', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].configuration.ratingPlatforms',
                                        'Rating Platforms',
                                        formik
                                    )}

                                    {getCheckbox(theme, 'permissions[0].configuration.taxes', 'Taxes', formik)}
                                    {getCheckbox(theme, 'permissions[0].configuration.trucks', 'Trucks', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Audit</Typography>
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].audit.reviewInfractions',
                                        'Review Infractions',
                                        formik
                                    )}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Logs</Typography>
                                    {getCheckbox(theme, 'permissions[0].logs.googleCalls', 'Google Calls', formik)}
                                    {getCheckbox(theme, 'permissions[0].logs.databaseLogs', 'Database', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].logs.messageLogs',
                                        'Notifications (SMS/Email/App)',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].logs.requestLogs', 'HTTP Requests', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">Security</Typography>
                                    {getCheckbox(theme, 'permissions[0].security.ipBlocking', 'Ip Blocking', formik)}
                                </Grid>

                                <Grid item md={2} sm={3} xs={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Typography variant="body1">System</Typography>
                                    {getCheckbox(theme, 'permissions[0].system.integrations', 'Integrations', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].system.savedNotifications',
                                        'Saved Notifications',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].system.settings', 'Settings', formik)}
                                    {getCheckbox(
                                        theme,
                                        'permissions[0].system.informationForms',
                                        'Information Editor',
                                        formik
                                    )}
                                    {getCheckbox(theme, 'permissions[0].system.systemTasks', 'Automated Tasks', formik)}
                                </Grid>
                            </ConfigWidget>
                        )}

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Two-Factor Authentication"
                            icon="phonelink_lock"
                            error={
                                _.get(formik.touched, 'twoFactorAuthentication', false) &&
                                _.get(formik.errors, 'twoFactorAuthentication', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            {!_.isEmpty(twoFactorAuthenticationWarning) && process.env.REACT_APP_ENV === 'TEST' && (
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle1">{twoFactorAuthenticationWarning}</Typography>
                                </Grid>
                            )}
                            <Grid item md={12} xs={12}>
                                {getSwitch(
                                    theme,
                                    'twoFactorAuthentication.isRequired',
                                    'Require Two-Factor Authentication',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Enable two-factor authentication for all accounts'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...formik.getFieldProps('twoFactorAuthentication.maxCodeRequests')}
                                    type={'number'}
                                    error={
                                        (_.get(formik.touched, 'twoFactorAuthentication.maxCodeRequests', false) ||
                                            _.get(
                                                formik.touched,
                                                'twoFactorAuthentication.limitCodeRequests',
                                                false
                                            )) &&
                                        _.get(formik.errors, 'twoFactorAuthentication.maxCodeRequests', false)
                                            ? true
                                            : null
                                    }
                                    label={'Code Requests Before Locking Account'}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    variant="outlined"
                                    helperText={
                                        (_.get(formik.touched, 'twoFactorAuthentication.maxCodeRequests', false) ||
                                            _.get(
                                                formik.touched,
                                                'twoFactorAuthentication.limitCodeRequests',
                                                false
                                            )) &&
                                        _.get(formik.errors, 'twoFactorAuthentication.maxCodeRequests', false)
                                            ? _.get(formik.errors, 'twoFactorAuthentication.maxCodeRequests', false)
                                            : ''
                                    }
                                    fullWidth
                                    disabled={!_.get(formik.values, 'twoFactorAuthentication.limitCodeRequests')}
                                    data-cy={`${_.kebabCase('twoFactorAuthentication.maxCodeRequests')}-input`}
                                    InputProps={getInfoIcon(
                                        'Code Requests Before Locking Account',
                                        'Maximum number of authentication code requests a customer can make before being temporarily locked out of their account',
                                        theme
                                    )}
                                />
                                {getSwitch(
                                    theme,
                                    'twoFactorAuthentication.limitCodeRequests',
                                    'Limit Code Requests',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Limit the number of authentication code requests a customer can make before being temporarily locked out of their account'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...formik.getFieldProps('twoFactorAuthentication.maxAuthenticationAttempts')}
                                    type={'number'}
                                    error={
                                        (_.get(
                                            formik.touched,
                                            'twoFactorAuthentication.maxAuthenticationAttempts',
                                            false
                                        ) ||
                                            _.get(formik.touched, 'twoFactorAuthentication.limitAttempts', false)) &&
                                        _.get(formik.errors, 'twoFactorAuthentication.maxAuthenticationAttempts', false)
                                            ? true
                                            : null
                                    }
                                    label={'Authentication Attempts Before Locking Account'}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    variant="outlined"
                                    helperText={
                                        (_.get(
                                            formik.touched,
                                            'twoFactorAuthentication.maxAuthenticationAttempts',
                                            false
                                        ) ||
                                            _.get(formik.touched, 'twoFactorAuthentication.limitAttempts', false)) &&
                                        _.get(formik.errors, 'twoFactorAuthentication.maxAuthenticationAttempts', false)
                                            ? _.get(
                                                  formik.errors,
                                                  'twoFactorAuthentication.maxAuthenticationAttempts',
                                                  false
                                              )
                                            : ''
                                    }
                                    fullWidth
                                    disabled={!_.get(formik.values, 'twoFactorAuthentication.limitAttempts')}
                                    data-cy={`${_.kebabCase(
                                        'twoFactorAuthentication.maxAuthenticationAttempts'
                                    )}-input`}
                                    InputProps={getInfoIcon(
                                        'Authentication Attempts Before Locking Account',
                                        'Maximum number of failed authentication attempts a customer can make before being temporarily locked out of their account',
                                        theme
                                    )}
                                />
                                {getSwitch(
                                    theme,
                                    'twoFactorAuthentication.limitAttempts',
                                    'Limit Attempts',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Limit the number of failed authentication attempts a customer can make before being temporarily locked out of their account'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'twoFactorAuthentication.minutesToLockAccount',
                                    'Minutes Until Account is Unlocked',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Minutes Until Account is Unlocked',
                                        'Number of minutes to lock a customer out of their account after failed two-factor authentication',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'twoFactorAuthentication.secondsBetweenCodeRequests',
                                    'Seconds Between Code Requests',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Seconds Between Code Requests',
                                        'Amount of time to disable authentication code resend requests to prevent spamming',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Driver Tipping"
                            icon="price_check"
                            error={
                                _.get(formik.touched, 'driverTipping', false) &&
                                _.get(formik.errors, 'driverTipping', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            {getSwitch(
                                theme,
                                'driverTipping.isEnabled',
                                'Enable Driver Tipping',
                                formik,
                                {},
                                {},
                                undefined,
                                'Allow customers to tip drivers from returns on pickup counts'
                            )}
                            {getSelect(
                                theme,
                                'driverTipping.tippingModel',
                                'Tipping Model',
                                [TIPPING_MODEL_INDIVIDUAL, TIPPING_MODEL_POOL],
                                formik
                            )}
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Redemptions"
                            icon="credit_card"
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                            error={
                                _.get(formik.touched, 'redemptionOptions', false) &&
                                _.get(formik.errors, 'redemptionOptions', false)
                            }
                        >
                            <Grid item md={4} xs={6}>
                                {getTextInput(
                                    theme,
                                    'redemptionOptions.redemptionFee',
                                    'Redemption Fee ($)',
                                    formik,
                                    'number',
                                    getInfoIcon('Redemption Fee', 'Fixed fee for processing redemptions', theme)
                                )}
                            </Grid>
                            {availableLangs.map(lang => (
                                <Grid item md={4} xs={6}>
                                    <StringListInput
                                        label={`E-transfer Security Questions (${lang})`}
                                        values={_.get(formik.values, `redemptionOptions.securityQuestions.${lang}`)}
                                        onChange={values =>
                                            formik.setFieldValue(`redemptionOptions.securityQuestions.${lang}`, values)
                                        }
                                        error={securityQuestionError}
                                        errorMessage={securityQuestionErrorMessage}
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        infoText={
                                            <div>
                                                <div>
                                                    List of available security questions for customers to redeem via
                                                    e-transfer
                                                </div>
                                                <div>Questions must follow these rules:</div>
                                                <ul style={{ marginTop: 0 }}>
                                                    <li>Maximum 40 characters</li>
                                                    <li>Cannot contain the following</li>
                                                    <ul>
                                                        <li>These characters: {`- < > % { } [ ] & # \\ “`}</li>
                                                        <li>
                                                            These words: http:, https:, javascript, function, return,
                                                            www
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </div>
                                        }
                                    />
                                </Grid>
                            ))}

                            <Grid item md={6} xs={12}>
                                <Grid container spacing={theme.spacing.unit}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Customer</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Methods</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Minimum Value ($)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Processing Time (Days)</Typography>
                                    </Grid>
                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.methods.cheque',
                                                    'Cheque',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow customers to redeem balance by cheque'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.chequeMinimum',
                                                    'Minimum (cheque)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Cheque',
                                                        'Minimum balance required for customer to redeem by cheque',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.chequeProcessingTime',
                                                    'Time (cheque)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Cheque',
                                                        'Estimated time required to process cheque redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                        </>
                                    )}

                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.methods.bankTransfer',
                                                    loc('redemption6', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow customers to redeem balance via bank transfer'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.bankTransferMinimum',
                                                    'Minimum (bank transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Bank Transfer',
                                                        'Minimum balance required to redeem via bank transfer',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.bankTransferProcessingTime',
                                                    'Time (bank transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Bank Transfer',
                                                        'Estimated time required to process bank transfer redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.methods.etransfer',
                                                    loc('redemption4', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow customers to redeem balance via e-Transfer'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.eTransferMinimum',
                                                    'Minimum (e-transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum e-Transfer',
                                                        'Minimum balance required to redeem via e-Transfer',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.eTransferProcessingTime',
                                                    'Time (e-transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time e-Transfer',
                                                        'Estimated time required to process e-Transfer redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.methods.cash',
                                                    loc('redemption7', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow customers to redeem balance in cash'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.cashMinimum',
                                                    'Minimum (cash)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Cash',
                                                        'Minimum balance required to redeem in cash',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} />
                                        </>
                                    )}
                                    {isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.methods.directDeposit',
                                                    'Direct Deposit',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow customers to redeem via direct deposit'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} />
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.customer.directDepositProcessingTime',
                                                    'Time (direct deposit)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Direct Deposit',
                                                        'Estimated time to process direct deposit redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1">Other Settings</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.customer.enableEtransferLimit',
                                                    'Enable Yearly e-Transfer Limit',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Limit the total amount customers can redeem via e-Transfer in one year'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                {_.get(
                                                    formik.values,
                                                    'redemptionOptions.customer.enableEtransferLimit'
                                                ) &&
                                                    getTextInput(
                                                        theme,
                                                        'redemptionOptions.customer.etransferLimit',
                                                        'Yearly Limit (e-transfer)',
                                                        formik,
                                                        'number',
                                                        getInfoIcon(
                                                            'Yearly Limit e-Transfer',
                                                            'Maximum amount customers can redeem via e-Transfer in one year',
                                                            theme
                                                        )
                                                    )}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Grid container spacing={theme.spacing.unit}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Charity</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Methods</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Minimum Value ($)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">Processing Time (Days)</Typography>
                                    </Grid>

                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.methods.cheque',
                                                    'Cheque',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow charities to redeem by cheque'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.chequeMinimum',
                                                    'Minimum (cheque)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Cheque',
                                                        'Minimum balance required to redeem by cheque',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.chequeProcessingTime',
                                                    'Time (cheque)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Cheque',
                                                        'Estimated time required to process cheque redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                        </>
                                    )}

                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.methods.bankTransfer',
                                                    loc('redemption6', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow charities to redeem via bank transfer'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.bankTransferMinimum',
                                                    'Minimum (bank transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Bank Transfer',
                                                        'Minimum balance required to redeem via bank transfer',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.bankTransferProcessingTime',
                                                    'Time (bank transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Bank Transfer',
                                                        'Estimated time required to process bank transfer redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.methods.etransfer',
                                                    loc('redemption4', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow charities to redeem via e-Transfer'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.eTransferMinimum',
                                                    'Minimum (e-transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum e-Transfer',
                                                        'Minimum balance required to redeem via e-Transfer',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.eTransferProcessingTime',
                                                    'Time (e-transfer)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time e-Transfer',
                                                        'Estimated time required to process e-Transfer redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.methods.cash',
                                                    loc('redemption7', lang),
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow charities to redeem in cash'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.cashMinimum',
                                                    'Minimum (cash)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Minimum Cash',
                                                        'Minimum balance required to redeem in cash',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} />
                                        </>
                                    )}
                                    {isAUSRegion() && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.methods.directDeposit',
                                                    'Direct Deposit',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow charities to redeem via direct deposit'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4} />
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.charity.directDepositProcessingTime',
                                                    'Time (direct deposit)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Direct Deposit',
                                                        'Estimated time required to process direct deposit redemption',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    {!isAUSRegion() && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1">Other Settings</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.charity.enableEtransferLimit',
                                                    'Enable Yearly e-Transfer Limit',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Limit total amount charities can redeem via e-Transfer in one year'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                {_.get(
                                                    formik.values,
                                                    'redemptionOptions.charity.enableEtransferLimit'
                                                ) &&
                                                    getTextInput(
                                                        theme,
                                                        'redemptionOptions.charity.etransferLimit',
                                                        'Yearly Limit (e-transfer)',
                                                        formik,
                                                        'number',
                                                        getInfoIcon(
                                                            'Yearly Limit e-Transfer',
                                                            'Maximum amount charities can redeem via e-Transfer in one year',
                                                            theme
                                                        )
                                                    )}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Intuit Configuration"
                            icon="receipt_long"
                            error={_.get(formik.touched, 'intuit', false) && _.get(formik.errors, 'intuit', false)}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'intuit.company_id',
                                    'Company Id',
                                    formik,
                                    'text',
                                    getInfoIcon('Company Id', 'Intuit company ID', theme)
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Intuit Accounts</Typography>
                            </Grid>
                            {intuitAccountForm(formik, 'depositsReceivable', 'Deposits Receivable', theme)}
                            {intuitAccountForm(formik, 'customerLiability', 'Customer Liability', theme)}
                            {intuitAccountForm(formik, 'serviceIncome', 'Service Income', theme)}
                            {intuitAccountForm(formik, 'taxLiability', 'Tax Liability', theme)}
                            {intuitAccountForm(formik, 'referrals', 'Referrals', theme)}
                            {intuitAccountForm(formik, 'serviceFees', 'Service Fees', theme)}
                            {intuitAccountForm(formik, 'redemptionsPayment', 'Redemptions Payment', theme)}
                            {intuitAccountForm(formik, 'adSpend', 'Ad Spend', theme)}
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Rating Dialog Text"
                            icon="thumbs_up_down"
                            error={
                                _.get(formik.touched, `localization.${lang}.ratingDialogText`, false) &&
                                _.get(formik.errors, `localization.${lang}.ratingDialogText`, false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.rateUsTitle`,
                                    'No Ratings Title',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'No Ratings Title',
                                        'Title of rating dialog displayed when customer has never rated us before',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.thanksForRatingTitle`,
                                    'Just Rated Us Title',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Just Rated Us Title',
                                        'Title of rating dialog displayed when customer has just submitted a rating',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.ratedUsBeforeTitle`,
                                    'Rated Us Before Title',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Rated Us Before Title',
                                        'Title of rating dialog displayed when customer has previously submitted a rating',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.rateUsContent`,
                                    'No Ratings Content',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'No Ratings Content',
                                        'Content of rating dialog displayed when customer has never rated us before',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.thanksForRatingContent`,
                                    'Just Rated Us Content',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Just Rated Us Content',
                                        'Content of rating dialog displayed when customer has just submitted a rating',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.ratingDialogText.ratedUsBeforeContent`,
                                    'Rated Us Before Content',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Rated Us Before Content',
                                        'Content of rating dialog displayed when customer has previously submitted a rating',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Share And Feedback"
                            icon="share"
                            error={
                                (_.get(formik.touched, 'shareAndFeedbackDialogConfig', false) &&
                                    _.get(formik.errors, 'shareAndFeedbackDialogConfig', false)) ||
                                (_.get(formik.touched, `localization.${lang}.CRUDDefaults.regions`, false) &&
                                    _.get(formik.errors, `localization.${lang}.CRUDDefaults.regions`, false))
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Rating Screen
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'shareAndFeedbackDialogConfig.ratingScreen.ordering',
                                    'Ordering No',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Rating Ordering No',
                                        'Order of the rating screen when prompting the user to share and give feedback',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'shareAndFeedbackDialogConfig.ratingScreen.enabled',
                                    'Enabled',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display the rating screen when prompting the user to share and give feedback'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Referral Screen
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'shareAndFeedbackDialogConfig.referralScreen.ordering',
                                    'Ordering No',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Referral Ordering No',
                                        'Order of the referral screen when prompting the user to share and give feedback',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'shareAndFeedbackDialogConfig.referralScreen.enabled',
                                    'Enabled',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display the referral screen when prompting the user to share and give feedback'
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Share Screen
                                </Typography>
                                {getTextInput(
                                    theme,
                                    'shareAndFeedbackDialogConfig.shareScreen.ordering',
                                    'Ordering No',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Share Screen Ordering No',
                                        'Order of the share screen when prompting the user to share and give feedback',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'shareAndFeedbackDialogConfig.shareScreen.enabled',
                                    'Enabled',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display the share screen when prompting the user to share and give feedback'
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Default Share Dialog Values
                                </Typography>
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <SocialShareInput
                                    path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_DEFAULT`}
                                    formik={formik}
                                    headerText="Customer default share message"
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SocialShareInput
                                    path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_RECYCLED`}
                                    formik={formik}
                                    headerText="Customer pickup completed share message"
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SocialShareInput
                                    path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_DONATED`}
                                    formik={formik}
                                    headerText="Customer pickup donated share message"
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SocialShareInput
                                    path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_ORGANIZATION_DEFAULT`}
                                    formik={formik}
                                    headerText="Charity share message"
                                />
                            </Grid>
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Referrals"
                            icon="currency_exchange"
                            error={
                                _.get(formik.touched, 'promoCodeCustomer', false) &&
                                _.get(formik.errors, 'promoCodeCustomer', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Promo Configuration
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    'promoCodeCustomer.customerSplit',
                                    'Referee Refund Percentage',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Referee Refund Percentage',
                                        'Percentage of refund that goes to referee',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    'promoCodeCustomer.fixedRateKickback',
                                    'Kickback Amount($)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Kickback Amount',
                                        'Fixed kickback amount that goes to the referee',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    'promoCodeCustomer.kickbackThreshold',
                                    'Kickback Threshold($)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Kickback Threshold',
                                        'Count value threshold for promo to be applied',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Dialog Configuration
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.title`,
                                    'Referral Dialog Title',
                                    formik,
                                    'text',
                                    getInfoIcon('Referral Dialog Title', 'Title of customer referral dialog', theme)
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.text`,
                                    'Referral Dialog Text',
                                    formik,
                                    'text',
                                    getInfoIcon('Referral Dialog Text', 'Text of customer referral dialog', theme)
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.codeDisplayLabel`,
                                    'Referral Promo Label',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Referral Promo Label',
                                        'Label for displaying referral promo code to customer',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.referralURLDisplayLabel`,
                                    'Referral Url Label',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Referral Url Label',
                                        'Label for displaying referral link to customer',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.primaryActionLabel`,
                                    'Invite Label',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Invite Label',
                                        'Label for displaying social media invite options to customer',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.rulesDialogHandle`,
                                    'Rules Dialog Label',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Rules Dialog Label',
                                        'Label for button to open referral rules dialog',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.rulesTitle`,
                                    'Rules Dialog Title',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Rules Dialog Title',
                                        'Title of dialog explaining the rules of customer referrals',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.promoCodeCustomer.rules`,
                                    'Rules Dialog Text',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Rules Dialog Text',
                                        'Text of dialog explaining the rules of customer referrals',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Registration"
                            icon="how_to_reg"
                            error={
                                _.get(formik.touched, 'registration', false) &&
                                _.get(formik.errors, 'registration', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'registration.emailVerificationRequired',
                                    'Email Verification Required',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>
                            {availableLangs.map(lang => (
                                <Grid item xs={12}>
                                    <StringListInput
                                        label={`Registration Security Questions (${lang})`}
                                        values={_.get(formik.values, `registration.securityQuestions.${lang}`)}
                                        onChange={values =>
                                            formik.setFieldValue(`registration.securityQuestions.${lang}`, values)
                                        }
                                        error={securityQuestionError}
                                        errorMessage={securityQuestionErrorMessage}
                                        InputProps={{
                                            variant: 'outlined'
                                        }}
                                        style={{ margin: 0, padding: 0 }}
                                        infoText={
                                            <div>
                                                <div>
                                                    List of available security questions for customer registration
                                                </div>
                                                <div>Questions must follow these rules:</div>
                                                <ul style={{ marginTop: 0 }}>
                                                    <li>Maximum 40 characters</li>
                                                    <li>Cannot contain the following</li>
                                                    <ul>
                                                        <li>These characters: {`- < > % { } [ ] & # \\ “`}</li>
                                                        <li>
                                                            These words: http:, https:, javascript, function, return,
                                                            www
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </div>
                                        }
                                    />
                                </Grid>
                            ))}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    {...formik.getFieldProps('registration.questionnaire')}
                                    fullWidth
                                    label="Select Registration Questionnaire"
                                    variant="outlined"
                                    select
                                    data-cy="registration-questionnaire-select"
                                    style={{ marginTop: theme.spacing.unit * 2, padding: 0 }}
                                >
                                    <MenuItem value={''} data-cy="registration-questionnaire-select-none">
                                        <i>None</i>
                                    </MenuItem>
                                    {!_.isEmpty(questionnaires) &&
                                        questionnaires.map(questionnaire => (
                                            <MenuItem
                                                value={questionnaire._id}
                                                data-cy={`registration-questionnaire-select-${_.get(
                                                    questionnaire,
                                                    'name'
                                                )}`}
                                            >
                                                {questionnaire.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Registration Tagline"
                                    value={_.get(formik.values, 'registration.tagline')}
                                    onChange={value => formik.setFieldValue('registration.tagline', value)}
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tagline')}
                                    error={_.get(formik, 'errors.registration.tagline')}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Registration Types
                                </Typography>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'registration.options.regCustomer',
                                    'Show Register Customer',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>

                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'registration.options.regBusiness',
                                    'Show Register Business',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>

                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'registration.options.regOrganization',
                                    'Show Register Organization',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>

                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'registration.options.bottleDrive',
                                    'Show Register Bottle Drive',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6">Tooltips</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Address Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.address')}
                                    onChange={value => formik.setFieldValue('registration.tooltips.address', value)}
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.address')}
                                    error={_.get(formik, 'errors.registration.tooltips.address')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Name Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.name')}
                                    onChange={value => formik.setFieldValue('registration.tooltips.name', value)}
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.name')}
                                    error={_.get(formik, 'errors.registration.tooltips.name')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Email Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.email')}
                                    onChange={value => formik.setFieldValue('registration.tooltips.email', value)}
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.email')}
                                    error={_.get(formik, 'errors.registration.tooltips.email')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Phone Number Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.phone')}
                                    onChange={value => formik.setFieldValue('registration.tooltips.phone', value)}
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.phone')}
                                    error={_.get(formik, 'errors.registration.tooltips.phone')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Doing Business As Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.doingBusinessAs')}
                                    onChange={value =>
                                        formik.setFieldValue('registration.tooltips.doingBusinessAs', value)
                                    }
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.doingBusinessAs')}
                                    error={_.get(formik, 'errors.registration.tooltips.doingBusinessAs')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LocalizedTextInput
                                    label="Registered Business Name Tooltip"
                                    value={_.get(formik.values, 'registration.tooltips.registeredBusinessName')}
                                    onChange={value =>
                                        formik.setFieldValue('registration.tooltips.registeredBusinessName', value)
                                    }
                                    style={{ marginTop: theme.spacing.unit, padding: theme.spacing.unit }}
                                    touched={_.get(formik, 'touched.registration.tooltips.registeredBusinessName')}
                                    error={_.get(formik, 'errors.registration.tooltips.registeredBusinessName')}
                                />
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="POR"
                            icon="ballot"
                            error={
                                (_.get(formik.touched, 'liveCountAds', false) &&
                                    _.get(formik.errors, 'liveCountAds', false)) ||
                                (_.get(formik.touched, 'counterScreen', false) &&
                                    _.get(formik.errors, 'counterScreen', false)) ||
                                (_.get(formik.touched, 'adjustments', false) &&
                                    _.get(formik.errors, 'adjustments', false)) ||
                                (_.get(formik.touched, 'bulkCounter', false) &&
                                    _.get(formik.errors, 'bulkCounter', false))
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid xs={12}>
                                <Typography variant="h6">POR Advertisement Screens</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <StringListInput
                                    label="Half Screen Ads"
                                    values={_.get(formik.values, 'liveCountAds.halfScreen')}
                                    onChange={values => formik.setFieldValue('liveCountAds.halfScreen', values)}
                                    error={() => {}}
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    infoText="List of image files to show half screen ads on the live counts screen"
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <StringListInput
                                    label="Full Screen Ads"
                                    values={_.get(formik.values, 'liveCountAds.fullScreen')}
                                    onChange={values => formik.setFieldValue('liveCountAds.fullScreen', values)}
                                    error={() => {}}
                                    InputProps={{
                                        variant: 'outlined'
                                    }}
                                    infoText="List of image files to show full screen ads on the live counts screen"
                                />
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6">Counter Screen</Typography>
                            </Grid>
                            {_user.isSystemAdmin(operator) && (
                                <Grid item md={4} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'counterScreen.refreshInterval',
                                        'Counter screen auto refresh interval (s), zero or blank to disable',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Counter screen auto refresh interval',
                                            'Interval between automatic refreshes to update data displayed on counter screen',
                                            theme
                                        )
                                    )}
                                </Grid>
                            )}
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'bulkCounter.hourWindow',
                                    'Overdue Threshold (hrs) ',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Overdue Threshold',
                                        'Period of time after which an incomplete order is considered overdue',
                                        theme
                                    )
                                )}
                            </Grid>
                            {_user.isSystemAdmin(operator) && (
                                <>
                                    <Grid xs={12}>
                                        <Typography variant="h6">Adjustments</Typography>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        {getTextInput(
                                            theme,
                                            'adjustments.maxDailyAmountPerUser',
                                            'Max amount claimable per user per day ($)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max amount claimable',
                                                'Maximum dollar amount a user can claim in one day through adjustments',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'adjustments.enableAmountCapping',
                                            'Enabled',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Limit the total dollar amount a user can claim in one day through adjustments'
                                        )}
                                    </Grid>
                                </>
                            )}
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="General"
                            icon="miscellaneous_services"
                            error={
                                (_.get(formik.touched, 'charityTaskConfiguration', false) &&
                                    _.get(formik.errors, 'charityTaskConfiguration', false)) ||
                                (_.get(formik.touched, 'charityTaxReceipts', false) &&
                                    _.get(formik.errors, 'charityTaxReceipts', false)) ||
                                (_.get(formik.touched, 'googleApi', false) &&
                                    _.get(formik.errors, 'googleApi', false)) ||
                                (_.get(formik.touched, 'serviceableAreaLink', false) &&
                                    _.get(formik.errors, 'serviceableAreaLink', false)) ||
                                (_.get(formik.touched, 'customerHistory', false) &&
                                    _.get(formik.errors, 'customerHistory', false)) ||
                                (_.get(formik.touched, 'defaultCoordinates', false) &&
                                    _.get(formik.errors, 'defaultCoordinates', false)) ||
                                (_.get(formik.touched, 'dashboard', false) &&
                                    _.get(formik.errors, 'dashboard', false)) ||
                                (_.get(formik.touched, 'inactiveCustomerConfiguration.staleCustomerThreshold', false) &&
                                    _.get(
                                        formik.errors,
                                        'inactiveCustomerConfiguration.staleCustomerThreshold',
                                        false
                                    )) ||
                                (_.get(formik.touched, 'enabledFunctions', false) &&
                                    _.get(formik.errors, 'enabledFunctions', false)) ||
                                (_.get(formik.touched, 'idleLogoutTimeOut', false) &&
                                    _.get(formik.errors, 'idleLogoutTimeOut', false)) ||
                                (isAUSRegion() &&
                                    _.get(formik.touched, 'customerInvoicing', false) &&
                                    _.get(formik.errors, 'customerInvoicing', false))
                                // (_.get(formik.touched, 'imageConfig.maxImageUploads', false) &&
                                //     _.get(formik.errors, 'imageConfig.maxImageUploads', false))
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'charityTaskConfiguration.minimumVerifiedTasksForFeatured',
                                    'AutoFeature Completed Tasks',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'AutoFeature Completed Tasks',
                                        'Number of tasks an organization must successfully complete to become featured',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'charityTaxReceipts.defaultMinTaxReceiptAmount',
                                    'Minimum Tax Receipt Amount',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Minimum Tax Receipt Amount',
                                        'Minimum donation amount for a donating customer to receive a tax receipt',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'inactiveCustomerConfiguration.staleCustomerThreshold',
                                    'Stale Customer Threshold (days)',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Stale Customer Threshold',
                                        'Number of days of inactivity after which a user account is set as stale',
                                        theme
                                    )
                                )}
                            </Grid>
                            {/* <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'imageConfig.maxImageUploads',
                                    'Maximum image uploads per item',
                                    formik,
                                    'number'
                                )}
                            </Grid> */}

                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'customerHistory.allowViewReceipt',
                                    'Allow customers to see receipt info',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'login.showThirdPartyLogins',
                                    'Show third party logins',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Display third party login options on Login and Registration pages'
                                )}
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Enabled Functions
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'enabledFunctions.pickups',
                                    'Enable Site/App Pickup Functions',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 }
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'enabledFunctions.promos',
                                    'Enable Site/App Promo Functions',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    }
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'enabledFunctions.charity',
                                    'Enable Site/App Charity Functions',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    }
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'enabledFunctions.scanQR', 'Enable QR Scan Functions', formik, {
                                    marginTop: theme.spacing.unit * 2
                                })}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'enabledFunctions.showQR', 'Enable QR Show Functions', formik, {
                                    marginTop: theme.spacing.unit * 2
                                })}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Dashboard
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showDepot',
                                    'Show Depot Section',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display card containing depot information on main dashboard'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showInvoicing',
                                    'Show Invoicing Section',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display card containing invoicing information on main dashboard'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showChecksAndBalances',
                                    'Show Checks/Balances Section',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display card containing checks and balances on main dashboard'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showDrivers',
                                    'Show Drivers Section',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display card containing driver stats on main dashboard'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.consolidateCommission',
                                    'Consolidate Commission',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    `Add Depot CSF to ${
                                        process.env.REACT_APP_BRAND_NAME
                                    } CSF on main dashboard statistics`
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showFivePercentRefund',
                                    'Show Full Refund (5%)',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Display Full Refund 5% on main dashboard statistics'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showTotalInactiveUsers',
                                    'Show Total Inactive Users',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 },
                                    {},
                                    undefined,
                                    'Display total number of inactive users on main dashboard statistics'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dashboard.showContractorCost',
                                    'Show Contractor Cost',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 },
                                    {},
                                    undefined,
                                    'Display contractor cost on main dashboard statistics'
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'dashboard.inactiveBeforeNumMonths',
                                    'Show users inactive for # months',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Inactive months',
                                        'Number of months of inactivity after which a user is counted as inactive',
                                        theme
                                    )
                                )}
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Menu Options
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(
                                    theme,
                                    'menuOptions.showDefaultCollectorOptions',
                                    'Show default collector options',
                                    formik
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'menuOptions.showAccounting', 'Show accounting', formik)}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'menuOptions.showQuickbooks', 'Show quickbooks', formik)}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'menuOptions.showReconciliation', 'Show Reconciliation', formik)}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getSwitch(theme, 'menuOptions.showAgingBalances', 'Show aging balances', formik)}
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                                    Map Configuration
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'defaultCoordinates.latitude',
                                    'Default Latitude',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Default Latitude',
                                        'Default latitude coordinate used for initial map centering',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'defaultCoordinates.longitude',
                                    'Default Longitude',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Default Longitude',
                                        'Default longitude coordinate used for initial map centering',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'googleApi.mapsCoordsKey',
                                    'Google Maps Coords Key',
                                    formik,
                                    'text',
                                    getInfoIcon('Google Maps Coords Key', 'Google Maps API Coords Key', theme)
                                )}
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} sm={3}>
                                {getSwitch(
                                    theme,
                                    'dropLocationMarker.override',
                                    'Override Drop Location Marker',
                                    formik
                                )}
                                <Collapse in={_.get(formik.values, 'dropLocationMarker.override', false)}>
                                    {_.isNil(dropLocationMarkerFile) &&
                                    _.isNil(formik.values.dropLocationMarker.url) ? (
                                        <FormControl fullWidth data-cy="upload-drop-location-marker-input">
                                            <input
                                                accept={'image/*'}
                                                style={{ display: 'none' }}
                                                id="raised-button-file-drop-location"
                                                multiple={false}
                                                type="file"
                                                onChange={handleDropLocationMarkerFile}
                                            />
                                            <label htmlFor="raised-button-file-drop-location">
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    component="span"
                                                    style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                        </FormControl>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {!_.isNil(dropLocationMarkerFile) ? (
                                                <img
                                                    src={URL.createObjectURL(dropLocationMarkerFile)}
                                                    style={{ maxWidth: '100%' }}
                                                    alt="Drop Location Marker"
                                                />
                                            ) : !dropLocationImageError &&
                                              imageFileTypes.includes(
                                                  formik.values.dropLocationMarker.url.split('.').pop()
                                              ) ? (
                                                <img
                                                    src={formik.values.dropLocationMarker.url}
                                                    alt="charity-high-resolution-logo"
                                                    onError={() => setDropLocationImageError(true)}
                                                    style={{
                                                        maxWidth: '128px',
                                                        maxHeight: '128px'
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                        check_circle
                                                    </Icon>
                                                    <Typography
                                                        style={{
                                                            color: colors.green[500],
                                                            margin: theme.spacing.unit * 2,
                                                            textAlign: 'center'
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        Image Uploaded
                                                    </Typography>
                                                </div>
                                            )}
                                            <FormControl fullWidth data-cy="upload-photo-input">
                                                <input
                                                    accept={'image/*'}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file-drop-location"
                                                    multiple={false}
                                                    type="file"
                                                    onChange={handleDropLocationMarkerFile}
                                                />
                                                <label htmlFor="raised-button-file-drop-location">
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        component="span"
                                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                    >
                                                        Replace Image
                                                    </Button>
                                                </label>
                                            </FormControl>
                                        </div>
                                    )}
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {getSwitch(theme, 'charityMarker.override', 'Override Organization Marker', formik)}
                                <Collapse in={formik.values.charityMarker.override}>
                                    {_.isNil(charityMarkerFile) && _.isNil(formik.values.charityMarker.url) ? (
                                        <FormControl fullWidth data-cy="upload-charity-marker-input">
                                            <input
                                                accept={'image/*'}
                                                style={{ display: 'none' }}
                                                id="raised-button-file-charity"
                                                multiple={false}
                                                type="file"
                                                onChange={handleCharityMarkerFile}
                                            />
                                            <label htmlFor="raised-button-file-charity">
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    component="span"
                                                    style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                        </FormControl>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {!_.isNil(charityMarkerFile) ? (
                                                <img
                                                    src={URL.createObjectURL(charityMarkerFile)}
                                                    style={{ maxWidth: '100%' }}
                                                    alt="Drop Location Marker"
                                                />
                                            ) : !charityImageError &&
                                              imageFileTypes.includes(
                                                  formik.values.charityMarker.url.split('.').pop()
                                              ) ? (
                                                <img
                                                    src={formik.values.charityMarker.url}
                                                    alt="charity-high-resolution-logo"
                                                    onError={() => setCharityImageError(true)}
                                                    style={{
                                                        maxWidth: '128px',
                                                        maxHeight: '128px'
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                        check_circle
                                                    </Icon>
                                                    <Typography
                                                        style={{
                                                            color: colors.green[500],
                                                            margin: theme.spacing.unit * 2,
                                                            textAlign: 'center'
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        Image Uploaded
                                                    </Typography>
                                                </div>
                                            )}
                                            <FormControl fullWidth data-cy="upload-photo-input">
                                                <input
                                                    accept={'image/*'}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file-charity"
                                                    multiple={false}
                                                    type="file"
                                                    onChange={handleCharityMarkerFile}
                                                />
                                                <label htmlFor="raised-button-file-charity">
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        component="span"
                                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                    >
                                                        Replace Image
                                                    </Button>
                                                </label>
                                            </FormControl>
                                        </div>
                                    )}
                                </Collapse>
                            </Grid>

                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Serviceable Area Link
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                {getTextInput(
                                    theme,
                                    'serviceableAreaLink.link',
                                    'Serviceable Area Link',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Serviceable Area Link',
                                        'URL of webpage informing users of the current serviceable area',
                                        theme
                                    )
                                )}
                                {getSwitch(
                                    theme,
                                    'serviceableAreaLink.isEnabled',
                                    'Show Link',
                                    formik,
                                    {},
                                    {},
                                    undefined,
                                    'Display link to webpage informing users of the current serviceable area'
                                )}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                {getTextInput(
                                    theme,
                                    'serviceableAreaLink.text',
                                    'Link Text',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Link Text',
                                        'Text of link to webpage informing users of the current serviceable area',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Charity Name Similarity Threshold
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'charityNameSimilarityThreshold.charityNameSimilarityThreshold',
                                    'Charity Name Similarity Threshold',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Charity Name Similarity Threshold',
                                        'Minimum degree of similaritybetween two charity name to be considered too close, range from 0 to 1.',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Idle Logout Timeout
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'idleLogoutTimeout.systemAdministrator',
                                    'System Administrator (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'System Administrator Timeout (minutes)',
                                        'Minutes of inactivity after which to log out a System Administrator (0 to disable)',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'idleLogoutTimeout.csr',
                                    'CSR Timeout (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'CSR Timeout (minutes)',
                                        'Minutes of inactivity after which to log out a CSR account (0 to disable)',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'idleLogoutTimeout.collectorAdministrator',
                                    'Collector Administrator Timeout (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Collector Administrator Timeout (minutes)',
                                        'Minutes of inactivity after which to log out a Collector Administrator (0 to disable)',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'idleLogoutTimeout.collectorEmployee',
                                    'Collector Employee Timeout (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Collector Employee Timeout (minutes)',
                                        'Minutes of inactivity after which to log out a Collector Employee (0 to disable)',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {getTextInput(
                                    theme,
                                    'idleLogoutTimeout.customer',
                                    'Customer Timeout (min)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Customer Timeout (minutes)',
                                        'Minutes of inactivity after which to log out a Customer (0 to disable)',
                                        theme
                                    )
                                )}
                            </Grid>
                            {isAUSRegion() && (
                                <Grid xs={12}>
                                    <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                        Customer Invoicing
                                    </Typography>
                                </Grid>
                            )}
                            {isAUSRegion() && (
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'customerInvoicing.defaultFeeInvoicePaymentWindow',
                                        'Default Invoice Payment Window',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Default Invoice Payment Window',
                                            'Default number of days for customer to pay invoice after it is generated',
                                            theme
                                        )
                                    )}
                                </Grid>
                            )}
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Complaints"
                            icon="error"
                            error={
                                _.get(formik.touched, 'complaints', false) && _.get(formik.errors, 'complaints', false)
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Customer Complaints
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                        Add Resolution
                                    </Typography>
                                    <IconButton
                                        onClick={() => handleOpenComplaintsForm('Customer')}
                                        disabled={complaintFormOpen}
                                        color="primary"
                                    >
                                        <Icon>add_circle_outline</Icon>
                                    </IconButton>
                                </div>
                                <DragDropContext onDragEnd={handleDragEndCustomer}>
                                    <Droppable droppableId="ratingPlatformList">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}>
                                                {!configUpdating &&
                                                    !_.isEmpty(config) &&
                                                    config.complaints.customerComplaints.resolutions.map(
                                                        (resolution, idx, arr) => (
                                                            <Draggable
                                                                draggableId={resolution.code}
                                                                index={idx}
                                                                key={resolution.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid item md={12} xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.customerComplaints.resolutions`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.customerComplaints.resolutions`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.customerComplaints.resolutions`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.customerComplaints.resolutions[${idx}]`}
                                                                                togglePath={`complaints.customerComplaints.resolutions[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.customerComplaints.resolutions[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                headerText={
                                                                                    localization[lang].complaints
                                                                                        .customerComplaints.resolutions[
                                                                                        idx
                                                                                    ].label
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}

                                                <div style={{ width: 0 }}>{provided.placeholder}</div>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Counter Complaints
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                        Add Complaint
                                    </Typography>
                                    <IconButton
                                        onClick={() => handleOpenComplaintsForm('Counter')}
                                        disabled={complaintFormOpen}
                                        color="primary"
                                    >
                                        <Icon>add_circle_outline</Icon>
                                    </IconButton>
                                </div>
                                <DragDropContext onDragEnd={handleDragEndCounter}>
                                    <Droppable droppableId="ratingPlatformList">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}>
                                                {!configUpdating &&
                                                    !_.isEmpty(config) &&
                                                    config.complaints.counterComplaints.options.map(
                                                        (option, idx, arr) => (
                                                            <Draggable
                                                                draggableId={option.code}
                                                                index={idx}
                                                                key={option.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        // style={provided.draggableProps.style}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.counterComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.counterComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.counterComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.counterComplaints.options[${idx}]`}
                                                                                togglePath={`complaints.counterComplaints.options[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.counterComplaints.options[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                type="counter"
                                                                                headerText={
                                                                                    localization[lang].complaints
                                                                                        .counterComplaints.options[idx]
                                                                                        .label
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}

                                                <div style={{ width: 0 }}>{provided.placeholder}</div>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Driver Complaints
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                        Add Complaint
                                    </Typography>
                                    <IconButton
                                        onClick={() => handleOpenComplaintsForm('Driver')}
                                        disabled={complaintFormOpen}
                                        color="primary"
                                    >
                                        <Icon>add_circle_outline</Icon>
                                    </IconButton>
                                    {isMissingGPSOption && (
                                        <Button
                                            onClick={handleRecoverGPSOption}
                                            disabled={complaintFormOpen}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Recover GPS Option
                                        </Button>
                                    )}
                                </div>
                                <DragDropContext onDragEnd={handleDragEndDriver}>
                                    <Droppable droppableId="ratingPlatformList">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}>
                                                {!configUpdating &&
                                                    !_.isEmpty(config) &&
                                                    config.complaints.driverComplaints.options.map(
                                                        (option, idx, arr) => (
                                                            <Draggable
                                                                draggableId={option.code}
                                                                index={idx}
                                                                key={option.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        // style={provided.draggableProps.style}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.driverComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.driverComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.driverComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.driverComplaints.options[${idx}]`}
                                                                                togglePath={`complaints.driverComplaints.options[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.driverComplaints.options[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                headerText={
                                                                                    localization[lang].complaints
                                                                                        .driverComplaints.options[idx]
                                                                                        .label
                                                                                }
                                                                                type="driver"
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}

                                                <div style={{ width: 0 }}>{provided.placeholder}</div>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </ConfigWidget>
                        {_user.isSystemAdmin(operator) && (
                            <ConfigWidget
                                theme={theme}
                                formik={formik}
                                title="Audit"
                                icon="policy"
                                error={_.get(formik.touched, 'audit', false) && _.get(formik.errors, 'audit', false)}
                                isSubmitting={formik.isSubmitting}
                                isValid={formik.isValid}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        Pickup Bag Count Difference
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.bagDifference.enabled',
                                        'Enable Bag Difference Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for difference between expected and reported number of bags'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.bagDifference.threshold',
                                        'Max Allowed Pickup Bag Count Difference',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Max Bag Count Difference',
                                            'Threshold difference in number of bags collected that will trigger a bag difference infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.bagDifference.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSelect(
                                        theme,
                                        'audit.bagDifference.enabledCommodities',
                                        'Enabled Commodities',
                                        allCommodities,
                                        formik,
                                        null,
                                        null,
                                        null,
                                        true,
                                        !_.get(formik.values, 'audit.bagDifference.enabled')
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        Customer Amount Per Unit Difference
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.unitDifference.enabled',
                                        'Enable Unit Difference Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for difference between average and reported number of containers per bag'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.unitDifference.percentThreshold',
                                        'Amount Per Bag Difference Threshold (%)',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Amount Per Bag Difference',
                                            'Threshold difference in counts per bag that will trigger a counting threshold infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.unitDifference.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSelect(
                                        theme,
                                        'audit.unitDifference.enabledCommodities',
                                        'Enabled Commodities',
                                        allCommodities,
                                        formik,
                                        null,
                                        null,
                                        null,
                                        true,
                                        !_.get(formik.values, 'audit.unitDifference.enabled')
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        Driver Initiated Complaint
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.driverComplaint.enabled',
                                        'Enable Driver Complaint Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for number of driver initiated complaints'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.driverComplaint.threshold',
                                        'Max Allowed Driver Initiated Complaint',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Max Allowed Driver Initiated Complaint',
                                            'Maximum number of driver initiated complaints within time window before triggering a driver complaint infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.driverComplaint.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.driverComplaint.timeWindow',
                                        'Driver Complaint Time Window (month)',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Driver Complaint Time Window',
                                            'Time window for maximum number of driver initiated complaints before triggering a driver complaint infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.driverComplaint.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSelect(
                                        theme,
                                        'audit.driverComplaint.enabledCommodities',
                                        'Enabled Commodities',
                                        allCommodities,
                                        formik,
                                        null,
                                        null,
                                        null,
                                        true,
                                        !_.get(formik.values, 'audit.driverComplaint.enabled')
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        Counter Initiated Complaint
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.counterComplaint.enabled',
                                        'Enable Counter Complaint Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for number of counter initiated complaints'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.counterComplaint.threshold',
                                        'Max Allowed Counter Initiated Complaint',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Max Allowed Counter Initiated Complaint',
                                            'Maximum number of counter initiated complaints within time window before triggering a driver complaint infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.counterComplaint.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.counterComplaint.timeWindow',
                                        'Counter Complaint Time Window (month)',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Counter Complaint Time Window',
                                            'Time window for maximum number of counter initiated complaints before triggering a driver complaint infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.counterComplaint.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSelect(
                                        theme,
                                        'audit.counterComplaint.enabledCommodities',
                                        'Enabled Commodities',
                                        allCommodities,
                                        formik,
                                        null,
                                        null,
                                        null,
                                        true,
                                        !_.get(formik.values, 'audit.counterComplaint.enabled')
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        Customer Redemption
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.customerRedemptionEmail.enabled',
                                        'Enable Redemption Email Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for different email used to request redemption'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.customerRedemptionAmount.enabled',
                                        'Enable Redemption Amount Infraction',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        'Enables audit check for unusually high redemption amount'
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.customerRedemptionAmount.threshold',
                                        'Customer Redemption Threshold',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Customer Redemption Threshold',
                                            'Threshold difference in redemption amount that will trigger a redemption threshold infraction',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.customerRedemptionAmount.enabled')
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getTextInput(
                                        theme,
                                        'audit.customerRedemptionAmount.timeWindow',
                                        'Redemption Amount Time Window (month)',
                                        formik,
                                        'number',
                                        getInfoIcon(
                                            'Redemption Amount Time Window',
                                            'Time window for total redemption amount to compare against redemption threshold',
                                            theme
                                        ),
                                        null,
                                        null,
                                        !_.get(formik.values, 'audit.customerRedemptionAmount.enabled')
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                        IP Conflict
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.ipConflict.enabled',
                                        'Enable login IP check',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        "Checks IP upon login with all user's login IPs (default: true)"
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.ipConflict.excludePastIPs',
                                        'Only check current login IP',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        "Enable to only check against other user's current login IP, ignores IPs from past devices (default: false)"
                                    )}
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {getSwitch(
                                        theme,
                                        'audit.ipConflict.compareAllUsers',
                                        'Check between all account types',
                                        formik,
                                        { marginTop: theme.spacing.unit * 2 },
                                        {},
                                        undefined,
                                        "Enable to check against all user's login IP, disable to only check between admin and customer accounts (default: false)"
                                    )}
                                </Grid>
                            </ConfigWidget>
                        )}

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Drop Off"
                            icon="warehouse"
                            error={
                                // (_.get(formik.touched, 'dropAndGo.displayQRCode', false) &&
                                //     _.get(formik.errors, 'dropAndGo.displayQRCode', false)) ||
                                // (_.get(formik.touched, 'dropAndGo.allowRequestLabels', false) &&
                                //     _.get(formik.errors, 'dropAndGo.allowRequestLabels', false)) ||
                                (_.get(formik.touched, 'dropAndGo.multiBagDrops', false) &&
                                    _.get(formik.errors, 'dropAndGo.multiBagDrops', false)) ||
                                (_.get(formik.touched, 'quickDropPromo.label', false) &&
                                    _.get(formik.errors, 'quickDropPromo.label', false)) ||
                                (_.get(formik.touched, 'quickDropPromo.description', false) &&
                                    _.get(formik.errors, 'quickDropPromo.description', false)) ||
                                (_.get(formik.touched, 'quickDropPromo.serviceFee', false) &&
                                    _.get(formik.errors, 'quickDropPromo.serviceFee', false))
                                // (_.get(formik.touched, 'dropAndGo.promoCode', false) &&
                                //     _.get(formik.errors, 'dropAndGo.promoCode', false)) ||
                                // (_.get(formik.touched, 'dropAndGo.walkInPromoCode', false) &&
                                //     _.get(formik.errors, 'dropAndGo.walkInPromoCode', false))
                            }
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            {/* <Grid item md={4} xs={12}>
                                {getTextInput(theme, 'dropAndGo.promoCode', 'Default drop off promo code', formik)}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(theme, 'dropAndGo.walkInPromoCode', 'Default walk in promo code', formik)}
                            </Grid> */}
                            {/* <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dropAndGo.displayQRCode',
                                    'Show QR code view from menu/dash',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 },
                                    {},
                                    undefined,
                                    'Show drop off QR code view from the main dashboard or menu'
                                )}
                            </Grid> */}
                            {/* <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dropAndGo.allowRequestLabels',
                                    'Allow customers to request labels',
                                    formik,
                                    { marginTop: theme.spacing.unit * 2 },
                                    {},
                                    undefined,
                                    'Show option to request labels from the main dashboard or menu'
                                )}
                            </Grid> */}
                            <Grid item md={4} xs={12}>
                                {getSwitch(
                                    theme,
                                    'dropAndGo.multiBagDrops',
                                    'Allow multiple (bag) drop offs',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Allow multiple drop offs to be booked without waiting for the first to be processed'
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Promo Code
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'quickDropPromo.label',
                                    'Drop off promo label',
                                    formik,
                                    'text',
                                    getInfoIcon('Drop off promo label', 'Promo label applied for drop offs', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'quickDropPromo.description',
                                    'Drop off promo description',
                                    formik,
                                    'text',
                                    getInfoIcon(
                                        'Drop off promo description',
                                        'Description of promo applied to drop offs',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'quickDropPromo.serviceFee',
                                    'Drop off service fee (%)',
                                    formik,
                                    'text',
                                    getInfoIcon('Drop off service fee', 'Service fee applied to drop offs', theme)
                                )}
                            </Grid>
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Impact Report"
                            icon="eco"
                            error={false}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Water
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.waterAmount',
                                    'Amount per container',
                                    formik,
                                    'number',
                                    getInfoIcon('Amount per container', 'Amount of water per container', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.waterUnit',
                                    'Unit',
                                    formik,
                                    'text',
                                    getInfoIcon('Unit-water', 'Unit of water', theme)
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Energy
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.energyAmount',
                                    'Amount per container',
                                    formik,
                                    'number',
                                    getInfoIcon('Amount per container', 'Amount of energy per container', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.energyUnit',
                                    'Unit',
                                    formik,
                                    'text',
                                    getInfoIcon('Unit-energy', 'Unit of energy', theme)
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Landfill
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.landfillAmount',
                                    'Amount per container',
                                    formik,
                                    'number',
                                    getInfoIcon('Amount per container', 'Amount of landfill per container', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.landfillUnit',
                                    'Unit',
                                    formik,
                                    'text',
                                    getInfoIcon('Unit-landfill', 'Unit of landfill', theme)
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Carbon
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.carbonAmount',
                                    'Amount per container',
                                    formik,
                                    'number',
                                    getInfoIcon('Amount per container', 'Amount of carbon per container', theme)
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'impactReport.carbonUnit',
                                    'Unit',
                                    formik,
                                    'text',
                                    getInfoIcon('Unit-carbon', 'Unit of carbon', theme)
                                )}
                            </Grid>
                        </ConfigWidget>

                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="AI Counts"
                            icon="image_search"
                            error={false}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Upload Quality
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'aiCounts.quality',
                                    'Image Upload Quality % (0-100)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Image upload quality',
                                        'Reduces image quality to increase processing speed. Keep at 100 for no image quality reduction',
                                        theme
                                    )
                                )}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'aiCounts.maxDimensions',
                                    'Image Upload Max Height/Width (px)',
                                    formik,
                                    'number',
                                    getInfoIcon(
                                        'Max image height/width',
                                        'Scales down image to a maximum size according to the entered value. Set to 0 for no scaling',
                                        theme
                                    )
                                )}
                            </Grid>
                        </ConfigWidget>
                        <ConfigWidget
                            theme={theme}
                            formik={formik}
                            title="Theme"
                            icon="palette"
                            error={false}
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        >
                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Light
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'light.primary', false)}
                                                onChange={e => handleColorOverride('light.primary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.light.primary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.light.primary ||
                                                _.get(lightTheme, 'palette.primary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="light.primary"
                                            error={
                                                _.get(formik.touched, 'theme.light.primary') &&
                                                _.get(formik.errors, 'theme.light.primary')
                                                    ? true
                                                    : null
                                            }
                                            label="Primary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.light.primary') &&
                                                _.get(formik.errors, 'theme.light.primary')
                                                    ? _.get(formik.errors, 'theme.light.primary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={lightTheme.palette.primary.main}
                                            onBlur={formik.handleBlur}
                                            label="Primary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'light.secondary', false)}
                                                onChange={e => handleColorOverride('light.secondary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.light.secondary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.light.secondary ||
                                                _.get(lightTheme, 'palette.secondary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="light.secondary"
                                            error={
                                                _.get(formik.touched, 'theme.light.secondary') &&
                                                _.get(formik.errors, 'theme.light.secondary')
                                                    ? true
                                                    : null
                                            }
                                            label="Secondary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.light.secondary') &&
                                                _.get(formik.errors, 'theme.light.secondary')
                                                    ? _.get(formik.errors, 'theme.light.secondary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={lightTheme.palette.secondary.main}
                                            onBlur={formik.handleBlur}
                                            label="Secondary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Dark
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'dark.primary', false)}
                                                onChange={e => handleColorOverride('dark.primary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.dark.primary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.dark.primary ||
                                                _.get(darkTheme, 'palette.primary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="dark.primary"
                                            error={
                                                _.get(formik.touched, 'theme.dark.primary') &&
                                                _.get(formik.errors, 'theme.dark.primary')
                                                    ? true
                                                    : null
                                            }
                                            label="Primary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.dark.primary') &&
                                                _.get(formik.errors, 'theme.dark.primary')
                                                    ? _.get(formik.errors, 'theme.dark.primary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={darkTheme.palette.primary.main}
                                            onBlur={formik.handleBlur}
                                            label="Primary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'dark.secondary', false)}
                                                onChange={e => handleColorOverride('dark.secondary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.dark.secondary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.dark.secondary ||
                                                _.get(darkTheme, 'palette.secondary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="dark.secondary"
                                            error={
                                                _.get(formik.touched, 'theme.dark.secondary') &&
                                                _.get(formik.errors, 'theme.dark.secondary')
                                                    ? true
                                                    : null
                                            }
                                            label="Secondary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.dark.secondary') &&
                                                _.get(formik.errors, 'theme.dark.secondary')
                                                    ? _.get(formik.errors, 'theme.dark.secondary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={darkTheme.palette.secondary.main}
                                            onBlur={formik.handleBlur}
                                            label="Secondary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" style={{ paddingTop: theme.spacing.unit * 2 }}>
                                    Admin
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'admin.primary', false)}
                                                onChange={e => handleColorOverride('admin.primary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.admin.primary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.admin.primary ||
                                                _.get(adminTheme, 'palette.primary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="admin.primary"
                                            error={
                                                _.get(formik.touched, 'theme.admin.primary') &&
                                                _.get(formik.errors, 'theme.admin.primary')
                                                    ? true
                                                    : null
                                            }
                                            label="Primary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.admin.primary') &&
                                                _.get(formik.errors, 'theme.admin.primary')
                                                    ? _.get(formik.errors, 'theme.admin.primary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={adminTheme.palette.primary.main}
                                            onBlur={formik.handleBlur}
                                            label="Primary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(colorOverrides, 'admin.secondary', false)}
                                                onChange={e => handleColorOverride('admin.secondary', e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                Override
                                            </Typography>
                                        }
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />

                                    {colorOverrides.admin.secondary ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={
                                                formik.values.theme.admin.secondary ||
                                                _.get(adminTheme, 'palette.secondary.main')
                                            }
                                            onBlur={formik.handleBlur}
                                            name="admin.secondary"
                                            error={
                                                _.get(formik.touched, 'theme.admin.secondary') &&
                                                _.get(formik.errors, 'theme.admin.secondary')
                                                    ? true
                                                    : null
                                            }
                                            label="Secondary Color"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, 'theme.admin.secondary') &&
                                                _.get(formik.errors, 'theme.admin.secondary')
                                                    ? _.get(formik.errors, 'theme.admin.secondary')
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={adminTheme.palette.secondary.main}
                                            onBlur={formik.handleBlur}
                                            label="Secondary Default"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {getSwitch(theme, 'brandLogo.override', 'Override Brand Logo', formik)}
                                <Collapse in={formik.values.brandLogo.override}>
                                    {_.isNil(brandLogoFile) && _.isNil(formik.values.brandLogo.url) ? (
                                        <FormControl fullWidth data-cy="upload-brand-logo-input">
                                            <input
                                                accept={'image/*'}
                                                style={{ display: 'none' }}
                                                id="raised-button-file-brand-logo"
                                                multiple={false}
                                                type="file"
                                                onChange={handleBrandLogoFile}
                                            />
                                            <label htmlFor="raised-button-file-brand-logo">
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    component="span"
                                                    style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                >
                                                    Upload Image
                                                </Button>
                                            </label>
                                        </FormControl>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {!_.isNil(brandLogoFile) ? (
                                                <img
                                                    src={URL.createObjectURL(brandLogoFile)}
                                                    style={{ maxWidth: '100%' }}
                                                    alt="Drop Location Marker"
                                                />
                                            ) : !brandImageError &&
                                              imageFileTypes.includes(formik.values.brandLogo.url.split('.').pop()) ? (
                                                <img
                                                    src={formik.values.brandLogo.url}
                                                    alt="charity-high-resolution-logo"
                                                    onError={() => setBrandImageError(true)}
                                                    style={{
                                                        maxWidth: '128px',
                                                        maxHeight: '128px'
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                        check_circle
                                                    </Icon>
                                                    <Typography
                                                        style={{
                                                            color: colors.green[500],
                                                            margin: theme.spacing.unit * 2,
                                                            textAlign: 'center'
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        Image Uploaded
                                                    </Typography>
                                                </div>
                                            )}
                                            <FormControl fullWidth data-cy="upload-brand-logo-input">
                                                <input
                                                    accept={'image/*'}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file-brand-logo"
                                                    multiple={false}
                                                    type="file"
                                                    onChange={handleBrandLogoFile}
                                                />
                                                <label htmlFor="raised-button-file-brand-logo">
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        component="span"
                                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                    >
                                                        Replace Image
                                                    </Button>
                                                </label>
                                            </FormControl>
                                        </div>
                                    )}
                                </Collapse>
                            </Grid>
                        </ConfigWidget>

                        <ComplaintsForm
                            lang={lang}
                            initialObj={initialObj}
                            open={complaintFormOpen}
                            onClose={() => setComplaintFormOpen(false)}
                            complaintType={complaintType}
                            onSetField={formik.setFieldValue}
                            onConfigSubmit={formik.handleSubmit}
                            complaintsConfig={_.get(formik, `values.complaints`, {})}
                        />
                    </Grid>
                </>
            )}
            <Dialog open={errorDialogOpen} fullWidth>
                <DialogTitle disableTypography>
                    <Typography variant="h5" style={{ fontWeight: 300, color: colors.red[500] }}>
                        Validation Error
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: colors.red[500] }}>{errorDialogText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ color: colors.red[500] }}
                        onClick={() => {
                            setErrorDialogOpen(false);
                            setErrorDialogText('');
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withTheme()(Configuration);

function ConfigWidget(props) {
    const { children, formik, theme, title, icon, error, isSubmitting, isValid } = props;
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isSubmitting && !isValid) {
            setExpanded(error ? true : false);
        }
    }, [isSubmitting]);

    return (
        <Grid item xs={12} sm={expanded ? 12 : 6} lg={expanded ? 12 : 4} xl={expanded ? 12 : 3}>
            <Paper style={{ padding: theme.spacing.unit * 2, marginTop: theme.spacing.unit * 2 }}>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={10}>
                        <Typography variant="h6" style={{ color: error ? 'red' : '' }}>
                            <IconButton onClick={() => setExpanded(!expanded)} data-cy={`${_.camelCase(title)}-expand`}>
                                <Icon style={{ color: error ? 'red' : 'grey' }}>
                                    {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                </Icon>
                            </IconButton>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Icon
                                style={{
                                    margin: 10,
                                    alignSelf: 'flex-end',
                                    color: error ? 'red' : 'grey'
                                }}
                            >
                                {icon}
                            </Icon>
                        </div>
                    </Grid>
                </Grid>
                <Collapse in={expanded}>
                    <Grid container spacing={theme.spacing.unit}>
                        {children}
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing.unit * 2 }}>
                        <Button
                            color="primary"
                            data-cy={`${_.camelCase(title)}-submit`}
                            onClick={formik.handleSubmit}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </div>
                </Collapse>
            </Paper>
        </Grid>
    );
}

const intuitAccountForm = (formik, accountKey, accountName, theme) => {
    return (
        <Grid item lg={3} md={4} xs={12}>
            <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                {accountName}
            </Typography>
            {getTextInput(
                theme,
                `intuit.accounts.${accountKey}.name`,
                'Name',
                formik,
                'text',
                getInfoIcon(`${accountName} name`, `Name of ${accountName} intuit account`, theme)
            )}
            {getTextInput(
                theme,
                `intuit.accounts.${accountKey}.id`,
                'Id',
                formik,
                'number',
                getInfoIcon(`${accountName} id`, `Id number of ${accountName} intuit account`, theme)
            )}
        </Grid>
    );
};

function flattenObject(object) {
    var toReturn = {};

    for (var key in object) {
        if (!object.hasOwnProperty(key)) continue;

        if (typeof object[key] == 'object' && !_.isNil(object[key])) {
            var flatObject = flattenObject(object[key]);
            for (var nestedKey in flatObject) {
                if (!flatObject.hasOwnProperty(nestedKey)) continue;

                toReturn[key + '.' + nestedKey] = flatObject[nestedKey];
            }
        } else if (!_.isNil(object[key])) {
            toReturn[key] = object[key];
        }
    }
    return toReturn;
}

function getInfoIcon(title, infoText, theme) {
    return {
        endAdornment: (
            <InputAdornment position="end">
                {!_.isEmpty(infoText) && (
                    <Tooltip
                        title={infoText}
                        data-cy={`config-${_.kebabCase(title)}-info-text`}
                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                    >
                        <Icon data-cy={`config-${_.kebabCase(title)}-info-icon`}>info</Icon>
                    </Tooltip>
                )}
            </InputAdornment>
        )
    };
}
