import React, { useState, useEffect, useContext } from 'react';
import { get, isEmpty, isNil, map, includes, filter, isObject } from 'lodash';
import {
    Button,
    Dialog,
    Typography,
    Card,
    CardActions,
    CardContent,
    Icon,
    withTheme,
    LinearProgress
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

import { getRichTextEditorContent } from 'components/RichTextEditor';
import QuestionnaireForm from 'components/QuestionnaireForm';
import useQuestionnaireForm from 'components/QuestionnaireForm/hooks/useQuestionnaireForm';
import HttpContext from 'utils/contexts/HttpContext';

const Announcement = ({ http, bulks, pickups, customer, charityView, adminView, theme, hidden, reload = () => {} }) => {
    const [open, setOpen] = useState(false);
    const [allAnnouncements, setAllAnnouncements] = useState([]);
    const [allQuestionnaires, setAllQuestionnaires] = useState([]);

    const [announcementIndex, setAnnouncementsIndex] = useState(0);

    const [currentAnnouncement, setCurrentAnnouncement] = useState({});
    const [currentQuestionnaires, setCurrentQuestionnaires] = useState([]);

    useEffect(() => {
        const getCustomerPickupCollectorIDs = () => {
            let collectorIDs = [];

            for (let i = 0; i < pickups.length; i++) {
                let currentCollectorID = get(pickups[i], 'collector._id', null);
                if (currentCollectorID !== null && !collectorIDs.includes(currentCollectorID)) {
                    collectorIDs.push(currentCollectorID);
                }
            }

            return collectorIDs;
        };

        const getCustomerUsedTechnologies = () => {
            let technologiesUsed = [];
            let dropOffBulks = bulks.filter(bulk => !isEmpty(bulk.dropLocation));

            for (let i = 0; i < dropOffBulks.length; i++) {
                let technology = get(dropOffBulks[i], 'dropLocation.dropOffType', null);
                if (technology !== null && !technologiesUsed.includes(technology)) {
                    technologiesUsed.push(encodeURIComponent(technology));
                }
            }

            return technologiesUsed;
        };

        const getCustomerAnnouncementsAsync = async () => {
            setAnnouncementsIndex(0);
            let pickupCollectorIDs = getCustomerPickupCollectorIDs();
            var customerAccountType = get(customer, 'location.locationType', null);
            let technologiesUsed = getCustomerUsedTechnologies();

            const res = await http.getJSON(
                `/system/announcement?collectors=${JSON.stringify(pickupCollectorIDs)}&accountType=${JSON.stringify(
                    charityView ? 'Charity' : customerAccountType
                )}&technologies=${JSON.stringify(technologiesUsed)}`
            );

            if (res.ok) {
                const { announcements, questionnaires } = res.data;

                const filteredAnnouncements = [];
                for (const ann of announcements) {
                    var isQuestionnaire = !isEmpty(ann.questionnaire);
                    if (adminView && isQuestionnaire) {
                        continue;
                    }

                    if (get(customer, 'firstLogin', false) && ann.excludeNewUsers) {
                        localStorage.setItem(
                            `announcement-${ann._id.toString()}-${customer._id.toString()}`,
                            ann.__v.toString()
                        );
                        continue;
                    } else if (
                        (!get(customer, 'firstLogin', false) || get(customer, 'isExpressLegacyAccount', false)) &&
                        ann.newUsersOnly
                    ) {
                        localStorage.setItem(
                            `announcement-${ann._id.toString()}-${customer._id.toString()}`,
                            ann.__v.toString()
                        );
                        continue;
                    }

                    if (ann.alwaysShow) {
                        filteredAnnouncements.push(ann);
                    } else {
                        const value = localStorage.getItem(
                            `announcement-${ann._id.toString()}-${customer._id.toString()}`
                        );
                        if (value !== ann.__v.toString()) {
                            filteredAnnouncements.push(ann);
                        }
                    }
                }

                setAllAnnouncements(filteredAnnouncements);
                setAllQuestionnaires(questionnaires);
                setOpen(true);
            }
        };

        getCustomerAnnouncementsAsync();
    }, [charityView]);

    useEffect(() => {
        let announcement = get(allAnnouncements, `[${announcementIndex}]`, {});
        setCurrentAnnouncement(announcement);
    }, [allAnnouncements, announcementIndex]);

    useEffect(() => {
        setCurrentQuestionnaires(
            filter(allQuestionnaires, questionnaire =>
                includes(currentAnnouncement.questionnaire, get(questionnaire, '_id'))
            )
        );
    }, [currentAnnouncement, allQuestionnaires]);

    const handleClose = close => async () => {
        localStorage.setItem(
            `announcement-${currentAnnouncement._id.toString()}-${customer._id.toString()}`,
            currentAnnouncement.__v.toString()
        );
        reload();

        if (close) {
            setOpen(false);
        } else {
            setAnnouncementsIndex(announcementIndex + 1);
        }
    };

    return (
        !isNil(allAnnouncements) && (
            <Dialog
                open={open && !isEmpty(currentAnnouncement) && !hidden}
                onClose={
                    currentAnnouncement.questionnaireRequired && !isEmpty(currentQuestionnaires) && !adminView
                        ? null
                        : handleClose(true)
                }
                maxWidth="sm"
                fullWidth
                style={{
                    zIndex: theme.zIndex.modal - 1 //Make announcement dialog appear below other dialogs
                }}
            >
                <AnnouncementTemplate
                    {...currentAnnouncement}
                    body={getRichTextEditorContent(currentAnnouncement.body)}
                    questionnaire={currentQuestionnaires}
                    customer={customer}
                    adminView={adminView}
                    handleClose={handleClose(announcementIndex === allAnnouncements.length)}
                />
            </Dialog>
        )
    );
};

export const AnnouncementTemplate = ({
    title,
    body,
    backgroundColor,
    textColor,
    icon,
    handleClose,
    alwaysShow,
    customer,
    questionnaire = null,
    questionnaireRequired,
    previewMode = false,
    adminView
}) => {
    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const { responses, formIncomplete, handleResponseSelected, handleCustomReponseChanged } = useQuestionnaireForm({
        questionnaire
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        if (previewMode) return;

        const res = await http.postJSON('/questionnaires/submitResponse', {
            customer: get(customer, '_id'),
            questionnaire: map(questionnaire, questionnaire => get(questionnaire, '_id', '')),
            responses
        });

        if (res.ok) {
            handleClose();
        }
        setIsLoading(false);
    };

    if (!title && !alwaysShow) return null;
    return (
        <>
            <Card style={{ width: '100%', backgroundColor, overflowY: 'auto' }}>
                <CardContent>
                    {icon && (
                        <div style={{ textAlign: 'center' }}>
                            <Icon style={{ color: textColor, fontSize: 75, marginBottom: 8 }} fontSize="large">
                                {icon}
                            </Icon>
                        </div>
                    )}

                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: textColor }}>
                        {title || 'Title'}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: textColor }}>
                        {body || 'Body'}
                    </Typography>

                    {!isEmpty(questionnaire) &&
                        questionnaire.map((questionnaire, index) => (
                            <div key={index} style={{ margingBottom: 20 }}>
                                <QuestionnaireForm
                                    questionnaire={questionnaire}
                                    responses={responses}
                                    textColor={textColor}
                                    onResponseSelected={handleResponseSelected}
                                    onCustomResponseChange={handleCustomReponseChanged}
                                />
                            </div>
                        ))}
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    {!(questionnaireRequired && !isEmpty(questionnaire) && !adminView) && (
                        <Button
                            onClick={handleClose}
                            disabled={isLoading}
                            style={{ color: textColor }}
                            data-cy="announcement-dismiss-btn"
                        >
                            {loc('dismiss', lang)}
                        </Button>
                    )}
                    {!isEmpty(questionnaire) && (
                        <Button
                            data-cy="announcement-submit-btn"
                            variant="outlined"
                            onClick={handleSubmit}
                            disabled={formIncomplete || isLoading}
                            style={{ color: !formIncomplete && textColor, borderColor: !formIncomplete && textColor }}
                        >
                            {loc('submit', lang)}
                        </Button>
                    )}
                </CardActions>
            </Card>
            {isLoading && <LinearProgress />}
        </>
    );
};

export default withTheme()(Announcement);
