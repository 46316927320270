import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import PinTypesForm from './Forms/PinTypesForm';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function PinTypesTable(props) {
    const { operator, theme } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);

    const endpoints = {
        getEndPoint: '/pinTypes',
        getEditEndPoint: _id => `/pinTypes/update`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/pinTypes/create' // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState('createdAt');
    const [activeOrder, setActiveOrder] = useState('desc');

    const { data, loading, handleCreate, handleEdit, handleReloadData } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar,
        fileUpload: true
    });

    const columns = [
        {
            key: 'url',
            header: 'Image',
            formatValue: (value, document) => (
                <img
                    src={value}
                    style={{ height: document.height, width: document.width }}
                    alt={`${document.type} Pin`}
                />
            )
        },
        { key: 'type', header: 'Type' }
    ];

    const editForm = (
        <PinTypesForm
            theme={theme}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            selectedPinType={selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
        />
    );

    const handleDelete = async document => {
        const res = await http.post(`/pinTypes/${document._id}/delete`, {});
        if (res.ok) {
            onSnackbar('Pin type deleted');
            handleReloadData();
        }
    };

    const renderExtraActions = document => (
        <Tooltip title="Delete" disableFocusListener disableTouchListener>
            <IconButton
                onClick={() => {
                    warnAction(() => {
                        return handleDelete(document);
                    }, 'Are you sure you want to delete this map pin type? Return sites using it will reset to the default pin.');
                }}
                data-cy="pin-type-delete-button"
            >
                <Icon>delete</Icon>
            </IconButton>
        </Tooltip>
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={serviceFee => _.get(serviceFee, 'disabled', false)}
                defaultRowsPerPage={5}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Map Pin Types'}
                // disabledHeaderText={'Disabled Warnings'}
                loading={loading}
                hideDateFilters
                renderExtraActions={renderExtraActions}
            />
        </>
    );
}

export default withTheme()(PinTypesTable);
