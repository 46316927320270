import React, { Component } from 'react';
import { Clipboard } from '@capacitor/clipboard';

import _ from 'lodash';

import BaseWidget from './BaseWidget';
import ExternalLink from '../ExternalLink/ExternalLink';

import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';
import { Link } from '@material-ui/core';

class CharityFullInfoWidget extends Component {
    static contextType = LocalizationContext;

    state = {
        featureDialogOpen: false
    };

    handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        this.props.onSnackbar(loc('growthGeneral20', this.context.lang));
    };

    render() {
        const { theme, elevated, charity } = this.props;

        let charityStatus; // TODO: show this
        if (charity.approved) {
            charityStatus = (
                <span style={{ color: colors.green[500] }}>{loc('growthGeneral2', this.context.lang)}</span>
            );
        } else if (!charity.approved && charity.approvalPending) {
            charityStatus = (
                <span style={{ color: colors.orange[500] }}>{loc('dashboardGrowthInfo3', this.context.lang)}</span>
            );
        } else {
            charityStatus = <span style={{ color: colors.red[500] }}>{loc('dashboardMain2', this.context.lang)}</span>;
        }

        let url = !_.isEmpty(charity.url) ? charity.url : charity._id;
        let referralLink = `${process.env.REACT_APP_ORIGIN_URL}/${url}`;

        return (
            <BaseWidget elevated={elevated} style={{ minHeight: '100%' }}>
                <div
                    data-cy="charity-full-info-widget"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                        marginRight: -theme.spacing.unit * 1.5
                    }}
                >
                    <div style={{ overflow: 'hidden', paddingRight: theme.spacing.unit }}>
                        <Typography variant="h6" style={{ marginBottom: theme.spacing.unit, fontSize: 18 }}>
                            {charity.name}
                        </Typography>
                        <img
                            src={!_.isNil(charity.highResImage) ? charity.highResImage : charity.logo}
                            alt={loc('dashboardSetting10', this.context.lang, {
                                organization: terms.ORGANIZATION_NAME
                            })}
                            style={{ maxWidth: 160, maxHeight: 80, marginBottom: theme.spacing.unit }}
                        />
                        <Typography>
                            {loc('status', this.context.lang)}: {charityStatus}
                        </Typography>
                        <Typography style={{ marginTop: theme.spacing.unit }}>{charity.mission}</Typography>
                        {!_.isNil(charity.subdivisions) && !_.isEmpty(charity.subdivisions) && (
                            <Typography style={{ marginTop: theme.spacing.unit }}>
                                {loc('dashboardCharityInfo1', this.context.lang)}: {charity.subdivisions.join(', ')}
                            </Typography>
                        )}
                        {!_.isNil(charity.charityRegistrationNumber) && (
                            <Typography style={{ marginTop: theme.spacing.unit }}>
                                CRN: {charity.charityRegistrationNumber}
                            </Typography>
                        )}
                        <Typography
                            noWrap
                            style={{ marginTop: theme.spacing.unit, textOverflow: 'ellipsis', overflow: 'hidden' }}
                        >
                            {(
                                <ExternalLink
                                    text={loc('dashboardCharityInfo2', this.context.lang)}
                                    url={charity.website}
                                />
                            ) || 'N/A'}
                        </Typography>

                        <div style={{ marginTop: theme.spacing.unit }}>
                            {charity.approved ? (
                                <Typography noWrap>
                                    {loc('dashboardCharityInfo3', this.context.lang)}{' '}
                                    <Link
                                        href={referralLink}
                                        style={{ color: theme.palette.linkColor }}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.handleCopyToClipboard(referralLink)();
                                        }}
                                    >
                                        .../
                                        {url}
                                    </Link>
                                </Typography>
                            ) : (
                                <Typography color="textSecondary">
                                    {loc('dashboardCharityInfo5', this.context.lang)}
                                </Typography>
                            )}
                        </div>

                        <Typography style={{ marginTop: theme.spacing.unit }}>
                            {loc('dashboardCharityInfo4', this.context.lang)} {charity.redemptionEmail}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 50,
                            marginBottom: 50,
                            paddingLeft: theme.spacing.unit,
                            paddingRight: theme.spacing.unit,
                            borderLeft: '1px solid ' + theme.palette.text.hint
                        }}
                    >
                        <IconButton onClick={this.props.onCharityInfoEdit}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </div>
            </BaseWidget>
        );
    }
}

export default withTheme()(CharityFullInfoWidget);
