import React, { useContext } from 'react';
import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import { Dialog, DialogContent, DialogContentText, withTheme, withMobileDialog } from '@material-ui/core';

import QuickdropInfoAndCancel from 'components/QuickdropInfoAndCancel/index';

import { getQuickDropName, isMXDRegion } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../../localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function UncountedQuickdropDialog(props) {
    const {
        open,
        onClose,
        quickdropBulk,
        customer,
        onReloadCustomer,
        http,
        theme,
        fullScreen,
        charityEnabled,
        daysToCount
    } = props;
    const { lang } = useContext(LocalizationContext);

    const handleCancel = () => {
        onReloadCustomer(true);
        onClose();
    };

    const quickDropImages = _.get(quickdropBulk, 'quickdropConfirmationImages', []);

    return (
        <Dialog open={open} fullWidth fullScreen={fullScreen} onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {loc('pickupWidgets4', lang, { type: getQuickDropName(lang) })}
            </DialogTitlePrimary>
            <DialogContent>
                <DialogContentText style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                    <span>{loc('pickupWidgets33', lang, { days: daysToCount })}</span>
                </DialogContentText>
                {!_.isEmpty(quickDropImages) && (
                    <div style={{ textAlign: 'center', margin: '10px' }}>
                        <ImageViewer
                            displayImages={quickDropImages}
                            disableDelete={true}
                            scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                        />
                    </div>
                )}
                <DialogContentText style={{ marginTop: theme.spacing.unit }}>
                    <QuickdropInfoAndCancel
                        quickdropBulk={quickdropBulk}
                        customer={customer}
                        onCancel={handleCancel}
                        http={http}
                        charityEnabled={charityEnabled}
                    />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(UncountedQuickdropDialog));
