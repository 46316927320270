import _ from 'lodash';
import { useState } from 'react';

const useComplaintsForm = complaints => {
    const [initialObj, setInitialObj] = useState({});
    const [complaintFormOpen, setComplaintFormOpen] = useState(false);
    const [complaintType, setComplaintType] = useState(''); // <'Customer' | 'Counter' | 'Driver'>

    const generateNewCode = type => {
        let codeCounter = _.get(complaints, `${type.toLowerCase()}Complaints.codeCounter`);
        if (type === 'Customer') {
            return `R${codeCounter}`;
        } else if (type === 'Counter') {
            return `C${codeCounter}`;
        } else {
            return `D${codeCounter}`;
        }
    };

    const handleOpenComplaintsForm = type => {
        switch (type) {
            case 'Customer':
                setComplaintType(type);
                setInitialObj({
                    code: generateNewCode(type),
                    label: { en: '', fr: '' },
                    description: { en: '', fr: '' },
                    emailToCustomer: true
                });
                setComplaintFormOpen(true);
                break;
            case 'Counter':
                setComplaintType(type);
                setInitialObj({
                    code: generateNewCode(type),
                    label: { en: '', fr: '' },
                    description: { en: '', fr: '' },
                    emailToCustomer: true
                });
                setComplaintFormOpen(true);
                break;
            case 'Driver':
                setComplaintType(type);
                setInitialObj({
                    code: generateNewCode(type),
                    label: { en: '', fr: '' },
                    suggestions: { en: ['', ''], fr: ['', ''] },
                    emailToCustomer: true
                });
                setComplaintFormOpen(true);
                break;
            default:
                setComplaintType(type);
                setInitialObj({
                    code: generateNewCode(type),
                    label: { en: '', fr: '' },
                    description: { en: '', fr: '' },
                    emailToCustomer: true
                });
                setComplaintFormOpen(true);
        }
    };

    return {
        initialObj,
        complaintType,
        complaintFormOpen,
        setComplaintFormOpen,
        handleOpenComplaintsForm
    };
};

export default useComplaintsForm;
