import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';

import { formatAsAddress, getReceiverName } from 'utils/misc';
import { Collapse } from '@material-ui/core';
import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';

function AssignDialog(props) {
    const {
        theme,
        socket,
        open,
        pickupSelected,
        pickupsChecked,
        trips,
        collectorsAvailable,
        onClose,
        unselectEverything,
        filterDate,
        onSnackbar,
        http,
        setRecalculatingTrips
    } = props;
    const [tripAssigned, setTripAssigned] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [selectedTransporter, setSelectedTransporter] = useState();
    const [selectedProcessor, setSelectedProcessor] = useState();

    useEffect(() => {
        setTripAssigned(
            _.uniq(pickupsChecked.map(p => p.trip)).length > 1
                ? null
                : _.first(_.uniq(pickupsChecked.map(p => _.get(p.trip, '_id', p.trip))))
        );
    }, [open]);

    useEffect(() => {
        let trip = _.find(trips, t => t._id.toString() === tripAssigned);
        setSelectedTransporter(_.get(trip, 'transporterCollector._id', _.get(trip, 'transporter.collector._id', null)));
        setSelectedProcessor(_.get(trip, 'collector._id', null));
    }, [tripAssigned]);

    const handleChangeTransporter = e => {
        setSelectedTransporter(e.target.value);
    };

    const handleSubmit = async () => {
        setInProgress(true);

        const res = await http.post('/pickups/reassignPickups', {
            pickup_ids: !_.isEmpty(pickupsChecked) ? pickupsChecked : [pickupSelected._id],
            collector_id: selectedTransporter,
            trip_id: tripAssigned
        });

        if (res.ok) {
            setInProgress(false);
            setTripAssigned('');
            unselectEverything();
            onSnackbar(`Pickup${pickupsChecked.length <= 1 ? '' : 's'} assigned.  Recalculating trip...`);
            onClose();
            if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(true);

            socket.emit(
                'refresh-selected-day',
                {
                    date: filterDate,
                    dateInteger: moment(filterDate).format('YYYYMMDD')
                },
                success => {
                    if (success) {
                        onSnackbar('Trip recalculation complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                    if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(false);
                }
            );
        } else {
            onSnackbar('Failed to reassign pickups. Please try again in a moment.', 'error');
        }
    };
    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={inProgress ? () => undefined : () => onClose()}>
            <DialogTitlePrimary>
                {_.isEmpty(pickupsChecked) && !_.isEmpty(pickupSelected)
                    ? formatAsAddress(pickupSelected.location)
                    : 'Bulk Assignment'}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                <FormControl
                    fullWidth
                    data-cy="pickups-admin-assign-trip-select"
                    style={{ marginTop: theme.spacing.unit / 2 }}
                    margin="normal"
                >
                    <InputLabel htmlFor="driver-assigned">Trip assigned</InputLabel>
                    <Select
                        value={tripAssigned || ''}
                        onChange={event => setTripAssigned(event.target.value)}
                        inputProps={{
                            name: 'tripAssigned',
                            id: 'trip-assigned'
                        }}
                    >
                        <MenuItem key={'none'} value={''} data-cy={`none-pickups-admin-assign-trip-select-li`}>
                            None
                        </MenuItem>
                        {trips.map(trip => {
                            const timezone = _.get(
                                trip,
                                'transporterCollector.timezone',
                                _.get(trip, 'transporter.location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                            );
                            return (
                                <MenuItem
                                    key={trip._id}
                                    value={trip._id}
                                    data-cy={`${getReceiverName(trip.transporter)
                                        .replace(/\s/g, '-')
                                        .toLowerCase()}-pickups-admin-assign-trip-select-li`}
                                >
                                    <span>
                                        <span>
                                            {moment(trip.startTime)
                                                .tz(timezone)
                                                .format('h:mm A')}
                                            <CustomTextBadge
                                                tooltip={
                                                    moment(trip.startTime)
                                                        .tz(timezone)
                                                        .format('h:mm A Z') +
                                                    ' ' +
                                                    timezone
                                                }
                                            >
                                                {moment(trip.startTime)
                                                    .tz(timezone)
                                                    .format('z')}
                                            </CustomTextBadge>
                                        </span>{' '}
                                        <span style={{ color: theme.palette.primary.main }}>
                                            [{_.get(trip, 'transporterCollector.code', 'N/A')}]
                                        </span>{' '}
                                        {getReceiverName(trip.transporter)} ➡{' '}
                                        <span style={{ color: theme.palette.primary.main }}>{trip.collector.code}</span>
                                    </span>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    data-cy="pickups-admin-assign-trip-collector"
                    style={{ marginTop: theme.spacing.unit / 2 }}
                    margin="normal"
                >
                    <InputLabel>Transporter</InputLabel>
                    <Select
                        value={selectedTransporter || ''}
                        onChange={handleChangeTransporter}
                        disabled={!_.isEmpty(tripAssigned)}
                    >
                        {collectorsAvailable
                            .filter(c => _.get(c, 'configuration.enablePickups', false))
                            .map(collector => {
                                return (
                                    <MenuItem key={collector._id} value={collector._id}>
                                        {collector.name} ({collector.code})
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <Collapse in={!_.isEmpty(tripAssigned)}>
                    <FormControl
                        fullWidth
                        data-cy="pickups-admin-assign-trip-collector"
                        style={{ marginTop: theme.spacing.unit / 2 }}
                        margin="normal"
                        disabled
                    >
                        <InputLabel>Processor</InputLabel>
                        <Select value={selectedProcessor || ''} onChange={() => {}} disabled={!_.isNil(tripAssigned)}>
                            {collectorsAvailable.map(collector => {
                                return (
                                    <MenuItem key={collector._id} value={collector._id}>
                                        {collector.name} ({collector.code})
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Collapse>

                {moment().isSame(filterDate, 'day') && (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing.unit }}>
                        <Icon
                            style={{
                                marginRight: theme.spacing.unit / 2,
                                color: theme.palette.error.main
                            }}
                        >
                            warning
                        </Icon>
                        <Typography style={{ color: theme.palette.error.main }}>
                            This operation will alter customer ETAs
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    disabled={inProgress || _.isNil(selectedTransporter)}
                    onClick={handleSubmit}
                    data-cy="pickups-admin-assign-button"
                >
                    Assign
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withTheme()(AssignDialog);
