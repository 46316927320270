import { useState, useEffect } from 'react';
import _ from 'lodash';

import moment from 'moment-timezone';

function useCRUD({
    endpoints,
    setEditDialogOpen,
    setActiveOrderBy,
    setActiveOrder,
    http,
    onSnackbar,
    getQuery = '',
    fileUpload = false
}) {
    const startMonth = moment(new Date())
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .startOf('month');
    const endMonth = moment(new Date())
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .endOf('day');
    const [startDateFilterStats, setStartDateFilterStats] = useState(startMonth);
    const [endDateFilterStats, setEndDateFilterStats] = useState(endMonth);
    const [dateFilterStatsErrorMessage, setDateFilterStatsErrorMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [otherStats, setOtherStats] = useState({});
    const [defaults, setDefaults] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            if (new Date(startDateFilterStats) > new Date(endDateFilterStats)) {
                setDateFilterStatsErrorMessage('End date is before start date');
            } else {
                setLoading(true);
                setDateFilterStatsErrorMessage('');
                let resGetData = await http.getJSON(
                    `${endpoints.getEndPoint}?startDate=${new Date(
                        startDateFilterStats
                    ).toISOString()}&endDate=${new Date(endDateFilterStats).toISOString()}${getQuery}`
                );
                if (resGetData.ok) {
                    setData(resGetData.data.collectionData);
                    setOtherStats(resGetData.data.otherStats);
                    setDefaults(_.get(resGetData, 'data.defaults', {}));
                }
                /*setRowsPerPage(
                    defaultRowsPerPage === 'All' ? resGetData.data.collectionData.length : defaultRowsPerPage
                );*/
                setLoading(false);
            }
        };

        fetchData();
    }, [startDateFilterStats, endDateFilterStats]);

    const handleReloadData = async () => {
        let resGetData = await http.getJSON(
            `${endpoints.getEndPoint}?startDate=${new Date(startDateFilterStats).toISOString()}&endDate=${new Date(
                endDateFilterStats
            ).toISOString()}${getQuery}`
        );
        if (resGetData.ok) {
            setData(resGetData.data.collectionData);
            setOtherStats(resGetData.data.otherStats);
            setDefaults(_.get(resGetData, 'data.defaults', {}));
        }
    };

    const handleCreate = async newValues => {
        setLoading(true);
        const resCreate = await http.postJSON(endpoints.createEndPoint, newValues, true, fileUpload);
        if (resCreate.ok) {
            await handleReloadData();
            setEditDialogOpen(false);
            setActiveOrderBy('createdAt');
            setActiveOrder('desc');
        } else {
            onSnackbar(resCreate.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleEdit = async values => {
        setLoading(true);
        let resUpdate = await http.postJSON(endpoints.getEditEndPoint(values._id), values, true, fileUpload);
        if (resUpdate.ok) {
            await handleReloadData();
            setEditDialogOpen(false);
        } else {
            onSnackbar(resUpdate.errorMessage, 'error');
        }
        setLoading(false);
    };

    return {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        defaults,
        handleCreate,
        handleEdit,
        handleReloadData
    };
}

export default useCRUD;
