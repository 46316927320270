import React, { useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { Icon, withTheme, Typography, Avatar, CircularProgress } from '@material-ui/core';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { loc } from '../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function ComplimentsWidget(props) {
    const { lang } = useContext(LocalizationContext);

    const { operator, pickupsWithCompliments, theme, loading, noPadding, style, height } = props;

    let allCompliments = (pickupsWithCompliments || []).map(pickup => {
        //currently no way to resolve pickup compliments, display as resolved by default
        return {
            complimentId: pickup._id,
            complimentItemType: 'pickup',
            date: pickup.completionDate,
            unresolved: false,
            complimentDescription: pickup.customerCompliment.complimentDescription
        };
    });

    //sort by unresolved/resolved then by date
    allCompliments.sort((a, b) => {
        if (a.date > b.date) {
            return -1;
        } else if (a.date < b.date) {
            return 1;
        } else {
            return 0;
        }
    });

    return loading ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 200,
                height: height ? height : undefined,
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <div
            style={{
                width: '100%',
                overflowY: 'scroll',
                maxHeight: 200,
                height: height ? height : undefined,
            }}
        >
            <List dense>
                {_.isEmpty(allCompliments) ? (
                    <ListItem>
                        <Typography>{loc('noCompliments', lang)}</Typography>
                    </ListItem>
                ) : (
                    allCompliments.map(compliment => (
                        <ListItem>
                            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
                                <Icon>favorite</Icon>
                            </Avatar>
                            <ListItemText
                                style={{ fontSize: '0.875rem' }}
                                primary={`"${compliment.complimentDescription}"`}
                                secondary={
                                    loc('pickupCompliment', lang) +
                                    ` - ${moment(compliment.date).format('MMM DD, YYYY')}`
                                }
                            />
                        </ListItem>
                    ))
                )}
            </List>
        </div>
    );
}

export default withTheme()(ComplimentsWidget);
