import { useState, useReducer, useMemo, useEffect, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    COMMERCIAL,
    BOTTLE_DRIVE,
    AVAILABLE_FREQUENCIES,
    DROPANDGO,
    EXPRESS,
    WALK_IN,
    INSTRUCTIONS,
    DROP_OFF_TYPES,
    ACCESS_DOOR
} from 'constants.js';

import { validate, validatePayload } from 'utils/validate';

import { _user, _zone, _time, _commodity, _pickup } from 'std';

import { getPlaceDetails } from 'utils/mapfunc';
import GoogleContext from 'utils/contexts/GoogleContext';
import OperatorContext from 'utils/contexts/OperatorContext';

import pickupFormReducer from './pickupFormReducer';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import useImageUpload from 'components/ImageUploads/useImageUpload';
import { convertToImportName } from 'utils/misc';

const Analytics = require('utils/analytics.js');

function usePickupDialog({
    auth,
    http,
    google,
    history,
    pickups,
    selectedPickup,
    pickupPending,
    incompletePickups,
    charitySelected,
    subdivisionPreferred,
    commoditiesAvailable,
    charities,
    customer,
    onClose,
    onCancel,
    onSubmitSuccess,
    onSnackbar,
    mapLocationSelection,
    setMapLocationSelected,
    setMapLocationSelection,
    handleResetDropAndGo
}) {
    const operator = useContext(OperatorContext);
    const { lang } = useContext(LocalizationContext);
    const initialPickupForm = useMemo(
        () =>
            generateInitialPickupForm({
                selectedPickup,
                customer,
                pickupPending,
                pickups,
                subdivisionPreferred,
                commoditiesAvailable
            }),
        [selectedPickup, customer, pickupPending, pickups, subdivisionPreferred, commoditiesAvailable]
    );

    const isSystemAdmin = useMemo(() => {
        return _user.isSystemAdmin(auth) || _user.isCollectorAdmin(auth) || _user.isInternalRole(auth);
    }, [auth]);

    const [mode, setMode] = useState(pickupPending ? 'ACTION' : 'CREATE'); // CREATE, CANCEL, ACTION modes instead of several open dialog booleans
    const [pickupForm, dispatchPickupForm] = useReducer(pickupFormReducer, initialPickupForm);
    const [inProgress, setInProgress] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [helpDialogTopic, setHelpDialogTopic] = useState(undefined);
    const [activeStep, setActiveStep] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [cancelReason, setCancelReason] = useState({ reason: '', comments: '' });
    const [prevStep, setPrevStep] = useState(0);
    const [suggestedCoordinates, setSuggestedCoordinates] = useState(null);
    const [invalidCommoditiesToBeRemoved, setInvalidCommoditiesToBeRemoved] = useState([]);

    const [availableBookings, setAvailableBookings] = useState([]);
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [showTimeSlots, setShowTimeSlots] = useState(false);
    const [showBypassZoneOptions, setShowBypassZoneOptions] = useState(false);

    const [timeOverride, setTimeOverride] = useState(_.get(pickupForm, 'manualTimeOverride', false));

    const [minFrequency, setMinFrequency] = useState(1);
    const [minFrequencyPickupTypes, setMinFrequencyPickupTypes] = useState([]);
    const [minPickupFeeEnabled, setMinPickupFeeEnabled] = useState(false);
    const [lowVolumeContainerThreshold, setLowVolumeContainerThreshold] = useState(0);

    const [collectionProgramOptions, setCollectionProgramOptions] = useState({});

    const [defaultToInstructions, setDefaultToInstructions] = useState(false);
    const [defaultToActionStep, setDefaultToActionStep] = useState(false);
    const [technologyTypeForInstructions, setTechnologyTypeForInstructions] = useState('');

    const [technologyFilter, setTechnologyFilter] = useState(null);

    const { placesService } = useContext(GoogleContext);

    const { imagePreviews, handleDeleteImage, handleUploadImage, setImagePreviews, uploadingImage } = useImageUpload(
        {}
    );

    useEffect(() => {
        const { location } = pickupForm;

        if (!_.isNil(customer.location)) {
            if (customer.location.lat !== location.lat && customer.location.lat !== location.lng) {
                setActiveStep(0);
                dispatchPickupForm({ type: 'set-location', location: customer.location });
            }
        }
    }, [customer.location.lat, customer.location.lng]);

    const resetFormToDefault = () => {
        dispatchPickupForm({ type: 'change-selected', initialPickupForm });
    };

    const validateForm = () => {
        const {
            payload,
            subPayloads,
            comment,
            pickupType,
            location,
            businessName,
            zone,
            phone,
            binRequested,
            binRequestMessage
        } = pickupForm;

        const payloadErrors = validatePayload(
            _.get(zone, 'payloadAccepted', []),
            _zone.getPayloadRequired(zone),
            payload,
            customer.adminView,
            subPayloads,
            _.get(customer, 'collectionProgramPreference', false),
            _.get(collectionProgramOptions, 'minimumRequiredPayload', 0)
        );

        setFormErrors({
            ...payloadErrors,
            comment: validate(['max-length:800', 'required'], comment, lang),
            unitNumber: validate(['max-length:100'], location.unitNumber, lang),
            buzzerCode: validate(['max-length:10'], location.buzzerCode, lang),
            businessName: validate(['max-length:100'], businessName, lang),
            location: validate(['gps'], `${location.lat},${location.lng}`, lang),
            phone: validate(['phone'], phone, lang),
            binRequestMessage: binRequested
                ? validate(['max-length:400', 'required'], binRequestMessage.trim(), lang)
                : { fail: false, reason: null }
        });
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/pickups/frequencySettings');
            if (res.ok) {
                setMinFrequency(_.get(res, 'data.minFrequency', 0));
                setMinFrequencyPickupTypes(_.get(res, 'data.minFrequencyPickupTypes', []));
            }

            const res2 = await http.getJSON('/pickups/minVolumeFee');
            if (res2.ok) {
                setMinPickupFeeEnabled(_.get(res2, 'data.minPickupFeeEnabled', false));
                setLowVolumeContainerThreshold(_.get(res2, 'data.lowVolumeContainerThreshold', 0));
            }
            const res3 = await http.getJSON('/system/configuration/collections', false, true);
            if (res3.ok) {
                setCollectionProgramOptions(res3.data.config);
            }
        })();
    }, []);

    useEffect(() => {
        resetFormToDefault();
        if (selectedPickup) {
            const payloadImages = _.get(selectedPickup, 'images.payloadImages', []);
            setImagePreviews(payloadImages);
        } else {
            setImagePreviews([]);
        }

        let unserviceable = !_.isNil(selectedPickup) ? selectedPickup.unserviced : false;
        if (unserviceable) {
            setCancelReason('Unserviceable');
        } else {
            setCancelReason('');
        }
        if (pickupPending) {
            setMode('ACTION');
        } else {
            setMode('CREATE');
        }
    }, [selectedPickup, pickupPending]);

    useEffect(() => {
        // validation watcher
        const bypassZoneChecksReq = bypassZoneChecksRequired(pickupForm);
        setShowBypassZoneOptions(bypassZoneChecksReq);

        validateForm();
    }, [pickupForm]);

    useEffect(() => {
        // watched for charity selected changed
        if (!_.isNil(charitySelected)) {
            dispatchPickupForm({
                type: 'set-charity',
                charity: charitySelected._id,
                subdivision: subdivisionPreferred
            });
        }
    }, [charitySelected, subdivisionPreferred]);

    const handleActionDialog = isOpen => {
        let activeStep = 0;
        if (!isOpen && !_.isNil(pickupForm.pickupType)) {
            // skip pickup type if necessary
            activeStep = 1;
        }
        setMode('CREATE');
        setActiveStep(activeStep);
    };

    const handleNext = () => {
        setPrevStep(activeStep);
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setPrevStep(activeStep);
        setActiveStep(activeStep - 1);
    };

    const googlePlace = async () => {
        const { location } = pickupForm;
        if (_.get(location, 'place_id', undefined)) {
            return await getPlaceDetails(
                placesService,
                location.place_id,
                google.maps.places.PlacesServiceStatus.OK,
                http
            );
        }
        return undefined;
    };

    const handleDateChange = date => {
        dispatchPickupForm({ type: 'set-date-change', date });
    };

    const handleTimePreferenceChange = timePreference => {
        dispatchPickupForm({ type: 'set-time-preference', timePreference });
    };

    const handleOverrideAlwaysConfirmChange = toggle => {
        dispatchPickupForm({ type: 'toggle-always-confirm-override', toggle });
    };

    const handleEfficiencyModifierChange = efficiencyModifier => {
        dispatchPickupForm({ type: 'set-efficiency-modifier', efficiencyModifier });
    };

    const handleOverrideProcessorChange = overrideProcessor => {
        dispatchPickupForm({ type: 'set-override-processor', overrideProcessor });
    };

    const handleOverrideTransporterChange = overrideTransporter => {
        dispatchPickupForm({ type: 'set-override-transporter', overrideTransporter });
    };

    const handleTipAmountChange = tipAmount => {
        dispatchPickupForm({ type: 'set-tip-amount', tipAmount });
    };

    const handleRequestBinChange = binRequested => {
        dispatchPickupForm({ type: 'set-bin-requested', binRequested });
    };

    const handleBinRequestMessageChange = binRequestMessage => {
        dispatchPickupForm({ type: 'set-bin-request-message', binRequestMessage });
    };

    // handles both cases when user has just selected a new 1s or already had one pre-populated
    const handleAddressNext = async (overrideLocation = null, resetDate = false) => {
        const { location, pickupType, receiver, frequency, promos } = pickupForm;

        setInProgress(true);

        let locationUpdated = _.cloneDeep(location);

        // Get and set longitude/latitude for pickup
        if (overrideLocation && !_.isNil(overrideLocation.lat) && !_.isNil(overrideLocation.lng)) {
            locationUpdated.lat = overrideLocation.lat;
            locationUpdated.lng = overrideLocation.lng;
            locationUpdated.isCustomGPS = true;
            dispatchPickupForm({ type: 'set-location', location: locationUpdated });
        } else if (_.isNil(location.lat) || _.isNil(location.lng) || _.isNil(location.postalCode)) {
            //const service = new google.maps.places.PlacesService(document.createElement('div'));
            const place = await googlePlace();

            if (place && (_.isNil(location.lat) || _.isNil(location.lng))) {
                locationUpdated.lat = place.geometry.location.lat();
                locationUpdated.lng = place.geometry.location.lng();
            }

            if (place && _.isNil(location.postalCode)) {
                locationUpdated.postalCode = _.get(
                    _.find(place.address_components, component => component.types[0] === 'postal_code'),
                    'short_name',
                    undefined
                );
            }
            dispatchPickupForm({ type: 'set-location', location: locationUpdated });
        }
        dispatchPickupForm({
            type: 'set-efficiency-modifier',
            efficiencyModifier: getPreviousEfficiencyModifier(pickups, customer, location)
        });

        let url = `/zone/dates?lat=${locationUpdated.lat}&lng=${locationUpdated.lng}`;

        if (!_.isEmpty(pickupType)) {
            url += `&pickupType=${pickupType}`;
        }

        if (!_.isEmpty(customer)) {
            url += `&customer_id=${customer._id}`;
        }

        const res = await http.getJSON(url);

        if (res.ok) {
            let payload = _.cloneDeep(_.get(pickupForm, 'payload', _pickup.createEmptyPayload()));
            let subPayloads = _.cloneDeep(_.get(pickupForm, 'subPayloads', []));

            // pull the data out of the res
            const { zone, timezone, availableDates, customerNextPickupDate, receivers, serviceEmail } = res.data;
            if (_.isEmpty(availableDates)) {
                if (
                    process.env.REACT_APP_ENV === 'AUS' &&
                    zone.active &&
                    _.get(zone, 'startingDates', []).every(date => moment(date).isAfter(moment()))
                ) {
                    onSnackbar(
                        loc('pickupDialogError2', lang, {
                            date: moment(
                                _.first(
                                    _.get(zone, 'startingDates', []).sort((a, b) => moment(a).diff(moment(b), 'days'))
                                )
                            )
                                .tz(timezone)
                                .locale(lang)
                                .format('MMM Do, YYYY'),
                            email: serviceEmail
                        }),
                        'info'
                    );
                } else {
                    onSnackbar(loc('pickupDialogError1', lang), 'info');
                }
            } else {
                let date;
                let updatedAvailableDates = availableDates;

                // Get mobile time slot bookings available dates
                if (_.get(zone, 'collector.configuration.mobileTimeSlotBookings', false)) {
                    const res2 = await http.getJSON(
                        `/availableTimeSlots?zoneId=${zone._id}&selectedPickup=${_.get(selectedPickup, '_id', null)}`
                    );

                    if (res2.ok) {
                        const availableBookings = _.get(res2, 'data.availableDates', []);
                        // const timezone = _.get(zone, 'timezone', process.env.TIMEZONE);

                        const objForSelectedDate = _.find(availableBookings, availableDate => {
                            const dateToCheck = moment(availableDate).tz(timezone);
                            const selectedDateAsMoment = moment(date).tz(timezone);

                            return dateToCheck.isSame(selectedDateAsMoment, 'day');
                        });

                        const timeSlotsForSelectedDate = _.get(objForSelectedDate, 'availableTimes', []);

                        // Sets default time to first available timeslot
                        if (!_.isEmpty(timeSlotsForSelectedDate)) {
                            date = momentForLocation(timeSlotsForSelectedDate[0], location);
                        }

                        // dates to show on date picker
                        updatedAvailableDates = availableBookings.map(bookingDay => bookingDay.day);

                        setShowTimeSlots(true);
                        setAvailableTimeSlots(timeSlotsForSelectedDate);
                        setAvailableBookings(availableBookings);
                    }
                } else {
                    // if there is a date and the selectedPickup.zone equals the new zone, then keep the date the same
                    // otherwise grab the first avaiable date

                    date =
                        !resetDate &&
                        !_.isEmpty(pickupForm.date) &&
                        _.get(selectedPickup, 'zone._id', null) === _.get(zone, '_id', null)
                            ? pickupForm.date
                            : _.first(updatedAvailableDates);
                }

                // const feeSubPayloads = _commodity.generateSubPayloadsForFees(
                //     { location: locationUpdated, customer },
                //     commoditiesAvailable
                // );

                // const emptyFeeSubPayloads = _.isEmpty(feeSubPayloads);
                // if (!emptyFeeSubPayloads) {
                //     payload = { beverage: 0 }; // reset the payload to 0 as it will use fees to populate
                //     subPayloads = feeSubPayloads;
                // }

                // if frequency doesn't exist, determine default frequency
                let newFrequency = frequency;
                if (newFrequency === undefined) {
                    // WARNING: Change to a lower frequency may conflict with customer frequency UI options
                    const frequencies = AVAILABLE_FREQUENCIES[_.get(zone, 'frequency')];
                    if (pickupType === COMMERCIAL) {
                        newFrequency = _.first(frequencies);
                    } else {
                        newFrequency = _.last(frequencies);
                    }
                } else {
                    newFrequency = _zone.isValidFrequency(pickupForm.frequency, zone) ? pickupForm.frequency : null;

                    // if (emptyFeeSubPayloads) {

                    // if (!pickupPending) {
                    //     // if brand new pickup, grab only the beverage payload
                    //     payload = _pickup.getBeveragePayload(payload);
                    // }
                    // }
                }
                const oldPayload = _.cloneDeep(payload);
                const oldSubPayloads = _.cloneDeep(subPayloads);
                payload = _pickup.removeItemsNotAllowedByZone({ payload }, zone);
                subPayloads = _pickup.removeSubPayloadsNotAllowedByZone({ subPayloads }, zone);
                subPayloads = _pickup.removeSubPayloadsWithEmptyOrDisabledSubCommodity(
                    { subPayloads },
                    commoditiesAvailable
                );
                if (isSystemAdmin && (!_.isEqual(oldPayload, payload) || !_.isEqual(oldSubPayloads, subPayloads))) {
                    let commodityDifference = [];
                    _.keys(oldPayload).forEach(skuType => {
                        if (_.get(payload, skuType, 0) !== _.get(oldPayload, skuType, 0)) {
                            const commodity = _.find(commoditiesAvailable, c => c.skuType === skuType);
                            commodityDifference.push({
                                name: _.get(commodity, `customerDisplayName.${lang}`, skuType),
                                amount: _.get(oldPayload, skuType, 0) - _.get(payload, skuType, 0),
                                icon: _.get(commodity, 'iconName', 'mdiHelpCircle'),
                                color: _.get(commodity, 'color')
                            });
                        }
                    });
                    oldSubPayloads.forEach(item => {
                        if (
                            !_.find(
                                subPayloads,
                                sp =>
                                    _.get(sp, 'subCommodity._id', '').toString() ===
                                    _.get(item, 'subCommodity._id', '').toString()
                            )
                        ) {
                            const commodity = _.find(commoditiesAvailable, c => c.skuType === item.skuType);
                            const subCommodity = _.find(
                                _.get(commodity, 'subCommodities', []),
                                sc => sc._id.toString() === _.get(item, 'subCommodity._id', '').toString()
                            );
                            commodityDifference.push({
                                name: _.get(subCommodity, `name.${lang}`, item.skuType),
                                amount: item.amount,
                                icon: convertToImportName(_.get(subCommodity, 'iconName', 'help-circle')),
                                color: _.get(subCommodity, 'color')
                            });
                        }
                    });
                    setInvalidCommoditiesToBeRemoved(commodityDifference);
                }
                let updatedReceiver = _.get(receiver, '_id', null);
                // if existing receiver is no longer valid (null or not a part of the active driver list)

                if (!resetDate && _.some(promos, { oneDayOnly: true })) {
                    const oneDayOnlyPromo = _.find(promos, { oneDayOnly: true });
                    const oneDayPromoDate = oneDayOnlyPromo.startDate;
                    date = oneDayPromoDate;
                }

                dispatchPickupForm({
                    type: 'set-address-data',
                    date: moment(date).tz(timezone), // must be a moment object, not an ISO string
                    availableDates: updatedAvailableDates,
                    zone,
                    frequency: newFrequency,
                    payload,
                    subPayloads,
                    customerNextPickupDate,
                    receivers,
                    receiver: updatedReceiver,
                    timezone: timezone
                });

                handleNext();
            }
        }

        setInProgress(false);
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        const { location } = pickupForm;

        const locationPlaceIsSuggested = location.place_id === place.place_id;
        const locationHasValidCoordinates = !_.isNil(location.lat) && !_.isNil(location.lng);
        if (locationPlaceIsSuggested && locationHasValidCoordinates) return;

        const placeTypes = _.get(place, 'types', []);
        const streetAddressTypes = ['street_address', 'premise', 'establishment'];
        const isStreet = _.isEmpty(_.intersection(placeTypes, streetAddressTypes));

        let locationUpdated = {
            place_id: place.place_id,
            description: suggestion.description,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            postalCode: _.get(
                _.find(place.address_components, component => component.types.includes('postal_code')),
                'short_name',
                undefined
            ),
            unitNumber: location.unitNumber || '',
            buzzerCode: location.buzzerCode || '',
            isCustomGPS: false,
            city: _.get(_.find(place.address_components, ac => ac.types.includes('locality')), 'long_name', undefined),
            province: _.get(
                _.find(place.address_components, ac => ac.types.includes('administrative_area_level_1')),
                'long_name',
                undefined
            )
        };

        setMapLocationSelected({ lat: locationUpdated.lat, lng: locationUpdated.lng });
        setSuggestedCoordinates({ lat: locationUpdated.lat, lng: locationUpdated.lng });
        dispatchPickupForm({ type: 'set-selected-suggestion', location: locationUpdated });
    };

    const handleManualGPSOverride = suggestion => {
        const { location } = pickupForm;
        let locationUpdated = _.cloneDeep(location);

        locationUpdated.lat = suggestion.lat;
        locationUpdated.lng = suggestion.lng;
        locationUpdated.isCustomGPS = true;

        dispatchPickupForm({ type: 'set-location', location: locationUpdated });
    };

    const handleGPSReset = async () => {
        const { location } = pickupForm;
        let locationUpdated = _.cloneDeep(location);

        const place = await googlePlace();

        if (place) {
            locationUpdated.lat = place.geometry.location.lat();
            locationUpdated.lng = place.geometry.location.lng();
            locationUpdated.isCustomGPS = false;

            if (_.isNil(location.postalCode)) {
                locationUpdated.postalCode = _.get(
                    _.find(place.address_components, component => component.types[0] === 'postal_code'),
                    'short_name',
                    undefined
                );
            }

            dispatchPickupForm({ type: 'set-location', location: locationUpdated });
        }
    };

    const togglePhoneOverride = e => {
        dispatchPickupForm({ type: 'toggle-phone-override' });
    };

    const handleChange = e => {
        let path = e.target.name;
        let value = e.target.value;
        dispatchPickupForm({ type: 'set', path, value });
    };

    const handlePastLocationData = pastLocation => {
        dispatchPickupForm({ type: 'set-past-location-data', pastLocation });
    };

    const handleDefaultTrip = _id => {
        dispatchPickupForm({ type: 'set', path: 'trip', value: _id });
    };

    const handleCancelChange = e => {
        setCancelReason({ ...cancelReason, [e.target.name]: e.target.value });
    };

    const handleAvailableTimeSlots = timeSlots => {
        setAvailableTimeSlots(timeSlots);
    };

    const handleShowTimeOverride = () => {
        setTimeOverride(!timeOverride);
    };

    const toggleDonate = () => {
        dispatchPickupForm({ type: 'toggle-donation' });
    };

    const handleCharityPreferred = (_id, subdivision = null) => () => {
        if (!customer.lockDonationPreference) {
            dispatchPickupForm({ type: 'set-charity-preferred', charityPreferred: _id, subdivision: subdivision });
        }
    };

    const handleSetDonation = state => {
        dispatchPickupForm({ type: 'set-donation', donate: state });
    };

    const toggleTaxReceiptRequested = () => {
        dispatchPickupForm({ type: 'toggle-tax-receipts' });
    };

    const toggleRecurring = () => {
        dispatchPickupForm({ type: 'toggle-recurring' });
    };

    const handlePayload = e => {
        const path = e.target.name;
        const value = e.target.value;

        dispatchPickupForm({ type: 'set-payload', payload: _.set(pickupForm.payload, path, value) });
    };

    const handleSubPayloads = e => {
        const { subPayloads } = pickupForm;

        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSubPayloads = _.cloneDeep(subPayloads);

        const commodity = _commodity.getCommodityBySubCommodityId(commoditiesAvailable, path);
        const subCommodity = _commodity.getSubCommodityById(commoditiesAvailable, path);

        let subPayload = _.find(updatedSubPayloads, subPayload => {
            const id = _.get(subPayload, 'subCommodity._id');
            return id === path;
        });

        if (!commodity || !subCommodity) return;

        if (subPayload) {
            subPayload.amount = value;
        } else {
            updatedSubPayloads.push({
                skuType: commodity.skuType,
                amount: value,
                subCommodity: subCommodity
            });
        }

        dispatchPickupForm({ type: 'set-sub-payloads', subPayloads: updatedSubPayloads });
    };

    const handleApplyPromo = promo => {
        const isOneDayOnly = _.get(promo, 'oneDayOnly', false);
        if (isOneDayOnly) {
            const date = moment(promo.startDate).startOf('day');
            dispatchPickupForm({ type: 'set-date-change', date });
        }

        dispatchPickupForm({ type: 'add-promo', promo });
    };

    const handleRemovePromo = async (promo, actions = {}) => {
        const isOneDayOnly = _.get(promo, 'oneDayOnly', false);
        if (isOneDayOnly) {
            // Reset date for one day promos
            dispatchPickupForm({ type: 'set-date-change', date: null });
        }

        const { returnToDateSelect } = actions;
        if (returnToDateSelect) {
            await handleAddressNext(null, true);
            setActiveStep(2);
        }

        dispatchPickupForm({ type: 'remove-promo', promo });
    };

    const handleClearPromos = () => {
        dispatchPickupForm({ type: 'clear-promos' });
    };

    const handlePromoInputSetDonation = (charity, subdivision = null) => {
        dispatchPickupForm({
            type: 'set-charity-preferred',
            charityPreferred: typeof charity === 'object' ? _.get(charity, '_id') : charity,
            subdivision: subdivision
        });
        dispatchPickupForm({ type: 'set-donation', donate: true });
    };

    const handleInHouse = (pickupType = 'Drop&Go') => {
        const { recurring, donate, charityPreferred } = pickupForm;
        setActiveStep(1);
        dispatchPickupForm({
            type: 'set-pickup-type-selected',
            pickupType,
            donate: donate,
            recurring: recurring,
            charityPreferred: charityPreferred,
            organizationName: '',
            customTime: undefined
        });
    };

    const handleSelectPickupType = (pickupType, service, noNext = false) => () => {
        const { recurring, donate, charityPreferred, payload } = pickupForm;

        let updatedRecurring = recurring;
        let updatedDonation = donate;
        let updatedCharityPreferred = charityPreferred;
        payload.beverage = _.get(payload, 'beverage', 0) > 0 ? _.get(payload, 'beverage', 0) : 0;
        if (pickupType === BOTTLE_DRIVE) {
            // disable recurring on bottle drives
            updatedRecurring = false;
            payload.beverage = _.get(payload, 'beverage', 0) > 50 ? _.get(payload, 'beverage', 0) : 50;
        } else if (pickupType === COMMERCIAL) {
            updatedRecurring = true;
        } else if ([DROPANDGO, EXPRESS, WALK_IN].includes(pickupType)) {
            setTechnologyFilter(_.get(service, 'technologies', []));
        } else if (pickupType === INSTRUCTIONS) {
            setTechnologyFilter(
                _.filter(DROP_OFF_TYPES, d => d.localizationName === _.get(service, 'instructionType', '')).map(
                    d => d.value
                )
            );
        } else if (pickupType === ACCESS_DOOR) {
            setTechnologyFilter(_.filter(DROP_OFF_TYPES, d => d.value === pickupType).map(d => d.value));
        }
        setDefaultToInstructions(pickupType === INSTRUCTIONS);
        setDefaultToActionStep(pickupType === ACCESS_DOOR);
        setTechnologyTypeForInstructions(
            pickupType === ACCESS_DOOR ? 'accessDoor' : _.get(service, 'instructionType', '')
        );

        dispatchPickupForm({
            type: 'set-pickup-type-selected',
            activeStep: activeStep + 1,
            pickupType: pickupType === INSTRUCTIONS || pickupType === ACCESS_DOOR ? DROPANDGO : pickupType,
            donate: updatedDonation,
            recurring: updatedRecurring,
            charityPreferred: updatedCharityPreferred,
            organizationName: '',
            customTime: undefined
        });

        // Prepopulate business name and address if pickup type is commercial
        if (pickupType === COMMERCIAL) {
            const lastCompleted = _.findLast(pickups, { pickupType: COMMERCIAL });
            if (!_.isNil(lastCompleted)) {
                dispatchPickupForm({ type: 'set-location', location: _.get(lastCompleted, 'location', {}) });
                // might not need to repopulate businessName anymore if pastlocation data has it...
                // dispatchPickupForm({
                //     type: 'set-business-name',
                //     businessName: _.get(lastCompleted, 'location.businessName', '')
                // });
            }
        }
        if (!noNext) handleNext();
    };

    const handleApplyToBeACharity = () => {
        history.push(`/register?tab=organization&existingEmail=${customer.email}`);
    };

    const handleCancelConfirmationDialog = state => {
        if (state) {
            setMode('CANCEL');
        } else {
            setMode('ACTION');
        }
    };

    const toggleHelpDialog = topic => () => {
        if (topic === helpDialogTopic && helpDialogOpen) {
            setHelpDialogOpen(false);
        } else {
            setHelpDialogOpen(true);
            setHelpDialogTopic(topic);
        }
    };

    const handleClose = () => {
        const isDropAndGo = ['Drop&Go', 'Express', 'Walk-In'].includes(pickupForm.pickupType);
        const isBottleDrive = pickupForm.pickupType === BOTTLE_DRIVE;

        if (pickupPending && mode === 'CREATE') {
            setMode('ACTION');
            setActiveStep(0);
        } else if (isDropAndGo || isBottleDrive) {
            setActiveStep(0);
        }

        if (isDropAndGo) {
            resetFormToDefault();
            handleResetDropAndGo();
            setDefaultToActionStep(false);
            setDefaultToInstructions(false);
            setTechnologyTypeForInstructions('');
        }

        onClose();
    };

    const handleTogglePromoRecurring = () => {
        dispatchPickupForm({ type: 'toggle-promo-recurring' });
    };

    const handleBypassChange = (freq, state) => {
        if (state && freq === 'bypassAlways') {
            dispatchPickupForm({ type: 'toggle-always-bypass-zone-check', alwaysBypassZoneChecks: true });
        } else {
            dispatchPickupForm({ type: 'toggle-always-bypass-zone-check', alwaysBypassZoneChecks: false });
        }
    };

    const handleCancel = e => {
        if (!e.currentTarget.disabled) {
            e.currentTarget.disabled = true;
            const nextMode = incompletePickups.length === 1 ? 'CREATE' : 'ACTION';
            onCancel(selectedPickup, cancelReason, setMode, nextMode);
            dispatchPickupForm({ type: 'set', path: 'trip', value: null });
        }
    };

    const handleCustomTime = customTime => {
        dispatchPickupForm({ type: 'set-customTime', customTime });
    };

    const handleRemoveCustomTime = customTime => {
        dispatchPickupForm({ type: 'set-customTime', customTime: null });
    };

    const handleToggleFlag = async (pickup_id, path, value) => {
        const res = await http.post('/pickups/setValueById', { pickup_id, path, value });
        if (res.ok) {
            return;
        }
    };

    const handleReceiverChange = async e => {
        dispatchPickupForm({ type: 'set-receiver', receiver: e.target.value });
    };

    const handleChangeCoordinates = e => {
        let { name, value } = e.target;
        const { location } = pickupForm;
        let locationUpdated = _.cloneDeep(location);

        locationUpdated[name] = value;
        locationUpdated.isCustomGPS = true;

        dispatchPickupForm({ type: 'set-location', location: locationUpdated });
    };

    const handleDonateBottleDrive = () => {
        dispatchPickupForm({ type: 'set-donating-to-bottle-drive', donatingToBottleDrive: true });
        handleSelectPickupType('Residential', null, true)(); //TODO:
    };

    const handleSubmit = async () => {
        const {
            location,
            date,
            donate,
            charityPreferred,
            subdivisionPreferred,
            taxReceiptRequested,
            payload,
            subPayloads,
            comment,
            recurring,
            frequency,
            pickupType,
            businessName,
            organizationName,
            customTime,
            placeAtStartOfTrip,
            promos,
            trip,
            receiver,
            zone,
            alwaysBypassZoneChecks,
            recurringPromo,
            phoneOverride,
            phone,
            tipAmount,
            transporterCollector,
            collector,
            isBottleDrive,
            driver,
            binRequested,
            binRequestMessage,
            timePreference,
            efficiencyModifier,
            overrideProcessor,
            overrideTransporter,
            donatingToBottleDrive,
            alwaysConfirmedOverride
        } = pickupForm;

        let charitySelected = _.find(charities, charity => charity._id === charityPreferred);

        let charitySendsTaxReceipts = charitySelected && charitySelected.taxReceiptsIssued;
        let taxReceiptAdjusted = charitySendsTaxReceipts && taxReceiptRequested;

        let locationAdjusted = _.clone(location);
        if (!_.isEmpty(businessName) && pickupType === COMMERCIAL) {
            _.set(locationAdjusted, 'businessName', businessName);
        }
        if (!_.isEmpty(organizationName) && pickupType === BOTTLE_DRIVE) {
            _.set(locationAdjusted, 'organizationName', organizationName);
        }

        // The following procedure will create a new pickup request if there's no pending request or recreate it if editing
        setInProgress(true);

        const cumulativePayload = _.cloneDeep(payload);
        _commodity.addSubPayloadsToPayload(cumulativePayload, subPayloads);

        let pickup_id;
        let images = {};
        if (pickupPending && !_.isNil(selectedPickup)) {
            pickup_id = selectedPickup._id;
        }

        if (!_.isEmpty(imagePreviews)) {
            images.payloadImages = imagePreviews;
        }

        const res = await http.postJSON(
            '/pickups/requestPickup/' + customer._id,
            {
                pickup_id,
                date: date,
                customTime,
                placeAtStartOfTrip,
                charity: donate ? charityPreferred : null,
                subdivision: donate && !_.isEmpty(subdivisionPreferred) ? subdivisionPreferred : null,
                taxReceiptRequested: taxReceiptAdjusted,
                location: locationAdjusted,
                payload: cumulativePayload,
                subPayloads,
                images,
                comment,
                recurring,
                frequency,
                pickupType,
                promoCodes: promos.map(p => p.code),
                trip,
                receiver: isBottleDrive ? driver : receiver,
                alwaysBypassZoneChecks: showBypassZoneOptions && alwaysBypassZoneChecks,
                recurringPromo,
                phone: phoneOverride ? phone.replace(/\D/g, '') : null,
                timeSlotBooking: _.get(zone, 'collector.configuration.mobileTimeSlotBookings', false),
                // If pickup isn't a time slot booking but time was manually overridden, set manualTimeOverride to true
                manualTimeOverride:
                    !_.get(zone, 'collector.configuration.mobileTimeSlotBookings', false) && timeOverride,
                tipAmount,
                isBottleDrive: isBottleDrive,
                collector: collector,
                transporterCollector,
                binRequested,
                binRequestMessage,
                timePreference,
                efficiencyModifier,
                overrideProcessor: overrideProcessor === 'Any' ? null : overrideProcessor,
                overrideTransporter: overrideTransporter === 'Any' ? null : overrideTransporter,
                donatingToBottleDrive,
                alwaysConfirmedOverride
            },
            true
        );

        if (res.ok) {
            onSubmitSuccess();
            setInvalidCommoditiesToBeRemoved([]);
            setActiveStep(0);
            // setActionDialogOpen(true);
            setMode('ACTION');

            if (mapLocationSelection) {
                setMapLocationSelection(false);
            }

            if (process.env.REACT_APP_ENV === 'PROD' && !_.isNil(window.fbq)) {
                window.fbq('track', 'Purchase');
            }

            Analytics.logEvent(
                'Pickup Request',
                _.isNil(zone) ? 'Unserviceable' : 'Serviceable',
                Math.round(_.get(payload, 'beverage', 0))
            );
            Analytics.logFacebookEvent(
                _.isNil(zone) ? 'Pickup Unserviceable' : 'Pickup',
                Math.round(_.get(payload, 'beverage', 0))
            );
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    function toggleBottleDrive() {
        const { isBottleDrive } = pickupForm;
        if (!isBottleDrive) {
            handleChange({ target: { value: false, name: 'recurring' } });
            dispatchPickupForm({ type: 'toggle-always-bypass-zone-check', alwaysBypassZoneChecks: false });
            handleChange({ target: { value: null, name: 'frequency' } });
        }
        handleChange({ target: { value: !isBottleDrive, name: 'isBottleDrive' } });
    }

    function togglePlaceAtStartOfTrip() {
        dispatchPickupForm({ type: 'toggle-place-at-start-of-trip' });
    }

    const [driversAvailable, setDriversAvailable] = useState([]);
    const [collectorsAvailable, setCollectorsAvailable] = useState([]);

    useEffect(() => {
        if (_user.isSystemAdmin(auth) || _user.isCollectorAdmin(auth) || _user.isInternalRole(auth)) {
            (async () => {
                let allDriversEndpoint = '/users/getAllDrivers/';
                if (_user.isCollectorAdmin(auth)) allDriversEndpoint += _.get(auth, 'collector_id', '');
                const driversRes = await http.getJSON(allDriversEndpoint);

                if (driversRes.ok) setDriversAvailable(driversRes.data.drivers);
                const collectorsRes = await http.getJSON('/collectors');
                if (collectorsRes.ok) setCollectorsAvailable(collectorsRes.data.collectors);
            })();
        }
    }, []);

    return {
        mode,
        setMode,
        pickupForm,
        formErrors,
        helpDialogTopic,
        helpDialogOpen,
        activeStep,
        prevStep,
        inProgress,
        cancelReason,
        validateForm,
        timeOverride,
        imagePreviews,
        uploadingImage,
        handleUploadImage,
        handleDeleteImage,
        showBypassZoneOptions,
        availableBookings,
        availableTimeSlots,
        showTimeSlots,
        driversAvailable,
        collectorsAvailable,
        minFrequency,
        minFrequencyPickupTypes,
        minPickupFeeEnabled,
        lowVolumeContainerThreshold,
        togglePhoneOverride,
        technologyFilter,
        handleBack,
        handleNext,
        handleGPSReset,
        handleInHouse,
        handleSelectPickupType,
        handleApplyToBeACharity,
        handleAddressNext,
        handleDefaultTrip,
        handleClose,
        handleCancel,
        handleChange,
        handlePastLocationData,
        handlePayload,
        handleSubPayloads,
        handleApplyPromo,
        handleRemovePromo,
        handleClearPromos,
        toggleHelpDialog,
        handleSuggestionSelected,
        handleDateChange,
        handleTimePreferenceChange,
        handleOverrideAlwaysConfirmChange,
        handleEfficiencyModifierChange,
        handleOverrideProcessorChange,
        handleOverrideTransporterChange,
        handleShowTimeOverride,
        handleCustomTime,
        handleRemoveCustomTime,
        toggleRecurring,
        handleCharityPreferred,
        handleSetDonation,
        handleCancelConfirmationDialog,
        handleToggleFlag,
        handleSubmit,
        handleActionDialog,
        handleCancelChange,
        handleReceiverChange,
        toggleDonate,
        togglePlaceAtStartOfTrip,
        toggleTaxReceiptRequested,
        handlePromoInputSetDonation,
        handleTogglePromoRecurring,
        handleManualGPSOverride,
        handleBypassChange,
        handleAvailableTimeSlots,
        handleTipAmountChange,
        toggleBottleDrive,
        handleRequestBinChange,
        handleBinRequestMessageChange,
        handleChangeCoordinates,
        handleDonateBottleDrive,
        defaultToInstructions,
        defaultToActionStep,
        technologyTypeForInstructions,
        invalidCommoditiesToBeRemoved
    };
}

export default usePickupDialog;

function evaluatePayload(pickups, customer) {
    const checkPayload = _.get(customer, 'cancelledPickupInformation.payload', _pickup.createEmptyPayload());
    return _pickup.payloadIsEmpty(checkPayload)
        ? _.get(_.last(pickups), 'payload', _pickup.createEmptyPayload())
        : checkPayload;
}

function evaluateComment(pickups, customer) {
    const checkComment = _.get(customer, 'cancelledPickupInformation.comment', '');
    return !checkComment ? _.get(_.last(pickups), 'comment', '') : checkComment;
}

function getPreviousEfficiencyModifier(pickups, customer, location) {
    let previousEfficiencyModifierForLocation = _.get(
        _.last(_.filter(pickups, p => p.location.place_id === location.place_id)),
        'efficiencyModifier'
    );
    let previousEfficiencyModifierCancelled =
        _.get(customer, 'cancelledPickupInformation.location.place_id') === location.place_id
            ? _.get(customer, 'cancelledPickupInformation.efficiencyModifier')
            : 1;
    return !_.isNil(previousEfficiencyModifierForLocation) && previousEfficiencyModifierForLocation >= 1
        ? previousEfficiencyModifierForLocation
        : !_.isNil(previousEfficiencyModifierCancelled) && previousEfficiencyModifierCancelled >= 1
        ? previousEfficiencyModifierCancelled
        : 1;
}

function evaluateCharity(pickups, customer, location) {
    const previousCharityForLocation = _.get(
        _.last(_.filter(pickups, p => p.location.place_id === location.place_id)),
        'charity'
    );
    const checkCharity = !_.isNil(previousCharityForLocation)
        ? previousCharityForLocation
        : _.get(customer, 'charitySelected._id', null);
    return checkCharity;
}

function generateInitialPickupForm({
    selectedPickup,
    customer,
    pickupPending,
    pickups,
    subdivisionPreferred,
    commoditiesAvailable
}) {
    const charity = evaluateCharity(pickups, customer, _.get(selectedPickup, 'location', customer.location));

    let newPickupForm;
    if (_.isNil(selectedPickup)) {
        const payload = evaluatePayload(pickups, customer);
        const subPayloads = [];

        newPickupForm = {
            pickupType: '',
            businessName: _.get(customer, 'businessName', ''),
            organizationName: '',
            location: customer.location,
            unitNumber: '',
            buzzerCode: '',
            date: undefined,
            customTime: undefined,
            placeAtStartOfTrip: false,
            trip: null,
            zone: undefined,
            donate: !_.isNil(charity),
            charityPreferred: charity,
            receiver: null,
            subdivisionPreferred,
            taxReceiptRequested: _.get(customer, 'receiveTaxReceipts', true),
            payload,
            subPayloads,
            comment: evaluateComment(pickups, customer),
            recurring: undefined,
            frequency: undefined,
            promos: _.get(customer, 'promos', []),
            recurringPromo: false,
            alwaysBypassZoneChecks: false,
            phoneOverride: false,
            phone: _.get(customer, 'phone', ''),
            tipAmount: 0,
            collector: '',
            transporterCollector: '',
            driver: '',
            isBottleDrive: false,
            manualTimeOverride: false,
            binRequested: false,
            binRequestMessage: '',
            timePreference: 'None',
            efficiencyModifier: getPreviousEfficiencyModifier(pickups, customer, customer.location),
            overrideProcessor: _.get(customer, 'overrideProcessor', 'Any'),
            overrideTransporter: _.get(customer, 'overrideTransporter', 'Any'),
            donatingToBottleDrive: false,
            alwaysConfirmedOverride: false
        };
    } else {
        const promos = _.get(selectedPickup, 'promos', []);
        let recurringPromo = false;
        if (!_.isEmpty(promos)) {
            const firstPromo = _.first(promos);
            recurringPromo = firstPromo.recurring;
        }
        const date = pickupPending ? _.get(selectedPickup, 'date', undefined) : undefined;
        const timezone = pickupPending ? _.get(selectedPickup, 'location.timezone', _time.getTimezone()) : undefined;

        // Don't modify payloads in place
        const payload = _.cloneDeep(_.get(selectedPickup, 'payload', _pickup.createEmptyPayload()));
        const subPayloads = _.cloneDeep(_.get(selectedPickup, 'subPayloads', []));

        _commodity.populateSubPayloadsSubCommodities(subPayloads, commoditiesAvailable);
        _commodity.removeSubPayloadsFromPayload(payload, subPayloads);

        newPickupForm = {
            pickupType: pickupPending ? _.get(selectedPickup, 'pickupType', '') : undefined,
            businessName: _.get(selectedPickup, 'location.businessName', ''),
            organizationName: _.get(selectedPickup, 'location.organizationName', ''),
            location: _.get(selectedPickup, 'location', {}),
            unitNumber: _.get(selectedPickup, 'location.unitNumber', ''),
            buzzerCode: _.get(selectedPickup, 'location.buzzerCode', ''),
            date: !_.isNil(date) ? moment(date).tz(timezone) : undefined,
            customTime: _.get(selectedPickup, 'customTime', undefined),
            placeAtStartOfTrip: _.get(selectedPickup, 'placeAtStartOfTrip', false),
            trip: _.get(selectedPickup, 'trip', null),
            zone: _.get(selectedPickup, 'zone', undefined),
            donate: !_.isNil(_.get(selectedPickup, 'charity', charity)),
            charityPreferred: _.get(selectedPickup, 'charity', charity),
            receiver: _.get(selectedPickup, 'receiver', null),
            subdivisionPreferred,
            taxReceiptRequested: _.get(customer, 'receiveTaxReceipts', true),
            payload,
            subPayloads,
            comment: _.get(selectedPickup, 'comment', ''),
            recurring: !_.isNil(_.get(selectedPickup, 'nextDate', undefined)),
            frequency: _.get(selectedPickup, 'frequency', undefined),
            promos: pickupPending ? promos : [],
            recurringPromo: recurringPromo,
            alwaysBypassZoneChecks: _.get(selectedPickup, 'alwaysBypassZoneChecks', false),
            phoneOverride: !_.isNil(_.get(selectedPickup, 'phone')),
            phone: !_.isNil(_.get(selectedPickup, 'phone'))
                ? _.get(selectedPickup, 'phone')
                : _.get(customer, 'phone', ''),
            tipAmount: _.get(selectedPickup, 'tipAmount', 0),
            collector: _.get(selectedPickup, 'collector._id', ''),
            transporterCollector: _.get(selectedPickup, 'receiver.collector', ''),
            driver: _.get(selectedPickup, 'receiver._id', ''),
            isBottleDrive: _.get(selectedPickup, 'isBottleDrive', false),
            manualTimeOverride: _.get(selectedPickup, 'manualTimeOverride', false),
            binRequested: _.get(selectedPickup, 'binRequested', false),
            binRequestMessage: _.get(selectedPickup, 'binRequestMessage', ''),
            timePreference: _.get(selectedPickup, 'timePreference', 'None'),
            efficiencyModifier: _.get(selectedPickup, 'efficiencyModifier', 1),
            overrideProcessor: _.isNil(selectedPickup.overrideProcessor)
                ? 'Any'
                : _.get(selectedPickup, 'overrideProcessor', 'Any'),
            overrideTransporter: _.isNil(selectedPickup.overrideTransporter)
                ? 'Any'
                : _.get(selectedPickup, 'overrideTransporter', 'Any'),
            alwaysConfirmedOverride: _.get(selectedPickup, 'alwaysConfirmedOverride', false)
        };
    }

    return newPickupForm;
}

function bypassZoneChecksRequired(data) {
    const { date, zone, frequency } = data;

    if (!zone) return false;

    const timezone = _.get(zone, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const zoneDaysOfWeekServiced = _.get(zone, 'startingDates', []).map(d =>
        moment(d)
            .tz(timezone)
            .isoWeekday()
    );

    const dateAsMomentObject = moment(date).tz(timezone);

    if (!zoneDaysOfWeekServiced.includes(dateAsMomentObject.isoWeekday())) {
        return true;
    } else if (!_.isNil(frequency) && frequency >= 7) {
        const zoneFreqWeeks = _.get(zone, 'frequency', 7) / 7;
        const pickupFreqWeeks = frequency / 7;

        const idx = zoneDaysOfWeekServiced.indexOf(dateAsMomentObject.isoWeekday());
        const diffWeeks = dateAsMomentObject.diff(moment(zone.startingDates[idx]).tz(timezone), 'week');

        if (diffWeeks % zoneFreqWeeks !== 0 || (pickupFreqWeeks > 0 && pickupFreqWeeks % zoneFreqWeeks !== 0)) {
            return true;
        }
    }

    return false;
}

function momentForLocation(date, location) {
    return moment(date).tz(_.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE));
}
