import { useState, useContext } from 'react';

import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

function useFetchBulks(operator) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [incompleteBulks, setIncompleteBulks] = useState([]);
    const [overdueBulks, setOverdueBulks] = useState([]);
    const [whinyBulks, setWhinyBulks] = useState([]);
    const [completedBulks, setCompletedBulks] = useState([]);
    const [lostAndFoundBulks, setLostAndFoundBulks] = useState([]);

    const [incompleteBulksLoading, setIncompleteBulksLoading] = useState(true);
    const [overdueBulksLoading, setOverdueBulksLoading] = useState(true);
    const [whinyBulksLoading, setWhinyBulksLoading] = useState(true);
    const [completedBulksLoading, setCompletedBulksLoading] = useState(true);
    const [lostAndFoundBulksLoading, setLostAndFoundBulksLoading] = useState(true);
    const [commodityColors, setCommodityColors] = useState({});
    const [commodities, setCommodities] = useState([]);
    const [commoditiesByCollector, setCommoditiesByCollector] = useState([]);

    const fetchCommodityColors = async () => {
        const res = await http.getJSON('/commodities/commodityColors');
        setCommodityColors(res.data);
    };
    const fetchCommodities = async () => {
        const res = await http.getJSON('/commodities/getAllCommodities');
        setCommodities(_.get(res, 'data.collectionData', []));
    };
    const fetchCommoditiesByCollector = async () => {
        const res = await http.getJSON('/commodities/getCommoditiesByCollector');
        setCommoditiesByCollector(_.get(res, 'data.commoditiesByCollector', {}));
    };
    // staggers grabbing all bulks into 4 calls to speed up loading time of screen drastically
    const fetchAllBulks = async (setLoading = true) => {
        if (setLoading) {
            setIncompleteBulksLoading(true);
            setOverdueBulksLoading(true);
            setWhinyBulksLoading(true);
            setCompletedBulksLoading(true);
        }

        const res = await http.getJSON(`/bulks/getDashboardBulks/${operator._id}`);
        if (res.ok) {
            const { incomplete, overdue, whiny, complete, lost } = res.data;
            setIncompleteBulks(incomplete);
            setWhinyBulks(whiny);
            setCompletedBulks(complete);

            overdue.forEach(bulk => (bulk.isOverdue = true));
            setOverdueBulks(overdue);

            setLostAndFoundBulks(lost);

            setIncompleteBulksLoading(false);
            setOverdueBulksLoading(false);
            setWhinyBulksLoading(false);
            setCompletedBulksLoading(false);
            setLostAndFoundBulksLoading(false);
        } else {
            onSnackbar('Error loading  bulks');
        }
    };

    return {
        incompleteBulks,
        overdueBulks,
        whinyBulks,
        completedBulks,
        lostAndFoundBulks,
        incompleteBulksLoading,
        overdueBulksLoading,
        whinyBulksLoading,
        completedBulksLoading,
        lostAndFoundBulksLoading,
        loading: incompleteBulksLoading || overdueBulksLoading || overdueBulksLoading || completedBulksLoading,
        fetchAllBulks,
        commodityColors,
        fetchCommodityColors,
        commodities,
        commoditiesByCollector,
        fetchCommodities,
        fetchCommoditiesByCollector
    };
}

export default useFetchBulks;
