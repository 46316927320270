import React from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    TextField,
    DialogTitle,
    Typography
} from '@material-ui/core';

import { getSwitch } from '../helperFunctions';

import { loc } from 'localizations/localizationHandler';
import LocalizedTextInput from '../Inputs/LocalizedTextInput';
import { useMemo } from 'react';
import { AVAILABLE_LANGS } from '../../../constants';

// add new complaint/resolution options
function ComplaintsForm(props) {
    const {
        lang,
        initialObj,
        open,
        onClose,
        complaintsConfig,
        onSetField,
        onConfigSubmit,
        theme,
        fullScreen,
        complaintType
    } = props;

    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialObj
        },
        validationSchema: Yup.object({
            code: Yup.string()
                .required('Code should be auto generated')
                .max(4, 'Must be less than 4 characters'),
            label: Yup.object({
                en: Yup.string()
                    .required('Must include a label')
                    .min(5, 'Must be more than 4 characters')
            }),
            ...(complaintType === 'Driver'
                ? {}
                : {
                      description: Yup.object({
                          en: Yup.string().required('Must include a description text for customer email')
                      })
                  })
        }),
        onSubmit: async (values, { resetForm }) => {
            let length = _.get(
                complaintsConfig,
                `${complaintType.toLowerCase()}Complaints.${complaintType === 'Customer' ? 'resolutions' : 'options'}`,
                []
            ).length;
            let newCodeCounter = _.get(complaintsConfig, `${complaintType.toLowerCase()}Complaints.codeCounter`, 0) + 1;

            onSetField(`complaints.${complaintType.toLowerCase()}Complaints.codeCounter`, newCodeCounter);
            onSetField(
                `complaints.${complaintType.toLowerCase()}Complaints.${
                    complaintType === 'Customer' ? 'resolutions' : 'options'
                }[${length}]`,
                values
            );

            await onConfigSubmit();
            onClose();
            resetForm();
        }
    });

    const objKeys = Object.keys(initialObj).filter(key => key !== 'sendToCustomer' && key !== 'emailToCustomer');
    const hasError = objKeys.some(
        key => _.get(formik.touched, `${key}`, false) && _.get(formik.errors, `${key}`, false)
    );

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Add New {complaintType} Option</DialogTitle>
            <DialogContent>
                <Typography>Complaint Code: {formik.values['code']}</Typography>
                <LocalizedTextInput
                    label="Label"
                    value={_.get(formik, 'values.label')}
                    onChange={value => formik.setFieldValue('label', value)}
                    touched={_.get(formik, 'touched.label')}
                    error={_.get(formik, 'errors.label.en')}
                    style={{
                        marginTop: theme.spacing.unit * 2
                    }}
                />
                {complaintType === 'Driver' &&
                    [0, 1].map(suggestionIndex => {
                        const touched = _.get(formik, 'touched.label');
                        const error = _.get(formik, 'errors.label');
                        return (
                            <div
                                style={{
                                    position: 'relative',
                                    padding: '10px 8px',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '4px 4px 4px 4px',
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                    marginTop: theme.spacing.unit * 2
                                }}
                            >
                                <div
                                    style={{
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        position: 'absolute',
                                        top: -10,
                                        backgroundColor: theme.palette.background.paper,
                                        fontFamily: theme.typography.subtitle2.fontFamily,
                                        fontSize: '12px',
                                        color: theme.palette.grey[600]
                                    }}
                                >
                                    Suggestion {suggestionIndex}
                                </div>
                                {availableLangs.map(lang => (
                                    <TextField
                                        data-cy={`localized-input-suggestions${suggestionIndex}-edit`}
                                        key={lang}
                                        style={{ marginBottom: theme.spacing.unit }}
                                        fullWidth
                                        multiline
                                        label={lang}
                                        value={_.get(formik, lang)}
                                        name={lang}
                                        onChange={e =>
                                            formik.setFieldValue(
                                                `suggestions.${lang}[${suggestionIndex}]`,
                                                e.target.value
                                            )
                                        }
                                        error={touched && !_.isNil(error)}
                                        helperText={touched && !_.isNil(error) ? error : ''}
                                    />
                                ))}
                            </div>
                        );
                    })}
                {complaintType !== 'Driver' && (
                    <>
                        <LocalizedTextInput
                            label="Description"
                            value={_.get(formik, 'values.description')}
                            onChange={value => formik.setFieldValue('description', value)}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            touched={_.get(formik, 'touched.description')}
                            error={_.get(formik, 'errors.description.en')}
                        />
                    </>
                )}

                {complaintType === 'Counter' && getSwitch(theme, `driverIssue`, 'Driver Issue', formik)}
                {complaintType === 'Driver' && getSwitch(theme, `clothingBinIssue`, 'Clothing Bin Issue', formik)}
                {!_.isNil(initialObj.emailToCustomer) &&
                    !formik.values.driverIssue &&
                    getSwitch(theme, `emailToCustomer`, 'Email to Customer', formik)}
                {formik.values.appliedBySystem &&
                    getSwitch(theme, `appliedBySystem`, 'Applied by System', formik, {}, {}, () => {}, '', true)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} disabled={hasError}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(ComplaintsForm));
