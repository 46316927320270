import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import charityTaskHelper from '../../helpers/charityTaskHelper';

import _ from 'lodash';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Divider,
    DialogTitle,
    TextField
} from '@material-ui/core';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton, Icon } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { Stepper, Step, StepButton } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';

import * as terms from 'localizations/terms';

import GrowthPlanTaskTemplate from './GrowthPlanTaskTemplate';
import CustomStepperIcon from './Components/CustomStepperIcon';
import IntroTaskDisplay from './IntroTaskDisplay';
import CharityGrowthTasksList from './CharityGrowthTasksList';
import CharityActionChips from 'components/CustomerWidgets/CharityActionChips';
import HighResImageWidget from 'components/HighResImageWidget/HighResImageWidget';

import VerificationDialog from './VerificationDialog';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import moment from 'moment-timezone';
import { isEXPRegion } from 'utils/misc';

const styles = {
    dialogPaper: {
        // minHeight: '90vh',
        // maxHeight: '90vh'
    }
};

function CharityGrowthPlanDialog(props) {
    const {
        open,
        onClose,
        theme,
        fullScreen,
        charity,
        taskIndex,
        setTaskIndex,
        http,
        onSnackbar,
        onUpdateCharityTasks,
        onCharityTaskOrderCreated,
        onCharityHighResImageUploaded,
        onEmailOutDirectly,
        customer,
        classes,
        width,
        minNumberTasks,
        onShareDialogOpen,
        shareDialogEnabled,
        onRedirectToPage,
        customersDonating,
        shares,
        charityPromo,

        //for social media share buttons
        subject,
        message,
        shareUrl,
        hashtags,
        onShare,
        onNativeShare
    } = props;

    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const [loading, setLoading] = useState(false);
    const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
    const [orderImageDialogOpen, setOrderImageDialogOpen] = useState(false);
    const [mainContentHeight, setMainContentHeight] = useState(0);

    const [imageFile, setImageFile] = useState(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    const [userInput, setUserInput] = useState('');
    const [userInputDialogOpen, setUserInputDialogOpen] = useState(false);

    const [tab, setTab] = useState('main');

    const charityTasks = _.filter(charity.charityTasks, task => !_.get(task, 'task.hidden'));
    const showPromoText =
        _.filter(
            charity.charityTasks,
            task => _.get(task, 'task.whatToDoDisplay') === 'promoCodeDisplay' && !_.get(task, 'task.hidden')
        ).length > 0;
    const countComplete = charityTaskHelper.numberOfTasksComplete(charityTasks);
    const countCompletable = _.filter(charityTasks, charityTask => !charityTask.task.notCompletable).length;

    const stepperRef = useRef(null);

    const scrollCurrentStep = () => {
        if (!_.isNil(stepperRef) && !_.isNil(stepperRef.current)) {
            stepperRef.current.scrollIntoView(false);
        }
        setMainContentHeight(_.get(mainContentRef, 'current.clientHeight', 0));
    };

    const mainContentRef = useRef(null);

    useEffect(() => {
        setMainContentHeight(_.get(mainContentRef, 'current.clientHeight', 0));
    }, [taskIndex]);

    useEffect(() => {
        if (_.sumBy(charityTasks, charityTask => charityTask.complete) === 0) return;

        const updateStepperHeight = () => setMainContentHeight(_.get(mainContentRef, 'current.clientHeight', 0));
        window.addEventListener('resize', updateStepperHeight);
        return () => window.removeEventListener('resize', updateStepperHeight);
    }, []);

    useEffect(() => {
        if (!_.isNil(stepperRef) && !_.isNil(stepperRef.current) && !isSmUp) {
            stepperRef.current.scrollIntoView({ block: 'end', inline: 'center' });
        }
        setUserInput(_.get(charityTasks[taskIndex], 'informationProvided', ''));
    }, [taskIndex]);

    if (!open) {
        return <></>;
    }

    const currentCharityTask = charityTasks[taskIndex];

    const currentTask = charityTasks[taskIndex].task;
    const currentTaskLastSubmission = charityTasks[taskIndex].lastSubmission;
    const currentTaskOrderType = _.get(charityTasks[taskIndex], 'task.orderType', '');
    const currentTaskAskForUserInput = _.get(charityTasks[taskIndex], 'task.askForUserInput', false);
    const currentTaskUserInputFields = _.get(charityTasks[taskIndex], `task.${lang}.userInputFields`, []);

    const hasNextTask = taskIndex < charityTasks.length - 1;
    const hasPreviousTask = taskIndex > 0;

    const onSubmitButtonClicked = async () => {
        setVerificationDialogOpen(true);
    };

    const handleImageDialog = (state = true) => {
        if (!state) setImageFile(null);
        setOrderImageDialogOpen(state);
    };

    const handleImageFile = file => {
        setImageFile(file);
    };

    const handleImageDimensions = (height, width) => {
        setImageHeight(height);
        setImageWidth(width);
    };

    const createOrder = async () => {
        setLoading(true);
        let orderData = {
            charityId: charity._id,
            orderType: currentTaskOrderType,
            taskId: currentTask._id,
            updateFilePath: false,
            userInput: userInput
        };
        if (!_.isNil(currentCharityTask.task[lang].orderEmailText)) {
            orderData.emailText = currentCharityTask.task[lang].orderEmailText;
        }

        const res = await http.postJSON(`/charities/${charity._id}/submitOrder`, orderData);
        setLoading(false);

        if (res.ok) {
            onSnackbar(loc('growthGeneral11', lang));
            onCharityTaskOrderCreated(currentCharityTask.task._id, userInput);
            setUserInputDialogOpen(false);
        }
    };

    const handleImageSubmit = async createOrderRequested => {
        if (!imageFile) return;

        setLoading(true);
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('filename', imageFile.name);
        formData.append('contentType', imageFile.type);

        const image = await http.postJSON(`/system/uploadImage`, formData, false, true);
        const res = await http.postJSON(`/charities/${charity._id}/setHighResImage`, {
            charityId: charity._id,
            filePath: image.data.imageUrl
        });
        setLoading(false);

        if (!createOrderRequested && res.ok) {
            onSnackbar(loc('charitySnackbar10', lang));
        } else if (createOrderRequested) {
            await createOrder();
        }

        handleImageDialog(false);
        onCharityHighResImageUploaded(image.data.imageUrl);
    };

    const handleCharityTaskSubmitted = async (values, snackbarMessage = loc('charitySnackbar11', lang)) => {
        const verificationUrl = _.get(values, 'link', '');
        const verificationImages = _.get(values, 'images', []);
        const verificationType = _.get(values, 'verificationType');

        setLoading(true);
        const res = await http.postJSON(`/charities/${charity._id}/submitCharityTaskForVerification`, {
            task: currentTask._id,
            verificationUrl,
            verificationImages,
            verificationType
        });
        setLoading(false);
        setVerificationDialogOpen(false);

        if (res.ok) {
            onSnackbar(snackbarMessage);
            onUpdateCharityTasks(res.data.charityTasks);
        } else {
            onSnackbar(loc('charitySnackbar12', lang), 'error');
        }
    };

    const isSmUp = isWidthUp('sm', width);

    let submitButton = null;
    //
    // First case is a task for which a physical item can be ordered (also used for requesting event attendance)
    if (charity.closed) {
        submitButton = <></>;
    } else if (
        !currentCharityTask.orderCreated &&
        (currentTaskOrderType || _.get(currentCharityTask, `task.${lang}.orderButtonText`))
    ) {
        submitButton = (
            <Button
                data-cy="growth-plan-dialog-order-btn"
                variant="contained"
                color="primary"
                // style={{
                //     backgroundColor: theme.palette.primary.main,
                //     color: 'white'
                // }}
                onClick={
                    currentTaskAskForUserInput
                        ? () => setUserInputDialogOpen(true)
                        : !charity.highResImage
                        ? handleImageDialog
                        : () =>
                              warnAction(
                                  () => {
                                      return createOrder();
                                  },
                                  _.get(currentCharityTask, `task.${lang}.orderButtonText`)
                                      ? loc('charitySnackbar17', lang, {
                                            customText: _.get(
                                                currentCharityTask,
                                                `task.${lang}.orderButtonText`
                                            ).toLowerCase()
                                        })
                                      : loc('charitySnackbar13', lang, { type: currentTaskOrderType })
                              )
                }
                disabled={loading}
            >
                {_.get(
                    currentCharityTask,
                    `task.${lang}.orderButtonText`,
                    loc('growthGeneral31', lang, { type: currentTaskOrderType })
                )}
            </Button>
        );
    } else if (_.isNil(currentTaskLastSubmission)) {
        submitButton = (
            <Button
                data-cy="growth-plan-dialog-submit-btn"
                variant="contained"
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white'
                }}
                onClick={onSubmitButtonClicked}
                disabled={loading}
            >
                {loc('growthGeneral39', lang)}
                <Icon
                    style={{
                        marginLeft: theme.spacing.unit
                    }}
                >
                    arrow_upward
                </Icon>
            </Button>
        );
        // } else if (!currentTaskLastSubmission.reviewed) {
        //     submitButton = (
        //         <Button
        //             data-cy="growth-plan-dialog-being-reviewed-btn"
        //             variant="contained"
        //             style={{
        //                 backgroundColor: colors.orange[200],
        //                 color: 'white'
        //             }}
        //             onClick={onSubmitButtonClicked}
        //             disabled={true}
        //         >
        //             {loc('growthGeneral10', lang)}
        //             <Icon
        //                 style={{
        //                     marginLeft: theme.spacing.unit
        //                 }}
        //             >
        //                 rate_review
        //             </Icon>
        //         </Button>
        //     );
    } else {
        submitButton = (
            <Button
                data-cy="growth-plan-dialog-resubmit-btn"
                variant="contained"
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white'
                }}
                onClick={onSubmitButtonClicked}
                disabled={loading}
            >
                {loc('growthGeneral32', lang)}
                <Icon
                    style={{
                        marginLeft: theme.spacing.unit
                    }}
                >
                    sync
                </Icon>
            </Button>
        );
    }
    return (
        <>
            {charity.approved ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullScreen={fullScreen}
                    fullWidth
                    scroll="paper"
                    onEntering={scrollCurrentStep}
                    classes={{ paper: isSmUp ? classes.dialogPaper : null }}
                >
                    {!isSmUp && (
                        <DialogTitle
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: 0,
                                backgroundColor: theme.palette.primary.main,
                                paddingTop: 'env(safe-area-inset-bottom, 8px)'
                            }}
                        >
                            <IconButton onClick={onClose}>
                                <Icon style={{ color: 'white' }} data-cy="growth-plan-dialog-close">
                                    close
                                </Icon>
                            </IconButton>
                        </DialogTitle>
                    )}
                    <RootRef rootRef={mainContentRef}>
                        <DialogContent style={{ padding: theme.spacing.unit * 2 }}>
                            {/* <div
                                style={{
                                    textAlign: 'center',
                                    width: isSmUp ? 300 : '100%',
                                    height: isSmUp ? mainContentHeight - theme.spacing.unit * 2 : null,
                                    overflow: 'auto',
                                    position: isSmUp ? 'fixed' : null
                                }}
                            >
                                {isSmUp ? (
                                    <div>
                                        <CharityGrowthTasksList
                                            tasks={charityTasks}
                                            indexSelected={taskIndex}
                                            onTaskSelected={index => setTaskIndex(index)}
                                            charity={charity}
                                            charityPromo={charityPromo}
                                        />
                                        <Divider style={{ marginBottom: theme.spacing.unit }} />
                                        <CharityActionChips
                                            charity={charity}
                                            customersDonating={customersDonating}
                                            shares={shares}
                                            onShareDialogOpen={onShareDialogOpen}
                                            shareDialogEnabled={shareDialogEnabled}
                                            onRedirectToPage={page => {
                                                onClose();
                                                onRedirectToPage(page);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Stepper alternativeLabel nonLinear activeStep={taskIndex}>
                                        {charityTasks.map((charityTask, index) => (
                                            <Step key={charityTask.task._id} style={{ minWidth: '11ch' }}>
                                                <StepButton
                                                    data-cy={`growth-plan-dialog-stepper-${charityTask.task[
                                                        lang
                                                    ].shortTitle.replace(/[ ,.]/g, '')}`}
                                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                                    icon={
                                                        <CustomStepperIcon
                                                            completed={
                                                                charityTask.complete ||
                                                                (charityTask.task.featuredCharityTask &&
                                                                    charity.isFeatured)
                                                            }
                                                            stepIndex={index}
                                                            charityTask={charityTask}
                                                            isCurrentTask={index === taskIndex}
                                                            charityFeatured={charity.isFeatured}
                                                        />
                                                    }
                                                    onClick={() => setTaskIndex(index)}
                                                    completed={
                                                        charityTask.complete ||
                                                        (charityTask.task.featuredCharityTask && charity.isFeatured)
                                                    }
                                                >
                                                    <span
                                                        id={`StepperLabel${index}`}
                                                        ref={taskIndex === index ? stepperRef : null}
                                                        style={{
                                                            color: _.get(charityTask, 'task.notCompletable', false)
                                                                ? theme.palette.linkColor
                                                                : undefined
                                                        }}
                                                    >
                                                        {charityTask.task[lang].shortTitle}
                                                    </span>
                                                </StepButton>
                                            </Step>
                                        ))}
                                    </Stepper>
                                )}
                            </div> */}

                            <div
                                style={{
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    paddingTop: theme.spacing.unit * 1,
                                    paddingBottom: theme.spacing.unit * 2,
                                    paddingRight: 0
                                }}
                            >
                                {taskIndex === 0 ? (
                                    <>
                                        <IntroTaskDisplay
                                            url={_.get(charity, 'url', '')}
                                            highResImage={
                                                !_.isNil(charity.highResImage) ? charity.highResImage : charity.logo
                                            }
                                            countComplete={countComplete}
                                            countCompletable={countCompletable}
                                            minNumberTasksToBeFeatured={minNumberTasks}
                                            currentTask={currentTask}
                                            showPromoText={showPromoText}
                                            handleImageDialog={handleImageDialog}
                                            http={http}
                                            tab={tab}
                                            setTab={setTab}
                                        />
                                        {tab === 'main' && (
                                            <CharityGrowthTasksList
                                                tasks={charityTasks}
                                                indexSelected={taskIndex}
                                                onTaskSelected={index => setTaskIndex(index)}
                                                charity={charity}
                                                charityPromo={charityPromo}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <GrowthPlanTaskTemplate
                                        charityTask={currentCharityTask}
                                        charity={charity}
                                        onSnackbar={onSnackbar}
                                        onSubmitButtonClicked={onSubmitButtonClicked}
                                        loading={loading}
                                        onEmailOutDirectly={onEmailOutDirectly}
                                        taskIndex={taskIndex}
                                        subject={subject}
                                        message={message}
                                        shareUrl={shareUrl}
                                        hashtags={hashtags}
                                        onShare={onShare}
                                        onNativeShare={onNativeShare}
                                        countCompletable={countCompletable}
                                        countComplete={countComplete}
                                        onSubmit={handleCharityTaskSubmitted}
                                        charityPromo={charityPromo}
                                        userInput={userInput}
                                        orderCreated={currentCharityTask.orderCreated}
                                        submitButton={submitButton}
                                    />
                                )}
                            </div>
                        </DialogContent>
                    </RootRef>
                    {loading && <LinearProgress />}
                    <DialogActions>
                        {/* {!currentTask.notCompletable && !currentTask.notSubmitable && submitButton} */}
                        {/* <IconButton disabled={!hasPreviousTask} onClick={() => setTaskIndex(taskIndex - 1)}>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                        <IconButton disabled={!hasNextTask} onClick={() => setTaskIndex(taskIndex + 1)}>
                            <Icon>navigate_next</Icon>
                        </IconButton> */}
                        {(taskIndex !== 0 || tab !== 'main') && (
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setTab('main');
                                    setTaskIndex(0);
                                }}
                                data-cy="charity-growth-plan-dialog-back"
                                style={{ marginTop: theme.spacing.unit }}
                            >
                                Back
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
                    <DialogTitlePrimary
                        closeButtonShown={true}
                        onClose={onClose}
                        style={{ marginBottom: theme.spacing.unit * 2 }}
                    >
                        <span style={{ marginLeft: theme.spacing.unit * 2 }}>{loc('growthGeneral33', lang)}</span>
                    </DialogTitlePrimary>
                    <DialogContent>
                        <Typography variant="body1" style={{ marginBottom: theme.spacing.unit * 2 }}>
                            {loc('dashboardGrowthInfo2', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                        </Typography>

                        <Typography variant="body1">
                            {loc('dashboardGrowthInfo1', lang)}:{' '}
                            {charity.approvalPending ? (
                                <span style={{ color: colors.orange[500] }}>{loc('dashboardGrowthInfo3', lang)}</span>
                            ) : (
                                <span style={{ color: colors.red[500] }}>{loc('dashboardGrowthInfo11', lang)}</span>
                            )}
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
            {verificationDialogOpen && (
                <VerificationDialog
                    open={verificationDialogOpen}
                    onClose={() => setVerificationDialogOpen(false)}
                    onSubmit={handleCharityTaskSubmitted}
                    task={currentTask}
                    http={http}
                    onSnackbar={onSnackbar}
                    setLoading={setLoading}
                />
            )}
            <Dialog open={currentTaskAskForUserInput && userInputDialogOpen && !currentCharityTask.orderCreated}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    <div style={{ padding: theme.spacing.unit }}>
                        <Typography variant="body1">
                            {loc('growthGeneral40', lang)}:
                            <ul>
                                {currentTaskUserInputFields.map(item => {
                                    return <li>{item}</li>;
                                })}
                            </ul>
                        </Typography>
                        <TextField
                            data-cy="commodity-pickup-field-units-input"
                            style={{ marginRight: theme.spacing.unit }}
                            value={userInput}
                            onChange={e => setUserInput(e.target.value)}
                            name="unit"
                            label={loc('details', lang)}
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUserInputDialogOpen(false)}>{loc('cancel', lang)}</Button>
                    <Button
                        color="primary"
                        onClick={
                            !charity.highResImage
                                ? handleImageDialog
                                : () =>
                                      warnAction(
                                          () => {
                                              return createOrder();
                                          },
                                          _.get(currentCharityTask, `task.${lang}.orderButtonText`)
                                              ? loc('charitySnackbar17', lang, {
                                                    customText: _.get(
                                                        currentCharityTask,
                                                        `task.${lang}.orderButtonText`
                                                    ).toLowerCase()
                                                })
                                              : loc('charitySnackbar13', lang, {
                                                    type: currentTaskOrderType
                                                })
                                      )
                        }
                    >
                        {loc('submit', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={orderImageDialogOpen}
                onClose={() => handleImageDialog(false)}
                fullScreen={fullScreen}
                fullWidth
                maxWidth={'sm'}
                scroll="paper"
            >
                <DialogTitlePrimary closeButtonShown={true} onClose={() => handleImageDialog(false)}>
                    <span style={{ marginLeft: theme.spacing.unit * 2 }}>{loc('dashboardCharityLogo1', lang)}</span>
                </DialogTitlePrimary>
                <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                    <HighResImageWidget
                        uploadFile={imageFile}
                        handleUpload={handleImageFile}
                        handleUploadDimensions={handleImageDimensions}
                    />
                </DialogContent>
                {imageFile && !isHighQualityImage(imageFile.type, { width: imageWidth, height: imageHeight }) && (
                    <DialogContent>
                        <Typography style={{ color: colors.red[500] }}>{loc('dashboardCharityLogo3', lang)}</Typography>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        color="primary"
                        // only place an order after submitting an image if the currently selected task is an order task
                        onClick={() => handleImageSubmit(!_.isEmpty(currentTaskOrderType) ? true : false)}
                        disabled={!imageFile}
                        data-cy="charity-high-res-image-submit"
                    >
                        {loc('submit', lang)}
                    </Button>
                    <Button onClick={() => handleImageDialog(false)}>close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

CharityGrowthPlanDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired
};

export default withWidth()(
    withStyles(styles)(withMobileDialog({ breakpoint: 'sm' })(withTheme()(CharityGrowthPlanDialog)))
);

function isHighQualityImage(imageType, dimensions) {
    const highQualityTypes = ['application/postscript', 'application/pdf', 'image/svg+xml'];

    // Check for pdf, svg, ai, eps image types
    if (highQualityTypes.includes(imageType)) {
        return true;
    }

    // Check image dimensions in both directions are greater than 4000
    if (_.get(dimensions, 'width', 0) > 4000 || _.get(dimensions, 'height', 0) > 4000) {
        return true;
    }

    return false;
}
