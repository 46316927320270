import React, { useState } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { getReceiverName } from 'utils/misc';

import * as terms from 'localizations/terms';

import {
    Button,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    InputAdornment,
    Typography,
    colors,
    ListItem,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    List,
    FormHelperText,
    Collapse,
    LinearProgress
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';

import { loc } from 'localizations/localizationHandler';
import { useEffect } from 'react';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import TimePicker from 'material-ui-pickers/TimePicker';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import { createLocation, getEmptyLocation } from 'helpers/locationHelper';
import { mdiAccountSearch } from '@mdi/js';
import CharitySelectDialog from 'components/CharitySelect/CharitySelectDialog';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';
import QuickRegisterFormDialog from 'components/Dialogs/QuickRegister';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';
import useQuickRegister from '../QuickRegistration/hooks/useQuickRegister';

function SchedulePickupDialog({
    open,
    lang,
    theme,
    selectedBottleDrive,
    onClose,
    http,
    afterSubmitSuccess,
    handleView,
    transporters,
    processors,
    charities,
    receivers,
    google,
    onSnackbar,
    history,
    promosEnabled
}) {
    const { defaultCoordinates } = useDefaultCoordinates(http);

    const [date, setDate] = useState(moment(_.get(selectedBottleDrive, 'date')));
    const [customer, setCustomer] = useState(_.get(selectedBottleDrive, 'customer'));
    const [customTime, setCustomTime] = useState(_.get(selectedBottleDrive, 'endTime'));
    const [estimatedBags, setEstimatedBags] = useState(_.get(selectedBottleDrive, 'estimatedBags'));
    const [comment, setComment] = useState(_.get(selectedBottleDrive, 'comment'));
    const [charity, setCharity] = useState(_.get(selectedBottleDrive, 'charity'));
    const [initialCharitySuggestion, setInitialCharitySuggestion] = useState(_.get(selectedBottleDrive, 'charityName'));
    const [subdivision, setSubdivision] = useState(_.get(selectedBottleDrive, 'subdivision'));
    const [transporter, setTransporter] = useState(null);
    const [receiver, setReceiver] = useState(null);
    const [processor, setProcessor] = useState(_.get(selectedBottleDrive, 'collector'));
    const [location, setLocation] = useState(_.get(selectedBottleDrive, 'location'));
    const [promos, setPromos] = useState([]);
    const [services, setServices] = useState([]);
    const [allowSpecialCharactersInEmail, setAllowSpecialCharactersInEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [driversSelectable, setDriversSelectable] = useState([]);
    const [charitySelectDialogOpen, setCharitySelectDialogOpen] = useState(false);
    const [charityInfoDialogOpen, setCharityInfoDialogOpen] = useState(false);
    const [charityInfo, setCharityInfo] = useState('');
    const [userSearchString, setUserSearchString] = useState(_.get(selectedBottleDrive, 'contactEmail'));
    const [userSearchError, setUserSearchError] = useState('');

    const [growthPlanEnabled, setGrowthPlanEnabled] = useState(true);

    const contactName = _.get(selectedBottleDrive, 'contactName', '');
    const [firstName = '', lastName = ''] = _.split(_.trim(contactName), ' ');

    const { quickRegisterFormOpen, setQuickRegisterFormOpen, handleRegisterUser } = useQuickRegister({
        http,
        history,
        reloadUsers: () => {},
        onSnackbar,
        viewCustomerAfterRegister: false
    });

    const handleAddressChange = () => {
        setLocation(getEmptyLocation());
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(userSearchString)) {
            linkUserToBottleDrive();
        }
    };

    const linkUserToBottleDrive = async () => {
        let res = await http.getJSON(`/users/getCustomer/${userSearchString}`);
        if (res.ok && !_.isNil(res.data.user)) {
            setCustomer(res.data.user);
        } else {
            setUserSearchError(_.get(res, 'data.message', 'Search string did not match any registered users.'));
        }
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        let locationUpdated = createLocation(
            place,
            suggestion.description,
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        setLocation(locationUpdated);
    };

    const handleRegisterSubmit = async (valuesCopy, { setSubmitting, resetForm }) => {
        const userIsRegistered = await handleRegisterUser(valuesCopy, { setSubmitting, resetForm });
        if (userIsRegistered) {
            await linkUserToBottleDrive();
        }
    };

    const formControlStyle = {
        marginTop: theme.spacing.unit * 2
    };

    useEffect(() => {
        setDate(moment(_.get(selectedBottleDrive, 'date')));
        setUserSearchString(_.get(selectedBottleDrive, 'contactEmail'));
        setUserSearchError('');
        setCustomer(_.get(selectedBottleDrive, 'customer'));
        setCustomTime(_.get(selectedBottleDrive, 'endTime'));
        setEstimatedBags(_.get(selectedBottleDrive, 'estimatedBags'));
        setComment(_.get(selectedBottleDrive, 'comment'));
        setCharity(_.get(selectedBottleDrive, 'charity'));
        setInitialCharitySuggestion(_.get(selectedBottleDrive, 'charityName'));
        setSubdivision(_.get(selectedBottleDrive, 'subdivision', null));
        setTransporter(_.get(selectedBottleDrive, 'collector._id', null));
        setReceiver(null);
        setProcessor(_.get(selectedBottleDrive, 'collector'));
        setLocation(_.get(selectedBottleDrive, 'location'));
        setPromos([]);
    }, [selectedBottleDrive]);

    useEffect(() => {
        getServices();
        getRegistrationConfig();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/config/charityTaskConfiguration');
            if (res.ok) {
                setGrowthPlanEnabled(res.data.config.growthPlanEnabled);
            }
        })();
    }, []);

    useEffect(() => {
        if (_.isNil(transporter)) {
            setDriversSelectable([]);
        } else {
            setDriversSelectable(
                _.filter(
                    receivers,
                    d => !d.banned && !d.disabled && _.get(d, 'collector._id', '').toString() === transporter.toString()
                )
            );
        }
    }, [transporter]);

    const handleCharityInfoDialog = (state, _id) => () => {
        setCharityInfoDialogOpen(state);
        setCharityInfo(_.find(charities, charity => charity._id === _id));
    };

    const handleCharityPreferred = _id => e => {
        setCharitySelectDialogOpen(false);
        setCharity(_.find(charities, c => c._id === _id));
    };

    const handlePromoCharityUpdate = charity => {
        setCharitySelectDialogOpen(false);
        setCharity(_.find(charities, c => c._id === charity._id));
    };

    const handleRequestPickup = async () => {
        setIsLoading(true);
        let values = {
            pickup_id: null,
            date: moment(date)
                .set('hour', moment(customTime).hour())
                .set('minute', moment(customTime).minute()),
            placeAtStartOfTrip: false,
            charity: _.get(charity, '_id'),
            subdivision,
            taxReceiptRequested: _.get(customer, 'receiveTaxReceipts'),
            location,
            payload: { beverage: estimatedBags },
            comment,
            recurring: false,
            frequency: null,
            phone: _.get(customer, 'phone'),
            manualTimeOverride: true,
            customTime,
            isBottleDrive: true,
            timePreference: 'None',
            efficiencyModifier: 1,
            transporterCollector: transporter,
            collector: transporter,
            overrideProcessor: processor,
            receiver,
            donatingToBottleDrive: true,
            bottleDriveId: selectedBottleDrive._id,
            pickupType: _.get(customer, 'location.locationType', 'Residential'),
            promoCodes: promos.map(p => p.code)
        };

        const res = await http.post(`/pickups/requestPickup/${customer._id}`, values);
        if (res.ok) {
            await http.post(`/bottleDrives/${selectedBottleDrive._id}/update`, {
                customer,
                charity,
                collector: processor,
                processedDate: new Date()
                // startTime: bottleDriveStartTime,
                // endTime: bottleDriveEndTime,
                // date: bottleDriveDate
            });

            afterSubmitSuccess();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setIsLoading(false);
    };

    const getServices = async () => {
        let res = await http.getJSON('/services', false, true);
        if (res.ok) {
            setServices(_.get(res, 'data.services', []));
        }
    };

    const getRegistrationConfig = async () => {
        const resRegistrationConfig = await http.getJSON('/registrationConfig', false, true);

        if (resRegistrationConfig.ok) {
            setAllowSpecialCharactersInEmail(_.get(resRegistrationConfig, 'data.allowSpecialCharactersInEmail', true));
        }
    };

    const isPastDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth>
                <DialogTitle style={{ paddingBottom: 14 }}>Confirm Bottle Drive Details</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={formControlStyle}>
                        {_.isNil(customer) ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    verticalAlign: 'center',
                                    paddingTop: theme.spacing.unit
                                }}
                            >
                                <TextField
                                    data-cy="link-account-customer-id-search"
                                    label="Customer ID, Email, or Phone"
                                    value={userSearchString}
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    helperText={!_.isEmpty(userSearchError) ? userSearchError : null}
                                    error={!_.isEmpty(userSearchError)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MDIcon path={mdiAccountSearch} size={1} color={colors.blue[500]} />
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{
                                        marginTop: theme.spacing.unit / 2,
                                        marginRight: theme.spacing.unit
                                    }}
                                    onChange={e => {
                                        setUserSearchError('');
                                        setUserSearchString(e.target.value);
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button
                                    data-cy={`link-account-customer-id-search-submit`}
                                    onClick={() => linkUserToBottleDrive()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Link
                                </Button>
                                <Button
                                    data-cy={`link-account-customer-id-search-submit`}
                                    onClick={() => setQuickRegisterFormOpen(true)}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: theme.spacing.unit }}
                                >
                                    <Icon>add</Icon> New
                                </Button>
                            </div>
                        ) : (
                            <List
                                style={{
                                    margin: 0
                                }}
                            >
                                <ListItem
                                    style={{
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    <Avatar
                                        style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white'
                                        }}
                                    >
                                        {_.get(customer, 'name.first[0]', '?')}
                                    </Avatar>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 'inherit'
                                                }}
                                            >
                                                {`${_.get(customer, 'name.first', 'N/A')} ${_.get(
                                                    customer,
                                                    'name.last',
                                                    ''
                                                )}`}
                                            </span>
                                        }
                                        style={{
                                            whiteSpace: 'normal',
                                            paddingRight: theme.spacing.unit * 3,
                                            position: 'relative'
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <IconButton onClick={() => handleView(customer._id)}>
                                                <Icon>visibility</Icon>
                                            </IconButton>
                                            <IconButton onClick={() => setCustomer(null)}>
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        )}
                        <FormHelperText style={{ marginTop: 0 }}>
                            <div>
                                Contact email was{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {_.get(selectedBottleDrive, 'contactEmail', 'N/A')}
                                </span>{' '}
                                and contact phone was{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {_.get(selectedBottleDrive, 'contactPhone', 'N/A')}
                                </span>
                            </div>
                            <div style={{ paddingTop: theme.spacing.unit }}>
                                Bottle Drive requested for{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {_.get(selectedBottleDrive, 'date')
                                        ? moment(_.get(selectedBottleDrive, 'date')).format('MMM D, YYYY')
                                        : 'N/A'}
                                </span>{' '}
                                between{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {_.get(selectedBottleDrive, 'startTime')
                                        ? moment(_.get(selectedBottleDrive, 'startTime')).format('h:mm A')
                                        : 'N/A'}{' '}
                                </span>{' '}
                                -{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {_.get(selectedBottleDrive, 'startTime')
                                        ? moment(_.get(selectedBottleDrive, 'endTime')).format('h:mm A')
                                        : 'N/A'}
                                </span>
                            </div>
                        </FormHelperText>
                    </FormControl>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            marginTop: theme.spacing.unit * 2
                        }}
                    >
                        <FormControl fullWidth>
                            <DatePicker
                                label="Pickup Date"
                                variant="outlined"
                                timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                value={date}
                                // error={moment(date).isBefore(bottleDriveDate)}
                                // helperText={
                                //     moment(date).isBefore(bottleDriveDate) &&
                                //     'Pickup date cannot be before the bottle drive date'
                                // }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>date_range</Icon>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={date => setDate(date)}
                                disablePast
                                FormHelperTextProps={{ error: true }}
                            />
                        </FormControl>
                        <TimePicker
                            fullWidth
                            label={'Pick up Start Time'}
                            variant="outlined"
                            value={customTime}
                            format="h:mm A"
                            onChange={time => setCustomTime(time)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>schedule</Icon>
                                    </InputAdornment>
                                )
                            }}
                            data-cy="bottle-drive-end-time-input"
                            error={_.isNil(customTime) || _.isEmpty(customTime)}
                            helperText={
                                (_.isNil(customTime) || _.isEmpty(customTime)) && 'You must select a pickup time.'
                            }
                            FormHelperTextProps={{ 'data-cy': `bottle-drive-end-time-input-helper-txt` }}
                        />
                    </div>

                    <GMapsAutocomplete
                        data-cy="registration-address-input"
                        google={google}
                        location={location}
                        label={loc('address', lang)}
                        selectedValue={_.get(location, 'description')}
                        types={['address']}
                        error={{
                            fail: _.isNil(location),
                            reason: 'You must set a location.'
                        }}
                        onChange={handleAddressChange}
                        onSuggestionSelected={handleSuggestionSelected}
                        suggestionsOccupyVerticalSpace={true}
                        style={formControlStyle}
                    />

                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            multiline
                            label={loc('pickupDialog41', lang)}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{ 'data-cy': `bottle-drive-comment-input` }}
                            FormHelperTextProps={{ 'data-cy': `bottle-drive-comment-input-helper-txt` }}
                        />
                    </FormControl>

                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            value={estimatedBags}
                            onChange={e => setEstimatedBags(e.target.value)}
                            label={loc('registrationBottleDrive1', lang)}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{ 'data-cy': `bottle-drive-bags-input` }}
                            error={estimatedBags < 40}
                            helperText={estimatedBags < 40 && 'Must have at least 40 bags'}
                            FormHelperTextProps={{ 'data-cy': `bottle-drive-bags-input-helper-txt` }}
                        />
                    </FormControl>

                    <FormControl fullWidth data-cy="charity-select-organization-select" style={formControlStyle}>
                        <TextField
                            id="outlined-select-charity"
                            data-cy="charity-select-org-select"
                            value={_.isNil(charity) ? loc('noDonation', lang) : _.get(charity, 'name')}
                            onClick={() => setCharitySelectDialogOpen(true)}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon>arrow_drop_down</Icon>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon style={{ color: colors.red[500] }}>favorite</Icon>
                                    </InputAdornment>
                                )
                            }}
                            label={loc('organizationName', lang, {
                                organizationName: terms.ORGANIZATION_NAME
                            })}
                            variant="outlined"
                        />
                        <FormHelperText>
                            Donation preference was{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {_.get(selectedBottleDrive, 'charityName', 'N/A')}
                            </span>
                        </FormHelperText>
                    </FormControl>
                    <Collapse in={_.get(charity, 'subdivisions', []).length > 0}>
                        <FormControl
                            data-cy="charity-select-select-dropdown"
                            fullWidth
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel variant="outlined" htmlFor="subdivisionPreferred">
                                {loc('pickupDialog62', lang)}
                            </InputLabel>
                            <Select
                                value={subdivision}
                                onChange={e => setSubdivision(e.target.value)}
                                renderValue={value => `${value}`}
                                input={
                                    <OutlinedInput
                                        labelWidth={180}
                                        name="subdivisionPreferred"
                                        id="subdivisionPreferred"
                                        data-cy="charity-select-team-dropdown"
                                        className="select-icon"
                                    />
                                }
                            >
                                <MenuItem value={null}>
                                    <ListItemText primary="General Donation" style={{ fontStyle: 'italic' }} />
                                </MenuItem>
                                {_.get(charity, 'subdivisions', []).length > 0 &&
                                    _.get(charity, 'subdivisions', []).map((subdivision, index) => {
                                        return (
                                            <MenuItem
                                                data-cy={`charity-select-select-dropdown-team-${index}`}
                                                key={subdivision}
                                                value={subdivision}
                                            >
                                                {subdivision}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Collapse>
                    <CharitySelectDialog
                        open={charitySelectDialogOpen}
                        charities={charities}
                        onChange={handleCharityPreferred}
                        onInfoDialog={handleCharityInfoDialog}
                        onClose={() => setCharitySelectDialogOpen(false)}
                        hideSubdivisions={true}
                        initialSearchValue={initialCharitySuggestion}
                        growthPlanEnabled={growthPlanEnabled}
                    />

                    <CharityInfoDialog
                        open={charityInfoDialogOpen}
                        charity={charityInfo}
                        onClose={handleCharityInfoDialog(false)}
                    />
                    {promosEnabled && (
                        <FormControl fullWidth>
                            <PromoCodesInput
                                customer={customer}
                                promos={promos}
                                onApplyPromo={e => setPromos([e])}
                                onRemovePromo={e => setPromos([])}
                                charityPreferred={_.get(charity, '_id', null)}
                                onCharityPreferred={e => handlePromoCharityUpdate(e)}
                                donate={!_.isNil(charity)}
                            />
                        </FormControl>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-collector-select"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    >
                        <InputLabel htmlFor="transporterCollector" variant="outlined" shrink>
                            Transporter
                        </InputLabel>
                        <Select
                            value={transporter}
                            onChange={e => setTransporter(e.target.value)}
                            inputProps={{ id: 'transporterCollector', name: 'transporterCollector' }}
                            input={
                                <OutlinedInput
                                    name="transporterCollector"
                                    id="transporterCollector"
                                    labelWidth={'Transporter'.length * theme.spacing.unit}
                                    notched
                                />
                            }
                        >
                            {transporters.map(collector => {
                                return (
                                    <MenuItem
                                        key={collector._id}
                                        value={collector._id}
                                        data-cy={`create-edit-trip-transporterCollector-${collector.name
                                            .replace(/\s*\W*\s+/g, '-')
                                            .toLowerCase()}-li`}
                                    >
                                        {collector.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {_.isEmpty(transporter) && (
                        <Typography
                            style={{
                                marginTop: theme.spacing.unit / 2,
                                color: theme.palette.error.main,
                                marginLeft: 5
                            }}
                        >
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No transporter selected!
                        </Typography>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-collector-select"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    >
                        <InputLabel htmlFor="collector" variant="outlined" shrink>
                            Processor
                        </InputLabel>
                        <Select
                            value={processor}
                            onChange={e => setProcessor(e.target.value)}
                            inputProps={{ id: 'collector', name: 'collector' }}
                            input={
                                <OutlinedInput
                                    name="collector"
                                    id="collector"
                                    labelWidth={'Processor'.length * theme.spacing.unit}
                                    notched
                                />
                            }
                        >
                            {processors.map(collector => {
                                return (
                                    <MenuItem
                                        key={collector._id}
                                        value={collector._id}
                                        data-cy={`create-edit-trip-${collector.name
                                            .replace(/\s*\W*\s+/g, '-')
                                            .toLowerCase()}-li`}
                                    >
                                        {collector.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>
                            Referring Depot was{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {_.get(selectedBottleDrive, 'collectorName', 'N/A')}
                            </span>
                        </FormHelperText>
                    </FormControl>
                    {_.isEmpty(processor) && (
                        <Typography
                            style={{
                                marginTop: theme.spacing.unit / 2,
                                color: colors.amber[700],
                                marginLeft: 5
                            }}
                        >
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: colors.amber[700]
                                }}
                            >
                                warning_amber
                            </Icon>{' '}
                            No processor selected
                        </Typography>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-driver-select"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    >
                        <InputLabel htmlFor="driver" variant="outlined" shrink>
                            Driver
                        </InputLabel>
                        <Select
                            disabled={_.isEmpty(transporter)}
                            value={receiver}
                            onChange={e => {
                                setReceiver(e.target.value);
                            }}
                            inputProps={{ id: 'driver', name: 'driver' }}
                            input={
                                <OutlinedInput
                                    name="driver"
                                    id="driver"
                                    labelWidth={'Driver'.length * theme.spacing.unit}
                                    notched
                                />
                            }
                        >
                            {driversSelectable.map(driver => {
                                return (
                                    <MenuItem
                                        key={driver._id}
                                        value={driver._id}
                                        data-cy={`${getReceiverName(driver)
                                            .replace(/\s/g, '-')
                                            .toLowerCase()}-create-edit-trip-driver-li`}
                                    >
                                        {getReceiverName(driver)} ➡{' '}
                                        <span style={{ color: theme.palette.primary.main }}>
                                            {_.get(
                                                _.find(processors, c => c._id.toString() === processor),
                                                'code',
                                                'N/A'
                                            )}
                                        </span>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {_.isEmpty(receiver) && (
                        <Typography
                            style={{
                                marginTop: theme.spacing.unit / 2,
                                color: colors.amber[700],
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: colors.amber[700]
                                }}
                            >
                                warning_amber
                            </Icon>{' '}
                            No driver selected
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                    <Button
                        data-cy="bottle-drive-submit-btn"
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ height: 40 }}
                        onClick={handleRequestPickup}
                        disabled={
                            _.isNil(customer) || isPastDate || estimatedBags < 40 || _.isNil(transporter)
                            // moment(date).isBefore(bottleDriveDate) ||
                            // moment(bottleDriveStartTime).isAfter(bottleDriveEndTime)
                        }
                    >
                        Schedule Pickup
                    </Button>
                </DialogActions>
                {isLoading && <LinearProgress />}
            </Dialog>

            <QuickRegisterFormDialog
                open={quickRegisterFormOpen}
                google={google}
                collectorObj={null}
                onClose={() => setQuickRegisterFormOpen(false)}
                onSubmitForm={handleRegisterSubmit}
                defaultCoordinates={defaultCoordinates}
                services={services}
                startingValues={{
                    name: {
                        first: firstName,
                        last: lastName || ''
                    },
                    location: _.get(selectedBottleDrive, 'location'),
                    email: _.get(selectedBottleDrive, 'contactEmail', ''),
                    phone: _.get(selectedBottleDrive, 'contactPhone', '')
                }}
                promosEnabled={promosEnabled}
                allowSpecialCharactersInEmail={allowSpecialCharactersInEmail}
            />
        </>
    );
}

export default SchedulePickupDialog;
