import React, { useContext } from 'react';
import moment from 'moment-timezone';
import HttpContext from 'utils/contexts/HttpContext';

import _ from 'lodash';

import { Icon, colors, withTheme, Avatar, Link, Tooltip, IconButton } from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';
import { mdiCounter } from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';
import { loc } from '../../../localizations/localizationHandler';
import { deviceHelper } from '../../../utils/misc.js';

function Complaint({complaint, lang, theme, operator, isDriver, setComplaintImages, history}) {
    const { complaintItemType, issueImages, unresolved, issueDescription, date } = complaint;

    const http = useContext(HttpContext);

    let secondary = loc('sortingComplaint', lang);
    let icon = <MDIcon path={mdiCounter} size={1} color={'white'} />;

    if (complaintItemType === 'pickup') {
        secondary = loc('pickupComplaint', lang);
        icon = <Icon>local_shipping</Icon>;
    }

    if (complaintItemType === 'system') {
        secondary = loc('systemComplaint', lang);
        icon = <Icon>settings</Icon>;
    }

    const openComplaintInBulks = async (operatorId, complaint) => {
        let { complaintId, complaintItemType } = complaint;

        if (complaintItemType !== 'bulk') {
            const res = await http.getJSON(`/bulk?pickup_id=${complaintId}`);
            if (res.ok) complaintId = res.data.bulk._id;
        }

        try {
            const URL = `/operators/${operatorId}/bulks/${complaintId}`;
            if (!deviceHelper.isNativeApp()) {
                window.open(URL, '_system');
            } else {
                history.push(URL);
            }
        } catch (err) {
            console.error('Error opening complaint');
        }
    };

    return (
        <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <ListItemText
                style={{ fontSize: '0.875rem' }}
                primary={
                    <>
                        {!_.isNil(unresolved) && complaintItemType === 'bulk' && (
                            <span
                                style={{
                                    background: unresolved ? colors.red[500] : colors.blue[100],
                                    color: theme.palette.getContrastText(
                                        unresolved ? colors.red[500] : colors.blue[100]
                                    ),
                                    fontSize: theme.typography.fontSize * 0.875,
                                    marginRight: theme.spacing.unit,
                                    padding: '2px 4px',
                                    borderRadius: 4
                                }}
                            >
                                {unresolved ? _.toUpper(loc('unresolved', lang)) : _.toUpper(loc('resolved', lang))}
                            </span>
                        )}
                        {(operator.accountType === 'Collector Employee' &&
                            !operator.accountPermissions.includes('Clerk')) ||
                        (complaintItemType === 'system' || complaintItemType === 'System') ? (
                            <span>"{issueDescription}"</span>
                        ) : (
                            <Link
                                color="inherit"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    if (!isDriver) openComplaintInBulks(operator._id, complaint);
                                }}
                            >
                                "{issueDescription}"
                            </Link>
                        )}
                    </>
                }
                secondary={
                    <>
                        {secondary} - {moment(date).format('MMM DD, YYYY')}
                    </>
                }
            />
            {!_.isEmpty(issueImages) && (
                <Tooltip title={loc('viewComplaintImages', lang)}>
                    <IconButton
                        onClick={() => {
                            setComplaintImages(issueImages);
                        }}
                    >
                        <Icon>image</Icon>
                    </IconButton>
                </Tooltip>
            )}
        </ListItem>
    );
}

export default withTheme()(Complaint);
