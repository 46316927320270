import React, { useContext, useState, useMemo, useEffect } from 'react';
import { COMMODITY_COLORS, PICKUP } from 'constants.js';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import {
    getAppBarHeight,
    getCustomerFirstNameAndLastInitial,
    getQuickDropName,
    getWalkInName,
    truncateBagtag
} from 'utils/misc';
import bulkHelper from 'helpers/bulkHelper';

import * as terms from 'localizations/terms';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import BulkSearchDialog from 'components/BulkComponents/BulkSearchDialog';
import BulkCard from './BulkCard';
import SearchBulkCard from './SearchBulkCard';
import SearchLabelCard from './SearchLabelCard';
import BulkCreateDialog from './BulkCreateDialog';

import BulkSkuTypeSelectionDropdown from 'components/BulkComponents/BulkSkuTypeSelectionDropdown';
import BulkSkuTypeSelectionDropdownMulti from 'components/BulkComponents/BulkSkuTypeSelectionDropdownMulti';
import BulkTypeSelectionDropdown from 'components/BulkComponents/BulkTypeSelectionDropdown';
import BulkReceiverSelectionDropdown from 'components/BulkComponents/BulkReceiverSelectionDropdown';
import BulkTripSelectionDropdown from 'components/BulkComponents/BulkTripSelectionDropdown';
import BulkCollectorSelectionDropdown from 'components/BulkComponents/BulkCollectorSelectionDropdown';
import CustomMUIBadge from 'components/Misc/CustomMUIBadge';

import { Checkbox, colors } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Icon as MDIcon } from '@mdi/react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    Hidden,
    Tab,
    Tabs,
    AppBar,
    Toolbar,
    ExpansionPanel,
    FormControlLabel,
    IconButton,
    Badge
} from '@material-ui/core';

import { _user } from 'std';

// custom hooks
import useBulkCounter from './hooks/useBulkCounter';

import OperatorContext from 'utils/contexts/OperatorContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import {
    mdiStarFace,
    mdiFilterMenuOutline,
    mdiClockAlert,
    mdiAccountAlert,
    mdiCheckCircle,
    mdiFormatListChecks,
    mdiCashEdit,
    mdiExitRun,
    mdiWalk,
    mdiCounter
} from '@mdi/js';

import { withTheme } from '@material-ui/core/styles';
import { _bulk } from 'std';

import HttpContext from 'utils/contexts/HttpContext';
import { getDriverName } from 'std/helpers/bulk';

// color scheme for the commodity types

const columnStyles = {
    height: '100%'
};

const columnPaperStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
};

const STEP_TITLES = ['Incomplete', 'Overdue', 'Complaints', 'Completed'];

function BulkCounter(props) {
    const { innerWidth: width, innerHeight: height } = window;
    const {
        theme,
        skus = [],
        receivers = [],
        collectors = [],
        resetTimeoutCount,
        incompleteBulks,
        overdueBulks,
        whinyBulks,
        completedBulks,
        lostAndFoundBulks,
        incompleteBulksLoading,
        overdueBulksLoading,
        whinyBulksLoading,
        completedBulksLoading,
        lostAndFoundBulksLoading,
        bulksLoading,
        fetchAllBulks,
        commodityColors,
        fetchCommodityColors,
        collector,
        commodities,
        fetchCommodities,
        commoditiesByCollector,
        fetchCommoditiesByCollector,
        pickupsEnabled
    } = props || {};

    const warnAction = useContext(ConfirmDialogContext);
    const history = useHistory();
    const operator = useContext(OperatorContext);
    const onSnackbar = useContext(SnackbarContext);

    const [currentTab, setCurrentTab] = useState(0); // tabs for mobile
    const [showFilters, setShowFilters] = useState(false); // tabs for mobile

    const [allCharities, setAllCharities] = useState([]);
    const [tripsWithIncompletePickups, setTripsWithIncompletePickups] = useState([]);
    const [showFiltersAnyways, setShowFiltersAnyways] = useState(false);
    const [manualSkus, setManualSkus] = useState(skus);
    const [bagtagLocationMap, setBagtagLocationMap] = useState({});
    const [bagtagLocationMapOldBagtagList, setBagtagLocationMapOldBagtagList] = useState([]);

    const [growthPlanEnabled, setGrowthPlanEnabled] = useState(true);

    const http = useContext(HttpContext);
    const accessibleSkus = useMemo(() => {
        return (operator.accountType === 'Collector Administrator' && !operator.adminView) ||
            (operator.accountType === 'Collector Employee' &&
                !operator.adminView &&
                operator.accountPermissions.includes('Clerk'))
            ? Object.keys(manualSkus) || []
            : Object.keys(skus) || [];
    }, [operator, manualSkus, skus]);

    const fetchCharities = async () => {
        const res = await http.getJSON('/charities/all');
        if (res.ok) {
            setAllCharities(_.get(res, 'data.charities', []));
        }
    };

    const allCommodityColors = { ...COMMODITY_COLORS, ...commodityColors };

    useEffect(() => {
        let manualSkuTypes = Array.from(
            commodities.filter(commodity => commodity.completionStrategy === 'Manual'),
            item => item.skuType
        );
        let newManualSkus = [];
        Object.keys(skus).forEach(skuName => {
            if (manualSkuTypes.includes(skuName)) {
                newManualSkus[skuName] = skus[skuName];
            }
        });
        setManualSkus(newManualSkus);
    }, [commodities, skus]);
    useEffect(() => {
        fetchAllBulks();
        fetchCommodityColors();
        fetchCharities();
        fetchCommodities();
        fetchCommoditiesByCollector();
        // make sure no counts accidentally get carried over
        localStorage.removeItem('currentBulkCounts');
        let newBulkRefreshSVCID = '';
        http.getJSON('/system/configuration/PORRefreshInterval').then(res => {
            if (res.ok) {
                let refreshInterval = _.get(res.data, 'refreshInterval', 0);
                if (refreshInterval > 4999) {
                    newBulkRefreshSVCID = setInterval(() => {
                        fetchAllBulks(false);
                    }, refreshInterval);
                }
            }
        });
        return () => {
            clearInterval(newBulkRefreshSVCID);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/config/charityTaskConfiguration');
            if (res.ok) {
                setGrowthPlanEnabled(res.data.config.growthPlanEnabled);
            }
        })();
    }, []);

    const {
        inProgress,
        searchingForCustomer,
        skuTypeFilter,
        bulkTypeFilter,
        bulkReceiverFilter,
        bulkTripFilter,
        bulkCollectorFilter,
        bulkProcessorFilter,
        globalBulkFilter,
        filteredReceivers,
        continuousCountingEnabled,
        bulkSearchDialogOpen,
        handleOpenSearchDialog,
        handleCloseSearchDialog,
        handleBulkSearchQueryChange,
        bulkSearchDialogQuery,
        bulkSearchDialogError,
        bulkSearchSkuType,
        bulkCreateDialogOpen,
        bulkCreateBulkType,
        bulkCreateSkuType,
        customerID,
        numberOfBags,
        selfishMode,
        disableChangingBulkType,
        bulkCreateDialogError,
        donateQuickdrop,
        charityPreferred,
        subdivisionPreferred,
        donate,
        handlePayload,
        handleGlobalBulkSearchChange,
        handleToggleContinuousCounting,
        handleSkuTypeFilterChange,
        handleBulkTypeFilterChange,
        handleBulkReceiverFilterChange,
        handleBulkTripFilterChange,
        handleBulkCollectorFilterChange,
        handleBulkProcessorFilterChange,
        handleSearchSkuTypeChange,
        handleSearchForBulkToCount,
        handleCreateBulk,
        handleOpenCreateBulkDialog,
        handleCloseCreateBulkDialog,
        handleCreateSkuTypeChange,
        handleCreateBulkTypeChange,
        handleBulkCreateCustomerIDChange,
        handleToggleSelfishMode,
        handleSetDonateQuickdrop,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        handleSetDonation,
        lostAndFoundBulkType,
        handleLAFBulkTypeChange,
        skuTypeFilterMulti,
        setSkuTypeFilterMulti,
        handleSkuTypeFilterChangeMulti,
        bulkCreateCollector,
        handleBulkCreateCollectorChange
        /*handleUpdateCommoditiesProcessed,
        handleIncrementCommoditiesProcessed,
        handleDecrementCommoditiesProcessed*/
    } = useBulkCounter({
        bulks: [...whinyBulks, ...overdueBulks, ...incompleteBulks, ...completedBulks],
        receivers,
        trips: tripsWithIncompletePickups,
        collectors,
        loadingBulks: incompleteBulksLoading || overdueBulksLoading,
        warnAction,
        initialCollector: _user.isCollectorAdmin(operator) ? collector : null,
        skus,
        commodities
    });

    const [bulkLookUpResult, setBulkLookUpResult] = useState([]);
    const [bulkLookUpLoading, setBulkLookUpLoading] = useState(false);
    const [bulkLookUpResultIsEmpty, setBulkLookUpResultIsEmpty] = useState(false);
    const [searchResultTab, setSearchResultTab] = useState(0);
    const [printedLabelSearchResult, setPrintedLabelSearchResult] = useState([]);
    const [printedLabelSearchLoading, setPrintedLabelSearchLoading] = useState(false);
    const [printedLabelSearchResultIsEmpty, setPrintedLabelSearchResultIsEmpty] = useState(false);
    const [numberOfPrintedLabelFound, setNumberOfPrintedLabelFound] = useState(0);
    async function handleBulkLookUp() {
        setBulkLookUpResultIsEmpty(false);
        setPrintedLabelSearchResultIsEmpty(false);
        setBulkLookUpLoading(true);
        setPrintedLabelSearchLoading(true);
        let bulkSearchDialogQueryTrimmed = bulkSearchDialogQuery.replace(/\s/g, '');
        let possibleBulks = await getOrderSearchResult(bulkSearchDialogQueryTrimmed);
        const newPrintedLabelSearchResult = await getPrintedLabelSearchResult(bulkSearchDialogQueryTrimmed);
        if (newPrintedLabelSearchResult.length === 0) {
            setPrintedLabelSearchResultIsEmpty(true);
        } else {
            setPrintedLabelSearchResultIsEmpty(false);
        }
        setPrintedLabelSearchLoading(false);
        setPrintedLabelSearchResult(newPrintedLabelSearchResult);
        possibleBulks = possibleBulks.filter(bulk => {
            return accessibleSkus.includes(bulk.skuType);
        });

        let bagtagList = [];
        possibleBulks.forEach(bulk => {
            _.get(bulk, 'countingSessions', []).forEach(session => {
                let bagtag = _.get(session, 'bagtag', '');
                if (!_.isEmpty(bagtag)) {
                    bagtagList.push(bagtag);
                }
            });
        });

        // don't call again if search is the same
        if (!_.isEmpty(_.xor(bagtagLocationMapOldBagtagList, bagtagList))) {
            const res = await http.postJSON('/getBagtagLocationMap', { bagtagList });
            if (res.ok) {
                setBagtagLocationMapOldBagtagList(bagtagList);
                setBagtagLocationMap(res.data);
            }
        }

        setBulkLookUpLoading(false);
        if (possibleBulks.length === 0) {
            setBulkLookUpResultIsEmpty(true);
        } else {
            setBulkLookUpResult(possibleBulks);
        }
    }

    useEffect(() => {
        let trips = [];
        let bulks = _.concat(
            _.filter(
                incompleteBulks,
                b =>
                    (bulkCollectorFilter === 'all' ||
                        _.get(
                            b,
                            'pickup.trip.transporterCollector._id',
                            _.get(b, 'receiver.collector._id', '')
                        ).toString() === bulkCollectorFilter) &&
                    (bulkProcessorFilter === 'all' || b.collector._id.toString() === bulkProcessorFilter) &&
                    skuTypeFilterMulti.includes(b.skuType) &&
                    [PICKUP, 'all'].includes(bulkTypeFilter) &&
                    b.bulkType === PICKUP
            ),
            _.filter(
                overdueBulks,
                b =>
                    (bulkCollectorFilter === 'all' ||
                        _.get(
                            b,
                            'pickup.trip.transporterCollector._id',
                            _.get(b, 'receiver.collector._id', '')
                        ).toString() === bulkCollectorFilter) &&
                    (bulkProcessorFilter === 'all' || b.collector._id.toString() === bulkProcessorFilter) &&
                    skuTypeFilterMulti.includes(b.skuType) &&
                    [PICKUP, 'all'].includes(bulkTypeFilter) &&
                    b.bulkType === PICKUP
            ),
            _.filter(
                whinyBulks,
                b =>
                    (bulkCollectorFilter === 'all' ||
                        _.get(
                            b,
                            'pickup.trip.transporterCollector._id',
                            _.get(b, 'receiver.collector._id', '')
                        ).toString() === bulkCollectorFilter) &&
                    (bulkProcessorFilter === 'all' || b.collector._id.toString() === bulkProcessorFilter) &&
                    skuTypeFilterMulti.includes(b.skuType) &&
                    [PICKUP, 'all'].includes(bulkTypeFilter) &&
                    b.bulkType === PICKUP
            ),
            _.filter(
                completedBulks,
                b =>
                    (bulkCollectorFilter === 'all' ||
                        _.get(
                            b,
                            'pickup.trip.transporterCollector._id',
                            _.get(b, 'receiver.collector._id', '')
                        ).toString() === bulkCollectorFilter) &&
                    (bulkProcessorFilter === 'all' || b.collector._id.toString() === bulkProcessorFilter) &&
                    skuTypeFilterMulti.includes(b.skuType) &&
                    [PICKUP, 'all'].includes(bulkTypeFilter) &&
                    b.bulkType === PICKUP
            )
        );
        let bulksByTrip = _.groupBy(bulks, 'pickup.trip._id');

        for (let trip_id of Object.keys(bulksByTrip)) {
            let bulk = _.first(bulksByTrip[trip_id]);
            let completedBulks = _.filter(
                bulksByTrip[trip_id],
                b => !_.isNil(b.dateCompleted) && _.get(b, 'customerIssues.customerHasIssue', false) === false
            );
            let overdueBulks = _.filter(bulksByTrip[trip_id], b => b.isOverdue);

            trips.push({
                _id: trip_id,
                driverName: getCustomerFirstNameAndLastInitial(bulk.receiver),
                transporterCollectorCode: _.get(
                    bulk,
                    'pickup.trip.transporterCollector.code',
                    _.get(bulk, 'receiver.collector.code', 'N/A')
                ).toString(),
                startTime: _.get(bulk, 'pickup.trip.startTime', ''),
                collector: bulk.collector._id,
                numberOfBulks: bulksByTrip[trip_id].length,
                numberOfCompletedBulks: completedBulks.length,
                numberOfOverdueBulks: overdueBulks.length
            });
        }
        setTripsWithIncompletePickups(trips);
    }, [
        incompleteBulks,
        overdueBulks,
        whinyBulks,
        skuTypeFilterMulti,
        bulkTypeFilter,
        bulkCollectorFilter,
        bulkProcessorFilter
    ]);

    const bulkPermissions = _.get(operator, 'collector.configuration.bulkPermissions', false);
    /*const inhouseDisabled =
        !_.get(bulkPermissions, 'inhouse.create', false) &&
        !_.get(operator, 'collector.hasDropLocations', false) &&
        !_user.isSystemAdmin(operator);*/
    const inhouseDisabled =
        (!_.get(bulkPermissions, 'inhouse.create', false) || !_.get(operator, 'collector.hasDropLocations', false)) &&
        !_user.isSystemAdmin(operator) &&
        !_user.isInternalRole(operator);
    const walkinDisabled =
        !_.get(bulkPermissions, 'walk-in.create', false) &&
        !_user.isSystemAdmin(operator) &&
        !_user.isInternalRole(operator);
    const adjustmentsDisabled =
        !_.get(bulkPermissions, 'adjustment.create', false) &&
        !_user.isSystemAdmin(operator) &&
        !_user.isInternalRole(operator);

    const mappedOverdueBulks = useMemo(
        () =>
            _(overdueBulks)
                .filter(b =>
                    filterFunction({
                        bulk: b,
                        filterString: globalBulkFilter,
                        skuType: skuTypeFilterMulti,
                        bulkType: bulkTypeFilter,
                        receiver: bulkReceiverFilter,
                        trip: bulkTripFilter,
                        collector: bulkCollectorFilter,
                        processor: bulkProcessorFilter,
                        creator: operator._id,
                        accessibleSkus: accessibleSkus
                    })
                )
                .value(),
        [
            overdueBulks,
            globalBulkFilter,
            skuTypeFilterMulti,
            bulkTypeFilter,
            bulkReceiverFilter,
            bulkTripFilter,
            bulkCollectorFilter,
            bulkProcessorFilter,
            operator._id,
            accessibleSkus
        ]
    );

    const mappedWhinyBulks = useMemo(
        () =>
            _(whinyBulks)
                .filter(b =>
                    filterFunction({
                        bulk: b,
                        filterString: globalBulkFilter,
                        skuType: skuTypeFilterMulti,
                        bulkType: bulkTypeFilter,
                        receiver: bulkReceiverFilter,
                        trip: bulkTripFilter,
                        collector: bulkCollectorFilter,
                        processor: bulkProcessorFilter,
                        creator: operator._id,
                        accessibleSkus: accessibleSkus
                    })
                )
                .value(),
        [
            whinyBulks,
            globalBulkFilter,
            skuTypeFilterMulti,
            bulkTypeFilter,
            bulkReceiverFilter,
            bulkTripFilter,
            bulkCollectorFilter,
            bulkProcessorFilter,
            operator._id,
            accessibleSkus
        ]
    );

    const mappedCompletedBulks = useMemo(
        () =>
            _(completedBulks)
                .filter(b =>
                    filterFunction({
                        bulk: b,
                        filterString: globalBulkFilter,
                        skuType: skuTypeFilterMulti,
                        bulkType: bulkTypeFilter,
                        receiver: bulkReceiverFilter,
                        trip: bulkTripFilter,
                        collector: bulkCollectorFilter,
                        processor: bulkProcessorFilter,
                        selfishMode,
                        creator: operator._id,
                        accessibleSkus: accessibleSkus
                    })
                )
                .sortBy(b => b.dateCompleted)
                .reverse()
                .value(),
        [
            completedBulks,
            globalBulkFilter,
            skuTypeFilterMulti,
            bulkTypeFilter,
            bulkReceiverFilter,
            bulkTripFilter,
            bulkCollectorFilter,
            bulkProcessorFilter,
            selfishMode,
            operator._id,
            accessibleSkus
        ]
    );

    const mappedIncompleteBulks = useMemo(
        () =>
            _(incompleteBulks)
                .filter(b =>
                    filterFunction({
                        bulk: b,
                        filterString: globalBulkFilter,
                        skuType: skuTypeFilterMulti,
                        bulkType: bulkTypeFilter,
                        receiver: bulkReceiverFilter,
                        trip: bulkTripFilter,
                        collector: bulkCollectorFilter,
                        processor: bulkProcessorFilter,
                        creator: operator._id,
                        accessibleSkus: accessibleSkus
                    })
                )
                .sortBy(b => b.datePickedUp)
                .value(),
        [
            incompleteBulks,
            globalBulkFilter,
            skuTypeFilterMulti,
            bulkTypeFilter,
            bulkReceiverFilter,
            bulkTripFilter,
            bulkCollectorFilter,
            bulkProcessorFilter,
            operator._id,
            accessibleSkus
        ]
    );

    const listTitleTextStyle = {
        fontWeight: 500
    };

    const listTitleStyle = {
        padding: theme.spacing.unit * 2
    };

    const overdueBulksItemData = useMemo(
        () =>
            createItemData(
                theme,
                mappedOverdueBulks,
                skus,
                allCommodityColors,
                operator,
                history,
                resetTimeoutCount,
                commodities
            ),
        [theme, mappedCompletedBulks, skus, allCommodityColors, operator, history, resetTimeoutCount, commodities]
    );
    const whinyBulksItemData = useMemo(
        () =>
            createItemData(
                theme,
                mappedWhinyBulks,
                skus,
                allCommodityColors,
                operator,
                history,
                resetTimeoutCount,
                commodities
            ),
        [theme, mappedCompletedBulks, skus, allCommodityColors, operator, history, resetTimeoutCount, commodities]
    );
    const incompleteBulksItemData = useMemo(
        () =>
            createItemData(
                theme,
                mappedIncompleteBulks,
                skus,
                allCommodityColors,
                operator,
                history,
                resetTimeoutCount,
                commodities
            ),
        [theme, mappedCompletedBulks, skus, allCommodityColors, operator, history, resetTimeoutCount, commodities]
    );

    const completeBulksItemData = useMemo(
        () =>
            createItemData(
                theme,
                mappedCompletedBulks,
                skus,
                allCommodityColors,
                operator,
                history,
                resetTimeoutCount,
                commodities
            ),
        [theme, mappedCompletedBulks, skus, allCommodityColors, operator, history, resetTimeoutCount, commodities]
    );

    const bulkLookUpResultItemData = useMemo(
        () =>
            createItemData(
                theme,
                bulkLookUpResult,
                skus,
                allCommodityColors,
                operator,
                history,
                resetTimeoutCount,
                commodities,
                bagtagLocationMap,
                bulkSearchDialogQuery
            ),
        [
            theme,
            bulkLookUpResult,
            skus,
            allCommodityColors,
            operator,
            history,
            resetTimeoutCount,
            commodities,
            bagtagLocationMap,
            bulkSearchDialogQuery
        ]
    );
    const printedLabelSearchResultItemData = useMemo(() => {
        let labelInfo = [];
        let bulkSearchDialogQueryTrimmed = bulkSearchDialogQuery.replace(/\s/g, '');
        printedLabelSearchResult.forEach(customerHistory => {
            _.get(customerHistory, 'data.printedLabels', []).forEach(label => {
                if (label.includes(bulkSearchDialogQueryTrimmed)) {
                    labelInfo.push({
                        label,
                        printedDate: _.get(customerHistory, 'date'),
                        location: _.get(customerHistory, 'data.kioskLocation', '')
                    });
                }
            });
        });
        setNumberOfPrintedLabelFound(labelInfo.length);
        return {
            theme,
            items: labelInfo
        };
    }, [theme, printedLabelSearchResult]);
    async function getOrderSearchResult(bulkSearchDialogQuery) {
        const res = await http.postJSON('/bulks/getBagtagSearchResult', { bagtagSearchString: bulkSearchDialogQuery });
        return _.get(res, 'data.bulks', []);
    }
    async function getPrintedLabelSearchResult(bulkSearchDialogQuery) {
        const res = await http.postJSON('/searchPrintedLabels', { bagtagSearchString: bulkSearchDialogQuery });
        return _.get(res, 'data.historyResults', []);
    }

    const mobileSelectedBulkListData = useMemo(() => {
        if (currentTab === 0) {
            return incompleteBulksItemData;
        } else if (currentTab === 1) {
            return overdueBulksItemData;
        } else if (currentTab === 2) {
            return whinyBulksItemData;
        } else if (currentTab === 3) {
            return completeBulksItemData;
        }
    }, [completeBulksItemData, currentTab, incompleteBulksItemData, overdueBulksItemData, whinyBulksItemData]);

    const mobileSelectedMappedBulkListSize = useMemo(() => {
        if (currentTab === 0) {
            return mappedIncompleteBulks.length;
        } else if (currentTab === 1) {
            return mappedOverdueBulks.length;
        } else if (currentTab === 2) {
            return mappedWhinyBulks.length;
        } else if (currentTab === 3) {
            return mappedCompletedBulks.length;
        }
    }, [currentTab, mappedCompletedBulks, mappedIncompleteBulks, mappedOverdueBulks, mappedWhinyBulks]);

    const mobileSelectedBulkListSize = useMemo(() => {
        if (currentTab === 0) {
            return incompleteBulks.length;
        } else if (currentTab === 1) {
            return overdueBulks.length;
        } else if (currentTab === 2) {
            return whinyBulks.length;
        } else if (currentTab === 3) {
            return completedBulks.length;
        }
    }, [completedBulks.length, currentTab, incompleteBulks.length, overdueBulks.length, whinyBulks.length]);

    const enabledSkus = useMemo(() => {
        return _.pickBy(skus, (skusForSkuType, skuType) => {
            const commodity = _.find(commodities, { skuType });
            return !_.get(commodity, 'disabled', false);
        });
    }, [skus, commodities]);

    const enabledManualSkus = useMemo(() => {
        return _.pickBy(manualSkus, (skusForSkuType, skuType) => {
            const commodity = _.find(commodities, { skuType });
            return !_.get(commodity, 'disabled', false);
        });
    }, [manualSkus, commodities]);

    const dropdowns = (
        <>
            <BulkSkuTypeSelectionDropdownMulti
                commodityColors={allCommodityColors}
                skus={
                    (operator.accountType === 'Collector Administrator' && !operator.adminView) ||
                    (operator.accountType === 'Collector Employee' &&
                        !operator.adminView &&
                        operator.accountPermissions.includes('Clerk'))
                        ? enabledManualSkus
                        : enabledSkus
                }
                helperText="Commodities"
                labelCustomWidth={120}
                typeFilter={skuTypeFilterMulti}
                handleChange={e => {
                    handleSkuTypeFilterChangeMulti(e);
                    resetTimeoutCount();
                }}
                style={{ margin: theme.spacing.unit, marginLeft: 0 }}
            />
            <BulkTypeSelectionDropdown
                skus={skus}
                disablePickupsType={!pickupsEnabled}
                helperText="Order Type"
                typeFilter={bulkTypeFilter}
                labelCustomWidth={80}
                handleChange={e => {
                    handleBulkTypeFilterChange(e);
                    resetTimeoutCount();
                }}
                style={{ margin: theme.spacing.unit, marginLeft: 0 }}
            />
            <BulkCollectorSelectionDropdown
                skus={skus}
                loading={bulksLoading}
                collectors={collectors}
                bulkCollectorFilter={bulkCollectorFilter}
                helperText="Transporter"
                labelCustomWidth={70}
                handleChange={e => {
                    handleBulkCollectorFilterChange(e);
                    resetTimeoutCount();
                }}
            />
            <BulkCollectorSelectionDropdown
                skus={skus}
                loading={bulksLoading}
                collectors={collectors}
                bulkCollectorFilter={bulkProcessorFilter}
                helperText="Processor"
                labelCustomWidth={75}
                handleChange={e => {
                    handleBulkProcessorFilterChange(e);
                    resetTimeoutCount();
                }}
            />
            {/* <BulkReceiverSelectionDropdown
                skus={skus}
                loading={bulksLoading}
                receivers={filteredReceivers}
                bulkReceiverFilter={bulkReceiverFilter}
                helperText="# Incomplete - Driver"
                labelCustomWidth={145}
                handleChange={e => {
                    handleBulkReceiverFilterChange(e);
                    resetTimeoutCount();
                }}
                style={{ margin: theme.spacing.unit, marginLeft: 0 }}
            /> */}
            <BulkTripSelectionDropdown
                skus={skus}
                loading={bulksLoading}
                trips={tripsWithIncompletePickups}
                bulkTripFilter={bulkTripFilter}
                helperText="# Incomplete - Trip"
                labelCustomWidth={130}
                handleChange={e => {
                    handleBulkTripFilterChange(e);
                    resetTimeoutCount();
                }}
                style={{ margin: theme.spacing.unit, marginLeft: 0 }}
            />
        </>
    );

    const tabStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <div
            style={{
                padding: theme.spacing.unit * 2,
                height: `calc(100% - ${getAppBarHeight(theme.breakpoints) + theme.spacing.unit * 3}px)`
            }}
        >
            <Hidden smDown>
                <Paper
                    className="fade-in"
                    style={{
                        alignItems: 'center'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    padding: theme.spacing.unit * 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: '155px',
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Button
                                        size="small"
                                        data-cy="bulk-counter-count-btn"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            handleOpenSearchDialog();
                                            resetTimeoutCount();
                                        }}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            width: '155px',
                                            marginBottom: theme.spacing.unit,
                                            marginRight: theme.spacing.unit
                                        }}
                                    >
                                        <MDIcon
                                            path={mdiCounter}
                                            size={1}
                                            color="white"
                                            style={{ marginRight: theme.spacing.unit }}
                                        />
                                        Lookup Bag Tag
                                    </Button>
                                    <Button
                                        size="small"
                                        data-cy="lost-and-found-btn"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            handleOpenCreateBulkDialog(skuTypeFilter, 'lostandfound');
                                        }}
                                        style={{ whiteSpace: 'nowrap', width: '155px' }}
                                    >
                                        Lost and Found
                                    </Button>
                                </div>

                                {(!inhouseDisabled || !walkinDisabled || !adjustmentsDisabled) && (
                                    <div style={{ width: '240px', marginRight: theme.spacing.unit }}>
                                        {!inhouseDisabled && (
                                            <div style={{ display: 'flex' }}>
                                                <Button
                                                    size="small"
                                                    data-cy="bulk-counter-create-quickdrop-btn"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{
                                                        marginBottom: theme.spacing.unit,
                                                        flexGrow: 1,
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                    onClick={() => {
                                                        handleOpenCreateBulkDialog(skuTypeFilter, 'inhouse');
                                                        resetTimeoutCount();
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiWalk}
                                                        size={1}
                                                        color="white"
                                                        style={{ marginRight: theme.spacing.unit }}
                                                    />
                                                    {getQuickDropName('en')}{' '}
                                                    {/*- OVERRIDE CODE: {operator.collector.quickdropOverrideCode}*/}
                                                </Button>
                                            </div>
                                        )}

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {!walkinDisabled && (
                                                <Button
                                                    size="small"
                                                    data-cy="bulk-counter-create-walkin-btn"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        flexGrow: 1,
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                    onClick={() => {
                                                        handleOpenCreateBulkDialog(skuTypeFilter, 'walk-in');
                                                        resetTimeoutCount();
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiExitRun}
                                                        size={1}
                                                        color="white"
                                                        style={{ marginRight: theme.spacing.unit }}
                                                    />
                                                    {process.env.REACT_APP_REGION_EXT === 'CON'
                                                        ? 'Walk In'
                                                        : getWalkInName('en')}
                                                </Button>
                                            )}
                                            {!adjustmentsDisabled && (
                                                <Button
                                                    data-cy="bulk-counter-create-adjustment-btn"
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ flexGrow: 1, whiteSpace: 'nowrap' }}
                                                    onClick={() => {
                                                        handleOpenCreateBulkDialog(skuTypeFilter, 'adjustment');
                                                        resetTimeoutCount();
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiCashEdit}
                                                        size={1}
                                                        color="white"
                                                        style={{ marginRight: theme.spacing.unit }}
                                                    />
                                                    Adjustment
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <TextField
                                            data-cy="bulk-counter-filter-input"
                                            value={globalBulkFilter}
                                            type="search"
                                            InputProps={{
                                                startAdornment: (
                                                    <Icon style={{ marginRight: theme.spacing.unit }}>search</Icon>
                                                )
                                            }}
                                            style={{ marginRight: theme.spacing.unit, width: '160px' }}
                                            variant="outlined"
                                            label="Order search"
                                            onChange={e => {
                                                handleGlobalBulkSearchChange(e);
                                                resetTimeoutCount();
                                            }}
                                        />

                                        {(width > 1100 || showFiltersAnyways) && dropdowns}
                                        {width <= 1100 && (
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    style={{ flexGrow: 1, whiteSpace: 'nowrap' }}
                                                    onClick={() => {
                                                        if (showFiltersAnyways) {
                                                            setShowFiltersAnyways(false);
                                                        } else {
                                                            setShowFiltersAnyways(true);
                                                        }
                                                    }}
                                                >
                                                    {showFiltersAnyways ? 'Hide Filters' : 'Show filters'}
                                                </Button>
                                                <IconButton
                                                    onClick={() => {
                                                        window.open(`/operators/${operator._id}/live-counts`, '_blank');
                                                    }}
                                                >
                                                    <Icon>cast</Icon>
                                                </IconButton>
                                            </div>
                                        )}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-cy="bulk-counter-show-mine-checkbox"
                                                    checked={selfishMode}
                                                    onChange={() => {
                                                        handleToggleSelfishMode();
                                                        resetTimeoutCount();
                                                    }}
                                                    // value={reason.short}
                                                    style={{
                                                        paddingTop: theme.spacing.unit / 2,
                                                        paddingBottom: theme.spacing.unit / 2,
                                                        marginLeft: theme.spacing.unit
                                                    }}
                                                />
                                            }
                                            label="Only show mine"
                                        />
                                        <IconButton
                                            onClick={() => {
                                                window.open(`/operators/${operator._id}/live-counts`, '_blank');
                                            }}
                                        >
                                            <Icon>cast</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            {_.get(operator, 'collector.hasDropLocations', false) && !_user.isSystemAdmin(operator) && (
                                <Typography
                                    color="textSecondary"
                                    align="center"
                                    style={{ fontSize: 18, marginTop: theme.spacing.unit }}
                                >
                                    Override Code:{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {collector.quickdropOverrideCode}
                                    </span>
                                </Typography>
                            )}
                        </div>
                    </div>
                </Paper>

                <Grid
                    className="fade-in"
                    container
                    spacing={theme.spacing.unit * 2}
                    style={{
                        marginTop: theme.spacing.unit * 1,
                        display: 'flex',
                        height: '100%'
                    }}
                >
                    <Grid item md={4} sm={12} xs={12} style={columnStyles}>
                        <Paper style={columnPaperStyles}>
                            <Grid container style={listTitleStyle}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" style={listTitleTextStyle}>
                                        Overdue
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Totals
                                        loading={overdueBulksLoading}
                                        theme={theme}
                                        total={overdueBulks.length}
                                        visibleTotal={mappedOverdueBulks.length}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: '42%', paddingBottom: theme.spacing.unit }}>
                                <Divider />
                                {overdueBulksLoading && <LinearProgress />}
                                {_.isEmpty(overdueBulks) && globalBulkFilter === '' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <MDIcon path={mdiStarFace} size={6} color={colors.grey[100]} />
                                    </div>
                                ) : (
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height - 1}
                                                itemCount={mappedOverdueBulks.length}
                                                itemData={overdueBulksItemData}
                                                itemSize={82}
                                                width={width}
                                            >
                                                {BulkCard}
                                            </List>
                                        )}
                                    </AutoSizer>
                                )}
                            </div>
                            <Divider />
                            <Grid container style={listTitleStyle}>
                                <Grid item xs={8}>
                                    <Typography variant="h6" style={listTitleTextStyle}>
                                        Complaints, Errors & Approvals
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Totals
                                        loading={whinyBulksLoading}
                                        theme={theme}
                                        total={whinyBulks.length}
                                        visibleTotal={mappedWhinyBulks.length}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: '42%', paddingBottom: theme.spacing.unit }}>
                                <Divider />
                                {whinyBulksLoading && <LinearProgress />}
                                {_.isEmpty(whinyBulks) && globalBulkFilter === '' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <MDIcon path={mdiStarFace} size={6} color={colors.grey[100]} />
                                    </div>
                                ) : (
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height - 1}
                                                itemCount={mappedWhinyBulks.length}
                                                itemData={whinyBulksItemData}
                                                itemSize={82}
                                                width={width}
                                            >
                                                {BulkCard}
                                            </List>
                                        )}
                                    </AutoSizer>
                                )}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={columnStyles}>
                        <Paper style={columnPaperStyles}>
                            <Grid container style={listTitleStyle}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" style={listTitleTextStyle}>
                                        Incomplete
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Totals
                                        loading={incompleteBulksLoading}
                                        theme={theme}
                                        total={incompleteBulks.length}
                                        visibleTotal={mappedIncompleteBulks.length}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: '100%', paddingBottom: theme.spacing.unit }}>
                                <Divider />
                                {incompleteBulksLoading && <LinearProgress />}
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height - 1}
                                            itemCount={mappedIncompleteBulks.length}
                                            itemData={incompleteBulksItemData}
                                            itemSize={82}
                                            width={width}
                                        >
                                            {BulkCard}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={columnStyles}>
                        <Paper style={columnPaperStyles}>
                            <Grid container style={listTitleStyle}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" style={listTitleTextStyle}>
                                        Completed
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Totals
                                        isComplete
                                        loading={completedBulksLoading}
                                        theme={theme}
                                        total={completedBulks.length}
                                        visibleTotal={mappedCompletedBulks.length}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: '100%', paddingBottom: theme.spacing.unit }}>
                                <Divider />
                                {completedBulksLoading && <LinearProgress />}
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height - 1}
                                            itemCount={mappedCompletedBulks.length}
                                            itemData={completeBulksItemData}
                                            itemSize={82}
                                            width={width}
                                        >
                                            {BulkCard}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid
                    container
                    spacing={theme.spacing.unit * 2}
                    style={{
                        display: 'flex',
                        height: '100%',
                        paddingBottom: 'env(safe-area-inset-bottom)px'
                    }}
                >
                    <Grid item xs={12} style={columnStyles}>
                        <Paper style={columnPaperStyles}>
                            <Grid container style={listTitleStyle}>
                                <Grid item xs={3}>
                                    <Typography variant="h6" style={listTitleTextStyle}>
                                        {STEP_TITLES[currentTab]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Totals
                                        operator={operator}
                                        isComplete={currentTab === 3}
                                        loading={bulksLoading}
                                        theme={theme}
                                        total={mobileSelectedBulkListSize}
                                        visibleTotal={mobileSelectedMappedBulkListSize}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: '100%', paddingBottom: theme.spacing.unit }}>
                                <Divider />
                                {completedBulksLoading && <LinearProgress />}
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height - 1}
                                            itemCount={mobileSelectedMappedBulkListSize}
                                            itemData={mobileSelectedBulkListData}
                                            itemSize={82}
                                            width={width}
                                        >
                                            {BulkCard}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <AppBar
                    position="fixed"
                    color="default"
                    elevation={0}
                    style={{
                        top: 'auto',
                        bottom: 0,
                        backgroundColor: theme.palette.background.paper,
                        paddingBottom: 'env(safe-area-inset-bottom)'
                    }}
                >
                    <Toolbar style={{ padding: 0 }}>
                        <Grid
                            container
                            style={{
                                borderTop: `2px solid ${theme.palette.envColor[500]}`
                            }}
                        >
                            <Grid item xs={6} style={{ padding: theme.spacing.unit }}>
                                <Button
                                    data-cy="bulk-counter-count-btn"
                                    color="default"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        handleOpenSearchDialog();
                                        resetTimeoutCount();
                                    }}
                                >
                                    Enter Counts
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                style={{ paddingTop: theme.spacing.unit, paddingBottom: theme.spacing.unit }}
                            >
                                <Button
                                    data-cy="bulk-counter-create-btn"
                                    color="default"
                                    fullWidth
                                    //disabled={operator.accountType !== 'System Administrator'}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        handleOpenCreateBulkDialog(skuTypeFilter);
                                        resetTimeoutCount();
                                    }}
                                >
                                    Create
                                </Button>
                            </Grid>
                            <Grid item xs={3} style={{ padding: theme.spacing.unit }}>
                                <Button
                                    data-cy="bulk-counter-filters-btn"
                                    color="default"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setShowFilters(!showFilters);
                                        resetTimeoutCount();
                                    }}
                                >
                                    <MDIcon path={mdiFilterMenuOutline} size={0.9} color={theme.palette.text.primary} />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                                <ExpansionPanel
                                    expanded={showFilters}
                                    style={{
                                        padding: 0,
                                        paddingLeft: theme.spacing.unit,
                                        paddingRight: theme.spacing.unit
                                    }}
                                    square={true}
                                    elevation={0}
                                >
                                    <TextField
                                        data-cy="bulk-counter-filter-input"
                                        value={globalBulkFilter}
                                        type="search"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <Icon style={{ marginRight: theme.spacing.unit }}>search</Icon>
                                            )
                                        }}
                                        style={{ marginBottom: theme.spacing.unit * 2 }}
                                        variant="outlined"
                                        label="Order search"
                                        onChange={e => {
                                            handleGlobalBulkSearchChange(e);
                                            resetTimeoutCount();
                                        }}
                                    />
                                    <BulkSkuTypeSelectionDropdownMulti
                                        commodityColors={allCommodityColors}
                                        skus={skus}
                                        helperText="Commodities"
                                        labelCustomWidth={120}
                                        style={{ marginBottom: theme.spacing.unit, marginRight: theme.spacing.unit }}
                                        typeFilter={skuTypeFilterMulti}
                                        handleChange={e => {
                                            handleSkuTypeFilterChangeMulti(e);
                                            resetTimeoutCount();
                                        }}
                                    />
                                    <BulkTypeSelectionDropdown
                                        skus={skus}
                                        disablePickupsType={!pickupsEnabled}
                                        helperText="Order Type"
                                        typeFilter={bulkTypeFilter}
                                        labelCustomWidth={80}
                                        style={{ marginBottom: theme.spacing.unit }}
                                        handleChange={e => {
                                            handleBulkTypeFilterChange(e);
                                            resetTimeoutCount();
                                        }}
                                    />
                                    <BulkCollectorSelectionDropdown
                                        skus={skus}
                                        gutterTop
                                        loading={bulksLoading}
                                        collectors={collectors}
                                        bulkCollectorFilter={bulkCollectorFilter}
                                        helperText="Transporter"
                                        labelCustomWidth={65}
                                        handleChange={e => {
                                            handleBulkCollectorFilterChange(e);
                                            resetTimeoutCount();
                                        }}
                                    />
                                    <BulkCollectorSelectionDropdown
                                        skus={skus}
                                        gutterTop
                                        loading={bulksLoading}
                                        collectors={collectors}
                                        bulkCollectorFilter={bulkProcessorFilter}
                                        helperText="Processor"
                                        labelCustomWidth={70}
                                        handleChange={e => {
                                            handleBulkProcessorFilterChange(e);
                                            resetTimeoutCount();
                                        }}
                                    />
                                    {/* <BulkReceiverSelectionDropdown
                                        skus={skus}
                                        gutterTop
                                        loading={bulksLoading}
                                        receivers={filteredReceivers}
                                        bulkReceiverFilter={bulkReceiverFilter}
                                        helperText="# Incomplete - Driver"
                                        labelCustomWidth={145}
                                        handleChange={e => {
                                            handleBulkReceiverFilterChange(e);
                                            resetTimeoutCount();
                                        }}
                                    /> */}
                                    <BulkTripSelectionDropdown
                                        skus={skus}
                                        loading={bulksLoading}
                                        trips={tripsWithIncompletePickups}
                                        bulkTripFilter={bulkTripFilter}
                                        helperText="# Incomplete - Trip"
                                        labelCustomWidth={130}
                                        handleChange={e => {
                                            handleBulkTripFilterChange(e);
                                            resetTimeoutCount();
                                        }}
                                        style={{ margin: theme.spacing.unit, marginLeft: 0 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                data-cy="bulk-counter-show-mine-checkbox"
                                                checked={selfishMode}
                                                onChange={() => {
                                                    handleToggleSelfishMode();
                                                    resetTimeoutCount();
                                                }}
                                                // value={reason.short}
                                                style={{
                                                    paddingTop: theme.spacing.unit / 2,
                                                    paddingBottom: theme.spacing.unit / 2,
                                                    marginLeft: theme.spacing.unit
                                                }}
                                            />
                                        }
                                        label="Only show mine"
                                    />
                                </ExpansionPanel>
                            </Grid>
                            <Grid item xs={12}>
                                <Tabs
                                    value={currentTab}
                                    onChange={(e, v) => {
                                        setCurrentTab(v);
                                        resetTimeoutCount();
                                    }}
                                    indicatorColor="primary"
                                    variant="fullWidth"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab
                                        data-cy="bulk-counter-incomplete-tab"
                                        label={
                                            <CustomMUIBadge badgeContent={mappedIncompleteBulks.length}>
                                                <div style={tabStyle}>
                                                    <MDIcon
                                                        path={mdiFormatListChecks}
                                                        size={0.7}
                                                        color={
                                                            currentTab === 0
                                                                ? theme.palette.primary.main
                                                                : theme.palette.text.secondary
                                                        }
                                                    />
                                                    <Typography variant="caption" color="inherit">
                                                        Incomplete
                                                    </Typography>
                                                </div>
                                            </CustomMUIBadge>
                                        }
                                    />
                                    <Tab
                                        data-cy="bulk-counter-overdue-tab"
                                        label={
                                            <CustomMUIBadge badgeContent={mappedOverdueBulks.length}>
                                                <div style={tabStyle}>
                                                    <MDIcon
                                                        path={mdiClockAlert}
                                                        size={0.7}
                                                        color={
                                                            currentTab === 1
                                                                ? theme.palette.primary.main
                                                                : theme.palette.text.secondary
                                                        }
                                                    />
                                                    <Typography variant="caption" color="inherit">
                                                        Overdue
                                                    </Typography>
                                                </div>
                                            </CustomMUIBadge>
                                        }
                                    />
                                    <Tab
                                        data-cy="bulk-counter-complaints-tab"
                                        label={
                                            <CustomMUIBadge badgeContent={mappedWhinyBulks.length}>
                                                <div style={tabStyle}>
                                                    <MDIcon
                                                        path={mdiAccountAlert}
                                                        size={0.7}
                                                        color={
                                                            currentTab === 2
                                                                ? theme.palette.primary.main
                                                                : theme.palette.text.secondary
                                                        }
                                                    />
                                                    <Typography variant="caption" color="inherit">
                                                        Issues & Approvals
                                                    </Typography>
                                                </div>
                                            </CustomMUIBadge>
                                        }
                                    />
                                    <Tab
                                        data-cy="bulk-counter-completed-tab"
                                        label={
                                            <CustomMUIBadge badgeContent={mappedCompletedBulks.length}>
                                                <div style={tabStyle}>
                                                    <MDIcon
                                                        path={mdiCheckCircle}
                                                        size={0.7}
                                                        color={
                                                            currentTab === 3
                                                                ? theme.palette.primary.main
                                                                : theme.palette.text.secondary
                                                        }
                                                    />
                                                    <Typography variant="caption" color="inherit">
                                                        Completed
                                                    </Typography>
                                                </div>
                                            </CustomMUIBadge>
                                        }
                                    />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <BulkSearchDialog
                open={bulkSearchDialogOpen}
                skus={skus}
                inProgress={inProgress}
                searchQuery={bulkSearchDialogQuery}
                error={bulkSearchDialogError}
                continuousCountingEnabled={continuousCountingEnabled}
                skuType={bulkSearchSkuType}
                //commoditiesProcessed={commoditiesProcessed}
                commodityColors={allCommodityColors}
                onSnackbar={onSnackbar}
                onClose={handleCloseSearchDialog}
                onToggleContinuousCounting={handleToggleContinuousCounting}
                onQueryChange={handleBulkSearchQueryChange}
                onBulkCounterDialog={handleOpenCreateBulkDialog}
                onSkuTypeChangeSearch={handleSearchSkuTypeChange}
                onSearchForBulkToCount={handleSearchForBulkToCount}
                resetTimeoutCount={resetTimeoutCount}
                handleBulkLookUp={handleBulkLookUp}
                bulkLookUpLoading={bulkLookUpLoading}
                printedLabelSearchLoading={printedLabelSearchLoading}
                /*onUpdateCommoditiesProcessed={handleUpdateCommoditiesProcessed}
                onIncrementCommoditiedProcessed={handleIncrementCommoditiesProcessed}
                onDecrementCommoditiedProcessed={handleDecrementCommoditiesProcessed}*/
            >
                <Tabs
                    value={searchResultTab}
                    onChange={(event, value) => {
                        setSearchResultTab(value);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    style={{ marginBottom: 5, width: '100%' }}
                >
                    <Tab
                        label={
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Typography>Orders</Typography>
                                <Badge
                                    badgeContent={bulkLookUpResult.length}
                                    color="primary"
                                    style={{
                                        right: -10
                                    }}
                                />
                            </div>
                        }
                    />
                    <Tab
                        label={
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Typography>Printed On</Typography>
                                <Badge
                                    badgeContent={numberOfPrintedLabelFound}
                                    color="primary"
                                    style={{
                                        right: -10
                                    }}
                                />
                            </div>
                        }
                    />
                </Tabs>

                {searchResultTab === 0 &&
                    (bulkLookUpResultIsEmpty ? (
                        <Typography variant="subtitle2" style={{ textAlign: 'center', width: '100%', margin: 8 }}>
                            No matching orders found
                        </Typography>
                    ) : bulkLookUpLoading ? (
                        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                            <CircularProgress style={{ margin: 8 }} />
                        </div>
                    ) : (
                        bulkLookUpResult.length > 0 && (
                            <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ height: Math.min(340, bulkLookUpResult.length * 82 + 28) }}
                            >
                                <Paper style={columnPaperStyles}>
                                    <div style={{ height: '100%', paddingBottom: theme.spacing.unit }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    height={height - 1}
                                                    itemCount={bulkLookUpResult.length}
                                                    itemData={bulkLookUpResultItemData}
                                                    itemSize={84}
                                                    width={width}
                                                >
                                                    {SearchBulkCard}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    ))}
                {searchResultTab === 1 &&
                    (printedLabelSearchLoading ? (
                        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                            <CircularProgress style={{ margin: 8 }} />
                        </div>
                    ) : printedLabelSearchResultIsEmpty ? (
                        <Typography variant="subtitle2" style={{ textAlign: 'center', width: '100%', margin: 8 }}>
                            No matching labels found
                        </Typography>
                    ) : (
                        numberOfPrintedLabelFound > 0 && (
                            <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ height: Math.min(340, numberOfPrintedLabelFound * 40 + 28) }}
                            >
                                <Paper style={columnPaperStyles}>
                                    <div style={{ height: '100%', paddingBottom: theme.spacing.unit }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    height={height - 1}
                                                    itemCount={numberOfPrintedLabelFound}
                                                    itemData={printedLabelSearchResultItemData}
                                                    itemSize={40}
                                                    width={width}
                                                >
                                                    {SearchLabelCard}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    ))}
            </BulkSearchDialog>

            <BulkCreateDialog
                open={bulkCreateDialogOpen}
                searchingForCustomer={searchingForCustomer}
                skus={skus}
                numberOfBags={numberOfBags}
                disableChangingBulkType={disableChangingBulkType}
                skuType={bulkCreateSkuType}
                bulkType={bulkCreateBulkType}
                inProgress={inProgress}
                customerID={customerID}
                error={bulkCreateDialogError}
                continuousCountingEnabled={continuousCountingEnabled}
                commodityColors={allCommodityColors}
                allCharities={allCharities}
                donateQuickdrop={donateQuickdrop}
                donate={donate}
                charityPreferred={charityPreferred}
                subdivisionPreferred={subdivisionPreferred}
                inhouseDisabled={inhouseDisabled}
                adjustmentsDisabled={adjustmentsDisabled}
                walkinDisabled={walkinDisabled}
                onSnackbar={onSnackbar}
                onPayload={handlePayload}
                onClose={handleCloseCreateBulkDialog}
                onCreateBulk={handleCreateBulk}
                onSkuTypeChange={handleCreateSkuTypeChange}
                onBulkTypeChange={handleCreateBulkTypeChange}
                onCustomerIDChange={handleBulkCreateCustomerIDChange}
                onDonateQuickdrop={handleSetDonateQuickdrop}
                onCharityPreferred={handleCharityPreferred}
                onChange={handleSubdivisionPreferred}
                onSetDonation={handleSetDonation}
                lostAndFoundBulks={lostAndFoundBulks}
                lostAndFoundBulksLoading={lostAndFoundBulksLoading}
                onViewLostAndFound={() => {
                    if (!_.isEmpty(lostAndFoundBulks)) {
                        history.push(`/operators/${operator._id}/bulks/${lostAndFoundBulks[0]._id}`);
                    }
                }}
                lostAndFoundBulkType={lostAndFoundBulkType}
                handleLAFBulkTypeChange={handleLAFBulkTypeChange}
                handleOpenCreateBulkDialog={handleOpenCreateBulkDialog}
                skuTypeFilter={skuTypeFilter}
                growthPlanEnabled={growthPlanEnabled}
                collectorForBulk={bulkCreateCollector}
                onCollectorChange={handleBulkCreateCollectorChange}
                collectors={collectors}
                commoditiesByCollector={commoditiesByCollector}
                operator={operator}
            />
        </div>
    );
}

export default withTheme()(BulkCounter);

function Totals({ total, visibleTotal, operator, isComplete = false, loading = false }) {
    let text = '';
    if (total < 1) {
        text = 'Empty';
    } else if (total !== visibleTotal) {
        text = `Showing ${visibleTotal} of ${total}`;
    } else {
        text = `${total} Total`;
    }

    return (
        <Typography variant="subtitle2" style={{ textAlign: 'right', marginTop: 8 }}>
            {!loading ? (!isComplete ? text : null) : 'Loading...'}{' '}
            {!loading && isComplete && `Showing last ${_.get(operator, 'config.bulkCounter.hourWindow', 48)} hours`}
        </Typography>
    );
}

const createItemData = (
    theme,
    items,
    skus,
    commodityColors,
    operator,
    history,
    resetTimeoutCount,
    commodities,
    bagtagLocationMap,
    bulkSearchDialogQuery
) => ({
    theme,
    items,
    skus,
    commodityColors,
    operator,
    history,
    resetTimeoutCount,
    commodities,
    bagtagLocationMap,
    bulkSearchDialogQuery
});

// holds all the logic for the filters on the bulks
function filterFunction({
    bulk,
    filterString,
    skuType,
    bulkType,
    receiver,
    trip,
    collector,
    processor,
    selfishMode = false,
    creator,
    accessibleSkus
}) {
    if (!skuType.includes(bulk.skuType)) {
        return false;
    }

    if (bulkType !== 'all' && bulk.bulkType !== bulkType) {
        return false;
    }

    if (receiver !== 'all' && _.get(bulk, 'receiver._id', null) !== receiver) {
        return false;
    }

    if (collector !== 'all' && _.get(bulk, 'receiver.collector._id', null) !== collector) {
        return false;
    }

    if (processor !== 'all' && _.get(bulk, 'collector._id', null) !== processor) {
        return false;
    }

    if (
        trip !== 'all' &&
        _.get(bulk, 'pickup.trip._id', null) !== trip &&
        !(trip === 'undefined' && _.isNil(bulk.pickup))
    ) {
        return false;
    }

    if (selfishMode) {
        let counterIds = [];
        if (bulk.counts) {
            let isRogue = _.get(bulk, 'autoComplete', false); // Rogue will not have an operator

            if (!isRogue) {
                counterIds = bulk.counts.map(({ operator }) => _.get(operator, '_id', operator).toString()); // For admins, operators is an object (populated), for counters it is just the id
            }
        }

        if (creator !== bulk.creator && !counterIds.includes(creator.toString())) {
            return false;
        }
    }

    const filter = filterString.toLowerCase();
    // if the query matches the label, driver, customer ID, or address

    return (
        _bulk
            .getTruncatedLabel(bulk)
            .toLowerCase()
            .match(filter) ||
        _bulk
            .getDriverName(bulk)
            .toLowerCase()
            .match(filter) ||
        _.get(bulk, 'owner.uniqueID', '')
            .toLowerCase()
            .match(filter) ||
        _bulk
            .getPickupAddress(bulk)
            .toLowerCase()
            .match(filter)
    );
}
