import React, { useContext } from 'react';

import { containsErrors } from '../../../utils/validate';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { Typography } from '@material-ui/core';

const Cheque = ({ theme, redemptionForm, handleBack, handleNext, formErrors, redemptionType }) => {
    const { lang } = useContext(LocalizationContext);

    const textStyling = { marginTop: theme.spacing.unit * 2 };
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption30', lang)} iconName="account_balance_wallet" />
                <DialogContentText>{loc('redemption31', lang)}</DialogContentText>
                {redemptionType === 'charity' && (
                    <DialogContentText>
                        <b>
                            Note: All details pertain to the primary charity administrator. To make changes, please
                            update the administrator's account, not the charity itself.
                        </b>
                    </DialogContentText>
                )}

                <div style={{ marginLeft: theme.spacing.unit }}>
                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption32', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.accountName}</Typography>
                    </div>

                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption33', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.street}</Typography>
                    </div>

                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption34', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.city}</Typography>
                    </div>

                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption35', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.postalCode}</Typography>
                    </div>

                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption67', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.province}</Typography>
                    </div>

                    <div style={textStyling}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {loc('redemption36', lang)}
                        </Typography>
                        <Typography variant="body1">{redemptionForm.country}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={handleBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    disabled={containsErrors(formErrors)}
                    onClick={handleNext}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
};

export default Cheque;
