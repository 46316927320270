import React, { useContext, useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Typography,
    Tooltip,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    colors,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    withTheme,
    Divider,
    Paper,
    Chip
} from '@material-ui/core';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import ImageViewer from 'components/ImageUploads/ImageViewer';

import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { _pickup, _bulk, _commodity } from 'std';

import {
    isEXPRegion,
    getHomeMarkerPayloadString,
    getCustomerName,
    getPlural,
    truncateBagtag,
    uppercaseFirstLetter
} from '../../../utils/misc';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { openWindow } from 'helpers/windowHelper';
import { removePostalCodeAndCountryFromAddress } from 'helpers/locationHelper';

import { mdiTagArrowDown } from '@mdi/js';

function DropOffSummary(props) {
    const {
        bulks,
        customer,
        adminView,
        commoditiesAvailable,
        handleViewCustomerReport,
        history,
        auth,
        customerHistories,
        theme,
        address
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imagesToView, setImagesToView] = useState([]);

    const payloadIssues = _.get(bulks, '[0].payloadIssues.issues', []);
    const payloadIssuesImages = _.get(bulks, '[0].payloadIssues.images', []);
    let imageUrls = _.get(bulks, '[0].quickdropConfirmationImages', []);

    //used for messaging the collector as an admin
    const beverageBulks = _.filter(bulks, bulk => bulk.skuType === 'beverage');
    const firstBulk = _.first(beverageBulks) || _.first(bulks);
    const timezone = _.get(firstBulk, 'collector.timzone', process.env.REACT_APP_REGION_TIMEZONE);
    const sessions = _.get(firstBulk, 'countingSessions', []);
    const expectedBagtags = _.get(firstBulk, 'expectedBagtags', []);
    const isExpLegacyImport = !_.isNil(_.get(firstBulk, 'expressLegacyImportDate', null));

    const labelsForBulk = useMemo(() => {
        const usedLabels = [];
        customerHistories
            .filter(historyItem => {
                return _.get(historyItem, 'type') === 'INHOUSE';
            })
            .forEach(historyItem => {
                const data = _.get(historyItem, 'data', {});
                const labels = !_.isNil(_.get(data, 'scannedLabels'))
                    ? _.get(data, 'scannedLabels', [])
                    : _.get(data, 'printedLabels', []);

                labels.forEach(label => {
                    const countSessionHasLabel = _.some(sessions, session => _.get(session, 'bagtag') === label);
                    if (countSessionHasLabel) {
                        usedLabels.push({ label: label, datePrinted: historyItem.createdAt, processed: true });
                    } else {
                        const expectedBagtagsHasLabel = _.get(firstBulk, 'expectedBagtags', []).includes(label);
                        if (expectedBagtagsHasLabel) {
                            usedLabels.push({ label: label, datePrinted: historyItem.createdAt, processed: false });
                        }
                    }
                });
            });

        return usedLabels;
    }, [customerHistories, firstBulk, sessions]);

    const allBagTags = bulks.flatMap(b =>
        isEXPRegion() && !_.isNil(b.expectedBagtags) && !_.isEmpty(b.expectedBagtags)
            ? b.expectedBagtags.map(bt => ({ bagtag: bt, dateCounted: b.dateCounted }))
            : { bagtag: b.label, dateCounted: b.dateCounted }
    );

    const dropLocationName = _bulk.getDropLocationName(firstBulk);
    const collectorName = _.get(firstBulk, 'collector.name');
    const displayedName = !_.isEmpty(dropLocationName) ? dropLocationName : collectorName;

    //const hasReportingIssues = !_.isNil(payloadIssues) && !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);

    let promosApplied = [];

    bulks.forEach(b => {
        if (!_.isEmpty(b.promosApplied)) {
            promosApplied = promosApplied.concat(b.promosApplied);
        }
    });
    const payload = bulks.reduce((payload, bulk) => {
        let expectedAmount = _.get(bulk, 'commodityAmount') || 0;
        let processedAmount = _.get(bulk, 'commoditiesProcessed') || 0;

        let displayAmount = Math.max(expectedAmount, processedAmount);
        if (_bulk.isCompleted(bulk)) {
            displayAmount = processedAmount;
        }

        _.set(payload, bulk.skuType, displayAmount);
        return payload;
    }, {});
    const allSubPayloads = bulks.reduce((subPayloads, bulk) => {
        const breakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
        const bulkSubPayloads = _.filter(breakdown, { isSubCommodity: true }).map(entry => {
            return {
                amount: _.get(entry, 'amount', 0),
                subCommodity: _.get(entry, 'subCommodity'),
                skuType: _.get(entry, 'skuType')
            };
        });
        return [...subPayloads, ...bulkSubPayloads];
    }, []);

    /*const commoditiesForStats = useMemo(() => _.filter(commoditiesAvailable, { includeContainersInStats: true }), [
        commoditiesAvailable
    ]);
    const commoditiesNotForStats = useMemo(
        () => _.filter(commoditiesAvailable, c => !_.get(c, 'includeContainersInStats')),
        [commoditiesAvailable]
    );

    const commoditiesNotForStatsHasPayload = useMemo(() => {
        return _.some(commoditiesNotForStats, c => _.get(payload, _commodity.getSkuType(c)) > 0);
    }, [commoditiesNotForStats, payload]);*/
    //const payload = _.get(pickup, 'payload', {});
    /*const payloadCommodities = Object.keys(payload)
        //.filter(skuType => _.get(payload, skuType, 0) > 0)
        .map(skuType => _.find(commoditiesAvailable, { skuType }))
        .filter(commodity => !_.isNil(commodity));*/

    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const handleViewImages = images => {
        setImageDialogOpen(true);
        setImagesToView(images);
    };

    const payloadStr = useMemo(() => {
        return commoditiesAvailable
            .map(commodity => {
                const payloadForCommodity = {};
                payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                    payload,
                    _commodity.getSkuType(commodity),
                    0
                );
                const subPayloadsForCommodity = _.filter(allSubPayloads, {
                    skuType: _commodity.getSkuType(commodity)
                });

                const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                    [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                    commoditiesAvailable
                );
                const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);
                if (_.isEmpty(groupedPayloadTotals)) {
                    return '';
                } else {
                    let payloadStr = '';
                    Object.keys(groupedPayloadTotals).forEach((unit, i) => {
                        payloadStr += `${groupedPayloadTotals[unit]} ${
                            groupedPayloadTotals[unit] > 1
                                ? getPlural(_commodity.getUnit(commodity, lang))
                                : _commodity.getUnit(commodity, lang)
                        }`;
                    });
                    return (
                        payloadStr + ` ${loc('of', lang)} ${_commodity.getPayloadInputName(commodity).toLowerCase()}`
                    );
                }
            })
            .filter(val => !_.isEmpty(val))
            .join(', ');
    }, [commoditiesAvailable, payload, allSubPayloads, lang]);

    return (
        <>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2, fontSize: '1rem' }}>
                        {loc('dashboardHistory62', lang)}
                    </Typography>
                    {/* {!_.isEmpty(imageUrls) && (
                        <Tooltip title={loc('tooltip2', lang)}>
                            <Button
                                size="small"
                                variant="outlined"
                                style={{
                                    padding: theme.spacing.unit / 2,
                                    margin: theme.spacing.unit / 2,
                                    minWidth: 32
                                }}
                                onClick={() => handleViewImages(imageUrls)}
                            >
                                <Icon fontSize="small">photo</Icon>
                            </Button>
                        </Tooltip>
                    )} */}
                </div>
                <Typography color="textSecondary">
                    {(expectedBagtags.length === 0 || isExpLegacyImport) && (
                        <span>{loc('youDroppedOffRogue', lang) + ' '}</span>
                    )}
                    {expectedBagtags.length > 0 && !isExpLegacyImport && (
                        <span>
                            {loc('on', lang)}{' '}
                            <b>
                                {loc('dateAtTime', lang, {
                                    date: locDate(_.get(firstBulk, 'createdAt'), 'MMM Do, YYYY', lang, timezone),
                                    time: locDate(_.get(firstBulk, 'createdAt'), 'h:mm a', lang, timezone)
                                })}
                            </b>{' '}
                            {loc('youDroppedOff', lang) + ' '}
                        </span>
                    )}
                    {payloadStr} {displayedName ? `${loc('at', lang)} ${displayedName}.` : '.'}{' '}
                    {!_.isEmpty(imageUrls) && (
                        <>
                            <span>
                                <span
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={() => handleViewImages(imageUrls)}
                                >
                                    {loc('tooltip2', lang)}
                                </span>
                            </span>
                        </>
                    )}
                </Typography>

                {/*displayedName && (
                    <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
                        {displayedName}
                    </Typography>
                )}
                {address && (
                    <Typography color="textSecondary">{removePostalCodeAndCountryFromAddress(address)}</Typography>
                )}
                <Typography color="textSecondary">
                    {locDate(firstBulk.createdAt, 'MMMM D, YYYY hh:mm A', lang)}
                </Typography>

                <Divider style={{ marginTop: 5, marginBottom: 5 }} />

                {commoditiesForStats.map(commodity => {
                    const commodityIcon = _.get(commodity, 'iconName');

                    const payloadForCommodity = {};
                    payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                        payload,
                        _commodity.getSkuType(commodity),
                        0
                    );
                    const subPayloadsForCommodity = _.filter(allSubPayloads, {
                        skuType: _commodity.getSkuType(commodity)
                    });

                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                        [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                        commoditiesAvailable
                    );
                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

                    return (
                        !_.isEmpty(groupedPayloadTotals) && (
                            <div key={_.get(commodity, '_id')}>
                                <Typography color="textSecondary">
                                    {uppercaseFirstLetter(_commodity.getPayloadInputName(commodity, lang))}
                                </Typography>
                                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                    <TableBody>
                                        {Object.keys(groupedPayloadTotals).map((unit, i) => (
                                            <TableRow style={{ height: 20 }} key={i}>
                                                <TableCell style={tableCellStyles}>
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(allIcons, commodityIcon)}
                                                            size={0.6}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                    {uppercaseFirstLetter(
                                                        groupedPayloadTotals[unit] > 1
                                                            ? getPlural(_commodity.getUnit(commodity, lang))
                                                            : _commodity.getUnit(commodity, lang)
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        ...tableCellStyles,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {groupedPayloadTotals[unit]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )
                    );
                })}
                {commoditiesNotForStatsHasPayload && (
                    <div>
                        <Typography color="textSecondary">{loc('other', lang)}</Typography>
                        <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                            <TableBody>
                                {commoditiesNotForStats.map(commodity => {
                                    const commodityIcon = _.get(commodity, 'iconName');

                                    const payloadForCommodity = {};
                                    payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                                        payload,
                                        _commodity.getSkuType(commodity),
                                        0
                                    );
                                    const subPayloadsForCommodity = _.filter(_.get(allSubPayloads, 'subPayloads', []), {
                                        skuType: _commodity.getSkuType(commodity)
                                    });

                                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                        [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                                        commoditiesAvailable
                                    );
                                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(
                                        combinedPayloadItems
                                    );
                                    return Object.keys(groupedPayloadTotals).map((unit, i) => (
                                        <TableRow style={{ height: 20 }} key={i}>
                                            <TableCell style={tableCellStyles}>
                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                    <MDIcon
                                                        path={_.get(allIcons, commodityIcon)}
                                                        size={0.6}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                                {_commodity.getPayloadInputName(commodity)}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    ...tableCellStyles,
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {groupedPayloadTotals[unit]}
                                            </TableCell>
                                        </TableRow>
                                    ));
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )*/}
                {/*beverage > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory47', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {beverage}
                                </TableCell>
                            </TableRow>
                        )}
                        {clothing > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory48', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {clothing}
                                </TableCell>
                            </TableRow>
                        )}
                        {electronics > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory49', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {electronics}
                                </TableCell>
                            </TableRow>
                        )*/}

                {(adminView || customer.allowViewBagtags) && !_.isEmpty(labelsForBulk) && (
                    <>
                        <Divider
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                marginBottom: theme.spacing.unit,
                                borderTop: '2px dashed rgba(0, 0, 0, 0.3)'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div style={{ width: '100%' }}>
                                {adminView && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            marginBottom: 5
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MDIcon
                                                path={_.get(allIcons, 'mdiShield')}
                                                size={1}
                                                color={theme.palette.text.secondary}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                            />
                                            <Typography variant="h6" style={{ fontSize: '1rem' }}>
                                                {loc('adminOnly', lang)} {loc('details', lang)}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                {labelsForBulk.map((label, i) => (
                                    <div
                                        key={i}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MDIcon
                                                path={mdiTagArrowDown}
                                                size={0.8}
                                                color={theme.palette.text.secondary}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                            />
                                            <Typography color="textSecondary">
                                                <b>{truncateBagtag(label.label)}</b>{' '}
                                                {locDate(label.datePrinted, 'MMM D, YY h:mmA', lang)}
                                            </Typography>
                                        </div>
                                        {label.processed && (
                                            <span
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    marginLeft: theme.spacing.unit
                                                }}
                                            >
                                                <Icon style={{ color: colors.green[500] }}>check</Icon>
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </Paper>

            <Dialog open={imageDialogOpen} maxWidth="xs" fullWidth onClose={() => setImageDialogOpen(false)}>
                <DialogTitlePrimary closeButtonShown onClose={() => setImageDialogOpen(false)}>
                    {loc('history10', lang)}
                </DialogTitlePrimary>
                <DialogContent style={{ margin: theme.spacing.unit, padding: 0 }}>
                    <ImageViewer
                        displayImages={imagesToView}
                        disableDelete={true}
                        scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withTheme()(DropOffSummary);
