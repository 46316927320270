import React, { useContext, useState, useMemo } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import {
    Typography,
    Tooltip,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    withTheme,
    Divider,
    Button,
    Paper
} from '@material-ui/core';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { _pickup, _commodity, _bulk } from 'std';

import { formatAsCurrency, uppercaseFirstLetter, truncateBagtag } from 'utils/misc';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import HttpContext from 'utils/contexts/HttpContext';

import MessageCollectorDialog from 'components/InputComponents/MessageCollectorDialog';

import PDFPreviewer from 'components/PDFComponents/PDFPreviewer';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import AUSLogo from 'icons/depot-login-header-AUS-white.png';
import STDLogo from 'icons/depot-login-header-STD-white.png';
import CONLogo from 'icons/depot-login-header-CON-white.png';
import EXPLogo from 'icons/encorp_logo_white.png';
import MXDLogo from 'icons/depot-login-header-MXD.png';

const tableCellStyles1 = { width: '75%' };
const tableCellStyles2 = { width: '25%' };

function Reciept(props) {
    const {
        bulks,
        pickup,
        customer,
        allRates,
        adminView,
        commoditiesAvailable,
        date,
        employeeName,
        address,
        collector,
        taxGroups,
        style,
        theme,
        serviceEmail,
        servicePhone,
        receiptAddress,
        allowReceiptDownload
    } = props;

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);

    const [messageCollectorDialogOpen, setMessageCollectorDialogOpen] = useState(false);
    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
    const [pdfUriStr, setPdfUriStr] = useState('');
    const [receiptCounter, setReceiptCounter] = useState(1);

    const firstBulk = _.first(bulks);
    const showMessageCollector = _.get(customer, 'showMessageCollector', false);
    const depositTaxGroup = _.get(customer, 'depositTaxGroup', null);
    const displayDepositTax = _.get(customer, 'displayDepositTax', false);
    const displayNetTax = _.get(customer, 'displayNetTax', false);
    const serviceFeeDisplay = _.get(customer, 'serviceFeeDisplay', 'Hide');

    const bagtag = _.get(firstBulk, 'label', null);
    const receiptViewEnabled = _.get(customer, 'allowViewReceipt', false);

    let messageCollectorSubject = loc('dashboardHistory19', lang);
    if (!_.isEmpty(bagtag)) {
        messageCollectorSubject += `: ${loc('dashboardHistory20', lang)} ${truncateBagtag(bagtag)}`;
    }

    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const allTaxes = useMemo(() => {
        return _.flatten(_.map(taxGroups, taxGroup => _.get(taxGroup, 'taxes', [])));
    }, [taxGroups]);
    const nonShowAsDonatedBulks = _.filter(bulks, b => {
        let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
        return !_.get(commodityForBulk, 'showAsDonatedInHistory', false);
    });
    const showAsDonatedBulks = _.filter(bulks, b => {
        let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
        return _.get(commodityForBulk, 'showAsDonatedInHistory', false);
    });
    const applicableLedgerLines = useMemo(() => {
        return nonShowAsDonatedBulks.reduce((lines, bulk) => {
            return [...lines, ..._.get(bulk, 'ledger.lines', [])];
        }, []);
    }, [nonShowAsDonatedBulks]);

    const allDepositTaxAmounts = getDepositTaxAmounts(depositTaxGroup, applicableLedgerLines);
    const totalDepositTax = _.sumBy(allDepositTaxAmounts, 'amount');

    const allFeeTaxAmounts = getFeeTaxAmounts(applicableLedgerLines, allTaxes);
    const totalFeeTax = _.sumBy(allFeeTaxAmounts, 'amount');

    const netTax = totalDepositTax - totalFeeTax;

    const allCounts = useMemo(() => {
        return bulks.reduce((counts, bulk) => {
            return [...counts, ..._.get(bulk, 'counts', [])];
        }, []);
    }, [bulks]);

    let promosApplied = [];
    if (!_.isEmpty(bulks)) {
        bulks.forEach(b => {
            if (!_.isEmpty(b.promosApplied)) {
                promosApplied = promosApplied.concat(b.promosApplied);
            }
        });
    } else {
        promosApplied = _.get(pickup, 'promos', []);
    }

    const isCustomerPaymentReciept = _.isEmpty(allCounts);
    const customFeesCombined = useMemo(() => {
        return getRolledUpBulkCustomFeeLines(bulks || [], lang);
    }, [bulks, lang]);

    async function addReceipt(receiptNumber) {
        const bulkIds = bulks.map(b => b._id);
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/addReceipt', {
            date: orderDate,
            receiptNumber,
            customer: _.get(customer, '_id'),
            bulks: bulkIds
        });
        if (res.ok) {
            let newReceiptCounter = _.get(res, 'data.receiptCounter', null);
            if (!_.isNil(newReceiptCounter)) {
                setReceiptCounter(newReceiptCounter);
            }
        }
    }
    async function getUsableReceiptCounter() {
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/getUsableReceiptCounter', {
            date: orderDate,
            customer: _.get(customer, '_id')
        });
        if (res.ok) {
            let newReceiptCounter = _.get(res, 'data.receiptCounter', 1);
            setReceiptCounter(newReceiptCounter);
            return newReceiptCounter;
        }
        return null;
    }
    async function getExistingReceiptNumber() {
        const bulkIds = bulks.map(b => b._id);
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/getExistingReceiptNumber', {
            date: orderDate,
            customer: _.get(customer, '_id'),
            bulks: bulkIds
        });
        return _.get(res, 'data.receiptNumber', null);
    }

    const handleGeneratePDF = async (download = false) => {
        let newReceiptCounter = await getUsableReceiptCounter();
        let existingReceiptNumber = await getExistingReceiptNumber();
        if (_.isNil(newReceiptCounter)) {
            newReceiptCounter = receiptCounter;
        }
        let newReceiptNumber = _.isNil(existingReceiptNumber)
            ? `${moment(date).format('YYYYMMDD')}-${customer.uniqueID}-${newReceiptCounter}`
            : existingReceiptNumber;

        let doc = new jsPDF({ orientation: '0' });
        doc.setFont('helvetica', 'normal');

        if (process.env.REACT_APP_REGION_EXT === 'STD') {
            doc.addImage(STDLogo, 'png', 14, 17, 54, 7);
            doc.setFontSize(20);
            doc.text(`Receipt`, 160, 20);
        } else if (process.env.REACT_APP_REGION_EXT === 'AUS') {
            doc.addImage(AUSLogo, 'png', 20, 20, 42, 13);
            doc.setFontSize(20);
            doc.text(`Tax Invoice`, 160, 20);
        } else if (process.env.REACT_APP_REGION_EXT === 'CON') {
            doc.addImage(CONLogo, 'png', 15, 13, 52, 15);
            doc.setFontSize(20);
            doc.text(loc('receipt', lang), 160, 20);
        } else if (process.env.REACT_APP_REGION_EXT === 'EXP') {
            doc.addImage(EXPLogo, 'png', 20, 16, 42, 20);
            doc.setFontSize(20);
            doc.text(`Receipt`, 160, 20);
        } else if (process.env.REACT_APP_REGION_EXT === 'MXD') {
            doc.addImage(MXDLogo, 'png', 14, 17, 54, 7);
            doc.setFontSize(20);
            doc.text(`Tax Invoice`, 160, 20);
        }

        doc.setFontSize(16);
        doc.text(process.env.REACT_APP_BRAND_NAME, 70, 20);

        doc.setFontSize(10);
        if (!_.isNil(receiptAddress) && !_.isEmpty(receiptAddress)) {
            doc.text(receiptAddress, 70, 25);
        }

        doc.setFontSize(10);
        if (!_.isNil(servicePhone) && !_.isEmpty(servicePhone)) {
            doc.text(servicePhone, 70, 40);
        }

        if (!_.isNil(serviceEmail) && !_.isEmpty(serviceEmail)) {
            doc.text(serviceEmail, 70, 45);
        }

        doc.line(15, 50, 195, 50);

        doc.setFont('helvetica', 'normal');
        doc.text(`${customer.name.first} ${customer.name.last}`, 25, 65);
        doc.text(`${customer.location.description}`, 25, 70);
        doc.text(`${customer.location.city}, ${customer.location.province} ${customer.location.postalCode}`, 25, 75);

        doc.setFont('helvetica', 'bold');
        doc.text(loc('ReceiptNumber', lang), 135, 60);
        doc.setFont('helvetica', 'normal');
        doc.text(newReceiptNumber, 145, 65);
        doc.setFont('helvetica', 'bold');
        doc.text(loc('date', lang), 135, 70);
        doc.setFont('helvetica', 'normal');
        doc.text(`${moment(date).format('M/D/YYYY')}`, 145, 75);

        let columns = [[loc('description', lang), loc('Quantity', lang), loc('Unitprice', lang), loc('Amount', lang)]];
        let rows = [];

        nonShowAsDonatedBulks.forEach(bulk => {
            const counts = _.get(bulk, 'counts', []);
            const items = _.flatten(counts.map(count => count.items));
            const countsByRate = [];
            const bulkRates = _.find(allRates, { _id: _.get(bulk, 'rates') });
            const rates = _.get(bulkRates, 'rates', []);
            items.forEach(item => {
                const skuRate = _.find(rates, { sku: _.get(item, 'sku') });
                const rateValue = -_.get(skuRate, 'value', 0);

                let countsForRateEntry = _.find(countsByRate, { rate: rateValue });
                if (_.isNil(countsForRateEntry)) {
                    countsForRateEntry = { rate: rateValue, quantity: 0 };
                    countsByRate.push(countsForRateEntry);
                }
                countsForRateEntry.quantity += _.get(item, 'quantity', 0);
            });

            countsByRate.forEach(count => {
                rows.push([
                    `${loc('containers', lang)} (${formatAsCurrency(count.rate, lang)})`,
                    count.quantity,
                    formatAsCurrency(count.rate, lang),
                    formatAsCurrency(count.rate * count.quantity, lang)
                ]);
            });

            let despositsWithoutTax = 0;

            if (displayDepositTax) {
                const lines = _.get(bulk, 'ledger.lines', []);
                const depositTaxAmounts = displayDepositTax ? getDepositTaxAmounts(depositTaxGroup, lines) : [];
                const deposits = _.sumBy(countsByRate, countGroup => {
                    return countGroup.rate * countGroup.quantity;
                });
                const totalTaxAmount = _.sumBy(depositTaxAmounts, 'amount');
                despositsWithoutTax = deposits - totalTaxAmount;
                rows.push([
                    loc('deposits', lang),
                    1,
                    formatAsCurrency(despositsWithoutTax, lang),
                    formatAsCurrency(despositsWithoutTax, lang)
                ]);
                depositTaxAmounts.forEach(taxObj => {
                    rows.push([
                        loc('dashboardHistory63', lang, { taxName: taxObj.taxName }),
                        1,
                        formatAsCurrency(taxObj.amount, lang),
                        formatAsCurrency(taxObj.amount, lang)
                    ]);
                });
            }
        });

        let serviceFeeAmount = 0;
        applicableLedgerLines.forEach(line => {
            const isTaxLine = !_.isNil(
                _.find(allTaxes, tax => _.get(line, 'description', '').includes(_.get(tax, 'name')))
            );
            if (_.get(line, 'description', '').includes('Service Fee') && !isTaxLine) {
                serviceFeeAmount += line.amount;
            }
        });
        if (!isCustomerPaymentReciept) {
            if (serviceFeeDisplay === 'Show Fee') {
                rows.push([
                    loc('serviceFee', lang),
                    1,
                    formatAsCurrency(-serviceFeeAmount, lang),
                    formatAsCurrency(-serviceFeeAmount, lang)
                ]);
            } else if (serviceFeeDisplay === 'Show Fee + Discount') {
                let totalDiscount = 0;
                const promo = _.first(promosApplied);
                if (
                    !_.isNil(promo) &&
                    !(_bulk.isQuickDrop(firstBulk) || _bulk.isWalkIn(firstBulk)) //don't show discount for DROP&GO and WALKIN promocodes
                ) {
                    const skuTypesForPromo = _.get(promo, 'commodities', []).map(commodityId => {
                        const commodity = _.find(commoditiesAvailable, { _id: commodityId });
                        return _commodity.getSkuType(commodity);
                    });
                    const onlyApplicableForCertainSkus = !_.isEmpty(skuTypesForPromo) && !_.isNil(skuTypesForPromo);
                    bulks.forEach(bulk => {
                        if (onlyApplicableForCertainSkus && !skuTypesForPromo.includes(_bulk.getSkuType(bulk))) {
                            return;
                        }

                        let deposits = 0;
                        applicableLedgerLines.forEach(line => {
                            if (line.amount < 0) {
                                deposits += Math.abs(line.amount);
                            }
                        });
                        const collectorServiceFees = _.get(collector, 'configuration.serviceFees', []);
                        const serviceFeeForCommodity = _.find(collectorServiceFees, { skuType: bulk.skuType });

                        let serviceFeeCustomerSplit = 1;
                        const bulkRates = _.find(allRates, { _id: _.get(bulk, 'rates') });
                        const rates = _.get(bulkRates, 'rates', []);
                        if (!_.isNil(serviceFeeForCommodity)) {
                            if (_.get(serviceFeeForCommodity, 'type', 'Percent Fee') === 'Percent Fee') {
                                serviceFeeCustomerSplit = 1.0 - _.get(serviceFeeForCommodity, 'value', 1);
                            } else {
                                const rateTotal = -_.get(
                                    _.find(rates, { sku: _.get(serviceFeeForCommodity, 'sku') }),
                                    'value'
                                );
                                serviceFeeCustomerSplit =
                                    _.get(serviceFeeForCommodity, 'split.customer', 0) / rateTotal;
                            }
                        }

                        const discountRatio = _.get(promo, 'variables.customerSplit') - serviceFeeCustomerSplit;
                        totalDiscount += _.round(deposits * discountRatio);
                    });
                }

                rows.push([
                    loc('serviceFee', lang),
                    1,
                    formatAsCurrency(-(serviceFeeAmount + totalDiscount), lang),
                    formatAsCurrency(-(serviceFeeAmount + totalDiscount), lang)
                ]);
                if (totalDiscount !== 0) {
                    rows.push([
                        uppercaseFirstLetter(loc('discount', lang)),
                        1,
                        formatAsCurrency(totalDiscount, lang),
                        formatAsCurrency(totalDiscount, lang)
                    ]);
                }
            }
        }

        customFeesCombined.forEach(customFee => {
            rows.push([
                customFee.baseDescription || customFee.description,
                _.isFinite(customFee.baseRate) ? customFee.amount / customFee.baseRate : 1,
                _.isFinite(customFee.baseRate)
                    ? formatAsCurrency(-customFee.baseRate, lang)
                    : formatAsCurrency(-customFee.amount, lang),
                formatAsCurrency(-customFee.amount, lang)
            ]);
        });

        doc.autoTable({
            startY: 80,
            theme: 'grid',
            styles: {
                lineWidth: 0.25,
                lineColor: 'black'
            },
            headStyles: {
                fillColor: '#f5f5f5',
                textColor: 'black'
            },
            head: columns,
            body: rows
        });

        let totalsDisplay = [];
        const totalAmount = applicableLedgerLines.reduce((sum, line) => sum + -_.get(line, 'amount'), 0);
        const subTotalAmount = totalAmount - _.sumBy(allFeeTaxAmounts, taxObj => -taxObj.amount);
        if (
            !isCustomerPaymentReciept &&
            (serviceFeeDisplay === 'Show Fee' || serviceFeeDisplay === 'Show Fee + Discount')
        ) {
            totalsDisplay.push({ name: loc('subTotal', lang), value: formatAsCurrency(subTotalAmount, lang) });
            allFeeTaxAmounts.forEach(taxObj => {
                totalsDisplay.push({
                    name: loc('dashboardHistory70', lang, { taxName: taxObj.taxName }),
                    value: formatAsCurrency(-taxObj.amount, lang)
                });
            });
        }
        if (totalAmount >= 0) {
            totalsDisplay.push({
                name: loc(isCustomerPaymentReciept ? 'balancePaid' : 'totalRefund', lang),
                value: formatAsCurrency(totalAmount, lang)
            });
            doc.setFont('helvetica', 'bold');
            doc.text(loc('RefundTo', lang), 15, 60);
        } else {
            totalsDisplay.push({
                name: loc('TotalOwing', lang),
                value: formatAsCurrency(totalAmount, lang)
            });
            doc.setFont('helvetica', 'bold');
            doc.text(loc('Invoice', lang), 15, 60);
        }
        let netTaxDisplay = [];
        if (!isCustomerPaymentReciept && displayNetTax) {
            allDepositTaxAmounts.forEach(taxObj => {
                netTaxDisplay.push({
                    name: loc('dashboardHistory63', lang, { taxName: taxObj.taxName }),
                    value: formatAsCurrency(taxObj.amount, lang)
                });
            });
            allFeeTaxAmounts.forEach(taxObj => {
                netTaxDisplay.push({
                    name: loc('dashboardHistory64', lang, { taxName: taxObj.taxName }),
                    value: formatAsCurrency(-taxObj.amount, lang)
                });
            });
            netTaxDisplay.push({
                name: loc('netTax', lang),
                value: formatAsCurrency(netTax, lang)
            });
        }
        let totalsStartY = 85 + (rows.length + 1) * 8;
        doc.setDrawColor(0, 0, 0);
        totalsDisplay.forEach(row => {
            doc.setFont('helvetica', 'bold');
            doc.text(row.name, 110, totalsStartY);
            doc.setFont('helvetica', 'normal');
            doc.text(row.value, 165, totalsStartY);
            doc.line(110, totalsStartY + 2, 195, totalsStartY + 2);
            totalsStartY += 8;
        });
        if (!_.isEmpty(netTaxDisplay)) {
            totalsStartY += 2;
            doc.setFont('helvetica', 'bold');
            doc.text(loc('taxBreakdown', lang), 110, totalsStartY);
            totalsStartY += 10;
        }
        netTaxDisplay.forEach(row => {
            doc.setFont('helvetica', 'bold');
            doc.text(row.name, 110, totalsStartY);
            doc.setFont('helvetica', 'normal');
            doc.text(row.value, 165, totalsStartY);
            doc.line(110, totalsStartY + 2, 195, totalsStartY + 2);
            totalsStartY += 8;
        });

        if (!_.isEmpty(_.get(firstBulk, 'referenceNote', ''))) {
            doc.setFont('helvetica', 'normal');
            doc.text(
                `${loc('dashboardHistory75', lang, {
                    referenceNote: _.get(firstBulk, 'referenceNote', '')
                })}`,
                15,
                totalsStartY + 4
            );
        }

        if (download) {
            doc.save(`Receipt ${moment(date).format('M_D_YYYY')}.pdf`);
        } else {
            setPdfUriStr(doc.output('datauristring', `Receipt ${date}`));
            setPdfViewerOpen(true);
        }
        addReceipt(newReceiptNumber);
    };

    return (
        <>
            <PDFPreviewer
                title={'Receipt'}
                open={pdfViewerOpen}
                onClose={() => setPdfViewerOpen(false)}
                pdfUrl={pdfUriStr}
            />
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    marginBottom: 10,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default,
                    ...style
                }}
            >
                {adminView && !receiptViewEnabled ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '92%' }}>
                        <MDIcon
                            path={_.get(allIcons, 'mdiShield')}
                            size={1}
                            color={theme.palette.text.secondary}
                            style={{ margin: theme.spacing.unit / 2 }}
                        />
                        <Typography variant="h6" style={{ fontSize: '1rem' }}>
                            {loc('adminOnly', lang)}
                        </Typography>
                    </div>
                ) : (
                    <></>
                )}
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                    <Typography
                        style={{
                            marginRight: theme.spacing.unit * 2,
                            fontSize: '1rem',
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        variant="h6"
                    >
                        {loc(isCustomerPaymentReciept ? 'dashboardHistory65' : 'dashboardHistory31', lang)}{' '}
                        {!isCustomerPaymentReciept && receiptViewEnabled && !_.isNil(_.get(collector, '_id')) && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                        marginLeft: 10
                                    }}
                                >
                                    <div />
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {showMessageCollector && (
                                            <Tooltip title="Message Collector">
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    style={{
                                                        padding: theme.spacing.unit / 2,
                                                        margin: theme.spacing.unit / 2,
                                                        minWidth: 32
                                                    }}
                                                    onClick={() => setMessageCollectorDialogOpen(true)}
                                                >
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ color: theme.palette.text.secondary }}
                                                    >
                                                        email
                                                    </Icon>
                                                </Button>
                                            </Tooltip>
                                        )}

                                        {allowReceiptDownload && (
                                            <Tooltip title="Download Receipt">
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    style={{
                                                        padding: theme.spacing.unit / 2,
                                                        margin: theme.spacing.unit / 2,
                                                        minWidth: 32
                                                    }}
                                                    onClick={() => handleGeneratePDF(true)}
                                                >
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ color: theme.palette.text.secondary }}
                                                    >
                                                        download
                                                    </Icon>
                                                </Button>
                                            </Tooltip>
                                        )}

                                        {allowReceiptDownload && (
                                            <Tooltip title="Print Receipt">
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    style={{
                                                        padding: theme.spacing.unit / 2,
                                                        margin: theme.spacing.unit / 2,
                                                        minWidth: 32
                                                    }}
                                                    onClick={() => handleGeneratePDF(false)}
                                                >
                                                    <Icon
                                                        fontSize="small"
                                                        style={{ color: theme.palette.text.secondary }}
                                                    >
                                                        print
                                                    </Icon>
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </Typography>
                    <div />
                </div>
                <>
                    {!isCustomerPaymentReciept &&
                        nonShowAsDonatedBulks.map(bulk => {
                            return (
                                <BulkCountsTable
                                    key={bulk._id}
                                    bulk={bulk}
                                    commoditiesAvailable={commoditiesAvailable}
                                    depositTaxGroup={depositTaxGroup}
                                    displayDepositTax={displayDepositTax}
                                    taxGroups={taxGroups}
                                    theme={theme}
                                    tableCellStyles={tableCellStyles}
                                />
                            );
                        })}
                    {!isCustomerPaymentReciept && !_.isEmpty(showAsDonatedBulks) && (
                        <DonatedCommoditiesTable
                            bulks={showAsDonatedBulks}
                            depositTaxGroup={depositTaxGroup}
                            theme={theme}
                            tableCellStyles={tableCellStyles}
                        />
                    )}
                    <FeesTable
                        bulks={bulks}
                        allRates={allRates}
                        allTaxes={allTaxes}
                        ledgerLines={applicableLedgerLines}
                        promosApplied={promosApplied}
                        collector={collector}
                        serviceFeeDisplay={serviceFeeDisplay}
                        commoditiesAvailable={commoditiesAvailable}
                        isCustomerPaymentReciept={isCustomerPaymentReciept}
                        theme={theme}
                        tableCellStyles={tableCellStyles}
                    />
                    <Divider />
                    <SummaryTable
                        bulks={bulks}
                        ledgerLines={applicableLedgerLines}
                        allFeeTaxAmounts={allFeeTaxAmounts}
                        allDepositTaxAmounts={allDepositTaxAmounts}
                        netTax={netTax}
                        isCustomerPaymentReciept={isCustomerPaymentReciept}
                        displayNetTax={displayNetTax}
                        theme={theme}
                        tableCellStyles={tableCellStyles}
                    />
                </>
            </Paper>
            <MessageCollectorDialog
                open={messageCollectorDialogOpen}
                onClose={() => setMessageCollectorDialogOpen(false)}
                initialSubject={messageCollectorSubject}
                initialBody={loc('history11', lang)}
                customerInfo={{
                    customerUniqueID: _.get(customer, 'uniqueID', ''),
                    bagtag: truncateBagtag(bagtag),
                    bulkType: _.get(firstBulk, 'bulkType', ''),
                    date,
                    employeeName: employeeName,
                    collectorName: _.get(collector, 'collector.name', ''),
                    address: address,
                    payload: _pickup.getBeveragePayloadFormated(pickup),
                    aborted: _.get(pickup, 'aborted', ''),
                    pickupCompleted: _.get(pickup, 'completed', false)
                }}
                collectorId={_.get(collector, '_id')}
                http={http}
            />
        </>
    );
}

export default withTheme()(Reciept);

function BulkCountsTable(props) {
    const { bulk, commoditiesAvailable, allTaxes, depositTaxGroup, displayDepositTax, theme, tableCellStyles } = props;

    const { lang } = useContext(LocalizationContext);

    const countsGroupedByRateFromLedger = useMemo(() => {
        const ledgers = _.get(bulk, 'ledger.lines', []);
        const counts = _.get(bulk, 'counts', []);
        const items = _.flatten(counts.map(count => count.items));
        const countsByRate = [];
        items.forEach(item => {
            const skuLedgerLine = _.find(ledgers, { sku: _.get(item, 'sku') });
            const ledgerAmount = -_.get(skuLedgerLine, 'amount', 0);
            const ledgerQuant = _.get(skuLedgerLine, 'quantity', 1);
            const rateValue = ledgerAmount / ledgerQuant;

            let countsForRateEntry = _.find(countsByRate, { rate: rateValue });
            if (_.isNil(countsForRateEntry)) {
                countsForRateEntry = { rate: rateValue, quantity: 0 };
                countsByRate.push(countsForRateEntry);
            }
            countsForRateEntry.quantity += _.get(item, 'quantity', 0);
        });
        return countsByRate;
    }, [bulk]);

    const ledgerLines = _.get(bulk, 'ledger.lines', []);
    const taxAmounts = [];
    ledgerLines.forEach(line => {
        const taxForLine = _.find(allTaxes, tax => _.get(line, 'description', '').includes(_.get(tax, 'name')));
        if (!_.isNil(taxForLine)) {
            let taxAmountForTax = _.find(taxAmounts, { taxName: _.get(taxForLine, 'name') });
            if (_.isNil(taxAmountForTax)) {
                taxAmountForTax = { amount: 0, taxName: _.get(taxForLine, 'name') };
                taxAmounts.push(taxAmountForTax);
            }
            taxAmountForTax.amount += line.amount;
        }
    });

    let driverTip = _.get(bulk, 'tip.adjustedAmount');
    if (!_.isNil(driverTip)) driverTip *= 100;
    let commodityType = bulk.skuType;
    let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === bulk.skuType);
    if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
        commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, bulk.skuType);
    }
    const commodityIcon = _.get(commodityForBulk, 'iconName');

    const lines = _.get(bulk, 'ledger.lines', []);
    const depositTaxAmounts = displayDepositTax ? getDepositTaxAmounts(depositTaxGroup, lines) : [];
    let despositsWithoutTax = 0;
    if (displayDepositTax) {
        const deposits = _.sumBy(countsGroupedByRateFromLedger, countGroup => {
            return countGroup.rate * countGroup.quantity;
        });
        const totalTaxAmount = _.sumBy(depositTaxAmounts, 'amount');
        despositsWithoutTax = deposits - totalTaxAmount;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                <Typography color="textSecondary">{uppercaseFirstLetter(commodityType)}</Typography>
                {_.get(commodityForBulk, 'showAsDonatedInHistory', false) && (
                    <Typography color="textSecondary">{loc('dashboardHistory6', lang)}</Typography>
                )}
            </div>
            {!_.get(commodityForBulk, 'showAsDonatedInHistory', false) && (
                <>
                    <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                        <TableBody>
                            {countsGroupedByRateFromLedger.map((countGroup, i) => (
                                <TableRow key={i} style={{ height: 20 }}>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles1,
                                            paddingTop: 0,
                                            paddingBottom: 0
                                        }}
                                    >
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(allIcons, commodityIcon)}
                                                size={0.6}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {loc('containers', lang)} ({formatAsCurrency(countGroup.rate, lang)})
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles2,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontWeight: 'bold',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {formatAsCurrency(countGroup.rate * countGroup.quantity, lang)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Divider />
                    {displayDepositTax && (
                        <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                            <TableBody>
                                <TableRow style={{ height: 20 }}>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles1,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            textAlign: 'right'
                                        }}
                                    >
                                        <b>{loc('deposits', lang)}</b>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles2,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontWeight: 'bold',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {formatAsCurrency(despositsWithoutTax, lang)}
                                    </TableCell>
                                </TableRow>
                                {depositTaxAmounts.map(taxObj => (
                                    <TableRow style={{ height: 20 }} key={taxObj.taxName}>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                ...tableCellStyles1,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                textAlign: 'right'
                                            }}
                                        >
                                            <b>{loc('dashboardHistory63', lang, { taxName: taxObj.taxName })}</b>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                ...tableCellStyles2,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                fontWeight: 'bold',
                                                textAlign: 'right'
                                            }}
                                        >
                                            {formatAsCurrency(taxObj.amount, lang)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </>
            )}
        </div>
    );
}

function DonatedCommoditiesTable(props) {
    const { bulks, commoditiesAvailable, theme, tableCellStyles } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                <Typography color="textSecondary">{uppercaseFirstLetter(loc('other', lang))}</Typography>
            </div>

            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <TableBody>
                    {bulks.map((bulk, i) => {
                        let commodityType = bulk.skuType;
                        let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === bulk.skuType);
                        if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                            commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, bulk.skuType);
                        }
                        const commodityIcon = _.get(commodityForBulk, 'iconName');
                        return (
                            <TableRow key={i} style={{ height: 20 }}>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        tableCellStyles1,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(allIcons, commodityIcon)}
                                            size={0.6}
                                            color={theme.palette.text.secondary}
                                        />
                                    </span>
                                    {uppercaseFirstLetter(commodityType)}
                                </TableCell>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        tableCellStyles2,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        textAlign: 'right'
                                    }}
                                >
                                    <Typography color="textSecondary">{loc('dashboardHistory6', lang)}</Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

function FeesTable(props) {
    const {
        bulks,
        ledgerLines,
        allTaxes,
        isCustomerPaymentReciept,
        serviceFeeDisplay,
        collector,
        promosApplied,
        commoditiesAvailable,
        allRates,
        theme,
        tableCellStyles
    } = props;

    const { lang } = useContext(LocalizationContext);

    const collectorServiceFees = _.get(collector, 'configuration.serviceFees', []);

    const customFeesCombined = useMemo(() => {
        return getRolledUpBulkCustomFeeLines(bulks || [], lang);
    }, [bulks, lang]);

    let serviceFeeAmount = 0;
    ledgerLines.forEach(line => {
        const isTaxLine = !_.isNil(
            _.find(allTaxes, tax => _.get(line, 'description', '').includes(_.get(tax, 'name')))
        );
        if (
            (_.get(line, 'description', '').includes('Service Fee') ||
                collectorServiceFees.map(csf => _.get(csf, 'description')).includes(_.get(line, 'description', ''))) &&
            !isTaxLine
        ) {
            serviceFeeAmount += line.amount;
        }
    });

    if (
        _.isEmpty(customFeesCombined) &&
        ((serviceFeeAmount === 0 && serviceFeeDisplay !== 'Show Fee + Discount') || serviceFeeDisplay === 'Hide')
    ) {
        return <></>;
    }

    let serviceFeeDisplayComp;
    if (serviceFeeDisplay === 'Hide') {
        serviceFeeDisplayComp = <></>;
    } else if (serviceFeeDisplay === 'Show Fee') {
        serviceFeeDisplayComp = (
            <TableRow style={{ height: 20 }}>
                <TableCell
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles1,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                >
                    <span style={{ marginRight: theme.spacing.unit }}>
                        <MDIcon
                            path={_.get(allIcons, 'mdiCurrencyUsd')}
                            size={0.6}
                            color={theme.palette.text.secondary}
                        />
                    </span>
                    {loc('serviceFee', lang)}
                </TableCell>
                <TableCell
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles2,
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontWeight: 'bold',
                        textAlign: 'right'
                    }}
                >
                    {formatAsCurrency(-serviceFeeAmount, lang)}
                </TableCell>
            </TableRow>
        );
    } else if (serviceFeeDisplay === 'Show Fee + Discount') {
        let totalDiscount = 0;
        const promo = _.first(promosApplied);
        if (
            !_.isNil(promo) &&
            !(_bulk.isQuickDrop(_.first(bulks)) || _bulk.isWalkIn(_.first(bulks))) //don't show discount for DROP&GO and WALKIN promocodes
        ) {
            const skuTypesForPromo = _.get(promo, 'commodities', []).map(commodityId => {
                const commodity = _.find(commoditiesAvailable, { _id: commodityId });
                return _commodity.getSkuType(commodity);
            });
            const onlyApplicableForCertainSkus = !_.isEmpty(skuTypesForPromo) && !_.isNil(skuTypesForPromo);
            bulks.forEach(bulk => {
                if (onlyApplicableForCertainSkus && !skuTypesForPromo.includes(_bulk.getSkuType(bulk))) {
                    return;
                }

                const bulkRates = _.find(allRates, { _id: _.get(bulk, 'rates') });
                const rates = _.get(bulkRates, 'rates', []);

                let deposits = 0;
                ledgerLines.forEach(line => {
                    if (line.amount < 0) {
                        deposits += Math.abs(line.amount);
                    }
                });
                const serviceFeeForCommodity = _.find(collectorServiceFees, { skuType: bulk.skuType });

                let serviceFeeCustomerSplit = 1;
                if (!_.isNil(serviceFeeForCommodity)) {
                    if (_.get(serviceFeeForCommodity, 'type', 'Percent Fee') === 'Percent Fee') {
                        serviceFeeCustomerSplit = 1.0 - _.get(serviceFeeForCommodity, 'value', 1);
                    } else {
                        const rateTotal = -_.get(_.find(rates, { sku: _.get(serviceFeeForCommodity, 'sku') }), 'value');
                        serviceFeeCustomerSplit = _.get(serviceFeeForCommodity, 'split.customer', 0) / rateTotal;
                    }
                }

                const discountRatio = _.get(promo, 'variables.customerSplit') - serviceFeeCustomerSplit;
                totalDiscount += _.round(deposits * discountRatio);
            });
        }

        serviceFeeDisplayComp = (
            <>
                <TableRow style={{ height: 20 }}>
                    <TableCell
                        style={{
                            ...tableCellStyles,
                            ...tableCellStyles1,
                            paddingTop: 0,
                            paddingBottom: 0
                        }}
                    >
                        <span style={{ marginRight: theme.spacing.unit }}>
                            <MDIcon
                                path={_.get(allIcons, 'mdiCurrencyUsd')}
                                size={0.6}
                                color={theme.palette.text.secondary}
                            />
                        </span>
                        {loc('serviceFee', lang)}
                    </TableCell>
                    <TableCell
                        style={{
                            ...tableCellStyles,
                            ...tableCellStyles2,
                            paddingTop: 0,
                            paddingBottom: 0,
                            fontWeight: 'bold',
                            textAlign: 'right'
                        }}
                    >
                        {formatAsCurrency(-(serviceFeeAmount + totalDiscount), lang)}
                    </TableCell>
                </TableRow>
                {totalDiscount !== 0 && (
                    <TableRow style={{ height: 20 }}>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles1,
                                paddingTop: 0,
                                paddingBottom: 0
                            }}
                        >
                            <span style={{ marginRight: theme.spacing.unit }}>
                                <MDIcon
                                    path={_.get(allIcons, 'mdiSale')}
                                    size={0.6}
                                    color={theme.palette.text.secondary}
                                />
                            </span>
                            {uppercaseFirstLetter(loc('discount', lang))}
                        </TableCell>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles2,
                                paddingTop: 0,
                                paddingBottom: 0,
                                fontWeight: 'bold',
                                textAlign: 'right'
                            }}
                        >
                            {formatAsCurrency(totalDiscount, lang)}
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    } else {
        serviceFeeDisplayComp = <></>;
    }

    return (
        <>
            {!isCustomerPaymentReciept && (!_.isEmpty(customFeesCombined) || serviceFeeDisplay !== 'Hide') && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                    <Typography color="textSecondary">{loc('fees', lang)}</Typography>
                </div>
            )}
            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <TableBody>
                    {!isCustomerPaymentReciept && serviceFeeDisplayComp}
                    {customFeesCombined.map((customFee, index) => {
                        let feeAmount = _.get(customFee, 'amount', 0);
                        if (_.get(customFee, 'tax.type') === 'included') {
                            feeAmount = feeAmount / (1.0 + _.get(customFee, 'tax.value', 0)); //remove included tax from amount
                        }
                        return (
                            <TableRow key={index} style={{ height: 20 }}>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles1,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(allIcons, 'mdiCurrencyUsd')}
                                            size={0.6}
                                            color={theme.palette.text.secondary}
                                        />
                                    </span>
                                    {customFee.description}
                                </TableCell>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles2,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: theme.palette.text.secondary
                                        }}
                                    >
                                        {formatAsCurrency(-feeAmount, lang)}
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

function SummaryTable(props) {
    const {
        ledgerLines,
        isCustomerPaymentReciept,
        allFeeTaxAmounts,
        allDepositTaxAmounts,
        netTax,
        displayNetTax,
        theme,
        tableCellStyles
    } = props;

    const { lang } = useContext(LocalizationContext);

    const totalAmount = ledgerLines.reduce((sum, line) => sum + -_.get(line, 'amount'), 0);
    const subTotalAmount = totalAmount - _.sumBy(allFeeTaxAmounts, taxObj => -taxObj.amount);

    return (
        <>
            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <TableBody>
                    {!isCustomerPaymentReciept && !_.isEmpty(allFeeTaxAmounts) && (
                        <>
                            <TableRow style={{ height: 20 }}>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles1,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        textAlign: 'right'
                                    }}
                                >
                                    <b>{loc('subTotal', lang)}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles2,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    <b>{formatAsCurrency(subTotalAmount, lang)}</b>
                                </TableCell>
                            </TableRow>
                            {allFeeTaxAmounts.map(taxObj => (
                                <TableRow style={{ height: 20 }} key={taxObj.taxName}>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles1,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            textAlign: 'right'
                                        }}
                                    >
                                        <b>{loc('dashboardHistory70', lang, { taxName: taxObj.taxName })}</b>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles2,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontWeight: 'bold',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {formatAsCurrency(-taxObj.amount, lang)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    )}
                    <TableRow style={{ height: 20 }}>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles1,
                                paddingTop: 0,
                                paddingBottom: 0,
                                textAlign: 'right'
                            }}
                        >
                            <b>{loc(isCustomerPaymentReciept ? 'balancePaid' : 'totalRefund', lang)}</b>
                        </TableCell>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles2,
                                paddingTop: 0,
                                paddingBottom: 0,
                                fontWeight: 'bold',
                                textAlign: 'right'
                            }}
                        >
                            <b>{formatAsCurrency(totalAmount, lang)}</b>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {!isCustomerPaymentReciept && displayNetTax && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                        <Typography color="textSecondary">{loc('taxBreakdown', lang)}</Typography>
                    </div>
                    <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                        <TableBody>
                            {allDepositTaxAmounts.map(taxObj => (
                                <TableRow style={{ height: 20 }} key={taxObj.taxName}>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles1,
                                            paddingTop: 0,
                                            paddingBottom: 0
                                        }}
                                    >
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(allIcons, 'mdiCurrencyUsd')}
                                                size={0.6}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {loc('dashboardHistory63', lang, { taxName: taxObj.taxName })}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles2,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontWeight: 'bold',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {formatAsCurrency(taxObj.amount, lang)}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {allFeeTaxAmounts.map(taxObj => (
                                <TableRow style={{ height: 20 }} key={taxObj.taxName}>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles1,
                                            paddingTop: 0,
                                            paddingBottom: 0
                                        }}
                                    >
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(allIcons, 'mdiCurrencyUsd')}
                                                size={0.6}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {loc('dashboardHistory64', lang, { taxName: taxObj.taxName })}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            ...tableCellStyles,
                                            ...tableCellStyles2,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontWeight: 'bold',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {formatAsCurrency(-taxObj.amount, lang)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Divider />
                    <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                        <TableBody>
                            <TableRow style={{ height: 20 }}>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles1,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        textAlign: 'right'
                                    }}
                                >
                                    <b>{loc('netTax', lang)}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles2,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    <b>{formatAsCurrency(netTax, lang)}</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
}

function getRolledUpBulkCustomFeeLines(bulks, lang) {
    let newCustomFeesLines = [];
    let customFeeLinesGrouped = [];

    if (_.isNil(bulks) || _.isEmpty(bulks)) return newCustomFeesLines;

    for (let bulk of bulks) {
        if (!_.isNil(bulk.customFees) && !_.isEmpty(bulk.customFees)) {
            newCustomFeesLines = newCustomFeesLines.concat(bulk.customFees);
        }
    }

    //group by description
    newCustomFeesLines.forEach(fee => {
        let baseRate;
        if (!_.isNil(fee.quantity) && fee.quantity !== 0 && fee.amount > 0) {
            baseRate = fee.amount / fee.quantity;
        } else if (fee.amount > 0) {
            baseRate = fee.amount;
        }
        const existingFee = _.find(customFeeLinesGrouped, { description: fee.description, baseRate });
        if (!_.isNil(existingFee)) {
            existingFee.amount += fee.amount;
            existingFee.quantity += _.get(fee, 'quantity', 0);
        } else {
            fee.baseRate = baseRate;
            if (!_.isNil(fee.quantity)) {
                fee.quantity = 1;
            }
            customFeeLinesGrouped.push(_.cloneDeep(fee));
        }
    });

    for (let fee of customFeeLinesGrouped) {
        let description = fee.description;
        if (!_.isNil(fee.quantity) && !_.isNil(fee.baseRate) && fee.quantity > 1 && fee.amount > 0) {
            fee.baseDescription = description;
            fee.description = `${fee.quantity} x ${description} (${formatAsCurrency(fee.baseRate, lang)} ${loc(
                'ea',
                lang
            )})`;
        }
    }

    return _.filter(customFeeLinesGrouped, line => {
        return line.amount !== 0;
    });
}

function getDepositTaxAmounts(depositTaxGroup, applicableLedgerLines) {
    let deposits = 0;
    applicableLedgerLines.forEach(line => {
        if (line.amount < 0) {
            deposits += Math.abs(line.amount);
        }
    });
    let depositTaxAmounts = [];
    if (!_.isNil(depositTaxGroup)) {
        _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
            let currentAmountForTax = _.find(depositTaxAmounts, { _id: tax._id });
            if (_.isNil(currentAmountForTax)) {
                currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                depositTaxAmounts.push(currentAmountForTax);
            }
            const taxType = _.get(tax, 'configuration.type', 'included');
            if (taxType === 'included') {
                const beforeTaxAmount = _.round(deposits / (1 + _.get(tax, 'configuration.value', 0)));

                const taxAmount = deposits - beforeTaxAmount;
                currentAmountForTax.amount += taxAmount;
            } else {
                //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
            }
        });
    }

    return depositTaxAmounts;
}

function getFeeTaxAmounts(ledgerLines, allTaxes) {
    const taxAmounts = [];
    ledgerLines.forEach(line => {
        const taxForLine = _.find(allTaxes, tax => _.get(line, 'description', '').includes(_.get(tax, 'name')));
        if (!_.isNil(taxForLine)) {
            let taxAmountForTax = _.find(taxAmounts, { taxName: _.get(taxForLine, 'name') });
            if (_.isNil(taxAmountForTax)) {
                taxAmountForTax = { amount: 0, taxName: _.get(taxForLine, 'name') };
                taxAmounts.push(taxAmountForTax);
            }
            taxAmountForTax.amount += line.amount;
        }
    });

    return taxAmounts;
}
