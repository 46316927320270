import React from 'react';
import _ from 'lodash';

import { maxFileSize } from 'constants.js';
import LogoDrop from './LogoDrop/LogoDrop';

import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { loc } from 'localizations/localizationHandler';

const SavedLogo = props => {
    const { logo, name, theme } = props;

    return <img src={logo} alt={`${name} logo`} style={{ marginTop: theme.spacing.unit, height: 80 }} />;
};

const NoLogoSelected = props => {
    const { theme, lang } = props;
    return <span style={{ color: theme.palette.text.disabled }}>{loc('dashboardSetting33', lang)}</span>;
};

const LogoSelected = props => {
    const { theme, file, lang } = props;

    return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
            <span style={{ color: theme.palette.primary.main }}>
                {file.name} ({(file.size / 1000).toFixed(1)} kB)
            </span>
            <span>
                {'\n'}
                {loc('dashboardSetting32', lang)}
            </span>
        </span>
    );
};

const LogoUpload = props => {
    const { theme, file, handleDrop, name, logo, lang, disabled } = props;

    return (
        <>
            <InputLabel shrink style={{ position: 'initial' }}>
                {name} Logo
            </InputLabel>
            <section style={{ marginTop: theme.spacing.unit / 2 }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <div>
                        <LogoDrop
                            disabled={disabled}
                            theme={theme}
                            file={file}
                            maxFileSize={maxFileSize}
                            handleDrop={handleDrop}
                        />
                        <Typography
                            variant="subtitle1"
                            style={{
                                marginTop: theme.spacing.unit,
                                color: disabled ? theme.palette.text.disabled : 'auto'
                            }}
                        >
                            {loc('dashboardSetting12', lang)}{' '}
                            {!_.isNil(file) ? (
                                <LogoSelected theme={theme} file={file} lang={lang} />
                            ) : (
                                <NoLogoSelected theme={theme} lang={lang} />
                            )}
                        </Typography>
                        <Typography variant="caption" style={{ color: theme.palette.text.secondary }}>
                            {' '}
                            {loc('dashboardSetting12a', lang)}{' '}
                        </Typography>
                    </div>
                    {logo && <SavedLogo logo={logo} name={name} theme={theme} />}
                </div>
            </section>
        </>
    );
};

export default LogoUpload;
