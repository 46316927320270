import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as turf from '@turf/turf';
import {
    formatAsCurrency,
    deviceHelper,
    downloadObjectAsCSV,
    getCustomerName,
    getQuickDropName,
    getWalkInName
} from 'utils/misc';
import { RESIDENTIAL, COMMERCIAL, CONDO } from '../../../constants';
import { DROPNGO, ADJUSTMENT, PICKUP, WALKIN } from '../../../constants';
import { getClosestRegion } from 'utils/latlngFunctions';
import { _bulk, _zone } from 'std';
import * as terms from 'localizations/terms';

// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE);

const CONDO_LOCAL_NAME = process.env.REACT_APP_REGION_EXT === 'AUS' ? 'Unit' : CONDO;

const useDashboard = ({ http, collectors, onSnackbar, startDate, endDate, regions, collector, pickupsEnabled }) => {
    const [loading, setLoading] = useState(false);

    const [collectorsSelected, setCollectorsSelected] = useState(
        collectors.filter(c => _.get(c, 'configuration.enableCounting', true)).map(c => c._id)
    );
    const [collectorSelected, setCollectorSelected] = useState();
    const [transportersSelected, setTransportersSelected] = useState(
        !_.isNil(collector)
            ? [collector._id]
            : collectors.filter(c => _.get(c, 'configuration.enablePickups', true)).map(c => c._id)
    );
    const [hideInactiveCollectors, setHideInactiveCollectors] = useState(true);
    const [quickStats, setQuickStats] = useState();

    const [allDrivers, setAllDrivers] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);
    const [allCollectorAdmins, setAllCollectorAdmins] = useState([]);
    const [collectorAdminsSelected, setCollectorAdminsSelected] = useState([]);

    const fetchQuickStats = async () => {
        setLoading(true);
        let employeesSelected = [...driversSelected, ...collectorAdminsSelected];
        const res = await http.getJSON(
            `/system/quickStats?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&processors=${collectorsSelected}&transporters=${transportersSelected}&receivers=${employeesSelected}`
        );

        if (res.ok) {
            let totals = {
                totalDeposits: 0,
                customers: 0,
                app: 0,
                CSF: 0,
                CSFTax: 0,
                CSFBySku: [],
                handlingFees: 0,
                fee: 0,
                feeTax: 0,
                collectorFee: 0,
                collectorFeeTax: 0,
                skipTheDepotFee: 0,
                skipTheDepotFeeTax: 0,
                promoCode: 0,
                kickBack: 0,
                commission: 0,
                fullRefundPromo: 0,
                quickdropCommission: 0,
                lateFees: 0,
                fixedCosts: 0,
                cashOutAmount: 0,
                independentDrivers: {
                    pay: 0,
                    containers: 0,
                    smallContainers: 0,
                    largeContainers: 0,
                    nonConformant: 0,
                    drivingDistance: 0,
                    commission: 0,
                    // wage: 0,
                    // wageEst: 0,
                    // wageHours: 0,
                    gasCost: 0,
                    gasCostEst: 0,
                    contractorCost: 0
                },
                independentDriversCSF: 0,
                depotDrivers: {
                    wage: 0,
                    wageEst: 0,
                    wageHours: 0,
                    containers: 0,
                    drivingDistance: 0,
                    smallContainers: 0,
                    largeContainers: 0,
                    gasCost: 0,
                    gasCostEst: 0
                },
                volumeFee: 0
            };

            totals.independentDrivers.commissionBreakdown = [];
            res.data.collectorsData.forEach(({ revenue }) => {
                totals.totalDeposits += revenue.totalDeposits;
                totals.customers += revenue.customerRevenue;
                totals.app += revenue.appRevenue;
                totals.CSF += revenue.collectorRevenue;
                totals.CSFBySku = [...totals.CSFBySku, ...revenue.csfBySkuType];
                totals.CSFTax += revenue.collectorRevenueTax;
                totals.handlingFees += revenue.handlingFees;
                totals.fee += revenue.customFeeRevenue.skipTheDepot + revenue.customFeeRevenue.collector;
                totals.feeTax += revenue.customFeeRevenue.skipTheDepotTax + revenue.customFeeRevenue.collectorTax;
                totals.collectorFee += revenue.customFeeRevenue.collector;
                totals.collectorFeeTax += revenue.customFeeRevenue.collectorTax;
                totals.skipTheDepotFee += revenue.customFeeRevenue.skipTheDepot;
                totals.skipTheDepotFeeTax += revenue.customFeeRevenue.skipTheDepotTax;
                totals.promoCode += revenue.promoCode;
                totals.kickBack += revenue.referralKickback;
                totals.commission += revenue.commission;
                totals.fullRefundPromo += revenue.fullRefundPromo;
                totals.quickdropCommission += revenue.quickdropCommission;
                totals.lateFees += revenue.lateFees;
                totals.fixedCosts += revenue.fixedCosts;
                totals.cashOutAmount += revenue.cashOutAmount;
                totals.volumeFee += revenue.volumeFee;

                totals.independentDrivers.pay += revenue.independentDrivers.pay;
                totals.independentDrivers.containers += revenue.independentDrivers.containers;
                totals.independentDrivers.smallContainers += revenue.independentDrivers.smallContainers;
                totals.independentDrivers.largeContainers += revenue.independentDrivers.largeContainers;
                totals.independentDrivers.nonConformant += revenue.independentDrivers.nonConformant;
                totals.independentDrivers.drivingDistance += revenue.independentDrivers.drivingDistance;
                totals.independentDrivers.commission += revenue.independentDrivers.commission;
                totals.independentDrivers.gasCost += revenue.independentDrivers.gasCost;
                totals.independentDrivers.gasCostEst += revenue.independentDrivers.gasCostEst;
                totals.independentDrivers.contractorCost += revenue.independentDrivers.contractorCost;
                // totals.independentDrivers.wage += revenue.independentDrivers.wage;
                // totals.independentDrivers.wageEst += revenue.independentDrivers.wageEst;
                // totals.independentDrivers.wageHours += revenue.independentDrivers.wageHours;
                revenue.independentDrivers.commissionBreakdown.forEach(commission => {
                    let totalsCommission = _.find(
                        totals.independentDrivers.commissionBreakdown,
                        c => c.skuType === commission.skuType
                    );
                    if (_.isNil(totalsCommission)) {
                        totals.independentDrivers.commissionBreakdown.push(commission);
                    } else {
                        totalsCommission.pay += commission.pay;
                    }
                });

                totals.independentDriversCSF += revenue.independentDriversCSF;

                // totals.depotDrivers.wage += revenue.depotDrivers.wage;
                totals.depotDrivers.wageEst += revenue.depotDrivers.wageEst;
                totals.depotDrivers.wageHours += revenue.depotDrivers.wageHours;
                totals.depotDrivers.containers += revenue.depotDrivers.containers;
                totals.depotDrivers.smallContainers += revenue.depotDrivers.smallContainers;
                totals.depotDrivers.largeContainers += revenue.depotDrivers.largeContainers;
                totals.depotDrivers.drivingDistance += revenue.depotDrivers.drivingDistance;
                totals.depotDrivers.gasCost += revenue.depotDrivers.gasCost;
                totals.depotDrivers.gasCostEst += revenue.depotDrivers.gasCostEst;
            });

            let totalCommodities = {};
            res.data.collectorsData.forEach(({ commodities }) => {
                for (let commodity in commodities) {
                    if (_.isNil(totalCommodities[commodity])) {
                        totalCommodities[commodity] = commodities[commodity];
                    } else {
                        totalCommodities[commodity].quantity += commodities[commodity].quantity;
                        totalCommodities[commodity].skipRevenue += commodities[commodity].skipRevenue;
                    }
                }
            });

            if (res.data.consolidateCommission) {
                const csfGroupedSums = _(totals.CSFBySku)
                    .filter(csfObj => ['beverage', 'clothing', 'electronics'].includes(_.get(csfObj, 'skuType', '')))
                    .groupBy('skuType')
                    .map((group, id) => ({
                        skuType: id,
                        csf: _.sumBy(group, 'csf')
                    }))
                    .value();

                csfGroupedSums.forEach(csfGroup => {
                    totals.commission += csfGroup.csf;
                    if (totalCommodities[csfGroup.skuType]) {
                        totalCommodities[csfGroup.skuType].skipRevenue += csfGroup.csf;
                    }
                });
            }

            setQuickStats({ ...res.data, totals, totalCommodities });
            setLoading(false);
        }
    };

    const handleCollectorsSelected = collectors => {
        setCollectorsSelected(collectors);
    };

    const handleTransportersSelected = transporters => {
        setTransportersSelected(transporters);
    };

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(
                collectors.filter(c => _.get(c, 'configuration.enableCounting', true)).map(c => c._id)
            );
        } else {
            setCollectorsSelected([]);
        }
    };

    const handleAllFilterTransporters = all => e => {
        e.stopPropagation();
        if (all) {
            setTransportersSelected(
                collectors.filter(c => _.get(c, 'configuration.enablePickups', true)).map(c => c._id)
            );
        } else {
            setTransportersSelected([]);
        }
    };

    const handleViewCollector = collectorManager => () => {
        if (deviceHelper.isNativeApp()) {
            this.props.history.push(`/operators/${collectorManager._id}/collector`);
        } else {
            window.open(`/operators/${collectorManager._id}/collector`, '_blank');
        }
    };

    const handleHideInactiveCollectors = () => {
        setHideInactiveCollectors(!hideInactiveCollectors);
    };

    const handleDownloadUsers = async () => {
        let res = await http.getJSON(
            `/system/users?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        );
        if (!res.ok) {
            return;
        }

        const customers = _.get(res, 'data.customers', []);

        const csvRows = [];
        customers.forEach(customer => {
            csvRows.push({
                dateCreated: moment(customer.createdAt).tz(process.env.REACT_APP_REGION_TIMEZONE),
                accountType: customer.accountType,
                name: getCustomerName(customer),
                customerCode: customer.uniqueID,
                email: customer.email,
                address: _.get(customer, 'location.description', ''),
                hasCharity: !_.isNil(customer.charities) && !_.isEmpty(customer.charities)
            });
        });

        let date = moment().format('YYYY-MM-DD HH:mm');
        /*await downloadObjectAsCSV(data, `users_${date}`);
            onSnackbar('CSV saved to downloads folder');*/
        const fileName = `users_${date}`;
        try {
            await downloadObjectAsCSV(csvRows, fileName);
            if (deviceHelper.isNativeApp()) {
                onSnackbar(`${fileName} saved to documents folder`);
            }
        } catch (err) {
            onSnackbar(`Cannot download file`, 'error');
        }
    };

    const handleDownloadPickups = async () => {
        let res = await http.getJSON(`/system/pickups?collectors=${collectorsSelected}`);
        if (res.ok) {
            let data = _.sortBy(
                res.data.pickups.map(pickup => {
                    let countDonated = _.get(
                        _.find(res.data.pickupsDonated, pickupDonated => pickupDonated._id === pickup._id),
                        'count',
                        0
                    );
                    return {
                        date: pickup._id,
                        regular: pickup.count - countDonated,
                        donated: countDonated,
                        all: pickup.count
                    };
                }),
                'date'
            );
            let date = moment().format('YYYY-MM-DD HH:mm');
            /*await downloadObjectAsCSV(data, `pickups_${date}`);
            onSnackbar('CSV saved to downloads folder');*/
            const fileName = `pickups_${date}`;
            try {
                await downloadObjectAsCSV(data, fileName);
                if (deviceHelper.isNativeApp()) {
                    onSnackbar(`${fileName} saved to documents folder`);
                }
            } catch (err) {
                onSnackbar(`Cannot download file`, 'error');
            }
        }
    };

    const handleDownloadContainers = async () => {
        let res = await http.getJSON('/system/getPaymentBreakdown');
        if (res.ok) {
            let data = _.sortBy(res.data.paymentBreakdown, '_id');
            let paymentsData = data.map(paymentBreakdown => {
                return {
                    date: paymentBreakdown._id,
                    owner_cut: _.round(paymentBreakdown.totalOwnerAmount / 100, 2),
                    driver_cut: _.round(paymentBreakdown.totalTransporterAmount / 100, 2),
                    collector_cut: _.round(paymentBreakdown.totalCollectorAmount / 100, 2),
                    handling_fees: _.round(paymentBreakdown.totalHandlingAmount / 100, 2)
                };
            });

            let date = moment().format('YYYY-MM-DD HH:mm');

            const fileName = `containers_worth_${date}`;
            try {
                await downloadObjectAsCSV(paymentsData, fileName);
                if (deviceHelper.isNativeApp()) {
                    onSnackbar(`${fileName} saved to documents folder`);
                }
            } catch (err) {
                onSnackbar(`Cannot download file`, 'error');
            }
        }
    };

    const handleClothingDialog = (id = null) => () => {
        if (id) {
            setCollectorSelected(_.find(quickStats.collectorsData, c => c._id === id));
        } else {
            setCollectorSelected();
        }
    };

    const handleCloseOutstandingClothing = async () => {
        const res = await http.post(`/collectors/${collectorSelected._id}/closeOutstandingClothing`, {
            startDate,
            endDate
        });
        if (res.ok) {
            handleClothingDialog(false);
            onSnackbar('Clothing closed out.');
            await fetchQuickStats();
        }
    };

    const getCustomerQuickstats = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;

        const {
            newNumberOfCustomers,
            numberOfNewActiveCustomers,
            totalNumberOfCustomers,
            numberOfInactiveCustomers,
            numberOfRecurringCustomers,
            numberOfDeletedCustomers,
            numberOfDisabledCustomers,
            numberOfStaleCustomers,
            redemptionsPending,
            redemptionsProcessed,
            totalReferralInvites,
            totalReferrals,
            totalReferralsClaimed,
            referralInvitesUniqueCustomer
        } = quickStats;

        const customerQuickStats = [
            {
                name: 'New',
                value: newNumberOfCustomers.toLocaleString(),
                title: `Number of new users between ${dateRangeText}`
            },
            {
                name: 'Active',
                value: `${numberOfNewActiveCustomers.toLocaleString()} (${parseInt(
                    (numberOfNewActiveCustomers / newNumberOfCustomers) * 100
                )}%)`,
                title: `Number of new sign ups who booked a pickup between ${dateRangeText}`
            },
            {
                name: 'Recurring',
                value: numberOfRecurringCustomers.toLocaleString(),
                title: `Number of existing users who booked a pickup between ${dateRangeText}`
            },
            {
                name: 'Total Users',
                value: totalNumberOfCustomers.toLocaleString(),
                title: `Number of total users`
            },
            {
                name: 'Total Closed',
                value: numberOfDisabledCustomers.toLocaleString(),
                title: `Number of closed users`
            },
            {
                name: 'Total Stale',
                value: numberOfStaleCustomers.toLocaleString(),
                title: `Number of stale users`
            },
            {
                name: 'Total Deleted',
                value: numberOfDeletedCustomers.toLocaleString(),
                title: `Number of deleted users`
            },
            {
                name: 'Redemptions Processed',
                value: `${redemptionsProcessed.length} (${formatAsCurrency(redemptionsProcessed.amount)})`,
                title: `Number of redemptions processed between ${dateRangeText}`
            },
            {
                name: 'Redemptions Pending',
                value: `${redemptionsPending.length} (${formatAsCurrency(redemptionsPending.amount)})`,
                title: `Number of redemptions awaiting processing between ${dateRangeText}`
            },
            {
                name: 'Referral Invites',
                value: `${totalReferralInvites} (${referralInvitesUniqueCustomer})`,
                title: `Total interactions with the referral dialog between ${dateRangeText} (Total customers)`
            },
            {
                name: 'Referrals',
                value: `${totalReferrals} (${totalReferralsClaimed} | ${totalReferrals - totalReferralsClaimed})`,
                title: `Total referrals on counted bulks picked up between ${dateRangeText} (Total claimed | Total denied)`
            }
        ];

        if (!pickupsEnabled) {
            customerQuickStats.splice(1, 2);
        } else if (numberOfInactiveCustomers > 0) {
            customerQuickStats.splice(
                4,
                0,

                {
                    name: 'Total Inactive',
                    value: numberOfInactiveCustomers.toLocaleString(),
                    title: `Number of inactive users`
                }
            );
        }

        return customerQuickStats;
    };

    const getCommissionBreakdown = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;
        const { commissionBreakdown } = quickStats.totals.independentDrivers;

        const commissionSubValues = [];
        for (const commission in commissionBreakdown) {
            if (commissionBreakdown[commission].pay === 0) {
                continue;
            }
            const newCommissionLine = {
                name: `▪ ${_.capitalize(_.get(commissionBreakdown[commission], 'skuType', '').toString())}`,
                value: `${formatAsCurrency(commissionBreakdown[commission].pay)} `,
                title: `Total ${commissionBreakdown[commission].skuType} revenue between ${dateRangeText}`
            };
            commissionSubValues.push(newCommissionLine);
        }

        return commissionSubValues;
    };

    const getFixedCostsBreakdown = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;
        const { allTransporterFixedCosts } = quickStats;

        const fixedCostSubValues = [];
        for (const fixedCost of allTransporterFixedCosts) {
            if (!fixedCost.amount || ['gas', 'Gas', 'GAS'].includes(fixedCost.name)) continue;

            const newFixedCostLine = {
                name: `▪ ${fixedCost.name}`,
                value: `${formatAsCurrency(fixedCost.amount)} `,
                title: `Fixed cost for ${fixedCost.name} on trips between ${dateRangeText}`
            };
            fixedCostSubValues.push(newFixedCostLine);
        }

        const sortedFixedCostSubValues = _.sortBy(fixedCostSubValues, 'name');

        return sortedFixedCostSubValues;
    };

    const getContractorQuickStats = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;
        const hasBreakdown = process.env.REACT_APP_REGION_EXT === 'STD';
        const {
            containers,
            smallContainers,
            largeContainers,
            drivingDistance,
            commission,
            gasCost: contractorGasCost,
            gasCostEst: contractorGasCostEst
            // wage: contractorWage,
            // wageEst: contractorWageEst
        } = quickStats.totals.independentDrivers;

        const {
            // gasCost: depotDriverGasCost,
            gasCostEst: depotDriverGasCostEst,
            // wage: depotDriverWage,

            drivingDistance: drivingDistanceDepot,
            containers: depotContainers,
            smallContainers: depotSmallContainers,
            largeContainers: depotLargeContainers,
            wageEst: depotDriverWageEst,
            wageHours
        } = quickStats.totals.depotDrivers;

        const { allTransporterFixedCosts } = quickStats;

        const gasFixedCosts = _.filter(allTransporterFixedCosts, cost =>
            _.get(cost, 'name', '')
                .toLowerCase()
                .includes('gas')
        );
        const gasFixedCost = gasFixedCosts.reduce((t, c) => t + _.get(c, 'amount', 0), 0);
        // const gasCost = contractorGasCost + gasFixedCost; // Actual gas cost

        const otherCosts = _.filter(
            allTransporterFixedCosts,
            cost =>
                !_.get(cost, 'name', '')
                    .toLowerCase()
                    .includes('gas') &&
                !_.get(cost, 'name', '')
                    .toLowerCase()
                    .includes('payroll')
        );
        const otherCostItems = otherCosts.map(c => {
            return { name: _.get(c, 'name', ''), value: formatAsCurrency(_.get(c, 'amount', 0)) };
        });
        const otherCost = otherCosts.reduce((t, c) => t + _.get(c, 'amount', 0), 0);

        const wageFixedCosts = _.filter(allTransporterFixedCosts, cost =>
            _.get(cost, 'name', '')
                .toLowerCase()
                .includes('payroll')
        );
        const wage = wageFixedCosts.reduce((t, c) => t + _.get(c, 'amount', 0), 0); // Actual wage
        const wageEst = depotDriverWageEst;

        const contractorDriverCost = commission + contractorGasCost;

        const totalDriverCost = -(
            contractorDriverCost +
            (!_.isNil(wage) && !_.isNaN(wage) && wage > 0 ? wage : wageEst) +
            (!_.isNil(gasFixedCost) && !_.isNaN(gasFixedCost) && gasFixedCost > 0
                ? gasFixedCost
                : depotDriverGasCostEst) +
            otherCost
        );

        const costPerContainer = -(totalDriverCost / (containers + depotContainers)) / 100;

        const driverStats = [
            {
                name: 'Contractor Pay',
                isBold: true,
                value: `${formatAsCurrency(commission + contractorGasCost)}`,
                title: `Total driver commission on trips between ${dateRangeText}`,
                subValues: [
                    ...getCommissionBreakdown(),
                    {
                        name: '▪ Gas',
                        value: `${formatAsCurrency(contractorGasCost)}`,
                        title: `Contractor gas cost on trips between ${dateRangeText}`
                    }
                ]
            },
            {
                name: 'Employee Cost',
                isBold: true,
                value: `${formatAsCurrency(!_.isNil(wage) && !_.isNaN(wage) && wage > 0 ? wage : wageEst)}`,
                title: `Total driver wage cost on trips between ${dateRangeText}`,
                subValues: [
                    {
                        name: `▪ Estimate (${wageHours.toLocaleString()} Hrs)`,
                        value: `${formatAsCurrency(wageEst)}`,
                        title: `Estimated driver wage cost on trips between ${dateRangeText}`,
                        isLight: !_.isNil(wage) && !_.isNaN(wage) && wage > 0
                    },
                    {
                        name: `▪  Actual`,
                        value: formatAsCurrency(wage),
                        title: `Actual driver wage cost on trips between ${dateRangeText}`,
                        isLight: _.isNil(wage) || _.isNaN(wage) || wage <= 0
                    }
                ]
            },
            {
                name: 'Gas Cost',
                isBold: true,
                value: `${formatAsCurrency(
                    !_.isNil(gasFixedCost) && !_.isNaN(gasFixedCost) && gasFixedCost > 0
                        ? gasFixedCost
                        : depotDriverGasCostEst
                )}`,
                title: `Total gas cost for wage drivers on trips between ${dateRangeText}`,
                subValues: [
                    {
                        name: `▪ Estimate (${(drivingDistanceDepot / 1000).toLocaleString()} Km)`,
                        value: formatAsCurrency(depotDriverGasCostEst),
                        title: `Estimated driver gas cost on trips between ${dateRangeText}`,
                        isLight: !_.isNil(gasFixedCost) && !_.isNaN(gasFixedCost) && gasFixedCost > 0
                    },
                    {
                        name: `▪ Actual`,
                        value: `${formatAsCurrency(gasFixedCost)}`,
                        title: `Actual driver gas cost on trips between ${dateRangeText}`,
                        isLight: _.isNil(gasFixedCost) || _.isNaN(gasFixedCost) || gasFixedCost <= 0
                    }
                ]
            },
            {
                name: 'Other Costs',
                isBold: true,
                value: `${formatAsCurrency(otherCost)}`,
                title: `Entered Costs for trips between ${dateRangeText}`,
                subValues: [...otherCostItems]
            },
            {
                name: 'Containers',
                value: `${(containers + depotContainers).toLocaleString()} ${
                    hasBreakdown
                        ? `(${(smallContainers + depotSmallContainers).toLocaleString()} | ${(
                              largeContainers + depotLargeContainers
                          ).toLocaleString()})`
                        : ''
                }`,
                title: `Total containers on trips between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: 'Cost per Container',
                value: `$${_.round(costPerContainer, 4)}`,
                title: `Cost per container on trips between ${dateRangeText}`
            }
        ];

        return { driverStats, totalDriverCost };
    };

    const getProcessingQuickStats = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;

        let totalUncountedBulks = 0,
            totalBulksUnderThreshold = 0;

        quickStats.collectorsData.forEach(({ numberOfUncountedBulks, numberOfBulksUnderThreshold, commodities }) => {
            totalUncountedBulks += numberOfUncountedBulks;
            totalBulksUnderThreshold += numberOfBulksUnderThreshold;
        });

        const textList = [
            {
                name: 'Booked',
                value: quickStats.numberOfPickupsRequested.toLocaleString(),
                title: `Number of currently outstanding pickups requested between ${dateRangeText}`
            },
            {
                name: 'Processed',
                value: (
                    quickStats.numberOfPickupsCompleted +
                    quickStats.numberOfQuickdropBulks +
                    quickStats.numberOfOTCWalkInBulks +
                    quickStats.numberOfRVMWalkInBulks
                ).toLocaleString(),
                title: `Number of orders processed between ${dateRangeText}`,
                subValues: [
                    {
                        name: '▪ Residential',
                        value: quickStats.numberOfResidentialPickupsCompleted.toLocaleString(),
                        title: `Number of residential pickups completed between ${dateRangeText}`
                    },
                    {
                        name: `▪ ${_.startCase(CONDO_LOCAL_NAME)}`,
                        value: quickStats.numberOfCondoPickupsCompleted.toLocaleString(),
                        title: `Number of ${_.startCase(CONDO_LOCAL_NAME)} pickups completed between ${dateRangeText}`
                    },
                    {
                        name: '▪ Commercial',
                        value: quickStats.numberOfCommercialPickupsCompleted.toLocaleString(),
                        title: `Number of commercial pickups completed between ${dateRangeText}`
                    },
                    {
                        name: '▪ OOS',
                        value: quickStats.numberOfOOSCompleted.toLocaleString(),
                        title: `Number of OOS pickups completed between ${dateRangeText}`
                    },
                    {
                        name: `▪ ${getQuickDropName('en')}`,
                        value: quickStats.numberOfQuickdropBulks.toLocaleString(),
                        title: `Number of ${getQuickDropName('en')} orders between ${dateRangeText}`
                    },
                    {
                        name: `▪ ${getWalkInName('en')} (OTC)`,
                        value: quickStats.numberOfOTCWalkInBulks.toLocaleString(),
                        title: `Number of ${getWalkInName('en')} (OTC) orders between ${dateRangeText}`
                    },
                    {
                        name: `▪ ${getWalkInName('en')} (RVM)`,
                        value: quickStats.numberOfRVMWalkInBulks.toLocaleString(),
                        title: `Number of ${getWalkInName('en')} (RVM) orders between ${dateRangeText}`
                    }
                ]
            },
            {
                name: 'Aborted',
                value: quickStats.numberOfAbortedPickups.toLocaleString(),
                title: `Number of pickups aborted between ${dateRangeText}`
            },
            {
                name: 'Rescheduled',
                value: quickStats.numberOfRescheduledPickups.toLocaleString(),
                title: `Number of pickups rescheduled between ${dateRangeText}`
            },
            {
                name: 'Uncounted',
                value: totalUncountedBulks.toLocaleString(),
                title: `Number of uncounted bulks picked up between ${dateRangeText}`
            },
            {
                name: 'Under Threshold',
                value: totalBulksUnderThreshold.toLocaleString(),
                title: `Number of low container volume bulks picked up between ${dateRangeText}`
            }
        ];

        if (!pickupsEnabled) {
            textList[1].subValues.splice(0, 4);
            textList.splice(2, 2);
            textList.splice(0, 1);
        }

        const { totalCommodities } = quickStats;
        for (let totalCommodity in totalCommodities) {
            if (totalCommodities[totalCommodity].quantity === 0) {
                continue;
            }

            let newCommodityLine = {
                name: totalCommodity,
                value: `${_.round(totalCommodities[totalCommodity].quantity, 2)} ${
                    totalCommodities[totalCommodity].unit
                }`,
                title: `Total ${totalCommodity} in ${totalCommodities[totalCommodity].unit} between ${dateRangeText}`
            };
            textList.push(newCommodityLine);
        }

        return textList;
    };

    const getContainersQuickStats = () => {
        const hasBreakdown = process.env.REACT_APP_REGION_EXT === 'STD';
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;

        const processing = {
            [RESIDENTIAL]: { small: 0, large: 0, total: 0 },
            [COMMERCIAL]: { small: 0, large: 0, total: 0 },
            [CONDO]: { small: 0, large: 0, total: 0 },
            inhouse: { small: 0, large: 0, total: 0 },
            walkin: { small: 0, large: 0, total: 0 },
            rvm: { small: 0, large: 0, total: 0 },
            adjustment: { small: 0, large: 0, total: 0 },
            lostandfound: { small: 0, large: 0, total: 0 },
            'Bottle Drive': { small: 0, large: 0 },
            total: { small: 0, large: 0, total: 0 }
        };

        quickStats.collectorsData.forEach(({ containers }) => {
            for (let key in containers) {
                processing[key].total += containers[key].small + containers[key].large;
                processing[key].small += containers[key].small;
                processing[key].large += containers[key].large;

                processing.total.total += containers[key].small + containers[key].large;
                processing.total.small += containers[key].small;
                processing.total.large += containers[key].large;
            }
        });

        const textList = [
            {
                name: RESIDENTIAL,
                value: `${processing[RESIDENTIAL].total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing[RESIDENTIAL].small.toLocaleString()} | ${processing[
                              RESIDENTIAL
                          ].large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${RESIDENTIAL} containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: COMMERCIAL,
                value: `${processing[COMMERCIAL].total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing[COMMERCIAL].small.toLocaleString()} | ${processing[
                              COMMERCIAL
                          ].large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${COMMERCIAL} containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: CONDO_LOCAL_NAME,
                value: `${processing[CONDO].total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing[CONDO].small.toLocaleString()} | ${processing[CONDO].large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${_.startCase(CONDO_LOCAL_NAME)} containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: getQuickDropName('en'),
                value: `${processing.inhouse.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.inhouse.small.toLocaleString()} | ${processing.inhouse.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${getQuickDropName('en')} containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: `${getWalkInName('en')} (OTC)`,
                value: `${processing.walkin.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.walkin.small.toLocaleString()} | ${processing.walkin.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${getWalkInName('en')} (OTC) containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: `${getWalkInName('en')} (RVM)`,
                value: `${processing.rvm.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.rvm.small.toLocaleString()} | ${processing.rvm.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of ${getWalkInName('en')} (RVM) containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: 'Adjustment',
                value: `${processing.adjustment.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.adjustment.small.toLocaleString()} | ${processing.adjustment.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of adjustment containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: 'Lost and Found',
                value: `${processing.lostandfound.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.lostandfound.small.toLocaleString()} | ${processing.lostandfound.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of lost and found containers between ${dateRangeText} ${
                    hasBreakdown ? '(Under 1L | Over 1L)' : ''
                }`
            },
            {
                name: 'Total Containers',
                value: `${processing.total.total.toLocaleString()} ${
                    hasBreakdown
                        ? `(${processing.total.small.toLocaleString()} | ${processing.total.large.toLocaleString()})`
                        : ''
                }`,
                title: `Number of containers between ${dateRangeText} ${hasBreakdown ? '(Under 1L | Over 1L)' : ''}`
            }
        ];

        if (!pickupsEnabled) {
            textList.splice(0, 3);
        }

        return textList;
    };

    const getCheckAndBalanceStats = () => {
        const {
            independentDrivers,
            totalDeposits,
            CSF,
            collectorFee,
            cashOutAmount,
            lateFees,
            fullRefundPromo,
            commission,
            customers,
            volumeFee
        } = quickStats.totals;

        const { depotCommodityRevenue, adSpend, volumeFee: invoicingVolumeFee, totalInvoiced } = quickStats.invoicing;

        const invoicingTotalFromBulks =
            totalDeposits -
            (CSF + collectorFee + depotCommodityRevenue + cashOutAmount) +
            (volumeFee + independentDrivers.pay + lateFees + adSpend);

        return [
            {
                name: 'Deposits',
                value: (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div title="Deposits From Containers">{formatAsCurrency(totalDeposits)}</div>
                        <div title="Deposits From Invoicing">
                            {formatAsCurrency(quickStats.invoicing.totalDeposits)}
                        </div>
                        <div title="Deposits from Customer Revenue + Commission + FullRefundPromo + CSF">
                            {formatAsCurrency(customers + commission + fullRefundPromo + CSF)}
                        </div>
                    </span>
                )
            },
            {
                name: 'Volume Fee',
                value: (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div title="Volume Fee From Invoicing">{formatAsCurrency(invoicingVolumeFee)}</div>
                        <div title="Volume Fee From Contractor Containers">{formatAsCurrency(volumeFee)}</div>
                    </span>
                )
            },
            {
                name: 'Total Invoiced',
                value: (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div title="From Invoicing">{formatAsCurrency(Math.abs(totalInvoiced))}</div>
                        <div title="From Data">{formatAsCurrency(invoicingTotalFromBulks)}</div>
                    </span>
                )
            }
        ];
    };

    const getSTDQuickStatsAndTotal = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;

        const {
            independentDrivers,
            commission,
            quickdropCommission,
            fullRefundPromo,
            skipTheDepotFee,
            skipTheDepotFeeTax,
            kickBack,
            volumeFee
        } = quickStats.totals;

        const { totalCommodities, customFees } = quickStats;
        const aggregatedCustomFees = _bulk.aggregateCustomFeesByName(customFees);

        // const commodityRevenue = quickStats.invoicing.stdCommodityRevenue;
        const contractorCost = independentDrivers.contractorCost;
        const stdStats = [
            {
                name: `${getQuickDropName('en')} 5%`,
                value: `${formatAsCurrency(quickdropCommission)}`,
                title: `${getQuickDropName('en')} commission on all counted bulks picked up between ${dateRangeText}`
            },
            {
                name: 'Fee Revenue ',
                value: `${formatAsCurrency(skipTheDepotFee)} (${formatAsCurrency(skipTheDepotFeeTax)})`,
                title: `${
                    process.env.REACT_APP_BRAND_NAME
                } cut from custom fees in all counted bulks picked up between ${dateRangeText} (Total tax)`,
                subValues: Object.keys(aggregatedCustomFees).map(customFee => {
                    return {
                        name: `▪ ${customFee}`,
                        value: `${formatAsCurrency(aggregatedCustomFees[customFee].stdAmount)}`,
                        isLight:
                            _.isNil(aggregatedCustomFees[customFee].stdAmount) ||
                            _.isNaN(aggregatedCustomFees[customFee].stdAmount) ||
                            aggregatedCustomFees[customFee].stdAmount <= 0
                    };
                })
            },
            // {
            //     name: 'Commodity Revenue',
            //     value: formatAsCurrency(commodityRevenue),
            //     title: `Total ${process.env.REACT_APP_BRAND_NAME}'s cut on commodity `
            // },
            {
                name: 'Volume Fee',
                value: formatAsCurrency(volumeFee)
            },
            {
                name: 'Kick Back Expense',
                value: `-${formatAsCurrency(kickBack)}`,
                title: `Total expenses from referral codes in all counted bulks picked up between ${dateRangeText}`
            }
        ];

        const showFivePercentRefund = _.get(quickStats, 'showFivePercentRefund', true);
        if (showFivePercentRefund) {
            stdStats.splice(1, 0, {
                name: 'FullRefundPromo 5% ',
                value: formatAsCurrency(fullRefundPromo),
                title: `FullRefundPromo on all counted bulks picked up between ${dateRangeText}`
            });
        }

        const showContractorCost = _.get(quickStats, 'showContractorCost', false);
        // const showDrivers = _.get(quickStats, 'panelItems.showDrivers', false);
        if (showContractorCost) {
            stdStats.push({
                name: 'Contractor Cost',
                value: `-${formatAsCurrency(contractorCost)}`,
                title: `Total expenses from contractor commission and gas costs on bulks picked up between ${dateRangeText}`
            });
        }

        let commodityRevenue = 0;
        let cmdtyArr = [];
        for (let commodity in totalCommodities) {
            if (totalCommodities[commodity].skipRevenue === 0) {
                continue;
            }
            commodityRevenue += totalCommodities[commodity].skipRevenue;
            let newCommodityLine = {
                name: `Commission ${commodity}`,
                value: `${formatAsCurrency(totalCommodities[commodity].skipRevenue)} `,
                title: `Total ${commodity} revenue between ${dateRangeText}`
            };
            cmdtyArr.push(newCommodityLine);
        }

        stdStats.unshift({
            name: 'Commission Beverage',
            value: `${formatAsCurrency(commission - commodityRevenue)}`,
            title: `Commission on all counted bulks picked up between ${dateRangeText}`
        });

        stdStats.splice(1, 0, ...cmdtyArr);

        const stdStatsTotal =
            commission +
            quickdropCommission +
            fullRefundPromo +
            skipTheDepotFee +
            commodityRevenue +
            volumeFee -
            kickBack -
            contractorCost;

        return { stdStats, stdStatsTotal };
    };

    const getDepotQuickStatsAndTotal = () => {
        const dateRangeText = `${moment(startDate).format('DD MMM YYYY')} and ${moment(endDate).format('DD MMM YYYY')}`;
        const invoicingDateRangeTxt = `${moment(startDate)
            .add(1, 'w')
            .format('DD MMM YYYY')} and ${moment(endDate)
            .add(1, 'w')
            .format('DD MMM YYYY')}`;

        const { customFees } = quickStats;
        const aggregatedCustomFees = _bulk.aggregateCustomFeesByName(customFees);

        const {
            depotDrivers,
            CSFBySku,
            collectorFee,
            collectorFeeTax,
            handlingFees,
            fullRefundPromo,
            fixedCosts,
            volumeFee
        } = quickStats.totals;

        const {
            // depotCommodityRevenue,
            adSpend
        } = quickStats.invoicing;

        const csfGroupedSums = _(CSFBySku)
            .filter(csfObj => ['beverage', 'clothing', 'electronics'].includes(_.get(csfObj, 'skuType', '')))
            .groupBy('skuType')
            .map((group, id) => ({
                skuType: id,
                csf: _.sumBy(group, 'csf')
            }))
            .value();

        let depotStats = [];
        csfGroupedSums.forEach(csfCmdty => {
            depotStats.push({
                name: `CSF ${_.capitalize(csfCmdty.skuType)}`,
                value: formatAsCurrency(csfCmdty.csf),
                title: `Total ${csfCmdty.skuType} service fees in all counted bulks picked up between ${dateRangeText}`
            });
        });

        depotStats = [
            ...depotStats,
            {
                name: 'Fee Revenue',
                value: `${formatAsCurrency(collectorFee)} (${formatAsCurrency(collectorFeeTax)})`,
                title: `Collector cut from custom fees in all counted bulks picked up between ${dateRangeText} (Total tax)`,
                subValues: Object.keys(aggregatedCustomFees).map(customFee => {
                    return {
                        name: `▪ ${customFee}`,
                        value: `${formatAsCurrency(aggregatedCustomFees[customFee].collectorAmount)}`,
                        isLight:
                            _.isNil(aggregatedCustomFees[customFee].collectorAmount) ||
                            _.isNaN(aggregatedCustomFees[customFee].collectorAmount) ||
                            aggregatedCustomFees[customFee].collectorAmount <= 0
                    };
                })
            },
            // {
            //     name: 'Commodity Revenue',
            //     value: formatAsCurrency(depotCommodityRevenue),
            //     title: `Total depots cut on commodity revenue on invoices between ${invoicingDateRangeTxt}`
            // },
            {
                name: 'Handling Fees',
                value: formatAsCurrency(handlingFees),
                title: `Estimated total handling fees in all counted bulks picked up between ${dateRangeText}`
            },
            {
                name: 'FullRefundPromo 5%',
                value: `-${formatAsCurrency(fullRefundPromo)}`,
                title: `FullRefundPromo on all counted bulks picked up between ${dateRangeText}`
            },
            {
                name: 'Driver Cost',
                value: `-${formatAsCurrency(depotDrivers.wageEst)}`,
                title: `Total wage cost from trips between ${dateRangeText}`
            },
            {
                name: 'Gas Cost ',
                value: `-${formatAsCurrency(depotDrivers.gasCostEst)}`,
                title: `Total gas cost from trips between ${dateRangeText}`
            },
            {
                name: 'Fixed Costs',
                value: `-${formatAsCurrency(fixedCosts)}`,
                title: `Total fixed costs from trips between ${dateRangeText}`
            },
            {
                name: 'Volume Fee',
                value: `-${formatAsCurrency(volumeFee)}`,
                title: `Total volume fee on invoices between ${invoicingDateRangeTxt}`
            },
            {
                name: 'Ad Spend',
                value: `-${formatAsCurrency(adSpend)}`,
                title: `Total ad spend on invoices between ${invoicingDateRangeTxt}`
            }
        ];

        if (!pickupsEnabled) {
            depotStats.splice(_.findIndex(depotStats, item => item.name === 'Driver Cost'), 2);
        }

        const depotTotal =
            _.sumBy(csfGroupedSums, 'csf') +
            collectorFee +
            // depotCommodityRevenue +
            handlingFees -
            fullRefundPromo -
            depotDrivers.wageEst -
            depotDrivers.gasCostEst -
            fixedCosts -
            volumeFee -
            adSpend;

        return { depotStats, depotTotal };
    };

    const getInvoicingStats = () => {
        const dateRangeText = `${moment(startDate)
            .add(1, 'w')
            .format('DD MMM YYYY')} and ${moment(endDate)
            .add(1, 'w')
            .format('DD MMM YYYY')}`;

        const totalInvoiced =
            quickStats.invoicing.totalDeposits +
            quickStats.invoicing.csfAfterTax +
            quickStats.invoicing.csfTax +
            quickStats.invoicing.volumeFee +
            quickStats.invoicing.lateFees +
            quickStats.invoicing.extraFeesAfterTax +
            quickStats.invoicing.extraFeesTax +
            quickStats.invoicing.cashPayouts;
        return [
            {
                name: 'Total Deposits',
                value: formatAsCurrency(quickStats.invoicing.totalDeposits),
                title: `Total CSF on invoices created between ${dateRangeText}`
            },
            {
                name: 'CSF ',
                value: `${formatAsCurrency(
                    quickStats.invoicing.csfAfterTax + quickStats.invoicing.csfTax
                )} (${formatAsCurrency(quickStats.invoicing.csfTax)})`,
                title: `Total CSF on invoices created between ${dateRangeText} (Total tax)`
            },
            {
                name: 'Volume Fee',
                value: formatAsCurrency(quickStats.invoicing.volumeFee),
                title: `Total volume fees on invoices created between ${dateRangeText}`
            },
            {
                name: 'LateFees',
                value: formatAsCurrency(quickStats.invoicing.lateFees),
                title: `Total late fees on invoices created between ${dateRangeText}`
            },
            {
                name: 'Invoice Fee Revenue',
                value: `${formatAsCurrency(
                    quickStats.invoicing.extraFeesAfterTax + quickStats.invoicing.extraFeesTax
                )} (${formatAsCurrency(quickStats.invoicing.extraFeesTax)})`,
                title: `Total collector cut on extra fees on invoices created between ${dateRangeText} (Total tax)`
            },
            {
                name: 'Cash Payouts',
                value: formatAsCurrency(quickStats.invoicing.cashPayouts),
                title: `Total cash payouts on invoices created between ${dateRangeText}`
            },
            {
                name: 'Total Invoiced',
                value: formatAsCurrency(totalInvoiced),
                title: `Total invoiced between ${dateRangeText}`
            }
        ];
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(
                    drivers
                        .filter(driver => transportersSelected.includes(_.get(driver, 'collector._id', [])))
                        .map(driver => _.get(driver, '_id', null))
                );
            }
        })();

        (async () => {
            const res = await http.getJSON('/users/getAllCollectorAdmins');
            if (res.ok) {
                let newCollectorAdmins = _.get(res, 'data.collectorAdmins', []);
                setAllCollectorAdmins(newCollectorAdmins);
                setCollectorAdminsSelected(newCollectorAdmins.map(user => user._id));
            }
        })();
    }, []);

    useEffect(() => {
        setDriversSelected(
            _(allDrivers)
                .filter(driver => transportersSelected.includes(driver.collector._id))
                .map(driver => driver._id)
                .value()
        );
        setCollectorAdminsSelected(
            _(allCollectorAdmins)
                .filter(user => transportersSelected.includes(user.collector._id))
                .map(user => user._id)
                .value()
        );
    }, [transportersSelected]);

    return {
        loading,
        collectorsSelected,
        hideInactiveCollectors,
        fetchQuickStats,
        quickStats,
        collectorSelected,
        transportersSelected,
        allDrivers,
        driversSelected,
        handleCollectorsSelected,
        handleTransportersSelected,
        handleAllFilterCollectors,
        handleAllFilterTransporters,
        handleViewCollector,
        handleHideInactiveCollectors,
        handleDownloadUsers,
        handleDownloadPickups,
        handleDownloadContainers,
        handleClothingDialog,
        handleCloseOutstandingClothing,
        getCustomerQuickstats,
        getContractorQuickStats,
        getProcessingQuickStats,
        getContainersQuickStats,
        getCheckAndBalanceStats,
        getSTDQuickStatsAndTotal,
        getDepotQuickStatsAndTotal,
        getInvoicingStats,
        setDriversSelected
    };
};

export default useDashboard;
