import React, { useState } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    withMobileDialog,
    Grid,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Icon,
    Menu,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import { _time } from 'std';
import { LOGGED_COLLECTIONS } from '../../../../constants';
import useSystemHistory from './hook/useSystemHistory';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import LogTable from 'containers/Operators/Logs/LogTable';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import moment from 'moment-timezone';
import DbLogs from 'containers/Operators/DbLogs';

function SystemHistoryDialog(props) {
    const { http, theme, open, onClose, accountIds, fullScreen, auth, lockToCustomer } = props;
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [selectedLog, setSelectedLog] = useState();

    const [query, setQuery] = useState({
        accountIds,
        // changed: '',
        collection: ['bulks', 'charities', 'pickups', 'redemptions', 'users'],
        operation: ['i', 'u', 'd'],
        systemEvents: 'show',
        page: 0
    });

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment('2018-01-01T07:00:00.000Z'),
        initEndVal: moment().endOf('day'),
        initDateWindow: 'allTime'
    });

    const { pairedData, loading, handleSearch } = useSystemHistory({
        http,
        query,
        startDate,
        endDate
    });

    const handleChange = e => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const handlePrev = () => {
        setQuery({ ...query, page: query.page - 1 });
    };

    const handleNext = () => {
        setQuery({ ...query, page: query.page + 1 });
    };

    const inputStyle = { margin: theme.spacing.unit / 2 };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {children}
                </div>
            );
        }
    };

    const buttonStyle = { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit, textAlign: 'right' };
    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                style: {
                    height: fullScreen ? '100vh' : '90vh'
                }
            }}
        >
            <DialogContent style={{ paddingBottom: 0 }}>
                <DbLogs auth={auth} lockToCustomer={lockToCustomer} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
    // return (
    //     <Dialog
    //         open={open}
    //         fullScreen={fullScreen}
    //         fullWidth
    //         maxWidth="lg"
    //         PaperProps={{
    //             style: {
    //                 height: fullScreen ? '100vh' : '90vh'
    //             }
    //         }}
    //     >
    //         <DialogContent style={{ paddingBottom: 0 }}>
    //             <Grid container spacing={8}>
    //                 <Grid item xs={12}>
    //                     <Paper
    //                         style={{
    //                             padding: theme.spacing.unit,
    //                             height: '100%'
    //                         }}
    //                     >
    //                         {renderSearch(
    //                             <div
    //                                 style={{
    //                                     display: 'flex',
    //                                     justifyContent: 'space-between',
    //                                     flexWrap: 'wrap',
    //                                     alignItems: 'center',
    //                                     width: '100%'
    //                                 }}
    //                             >
    //                                 <div
    //                                     style={{
    //                                         display: 'flex',
    //                                         alignItems: 'flex-end',
    //                                         flexWrap: 'wrap',
    //                                         padding: theme.spacing.unit / 2
    //                                     }}
    //                                 >
    //                                     <DateRangePicker
    //                                         timezone={timezone}
    //                                         startDate={startDate}
    //                                         endDate={endDate}
    //                                         window={dateWindow}
    //                                         disabled={loading}
    //                                         handlePrevious={handleGoBackwards}
    //                                         handleNext={handleGoForwards}
    //                                         handleChangeStartDate={handleChangeStartDate}
    //                                         handleChangeEndDate={handleChangeEndDate}
    //                                     />
    //                                     <FormControl>
    //                                         <Select
    //                                             value={dateWindow}
    //                                             disabled={loading}
    //                                             onChange={handleWindowSelect}
    //                                             style={{
    //                                                 maxWidth: '250px'
    //                                             }}
    //                                         >
    //                                             <MenuItem value="today">Daily</MenuItem>
    //                                             <MenuItem value="thisWeek">Weekly</MenuItem>
    //                                             <MenuItem value="thisMonth">Monthly</MenuItem>
    //                                             <MenuItem value="thisQuarter">Quarterly</MenuItem>
    //                                             <MenuItem value="allTime">All Time</MenuItem>
    //                                         </Select>
    //                                     </FormControl>
    //                                 </div>
    //                                 {/* <TextField
    //                                     data-cy="logs-source-input"
    //                                     value={query.source}
    //                                     type="search"
    //                                     name="source"
    //                                     variant="outlined"
    //                                     label="Source"
    //                                     onChange={handleChange}
    //                                     style={inputStyle}
    //                                 />
    //                                 <TextField
    //                                     data-cy="logs-target-input"
    //                                     value={query.target}
    //                                     type="search"
    //                                     name="target"
    //                                     variant="outlined"
    //                                     label="Target"
    //                                     onChange={handleChange}
    //                                     style={inputStyle}
    //                                 />
    //                                 <TextField
    //                                     data-cy="logs-changed-input"
    //                                     value={query.changed}
    //                                     type="search"
    //                                     name="changed"
    //                                     variant="outlined"
    //                                     label="Changed"
    //                                     onChange={handleChange}
    //                                     style={inputStyle}
    //                                 /> */}
    //                                 <div
    //                                     style={{
    //                                         display: 'flex',
    //                                         alignItems: 'center',
    //                                         flexWrap: 'wrap',
    //                                         padding: theme.spacing.unit / 2
    //                                     }}
    //                                 >
    //                                     <FormControl variant="outlined">
    //                                         <InputLabel htmlFor="collection">Collection</InputLabel>
    //                                         <Select
    //                                             multiple
    //                                             input={
    //                                                 <OutlinedInput id="collection" name="collection" labelWidth={75} />
    //                                             }
    //                                             value={query.collection}
    //                                             onChange={handleChange}
    //                                             style={{ ...inputStyle, width: 125 }}
    //                                         >
    //                                             {LOGGED_COLLECTIONS.map(collection => (
    //                                                 <MenuItem key={collection} value={collection}>
    //                                                     {collection.replace(/^\w/, c => c.toUpperCase())}
    //                                                 </MenuItem>
    //                                             ))}
    //                                         </Select>
    //                                     </FormControl>
    //                                     <FormControl variant="outlined">
    //                                         <InputLabel htmlFor="operation">Operation</InputLabel>
    //                                         <Select
    //                                             multiple
    //                                             inputProps={{ id: 'operation', name: 'operation' }}
    //                                             input={
    //                                                 <OutlinedInput id="operation" name="operation" labelWidth={75} />
    //                                             }
    //                                             value={query.operation}
    //                                             onChange={handleChange}
    //                                             style={{ ...inputStyle, width: 125 }}
    //                                         >
    //                                             <MenuItem value="i">Insert</MenuItem>
    //                                             <MenuItem value="u">Update</MenuItem>
    //                                             <MenuItem value="d">Delete</MenuItem>
    //                                             <MenuItem value="l">Login</MenuItem>
    //                                         </Select>
    //                                     </FormControl>
    //                                     <FormControl variant="outlined">
    //                                         <InputLabel htmlFor="systemEvents">System Events</InputLabel>
    //                                         <Select
    //                                             inputProps={{ id: 'systemEvents', name: 'systemEvents' }}
    //                                             input={
    //                                                 <OutlinedInput
    //                                                     id="systemEvents"
    //                                                     name="systemEvents"
    //                                                     labelWidth={105}
    //                                                 />
    //                                             }
    //                                             value={query.systemEvents}
    //                                             onChange={handleChange}
    //                                             style={{ ...inputStyle, width: 125 }}
    //                                             disabled={!_.isEmpty(query.source)}
    //                                         >
    //                                             <MenuItem value="show">Show</MenuItem>
    //                                             <MenuItem value="hide">Hide</MenuItem>
    //                                             <MenuItem value="only">Only</MenuItem>
    //                                         </Select>
    //                                     </FormControl>
    //                                     <Button
    //                                         color="primary"
    //                                         variant="contained"
    //                                         data-cy="config-logs-search-button"
    //                                         type="submit"
    //                                         onClick={handleSearch}
    //                                     >
    //                                         <Icon>search</Icon>
    //                                     </Button>
    //                                 </div>
    //                             </div>
    //                         )}
    //                         {collapseSearch && (
    //                             <div style={{ textAlign: 'right' }}>
    //                                 <IconButton onClick={handleFiltersOpen()}>
    //                                     <Icon>filter_list</Icon>
    //                                 </IconButton>
    //                             </div>
    //                         )}
    //                     </Paper>
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                     {!_.isEmpty(pairedData) ? (
    //                         <Paper style={{ padding: theme.spacing.unit }}>
    //                             {loading ? (
    //                                 <div style={{ display: 'flex', justifyContent: 'center' }}>
    //                                     <CircularProgress />
    //                                 </div>
    //                             ) : (
    //                                 <div style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
    //                                     <LogTable data={pairedData} setSelectedLog={setSelectedLog} />
    //                                 </div>
    //                             )}
    //                             <div style={buttonStyle}>
    //                                 <Button onClick={handlePrev} disabled={query.page === 0}>
    //                                     Prev
    //                                 </Button>
    //                                 <Button
    //                                     onClick={handleNext}
    //                                     disabled={_.isEmpty(pairedData) || _.get(pairedData, 'length', 0) < 25}
    //                                 >
    //                                     Next
    //                                 </Button>
    //                             </div>
    //                         </Paper>
    //                     ) : (
    //                         loading && (
    //                             <div style={{ display: 'flex', justifyContent: 'center' }}>
    //                                 <CircularProgress />
    //                             </div>
    //                         )
    //                     )}
    //                 </Grid>
    //             </Grid>
    //         </DialogContent>
    //         <DialogActions>
    //             <Button onClick={onClose} color="primary">
    //                 Dismiss
    //             </Button>
    //         </DialogActions>
    //         <CustomDebugDialog open={!_.isNil(selectedLog)} json={selectedLog} onClose={() => setSelectedLog()} />
    //     </Dialog>
    // );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(SystemHistoryDialog));
