import React, { useContext } from 'react';

import { Typography, Paper, withTheme } from '@material-ui/core';

import * as terms from 'localizations/terms';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

function NewsLetterDisplay({ referralLinkComponent, theme }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                {loc('growthNewsLetters1', lang)}
            </Typography>

            <Paper style={{ padding: theme.spacing.unit }}>
                <Typography variant="body1">
                    <b>
                        {isEXPRegion()
                            ? loc('growthNewsLetters2EXP', lang, { brand: process.env.REACT_APP_BRAND_NAME })
                            : loc('growthNewsLetters2', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
                    </b>
                    <br />
                    <br />
                    {isEXPRegion()
                        ? loc('growthNewsLetters3EXP', lang, { brand: process.env.REACT_APP_BRAND_NAME })
                        : loc('growthNewsLetters3', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
                    <ol>
                        <li>
                            {isEXPRegion()
                                ? loc('growthNewsLetters4EXPa', lang, { website: process.env.REACT_APP_ORIGIN_URL })
                                : loc('growthNewsLetters4', lang)}{' '}
                            {isEXPRegion() ? (
                                <div>
                                    {loc('growthNewsLetters4EXPb', lang, { website: process.env.REACT_APP_ORIGIN_URL })}
                                </div>
                            ) : (
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {referralLinkComponent}
                                </div>
                            )}
                        </li>
                        <li>
                            {isEXPRegion() ? loc('growthNewsLetters5EXPa', lang) : loc('growthNewsLetters5', lang)}
                            {isEXPRegion() && (
                                <>
                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {referralLinkComponent}
                                    </div>
                                    {loc('growthNewsLetters5EXPb', lang)}
                                </>
                            )}
                        </li>
                        <li>{isEXPRegion() ? loc('growthNewsLetters6EXP', lang) : loc('growthNewsLetters6', lang)}</li>
                    </ol>
                    {!isEXPRegion() &&
                        loc('growthNewsLetters7', lang, {
                            brand: process.env.REACT_APP_BRAND_NAME,
                            organization: terms.ORGANIZATION_NAME.toLowerCase()
                        })}
                </Typography>
            </Paper>
        </>
    );
}

export default withTheme()(NewsLetterDisplay);
