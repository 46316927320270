import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';
import Badge from '../../Badge/Badge';

import InlineChip from 'components/Chips/InlineChip';
import { colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

class EditPickupSubwidget extends Component {
    static contextType = LocalizationContext;
    render() {
        const {
            theme,
            pickup,
            charities,
            clicksAllowed,
            onPickupConfirmationDialog,
            customerId,
            schemeId,
            charityEnabled,
            promptToLabelBags
        } = this.props;

        const { lang } = this.context;

        const highPriority = pickup.postponedBySystem > 0;

        const tdIconStyle = { lineHeight: 0, width: 32 };
        const iconStyle = { marginRight: theme.spacing.unit, fontSize: 18, display: 'flex', flexDirection: 'column' };
        const tdTextStyle = { paddingTop: 3 };
        const textStyle = { fontSize: 12 };

        const charityFromPickup = _.find(charities, charity => charity._id === pickup.charity);
        const charityName = !_.isNil(charityFromPickup) ? charityFromPickup.name : 'N/A';

        const timezone = _.get(pickup, 'zone.timezone', process.env.REACT_APP_REGION_TIMEZONE);

        return (
            <div
                id="edit-pickup-subwidget"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div
                    style={{
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        overflow: 'hidden'
                    }}
                >
                    <table style={{ borderSpacing: '0px 5px', tableLayout: 'fixed', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={tdIconStyle}>
                                    <Icon color="action" style={iconStyle}>
                                        calendar_today
                                    </Icon>
                                </td>
                                <td style={tdTextStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={textStyle} data-cy="edit-pickup-subwidget-date">
                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                {locDate(
                                                    pickup.date,
                                                    pickup.timeSlotBooking || pickup.manualTimeOverride
                                                        ? 'ddd, MMM Do h:mm A'
                                                        : 'ddd, MMM Do',
                                                    lang,
                                                    timezone
                                                )}{' '}
                                                {!_.isNil(pickup.customTime)
                                                    ? locDate(pickup.customTime, 'h:mm A', lang, timezone)
                                                    : ''}
                                            </span>
                                        </Typography>
                                        {highPriority && (
                                            <Badge
                                                style={{
                                                    backgroundColor: 'rgba(0, 0, 0, .54)',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Icon color="action" style={{ ...iconStyle, color: '#fff' }}>
                                                    star
                                                </Icon>
                                                <Typography
                                                    style={{ ...textStyle, color: '#fff' }}
                                                    data-cy="edit-pickup-subwidget-high-priority-badge"
                                                >
                                                    {loc('pickupWidgets17', lang)}
                                                </Typography>
                                            </Badge>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            {charityEnabled && (
                                <tr>
                                    <td style={tdIconStyle}>
                                        <Icon color="action" style={iconStyle}>
                                            favorite
                                        </Icon>
                                    </td>
                                    <td style={tdTextStyle}>
                                        {!_.isEmpty(pickup.charity) ? (
                                            <Typography style={textStyle} noWrap>
                                                {loc('pickupWidgets19', lang, {
                                                    charity: `${charityName} ${
                                                        !_.isNil(pickup.subdivision) ? `(${pickup.subdivision})` : ''
                                                    }`
                                                })}
                                            </Typography>
                                        ) : (
                                            <Typography color="textSecondary" style={textStyle}>
                                                {loc('pickupWidgets18', lang)}
                                            </Typography>
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    {(pickup.confirmed && promptToLabelBags) ||
                                    (!pickup.confirmed && numOfDaysDifference(new Date(), pickup.date) <= 14) ? (
                                        <Icon
                                            color="action"
                                            style={{
                                                ...iconStyle,
                                                color: pickup.confirmed ? undefined : colors.orange[500]
                                            }}
                                        >
                                            info
                                        </Icon>
                                    ) : null}
                                </td>
                                <td>
                                    {pickup.confirmed ? (
                                        promptToLabelBags && (
                                            <Typography variant="caption">
                                                {loc('pickupWidgets20', lang, {
                                                    id: !_.isNil(schemeId) ? schemeId : customerId
                                                })}
                                            </Typography>
                                        )
                                    ) : numOfDaysDifference(new Date(), pickup.date) <= 14 ? (
                                        <InlineChip
                                            color={colors.orange[500]}
                                            textColor="white"
                                            style={{
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '95%',
                                                color: theme.palette.common.white
                                            }}
                                            text={loc('pickupWidgets21', lang)}
                                            onClick={onPickupConfirmationDialog}
                                        />
                                    ) : null}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        borderLeft: '1px solid ' + theme.palette.text.hint,
                        paddingLeft: theme.spacing.unit,
                        paddingRight: theme.spacing.unit
                    }}
                >
                    <IconButton
                        data-cy="edit-pickup-subwidget-edit-button"
                        disabled={!clicksAllowed}
                        onClick={this.props.onPickupDialog}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withTheme()(EditPickupSubwidget);

function numOfDaysDifference(date1, date2) {
    const date1obj = new Date(date1);
    const date2obj = new Date(date2);
    const diffTime = Math.abs(date2obj - date1obj);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
