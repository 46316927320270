import React from 'react';

import _ from 'lodash';

import { Typography, withTheme } from '@material-ui/core';

import CustomGrid from '../Components/CustomGrid';
import { loc } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

function InstagramDisplay({ charity, referralLinkComponent, urlToLink, examples, socialMediaHandle, theme, lang }) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                <>
                    <ol>
                        <li>
                            {loc('growthGeneral13', lang)}
                            <div style={linkStyle}>
                                {charity.approved ? referralLinkComponent : loc('growthGeneral17', lang)}
                            </div>
                        </li>
                        <li>
                            {loc('growthGeneral14', lang)} {urlToLink(process.env.REACT_APP_INSTAGRAM_LINK)}
                        </li>
                        <li>{loc('growthGeneral15', lang)}</li>
                        <li>{loc('growthGeneral16', lang)}</li>
                    </ol>
                </>
            </Typography>

            <CustomGrid
                contentItems={
                    isEXPRegion()
                        ? [
                              {
                                  image: examples[0] ? examples[0] : null,
                                  text: (
                                      <>
                                          {loc('growthTwitterEXP1', lang)}
                                          <br />
                                          <br />
                                          {loc('growthTwitterEXP2', lang)}
                                          <br />
                                          <br />
                                          {loc('growthTwitterEXP3', lang)}
                                          <div style={linkStyle}>{referralLinkComponent}</div>
                                      </>
                                  )
                              },
                              {
                                  image: examples[1] ? examples[1] : null,
                                  text: (
                                      <>
                                          {loc('growthTwitterEXP4', lang)}
                                          <br />
                                          <br />
                                          {loc('growthTwitterEXP5', lang)}
                                          <br />
                                          <br />
                                          {loc('growthTwitterEXP3', lang)}
                                          <div style={linkStyle}>{referralLinkComponent}</div>
                                      </>
                                  )
                              },
                              {
                                  image: examples[2] ? examples[2] : null,
                                  text: (
                                      <>
                                          {loc('growthTwitterEXP6', lang)}
                                          <br />
                                          <br />
                                          {loc('growthTwitterEXP7', lang)}
                                          <div style={linkStyle}>{referralLinkComponent}</div>
                                      </>
                                  )
                              }
                          ]
                        : [
                              {
                                  image: examples[0] ? examples[0] : null,
                                  text: <>{loc('growthInstagram1', lang, { socialMediaHandle })}</>
                              },
                              {
                                  image: examples[1] ? examples[1] : null,
                                  text: <>{loc('growthInstagram2', lang, { socialMediaHandle })}</>
                              },
                              {
                                  image: examples[2] ? examples[2] : null,
                                  text: <>{loc('growthInstagram3', lang, { socialMediaHandle })}</>
                              },
                              {
                                  image: examples[3] ? examples[3] : null,
                                  text: (
                                      <>{loc('growthInstagram4', lang, { socialMediaHandle, charity: charity.name })}</>
                                  )
                              }
                          ]
                }
            />
        </>
    );
}

export default withTheme()(InstagramDisplay);
