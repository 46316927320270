import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { getEmailHeading } from 'containers/Operators/Pickups/BulkRescheduleDialog';
import { _pickup } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

const RESCHEDULE_EMAIL_BODY = {
    en: `If you need to make changes to your pickup, please use the app/website to confirm or modify your pickup. If you have any issues please reply to ${
        process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
    } - sorry for the inconvenience.`,
    fr: `Si vous devez apporter des modifications à votre ramassage, veuillez utiliser l'application/le site Web pour confirmer ou modifier votre ramassage. Si vous avez des problèmes, veuillez répondre à ${
        process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
    } - désolé pour le désagrément.`
};

function CancelPickupDialog({
    theme,
    open,
    fullScreen,
    cancelReason,
    handleCancelConfirmationDialog,
    handleCancelChange,
    handleCancel,
    unserviceable,
    customer,
    http,
    selectedPickup,
    onSnackbar,
    onReloadCustomer,
    handleCloseConfirmation,
    payload,
    subPayloads
}) {
    const [availableDates, setAvailableDates] = React.useState([]);
    const [showPickupSoonerDialog, setShowPickupSoonerDialog] = React.useState(false);
    const [showBookLaterDialog, setShowBookLaterDialog] = React.useState(false);
    const [newPickupDate, setNewPickupDate] = React.useState(null);

    const { lang } = useContext(LocalizationContext);

    const formControlStyle = {
        marginTop: theme.spacing.unit + theme.spacing.unit / 2
    };

    const cancelReasons = [
        { reason: 'I need a pickup sooner', id: 'cancelPickup3a' },
        { reason: 'I will re-book later', id: 'cancelPickup3b' },
        {
            reason: 'I gave the bags away',
            label: 'Who did you donate to',
            id: 'cancelPickup3c',
            labelId: 'cancelPickup6'
        },
        {
            reason: "I don't like this service",
            label: 'How can we do better',
            id: 'cancelPickup3d',
            labelId: 'cancelPickup5'
        },
        { reason: 'Other', label: 'Reason', id: 'cancelPickup3e', labelId: 'cancelPickup4' }
    ];
    React.useEffect(() => {
        getAvailableDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPickup]);
    return (
        <>
            <PickupSoonerDialog
                open={showPickupSoonerDialog}
                onClose={() => {
                    setShowPickupSoonerDialog(false);
                }}
                newPickupDate={newPickupDate}
                onCancel={event => {
                    handleCancel(event);
                    setShowPickupSoonerDialog(false);
                }}
                onConfirm={async () => {
                    await handleReschedule();
                    setShowPickupSoonerDialog(false);
                    if (!_.isNil(handleCloseConfirmation)) handleCloseConfirmation();
                }}
            />
            <BookLaterDialog
                open={showBookLaterDialog}
                onClose={() => {
                    setShowBookLaterDialog(false);
                }}
                selectedPickup={selectedPickup}
                onCancel={event => {
                    handleCancel(event);
                    setShowBookLaterDialog(false);
                }}
                onRebook={async () => {
                    await handleReschedule();
                    setShowBookLaterDialog(false);
                    if (!_.isNil(handleCloseConfirmation)) handleCloseConfirmation();
                }}
            />
            <Dialog fullWidth open={open} fullScreen={fullScreen} onClose={() => handleCancelConfirmationDialog(false)}>
                <DialogTitle style={{ paddingTop: 'max(24px, var(--custom-safe-area-top, 24px))' }}>
                    {loc('cancelPickup1', lang)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('cancelPickup2', lang)}</DialogContentText>
                    {!unserviceable && (
                        <FormControl
                            data-cy="pickup-dialog-cancel-reason"
                            fullWidth
                            style={{ marginTop: theme.spacing.unit / 2 }}
                        >
                            <InputLabel htmlFor="cancelReason">{loc('cancelPickup3', lang)}</InputLabel>
                            <Select
                                value={cancelReason.reason || ''}
                                input={<Input value={cancelReason.reason || ''} name="reason" id="cancelReason" />}
                                onChange={handleCancelChange}
                            >
                                {cancelReasons.map(function(cancel, i) {
                                    return (
                                        <MenuItem
                                            key={i}
                                            data-cy={'pickup-dialog-cancel-reason-' + i}
                                            value={cancel.reason}
                                        >
                                            {loc(cancel.id, lang)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            {_.get(_.find(cancelReasons, ['reason', cancelReason.reason]), 'label') && (
                                <FormControl style={formControlStyle} fullWidth>
                                    <TextField
                                        data-cy="pickup-dialog-cancel-reason-comments"
                                        name="comments"
                                        label={loc(
                                            _.get(_.find(cancelReasons, ['reason', cancelReason.reason]), 'labelId'),
                                            lang
                                        )}
                                        value={cancelReason.comments}
                                        variant="outlined"
                                        onChange={handleCancelChange}
                                    />
                                </FormControl>
                            )}
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        data-cy="pickup-dialog-cancel-no-button"
                        color="primary"
                        onClick={() => handleCancelConfirmationDialog(false)}
                    >
                        {loc('no', lang)}
                    </Button>
                    <Button
                        data-cy="pickup-dialog-cancel-yes-button"
                        color="primary"
                        disabled={
                            unserviceable
                                ? false
                                : _.isEmpty(cancelReason.reason) ||
                                  (_.get(_.find(cancelReasons, ['reason', cancelReason.reason]), 'label') &&
                                      _.isEmpty(cancelReason.comments))
                        }
                        onClick={event => {
                            if (cancelReason.reason === 'I need a pickup sooner' && !_.isEmpty(availableDates)) {
                                setNewPickupDate(availableDates[0]);
                                handleCancelConfirmationDialog(false);
                                setShowPickupSoonerDialog(true);
                            } else if (cancelReason.reason === 'I will re-book later') {
                                setNewPickupDate(getXdaysLater(_.get(selectedPickup, 'date', null), 28));
                                handleCancelConfirmationDialog(false);
                                setShowBookLaterDialog(true);
                            } else {
                                handleCancel(event);
                            }
                        }}
                    >
                        {loc('yes', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
    async function handleReschedule() {
        let form = {
            updateNextDates: false,
            confirmed: 'unchanged',
            sendNotifications: true,
            date: moment(newPickupDate),
            emailBody: RESCHEDULE_EMAIL_BODY
        };
        const timezone = selectedPickup.zone.timezone;

        const res = await http.post('/pickups/reschedulePickups', {
            pickup_ids: [selectedPickup._id],
            email: {
                en: getEmailHeading(theme, timezone, selectedPickup.date, form.date) + ' ' + form.emailBody['en'],
                fr:
                    getEmailHeading(theme, timezone, selectedPickup.date, form.date, null, false, 'fr') +
                    ' ' +
                    form.emailBody['fr']
            },
            timezone: form.date.tz(),
            ...form
        });

        if (res.ok) {
            onSnackbar(loc('cancelPickup15', lang));
            await confirmPickup();
            onReloadCustomer(true);
        } else {
            onSnackbar(loc('cancelPickup16', lang), 'error');
        }
    }

    async function getAvailableDates() {
        if (_.isNil(selectedPickup)) return;
        let url = `/zone/dates?lat=${selectedPickup.location.lat}&lng=${selectedPickup.location.lng}`;

        url += `&pickupType=${selectedPickup.pickupType}`;

        if (!_.isNil(customer)) {
            url += `&customer_id=${customer._id}`;
        }
        const res = await http.getJSON(url);
        let newAvailableDates = [];
        if (res.ok) {
            let currentBookedDate = new Date(_.get(selectedPickup, 'date', null));
            for (let i = 0; i < res.data.availableDates.length; i++) {
                let currentAvailableDate = new Date(res.data.availableDates[i]);
                if (currentAvailableDate.getTime() < currentBookedDate.getTime()) {
                    newAvailableDates.push(res.data.availableDates[i]);
                }
            }
            setAvailableDates(newAvailableDates);
        }
    }

    async function confirmPickup() {
        await http.post(`/pickups/${selectedPickup._id}/confirm`, { payload, subPayloads });
    }
}

export default withTheme()(CancelPickupDialog);

function PickupSoonerDialog(props) {
    const { open, onClose, onConfirm, onCancel, newPickupDate } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>{loc('cancelPickup7', lang)}</DialogTitle>
            <DialogContent>
                <Typography>{loc('cancelPickup8', lang, { date: locDate(newPickupDate, 'LL', lang) })}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onCancel} data-cy="pickup-sooner-dialog-no">
                    {loc('cancelPickup9', lang)}
                </Button>
                <Button color="primary" size="small" onClick={onConfirm}>
                    {loc('cancelPickup10', lang, { date: locDate(newPickupDate, 'LL', lang) })}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BookLaterDialog(props) {
    const { open, onClose, onCancel, onRebook, selectedPickup } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>{loc('cancelPickup12', lang)}</DialogTitle>
            <DialogContent>
                <Typography>{loc('cancelPickup13', lang, { brand: process.env.REACT_APP_BRAND_NAME })}</Typography>
            </DialogContent>
            <DialogActions style={{ flexDirection: 'column' }}>
                <Button color="default" size="small" onClick={onCancel}>
                    {loc('cancelPickup9', lang)}
                </Button>
                <Button color="primary" size="small" onClick={onClose}>
                    {loc('cancelPickup10', lang, { date: locDate(_.get(selectedPickup, 'date', null), 'LL', lang) })}
                </Button>
                {numOfDaysDifference(new Date(), _.get(selectedPickup, 'date', null)) <= 56 ? (
                    <Button color="primary" size="small" onClick={onRebook}>
                        {loc('cancelPickup14', lang, {
                            date: locDate(getXdaysLater(_.get(selectedPickup, 'date', null), 28), 'LL', lang)
                        })}
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
}

function numOfDaysDifference(date1, date2) {
    const date1obj = new Date(date1);
    const date2obj = new Date(date2);
    const diffTime = Math.abs(date2obj - date1obj);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
function getXdaysLater(date, x) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + x);
    return newDate;
}
