import React from 'react';

import { Typography, Paper, withTheme } from '@material-ui/core';

import * as terms from 'localizations/terms';
import { loc } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

function BlogDisplay({ charity, referralLinkComponent, lang }) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    return (
        <Typography variant="body1">
            {isEXPRegion() ? loc('growBlog1EXPa', lang) : loc('growBlog1', lang)}
            <br />
            <br />
            {isEXPRegion() && loc('growBlog1EXPb', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
            {isEXPRegion() ? (
                <ol>
                    <li>
                        {loc('growthNewsLetters4EXPa', lang, { website: process.env.REACT_APP_ORIGIN_URL })}
                        <div>{loc('growthNewsLetters4EXPb', lang, { website: process.env.REACT_APP_ORIGIN_URL })}</div>
                    </li>
                    <li>
                        {loc('growthNewsLetters5EXPa', lang)}
                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {referralLinkComponent}
                        </div>
                        {loc('growthNewsLetters5EXPb', lang)}
                    </li>
                    <li>{loc('growthNewsLetters6EXP', lang)}</li>
                </ol>
            ) : (
                <ul>
                    <li>
                        {loc('growBlog2', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}
                    </li>
                    <li>
                        {loc('growBlog3', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}
                    </li>
                    <li>
                        {loc('growBlog4', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}
                    </li>
                    <li>
                        {loc('growBlog5', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}
                    </li>
                </ul>
            )}
            {!isEXPRegion() && (
                <>
                    {loc('growBlog6', lang)}
                    <div style={linkStyle}>{referralLinkComponent}</div>
                </>
            )}
        </Typography>
    );
}

export default withTheme()(BlogDisplay);
