import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment-timezone';
import * as allIcons from '@mdi/js';

import {
    getQuickDropName,
    formatAsCurrency,
    getBulkCompletionDate,
    uppercaseFirstLetter,
    getCustomerName,
    isAUSRegion,
    getWalkInName,
    getCustomerFirstNameAndLastInitial,
    truncateBagtag,
    isEXPRegion,
    isCONRegion,
    getHomeMarkerPayloadString,
    getPlural,
    groupQuickDropBulks,
    convertToImportName
} from 'utils/misc';
import { API_PAYMENT_STATUS, EXPRESS_APP_BALANCE_SKUTYPE } from '../../../constants';
import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';
import * as terms from 'localizations/terms';

import CustomerReportDialog from 'components/Dialogs/Customer/CustomerReportDialog';
import BulkComplaintDialog from 'components/BulkComponents/BulkComplaintDialog';
import { HistoryWidget } from 'components/CustomerWidgets';
import BulkLedgerTable from 'components/BulkComponents/BulkLedgerTable';
import BulkCustomFeesTable from 'components/BulkComponents/BulkCustomFeesTable';
import PickupSummary from './PickupSummary';

import * as colors from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import MDIcon from '@mdi/react';

import PDFPreviewer from 'components/PDFComponents/PDFPreviewer';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import AUSLogo from 'icons/depot-login-header-AUS-white.png';
import STDLogo from 'icons/depot-login-header-STD-white.png';
import CONLogo from 'icons/depot-login-header-CON-white.png';
import EXPLogo from 'icons/encorp_logo_white.png';
import MXDLogo from 'icons/depot-login-header-MXD.png';

import { withTheme } from '@material-ui/core/styles';

import {
    mdiTruck,
    mdiRun,
    mdiTruckFast,
    mdiBallotRecountOutline,
    mdiCash100,
    mdiTagArrowDown,
    mdiCurrencyUsd,
    mdiHelpRhombus,
    mdiAlertDecagram,
    mdiProgressClock,
    mdiAccountSwitch,
    mdiReceipt,
    mdiAccountCash,
    mdiCalendarArrowRight,
    mdiCalendarArrowLeft,
    mdiHistory,
    mdiNoteText,
    mdiCurrencyUsdOff,
    mdiStore,
    mdiCashEdit,
    mdiQrcodeScan,
    mdiSack,
    mdiShield
} from '@mdi/js';

// helper functions
import bulkHelper from 'helpers/bulkHelper';
import { _pickup, _bulk, _rates, _commodity, _user } from 'std';
import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import ProcessingSummary from './ProcessingSummary';
import Reciept from './Reciept';
import DropOffSummary from './DropOffSummary';
import { Button, Chip, Divider, Tooltip } from '@material-ui/core';
import { openWindow } from 'helpers/windowHelper';
import { removePostalCodeAndCountryFromAddress } from 'helpers/locationHelper';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import useGetJSON from 'utils/hooks/useGetJSON';

/**
 * Assumptions:
 * - The pickups that are associated to bulks are assumed to be rolled up (ie. pickups.bulks are populated and not empty if the pickup is completed)
 *
 */
function History({
    theme,
    rates,
    redemptions,
    bulks,
    pickups,
    tips,
    customerHistory,
    commoditiesAvailable,
    taxReceipts,
    invoices,
    adminView,
    customer,
    taxGroups,
    reloadCustomer,
    disableInAppRedemptionCancel,
    http,
    auth,
    rolePermissions,
    pickupsEnabled,
    allRates
}) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [customerReportDialogOpen, setCustomerReportDialogOpen] = useState(false);
    const [reportedBulk, setReportedBulk] = useState();
    const [reportedPickup, setReportedPickup] = useState();

    const [complaintDialogOpen, setComplaintDialogOpen] = useState(false);
    const [complaintBulk, setComplaintBulk] = useState();
    const [complaintsDialogLoading, setComplaintsDialogLoading] = useState(false);
    const [complaintResolution, setComplaintResolution] = useState('');

    const [rescheduleItems, setRescheduleItems] = useState([]);

    const [donationAmountsByCharity, setDonationAmountsByCharity] = useState(new Map());

    const [clerkComplaints, setClerkComplaints] = useState([]);
    const [driverComplaints, setDriverComplaints] = useState([]);

    const [allowReceiptDownload, setAllowReceiptDownload] = useState(false);
    const [allowRedemptionReceiptDownload, setAllowRedemptionReceiptDownload] = useState(false);
    const [showRedemptionBreakdown, setShowRedemptionBreakdown] = useState(false);

    const [historyIcons, setHistoryIcons] = useState({});

    const [serviceEmail, setServiceEmail] = useState('');
    const [servicePhone, setServicePhone] = useState('');
    const [receiptAddress, setReceiptAddress] = useState('');

    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
    const [pdfUriStr, setPdfUriStr] = useState('');

    async function fetchHistoryConfig() {
        const res = await http.getJSON(`/system/configuration/history`);

        if (res.ok) {
            setAllowReceiptDownload(_.get(res, 'data.allowReceiptDownload'));
            setAllowRedemptionReceiptDownload(_.get(res, 'data.allowRedemptionReceiptDownload'));
            setShowRedemptionBreakdown(_.get(res, 'data.showRedemptionBreakdown'));
            setReceiptAddress(_.get(res, 'data.customerReceiptAddress', ''));
            setServiceEmail(_.get(res, 'data.email', ''));
            setServicePhone(_.get(res, 'data.phone', ''));
            setHistoryIcons(_.get(res, 'data.historyIcons', {}));
        }
    }

    // async function fetchReceiptPermissions() {
    //     const [res, res2, res3] = await Promise.all([
    //         http.getJSON(`/system/configuration/allowReceiptDownload`),
    //         http.getJSON(`/system/configuration/allowRedemptionReceiptDownload`),
    //         http.getJSON(`/system/configuration/showRedemptionBreakdown`)
    //     ]);
    //     if (res.ok) {
    //         setAllowReceiptDownload(res.data.allowReceiptDownload);
    //     }
    //     if (res2.ok) {
    //         setAllowRedemptionReceiptDownload(res2.data.allowRedemptionReceiptDownload);
    //     }
    //     if (res3.ok) {
    //         setShowRedemptionBreakdown(res3.data.showRedemptionBreakdown);
    //     }
    // }

    // async function fetchCustomerReceiptAddress() {
    //     let res = await http.getJSON('/system/configuration/customerReceiptAddress');
    //     if (res.ok) {
    //         setReceiptAddress(_.get(res, 'data.customerReceiptAddress', ''));
    //     }
    // }
    // async function fetchServiceContactOptions() {
    //     let res = await http.getJSON('/system/configuration/serviceContactOptions');
    //     if (res.ok) {
    //         setServiceEmail(_.get(res, 'data.email', ''));
    //         setServicePhone(_.get(res, 'data.phone', ''));
    //     }
    // }
    // async function fetchHistoryIcons() {
    //     let res = await http.getJSON('/system/configuration/historyIcons');
    //     if (res.ok) {
    //         setHistoryIcons(_.get(res, 'data.historyIcons', {}));
    //     }
    // }
    useEffect(() => {
        fetchHistoryConfig();
        // fetchReceiptPermissions();
        // fetchServiceContactOptions();
        // fetchCustomerReceiptAddress();
        // fetchHistoryIcons();
    }, []);
    async function getUsableRedemptionReceiptCounter(date) {
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/getUsableRedemptionReceiptCounter', {
            date: orderDate,
            customer: _.get(customer, '_id')
        });
        if (res.ok) {
            return _.get(res, 'data.receiptCounter', 1);
        }
        return 1;
    }
    async function getExistingRedemptionReceiptNumber(date, redemption) {
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/getExistingRedemptionReceiptNumber', {
            date: orderDate,
            customer: _.get(customer, '_id'),
            redemption: redemption
        });
        return _.get(res, 'data.receiptNumber', null);
    }

    async function addRedemptionReceipt(receiptNumber, redemption, date) {
        let orderDate = new Date(new Date(date).toDateString());
        const res = await http.postJSON('/receipt/addRedemptionReceipt', {
            date: orderDate,
            receiptNumber,
            customer: _.get(customer, '_id'),
            redemption: redemption
        });
    }
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);

    const donatedStatus = loc('dashboardHistory6', lang);
    const countedStatus = loc('dashboardHistory14', lang);

    // const { loading: allRatesLoading, error: allRatesLoadingErr, data: allRates } = useGetJSON(
    //     `/`,
    //     'rates'
    // );

    const handleReloadData = () => {
        reloadCustomer().then(() => setLoading(false));
    };

    useEffect(() => {
        handleReloadData();
        setDonationAmountsByCharity(getDonationAmountsByCharity(bulks));
    }, [reloadCustomer]);

    useEffect(() => {
        (async () => {
            const [res, res2] = await Promise.all([
                http.getJSON(`/system/configuration/clerkComplaintOptions/${lang}`),
                http.getJSON(`/system/configuration/driverComplaintOptions/${lang}`)
            ]);
            if (res.ok && res2.ok) {
                setClerkComplaints(res.data.options);
                setDriverComplaints(res2.data.options);
            }
        })();
    }, [lang]);

    const handleGenerateRedemptionReceiptPDF = async (
        download = false,
        date,
        redemption,
        countsCombined,
        bulk,
        redeemedBulks
    ) => {
        let newReceiptCounter = await getUsableRedemptionReceiptCounter(date);
        let existingReceiptNumber = await getExistingRedemptionReceiptNumber(date, redemption._id);
        let newReceiptNumber = _.isNil(existingReceiptNumber)
            ? `${moment(date).format('YYYYMMDD')}-${customer.uniqueID}-${newReceiptCounter}-r`
            : existingReceiptNumber;
        let doc = new jsPDF({ orientation: '0' });
        doc.setFont('helvetica', 'normal');
        if (process.env.REACT_APP_REGION_EXT === 'STD') {
            doc.addImage(STDLogo, 'png', 14, 17, 54, 7);
        } else if (process.env.REACT_APP_REGION_EXT === 'AUS') {
            doc.addImage(AUSLogo, 'png', 20, 20, 42, 13);
        } else if (process.env.REACT_APP_REGION_EXT === 'CON') {
            doc.addImage(CONLogo, 'png', 15, 13, 52, 15);
        } else if (process.env.REACT_APP_REGION_EXT === 'EXP') {
            doc.addImage(EXPLogo, 'png', 20, 16, 42, 20);
        } else if (process.env.REACT_APP_REGION_EXT === 'MXD') {
            doc.addImage(MXDLogo, 'png', 14, 17, 54, 7);
        }
        doc.setFontSize(16);
        doc.text(process.env.REACT_APP_BRAND_NAME, 70, 20);
        doc.setFontSize(10);
        if (!_.isNil(receiptAddress) && !_.isEmpty(receiptAddress)) {
            doc.text(receiptAddress, 70, 25);
        }
        doc.setFontSize(20);
        doc.text(`Redemption Receipt`, 130, 20);
        doc.setFontSize(10);
        if (!_.isNil(servicePhone) && !_.isEmpty(servicePhone)) {
            doc.text(servicePhone, 70, 40);
        }
        if (!_.isNil(serviceEmail) && !_.isEmpty(serviceEmail)) {
            doc.text(serviceEmail, 70, 45);
        }
        doc.line(15, 50, 195, 50);

        doc.setFont('helvetica', 'bold');
        doc.text(`Redeemed By`, 15, 60);

        doc.setFont('helvetica', 'normal');
        doc.text(`${customer.name.first} ${customer.name.last}`, 22, 65);
        doc.text(`${customer.location.description}`, 22, 70);
        doc.text(`${customer.location.city}, ${customer.location.province} ${customer.location.postalCode}`, 22, 75);
        doc.setFont('helvetica', 'bold');
        doc.text(`Receipt Number`, 135, 60);
        doc.setFont('helvetica', 'normal');
        doc.text(newReceiptNumber, 142, 65);
        doc.setFont('helvetica', 'bold');
        doc.text(`Date`, 135, 70);
        doc.setFont('helvetica', 'normal');
        doc.text(`${moment(date).format('M/D/YYYY')}`, 142, 75);
        let rows = [];
        countsCombined.forEach(count => {
            rows.push([
                {
                    content: count.fullDescriptionText,
                    dataKey: 'name',
                    styles: {
                        lineColor: 'black',
                        lineWidth: {
                            right: 0,
                            bottom: 0.25,
                            top: 0,
                            left: 0
                        }
                    }
                },
                {
                    content: count.quantity,
                    dataKey: 'value',
                    styles: {
                        textColor: ['NON', 'Non-conformant'].includes(count.materialType) ? 'red' : 'black',
                        lineColor: 'black',
                        lineWidth: {
                            right: 0,
                            bottom: 0.25,
                            top: 0,
                            left: 0
                        }
                    }
                }
            ]);
        });
        if (!_.isNil(redemption.fee) && redemption.fee !== 0) {
            rows.push([
                {
                    content: 'Fee',
                    dataKey: 'name',
                    styles: {
                        lineColor: 'black',
                        lineWidth: {
                            right: 0,
                            bottom: 0.25,
                            top: 0,
                            left: 0
                        }
                    }
                },
                {
                    content: formatAsCurrency(-redemption.fee / _.keys(redeemedBulks).length, lang),
                    dataKey: 'value',
                    styles: {
                        textColor: 'red',
                        lineColor: 'black',
                        lineWidth: {
                            right: 0,
                            bottom: 0.25,
                            top: 0,
                            left: 0
                        }
                    }
                }
            ]);
        }
        rows.push([
            {
                content: loc('value', lang),
                dataKey: 'name',
                styles: {
                    lineColor: 'black',
                    lineWidth: 0
                }
            },
            {
                content: formatAsCurrency(bulk.value, lang),
                dataKey: 'value',
                styles: {
                    textColor: 'green',
                    lineColor: 'black',
                    lineWidth: 0
                }
            }
        ]);

        doc.autoTable({
            startY: 80,
            columnStyles: {
                1: {
                    halign: 'right'
                }
            },
            body: rows,
            margin: { left: 30, right: 30 }
        });

        if (download) {
            doc.save(`Redemption Receipt ${moment(date).format('M_D_YYYY')}.pdf`);
        } else {
            setPdfUriStr(doc.output('datauristring', `Receipt ${date}`));
            setPdfViewerOpen(true);
        }
        addRedemptionReceipt(newReceiptNumber, redemption._id, date);
    };

    const handleViewCustomerReport = (bulk, pickup) => () => {
        if (!_.isNil(bulk) || !_.isNil(pickup)) {
            setReportedBulk(bulk);
            setReportedPickup(pickup);
            setCustomerReportDialogOpen(true);
        }
    };

    const handleCustomerReportDialog = state => () => {
        setCustomerReportDialogOpen(state);
    };

    const handleViewComplaintDialog = bulk => () => {
        if (!_.isNil(bulk)) {
            setComplaintBulk(bulk);
            setComplaintResolution(_.get(bulk, 'customerIssues.resolution', ''));
            setComplaintDialogOpen(true);
        }
    };

    const handleToggleResolveCustomerIssue = async () => {
        setComplaintsDialogLoading(true);

        const isResolved = _.isNil(_.get(complaintBulk, 'customerIssues.dateResolved', null));

        const res = await http.post(
            `/bulks/${complaintBulk._id}/resolve`,
            { isResolved, customerIssueResolution: isResolved ? complaintResolution : '' },
            true
        );

        if (res.ok) {
            handleReloadData();
            setComplaintDialogOpen(false);

            onSnackbar(`Successfully ${isResolved ? 'resolved' : 'unresolved'} customer issue`);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setComplaintsDialogLoading(false);
    };

    const ignoredSkusForTotals = _rates.getIgnoredSkusForTotals(rates);

    const quickdropBulks = useMemo(() => {
        return _(bulks)
            .filter(b => b.bulkType === 'inhouse')
            .sortBy('createdAt')
            .value();
    }, [bulks]);

    // filter out bulks that are not pickups or quickdrops as well as incomplete adujustments when not in admin view
    const standAloneBulks = useMemo(() => {
        return _(bulks)
            .filter(b => {
                if (_.isNil(b.pickup) && b.bulkType !== 'pickup' && b.bulkType !== 'inhouse') {
                    if (!adminView && b.bulkType === 'adjustment' && _.isNil(b.dateCompleted)) {
                        return false;
                    } else if (
                        !adminView &&
                        b.bulkType === 'adjustment' &&
                        b.skuType === EXPRESS_APP_BALANCE_SKUTYPE &&
                        isEXPRegion()
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            })
            .value();
    }, [bulks, adminView]);

    const pickupItems = useMemo(
        () =>
            pickups.map((pickup, index) => {
                const payloadCombinedStr = getHomeMarkerPayloadString(pickup, commoditiesAvailable, lang);

                const address = removePostalCodeAndCountryFromAddress(_.get(pickup, 'location.description'));
                const addressFilterVal = removePostalCodeAndCountryFromAddress(_.get(pickup, 'location.description'));
                const driverIssues = _.get(pickup, 'driverIssues', []);
                const payloadIssues = _.get(pickup, 'bulks[0].payloadIssues.issues', []);
                const payloadIssuesImages = _.get(pickup, 'bulks[0].payloadIssues.images', []);
                let imageUrls = _.get(pickup, 'images.pickupImages', []);

                const issues = getItemIssues({ driverIssues, payloadIssues, driverComplaints, clerkComplaints });
                const driverIssuesTxt = _.filter(issues, { type: 'DRIVER' });
                const payloadIssuesTxt = _.filter(issues, { type: 'PAYLOAD', suppressWarnings: false });

                const { hasResolvedComplaints, hasUnresolvedComplaints } = checkBulkComplaints(pickup.bulks);

                // iterate through the bulks and determine how many of the multiple bulks is counted
                const bulksCounted = _pickup.numberOfCountedBulksOnPickup(pickup);

                const allNonShowAsDonatedBulksCompleted = _.filter(pickup.bulks, b => {
                    let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
                    return !_.get(commodityForBulk, 'showAsDonatedInHistory', false);
                }).every(b => !_.isNil(b.dateCompleted));

                //used for messaging the collector as an admin
                const firstBulk = _.first(pickup.bulks);
                const bagtag = _.get(firstBulk, 'label', null);
                const employeeName = getCustomerName(pickup.receiver);
                const employeeId = _.get(pickup, 'receiver._id');

                // if one bulk has at least one ledger you can show it
                const bulkHasLedger = _.some(pickup.bulks, b => !_.isNil(b.ledger));
                const receiptViewEnabled = _.get(customer, 'allowViewReceipt', false);
                const showReceipt = bulkHasLedger && (adminView || receiptViewEnabled);

                // grab the total from the bulks
                const total = pickup.bulks.reduce((sum, bulk) => {
                    return (sum += _bulk.getCustomerAmount(bulk));
                }, 0);

                const hasDriverIssues = !_.isNil(driverIssues) && !_.isEmpty(driverIssues);

                let promosApplied = [];
                if (!_.isEmpty(pickup.bulks)) {
                    pickup.bulks.forEach(b => {
                        if (!_.isEmpty(b.promosApplied)) {
                            promosApplied = promosApplied.concat(b.promosApplied);
                        }
                    });
                } else {
                    promosApplied = _.get(pickup, 'promos', []);
                }

                let icon, color, primary, secondary, content, status;
                if (bulksCounted < 1) {
                    // if none of the bulks are counted
                    content = (
                        <div>
                            <PickupSummary
                                imageUrls={imageUrls}
                                pickup={pickup}
                                commoditiesAvailable={commoditiesAvailable}
                                customer={customer}
                                adminView={adminView}
                                handleViewCustomerReport={handleViewCustomerReport}
                                history={history}
                                auth={auth}
                                driverIssuesTxt={driverIssuesTxt}
                                rolePermissions={rolePermissions}
                            />
                        </div>
                    );
                } else if (bulksCounted >= 1) {
                    // if one or more bulks are counted
                    content = (
                        <>
                            <PickupSummary
                                imageUrls={imageUrls}
                                pickup={pickup}
                                commoditiesAvailable={commoditiesAvailable}
                                customer={customer}
                                adminView={adminView}
                                handleViewCustomerReport={handleViewCustomerReport}
                                history={history}
                                auth={auth}
                                driverIssuesTxt={driverIssuesTxt}
                                rolePermissions={rolePermissions}
                            />
                            <ProcessingSummary
                                pickup={pickup}
                                bulks={pickup.bulks}
                                customer={customer}
                                rates={rates}
                                allRates={allRates}
                                adminView={adminView}
                                commoditiesAvailable={commoditiesAvailable}
                                payloadIssuesTxt={payloadIssuesTxt}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                                history={history}
                                auth={auth}
                                onViewCustomerReport={handleViewCustomerReport}
                                onViewComplaintDialog={handleViewComplaintDialog}
                                rolePermissions={rolePermissions}
                                clerkComplaints={clerkComplaints}
                            />
                            {showReceipt && (
                                <Reciept
                                    pickup={pickup}
                                    bulks={pickup.bulks}
                                    customer={customer}
                                    allRates={allRates}
                                    adminView={adminView}
                                    commoditiesAvailable={commoditiesAvailable}
                                    date={displayDate(pickup)}
                                    employeeName={employeeName}
                                    address={address}
                                    collector={_.get(pickup, 'collector')}
                                    taxGroups={taxGroups}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    serviceEmail={serviceEmail}
                                    servicePhone={servicePhone}
                                    receiptAddress={receiptAddress}
                                    allowReceiptDownload={allowReceiptDownload}
                                />
                            )}
                        </>
                    );
                }

                // 3 possible states of a pickup [pending, completed, or aborted]
                if (pickup.aborted) {
                    let cancelledBy = '';
                    if (_user.isCollectorEmployee(pickup.receiver)) {
                        cancelledBy = loc('driver', lang);
                    } else if (_user.isSystemAdmin(pickup.receiver)) {
                        cancelledBy = loc('admin', lang);
                    }
                    status = loc('dashboardHistory10', lang);
                    icon = (
                        <MDIcon
                            path={_.get(
                                allIcons,
                                convertToImportName(_.get(historyIcons, 'pickupAborted.icon', 'dotsHorizontal'))
                            )}
                            size={1}
                            color={theme.palette.background.default}
                        />
                    );
                    color = _.get(historyIcons, 'pickupAborted.color', colors.grey[200]);
                    secondary = (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{loc('dashboardHistory27', lang, { cancelledBy })}</span>{' '}
                            <PromoCodeChip theme={theme} promosApplied={promosApplied} />
                        </span>
                    );
                    imageUrls = _.get(pickup, 'images.abortImages', []);

                    if (total !== 0) {
                        secondary = <FinancialInfo bulks={pickup.bulks} theme={theme} lang={lang} />;
                    }
                } else if (_.isNil(pickup.completionDate)) {
                    status = loc('dashboardHistory11', lang);
                    secondary = (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{payloadCombinedStr}</span>
                            <PromoCodeChip theme={theme} promosApplied={promosApplied} />
                        </span>
                    );
                    icon = (
                        <MDIcon
                            path={_.get(
                                allIcons,
                                convertToImportName(_.get(historyIcons, 'pickupCompleted.icon', 'dotsHorizontal'))
                            )}
                            size={1}
                            color={theme.palette.background.default}
                        />
                    );
                    color = _.get(historyIcons, 'pickupCompleted.color', colors.grey[200]);
                } else {
                    icon = (
                        <MDIcon
                            path={_.get(
                                allIcons,
                                convertToImportName(_.get(historyIcons, 'pickupPending.icon', 'dotsHorizontal'))
                            )}
                            size={1}
                            color={theme.palette.background.default}
                        />
                    );
                    color = _.get(historyIcons, 'pickupPending.color', colors.grey[200]);

                    if (total !== 0) {
                        secondary = <FinancialInfo bulks={pickup.bulks} theme={theme} lang={lang} />;
                    }

                    if (allNonShowAsDonatedBulksCompleted) {
                        //if pickup has bulks use them to determine if a pickup was actually donated (e.g. donated later on redemption
                        //or donation was removed because the bulks value was negative
                        if (_.isNil(pickup.bulks) || _.isEmpty(pickup.bulks)) {
                            status = _.isNil(pickup.charity) ? countedStatus : donatedStatus;
                        } else if (_.some(pickup.bulks, b => !_.isNil(b.charity))) {
                            status = donatedStatus;
                        } else {
                            status = countedStatus;
                        }
                    } else {
                        // if some of the bulks are counted, then display
                        if (bulksCounted > 0 && bulksCounted < pickup.bulks.length) {
                            icon = (
                                <MDIcon
                                    path={_.get(
                                        allIcons,
                                        convertToImportName(
                                            _.get(historyIcons, 'pickupProcessing.icon', 'dotsHorizontal')
                                        )
                                    )}
                                    size={1}
                                    color={theme.palette.background.default}
                                />
                            );
                            color = _.get(historyIcons, 'pickupProcessing.color', colors.grey[200]);
                            status = loc('dashboardHistory12', lang);
                            secondary = (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{payloadCombinedStr}</span>
                                    <PromoCodeChip theme={theme} promosApplied={promosApplied} />
                                </span>
                            );
                        } else {
                            status = loc('dashboardHistory13', lang);
                            secondary = (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{payloadCombinedStr}</span>
                                    <PromoCodeChip theme={theme} promosApplied={promosApplied} />
                                </span>
                            );
                        }
                    }
                }

                primary = (
                    <span>
                        {loc('pickup', lang)} {status}
                    </span>
                );

                const date = displayDate(pickup);

                return {
                    id: `customer-history-pickup-${index}`,
                    key: pickup._id,
                    type: 'PICKUP',
                    timezone: pickup.location.timezone,
                    color,
                    icon,
                    date,
                    primary,
                    secondary,
                    content,
                    imageUrls,
                    address,
                    addressFilterVal,
                    issues,
                    hasDriverIssues,
                    payloadIssuesImages,
                    hasUnresolvedComplaints,
                    hasResolvedComplaints,
                    bagtag: !_.isNil(bagtag) ? truncateBagtag(bagtag) : '',
                    bagtags: !_.isNil(bagtag) ? [truncateBagtag(bagtag)] : '',
                    employeeName,
                    employeeId,
                    customerUniqueID: customer.uniqueID,
                    bulkType: 'pickup',
                    collector: _.get(pickup, 'collector', null),
                    payload: _pickup.getBeveragePayloadFormated(pickup),
                    aborted: _.get(pickup, 'aborted', false),
                    pickupCompleted: !_.isNil(pickup.completionDate)
                };
            }),
        [
            adminView,
            pickups,
            theme,
            commoditiesAvailable,
            auth,
            clerkComplaints,
            countedStatus,
            customer,
            donatedStatus,
            lang,
            driverComplaints,
            history,
            rates,
            rolePermissions,
            taxGroups
        ]
    );

    const quickdropItems = useMemo(() => {
        const groupedQuickdropBulks = groupQuickDropBulks(quickdropBulks, adminView);

        return (groupedQuickdropBulks || []).map((bulkGroup, index) => {
            const combinedPayloadItems = bulkGroup.map(bulk => {
                let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === bulk.skuType);
                return {
                    amount: _.get(bulk, 'commoditiesProcessed', 0),
                    unit: _.get(commodityForBulk, `units.${lang}`)
                };
            });

            const groupedPayloadItems = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

            const payloadStringParts = Object.keys(groupedPayloadItems).map(itemKey => {
                const count = groupedPayloadItems[itemKey];
                const translatedItemKey = loc(itemKey, lang) || itemKey;
                return `${count} ${count > 1 ? getPlural(translatedItemKey) : translatedItemKey}`;
            });
            const payloadCombinedStr = payloadStringParts.join(', ');
            let bulkType = 'INHOUSE';
            const beverageBulks = _.filter(bulkGroup, bulk => bulk.skuType === 'beverage');
            const firstBulk = _.first(beverageBulks) || _.first(bulkGroup);

            const donated = !_.isNil(firstBulk.charity);
            const amount = bulkGroup.reduce((sum, bulk) => sum + _bulk.getCustomerAmount(bulk), 0);
            const countsCombined = bulkGroup.reduce((counts, bulk) => [...counts, ...getCombinedCounts(bulk)], []);

            const payloadIssues = _.get(firstBulk, 'payloadIssues.issues', null);
            const payloadIssuesImages = _.get(firstBulk, 'payloadIssues.images', []);

            const { hasResolvedComplaints, hasUnresolvedComplaints } = checkBulkComplaints(bulkGroup);

            //used for messaging the collector as an admin
            const bagtag = _.get(firstBulk, 'label', null);
            const firstCount = _.first(firstBulk.counts);
            const employeeName = !_.isNil(firstCount) ? getCustomerName(firstCount.operator) : '';

            /*let uom = _.get(bulk, 'commodityUOM', _bulk.getBulkUnitOfMeasurement(bulk));
            if (bulk.commodityAmount > 1) {
                uom += 's';
            }*/

            const hasIssues = !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);
            const issues = getItemIssues({ payloadIssues, driverComplaints, clerkComplaints });
            const payloadIssuesTxt = _.filter(issues, { type: 'PAYLOAD', suppressWarnings: false });

            const bulkHasLedger = _bulk.hasLedger(firstBulk);
            const receiptViewEnabled = _.get(customer, 'allowViewReceipt', false);
            const showReceipt = bulkHasLedger && (adminView || receiptViewEnabled);

            const date = getBulkCompletionDate(firstBulk);
            const timezone = _.get(firstBulk, 'pickup.location.timezone', process.env.REACT_APP_REGION_TIMEZONE); // for bulks with no pickup, we just use region timezone (should make sense for manual adjustments, but will need more thought for quick drop)
            // default use this for secondary

            let color, icon, title, primary, content, status, type;
            let adminOnly = false;
            type = bulkHelper.getBulkTypeFormatted(firstBulk, lang);
            title = uppercaseFirstLetter(loc(type, lang) || type);
            if (isCONRegion()) {
                title = uppercaseFirstLetter(loc(firstBulk.commodityUOM, lang));
            }

            const commodityName = loc(firstBulk.commodityUOM, lang) || firstBulk.commodityUOM;
            const commoditiesProcessed = firstBulk.commoditiesProcessed;
            const commodityAmount = firstBulk.commodityAmount;
            if (_bulk.isCompleted(firstBulk) && commoditiesProcessed > 0) {
                title = `${commoditiesProcessed} ${_.startCase(commodityName)}${commoditiesProcessed > 1 ? 's' : ''}`;
            } else if (commodityAmount > 0) {
                title = `${commodityAmount} ${title}${isCONRegion() ? '' : ' Bag'}${commodityAmount > 1 ? 's' : ''}`;
            } else if (
                _.get(firstBulk, 'autoComplete', false) &&
                _.isNil(commodityAmount) &&
                commoditiesProcessed > 0
            ) {
                title = `${commoditiesProcessed} ${title}${isCONRegion() ? '' : ' Bag'}${
                    commoditiesProcessed > 1 ? 's' : ''
                }`;
                adminOnly = true;
            }

            let secondary;
            let dropoffDistance = 'N/A';
            const imageUrls = _.get(firstBulk, 'quickdropConfirmationImages', []);
            if (
                _bulk.isQuickDrop(firstBulk) &&
                !_.isNil(firstBulk.customerLocation) &&
                !_.isNil(firstBulk.dropLocation)
            ) {
                dropoffDistance = `${(
                    getDistanceFromLatLonInKm(
                        firstBulk.dropLocation.location.lat,
                        firstBulk.dropLocation.location.lng,
                        firstBulk.customerLocation.lat,
                        firstBulk.customerLocation.lng
                    ) * 1000
                ).toFixed(2)}m`;

                secondary = payloadCombinedStr;
            } else {
                secondary = `${_.isNull(commoditiesProcessed) ? '?' : commoditiesProcessed} ${commodityName}${
                    _.isNull(commoditiesProcessed) || commoditiesProcessed > 1 ? 's' : ''
                }`;
            }

            secondary = payloadCombinedStr; // TODO: this overrides logic above. Probably a bug...

            if (!_bulk.isCompleted(firstBulk) && !adminView) {
                secondary = ''; // hakcyish fix
            }

            icon = (
                <MDIcon
                    path={_.get(allIcons, convertToImportName(_.get(historyIcons, 'quickdrop.icon', 'dotsHorizontal')))}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'quickdrop.color', colors.grey[200]);

            const dropLocationName = _bulk.getDropLocationName(firstBulk);
            const collectorName = _bulk.getCollectorName(firstBulk);
            const displayedName = !_.isEmpty(collectorName) ? collectorName : dropLocationName; // first choice should be Collector Name, not Technology (dropLocation) Name or Depot Code

            const address = removePostalCodeAndCountryFromAddress(
                _.get(
                    firstBulk,
                    'dropLocation.location.description',
                    _.get(firstBulk, 'collector.location.description')
                )
            );
            const addressAndLabel = _.isNil(address)
                ? null
                : _.isEmpty(displayedName)
                ? removePostalCodeAndCountryFromAddress(address)
                : `${removePostalCodeAndCountryFromAddress(address)} (${displayedName})`;
            const addressFilterVal = !_.isEmpty(dropLocationName)
                ? dropLocationName
                : !_.isEmpty(collectorName)
                ? collectorName
                : removePostalCodeAndCountryFromAddress(address);

            const bagtags = [truncateBagtag(firstBulk.label)];
            const countingSessions = firstBulk.countingSessions || [];
            for (let session of countingSessions) {
                if (session.bagtag) {
                    bagtags.push(session.bagtag);
                }
            }

            if (amount !== 0) {
                // if amount is over 0 then display it, otherwise display just the commodity amount
                secondary = <FinancialInfo bulks={bulkGroup} theme={theme} lang={lang} />;
            }

            /*let commodityType = bulk.skuType;
            let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === bulk.skuType);
            if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, bulk.skuType);
            }*/

            if (_bulk.isCompleted(firstBulk)) {
                // completed bulks can either be donated or not
                if (donated) {
                    status = donatedStatus;
                } else {
                    status = countedStatus;
                }
            } else {
                status = loc('dashboardHistory56', lang);
            }

            // As per GITLAB #3381 for History Items for Scanned events
            if (isEXPRegion() && status === loc('dashboardHistory56', lang)) {
                status = loc('dashboardHistory56Exp', lang);
            }

            if (_bulk.isCompleted(firstBulk)) {
                // dont show expandable content for promos and adjustments
                content = (
                    <>
                        <DropOffSummary
                            address={address}
                            bulks={bulkGroup}
                            customerHistories={customerHistory}
                            customer={customer}
                            adminView={adminView}
                            commoditiesAvailable={commoditiesAvailable}
                            handleViewCustomerReport={handleViewCustomerReport}
                            history={history}
                            auth={auth}
                        />
                        <ProcessingSummary
                            bulks={bulkGroup}
                            customer={customer}
                            rates={rates}
                            allRates={allRates}
                            adminView={adminView}
                            commoditiesAvailable={commoditiesAvailable}
                            payloadIssuesTxt={payloadIssuesTxt}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            history={history}
                            auth={auth}
                            onViewCustomerReport={handleViewCustomerReport}
                            onViewComplaintDialog={handleViewComplaintDialog}
                            rolePermissions={rolePermissions}
                            clerkComplaints={clerkComplaints}
                        />
                        {showReceipt && (
                            <Reciept
                                bulks={bulkGroup}
                                customer={customer}
                                allRates={allRates}
                                adminView={adminView}
                                commoditiesAvailable={commoditiesAvailable}
                                date={date}
                                employeeName={employeeName}
                                address={address}
                                collector={_.get(firstBulk, 'collector')}
                                taxGroups={taxGroups}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                                serviceEmail={serviceEmail}
                                servicePhone={servicePhone}
                                receiptAddress={receiptAddress}
                                allowReceiptDownload={allowReceiptDownload}
                            />
                        )}
                    </>
                );
            } else {
                // Uncounted bulk has issues
                if (hasIssues) {
                    // TODO: Review if processing summary should still be hidden here...
                    content = (
                        <>
                            <DropOffSummary
                                address={address}
                                bulks={bulkGroup}
                                customerHistories={customerHistory}
                                customer={customer}
                                adminView={adminView}
                                commoditiesAvailable={commoditiesAvailable}
                                handleViewCustomerReport={handleViewCustomerReport}
                                history={history}
                                auth={auth}
                            />
                            <ProcessingSummary
                                bulks={bulkGroup}
                                customer={customer}
                                rates={rates}
                                allRates={allRates}
                                adminView={adminView}
                                commoditiesAvailable={commoditiesAvailable}
                                payloadIssuesTxt={payloadIssuesTxt}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                                history={history}
                                auth={auth}
                                onViewCustomerReport={handleViewCustomerReport}
                                onViewComplaintDialog={handleViewComplaintDialog}
                                rolePermissions={rolePermissions}
                                clerkComplaints={clerkComplaints}
                            />
                        </>
                    );
                } else {
                    content = (
                        <>
                            <DropOffSummary
                                address={address}
                                bulks={bulkGroup}
                                customerHistories={customerHistory}
                                customer={customer}
                                adminView={adminView}
                                commoditiesAvailable={commoditiesAvailable}
                                handleViewCustomerReport={handleViewCustomerReport}
                                history={history}
                                auth={auth}
                            />
                            {adminView && (
                                <>
                                    <ProcessingSummary
                                        bulks={bulkGroup}
                                        customer={customer}
                                        rates={rates}
                                        allRates={allRates}
                                        adminView={adminView}
                                        commoditiesAvailable={commoditiesAvailable}
                                        payloadIssuesTxt={payloadIssuesTxt}
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                        history={history}
                                        auth={auth}
                                        onViewCustomerReport={handleViewCustomerReport}
                                        onViewComplaintDialog={handleViewComplaintDialog}
                                        rolePermissions={rolePermissions}
                                        clerkComplaints={clerkComplaints}
                                    />
                                </>
                            )}
                        </>
                    );
                }
            }

            primary = (
                <span>
                    {title} {status}
                    {adminOnly ? ' (Visible to admins only)' : ''}
                </span>
            );

            return {
                id: `customer-history-bulk-${index}`,
                key: firstBulk._id,
                type: bulkType,
                adjustment: firstBulk.adjustment,
                adjustmentReason: firstBulk.adjustment ? firstBulk.adjustmentReason : undefined,
                bagtags,
                color,
                icon,
                date,
                timezone,
                primary,
                secondary,
                content,
                payloadIssuesImages,
                issues,
                hasResolvedComplaints,
                hasUnresolvedComplaints,
                bagtag: !_.isNil(bagtag) ? truncateBagtag(bagtag) : '',
                employeeName,
                customerUniqueID: customer.uniqueID,
                bulkType: firstBulk.bulkType,
                collector: _.get(firstBulk, 'collector'),
                imageUrls,
                address: displayedName, // addressAndLabel,
                addressFilterVal,
                payload: bulkGroup.map(bulk => _bulk.getCommodityPayloadFormatted(bulk)).join(', ')
            };
        });
    }, [
        adminView,
        customer,
        quickdropBulks,
        lang,
        theme,
        commoditiesAvailable,
        auth,
        clerkComplaints,
        countedStatus,
        customerHistory,
        donatedStatus,
        driverComplaints,
        history,
        rates,
        rolePermissions,
        taxGroups
    ]);

    const bulkItems = useMemo(
        () =>
            (standAloneBulks || []).map((bulk, index) => {
                let bulkType = 'PICKUP';
                const donated = !_.isNil(bulk.charity);
                const amount = _bulk.getCustomerAmount(bulk);
                const countsCombined = getCombinedCounts(bulk);

                const payloadIssues = _.get(bulk, 'payloadIssues.issues', null);
                const payloadIssuesImages = _.get(bulk, 'payloadIssues.images', []);

                //used for messaging the collector as an admin
                const bagtag = _.get(bulk, 'label', null);
                const firstCount = _.first(bulk.counts);
                const employeeName = !_.isNil(firstCount) ? getCustomerName(firstCount.operator) : '';

                let uom = _.get(bulk, 'commodityUOM', _bulk.getBulkUnitOfMeasurement(bulk));
                if (_.isNull(bulk.commoditiesProcessed) || bulk.commoditiesProcessed > 1) {
                    uom += 's';
                }

                const hasIssues = !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);
                const issues = getItemIssues({ payloadIssues, driverComplaints, clerkComplaints });
                const payloadIssuesTxt = _.filter(issues, { type: 'PAYLOAD', suppressWarnings: false });

                const { hasResolvedComplaints, hasUnresolvedComplaints } = checkBulkComplaints([bulk]);

                const bulkHasLedger = _bulk.hasLedger(bulk);
                const receiptViewEnabled = _.get(customer, 'allowViewReceipt', false);
                const showReceipt = bulkHasLedger && (adminView || receiptViewEnabled);
                const displayDepositTax = _.get(customer, 'displayDepositTax', false);
                const depositTaxGroup = _.get(customer, 'depositTaxGroup', null);

                const date = getBulkCompletionDate(bulk);
                const timezone = _.get(bulk, 'pickup.location.timezone', process.env.REACT_APP_REGION_TIMEZONE); // for bulks with no pickup, we just use region timezone (should make sense for manual adjustments, but will need more thought for quick drop)
                // default use this for secondary

                let color, icon, title, primary, content, status, type;

                type = bulkHelper.getBulkTypeFormatted(bulk, lang);
                if (_bulk.isWalkIn(bulk)) {
                    title = getWalkInName(lang);
                } else {
                    title = uppercaseFirstLetter(type);
                }

                let secondary;
                let dropoffDistance = 'N/A';
                const imageUrls = _.get(bulk, 'quickdropConfirmationImages', []);
                if (_bulk.isQuickDrop(bulk) && !_.isNil(bulk.customerLocation) && !_.isNil(bulk.dropLocation)) {
                    dropoffDistance = `${(
                        getDistanceFromLatLonInKm(
                            bulk.dropLocation.location.lat,
                            bulk.dropLocation.location.lng,
                            bulk.customerLocation.lat,
                            bulk.customerLocation.lng
                        ) * 1000
                    ).toFixed(2)}m`;

                    secondary = `${_.isNull(bulk.commoditiesProcessed) ? '?' : bulk.commoditiesProcessed} ${
                        bulk.commodityUOM
                    }${(_.isNull(bulk.commoditiesProcessed) || bulk.commoditiesProcessed) > 1 ? 's' : ''} ${loc(
                        'history18',
                        lang
                    )} ${locDate(_bulk.getQuickdropDropOffDate(bulk), 'h:mma', lang)}`;
                } else {
                    secondary = `${_.isNull(bulk.commoditiesProcessed) ? '?' : bulk.commoditiesProcessed} ${
                        bulk.commodityUOM
                    }${_.isNull(bulk.commoditiesProcessed) || bulk.commoditiesProcessed > 1 ? 's' : ''}`;
                }

                switch (bulk.bulkType) {
                    case 'inhouse':
                        bulkType = 'INHOUSE';
                        icon = (
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(_.get(historyIcons, 'inhouseOrder.icon', 'dotsHorizontal'))
                                )}
                                size={1}
                                color={theme.palette.background.default}
                            />
                        );
                        color = _.get(historyIcons, 'inhouseOrder.color', colors.grey[200]);
                        break;
                    case 'walk-in':
                        bulkType = 'WALKIN';
                        icon = (
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(_.get(historyIcons, 'walkinOrder.icon', 'dotsHorizontal'))
                                )}
                                size={1}
                                color={theme.palette.background.default}
                            />
                        );
                        color = _.get(historyIcons, 'walkinOrder.color', colors.grey[200]);

                        break;
                    case 'adjustment':
                        bulkType = 'ADJUSTMENT';
                        icon = (
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(_.get(historyIcons, 'adjustmentOrder.icon', 'dotsHorizontal'))
                                )}
                                size={1}
                                color={theme.palette.background.default}
                            />
                        );
                        color = _.get(historyIcons, 'adjustmentOrder.color', colors.grey[200]);

                        if (_bulk.isPromoBulk(bulk)) {
                        } else if (_bulk.isPenaltyBulk(bulk)) {
                            color = colors.red[500];
                        }

                        break;
                    case 'promo':
                        icon = (
                            <MDIcon
                                path={_.get(
                                    allIcons,
                                    convertToImportName(_.get(historyIcons, 'promoOrder.icon', 'dotsHorizontal'))
                                )}
                                size={1}
                                color={theme.palette.background.default}
                            />
                        );
                        color = _.get(historyIcons, 'promoOrder.color', colors.grey[200]);
                        break;
                    default:
                        // old data should be pickups?
                        icon = <MDIcon path={mdiHelpRhombus} size={1} color={theme.palette.background.default} />;
                        color = colors.grey[200];
                        break;
                }

                const dropLocationName = _bulk.getDropLocationName(bulk);
                const collectorName = _.get(bulk, 'collector.name');
                const displayedName =
                    _bulk.isAdjustment(bulk) && !_.isNil(bulk.referralBulk)
                        ? loc('history19', lang, {
                              name: getCustomerFirstNameAndLastInitial(_.get(bulk, 'referralBulk.owner', {}))
                          })
                        : !_.isEmpty(dropLocationName)
                        ? dropLocationName
                        : collectorName;

                const address = removePostalCodeAndCountryFromAddress(
                    _.get(bulk, 'dropLocation.location.description', _.get(bulk, 'collector.location.description'))
                );

                const addressFilterVal = !_.isEmpty(dropLocationName)
                    ? dropLocationName
                    : !_.isNil(_.get(bulk, 'collector')) && !_.isEmpty(collectorName)
                    ? collectorName
                    : !_.isNil(_.get(bulk, 'collector'))
                    ? removePostalCodeAndCountryFromAddress(_.get(bulk, 'collector.location.description', ''))
                    : null;

                const bagtags = [truncateBagtag(bulk.label)];
                const countingSessions = bulk.countingSessions || [];
                for (let session of countingSessions) {
                    if (session.bagtag) {
                        bagtags.push(session.bagtag);
                    }
                }

                if (amount !== 0) {
                    // if amount is over 0 then display it, otherwise display just the commodity amount
                    secondary = <FinancialInfo bulks={[bulk]} theme={theme} lang={lang} />;
                }

                let commodityType = bulk.skuType;
                let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === bulk.skuType);
                if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                    commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, bulk.skuType);
                }

                if (_bulk.isCounted(bulk) || _bulk.isPenaltyBulk(bulk)) {
                    // dont show expandable content for promos and adjustments
                    if (_bulk.isPromoBulk(bulk) && !_.isNil(bulk.referralBulk) && _user.isSystemAdmin(auth)) {
                        content = (
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                                <Tooltip title="View Referred Customer">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        style={{
                                            marginRight: theme.spacing.unit * 2,
                                            padding: theme.spacing.unit * 0.5,
                                            minWidth: 32
                                        }}
                                        onClick={() => {
                                            openWindow(
                                                history,
                                                true,
                                                `/customers/${_.get(bulk, 'referralBulk.owner._id')}`
                                            );
                                        }}
                                    >
                                        <Icon fontSize="small">person</Icon>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="View Referral Bulk">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        style={{
                                            padding: theme.spacing.unit * 0.5,
                                            minWidth: 32
                                        }}
                                        onClick={() => {
                                            openWindow(
                                                history,
                                                true,
                                                `/operators/${auth._id}/bulks/${_.get(bulk, 'referralBulk._id')}`
                                            );
                                        }}
                                    >
                                        <Icon fontSize="small">ballot</Icon>
                                    </Button>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        content =
                            !_bulk.isCreditBulk(bulk) && !_bulk.isPromoBulk(bulk) ? (
                                <>
                                    <ProcessingSummary
                                        bulks={[bulk]}
                                        customer={customer}
                                        rates={rates}
                                        allRates={allRates}
                                        adminView={adminView}
                                        commoditiesAvailable={commoditiesAvailable}
                                        payloadIssuesTxt={payloadIssuesTxt}
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                        history={history}
                                        auth={auth}
                                        onViewCustomerReport={handleViewCustomerReport}
                                        onViewComplaintDialog={handleViewComplaintDialog}
                                        rolePermissions={rolePermissions}
                                        clerkComplaints={clerkComplaints}
                                    />
                                    {showReceipt && (
                                        <>
                                            <Reciept
                                                bulks={[bulk]}
                                                customer={customer}
                                                allRates={allRates}
                                                adminView={adminView}
                                                commoditiesAvailable={commoditiesAvailable}
                                                date={date}
                                                employeeName={employeeName}
                                                address={address}
                                                collector={_.get(bulk, 'collector')}
                                                taxGroups={taxGroups}
                                                style={{ marginTop: theme.spacing.unit * 2 }}
                                                serviceEmail={serviceEmail}
                                                servicePhone={servicePhone}
                                                receiptAddress={receiptAddress}
                                                allowReceiptDownload={allowReceiptDownload}
                                            />
                                        </>
                                    )}
                                </>
                            ) : null;
                    }
                    // counted bulks can either be donated or not
                    if (donated) {
                        status = donatedStatus;
                    } else {
                        // credit and promo bulks are credited, not counted
                        if (_bulk.isCreditBulk(bulk)) {
                            status = loc('dashboardHistory14', lang);
                        } else if (_bulk.isPromoBulk(bulk)) {
                            title = loc('dashboardHistory15', lang);
                            status = loc('dashboardHistory16', lang);
                            icon = (
                                <MDIcon
                                    path={_.get(
                                        allIcons,
                                        convertToImportName(
                                            _.get(historyIcons, 'referralPromoOrder.icon', 'dotsHorizontal')
                                        )
                                    )}
                                    size={1}
                                    color={theme.palette.background.default}
                                />
                            );
                            color = _.get(historyIcons, 'referralPromoOrder.color', colors.grey[200]);
                        } else if (_bulk.paidInCash(bulk)) {
                            status = loc('dashboardHistory17', lang);
                        } else {
                            status = countedStatus;
                        }
                    }
                } else {
                    status = loc('dashboardHistory18', lang);

                    // Uncounted bulk has issues
                    if (hasIssues) {
                        content = (
                            <>
                                <ProcessingSummary
                                    bulks={[bulk]}
                                    customer={customer}
                                    rates={rates}
                                    allRates={allRates}
                                    adminView={adminView}
                                    commoditiesAvailable={commoditiesAvailable}
                                    payloadIssuesTxt={payloadIssuesTxt}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    history={history}
                                    auth={auth}
                                    onViewCustomerReport={handleViewCustomerReport}
                                    onViewComplaintDialog={handleViewComplaintDialog}
                                    rolePermissions={rolePermissions}
                                    clerkComplaints={clerkComplaints}
                                />
                            </>
                        );
                    } else {
                        content = (
                            <>
                                {adminView ? (
                                    <>
                                        <ProcessingSummary
                                            bulks={[bulk]}
                                            customer={customer}
                                            rates={rates}
                                            allRates={allRates}
                                            adminView={adminView}
                                            commoditiesAvailable={commoditiesAvailable}
                                            payloadIssuesTxt={payloadIssuesTxt}
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                            history={history}
                                            auth={auth}
                                            onViewCustomerReport={handleViewCustomerReport}
                                            onViewComplaintDialog={handleViewComplaintDialog}
                                            rolePermissions={rolePermissions}
                                            clerkComplaints={clerkComplaints}
                                        />
                                    </>
                                ) : (
                                    <Typography>
                                        {loc('dashboardHistory20', lang)}:{' '}
                                        {!_.isNil(bagtag) ? truncateBagtag(bagtag) : ''}
                                    </Typography>
                                )}
                            </>
                        );
                    }
                }

                if (_bulk.isWalkIn(bulk)) {
                    primary = <span>{title}</span>;
                } else if (_bulk.isAdjustment(bulk) && _bulk.isCompleted(bulk)) {
                    if (bulk.skuType === EXPRESS_APP_BALANCE_SKUTYPE && isEXPRegion()) {
                        primary = (
                            <span>
                                {loc('openingBalanceOn', lang, { date: locDate(date, 'D MMMM YYYY', lang, timezone) })}
                            </span>
                        );
                        content = undefined; //don't show drop off summary, processing summary, reciept for opening balance bulks
                    } else {
                        primary = <span>{loc('accountAdjustment', lang)}</span>;
                    }
                } else {
                    primary = (
                        <span>
                            {title} {status}
                        </span>
                    );
                }

                return {
                    id: `customer-history-bulk-${index}`,
                    key: bulk._id,
                    type: bulkType,
                    adjustment: bulk.adjustment,
                    adjustmentReason: _bulk.isAdjustment(bulk) ? bulk.adjustmentReason : undefined,
                    bagtags,
                    color,
                    icon,
                    date,
                    timezone,
                    primary,
                    secondary,
                    content,
                    payloadIssuesImages,
                    issues,
                    hasResolvedComplaints,
                    hasUnresolvedComplaints,
                    bagtag: !_.isNil(bagtag) ? truncateBagtag(bagtag) : '',
                    employeeName,
                    customerUniqueID: customer.uniqueID,
                    bulkType: bulk.bulkType,
                    collector: _.get(bulk, 'collector'),
                    imageUrls,
                    address: displayedName,
                    addressFilterVal,
                    payload: _bulk.getCommodityPayloadFormatted(bulk),
                    referralBulk: _bulk.isAdjustment(bulk) ? _.get(bulk, 'referralBulk', null) : null
                };
            }),
        [
            adminView,
            customer,
            standAloneBulks,
            lang,
            theme,
            commoditiesAvailable,
            auth,
            clerkComplaints,
            countedStatus,
            donatedStatus,
            driverComplaints,
            history,
            rates,
            rolePermissions,
            taxGroups
        ]
    );

    useEffect(() => {
        if (adminView) {
            let rescheduleDetails = [];
            let index = 0;
            pickups.forEach(pickup => {
                _.get(pickup, 'rescheduleDetails', []).forEach(reschedule => {
                    let icon, color, primary, secondary, content;
                    icon = (
                        <MDIcon
                            path={_.get(
                                allIcons,
                                convertToImportName(
                                    _.get(
                                        historyIcons,
                                        `${
                                            moment(reschedule.oldDate).isBefore(moment(reschedule.newDate))
                                                ? 'rescheduleForward'
                                                : 'rescheduleBackward'
                                        }.icon`,
                                        'dotsHorizontal'
                                    )
                                )
                            )}
                            size={1}
                            color={theme.palette.background.default}
                        />
                    );
                    color = _.get(
                        historyIcons,
                        `${
                            moment(reschedule.oldDate).isBefore(moment(reschedule.newDate))
                                ? 'rescheduleForward'
                                : 'rescheduleBackward'
                        }.color`,
                        colors.grey[200]
                    );
                    primary = <span>Pickup Rescheduled</span>;
                    content = (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>Rescheduled by: </span>
                                {reschedule.initiator}
                            </Typography>
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>Reason: </span>
                                {reschedule.reason}
                            </Typography>{' '}
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>Dates: </span>
                                {moment(reschedule.oldDate)
                                    .locale(lang)
                                    .tz(pickup.location.timezone)
                                    .format('MMM Do, YYYY')}{' '}
                                ➡{' '}
                                {moment(reschedule.newDate)
                                    .locale(lang)
                                    .tz(pickup.location.timezone)
                                    .format('MMM Do, YYYY')}
                            </Typography>
                        </div>
                    );
                    secondary = (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                                {loc('dashboardHistory57', lang, {
                                    date: locDate(reschedule.newDate, 'MMM Do, YYYY', lang, pickup.location.timezone)
                                })}
                            </span>
                        </span>
                    );
                    let date = reschedule.dateRescheduled;
                    rescheduleDetails.push({
                        id: `customer-history-pickup-rescheduled-${index++}`,
                        key: reschedule._id,
                        type: 'PICKUP',
                        timezone: pickup.location.timezone,
                        color,
                        icon,
                        date,
                        primary,
                        secondary,
                        content,
                        address: removePostalCodeAndCountryFromAddress(_.get(pickup, 'location.description')),
                        addressFilterVal: removePostalCodeAndCountryFromAddress(_.get(pickup, 'location.description')),
                        employeeName: _.get(reschedule, 'initiator'),
                        customerUniqueID: customer.uniqueID,
                        bulkType: 'pickup',
                        payload: _pickup.getBeveragePayloadFormated(pickup),
                        aborted: _.get(pickup, 'aborted', false),
                        pickupCompleted: !_.isNil(pickup.completionDate)
                    });
                });
            });
            setRescheduleItems(rescheduleDetails);
        }
    }, [adminView, customer, pickups, lang, theme]);

    // added filter to prevent uncounted bulks from being shown
    const redemptionItems = redemptions.map((redemption, index) => {
        let donated = !_.isEmpty(redemption.charity);
        let redemptionMessage, redemptionTypeName, redemptionDestination, redemptionStatus;
        if (redemption.complete) {
            if (redemption.cancelled) {
                redemptionStatus = _.lowerCase(loc('dashboardHistory61', lang));
            } else {
                redemptionStatus = _.lowerCase(loc('dashboardHistory60', lang));
            }
        } else {
            if (_.get(redemption, 'apiStatus') === API_PAYMENT_STATUS.RECEIVED) {
                redemptionStatus = _.lowerCase(loc('issued', lang));
            } else {
                redemptionStatus = _.lowerCase(loc('requested', lang));
            }
        }

        switch (redemption.paymentModel) {
            case 'AUS_DIRECT_BANKING':
                redemptionTypeName = loc('ETRANSFERS_NAME_SHORT', lang);
                redemptionDestination = `${
                    redemption.cancelled || _.get(redemption, 'apiStatus') === API_PAYMENT_STATUS.RECEIVED
                        ? loc('for', lang)
                        : loc('to', lang)
                } ${_.get(redemption, 'banking.accountNumberMasked', 'N/A')}`;
                break;
            case 'INTERAC_ETRANSFERS':
                redemptionTypeName = loc('ETRANSFERS_NAME_SHORT', lang);
                redemptionDestination = `${
                    (redemption.complete && !redemption.cancelled) ||
                    _.get(redemption, 'apiStatus') === API_PAYMENT_STATUS.RECEIVED
                        ? loc('to', lang)
                        : loc('for', lang)
                } ${redemption.email}`;
                break;
            case 'CHEQUE':
                redemptionTypeName = loc('cheque', lang);
                redemptionDestination = `${loc('to', lang)} ${redemption.passphrase.split(' - ')[1]}`;
                break;
            case 'CASH':
                redemptionMessage = loc('history13', lang, { name: _user.getNameFirstNameAndLastInitial(customer) });
                redemptionStatus = null;
                break;
            case 'BANK_TRANSFER':
                redemptionTypeName = uppercaseFirstLetter(loc('redemption6', lang));
                redemptionDestination = `${loc('to', lang)} ${_.get(redemption, 'banking.accountNumberMasked', 'N/A')}`;
                break;
            case 'SCHEME_ID_REDEMPTION':
                redemptionTypeName = `EFT`;
                redemptionStatus = loc('sent', lang);
                redemptionDestination = `to scheme ID ${_.get(customer, 'schemeID', 'N/A')}`;
                break;
            default:
                redemptionMessage = '<unknown destination>'; // should never occur
        }

        const firstRedeemedBulk = _.find(bulks, { _id: _.first(_.get(redemption, 'bulks', [])) });

        let content = <></>;

        if (redemption.paymentModel !== 'SCHEME_ID_REDEMPTION') {
            if (!donated) {
                const redeemedBulks = {};

                const redemptionBulks = _.get(redemption, 'bulks', []);
                // Find all the bulks associated with the redemption
                redemptionBulks.forEach(bulk_id => {
                    const bulk = _.find(bulks, { _id: bulk_id, skuType: 'beverage' });
                    if (bulk) {
                        const location = _.get(bulk, 'pickup.location.place_id'); //NOTE: Will be undefined for adjustments, Drop&Gos and walk-ins
                        const value = _.isNil(bulk.ledger)
                            ? _.get(bulk, 'payments.owner.amount', 0)
                            : -1 * bulk.ledger.lines.reduce((sum, line) => (sum += line.amount), 0);
                        if (_.has(redeemedBulks, location)) {
                            redeemedBulks[location].counts = redeemedBulks[location].counts.concat(
                                _.get(bulk, 'counts', [])
                            );
                            redeemedBulks[location].value += value;
                        } else {
                            redeemedBulks[location] = {
                                counts: _.get(bulk, 'counts', []),
                                address: removePostalCodeAndCountryFromAddress(
                                    _.get(
                                        bulk,
                                        'pickup.location.description',
                                        process.env.REACT_APP_REGION_EXT !== 'AUS'
                                            ? loc('dashboardHistory33', lang, {
                                                  quickDropType: getQuickDropName(lang),
                                                  walkInType: getWalkInName(lang)
                                              })
                                            : 'Adjustments / Express / Walk-In'
                                    )
                                ),
                                value
                            };
                        }
                    }
                });

                content = (
                    <>
                        {_.map(Object.values(redeemedBulks), bulk => {
                            const countsCombined = getCombinedCounts(bulk);

                            return (
                                <Paper
                                    style={{
                                        padding: theme.spacing.unit * 2,
                                        borderRadius: theme.shape.borderRadius,
                                        backgroundColor: theme.palette.background.default,
                                        marginBottom: theme.spacing.unit * 2
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography style={{ marginTop: theme.spacing.unit }}>
                                            {bulk.address}
                                        </Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                        >
                                            {allowRedemptionReceiptDownload && (
                                                <Tooltip title="Download Receipt">
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        style={{
                                                            padding: theme.spacing.unit / 2,
                                                            margin: theme.spacing.unit / 2,
                                                            minWidth: 32
                                                        }}
                                                        onClick={() =>
                                                            handleGenerateRedemptionReceiptPDF(
                                                                true,
                                                                redemption.createdAt,
                                                                redemption,
                                                                countsCombined,
                                                                bulk,
                                                                redeemedBulks
                                                            )
                                                        }
                                                    >
                                                        <Icon
                                                            fontSize="small"
                                                            style={{ color: theme.palette.text.secondary }}
                                                        >
                                                            download
                                                        </Icon>
                                                    </Button>
                                                </Tooltip>
                                            )}

                                            {allowRedemptionReceiptDownload && (
                                                <Tooltip title="Print Receipt">
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        style={{
                                                            padding: theme.spacing.unit / 2,
                                                            margin: theme.spacing.unit / 2,
                                                            minWidth: 32
                                                        }}
                                                        onClick={() =>
                                                            handleGenerateRedemptionReceiptPDF(
                                                                false,
                                                                redemption.createdAt,
                                                                redemption,
                                                                countsCombined,
                                                                bulk,
                                                                redeemedBulks
                                                            )
                                                        }
                                                    >
                                                        <Icon
                                                            fontSize="small"
                                                            style={{ color: theme.palette.text.secondary }}
                                                        >
                                                            print
                                                        </Icon>
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                    <Table>
                                        <TableBody>
                                            {!_.isEmpty(countsCombined) &&
                                                countsCombined.map(count => (
                                                    <TableRow key={count.sku} style={{ height: 36 }}>
                                                        <TableCell
                                                            style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                color: ['NON', 'Non-conformant'].includes(
                                                                    count.materialType
                                                                )
                                                                    ? colors.red[500]
                                                                    : theme.palette.text.primary
                                                            }}
                                                        >
                                                            {count.fullDescription}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                color: ['NON', 'Non-conformant'].includes(
                                                                    count.materialType
                                                                )
                                                                    ? colors.red[500]
                                                                    : theme.palette.text.primary,
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            {count.quantity}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            {!_.isNil(redemption.fee) && redemption.fee !== 0 && (
                                                <TableRow style={{ height: 36 }}>
                                                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        Fee
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            fontWeight: 'bold',
                                                            color: colors.red['500'],
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        {formatAsCurrency(
                                                            -redemption.fee / _.keys(redeemedBulks).length,
                                                            lang
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow style={{ height: 36 }}>
                                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    {loc('value', lang)}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        fontWeight: 'bold',
                                                        color: colors.green['500'],
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {formatAsCurrency(bulk.value, lang)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            );
                        })}
                    </>
                );
            } else {
                if (_.get(redemption, 'charity.taxReceiptsIssued', false)) {
                    const requestedTaxRecipt = _.get(redemption, 'taxReceiptRequested', false);
                    if (requestedTaxRecipt) {
                        const redemptionBulks = _.get(redemption, 'bulks', []);
                        const notIssued = _.some(redemptionBulks, redeemedBulkId => {
                            return _.isNil(
                                _.find(taxReceipts, taxReceipt =>
                                    _.some(
                                        _.get(taxReceipt, 'bulks', []),
                                        issuedBulk => issuedBulk._id === redeemedBulkId
                                    )
                                )
                            );
                        });

                        let taxReceiptYearEndAdjusted;
                        let showNextIssuanceDate = false;

                        const taxReceiptYearEnd = _.get(redemption, 'charity.taxReceiptsEndOfYear');

                        if (taxReceiptYearEnd) {
                            const today = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);
                            const currentYear = today.year();

                            const redemptionCreatedDate = moment(redemption.createdAt).tz(
                                process.env.REACT_APP_REGION_TIMEZONE
                            );

                            taxReceiptYearEndAdjusted = moment(taxReceiptYearEnd)
                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                .set('year', currentYear);

                            if (today.isAfter(taxReceiptYearEndAdjusted)) {
                                taxReceiptYearEndAdjusted.set('year', currentYear + 1);
                            }

                            const taxReceiptYearStartAdjusted = moment(taxReceiptYearEndAdjusted).set(
                                'year',
                                taxReceiptYearEndAdjusted.year() - 1
                            );

                            if (
                                redemptionCreatedDate.isBefore(taxReceiptYearEndAdjusted) &&
                                redemptionCreatedDate.isAfter(taxReceiptYearStartAdjusted)
                            ) {
                                showNextIssuanceDate = true;
                            }
                        }

                        content = (
                            <>
                                {!_.isEmpty(redemptionBulks) && ( //some old redemptions don't have bulks saved to them, cannot determine if tax recipt issued for them
                                    <div
                                        data-cy={`donation-tax-receipt-details-${index}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: theme.palette.text.secondary
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                color: notIssued ? colors.orange[500] : colors.green[500]
                                            }}
                                        >
                                            info
                                        </Icon>
                                        {notIssued ? (
                                            showNextIssuanceDate ? (
                                                <Typography style={{ color: colors.orange[500] }}>
                                                    {loc('dashboardHistory34', lang)}{' '}
                                                    {locDate(taxReceiptYearEndAdjusted, 'MMMM Do YYYY', lang)}.
                                                </Typography>
                                            ) : (
                                                <Typography style={{ color: colors.orange[500] }}>
                                                    {donationAmountsByCharity.has(_.get(redemption, 'charity._id')) &&
                                                    donationAmountsByCharity.get(_.get(redemption, 'charity._id')) >
                                                        _.get(redemption, 'charity.minTaxReceiptAmount', 2000)
                                                        ? loc('dashboardHistory35', lang)
                                                        : loc('dashboardHistory36', lang, {
                                                              amount: formatAsCurrency(
                                                                  _.get(
                                                                      redemption,
                                                                      'charity.minTaxReceiptAmount',
                                                                      2000
                                                                  ) / 100,
                                                                  lang
                                                              )
                                                          })}
                                                </Typography>
                                            )
                                        ) : (
                                            <Typography style={{ color: colors.green[500] }}>
                                                {loc('dashboardHistory37', lang)}
                                            </Typography>
                                        )}
                                    </div>
                                )}

                                <Typography>
                                    {loc('dashboardHistory38', lang, {
                                        charity: _.get(redemption, 'charity.name', ''),
                                        charityRegistrationNumber: _.get(
                                            redemption,
                                            'charity.charityRegistrationNumber',
                                            ''
                                        )
                                    })}
                                </Typography>
                            </>
                        );
                    } else {
                        content = (
                            <div
                                data-cy={`donation-tax-receipt-details-${index}`}
                                style={{ display: 'flex', alignItems: 'center', color: theme.palette.text.secondary }}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>info</Icon>
                                <Typography color="textSecondary">{loc('dashboardHistory39', lang)}</Typography>
                            </div>
                        );
                    }
                } else {
                    content = (
                        <div style={{ display: 'flex', alignItems: 'center', color: theme.palette.text.secondary }}>
                            <Icon style={{ marginRight: theme.spacing.unit }}>info</Icon>
                            <Typography color="textSecondary">
                                {loc('dashboardUpdate4', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                            </Typography>
                        </div>
                    );
                }
            }
        } else {
            content = (
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.default,
                        marginBottom: theme.spacing.unit * 2
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography style={{ marginTop: theme.spacing.unit }}>
                            Your payment is linked to scheme ID {_.get(customer, 'schemeID', 'N/A')}. Your balance
                            should be deposited in 3-5 days.
                        </Typography>
                    </div>
                </Paper>
            );
        }

        let status = '',
            icon,
            color;

        if (!redemption.complete) {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'redemptionPending.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'redemptionPending.color', colors.grey[200]);
        } else if (redemption.complete && !redemption.cancelled) {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'redemptionCompleted.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'redemptionCompleted.color', colors.grey[200]);
        } else {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'redemptionCancelled.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'redemptionCancelled.color', colors.grey[200]);
        }

        if (redemption.paymentModel === 'SCHEME_ID_REDEMPTION') {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'redemptionDeposited.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'redemptionDeposited.color', colors.grey[200]);
        }

        let isCancellable = !redemption.cancelled;

        if (isAUSRegion()) {
            isCancellable = !redemption.cancelled && !redemption.complete;
        }

        return {
            id: `customer-history-donation-${index}`,
            key: redemption._id,
            type: 'REDEMPTION',
            isDonation: donated,
            isCancellable: isCancellable,
            color: color,
            icon: icon,
            date: redemption.createdAt,
            timezone: _.get(firstRedeemedBulk, 'pickup.location.timezone', customer.location.timezone),
            primary: donated ? (
                <React.Fragment>
                    <span>
                        {loc('dashboardHistory5', lang, {
                            commodityType: _.startCase(_.get(firstRedeemedBulk, 'skuType', ''))
                        })}{' '}
                        {redemption.charity.name}{' '}
                        {!_.isNil(redemption.subdivision) ? `(${redemption.subdivision})` : ''}
                        {redemption.refunded && `(${loc('refunded', lang)})`}
                    </span>
                    {redemption.taxReceiptRequested && (
                        <Icon
                            title={loc('dashboardHistory40', lang)}
                            style={{
                                marginLeft: theme.spacing.unit / 2,
                                color: theme.palette.text.secondary,
                                fontSize: '115%',
                                verticalAlign: 'text-bottom'
                            }}
                        >
                            receipt
                        </Icon>
                    )}
                </React.Fragment>
            ) : (
                <span>
                    {redemptionMessage} {redemptionTypeName} <span style={{ fontWeight: 500 }}>{redemptionStatus}</span>{' '}
                    {redemptionDestination}
                </span>
            ),
            secondary: (
                <span style={{ fontWeight: 'bold', color: colors.green['500'], fontSize: '14px', fontStyle: 'normal' }}>
                    {donated
                        ? formatAsCurrency(redemption.amount, lang)
                        : formatAsCurrency(redemption.amount - redemption.fee, lang)}{' '}
                </span>
            ),
            ...(showRedemptionBreakdown ? { content } : {})
        };
    });

    const taxReceiptItems = taxReceipts.map((receipt, index) => {
        const receiptBulks = _.get(receipt, 'bulks', []);

        const content = (
            <>
                <Typography style={{ marginTop: theme.spacing.unit }}>{loc('dashboardHistory41', lang)}</Typography>
                <Table>
                    <TableBody>
                        {receiptBulks.map((bulk, index) => (
                            <TableRow key={index} style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {locDate(bulk.dateCompleted, 'DD MMM YYYY', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {formatAsCurrency(getLedgerTotal(_.get(bulk, 'ledger.lines', [])), lang)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        );

        return {
            id: `customer-history-tax-receipt-${index}`,
            key: receipt._id,
            type: 'TAXRECEIPT',
            color: colors.yellow['800'],
            icon: <MDIcon path={mdiReceipt} size={1} color={theme.palette.background.default} />,
            date: receipt.cutOffDate,
            timezone: customer.location.timezone,
            primary: (
                <React.Fragment>
                    <span>
                        {loc('history14', lang)} {receipt.charity.name}
                    </span>
                </React.Fragment>
            ),
            secondary: (
                <span style={{ fontWeight: 'bold', color: colors.green['500'], fontSize: '14px', fontStyle: 'normal' }}>
                    {formatAsCurrency(receipt.amount, lang)}
                </span>
            ),
            content
        };
    });

    const invoiceItems = isAUSRegion()
        ? invoices.map((invoice, index) => {
              const invoiceLines = _.get(invoice, 'invoiceLines', []);

              const content = (
                  <>
                      <Typography style={{ marginTop: theme.spacing.unit }}>Invoice Items</Typography>
                      <Table>
                          <TableBody>
                              {invoiceLines.map((line, index) => (
                                  <TableRow key={index} style={{ height: 36 }}>
                                      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                          {line.description}
                                      </TableCell>
                                      <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                          {formatAsCurrency(line.amount, lang)}
                                      </TableCell>
                                  </TableRow>
                              ))}
                              <TableRow key={index} style={{ height: 36 }}>
                                  <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                      <span style={{ fontWeight: 'bold' }}>Total</span>
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                          {formatAsCurrency(invoice.totalInvoiceAmount, lang)}
                                      </span>
                                  </TableCell>
                              </TableRow>
                          </TableBody>
                      </Table>
                  </>
              );

              return {
                  id: `customer-history-invoice-${index}`,
                  key: invoice._id,
                  type: 'INVOICE',
                  color: colors.grey['900'],
                  icon: <MDIcon path={mdiReceipt} size={1} color={theme.palette.background.default} />,
                  date: invoice.invoicedDate,
                  timezone: customer.location.timezone,
                  primary: (
                      <React.Fragment>
                          <span>Invoice Issued {invoice.status}</span>
                      </React.Fragment>
                  ),
                  secondary: (
                      <>
                          <span
                              style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  fontStyle: 'normal'
                              }}
                          >
                              {formatAsCurrency(invoice.totalInvoiceAmount, lang)}{' '}
                              <span style={{ color: theme.palette.text.disabled }}>
                                  (due{' '}
                                  {moment(invoice.dueDate)
                                      .locale(lang)
                                      .tz(_.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
                                      .format('MMM Do, YYYY')}
                                  )
                              </span>
                          </span>
                          <span
                              style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  fontStyle: 'normal'
                              }}
                          >
                              {_.isNil(invoice.link) ? (
                                  <Typography variant="body2">
                                      Payment link is unavailable. Please contatct {process.env.REACT_APP_BRAND_NAME}{' '}
                                      for payment options.
                                  </Typography>
                              ) : (
                                  <ExternalLink
                                      text={_.isNil(invoice.link) ? 'Payment Link Unavailable' : 'Payment Link'}
                                      url={invoice.link}
                                  />
                              )}
                          </span>
                      </>
                  ),
                  content
              };
          })
        : [];

    const customerHistoryItems = customerHistory.map((item, index) => {
        const data = _.get(item, 'data', {});

        let primary, secondary, icon, color, content;
        let address = _.get(data, 'collectorName', _.get(data, 'kioskLocation', 'N/A'));
        if (item.collector) {
            const collectorName = _.get(item, 'collector.name');
            const collectorAddress = removePostalCodeAndCountryFromAddress(
                _.get(item, 'collector.location.description')
            );
            address = collectorName || collectorAddress || address;
        }

        const bagtags = [];
        const labelsMap = {};
        const labelCountedOnMap = {};
        const labels = !_.isNil(_.get(data, 'scannedLabels'))
            ? _.get(data, 'scannedLabels', [])
            : _.get(data, 'printedLabels', []);
        for (let label of labels) {
            labelsMap[label] = 1;
            labelCountedOnMap[label] = null;

            bagtags.push(label);
        }

        // const bulksCompletedAfterLabelCreation = _.filter(bulks, bulk =>
        //     moment(bulk.createdAt).isAfter(moment(item.date))
        // );

        // TODO: refactor? Probably exists faster method
        for (let bulk of bulks) {
            const sessions = _.get(bulk, 'countingSessions', []);
            for (let session of sessions) {
                if (sessions && session.bagtag) {
                    labelsMap[session.bagtag] = 0;
                    labelCountedOnMap[session.bagtag] = session.dateCounted;
                }
            }
        }

        if (!_.isNil(_.get(data, 'scannedLabels'))) {
            const scannedLabels = _.get(data, 'scannedLabels', []);

            if (!Object.values(labelsMap).includes(1)) {
                return null;
            }

            primary = (
                <span>
                    {getQuickDropName(lang)} {loc('dashboardHistory18', lang)}
                </span>
            );
            content = (
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <Typography color="textSecondary">
                        {loc('on', lang)}{' '}
                        <b>
                            {loc('dateAtTime', lang, {
                                date: locDate(item.date, 'MMM Do, YYYY', lang),
                                time: locDate(item.date, 'h:mm a', lang)
                            })}
                        </b>
                        {loc('dashboardHistory69', lang, {
                            count: scannedLabels.length,
                            location: address
                        })}
                    </Typography>
                    {adminView && (
                        <>
                            <Divider
                                style={{
                                    marginTop: theme.spacing.unit * 2,
                                    marginBottom: theme.spacing.unit,
                                    borderTop: '2px dashed rgba(0, 0, 0, 0.3)'
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            marginBottom: 5
                                        }}
                                    >
                                        <div style={{ display: 'flex', margin: theme.spacing.unit / 2 }}>
                                            <MDIcon path={mdiShield} size={1} color={theme.palette.text.secondary} />
                                        </div>
                                        <Typography variant="h6" style={{ fontSize: '1rem' }}>
                                            {loc('adminOnly', lang)} {loc('details', lang)}
                                        </Typography>
                                    </div>
                                    {scannedLabels.map((label, index) => (
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                            key={index}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', margin: theme.spacing.unit / 2 }}>
                                                    <MDIcon
                                                        path={mdiTagArrowDown}
                                                        size={0.8}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </div>
                                                {labelsMap[label] === 0 && (
                                                    <Typography color="textSecondary">
                                                        <b>{truncateBagtag(label)}</b>{' '}
                                                        {locDate(labelCountedOnMap[label], 'MMM D, YY h:mmA', lang)}
                                                    </Typography>
                                                )}
                                                {labelsMap[label] !== 0 && (
                                                    <Typography color="textSecondary">
                                                        <b>{truncateBagtag(label)}</b>
                                                    </Typography>
                                                )}
                                            </div>
                                            {labelsMap[label] === 0 && (
                                                <span
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        marginLeft: theme.spacing.unit
                                                    }}
                                                >
                                                    <Icon style={{ color: colors.green[500] }}>check</Icon>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </Paper>
            );
            secondary = `${scannedLabels.length} ${loc('bag', lang)}${scannedLabels.length > 1 ? 's' : ''} ${loc(
                'history18',
                lang
            )} ${locDate(_.get(item, 'date'), 'h:mma', lang)}`;
        } else if (!_.isNil(_.get(data, 'printedLabels'))) {
            let reprint = _.get(data, 'reprint', false);
            if (reprint && !adminView) return null;

            const printedLabels = _.get(data, 'printedLabels', []);
            let label = reprint
                ? `${loc('labelsReprinted', lang)} (Visible to admins only)`
                : loc(_.get(item, 'description'), lang);
            if (printedLabels.length === 1) {
                label = label.replace('Labels', 'Label');
                label = label.replace('Auto-collants', 'Auto-collant');
            }

            primary = (
                <Typography style={{ marginTop: theme.spacing.unit }}>
                    {printedLabels.length} {label}
                </Typography>
            );
            secondary = (
                <span>
                    {loc('history16', lang)} {locDate(_.get(item, 'date'), 'h:mma', lang)}
                </span>
            );
            content = (
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <Typography color="textSecondary">
                        {loc('on', lang)}{' '}
                        <b>
                            {loc('dateAtTime', lang, {
                                date: locDate(item.date, 'MMM Do, YYYY', lang),
                                time: locDate(item.date, 'h:mm a', lang)
                            })}
                        </b>
                        {loc('dashboardHistory69', lang, {
                            count: printedLabels.length,
                            location: address
                        })}
                    </Typography>
                    {adminView && (
                        <>
                            <Divider
                                style={{
                                    marginTop: theme.spacing.unit * 2,
                                    marginBottom: theme.spacing.unit,
                                    borderTop: '2px dashed rgba(0, 0, 0, 0.3)'
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            marginBottom: 5
                                        }}
                                    >
                                        <div style={{ display: 'flex', margin: theme.spacing.unit / 2 }}>
                                            <MDIcon path={mdiShield} size={1} color={theme.palette.text.secondary} />
                                        </div>
                                        <Typography variant="h6" style={{ fontSize: '1rem' }}>
                                            {loc('adminOnly', lang)} {loc('details', lang)}
                                        </Typography>
                                    </div>
                                    {printedLabels.map((label, index) => (
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                            key={index}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', margin: theme.spacing.unit / 2 }}>
                                                    <MDIcon
                                                        path={mdiTagArrowDown}
                                                        size={0.8}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </div>
                                                {labelsMap[label] === 0 && (
                                                    <Typography color="textSecondary">
                                                        <b>{truncateBagtag(label)}</b>{' '}
                                                        {locDate(labelCountedOnMap[label], 'MMM D, YY h:mmA', lang)}
                                                    </Typography>
                                                )}
                                                {labelsMap[label] !== 0 && (
                                                    <Typography color="textSecondary">
                                                        <b>{truncateBagtag(label)}</b>
                                                    </Typography>
                                                )}
                                            </div>
                                            {labelsMap[label] === 0 && (
                                                <span
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        marginLeft: theme.spacing.unit
                                                    }}
                                                >
                                                    <Icon style={{ color: colors.green[500] }}>check</Icon>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </Paper>
            );
        }

        if (item.type === 'LABELS') {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'customerHistoryLabels.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'customerHistoryLabels.color', colors.grey[200]);
        } else if (item.type === 'INHOUSE') {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'customerHistoryInhouse.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'customerHistoryInhouse.color', colors.grey[200]);
        } else {
            icon = (
                <MDIcon
                    path={_.get(
                        allIcons,
                        convertToImportName(_.get(historyIcons, 'customerHistoryDefault.icon', 'dotsHorizontal'))
                    )}
                    size={1}
                    color={theme.palette.background.default}
                />
            );
            color = _.get(historyIcons, 'customerHistoryDefault.color', colors.grey[200]);
        }

        return {
            id: `customer-history-label-${index}`,
            key: item._id,
            type: item.type,
            color,
            icon,
            date: item.date,
            timezone: customer.location.timezone,
            primary,
            secondary,
            address: removePostalCodeAndCountryFromAddress(address),
            addressFilterVal: removePostalCodeAndCountryFromAddress(address),
            content,
            bagtags
        };
    });

    const combinedItems = _.sortBy(
        bulkItems
            .concat(redemptionItems)
            .concat(pickupItems)
            .concat(rescheduleItems)
            .concat(taxReceiptItems)
            .concat(invoiceItems)
            .concat(customerHistoryItems.filter(item => !_.isNil(item)))
            .concat(quickdropItems),
        item => item.date
    ).reverse();

    let defaultFilters = [
        { name: loc('history3', lang), value: 'REDEMPTION' },
        { name: loc('dashboardHistory1', lang), value: 'PICKUP' },
        { name: loc('dashboardSetting14', lang), value: 'TAXRECEIPT' },
        { name: getWalkInName(lang), value: 'WALKIN' },
        { name: getQuickDropName(lang), value: 'INHOUSE' },
        { name: loc('adjustment', lang), value: 'ADJUSTMENT' }
    ];

    if (isAUSRegion() && !_.isEmpty(invoiceItems)) {
        //    defaultFilters = ['BULK', 'REDEMPTION', 'PICKUP', 'TAXRECEIPT', 'WALKIN', 'INHOUSE', 'INVOICE'];

        defaultFilters = [
            { name: loc('history3', lang), value: 'REDEMPTION' },
            { name: loc('dashboardHistory1', lang), value: 'PICKUP' },
            { name: loc('dashboardSetting14', lang), value: 'TAXRECEIPT' },
            { name: getWalkInName(lang), value: 'WALKIN' },
            { name: getQuickDropName(lang), value: 'INHOUSE' },
            { name: loc('adjustment', lang), value: 'ADJUSTMENT' },
            { name: 'Invoices', value: 'INVOICE' }
        ];
    }
    //if (isEXPRegion()) defaultFilters = ['BULK', 'REDEMPTION', 'TAXRECEIPT', 'WALKIN', 'INHOUSE', 'LABELS'];
    if (isEXPRegion()) {
        defaultFilters = [
            { name: loc('history3', lang), value: 'REDEMPTION' },
            { name: loc('dashboardHistory1', lang), value: 'PICKUP' },
            { name: loc('dashboardSetting14', lang), value: 'TAXRECEIPT' },
            // { name: getWalkInName(lang), value: 'WALKIN' },
            { name: getQuickDropName(lang), value: 'INHOUSE' },
            { name: loc('adjustment', lang), value: 'ADJUSTMENT' },
            { name: loc('labels', lang), value: 'LABELS' }
        ];
    }

    if (isCONRegion()) {
        defaultFilters = [
            { name: loc('history3', lang), value: 'REDEMPTION' },
            { name: loc('dashboardHistory1', lang), value: 'PICKUP' },
            { name: getQuickDropName(lang), value: 'INHOUSE' },
            { name: getWalkInName(lang), value: 'WALKIN' },
            { name: loc('adjustment', lang), value: 'ADJUSTMENT' },
            { name: loc('labels', lang), value: 'LABELS' }
        ];
    }

    if (_.get(customer, 'allowViewTaxReceiptHistory', false) === false) {
        _.remove(defaultFilters, { value: 'TAXRECEIPT' });
    }

    if (!pickupsEnabled) {
        _.remove(defaultFilters, { value: 'PICKUP' });
    }

    return (
        <>
            <PDFPreviewer
                title={'Receipt'}
                open={pdfViewerOpen}
                onClose={() => setPdfViewerOpen(false)}
                pdfUrl={pdfUriStr}
            />
            <HistoryWidget
                theme={theme}
                loading={loading}
                items={combinedItems}
                defaultFilters={defaultFilters}
                disableInAppRedemptionCancel={disableInAppRedemptionCancel}
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: theme.spacing.unit * 4
                }}
                adminView={adminView}
                http={http}
                history={history}
                auth={auth}
                reloadData={handleReloadData}
                isCollectionProgramUser={_.get(customer, 'collectionProgramPreference', false)}
            />
            <CustomerReportDialog
                open={customerReportDialogOpen}
                onClose={handleCustomerReportDialog(false)}
                bulk={reportedBulk}
                pickup={reportedPickup}
                http={http}
            />
            {!_.isNil(complaintBulk) && (
                <BulkComplaintDialog
                    operator={auth}
                    open={complaintDialogOpen}
                    bulk={complaintBulk}
                    inProgress={complaintsDialogLoading}
                    customerIssueResolution={complaintResolution}
                    onToggleResolveCustomerIssue={handleToggleResolveCustomerIssue}
                    onChangeCustomerIssueResolution={e => setComplaintResolution(e.target.value)}
                    onClose={() => {
                        setComplaintDialogOpen(false);
                    }}
                    http={http}
                />
            )}
        </>
    );
}

export default withTheme()(History);

function FinancialInfo({ bulks, theme, lang }) {
    let promosApplied = [];
    let ownerAmount = 0;
    let transporterTotalAmount = 0;
    let collectorTotalAmount = 0;
    let tipAmount = 0;

    bulks.forEach(b => {
        if (!_.isEmpty(b.promosApplied)) {
            promosApplied = promosApplied.concat(b.promosApplied);
        }

        const { customerAmount, transporterAmount, collectorAmount } = bulkHelper.getAmounts(b);
        ownerAmount += customerAmount;
        transporterTotalAmount += transporterAmount;
        collectorTotalAmount += collectorAmount;
        tipAmount += _.get(b, 'tip.adjustedAmount', 0) * 100;
    });

    let financial = (
        <span
            style={{
                fontWeight: 'bold',
                color: ownerAmount > 0 ? colors.green['500'] : colors.red['500'],
                fontSize: '14px',
                fontStyle: 'normal'
            }}
        >
            {formatAsCurrency(ownerAmount - tipAmount, lang)}
        </span>
    );
    /*switch (process.env.REACT_APP_PAYMENT_MODEL) {
        case 'AUS_DIRECT_BANKING': {
            let depositValue = ownerAmount + transporterTotalAmount + collectorTotalAmount;
            let serviceFee = depositValue - ownerAmount;

            financial = (
                <>
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: colors.green['500'],
                            fontSize: '14px',
                            fontStyle: 'normal'
                        }}
                    >
                        {formatAsCurrency(depositValue)}{' '}
                        <span style={{ color: theme.palette.text.disabled }}>
                            (service fee {formatAsCurrency(serviceFee)}
                            {tipAmount > 0 ? ` and tip ${formatAsCurrency(tipAmount)}` : ''})
                        </span>
                    </span>
                </>
            );
            break;
        }
        case 'INTERAC_ETRANSFERS':
            financial = (
                <span
                    style={{
                        fontWeight: 'bold',
                        color: ownerAmount > 0 ? colors.green['500'] : colors.red['500'],
                        fontSize: '14px',
                        fontStyle: 'normal'
                    }}
                >
                    {formatAsCurrency(ownerAmount - tipAmount)}
                </span>
            );
            break;
        default:
            throw new Error('REACT_APP_PAYMENT_MODEL not set properly');
    }*/

    if (!_.isEmpty(promosApplied)) {
        return (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                {financial}
                <PromoCodeChip theme={theme} promosApplied={promosApplied} />
            </span>
        );
    } else {
        return financial;
    }
}

function PromoCodeChip({ theme, promosApplied }) {
    if (!_.isEmpty(promosApplied)) {
        return (
            <span
                style={{
                    marginLeft: theme.spacing.unit / 2,
                    background: colors.blue[50],
                    color: 'rgba(0, 0, 0, 0.65)',
                    fontSize: theme.typography.fontSize * 0.875,
                    padding: '2px 4px',
                    borderRadius: 4,
                    border: `1px solid ${colors.blue[100]}`
                }}
            >
                {_.first(promosApplied).label}
            </span>
        );
    } else {
        return null;
    }
}

function getCombinedCounts(bulk) {
    let countsCombined = [];
    // let conformants = {};
    let nonConformants = {};
    let conformantsByMatAndSize = {};

    if (_.has(bulk, 'counts')) {
        bulk.counts.forEach(count => {
            if (_.has(count, 'items')) {
                count.items.forEach(item => {
                    const updatedItemObj = {
                        sku: item.sku,
                        size: item.size,
                        quantity: item.quantity,
                        materialType: item.materialType,
                        fullDescription: (
                            <span>
                                {item.materialType}{' '}
                                {!_.isEmpty(item.size) ? (
                                    <span style={{ whiteSpace: 'nowrap' }}>({item.size})</span>
                                ) : null}
                            </span>
                        ),
                        fullDescriptionText: `${item.materialType} ${item.size}`
                    };
                    let groupName = `${item.materialType}${item.size}`.trim();
                    if (['NON', 'Non-conformant'].includes(item.materialType)) {
                        if (!_.isNil(nonConformants[item.sku])) {
                            updatedItemObj.quantity += nonConformants[item.sku].quantity;
                        }
                        nonConformants[item.sku] = updatedItemObj;
                    } else {
                        if (!_.isNil(conformantsByMatAndSize[groupName])) {
                            updatedItemObj.quantity += conformantsByMatAndSize[groupName].quantity;
                        }

                        conformantsByMatAndSize[groupName] = updatedItemObj;

                        // if (!_.isNil(conformants[item.sku])) {
                        // updatedItemObj.quantity += conformants[item.sku].quantity;
                        // }
                        // conformants[item.sku] = updatedItemObj;
                    }
                });
            }
        });

        // const sortedCounts = _.sortBy(Object.values(conformants), ['size', 'materialType']);
        const sortedCounts = _.sortBy(Object.values(conformantsByMatAndSize), ['size', 'materialType']);
        const nonConformantCounts = Object.values(nonConformants);

        countsCombined = _.concat(sortedCounts, nonConformantCounts);
    }
    return countsCombined;
}

function getDonationAmountsByCharity(bulks) {
    // Map total donations to charity by charity id
    const donationMap = new Map();

    bulks.forEach(b => {
        if (_.isNil(b.charity)) return;

        const taxReceiptRequested = _.get(b, 'taxReceipt.requested');
        const taxReceiptIssued = _.get(b, 'taxReceipt.issued');

        if (!taxReceiptRequested || taxReceiptIssued) {
            return;
        }

        const charityId = _.get(b, 'charity._id');
        const ledgerLines = _.get(b, 'ledger.lines', []);
        const bulksTotalValue = getLedgerTotal(ledgerLines);

        if (donationMap.has(charityId)) {
            donationMap.set(charityId, donationMap.get(charityId) + bulksTotalValue);
        } else {
            donationMap.set(charityId, bulksTotalValue);
        }
    });

    return donationMap;
}

function displayDate(pickup) {
    if (!_.isNil(pickup.completionDate)) {
        return pickup.completionDate;
    } else if (moment(pickup.date).isSame('2018-01-01T00:00:00Z', 'day')) {
        return 'Not assigned yet';
    } else {
        return pickup.date;
    }
}

function getLedgerTotal(ledger) {
    let sum = 0;

    ledger.forEach(line => {
        sum += _.get(line, 'amount', 0);
    });

    return Math.abs(sum);
}

function getItemIssues({ driverIssues = [], payloadIssues = [], driverComplaints = [], clerkComplaints = [] }) {
    const driverIssuesObjs = driverIssues.map(issue => {
        const complaint = _.find(driverComplaints, { code: issue });
        return { name: _.isNil(complaint) ? issue : _.get(complaint, 'label', ''), value: issue, type: 'DRIVER' };
    });
    const payloadIssuesObjs = payloadIssues.map(issue => {
        const complaint = _.find(clerkComplaints, { code: issue });
        return {
            name: _.isNil(complaint) ? issue : _.get(complaint, 'label', ''),
            value: issue,
            type: 'PAYLOAD',
            suppressWarnings: _.get(complaint, 'suppressWarnings', false)
        };
    });

    return [...driverIssuesObjs, ...payloadIssuesObjs];
}

function checkBulkComplaints(bulks) {
    let hasUnresolvedComplaints = false,
        hasResolvedComplaints = false;
    for (let b of bulks) {
        if (_.get(b, 'customerIssues.customerHasIssue', false) && _.isNil(_.get(b, 'customerIssues.dateResolved'))) {
            hasUnresolvedComplaints = true;
        } else if (
            _.get(b, 'customerIssues.customerHasIssue', false) &&
            !_.isNil(_.get(b, 'customerIssues.dateResolved'))
        ) {
            hasResolvedComplaints = true;
        }
    }
    return { hasResolvedComplaints, hasUnresolvedComplaints };
}
