import React, { useState, useEffect, useContext } from 'react';
import DateIntervalCarousal from 'components/DateTimePickersTz/DateIntervalCarousal';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';

import { TripsWidget, DriverCodeWidget, CompletedTripsWidget } from 'components/DriverWidgets';
//import OperatorRatingWidgets from 'components/OperatorRatingWidgets/OperatorRatingWidgets';
import PickupRatingWidget from 'components/OperatorRatingWidgets/PickupRatingWidget';
import CountingRatingWidget from 'components/OperatorRatingWidgets/CountingRatingWidget';
import ComplaintsWidget from 'components/OperatorRatingWidgets/ComplaintsWidget/ComplaintsWidget';

import Grid from '@material-ui/core/Grid';
import { withTheme } from '@material-ui/core/styles';
import {
    Hidden,
    CircularProgress,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    FormControlLabel,
    Checkbox,
    AppBar,
    Tabs,
    Tab,
    Typography,
    withStyles
} from '@material-ui/core';

import useGetJSON from 'utils/hooks/useGetJSON';

import ComplimentsWidget from 'components/OperatorRatingWidgets/ComplimentsWidget';
import CustomerFeedbackWidget from 'components/OperatorRatingWidgets/CustomerFeedbackWidget';
import TipsWidget from 'components/OperatorRatingWidgets/TipsWidget';

import CustomerReviewWrapper from 'components/DriverWidgets/CustomerReviewWrapper';
import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useRef } from 'react';

const styles = theme => ({
    tabBadgeRoot: {
        color: 'white',
        '& > .tab-text': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper
        }
    },
    tabBadgeShifted: {
        right: -theme.spacing.unit
    },
    tabBadgeDefault: {
        right: 0
    }
});

function Dashboard(props) {
    const {
        theme,
        classes,
        http,
        history,
        operator,
        pickups,
        redemptions,
        redemptionFee,
        redemptionPending,
        minRedemption,
        onSnackbar,
        reloadOperator
    } = props;

    const [filtersOpen, setFiltersOpen] = useState(false);

    const { lang } = useContext(LocalizationContext);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeBothDates,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initDateWindow: 'thisIsoWeek'
    });

    const [currentTab, setCurrentTab] = useState(0);
    const [locationDialogOpen, setLocationDialogOpen] = useState(
        JSON.parse(localStorage.getItem('locationAccessDialog') || 'true')
    );

    const [intervalValue, setIntervalValue] = useState(
        [moment(startDate).format('MM-DD-YYYY'), moment(endDate).format('MM-DD-YYYY')].toString()
    );

    const { loading: tripsLoading, data: trips } = useGetJSON(`/users/operators/${operator._id}/getTrips`, 'trips', []);

    const { loading: tipStatsLoading, data: tips } = useGetJSON(
        `/tips/operators/${operator._id}/getDriverTipStats?startDate=${intervalValue.split(',')[0]}&endDate=${
            intervalValue.split(',')[1]
        }`
    );

    const upcomingTrips = _.filter(
        trips,
        trip =>
            trip.upcoming ||
            (moment(trip.startTime)
                .tz(operator.collector.timezone)
                .startOf('day')
                .isSame(
                    moment()
                        .tz(operator.collector.timezone)
                        .startOf('day')
                ) &&
                _.isNil(trip.offloadTime))
    );

    const handleChangeTab = (e, value) => {
        setCurrentTab(value);
    };

    const [commodities, setCommodities] = useState([]);

    const [completeTripStatistics, setCompletedTripStatistics] = useState([]);
    const [completeTripStatisticsLoading, setCompletedTripStatisticsLoading] = useState(true);

    const [driverRatingObj, setDriverRatingObj] = useState([]);
    const [driverRatingLoading, setDriverRatingLoading] = useState(true);

    useEffect(() => {
        getDriverRatingData();
        getCompleteTripStatistics();
    }, [intervalValue]);

    useEffect(() => {
        getCommodities();
    }, []);

    async function getTipData() {
        setDriverRatingLoading(true);
        const res = await http.getJSON(
            `/users/${operator._id}/operatorRatingStatistics?startDate=${intervalValue.split(',')[0]}&endDate=${
                intervalValue.split(',')[1]
            }`
        );
        if (res.ok) {
            setDriverRatingObj(_.get(res, 'data', []));
        }
        setDriverRatingLoading(false);
    }

    async function getDriverRatingData() {
        setDriverRatingLoading(true);
        const res = await http.getJSON(
            `/users/${operator._id}/operatorRatingStatistics?startDate=${intervalValue.split(',')[0]}&endDate=${
                intervalValue.split(',')[1]
            }`
        );
        if (res.ok) {
            setDriverRatingObj(_.get(res, 'data', []));
        }
        setDriverRatingLoading(false);
    }

    //Problem: Fast date carousal usage, causes race condition for complete trips
    //Solution: track most recent request with ref and only update with ref request
    const requestRef = useRef(0);

    async function getCompleteTripStatistics() {
        const requestId = ++requestRef.current;

        setCompletedTripStatisticsLoading(true);
        const res = await http.getJSON(
            '/users/operators/' +
                operator._id +
                `/getCompletedTripsStatistics?startDate=${intervalValue.split(',')[0]}&endDate=${
                    intervalValue.split(',')[1]
                }`
        );
        if (res.ok && requestId === requestRef.current) {
            setCompletedTripStatistics(_.get(res, 'data', []));
        }
        setCompletedTripStatisticsLoading(false);
    }

    async function getCommodities() {
        const res = await http.getJSON(`/commodities/getAllCommodities`);
        if (res.ok) {
            setCommodities(_.get(res, 'data.collectionData', []));
        }
    }

    useEffect(() => {
        if (filtersOpen) {
            setFiltersOpen(false);
        }
        setIntervalValue([moment(startDate).format('MM-DD-YYYY'), moment(endDate).format('MM-DD-YYYY')].toString());
    }, [startDate, endDate]);

    const [complimentsViewed, setComplimentsViewed] = useState(localStorage.getItem('complimentsViewed') === 'true');
    const [complaintsViewed, setComplaintsViewed] = useState(localStorage.getItem('complaintsViewed') === 'true');
    const [unviewedComplimentsCount, setUnviewedComplimentsCount] = useState(0);
    const [unviewedComplaintsCount, setUnviewedComplaintsCount] = useState(0);
    const [unviewedComplaintIdsState, setUnviewedComplaintIdsState] = useState([]);
    const [unviewedComplaintBulkIdsState, setUnviewedComplaintBulkIdsState] = useState([]);

    useEffect(() => {
        let complimentIds = _.get(driverRatingObj, 'pickupsWithCompliments', []).map(c => c._id);
        let viewedComplimentIds = JSON.parse(localStorage.getItem('viewedComplimentIds') || '[]');
        let viewedComplimentIdsSet = new Set(viewedComplimentIds);
        let unviewedComplimentIds = [];
        complimentIds.forEach(id => {
            if (!viewedComplimentIdsSet.has(id)) {
                unviewedComplimentIds.push(id);
            }
        });
        let complimentCount = unviewedComplimentIds.length;
        setUnviewedComplimentsCount(complimentCount);
        let complaintIds = [
            ..._.get(driverRatingObj, 'pickupsWithIssues', []),
            ..._.get(driverRatingObj, 'systemComplaints', [])
        ].map(c => c._id);
        let complaintBulks = _.get(driverRatingObj, 'bulksWithIssues', []);
        let viewedComplaintBulks = JSON.parse(localStorage.getItem('viewedComplaintBulks') || '{}');
        let unviewedComplaintBulks = [];
        // viewedComplaintBulks contains key value pair of complaint bulks id and their complaint description
        // for each of compalaint bulks currently in view, check their id and check if the description match
        // if match consider it viewed
        complaintBulks.forEach(b => {
            let viewedComplaintDesc = _.get(viewedComplaintBulks, `${b._id}`, null);
            let unviewedComplaintDesc = null;
            if (!_.isEmpty(_.get(b, 'driverIssues.issues', []))) {
                unviewedComplaintDesc = _.get(b, `driverIssues.description`, '');
            }
            if (!_.isNil(viewedComplaintDesc) && viewedComplaintDesc === unviewedComplaintDesc) {
                return;
            }
            unviewedComplaintBulks.push(b);
        });
        let complaintBulkCount = unviewedComplaintBulks.length;
        setUnviewedComplaintBulkIdsState(unviewedComplaintBulks.map(b => b._id));

        let viewedComplaintIds = JSON.parse(localStorage.getItem('viewedComplaintIds') || '[]');
        let viewedComplaintIdsSet = new Set(viewedComplaintIds);
        let unviewedComplaintIds = [];
        complaintIds.forEach(id => {
            if (!viewedComplaintIdsSet.has(id)) {
                unviewedComplaintIds.push(id);
            }
        });
        setUnviewedComplaintIdsState(unviewedComplaintIds);
        let complaintCount = unviewedComplaintIds.length;
        setUnviewedComplaintsCount(complaintCount + complaintBulkCount);
        if (complimentCount !== 0) {
            localStorage.setItem('complimentsViewed', false);
            setComplimentsViewed(false);
        }
        if (complaintCount + complaintBulkCount > 0) {
            localStorage.setItem('complaintsViewed', false);
            setComplaintsViewed(false);
        }
    }, [driverRatingObj]);

    const handleViewCompliments = driverRatingObj => {
        let complementIds = _.get(driverRatingObj, 'pickupsWithCompliments', []).map(c => c._id);
        let viewedComplimentIds = JSON.parse(localStorage.getItem('viewedComplimentIds') || '[]');
        let newViewedComplimentIds = [...new Set([...complementIds, ...viewedComplimentIds])];
        localStorage.setItem('viewedComplimentIds', JSON.stringify(newViewedComplimentIds));
        localStorage.setItem('complimentsViewed', true);
        setComplimentsViewed(true);
    };

    const handleViewComplaints = driverRatingObj => {
        let complaintIds = [
            ..._.get(driverRatingObj, 'pickupsWithIssues', []),
            ..._.get(driverRatingObj, 'systemComplaints', [])
        ].map(c => c._id);
        let complaintBulks = _.get(driverRatingObj, 'bulksWithIssues', []);
        let viewedComplaintBulks = JSON.parse(localStorage.getItem('viewedComplaintBulks') || '{}');
        complaintBulks.forEach(b => {
            viewedComplaintBulks[b._id] = _.get(b, 'driverIssues.description', '');
        });
        let viewedComplaintIds = JSON.parse(localStorage.getItem('viewedComplaintIds') || '[]');
        let newViewedComplaintIds = [...new Set([...complaintIds, ...viewedComplaintIds])];
        localStorage.setItem('viewedComplaintIds', JSON.stringify(newViewedComplaintIds));
        localStorage.setItem('viewedComplaintBulks', JSON.stringify(viewedComplaintBulks));
        localStorage.setItem('complaintsViewed', true);
        setComplaintsViewed(true);
    };

    const isDriver = _user.isDriver(operator);
    const isClerk = _user.isClerk(operator);

    return (
        <>
            <Hidden mdDown>
                <Grid
                    container
                    style={{ padding: theme.spacing.unit * 1, width: '100%' }}
                    spacing={theme.spacing.unit * 2}
                >
                    <Grid item xs={12}>
                        <Paper
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                        >
                            <DateIntervalCarousal
                                startDate={startDate}
                                endDate={endDate}
                                handleGoBackwards={handleGoBackwards}
                                handleGoForwards={handleGoForwards}
                                theme={theme}
                                dateInterval={'isoWeek'}
                            />
                        </Paper>
                    </Grid>
                    {isDriver && (
                        <>
                            <Grid item xs={6}>
                                <div style={{ marginBottom: theme.spacing.unit }}>
                                    <DriverCodeWidget driverCode={operator.driverCode} />
                                </div>
                                <TripsWidget
                                    history={props.history}
                                    operator={operator}
                                    upcomingTrips={upcomingTrips}
                                    onCustomHeader={props.onCustomHeader}
                                    commodities={commodities}
                                    loading={tripsLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CompletedTripsWidget
                                    http={http}
                                    completedTrips={_.get(
                                        completeTripStatistics,
                                        'completedTripsWithStats.completedTrips',
                                        []
                                    )}
                                    operator={operator}
                                    commodities={commodities}
                                    tipsGroupedByTrip={tips.tipsGroupedByTrip}
                                    tipsAreEstimated={tips.tipsAreEstimated}
                                    tipsAreEnabled={tips.tipsAreEnabled}
                                    loading={completeTripStatisticsLoading || tipStatsLoading}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={theme.spacing.unit * 2}>
                            {driverRatingLoading || completeTripStatisticsLoading ? (
                                <Grid item xs={12}>
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        <CircularProgress />
                                    </Paper>
                                </Grid>
                            ) : (
                                <>
                                    {isDriver && (
                                        <Grid item lg={12} xl>
                                            <PickupRatingWidget
                                                pickupRating={driverRatingObj.pickupRating}
                                                operator={operator}
                                                driverFinancialInfo={_.get(
                                                    completeTripStatistics,
                                                    'completedTripsWithStats.driverFinancialInfo',
                                                    []
                                                )}
                                                driverRanking={_.get(
                                                    completeTripStatistics,
                                                    'driverRankingPickedUpCmdties.driverRanking',
                                                    []
                                                )}
                                                isDriver={isDriver}
                                                pickedUpCommodities={_.get(
                                                    completeTripStatistics,
                                                    'driverRankingPickedUpCmdties.pickedUpCommodities',
                                                    []
                                                )}
                                                commodities={commodities}
                                                tipBalance={tips.tipBalance}
                                            />
                                        </Grid>
                                    )}

                                    {isClerk && (
                                        <Grid item lg={6} xl>
                                            <CountingRatingWidget
                                                countRating={driverRatingObj.countRating}
                                                isClerk={isClerk}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item lg={12} xl>
                                        {_.get(operator, 'permissions.payThroughSystem', false) && tips.tipsAreEnabled && (
                                            <TipsWidget
                                                style={{
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                                tipBalance={tips.tipBalance}
                                                theme={theme}
                                                loading={tipStatsLoading}
                                            />
                                        )}
                                        <CustomerReviewWrapper
                                            type="compliments"
                                            count={unviewedComplimentsCount}
                                            viewed={complimentsViewed}
                                            handleExpand={handleViewCompliments}
                                            driverRatingObj={driverRatingObj}
                                        >
                                            <ComplimentsWidget
                                                style={{
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                                noPadding={true}
                                                operator={operator}
                                                pickupsWithCompliments={driverRatingObj.pickupsWithCompliments}
                                                loading={driverRatingLoading}
                                            />
                                        </CustomerReviewWrapper>
                                        <CustomerReviewWrapper
                                            viewed={complaintsViewed}
                                            count={unviewedComplaintsCount}
                                            handleExpand={handleViewComplaints}
                                            driverRatingObj={driverRatingObj}
                                        >
                                            <ComplaintsWidget
                                                noPadding={true}
                                                operator={operator}
                                                history={history}
                                                pickupsWithIssues={driverRatingObj.pickupsWithIssues}
                                                bulksWithIssues={driverRatingObj.bulksWithIssues}
                                                systemComplaints={driverRatingObj.systemComplaints}
                                                loading={driverRatingLoading}
                                                isDriver={isDriver}
                                                unviewedComplaintIdsState={unviewedComplaintIdsState}
                                                unviewedComplaintBulkIdsState={unviewedComplaintBulkIdsState}
                                            />
                                        </CustomerReviewWrapper>

                                        {driverRatingObj.customerFeedback && (
                                            <CustomerFeedbackWidget
                                                style={{
                                                    marginTop: theme.spacing.unit * 2
                                                }}
                                                customerFeedback={driverRatingObj.customerFeedback}
                                                theme={theme}
                                                loading={tripsLoading}
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingBottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing.unit * 7}px)`
                    }}
                >
                    {(isDriver || isClerk) && (
                        <Grid item xs={12}>
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: theme.spacing.unit,
                                    marginBottom: theme.spacing.unit
                                }}
                            >
                                <DateIntervalCarousal
                                    startDate={startDate}
                                    endDate={endDate}
                                    handleGoBackwards={handleGoBackwards}
                                    handleGoForwards={handleGoForwards}
                                    theme={theme}
                                    dateInterval={'isoWeek'}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {currentTab === 0 && (
                        <>
                            {isDriver && (
                                <div style={{ marginBottom: theme.spacing.unit }}>
                                    <DriverCodeWidget driverCode={operator.driverCode} />
                                </div>
                            )}
                            {isDriver && (
                                <div style={{ marginBottom: theme.spacing.unit }}>
                                    <TripsWidget
                                        history={props.history}
                                        operator={operator}
                                        upcomingTrips={upcomingTrips}
                                        onCustomHeader={props.onCustomHeader}
                                        commodities={commodities}
                                        loading={tripsLoading}
                                    />
                                </div>
                            )}
                            <CustomerReviewWrapper
                                type="compliments"
                                count={complimentsViewed}
                                viewed={complimentsViewed}
                                handleExpand={handleViewCompliments}
                                driverRatingObj={driverRatingObj}
                            >
                                <ComplimentsWidget
                                    style={{
                                        marginBottom: theme.spacing.unit * 2
                                    }}
                                    noPadding={true}
                                    operator={operator}
                                    pickupsWithCompliments={driverRatingObj.pickupsWithCompliments}
                                    loading={driverRatingLoading}
                                />
                            </CustomerReviewWrapper>
                            <CustomerReviewWrapper
                                viewed={complaintsViewed}
                                count={complaintsViewed}
                                handleExpand={handleViewComplaints}
                                driverRatingObj={driverRatingObj}
                            >
                                <ComplaintsWidget
                                    noPadding={true}
                                    operator={operator}
                                    history={history}
                                    pickupsWithIssues={driverRatingObj.pickupsWithIssues}
                                    bulksWithIssues={driverRatingObj.bulksWithIssues}
                                    systemComplaints={driverRatingObj.systemComplaints}
                                    loading={driverRatingLoading}
                                    isDriver={isDriver}
                                    unviewedComplaintIdsState={unviewedComplaintIdsState}
                                    unviewedComplaintBulkIdsState={unviewedComplaintBulkIdsState}
                                />
                            </CustomerReviewWrapper>

                            {isClerk && !isDriver && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant="h6">Driver Permission Required</Typography>
                                    <Typography>Sign in to an driver account to gain access to this tab</Typography>
                                </div>
                            )}
                        </>
                    )}

                    {currentTab === 1 && (
                        <>
                            {driverRatingLoading || completeTripStatisticsLoading ? (
                                <Grid item xs={12}>
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        <CircularProgress />
                                    </Paper>
                                </Grid>
                            ) : (
                                <>
                                    <CompletedTripsWidget
                                        http={http}
                                        completedTrips={_.get(
                                            completeTripStatistics,
                                            'completedTripsWithStats.completedTrips',
                                            []
                                        )}
                                        operator={operator}
                                        commodities={commodities}
                                        tipsGroupedByTrip={tips.tipsGroupedByTrip}
                                        tipsAreEstimated={tips.tipsAreEstimated}
                                        tipsAreEnabled={tips.tipsAreEnabled}
                                        loading={completeTripStatisticsLoading || tipStatsLoading}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 2 && (
                        <>
                            {completeTripStatisticsLoading ? (
                                <Paper
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: theme.spacing.unit,
                                        marginBottom: theme.spacing.unit
                                    }}
                                >
                                    <CircularProgress />
                                </Paper>
                            ) : (
                                <>
                                    <div style={{ marginBottom: theme.spacing.unit }}>
                                        {_.get(operator, 'permissions.payThroughSystem', false) &&
                                            tips.tipsAreEnabled && (
                                                <TipsWidget
                                                    tipBalance={tips.tipBalance}
                                                    theme={theme}
                                                    loading={tipStatsLoading}
                                                />
                                            )}
                                    </div>
                                    {isDriver && (
                                        <div style={{ marginBottom: theme.spacing.unit }}>
                                            <PickupRatingWidget
                                                pickupRating={driverRatingObj.pickupRating}
                                                operator={operator}
                                                driverFinancialInfo={_.get(
                                                    completeTripStatistics,
                                                    'completedTripsWithStats.driverFinancialInfo',
                                                    []
                                                )}
                                                driverRanking={_.get(
                                                    completeTripStatistics,
                                                    'driverRankingPickedUpCmdties.driverRanking',
                                                    []
                                                )}
                                                isDriver={isDriver}
                                                pickedUpCommodities={_.get(
                                                    completeTripStatistics,
                                                    'driverRankingPickedUpCmdties.pickedUpCommodities',
                                                    []
                                                )}
                                                commodities={commodities}
                                                tipBalance={tips.tipBalance}
                                            />
                                        </div>
                                    )}

                                    {isClerk && (
                                        <div style={{ marginBottom: theme.spacing.unit }}>
                                            <CountingRatingWidget
                                                countRating={driverRatingObj.countRating}
                                                isClerk={isClerk}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>

                <AppBar
                    position="fixed"
                    color="default"
                    elevation={0}
                    style={{
                        top: 'auto',
                        bottom: 0,
                        backgroundColor: theme.palette.background.paper,
                        paddingBottom: 'env(safe-area-inset-bottom)',
                        borderTop: `2px solid ${theme.palette.primary.main}`
                    }}
                >
                    <Tabs
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        value={currentTab}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            data-cy="operator-dashboard-tab-upcoming"
                            label={loc('upcoming', lang)}
                            style={{ flex: 1 }}
                        />
                        <Tab
                            data-cy="operator-dashboard-tab-completed"
                            label={loc('completed', lang)}
                            style={{ flex: 1 }}
                        />
                        <Tab data-cy="operator-dashboard-tab-stats" label={loc('stats', lang)} style={{ flex: 1 }} />
                    </Tabs>
                </AppBar>
            </Hidden>
            <LocationAccessDialog
                locationDialogOpen={locationDialogOpen}
                setLocationDialogOpen={setLocationDialogOpen}
                lang={lang}
            />
        </>
    );
}

function LocationAccessDialog({ locationDialogOpen, setLocationDialogOpen, lang }) {
    const [locationDialogCheckbox, setLocationDialogCheckbox] = useState(false);

    const handleLocationDialog = () => {
        setLocationDialogOpen(false);
        if (locationDialogCheckbox) {
            localStorage.setItem('locationAccessDialog', false);
        }
    };

    const handleLocationDialogCheckbox = () => {
        setLocationDialogCheckbox(!locationDialogCheckbox);
    };

    return (
        <Dialog
            open={locationDialogOpen && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)}
            handleClose={handleLocationDialog}
        >
            <DialogTitle>{loc('locationAccess', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{loc('thisAppCollectionLocation', lang)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={locationDialogCheckbox}
                            onChange={handleLocationDialogCheckbox}
                            value={locationDialogCheckbox}
                            data-cy="location-dialog-checkbox-do-not-show-again"
                        />
                    }
                    label={loc('doNotShowThisAgain', lang)}
                />
                <Button onClick={handleLocationDialog} data-cy="location-dialog-checkbox-dismiss">
                    {loc('dismiss', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(withTheme()(Dashboard));
