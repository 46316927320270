import React from 'react';
import _ from 'lodash';

import { withTheme, Icon, IconButton, Typography } from '@material-ui/core';
import moment from 'moment-timezone';

/**
 * You may select an interval to use for the carousal. 
 *    
 * dateInterval: 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'isoWeek', 'month', 'quarter', 'year'
 * 
 * Will need to made sure handleGoBackward, handleGoFowards account for interval aswell
 * */

function DateIntervalCarousal({ startDate, endDate, handleGoBackwards, handleGoForwards, theme, dateInterval }) {
    const startOfCurrentInterval = moment().startOf(dateInterval);
    const endOfCurrentInterval = moment().endOf(dateInterval);

    const startOfLastInterval = moment()
        .subtract(1, dateInterval === 'isoWeek' ? 'week' : dateInterval)
        .startOf(dateInterval);
    const endOfLastInterval = moment()
        .subtract(1, dateInterval === 'isoWeek' ? 'week' : dateInterval)
        .endOf(dateInterval);

    const isCurrentInteravl =
        startDate.isBetween(startOfCurrentInterval, endOfCurrentInterval, null, '[]') ||
        endDate.isBetween(startOfCurrentInterval, endOfCurrentInterval, null, '[]') ||
        (startDate.isBefore(startOfCurrentInterval) && endDate.isAfter(endOfCurrentInterval));

    // Check if the given date range is within last week
    const isLastInterval =
        startDate.isBetween(startOfLastInterval, endOfLastInterval, null, '[]') ||
        endDate.isBetween(startOfLastInterval, endOfLastInterval, null, '[]') ||
        (startDate.isBefore(startOfLastInterval) && endDate.isAfter(endOfLastInterval));

    let contentToDisplay = `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;

    if (isCurrentInteravl) {
        contentToDisplay = `This ${_.capitalize(dateInterval === 'isoWeek' ? 'week' : dateInterval)}`;
    } else if (isLastInterval) {
        contentToDisplay = `Last ${_.capitalize(dateInterval === 'isoWeek' ? 'week' : dateInterval)}`;
    }

    return (
        <>
            <IconButton onClick={handleGoBackwards}>
                <Icon style={{ position: 'relative', left: 4 }}>arrow_back_ios</Icon>
            </IconButton>
            <Typography style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                {contentToDisplay}
            </Typography>
            <IconButton aria-label="left" onClick={handleGoForwards} disabled={isCurrentInteravl}>
                <Icon>arrow_forward_ios</Icon>
            </IconButton>
        </>
    );
}

export default withTheme()(DateIntervalCarousal);
