import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _, { startCase } from 'lodash';
import { _user } from 'std';
import crypto from 'crypto';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    MenuItem,
    Grid,
    colors,
    Icon,
    IconButton,
    InputAdornment,
    Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { getTextInput } from '../helperFunctions';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { TextField } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';

const iconColors = [
    'pink',
    'red',
    'deepOrange',
    'orange',
    'amber',
    'yellow',
    'lightGreen',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'grey',
    'black'
];
function OTCGroupForm(props) {
    const {
        OTCGroup,
        allOTCGroup,
        loading,
        editing,
        open,
        theme,
        fullScreen,
        onSubmit,
        onClose,
        width,
        operator,
        allCollectors
    } = props;
    const isMultiCollector = _.get(operator, 'collectors', []).length > 1;
    const allAccessCodes = allOTCGroup.map(g => g.accessCode);
    const { lang } = useContext(LocalizationContext);

    const handleFormSubmit = values => {
        const collector = _.get(values, 'collector', null);
        onSubmit({
            ...values,
            collector: _.isEmpty(collector) ? null : collector
        });
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(OTCGroup, '_id'),
            name: _.get(OTCGroup, 'name', ''),
            email: _.get(OTCGroup, 'email', ''),
            defaultIcon: _.get(OTCGroup, 'defaultIcon', ''),
            defaultColor: _.get(OTCGroup, 'defaultColor', ''),
            accessCode: _.get(OTCGroup, 'accessCode', getNewUniqueAccessCode(allAccessCodes)),
            collector: _.get(
                OTCGroup,
                'collector._id',
                _user.isSystemAdmin(operator) ? '' : _.get(operator, 'collector._id', '')
            )
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a name'),
            email: Yup.string()
                .email()
                .trim()
                .required(),
            defaultIcon: Yup.string().required('You must select an icon'),
            defaultColor: Yup.string().required('You must select a color')
        }),
        onSubmit: handleFormSubmit
    });

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        getFieldProps
    } = formik;

    const handlePaste = (field, e) => {
        e.preventDefault();
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        paste = field === 'phone' ? paste.replace(/[^0-9]/g, '').trim() : paste;
        setFieldValue(field, paste);
    };
    return (
        <>
            <Dialog open={open} fullScreen={fullScreen}>
                <DialogTitle
                    style={{
                        paddingBottom: 0
                    }}
                >
                    {editing ? `Update ${_.get(OTCGroup, 'name', '')}` : 'Create'}
                </DialogTitle>
                <DialogContent>
                    <div style={{ marginTop: theme.spacing.unit }}>
                        <Grid container spacing={theme.spacing.unit}>
                            <Grid item xs={6}>
                                {getTextInput(theme, 'name', 'Name', formik)}
                            </Grid>
                            <Grid item xs={6}>
                                {getTextInput(theme, 'email', 'Email', formik)}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{ display: 'flex', flexDirection: 'row', marginTop: theme.spacing.unit * 2 }}
                            >
                                {!_.isEmpty(values.defaultIcon) && (
                                    <MDIcon
                                        path={_.get(allIcons, convertToImportName(values.defaultIcon))}
                                        size={2.2}
                                        color={
                                            _.isNil(colors[values.defaultColor])
                                                ? values.defaultColor
                                                : colors[values.defaultColor][500]
                                        }
                                    />
                                )}
                                <TextField
                                    {...getFieldProps('defaultIcon')}
                                    style={{
                                        marginRight: theme.spacing.unit,
                                        width: _.isEmpty(values.defaultIcon) ? 250 : 192
                                    }}
                                    name="defaultIcon"
                                    label="Default Icon"
                                    variant="outlined"
                                    error={
                                        _.get(touched, 'defaultIcon', false) && _.get(errors, 'defaultIcon', false)
                                            ? true
                                            : null
                                    }
                                    helperText={
                                        _.get(touched, 'defaultIcon', false) && _.get(errors, 'defaultIcon', false)
                                            ? _.get(errors, 'defaultIcon', false)
                                            : null
                                    }
                                />
                                <IconButton
                                    style={{ margin: 0, padding: 0 }}
                                    onClick={() => {
                                        window.open('https://materialdesignicons.com/');
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <TextField
                                    {...getFieldProps('defaultColor')}
                                    select
                                    fullWidth
                                    name="defaultColor"
                                    label="Default Color"
                                    variant="outlined"
                                    error={
                                        _.get(touched, 'defaultColor', false) && _.get(errors, 'defaultColor', false)
                                            ? true
                                            : null
                                    }
                                    helperText={
                                        _.get(touched, 'defaultColor', false) && _.get(errors, 'defaultColor', false)
                                            ? _.get(errors, 'defaultColor', false)
                                            : null
                                    }
                                >
                                    {iconColors.map(color => {
                                        return (
                                            <MenuItem key={color} value={color}>
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: theme.spacing.unit,
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: '100%',
                                                            backgroundColor: getColor(color)
                                                        }}
                                                    />
                                                    {startCase(color)}
                                                </div>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>

                            {(_user.isSystemAdmin(operator) ||
                                (_user.isCollectorAdmin(operator) && isMultiCollector)) && (
                                <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <TextField
                                        {...getFieldProps('collector')}
                                        variant="outlined"
                                        name="collector"
                                        label="Collector"
                                        select
                                        error={
                                            _.get(touched, 'collector', false) && _.get(errors, 'collector', false)
                                                ? true
                                                : null
                                        }
                                        fullWidth
                                        helperText={_.get(errors, 'collector', false)}
                                    >
                                        {_user.isSystemAdmin(operator) && (
                                            <MenuItem key={'no-collector'} value={''}>
                                                None
                                            </MenuItem>
                                        )}
                                        {allCollectors.map(collector => {
                                            return (
                                                <MenuItem key={collector} value={_.get(collector, '_id', null)}>
                                                    {_.get(collector, 'name', '')}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <TextField
                                    {...getFieldProps('accessCode')}
                                    label={'Access Code'}
                                    fullWidth
                                    onChange={undefined}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    data-cy="accessCode-refresh"
                                                    color="primary"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'accessCode',
                                                            getNewUniqueAccessCode(allAccessCodes)
                                                        );
                                                    }}
                                                >
                                                    <Icon>refresh</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {editing && (
                                    <Typography
                                        onClick={() => {
                                            window.open(
                                                `${process.env.REACT_APP_ORIGIN_URL}/external?code=${_.get(
                                                    OTCGroup,
                                                    'accessCode',
                                                    ''
                                                )}`,
                                                '_blank'
                                            );
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            fontSize: 12,
                                            color: '#0000EE',
                                            marginTop: 5
                                        }}
                                    >
                                        {`${process.env.REACT_APP_ORIGIN_URL}/external?code=${_.get(
                                            OTCGroup,
                                            'accessCode',
                                            ''
                                        )}`}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                data-cy="submit"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                {loc('submit', lang)}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withMobileDialog()(withTheme()(OTCGroupForm));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
function getColor(color) {
    try {
        if (_.isNil(color) || color === '') {
            return '';
        }
        return color === 'black' ? colors['grey'][900] : colors[color][600];
    } catch (e) {
        return '';
    }
}
function getNewUniqueAccessCode(existingCodes) {
    let newCode = crypto
        .randomBytes(Math.ceil(4))
        .toString('hex') // convert to hexadecimal format
        .slice(0, 8)
        .toUpperCase(); // return required number of characters
    let attempt = 0;
    while (existingCodes.includes(newCode) && attempt < 1000) {
        newCode = crypto
            .randomBytes(Math.ceil(4))
            .toString('hex')
            .slice(0, 8)
            .toUpperCase();
        attempt++;
    }
    return newCode;
}
