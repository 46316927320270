import React, { useContext, useState, useMemo } from 'react';
import _ from 'lodash';

import { _user } from 'std';

import Dialog from '@material-ui/core/Dialog';
import { MobileStepper, TextField } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { withTheme } from '@material-ui/core/styles';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import Cashout from 'components/RedemptionDialog/screens/Cashout';
import useRedemptionDialog from 'components/RedemptionDialog/hooks/useRedemptionDialog';
import AusDirectBanking from 'components/RedemptionDialog/screens/AusDirectBanking';
import InteracETransfer from 'components/RedemptionDialog/screens/InteracETransfer';
import Cheque from 'components/RedemptionDialog/screens/Cheque';
import BankTransfer from 'components/RedemptionDialog/screens/BankTranfer';
import { useEffect } from 'react';
import AutoRedemptionSummary from './screens/AutoRedemptionSummary';

function AutoRedemptionDialog({
    open,
    customer,
    operator,
    onClose,
    theme,
    http,
    redemptionFee,
    redemptionType = 'customer',
    redemptions,
    redemptionPending = false,
    email,
    accountName,
    charityPreferred = null,
    onDonateSuccess = () => {},
    onRedeemSuccess = () => {},
    reloadCharity = () => {},
    reloadCustomer,
    subdivisionPreferred = null,
    emailVerificationRequired,
    emailVerificationRequiredForETransfer,
    handleVerifyEmailDialog,
    charityEnabled = false,
    balance,
    collectors,
    redemptionOptions,
    charity = null,
    deletingAccount = false,
    disablingAccount = false,
    autoRedemptionDetails,
    setAutoRedemptionDetails,
    setAutoRedemptionPreference,
    setAutoRedemptionDialogOpen
}) {
    const banking = useMemo(() => {
        const redemptionsWithBanking = _.filter(redemptions, redemption => !_.isEmpty(_.get(redemption, 'banking')));
        return _.get(_.last(redemptionsWithBanking), 'banking', null);
    }, [redemptions]);

    const {
        paymentModel,
        activeStep,
        modifiedRedemptionFee,
        redemptionForm,
        cashedOutBy,
        securityAnswerDialogOpen,
        askForPreviousBankInfo,
        usePreviousBankingInfo,
        formErrors,
        loading,
        handlePaymentModelChange,
        handleChangeCashedOutBy,
        handleNext,
        handleBack,
        handleChange,
        handleUsePreviousBankingInformation,
        handleSecurityAnswerDialog,
        handleSetPaymentModel,
        securityQuestions,
        handleResetForm
    } = useRedemptionDialog({
        http,
        customer,
        redemptionFee,
        redemptionType,
        redemptions,
        redemptionPending,
        email,
        authorizedEmails: _.get(customer, 'authorizedEmails', [])
            .filter(authorization => authorization.authorized)
            .map(authorization => authorization.email),
        accountName,
        charityPreferred,
        subdivisionPreferred,
        onDonateSuccess,
        onRedeemSuccess,
        reloadCharity,
        reloadCustomer,
        banking,
        open,
        deletingOrDisablingAccount: deletingAccount || disablingAccount,
        emailVerificationRequired,
        emailVerificationRequiredForETransfer,
        handleVerifyEmailDialog,
        charityEnabled,
        autoRedemption: true
    });

    const { lang } = useContext(LocalizationContext);

    const handleSave = () => {
        let {
            email,
            question,
            passphrase,
            bsbNumber,
            accountNumber,
            accountName,
            street,
            city,
            province,
            country,
            postalCode,
            institutionNumber,
            transitNumber
        } = redemptionForm;

        if (paymentModel === 'CHEQUE') {
            passphrase = `${accountName} - ${street} ${city} ${province} ${country} ${postalCode}`;
        }

        const redemptionPreferences = {
            paymentModel,
            email,
            question,
            passphrase,
            bsbNumber,
            accountNumber,
            accountName,
            street,
            city,
            province,
            country,
            postalCode,
            institutionNumber,
            transitNumber,
            usePreviousBankingInfo,
            cashedOutBy: _.isEmpty(cashedOutBy) ? null : cashedOutBy,
            deletingOrDisablingAccount: deletingAccount || disablingAccount
        };

        setAutoRedemptionDetails(redemptionPreferences);
        setAutoRedemptionPreference(true);
        setAutoRedemptionDialogOpen(false);
    };

    const firstStep = useMemo(
        () => (
            <Cashout
                operator={operator}
                theme={theme}
                balance={balance}
                customer={customer}
                charity={charity}
                collectors={collectors}
                handleBack={handleBack}
                handleNext={handleNext}
                handleChange={handlePaymentModelChange}
                handleChangeCashedOutBy={handleChangeCashedOutBy}
                paymentModel={paymentModel}
                cashedOutBy={cashedOutBy}
                modifiedRedemptionFee={modifiedRedemptionFee}
                redemptionOptions={redemptionOptions}
                redemptionType={redemptionType}
                activeStep={activeStep}
                canRedeemWithoutDonation={true}
                setPaymentModel={handleSetPaymentModel}
                redemptions={redemptions}
                loading={loading}
                disablingAccount={disablingAccount}
                deletingAccount={deletingAccount}
            />
        ),
        [handleBack, handleNext]
    );

    const secondStep = useMemo(() => {
        switch (paymentModel) {
            case 'AUS_DIRECT_BANKING':
                return (
                    <AusDirectBanking
                        askForPreviousBankInfo={askForPreviousBankInfo}
                        theme={theme}
                        banking={banking}
                        handleUsePreviousBankingInformation={handleUsePreviousBankingInformation}
                        redemptionForm={redemptionForm}
                        handleChange={handleChange}
                        usePreviousBankingInfo={usePreviousBankingInfo}
                        formErrors={formErrors}
                        donationDisabled={false}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                );
            case 'INTERAC_ETRANSFERS':
                return (
                    <InteracETransfer
                        customer={customer}
                        charity={charity}
                        redemptionType={redemptionType}
                        theme={theme}
                        redemptionForm={redemptionForm}
                        formErrors={formErrors}
                        handleChange={handleChange}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        etransfername={loc('ETRANSFERS_NAME', lang)}
                        securityAnswerDialogOpen={securityAnswerDialogOpen}
                        handleSecurityAnswerDialog={handleSecurityAnswerDialog}
                        securityQuestions={securityQuestions}
                        onClose={onClose}
                        emailVerificationRequire={emailVerificationRequired}
                        emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                    />
                );
            case 'CHEQUE':
                return (
                    <Cheque
                        theme={theme}
                        redemptionForm={redemptionForm}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        formErrors={formErrors}
                        redemptionType={redemptionType}
                    />
                );

            case 'BANK_TRANSFER':
                return (
                    <BankTransfer
                        askForPreviousBankInfo={askForPreviousBankInfo}
                        theme={theme}
                        banking={banking}
                        handleUsePreviousBankingInformation={handleUsePreviousBankingInformation}
                        redemptionForm={redemptionForm}
                        handleChange={handleChange}
                        usePreviousBankingInfo={usePreviousBankingInfo}
                        formErrors={formErrors}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        donationDisabled={false}
                    />
                );
            default:
                return null;
        }
    }, [paymentModel, handleBack, handleNext]);

    const getData = (paymentModel, collectors, cashedOutBy, redemptionForm, usePreviousBankingInfo, banking) => {
        switch (paymentModel) {
            case 'CASH':
                return { collector: _.get(_.find(collectors, collector => collector._id === cashedOutBy), 'name') };
            case 'AUS_DIRECT_BANKING':
                return usePreviousBankingInfo
                    ? { accountNumber: _.get(banking, 'accountNumberMasked', 'N/A') }
                    : _.pick(redemptionForm, ['accountName', 'accountNumber', 'bsbNumber']);
            case 'INTERAC_ETRANSFERS':
                return _.pick(redemptionForm, ['question']);
            case 'CHEQUE':
                return _.pick(redemptionForm, ['accountName', 'street', 'city', 'postalCode', 'province', 'country']);
            case 'BANK_TRANSFER':
                return usePreviousBankingInfo
                    ? { accountNumber: _.get(banking, 'accountNumberMasked', 'N/A') }
                    : _.pick(redemptionForm, ['accountNumber', 'transitNumber', 'institutionNumber']);
        }
    };

    const thirdStep = useMemo(
        () => (
            <AutoRedemptionSummary
                handleBack={handleBack}
                handleSave={handleSave}
                loading={loading}
                method={paymentModel}
                data={getData(paymentModel, collectors, cashedOutBy, redemptionForm, usePreviousBankingInfo, banking)}
                usePreviousBankingInfo={usePreviousBankingInfo}
            />
        ),
        [handleBack, getData]
    );

    const steps = [firstStep, secondStep, thirdStep];

    useEffect(() => {
        if (open) {
            if (!_.isEmpty(autoRedemptionDetails)) {
                handleSetPaymentModel(autoRedemptionDetails.paymentModel);
                handleChangeCashedOutBy({ target: { value: autoRedemptionDetails.cashedOutBy } });
                if (_.get(autoRedemptionDetails, 'usePreviousBankingInfo', false)) {
                    let redemptionDetails = {
                        ..._.omit(autoRedemptionDetails, [
                            'paymentModel',
                            'usePreviousBankingInfo',
                            'cashedOutBy',
                            'deletingOrDisablingAccount'
                        ]),
                        bsbNumber: '',
                        accountNumber: '',
                        transitNumber: '',
                        institutionNumber: ''
                    };
                    handleResetForm(redemptionDetails);
                } else {
                    if (['AUS_DIRECT_BANKING', 'BANK_TRANSFER'].includes(_.get(autoRedemptionDetails, 'paymentModel')))
                        handleUsePreviousBankingInformation(false);
                    handleResetForm(
                        _.omit(autoRedemptionDetails, [
                            'paymentModel',
                            'usePreviousBankingInfo',
                            'cashedOutBy',
                            'deletingOrDisablingAccount'
                        ])
                    );
                }
            } else {
                handleResetForm();
            }
        }
    }, [open, autoRedemptionDetails, customer]);

    if (!open) return null;

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {'Auto Redemption Preferences'}
            </DialogTitlePrimary>
            {steps.length > 1 && (
                <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={<div style={{ height: theme.spacing.unit * 2 }} />}
                    backButton={<div style={{ height: theme.spacing.unit * 2 }} />}
                    style={{
                        backgroundColor: theme.palette.background.paper,
                        paddingTop: theme.spacing.unit * 4
                    }}
                />
            )}
            <div>{steps[activeStep]}</div>
        </Dialog>
    );
}

export default withTheme()(AutoRedemptionDialog);
