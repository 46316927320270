import React, { Component, useEffect, useState, useContext } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';

import _ from 'lodash';
import * as qs from 'query-string';
import io from 'socket.io-client';
import crypto from 'crypto';

import * as terms from 'localizations/terms';
import {
    getDeviceInformation,
    formatsSecondsToTime,
    getWindowWidth,
    getWindowHeight,
    isAUSRegion,
    isCONRegion
} from 'utils/misc.js';
import { _time } from 'std';
import moment from 'moment-timezone';

import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import AppStoreLink from 'components/AppStoreLink/AppStoreLink';
import AlternativeRegistrations from 'components/AlternativeRegistrations/AlternativeRegistrations';
import OAuthDialog from 'components/OAuthDialog/OAuthDialog';
import HowItWorks from 'components/InfoDialogs/HowItWorks';
import FAQ from 'components/InfoDialogs/FAQ';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Collapse from '@material-ui/core/Collapse';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ExternalLink from 'components/ExternalLink/ExternalLink';
import FakeLink from 'components/FakeLink/FakeLink';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';
import { Helmet } from 'react-helmet';

import { _charity } from 'std';

import { deviceHelper, isEXPRegion, isMXDRegion } from 'utils/misc';

import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';

import { Checkbox, Divider, Switch, Icon, Select, MenuItem, withTheme } from '@material-ui/core';
import { InAppBrowser } from '@ionic-native/in-app-browser'; //Browser.close doesn't work on android had to use the cordova in-app-browser plugin
import { NativeBiometric } from '@capgo/capacitor-native-biometric';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

import { AVAILABLE_LANGS, ROLES } from '../../constants';
import PasswordInput from 'components/InputComponents/PasswordInput';
import withWidth from '@material-ui/core/withWidth';
import RoundSquareButton from 'components/Buttons/RoundSquareButton';
import ContactForm from 'components/ContactForm/ContactForm';

const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
const logo = require(process.env.REACT_APP_LOGO);

const FirebaseAdmin = require('firebase/firebaseAdmin.js');
const splashScreenBackgroundOverlay = require('icons/Bottle-Pattern-White-Tiles-2.svg');
const splashScreenBackgroundOverlayNoMilk = require('icons/Bottle-Pattern-No-Jug.svg');
const splashScreenBackgroundOverlayEXP_1 = require('icons/Beverage_Icons_Express.png');
const splashScreenBackgroundOverlayEXP_2 = require('icons/Beverage_Icons_Express_2.png');

const backgroundImage = isSmallScreen => {
    if (isEXPRegion() && isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_1;
    }

    if (isEXPRegion() && !isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_2;
    }

    if (isCONRegion()) {
        // return splashScreenBackgroundOverlayNoMilk;
        return null;
    }

    return splashScreenBackgroundOverlay;
};

class Login extends Component {
    static contextType = LocalizationContext;

    constructor(props) {
        super(props);
        this.codeInput = null;
    }

    state = {
        tabIndex: undefined,
        helpCenterOpen: false,
        howItWorksOpen: false,
        faqOpen: false,
        contactFormOpen: false,
        email: '',
        password: '',
        oAuthUser: undefined,
        oAuthUID: null,
        oAuthRegistrationCharity: null,
        attemptingLogin: false,
        snackbarOpen: false,
        snackbarMessage: '',
        snackbarVariant: 'error',
        forgotPasswordDialogOpen: false,
        recoveryEmail: '',
        showAuthDialog: false,
        authDialogMessage: '',
        isInAppBrowser: false,
        promoCode: null,
        twoFactorAuthenticationCode: '',
        twoFactorAuthenticationPhoneNumber: JSON.parse(window.sessionStorage.getItem('login-phone')) || '',
        twoFactorAuthenticationStep: JSON.parse(window.sessionStorage.getItem('login-success')) || false,
        showIdentityVerification: false,
        identityVerificationEnabled:
            JSON.parse(window.sessionStorage.getItem('identity-verification-enabled')) || false,
        identityVerificationAvailable:
            JSON.parse(window.sessionStorage.getItem('identity-verification-available')) || false,
        identityVerificationUnavailableDialogOpen: false,
        securityQuestion: window.sessionStorage.getItem('security-question') || '',
        securityAnswer: '',
        postalCode: '',
        resendDisabled: false,
        // twoFactorAuthenticationCodeInputDisabled: true,
        channel: '',
        phoneNumberInputRequired: false,
        canEditNumber: false,
        enableSendTime: null,
        trustThisDevice: false,
        authenticationSID: '',
        accountLocked: false,
        lockAccountUntil: null,
        showFBDiscontinuedDialog: false,
        socket: null,
        registrationTagline: null,
        windowWidth: getWindowWidth(),
        windowHeight: getWindowHeight(),
        biometricsAvailable: false,
        biometricsOptIn: false,
        biometricsHasCredentials: false,
        biometricSignInLoading: false
    };

    componentWillMount() {
        document.getElementsByTagName('body')[0].className = undefined;
    }

    async componentDidMount() {
        //document.title = `${process.env.REACT_APP_BRAND_NAME} | Sign in`;

        this.queryUrl();

        let deviceInfo = await getDeviceInformation();
        let biometricsAvailable = false,
            biometricsHasCredentials = false;

        if (deviceHelper.isNativeApp()) {
            App.addListener('appUrlOpen', data => {
                if (data && data.url) {
                    let nativeUrl = data.url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//, '');
                    console.log('nativeUrl', nativeUrl);
                    this.props.history.push(`/${nativeUrl}`);
                }
            });

            try {
                const availableResult = await NativeBiometric.isAvailable();
                biometricsAvailable = availableResult.isAvailable;
                biometricsHasCredentials = JSON.parse(window.localStorage.getItem('biometricsHasCredentials'));
            } catch (err) {}
        }

        let resRegistrationConfig = await this.props.http.getJSON('/registrationConfig', false, true);
        if (resRegistrationConfig.ok) {
            this.setState({
                registrationTagline: _.get(resRegistrationConfig, `data.tagline.${this.context.lang}`, null)
            });
        }

        window.addEventListener('resize', this.handleResize);
        this.setState({
            isInAppBrowser: deviceInfo.isInAppBrowser,
            biometricsAvailable,
            biometricsHasCredentials,
            biometricsOptIn: biometricsHasCredentials
        });

        //automatically pop up the finger/face scan dialog
        if (biometricsHasCredentials && biometricsAvailable) {
            this.handleBiometricsSignIn();
        }
    }

    queryUrl = async () => {
        let query = qs.parse(this.props.location.search);
        const queryKeys = Object.keys(query);
        const promoQueryKey = _.find(queryKeys, key => key.toUpperCase() === 'PROMOCODE'); //make query key for promo code case insensitive
        if (!_.isNil(promoQueryKey)) this.setState({ promoCode: query[promoQueryKey] });
    };
    handleResize = e => {
        const setWindowDimensions = () => {
            this.setState({ windowWidth: getWindowWidth(), windowHeight: getWindowHeight() });
        };
        setTimeout(function() {
            setWindowDimensions();
        }, 250);
    };

    redirectToHome = () => {
        this.props.history.push('/');
    };

    handleIdentityVerificationUnavailableDialog = state => () => {
        this.setState({
            identityVerificationUnavailableDialogOpen: !this.state.identityVerificationAvailable && state
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleChange = e => {
        this.setState(_.set(this.state, e.target.name, e.target.value));
    };

    handleCheckbox = e => {
        this.setState(_.set(this.state, e.target.name, e.target.checked));
    };

    handleRecoveryEmail = e => {
        let value = e.target.value.toLowerCase();
        this.setState(_.set(this.state, e.target.name, value));
    };

    handleUserPhoneNumber = e => {
        let value = e.target.value.replace(/\D+/g, '');
        this.setState(_.set(this.state, e.target.name, value));
    };

    handleForgotPasswordDialog = state => () => {
        this.setState({ forgotPasswordDialogOpen: state, recoveryEmail: this.state.email });
    };

    handleRequestResetPassword = async () => {
        const { recoveryEmail } = this.state;

        let res = await this.props.http.post('/requestResetPassword', { email: recoveryEmail });
        if (res.ok) {
            this.setState({
                forgotPasswordDialogOpen: false,
                snackbarOpen: true,
                snackbarMessage: loc('emailMatchCheck', this.context.lang),
                snackbarVariant: 'info'
            });
        } else {
            this.setState({
                snackbarOpen: true,
                snackbarMessage: loc('somethingWentWrong', this.context.lang),
                snackbarVariant: 'error'
            });
        }
    };

    handleFAQ = state => () => {
        this.setState({ helpCenterOpen: false, faqOpen: state });
    };

    handleHowItWorks = state => () => {
        this.setState({ helpCenterOpen: false, howItWorksOpen: state });
    };

    handleContactForm = state => () => {
        this.setState({ helpCenterOpen: false, contactFormOpen: state });
    };

    handleContactSubmitSuccess = () => {
        this.setState({
            helpCenterOpen: false,
            contactFormOpen: false,
            snackbarOpen: true,
            snackbarMessage: loc('contactFormSuccess', this.context.lang),
            snackbarVariant: 'success'
        });
    };

    handleHelpCenter = state => () => {
        this.setState({ helpCenterOpen: state });
    };

    handleRegisterOAuth = async oAuthUser => {
        const { oAuthRegistrationCharity } = this.state;
        let idToken = await FirebaseAdmin.getCurrentUsersIDToken();
        let dispatchObj = await FirebaseAdmin.registerWithOAuthUser(oAuthUser, idToken, oAuthRegistrationCharity);
        this.setState({ showAuthDialog: false });
        if (dispatchObj instanceof Error) {
            this.setState({
                attemptingLogin: false,
                snackbarOpen: true,
                snackbarMessage: loc(dispatchObj.message, this.context.lang) || dispatchObj.message,
                snackbarVariant: 'error'
            });
        } else {
            if (dispatchObj.twoFactorAuthenticationCodeRequired) {
                this.setState({
                    showAuthDialog: false,
                    attemptingLogin: false,
                    snackbarOpen: true,
                    snackbarMessage: loc('enterAuthCode', this.context.lang),
                    snackbarVariant: 'info',
                    twoFactorAuthenticationStep: true,
                    twoFactorAuthenticationCode: '',
                    twoFactorAuthenticationPhoneNumber: dispatchObj.twoFactorAuthenticationPhoneNumber,
                    channel: dispatchObj.channel,
                    phoneNumberInputRequired: _.isEmpty(dispatchObj.twoFactorAuthenticationPhoneNumber),
                    oAuthUID: _.get(oAuthUser, 'oAuth.uid'),
                    identityVerificationEnabled: dispatchObj.identityVerificationEnabled,
                    identityVerificationAvailable: dispatchObj.identityVerificationAvailable,
                    securityQuestion: dispatchObj.securityQuestion
                });
                setSessionStorageItems({
                    securityQuestion: dispatchObj.securityQuestion,
                    identityVerificationEnabled: dispatchObj.identityVerificationEnabled,
                    identityVerificationAvailable: dispatchObj.identityVerificationAvailable,
                    loginSuccess: true,
                    loginOAuthUID: _.get(oAuthUser, 'oAuth.uid'),
                    loginPhone: dispatchObj.twoFactorAuthenticationPhoneNumber
                });
                this.handleSendVerificationCode();
            } else {
                this.props.dispatch(dispatchObj);
            }
        }
    };

    handleOAuthRegistrationCharity = charity => {
        this.setState({ oAuthRegistrationCharity: charity });
    };

    handleCancelOAuthRegistration = async () => {
        attemptSocketClose(this.state.socket);

        await FirebaseAdmin.logOutOAuth();
        this.setState({ showAuthDialog: false, oAuthUser: null });
    };

    handleVerifyIdentity = async e => {
        const { securityAnswer, postalCode, oAuthUID, email, identityVerificationAvailable } = this.state;
        if (!identityVerificationAvailable) {
            this.setState({ identityVerificationUnavailableDialogOpen: true });
            return;
        }
        let bodyOAuthUID = oAuthUID;
        if (_.isNil(bodyOAuthUID) || _.isEmpty(bodyOAuthUID)) {
            bodyOAuthUID = window.sessionStorage.getItem('login-oAuthUID') || oAuthUID;
        }
        let bodyEmail = email;
        if (_.isNil(bodyEmail) || _.isEmpty(bodyEmail)) {
            bodyEmail = window.sessionStorage.getItem('login-email');
        }
        let body = {
            securityAnswer,
            postalCode,
            oAuthUID: bodyOAuthUID,
            email: bodyEmail
        };
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/verifyIdentity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    this.setState({
                        canEditNumber: true,
                        phoneNumberInputRequired: true,
                        snackbarOpen: true,
                        snackbarMessage: loc('verificationSuccessful', this.context.lang),
                        snackbarVariant: 'success',
                        showIdentityVerification: false
                    });
                    throw new Break();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = loc('badRequest', this.context.lang); // error message to be generated locally
                    }
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: message,
                        snackbarVariant: 'error'
                    });
                } else if (res.status === 401) {
                    this.setState({
                        attemptingLogin: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('identityVerificationFailed', this.context.lang),
                        snackbarVariant: 'error',
                        password: ''
                    });
                    throw new Break();
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        this.setState({
                            accountLocked: true,
                            lockAccountUntil: json.lockAccountUntil
                        });
                    } catch (err) {}
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: loc('tooManyAttempts', this.context.lang),
                        snackbarVariant: 'error',
                        password: ''
                    });
                    clearSessionStorageItems();

                    //open account locked dialog and then -> this.props.history.push(`/`);
                    throw new Break();
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /verifyIdentity', err);
                this.setState({
                    snackbarOpen: true,
                    snackbarMessage: loc('connectionErr', this.context.lang),
                    snackbarVariant: 'error'
                });
            });
    };

    // Handles initial OAuth click
    handleoAuth = async providerType => {
        this.setState({ showAuthDialog: true, authDialogMessage: loc('login2', this.context.lang), password: '' });

        attemptSocketClose(this.state.socket);

        let oAuthUser = await FirebaseAdmin.authenticateWithoAuth(providerType);
        if (oAuthUser instanceof Error) {
            this.setState({
                showAuthDialog: false,
                attemptingLogin: false,
                snackbarOpen: true,
                snackbarMessage:
                    loc(oAuthUser.message, this.context.lang, {
                        providerType,
                        serviceEmailAddress: process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
                    }) || oAuthUser.message,
                snackbarVariant: 'error'
            });
        } else {
            let idToken = await FirebaseAdmin.getCurrentUsersIDToken();
            if (!_.isNil(idToken)) {
                let dispatchObj = await FirebaseAdmin.loginWithOAuthUser(oAuthUser, idToken);
                if (dispatchObj instanceof Error) {
                    this.setState({
                        showAuthDialog: false,
                        attemptingLogin: false,
                        snackbarOpen: true,
                        snackbarMessage: loc(dispatchObj.message, this.context.lang) || dispatchObj.message,
                        snackbarVariant: 'error'
                    });
                } else {
                    if (dispatchObj.isNotRegistered) {
                        this.setState({
                            oAuthUser: oAuthUser,
                            showAuthDialog: true
                        });
                    } else if (dispatchObj.twoFactorAuthenticationCodeRequired) {
                        this.setState({
                            showAuthDialog: false,
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc('enterAuthCode', this.context.lang),
                            snackbarVariant: 'info',
                            twoFactorAuthenticationStep: true,
                            twoFactorAuthenticationCode: '',
                            twoFactorAuthenticationPhoneNumber: dispatchObj.twoFactorAuthenticationPhoneNumber,
                            channel: dispatchObj.channel,
                            phoneNumberInputRequired: _.isEmpty(dispatchObj.twoFactorAuthenticationPhoneNumber),
                            oAuthUID: _.get(oAuthUser, 'oAuth.uid'),
                            identityVerificationEnabled: dispatchObj.identityVerificationEnabled,
                            identityVerificationAvailable: dispatchObj.identityVerificationAvailable,
                            securityQuestion: dispatchObj.securityQuestion
                        });
                        setSessionStorageItems({
                            securityQuestion: dispatchObj.securityQuestion,
                            identityVerificationEnabled: dispatchObj.identityVerificationEnabled,
                            identityVerificationAvailable: dispatchObj.identityVerificationAvailable,
                            loginSuccess: true,
                            loginOAuthUID: _.get(oAuthUser, 'oAuth.uid'),
                            loginPhone: dispatchObj.twoFactorAuthenticationPhoneNumber
                        });
                        this.handleSendVerificationCode();
                    } else if (dispatchObj.accountLocked) {
                        await FirebaseAdmin.logOutOAuth();
                        this.setState({
                            showAuthDialog: false,
                            attemptingLogin: false,
                            accountLocked: true,
                            oAuthUID: null,
                            lockAccountUntil: dispatchObj.lockAccountUntil
                        });
                        clearSessionStorageItems();
                    } else {
                        if (dispatchObj.lang) {
                            this.props.setLang(dispatchObj.lang);
                        }
                        this.props.dispatch(dispatchObj);
                    }
                }
            }
        }
    };

    handleBack = () => {
        this.setState({
            tabIndex: undefined,
            howItWorksOpen: false,
            faqOpen: false,
            contactFormOpen: false,
            helpCenterOpen: false,
            password: '',
            oAuthUser: undefined,
            oAuthUID: null,
            oAuthRegistrationCharity: null,
            attemptingLogin: false,
            snackbarOpen: false,
            snackbarMessage: '',
            snackbarVariant: 'error',
            forgotPasswordDialogOpen: false,
            showAuthDialog: false,
            authDialogMessage: '',
            isInAppBrowser: false,
            promoCode: null,
            twoFactorAuthenticationCode: '',
            twoFactorAuthenticationPhoneNumber: '',
            twoFactorAuthenticationStep: false,
            resendDisabled: false,
            // twoFactorAuthenticationCodeInputDisabled: true,
            channel: '',
            phoneNumberInputRequired: false,
            enableSendTime: null,
            trustThisDevice: false
        });
        clearSessionStorageItems();
    };

    disableSendButton = timeoutInSeconds => {
        this.setState({ resendDisabled: true });
        setTimeout(() => this.setState({ resendDisabled: false }), timeoutInSeconds * 1000);
    };

    handleSendVerificationCode = async (channel = 'sms') => {
        if (!this.state.twoFactorAuthenticationStep && !window.sessionStorage.getItem('login-success')) {
            this.handleBack();
            return;
        }
        // const { http } = this.props;
        const { twoFactorAuthenticationPhoneNumber, authenticationSID, oAuthUID, email } = this.state;
        let bodyOAuthUID = oAuthUID;
        if (_.isNil(bodyOAuthUID) || _.isEmpty(bodyOAuthUID)) {
            bodyOAuthUID = window.sessionStorage.getItem('login-oAuthUID') || oAuthUID;
        }
        let bodyEmail = email;
        if (_.isNil(bodyEmail) || _.isEmpty(bodyEmail)) {
            bodyEmail = window.sessionStorage.getItem('login-email');
        }
        let body = {
            twoFactorAuthenticationPhoneNumber,
            channel,
            authenticationSID,
            oAuthUID: bodyOAuthUID,
            email: bodyEmail
        };
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/sendTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    const json = await res.json();
                    let currentTime = moment(new Date());
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: loc('authCodeSent', this.context.lang),
                        snackbarVariant: 'success',
                        authenticationSID: json.authenticationSID,
                        // twoFactorAuthenticationCodeInputDisabled: false,
                        enableSendTime: currentTime.add(json.timeUntilNextRetry, 's')
                    });
                    this.disableSendButton(json.timeUntilNextRetry);
                    if (this.codeInput) {
                        this.codeInput.focus();
                    }
                    throw new Break();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = loc('badRequest', this.context.lang); // error message to be generated locally
                    }
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: message,
                        snackbarVariant: 'error'
                    });
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        this.setState({
                            accountLocked: true,
                            lockAccountUntil: json.lockAccountUntil
                        });
                    } catch (err) {}
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: loc('tooManyAttempts', this.context.lang),
                        snackbarVariant: 'error',
                        password: ''
                    });

                    clearSessionStorageItems();

                    //open account locked dialog and then -> this.props.history.push(`/`);
                    throw new Break();
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /login', err);
                this.setState({
                    snackbarOpen: true,
                    snackbarMessage: loc('connectionErr', this.context.lang),
                    snackbarVariant: 'error'
                });
            });
    };

    handleCustomAuthWindowClose = () => {
        setTimeout(() => {
            if (!this.state.socket) return;

            const socketWasConnected = this.state.socket.connected;

            try {
                this.state.socket.close();
            } catch (err) {
                // unhandled catch block
            }

            if (socketWasConnected) {
                this.setState({
                    socket: null,
                    showAuthDialog: false,
                    attemptingLogin: false,
                    snackbarOpen: true,
                    snackbarMessage: loc('authInterrupted', this.context.lang),
                    snackbarVariant: 'error'
                });
            } else {
                this.setState({
                    socket: null
                });
            }
        }, 1500);
    };

    handleCustomAuth = async () => {
        this.setState({ showAuthDialog: true });

        let androidBrowserRef;

        // console.log(`%cuseLiveCountsSocketCounterIO: opening socket connection`, 'color: blue');
        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');
        const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

        const authSocket = io(`${originURL}/customAuth`, {
            path: `${pathExtension}/socket.io`
        });

        const state = crypto.randomBytes(20).toString('hex');

        authSocket.emit('socket-ready', { state });

        authSocket.on('custom-auth-complete', async res => {
            const { data } = res;

            const token = data.accessToken;

            const user = {
                altEmail: '',
                email: data.email,
                emailVerified: true,
                nameFirst: data.firstName,
                nameLast: data.lastName,
                promos: [],
                oAuth: {
                    provider: data.provider,
                    uid: data.uid,
                    tokenData: {
                        accessToken: token,
                        tokenType: data.tokenType,
                        provider: data.provider,
                        expiresOn: data.expires,
                        issuedOn: data.issued
                    }
                },
                phone: data.phone,
                location: data.location,
                businessName: data.businessName
            };

            if (deviceHelper.isNativeApp()) {
                if (deviceHelper.AndroidCordova()) {
                    androidBrowserRef.close();
                } else {
                    Browser.close();
                }
            }

            // Check that the user isn't already registered
            let registrationObj = await FirebaseAdmin.loginWithOAuthUser(user, token);
            if (registrationObj instanceof Error) {
                this.setState({
                    showAuthDialog: false,
                    attemptingLogin: false,
                    snackbarOpen: true,
                    snackbarMessage: loc(registrationObj.message, this.context.lang) || registrationObj.message,
                    snackbarVariant: 'error'
                });
            } else {
                if (registrationObj.isNotRegistered) {
                    this.setState({
                        oAuthUser: user,
                        showAuthDialog: true
                    });
                } else if (registrationObj.twoFactorAuthenticationCodeRequired) {
                    this.setState({
                        showAuthDialog: false,
                        attemptingLogin: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('enterAuthCode', this.context.lang),
                        snackbarVariant: 'info',
                        twoFactorAuthenticationStep: true,
                        twoFactorAuthenticationCode: '',
                        twoFactorAuthenticationPhoneNumber: registrationObj.twoFactorAuthenticationPhoneNumber,
                        identityVerificationEnabled: registrationObj.identityVerificationEnabled,
                        identityVerificationAvailable: registrationObj.identityVerificationAvailable,
                        securityQuestion: registrationObj.securityQuestion,
                        channel: registrationObj.channel,
                        phoneNumberInputRequired: _.isEmpty(registrationObj.twoFactorAuthenticationPhoneNumber),
                        oAuthUID: _.get(user, 'oAuth.uid')
                    });
                    setSessionStorageItems({
                        securityQuestion: registrationObj.securityQuestion,
                        identityVerificationEnabled: registrationObj.identityVerificationEnabled,
                        identityVerificationAvailable: registrationObj.identityVerificationAvailable,
                        loginSuccess: true,
                        loginOAuthUID: _.get(user, 'oAuth.uid'),
                        loginPhone: registrationObj.twoFactorAuthenticationPhoneNumber
                    });
                    this.handleSendVerificationCode();
                } else if (registrationObj.accountLocked) {
                    await FirebaseAdmin.logOutOAuth();
                    this.setState({
                        showAuthDialog: false,
                        attemptingLogin: false,
                        accountLocked: true,
                        oAuthUID: null,
                        lockAccountUntil: registrationObj.lockAccountUntil
                    });
                } else {
                    if (registrationObj.lang) {
                        this.props.setLang(registrationObj.lang);
                    }
                    this.props.dispatch(registrationObj);
                }
            }

            authSocket.close(); // Socket should already be closed at this point
        });

        authSocket.on('custom-auth-failed', error => {
            if (deviceHelper.isNativeApp()) {
                if (deviceHelper.AndroidCordova()) {
                    androidBrowserRef.close();
                } else {
                    Browser.close();
                }
            }

            authSocket.close();
            this.setState({
                showAuthDialog: false,
                snackbarOpen: true,
                snackbarMessage: error.errorMessage,
                snackbarVariant: 'error'
            });
        });

        authSocket.on('disconnect', () => {
            if (deviceHelper.isNativeApp()) {
                if (deviceHelper.AndroidCordova()) {
                    androidBrowserRef.close();
                } else {
                    Browser.close();
                }
            }

            authSocket.close(); // Redundant?
        });

        // Store socket in state so it can be closed elsewhere
        this.setState({ socket: authSocket });

        if (deviceHelper.isNativeApp()) {
            if (deviceHelper.AndroidCordova()) {
                androidBrowserRef = await InAppBrowser.create(
                    `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                    '_blank',
                    'location=yes,popup=yes'
                );

                androidBrowserRef.on('exit').subscribe(event => {
                    this.handleCustomAuthWindowClose();
                });
            } else {
                await Browser.open({
                    url: `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                    presentationStyle: 'popover'
                });

                Browser.addListener('browserFinished', () => {
                    this.handleCustomAuthWindowClose();
                });
            }
        } else if (!deviceHelper.isNativeApp()) {
            const popupWindow = window.open(
                `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                'firebaseAuth',
                'height=615,width=650'
            );

            const popupWindowTimer = setInterval(() => {
                if (popupWindow.closed) {
                    clearInterval(popupWindowTimer);
                    this.handleCustomAuthWindowClose();
                }
            }, 1000);
        }
    };

    handleShowIdentityVerification = state => () => {
        if (!this.state.identityVerificationAvailable) {
            this.setState({ identityVerificationUnavailableDialogOpen: true });
            return;
        }
        this.setState({ showIdentityVerification: this.state.identityVerificationEnabled && state });
    };

    handleSubmitLogin = async (form, isBiometricsLogin = false) => {
        const { http, setOnLoginMessage, setOnLoginMessageVariant } = this.props;
        const { promoCode, twoFactorAuthenticationStep, biometricsAvailable, biometricsOptIn } = this.state;

        attemptSocketClose(this.state.socket);

        if (!twoFactorAuthenticationStep) {
            this.setState({
                attemptingLogin: true
            });

            const Break = {};
            fetch(process.env.REACT_APP_API_URL + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(form)
            })
                .then(async res => {
                    if (res.status === 200) {
                        if (biometricsAvailable && biometricsOptIn && !isBiometricsLogin) {
                            try {
                                //There is sometimes issues with setting credentials on IOS
                                //the workaround for this plugin is to always call deleteCredentials before setting them even if there are no credentials set
                                await NativeBiometric.deleteCredentials({
                                    server: process.env.REACT_APP_ORIGIN_URL
                                });
                                //Save user's credentials to the device - Keychain (iOS) and Keystore (Android).
                                await NativeBiometric.setCredentials({
                                    username: form.email,
                                    password: form.password,
                                    server: process.env.REACT_APP_ORIGIN_URL
                                });
                                window.localStorage.setItem('biometricsHasCredentials', 'true');
                            } catch (err) {}
                        }

                        const json = await res.json();
                        if (json.twoFactorAuthenticationCodeRequired) {
                            this.setState({
                                attemptingLogin: false,
                                twoFactorAuthenticationStep: true,
                                twoFactorAuthenticationCode: '',
                                twoFactorAuthenticationPhoneNumber: json.twoFactorAuthenticationPhoneNumber,
                                channel: json.channel,
                                phoneNumberInputRequired: _.isEmpty(json.twoFactorAuthenticationPhoneNumber),
                                canEditNumber: !json.twoFactorAuthenticationPhoneNumberVerified,
                                identityVerificationEnabled: json.identityVerificationEnabled,
                                identityVerificationAvailable: json.identityVerificationAvailable,
                                securityQuestion: json.securityQuestion
                            });
                            setSessionStorageItems({
                                securityQuestion: json.securityQuestion,
                                identityVerificationEnabled: json.identityVerificationEnabled,
                                identityVerificationAvailable: json.identityVerificationAvailable,
                                loginSuccess: true,
                                loginEmail: this.state.email.toLowerCase().trim(),
                                loginPhone: json.twoFactorAuthenticationPhoneNumber
                            });

                            this.handleSendVerificationCode();
                            throw new Break();
                        } else {
                            return json;
                        }
                    } else if (res.status === 401) {
                        this.setState({
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc(isBiometricsLogin ? 'biometrics5' : 'invalidCreds', this.context.lang),
                            snackbarVariant: 'error',
                            password: ''
                        });
                        if (isBiometricsLogin) {
                            try {
                                this.setState({
                                    biometricsHasCredentials: false
                                });
                                window.localStorage.setItem('biometricsHasCredentials', 'false');
                            } catch (err) {}
                        }

                        throw new Break();
                    } else if (res.status === 429) {
                        try {
                            const json = await res.json();
                            this.setState({
                                accountLocked: true,
                                lockAccountUntil: json.lockAccountUntil
                            });
                        } catch (err) {}
                        this.setState({
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc('tooManyAttempts', this.context.lang),
                            snackbarVariant: 'error',
                            password: ''
                        });

                        clearSessionStorageItems();
                        throw new Break();
                    } else if (res.status === 409) {
                        const json = await res.json();
                        this.setState({
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc('emailOAuthAlreadyRegistered', this.context.lang, {
                                provider: json.provider
                            }),
                            snackbarVariant: 'error',
                            password: ''
                        });
                        throw new Break();
                    } else if (res.status === 502) {
                        this.setState({
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc('serverUnreachable', this.context.lang),
                            snackbarVariant: 'error'
                        });
                        throw new Break();
                    } else {
                        this.setState({
                            attemptingLogin: false,
                            snackbarOpen: true,
                            snackbarMessage: loc('serverError', this.context.lang),
                            snackbarVariant: 'error'
                        });
                        throw new Break();
                    }
                })
                .then(async data => {
                    if (!_.isNil(promoCode) && data.accountType === 'Customer') {
                        //apply promo to user if successful login and promo valid
                        const customerId = data._id;
                        const res = await http.postJSON(`/users/${customerId}/applyPromoToUser`, { promoCode });
                        if (res.ok) {
                            if (!res.data.result.valid) {
                                setOnLoginMessage(
                                    loc('loginMessage1', this.context.lang, { reason: res.data.result.reason })
                                );
                                setOnLoginMessageVariant('error');
                            } else {
                                setOnLoginMessage(loc('loginMessage2', this.context.lang));
                                setOnLoginMessageVariant('success');
                            }
                        } else {
                            setOnLoginMessage(loc('loginMessage3', this.context.lang));
                            setOnLoginMessageVariant('error');
                        }
                    }
                    return data;
                })
                .then(data => {
                    console.log('POST /login (data)', data);
                    clearSessionStorageItems();
                    let homeURL;
                    switch (data.accountType) {
                        case 'Customer':
                            homeURL = '/customers/' + data._id;
                            if (!_.isNil(data.lastPath)) {
                                homeURL += data.lastPath;
                            } else if (!_.isEmpty(data.charities)) {
                                homeURL +=
                                    '/' + _.get(_.first(data.charities), '_id', _.first(data.charities)) + '/charity';
                            }
                            break;
                        case 'Collector Employee':
                            homeURL = `/operators/${data._id}/driver`;
                            break;
                        case 'Collector Administrator':
                            homeURL = `/operators/${data._id}/collector`;
                            break;
                        case 'System Administrator':
                            homeURL = '/operators/' + data._id;
                            break;
                        default:
                            if (ROLES.includes(data.accountType)) {
                                homeURL = '/operators/' + data._id;
                            } else {
                                throw new Error('Unexpected account type.');
                            }
                    }
                    if (data.lang) {
                        this.props.setLang(data.lang);
                    }
                    this.props.dispatch({
                        type: 'SET_AUTH_TRUE',
                        accountType: data.accountType,
                        name: !_.isNil(data.name) ? data.name.first + ' ' + data.name.last : undefined,
                        _id: data._id,
                        multipleAccountAccessList: data.multipleAccountAccessList,
                        collector_id: _.get(data, 'collector._id', undefined),
                        home: homeURL,
                        accountPermissions: data.accountPermissions
                    });
                })
                .catch(Break => {})
                .catch(err => {
                    console.error('POST /login', err);
                    this.setState({
                        attemptingLogin: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('connectionErr', this.context.lang),
                        snackbarVariant: 'error'
                    });
                });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        let deviceInfo = await getDeviceInformation();
        let form = {
            email: this.state.email.toLowerCase().trim(),
            password: this.state.password,
            deviceInfo,
            timezone: _time.getTimezone()
        };
        this.handleSubmitLogin(form, false);
    };

    handleVerify = async e => {
        const { http, setOnLoginMessage, setOnLoginMessageVariant } = this.props;
        const {
            promoCode,
            oAuthUID,
            email,
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber,
            trustThisDevice
            // password
        } = this.state;
        e.preventDefault();
        let deviceInfo = await getDeviceInformation();
        let bodyOAuthUID = oAuthUID;
        if (_.isNil(bodyOAuthUID) || _.isEmpty(bodyOAuthUID)) {
            bodyOAuthUID = window.sessionStorage.getItem('login-oAuthUID') || oAuthUID;
        }
        let bodyEmail = email.toLowerCase().trim();
        if (_.isNil(bodyEmail) || _.isEmpty(bodyEmail)) {
            bodyEmail = window.sessionStorage.getItem('login-email');
        }
        let form = {
            oAuthUID: bodyOAuthUID,
            email: bodyEmail,
            // password,
            deviceInfo,
            timezone: _time.getTimezone(),
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber,
            trustThisDevice
        };
        this.setState({
            attemptingTwoFactorAuthentication: true
        });
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/checkTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(form)
        })
            .then(async res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: message,
                        snackbarVariant: 'error',
                        twoFactorAuthenticationCode: '',
                        attemptingTwoFactorAuthentication: false
                    });
                } else if (res.status === 401) {
                    this.setState({
                        attemptingTwoFactorAuthentication: false,
                        twoFactorAuthenticationCode: '',
                        snackbarOpen: true,
                        snackbarMessage: loc('authCodeFailed', this.context.lang),
                        snackbarVariant: 'error'
                    });
                    throw new Break();
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        this.setState({
                            accountLocked: true,
                            lockAccountUntil: json.lockAccountUntil
                        });
                        clearSessionStorageItems();
                    } catch (err) {}
                    this.setState({
                        attemptingTwoFactorAuthentication: false,
                        twoFactorAuthenticationCode: '',
                        snackbarOpen: true,
                        snackbarMessage: loc('tooManyAttempts', this.context.lang),
                        snackbarVariant: 'error',
                        password: ''
                    });
                    clearSessionStorageItems();

                    //open account locked dialog and then -> this.props.history.push(`/`);
                    throw new Break();
                } else if (res.status === 409) {
                    this.setState({
                        attemptingTwoFactorAuthentication: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('errorVerifyingCreds', this.context.lang),
                        snackbarVariant: 'error',
                        password: '',
                        email: '',
                        twoFactorAuthenticationCode: '',
                        twoFactorAuthenticationPhoneNumber: '',
                        twoFactorAuthenticationStep: false
                    });
                    clearSessionStorageItems();
                    throw new Break();
                } else if (res.status === 502) {
                    this.setState({
                        attemptingTwoFactorAuthentication: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('serverUnreachable', this.context.lang),
                        snackbarVariant: 'error'
                    });
                    throw new Break();
                } else {
                    this.setState({
                        attemptingTwoFactorAuthentication: false,
                        snackbarOpen: true,
                        snackbarMessage: loc('serverError', this.context.lang),
                        snackbarVariant: 'error'
                    });
                    throw new Break();
                }
            })
            .then(async data => {
                if (!_.isNil(promoCode) && data.accountType === 'Customer') {
                    //apply promo to user if successful login and promo valid
                    const customerId = data._id;
                    const res = await http.postJSON(`/users/${customerId}/applyPromoToUser`, { promoCode });
                    if (res.ok) {
                        if (!res.data.result.valid) {
                            setOnLoginMessage(
                                loc('loginMessage1', this.context.lang, { reason: res.data.result.reason })
                            );
                            setOnLoginMessageVariant('error');
                        } else {
                            setOnLoginMessage(loc('loginMessage2', this.context.lang));
                            setOnLoginMessageVariant('success');
                        }
                    } else {
                        setOnLoginMessage(loc('loginMessage3', this.context.lang));
                        setOnLoginMessageVariant('error');
                    }
                }
                return data;
            })
            .then(data => {
                console.log('POST /checkTwoFactorAuthenticationCode (data)', data);
                clearSessionStorageItems();
                let homeURL;
                switch (data.accountType) {
                    case 'Customer':
                        homeURL = '/customers/' + data._id;
                        if (!_.isNil(data.lastPath)) {
                            homeURL += data.lastPath;
                        } else if (!_.isEmpty(data.charities)) {
                            homeURL +=
                                '/' + _.get(_.first(data.charities), '_id', _.first(data.charities)) + '/charity';
                        }
                        break;
                    case 'Collector Employee':
                        homeURL = `/operators/${data._id}/driver`;
                        break;
                    case 'Collector Administrator':
                        homeURL = `/operators/${data._id}/collector`;
                        break;
                    case 'System Administrator':
                        homeURL = '/operators/' + data._id;
                        break;
                    default:
                        if (ROLES.includes(data.accountType)) {
                            homeURL = '/operators/' + data._id;
                        } else {
                            throw new Error('Unexpected account type.');
                        }
                }
                if (data.lang) {
                    this.props.setLang(data.lang);
                }
                this.props.dispatch({
                    type: 'SET_AUTH_TRUE',
                    accountType: data.accountType,
                    multipleAccountAccessList: data.multipleAccountAccessList,
                    name: !_.isNil(data.name) ? data.name.first + ' ' + data.name.last : undefined,
                    _id: data._id,
                    collector_id: _.get(data, 'collector._id', undefined),
                    home: homeURL,
                    accountPermissions: data.accountPermissions
                });
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /checkTwoFactorAuthenticationCode', err);
                this.setState({
                    attemptingTwoFactorAuthentication: false,
                    snackbarOpen: true,
                    snackbarMessage: loc('connectionErr', this.context.lang),
                    snackbarVariant: 'error'
                });
            });
    };

    handleEditNumber = () => {
        this.setState({ phoneNumberInputRequired: true });
    };

    handleResetAllPasswords = async () => {
        // Lots of redundancy to prevent breaking production
        if (process.env.REACT_APP_ENV === 'PROD') return;
        if (process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'TEST') {
            const res = await this.props.http.post('/dangerouslyResetAllPasswordsOnStaging');
            if (res.ok) {
                this.setState({
                    snackbarOpen: true,
                    snackbarMessage: 'Passwords reset. Check notification channel for new password.',
                    snackbarVariant: 'success'
                });
            }
        }
    };

    handleFBDiscontinuedDialogClose = () => {
        this.setState({ showFBDiscontinuedDialog: false });
    };
    handleFBDiscontinuedDialogPWReset = () => {
        this.setState({ showFBDiscontinuedDialog: false, forgotPasswordDialogOpen: true });
    };

    handleToggleBiometricsOptIn = e => {
        this.setState({ biometricsOptIn: e.target.checked });
    };

    handleBiometricsSignIn = async () => {
        try {
            this.setState({ biometricSignInLoading: true });
            const verified = await NativeBiometric.verifyIdentity({
                reason: loc('signIn', this.context.lang),
                title: loc('signIn', this.context.lang)
            })
                .then(() => true)
                .catch(() => false);

            if (verified) {
                //load email and password saved to the device
                const credentials = await NativeBiometric.getCredentials({
                    server: process.env.REACT_APP_ORIGIN_URL
                });

                //console.log('CREDENTIALS', credentials.username, credentials.password);

                let deviceInfo = await getDeviceInformation();
                const email = credentials.username.toLowerCase().trim();
                let form = {
                    email,
                    password: credentials.password,
                    deviceInfo,
                    timezone: _time.getTimezone()
                };
                // required to be set for 2fa,
                // also if the password saved to the device is incorrect(it was updated using a diferrent device) the email will already be filled out.
                this.setState({ email });
                this.handleSubmitLogin(form, true);
            } else {
                this.setState({
                    snackbarOpen: true,
                    snackbarMessage: loc('biometrics4', this.context.lang),
                    snackbarVariant: 'error'
                });
            }
            this.setState({ biometricSignInLoading: false });
        } catch (err) {
            console.log(err);
            this.setState({
                snackbarOpen: true,
                snackbarMessage: loc('biometrics3', this.context.lang),
                snackbarVariant: 'error',
                biometricSignInLoading: false
            });
        }
    };

    render() {
        const { theme, useFallbackData, showThirdPartyLogins, collectionProgramEnabled } = this.props;
        const { pathname } = this.props.location;
        const {
            tabIndex,
            howItWorksOpen,
            faqOpen,
            contactFormOpen,
            helpCenterOpen,
            identityVerificationUnavailableDialogOpen,
            attemptingLogin,
            email,
            password,
            snackbarOpen,
            snackbarMessage,
            snackbarVariant,
            forgotPasswordDialogOpen,
            recoveryEmail,
            showAuthDialog,
            authDialogMessage,
            oAuthUser,
            oAuthRegistrationCharity,
            isInAppBrowser,
            twoFactorAuthenticationStep,
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber,
            resendDisabled,
            // twoFactorAuthenticationCodeInputDisabled,
            // channel,
            showIdentityVerification,
            identityVerificationEnabled,
            identityVerificationAvailable,
            securityQuestion,
            securityAnswer,
            postalCode,
            phoneNumberInputRequired,
            canEditNumber,
            enableSendTime,
            trustThisDevice,
            // authenticationSID,
            accountLocked,
            lockAccountUntil,
            attemptingTwoFactorAuthentication,
            showFBDiscontinuedDialog,
            registrationTagline,
            windowWidth,
            windowHeight,
            biometricsAvailable,
            biometricsOptIn,
            biometricsHasCredentials,
            biometricSignInLoading
        } = this.state;

        if (pathname !== '/login') {
            return <Redirect to="/login" />;
        }

        const newUserInfo = () => {
            if (isMXDRegion() && this.context.lang === 'fr') {
                return (
                    <Typography style={{ marginTop: theme.spacing.unit }} variant="body2" color="textSecondary">
                        {loc('registration1a', this.context.lang)}{' '}
                        <ExternalLink
                            textInline={true}
                            theme={theme}
                            text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
                            url={process.env.REACT_APP_BRAND_WEBSITE}
                        />{' '}
                        {loc('registration1b', this.context.lang)}{' '}
                        <FakeLink theme={theme} text={'FAQ'} onClickEvent={this.handleFAQ(true)} />
                        {'.'}
                    </Typography>
                );
            }

            return (
                <Typography style={{ marginTop: theme.spacing.unit }} variant="body2" color="textSecondary">
                    {loc('registration12', this.context.lang)}
                    {': '}
                    <FakeLink
                        theme={theme}
                        text={loc('howItWorks', this.context.lang)}
                        onClickEvent={this.handleHowItWorks(true)}
                        data-cy="new-user-how-it-works-link"
                    />
                    {', '}
                    <FakeLink
                        theme={theme}
                        text={'Frequently Asked Question'}
                        onClickEvent={this.handleFAQ(true)}
                        data-cy="new-user-faq-link"
                    />
                    {', '}
                    <ExternalLink
                        textInline={true}
                        theme={theme}
                        text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
                        url={process.env.REACT_APP_BRAND_WEBSITE}
                    />
                </Typography>
            );

            // return (
            //     <Typography style={{ marginTop: theme.spacing.unit * 2 }} variant="body2" color="textSecondary">
            //         {loc('registration2a', this.context.lang)}{' '}
            //         <FakeLink
            //             theme={theme}
            //             text={loc('howItWorks', this.context.lang)}
            //             onClickEvent={this.handleHowItWorks(true)}
            //         />{' '}
            //         {loc('registration2b', this.context.lang)}{' '}
            //         <FakeLink
            //             theme={theme}
            //             text={isEXPRegion() ? 'Frequently Asked Questions' : 'FAQ'}
            //             onClickEvent={this.handleFAQ(true)}
            //         />
            //         . {loc('registration2c', this.context.lang)}{' '}
            //         <ExternalLink
            //             textInline={true}
            //             theme={theme}
            //             text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
            //             url={process.env.REACT_APP_BRAND_WEBSITE}
            //         />
            //         {isEXPRegion() && ` ${loc('registration2d', this.context.lang)}`}
            //     </Typography>
            // );
        };

        const splashScreenBackgroundColor = isAUSRegion() ? '#454545' : theme.palette.primary.main;
        let mySVG = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 ${windowWidth} ${windowHeight}" class="css-conixt ekalwmn1"><path d="M0 ${windowHeight} L0 ${windowHeight /
            2 +
            windowHeight * 0.1} L${windowWidth} ${windowHeight / 2 -
            windowHeight * 0.1} L${windowWidth} ${windowHeight} Z" fill="#dde3e8" ></path></svg>`;
        let mySVG64 = window.btoa(mySVG);

        const isEXP = isEXPRegion();
        const isSmallScreen = windowWidth < 1000;
        return (
            <>
                <Helmet>
                    <title>{`${process.env.REACT_APP_BRAND_NAME} | ${loc('signIn', this.context.lang)}`}</title>
                </Helmet>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        backgroundColor: splashScreenBackgroundColor,
                        backgroundImage: `url("${backgroundImage()}")`,
                        backgroundRepeat: isEXP && isSmallScreen ? 'no-repeat' : 'repeat',
                        backgroundSize: isEXP ? (isSmallScreen ? '100%' : '500px') : '310px 310px',
                        paddingTop: 'var(--custom-safe-area-top)'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            minWidth: '100%',
                            backgroundImage: "url('data:image/svg+xml;base64," + mySVG64 + "')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom',
                            backgroundSize: '100%',
                            overflowY: 'scroll'
                        }}
                    >
                        {/* <ContainerWithLogo brandLogoOverride={_.get(this, 'props.brandLogoOverride')}> */}
                        <img
                            src={_.get(this.props, 'brandLogoOverride.url', logo)}
                            alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                            style={{
                                margin: '0px auto',
                                marginTop: '30px', //theme.spacing.unit * 5,
                                height: process.env.REACT_APP_REGION_EXT === 'STD' ? 'auto' : 100,
                                maxWidth: '85%'
                            }}
                        />
                        <FacebookDiscontinuedDialog
                            open={showFBDiscontinuedDialog}
                            onClose={this.handleFBDiscontinuedDialogClose}
                            onPasswordReset={this.handleFBDiscontinuedDialogPWReset}
                        />
                        <div style={{ display: 'flex' }}>
                            <div
                                style={{
                                    maxWidth: 480,
                                    marginTop: '30px', //theme.spacing.unit * 3,
                                    paddingTop: `calc(var(--custom-safe-area-top))`
                                }}
                            >
                                <Paper
                                    elevation={4}
                                    style={{
                                        backgroundColor: theme.palette.background.default,
                                        margin: theme.spacing.unit * 2,
                                        marginTop: 0,
                                        marginBottom: theme.spacing.unit * 4,
                                        padding: theme.spacing.unit,
                                        borderRadius: 15
                                    }}
                                >
                                    {/* <Typography variant="h5" style={{ fontSize: '1.75rem' }}>
                                    {twoFactorAuthenticationStep
                                        ? loc('twoFactorAuthentication', this.context.lang)
                                        : loc('signIn', this.context.lang)}
                                </Typography> */}
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        style={{ fontSize: '1.75rem', marginTop: theme.spacing.unit * 3 }}
                                    >
                                        {loc('signIn', this.context.lang)}
                                    </Typography>
                                    <Collapse in={!twoFactorAuthenticationStep && !showIdentityVerification}>
                                        {/* <Typography
                                            variant="body1"
                                            align="left"
                                            style={{ lineHeight: 1.2, fontWeight: 300, marginTop: theme.spacing.unit }}
                                        >
                                            {loc('registration1', this.context.lang)}
                                        </Typography> */}
                                        {/* {newUserInfo()} */}
                                    </Collapse>
                                    <form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: theme.spacing.unit * 2,
                                            padding: theme.spacing.unit * 2
                                        }}
                                    >
                                        <Collapse in={!twoFactorAuthenticationStep && !showIdentityVerification}>
                                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                <TextField
                                                    data-cy="login-email-input" // NB: do not add autofocus to prevent keyboard issue with phones
                                                    name="email"
                                                    label={loc('email', this.context.lang)}
                                                    value={email}
                                                    autoFocus={!deviceHelper.isNativeApp()}
                                                    onChange={this.handleChange}
                                                    disabled={twoFactorAuthenticationStep}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                <PasswordInput
                                                    data-cy="login-password-input"
                                                    name="password"
                                                    type="password"
                                                    label={loc('password', this.context.lang)}
                                                    value={password}
                                                    onChange={this.handleChange}
                                                    disabled={twoFactorAuthenticationStep}
                                                />

                                                <Typography
                                                    component={Link}
                                                    to="#"
                                                    variant="caption"
                                                    color="primary"
                                                    style={{
                                                        display: 'inline',
                                                        fontSize: '1rem',
                                                        marginTop: theme.spacing.unit
                                                    }}
                                                    align="right"
                                                    onClick={this.handleForgotPasswordDialog(true)}
                                                >
                                                    {loc('forgotPassword', this.context.lang)}
                                                </Typography>
                                            </FormControl>
                                            {biometricsAvailable && (
                                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                onChange={this.handleToggleBiometricsOptIn}
                                                                value={biometricsOptIn}
                                                                checked={biometricsOptIn}
                                                                disabled={biometricsHasCredentials}
                                                            />
                                                        }
                                                        label={loc('biometrics1', this.context.lang)}
                                                        labelPlacement="start"
                                                    />
                                                </FormControl>
                                            )}
                                        </Collapse>
                                        <Collapse in={twoFactorAuthenticationStep && !showIdentityVerification}>
                                            {phoneNumberInputRequired ? (
                                                <>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {loc('registration2fa1', this.context.lang)}
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                                    >
                                                        <TextField
                                                            data-cy="login-phone-input"
                                                            type="tel"
                                                            variant="outlined"
                                                            name="twoFactorAuthenticationPhoneNumber"
                                                            label={loc('phoneNumber', this.context.lang)}
                                                            value={twoFactorAuthenticationPhoneNumber}
                                                            InputProps={{
                                                                inputComponent: TextMaskPhoneNumber,
                                                                style: {
                                                                    backgroundColor: theme.palette.background.paper
                                                                }
                                                            }}
                                                            onChange={this.handleUserPhoneNumber}
                                                        />
                                                    </FormControl>
                                                </>
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">
                                                    {loc('registration2fa2', this.context.lang, {
                                                        formattedPhoneNumber: formatAndRedactPhoneNumber(
                                                            twoFactorAuthenticationPhoneNumber
                                                        )
                                                    })}
                                                </Typography>
                                            )}
                                            {process.env.REACT_APP_ENV === 'TEST' && (
                                                <Typography variant="body2" color="error">
                                                    {loc('registration2fa3', this.context.lang)}
                                                </Typography>
                                            )}
                                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                <TextField
                                                    data-cy="login-2fa-code-input"
                                                    inputRef={elem => (this.codeInput = elem)}
                                                    // disabled={twoFactorAuthenticationCodeInputDisabled}
                                                    variant="outlined"
                                                    name="twoFactorAuthenticationCode"
                                                    type="numeric"
                                                    label={loc('registration2fa6', this.context.lang)}
                                                    placeholder="e.g. 123456"
                                                    value={twoFactorAuthenticationCode}
                                                    InputProps={{
                                                        style: { backgroundColor: theme.palette.background.paper }
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </FormControl>
                                        </Collapse>
                                        <Collapse in={showIdentityVerification}>
                                            <Typography variant="body2" color="textSecondary">
                                                {securityQuestion}
                                            </Typography>
                                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                <TextField
                                                    data-cy="login-security-answer-input"
                                                    // disabled={twoFactorAuthenticationCodeInputDisabled}
                                                    variant="outlined"
                                                    name="securityAnswer"
                                                    type="text"
                                                    label={loc('answer', this.context.lang)}
                                                    // placeholder="e.g. 123456"
                                                    value={securityAnswer}
                                                    InputProps={{
                                                        style: { backgroundColor: theme.palette.background.paper }
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                                <TextField
                                                    data-cy="login-postal-code-input"
                                                    // disabled={twoFactorAuthenticationCodeInputDisabled}
                                                    variant="outlined"
                                                    name="postalCode"
                                                    type="numeric"
                                                    label={loc('redemption35', this.context.lang)}
                                                    // placeholder="e.g. 123456"
                                                    value={postalCode}
                                                    InputProps={{
                                                        style: { backgroundColor: theme.palette.background.paper }
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </FormControl>
                                        </Collapse>
                                        <Collapse in={!twoFactorAuthenticationStep && !showIdentityVerification}>
                                            <div style={{ marginTop: theme.spacing.unit * 3 }}>
                                                <Button
                                                    data-cy="login-btn"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        display: 'inline',
                                                        float: 'right',
                                                        height: 50,
                                                        textTransform: 'none',
                                                        borderRadius: 15
                                                    }}
                                                    fullWidth
                                                    onClick={this.handleSubmit}
                                                >
                                                    {loc('signIn', this.context.lang)}{' '}
                                                    <CircularProgress
                                                        size={22}
                                                        thickness={4.8}
                                                        style={{
                                                            display: attemptingLogin ? '' : 'none',
                                                            marginLeft: 4,
                                                            color: 'white',
                                                            verticalAlign: 'bottom'
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                        </Collapse>
                                        <Collapse in={twoFactorAuthenticationStep && !showIdentityVerification}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '80%' }}
                                            >
                                                {loc('registration2fa4', this.context.lang)}{' '}
                                                <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                                                    {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                                                </a>
                                            </Typography>
                                            {identityVerificationEnabled && (
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    style={{ fontSize: '80%' }}
                                                >
                                                    <FakeLink
                                                        theme={theme}
                                                        text={loc('registration2fa15', this.context.lang)}
                                                        onClickEvent={this.handleShowIdentityVerification(true)}
                                                        color={theme.palette.secondary.main}
                                                    />
                                                </Typography>
                                            )}
                                            <div
                                                fullWidth
                                                style={{ display: 'inline', marginTop: theme.spacing.unit * 2 }}
                                            >
                                                <FormControlLabel
                                                    style={{ float: 'left' }}
                                                    control={
                                                        <Checkbox
                                                            name="trustThisDevice"
                                                            onChange={this.handleCheckbox}
                                                            checked={trustThisDevice}
                                                        />
                                                    }
                                                    label={loc('registration2fa7', this.context.lang)}
                                                />
                                                <div style={{ float: 'right', height: 40 }}>
                                                    <Button
                                                        data-cy="back-to-login-btn"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={this.handleBack}
                                                    >
                                                        {loc('back', this.context.lang)}
                                                    </Button>
                                                    <Button
                                                        data-cy="verify-btn"
                                                        disabled={_.isEmpty(twoFactorAuthenticationCode)}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing.unit }}
                                                        onClick={this.handleVerify}
                                                    >
                                                        {loc('continue', this.context.lang)}{' '}
                                                        <CircularProgress
                                                            size={22}
                                                            thickness={4.8}
                                                            style={{
                                                                display: attemptingTwoFactorAuthentication
                                                                    ? ''
                                                                    : 'none',
                                                                marginLeft: 4,
                                                                color: 'white',
                                                                verticalAlign: 'bottom'
                                                            }}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div
                                                fullWidth
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <div>
                                                    <Typography
                                                        style={{ marginTop: theme.spacing.unit }}
                                                        variant="body2"
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? theme.palette.text.secondary
                                                                        : theme.palette.primary.main,
                                                                textDecoration:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? ''
                                                                        : 'underline',
                                                                cursor:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? 'default'
                                                                        : 'pointer'
                                                            }}
                                                            data-cy="2fa-resend-code-via-text"
                                                            onClick={
                                                                resendDisabled ||
                                                                isIncompletePhoneNumber(
                                                                    twoFactorAuthenticationPhoneNumber
                                                                )
                                                                    ? () => {}
                                                                    : e => {
                                                                          e.preventDefault();
                                                                          this.handleSendVerificationCode('sms');
                                                                      }
                                                            }
                                                        >
                                                            {`${loc('resendViaText', this.context.lang)} >`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography
                                                        style={{ marginTop: theme.spacing.unit }}
                                                        variant="body2"
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? theme.palette.text.secondary
                                                                        : theme.palette.primary.main,
                                                                textDecoration:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? ''
                                                                        : 'underline',
                                                                cursor:
                                                                    resendDisabled ||
                                                                    isIncompletePhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )
                                                                        ? 'default'
                                                                        : 'pointer'
                                                            }}
                                                            data-cy="2fa-resend-code-via-voice"
                                                            onClick={
                                                                resendDisabled ||
                                                                isIncompletePhoneNumber(
                                                                    twoFactorAuthenticationPhoneNumber
                                                                )
                                                                    ? () => {}
                                                                    : e => {
                                                                          e.preventDefault();
                                                                          this.handleSendVerificationCode('call');
                                                                      }
                                                            }
                                                        >
                                                            {`${loc('resendViaVoice', this.context.lang)} >`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                {resendDisabled && (
                                                    <div fullwidth style={{ display: 'inline-flex' }}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            style={{ float: 'right', fontSize: '60%' }}
                                                        >
                                                            {loc('registration2fa5', this.context.lang)}{' '}
                                                            <WaitToRetry time={enableSendTime} />
                                                        </Typography>
                                                    </div>
                                                )}
                                                {/* <Button
                                                color="primary"
                                                variant="contained"
                                                style={{ float: 'right', height: 40, marginTop: theme.spacing.unit }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.handleSendVerificationCode();
                                                }}
                                                disabled={
                                                    resendDisabled ||
                                                    isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                                }
                                            >
                                                {loc('send', this.context.lang)}
                                            </Button> */}

                                                {!phoneNumberInputRequired && canEditNumber && (
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            style={{
                                                                marginLeft: theme.spacing.unit,
                                                                height: 40,
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            onClick={this.handleEditNumber}
                                                        >
                                                            {loc('registration2fa14', this.context.lang)}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Collapse>
                                        <Collapse in={showIdentityVerification}>
                                            <div style={{ float: 'right', height: 40 }}>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        height: 40,
                                                        marginTop: theme.spacing.unit
                                                    }}
                                                    onClick={this.handleShowIdentityVerification(false)}
                                                >
                                                    {loc('back', this.context.lang)}
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        height: 40,
                                                        marginTop: theme.spacing.unit
                                                    }}
                                                    onClick={this.handleVerifyIdentity}
                                                >
                                                    {loc('verify', this.context.lang)}
                                                </Button>
                                            </div>
                                        </Collapse>
                                        {/* {isInAppBrowser ? (
                                    <div style={{ marginTop: theme.spacing.unit * 3 }}>
                                        <AppStoreLink />
                                    </div>
                                ) : (
                                    <AlternativeRegistrations
                                        style={{ marginTop: theme.spacing.unit * 3 }}
                                        registerFacebook={() => this.handleoAuth('fb')}
                                        registerGoogle={() => this.handleoAuth('google')}
                                    />
                                )} */}
                                        {(showThirdPartyLogins ||
                                            (biometricsAvailable && biometricsHasCredentials)) && (
                                            <Collapse in={!twoFactorAuthenticationStep && !showIdentityVerification}>
                                                <div style={{ display: 'flex', marginTop: theme.spacing.unit * 2 }}>
                                                    <Divider style={{ flex: 2, marginTop: theme.spacing.unit }} />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around',
                                                            paddingRight: theme.spacing.unit,
                                                            paddingLeft: theme.spacing.unit
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            color="textSecondary"
                                                            style={{ flex: 1 }}
                                                        >
                                                            {loc('or', this.context.lang)}
                                                        </Typography>
                                                    </div>
                                                    <Divider style={{ flex: 2, marginTop: theme.spacing.unit }} />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-around'
                                                    }}
                                                >
                                                    {biometricsAvailable && biometricsHasCredentials && (
                                                        <Button
                                                            disabled={biometricSignInLoading}
                                                            style={{
                                                                height: 50,
                                                                borderRadius: 15,
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            onClick={this.handleBiometricsSignIn}
                                                            variant="outlined"
                                                            color="primary"
                                                        >
                                                            <Icon
                                                                style={{
                                                                    height: '24px',
                                                                    width: '24px',
                                                                    margin: theme.spacing.unit / 3
                                                                }}
                                                            >
                                                                fingerprint
                                                            </Icon>
                                                            <Typography
                                                                variant="button"
                                                                style={{
                                                                    marginLeft: theme.spacing.unit,
                                                                    color: theme.palette.primary.main,
                                                                    textTransform: 'none'
                                                                }}
                                                            >
                                                                {loc('biometrics2', this.context.lang)}
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                </div>
                                                {showThirdPartyLogins && (
                                                    <AlternativeRegistrations
                                                        registerFacebook={() => {
                                                            this.setState({
                                                                showFBDiscontinuedDialog: true
                                                            });
                                                        }}
                                                        registerGoogle={() => this.handleoAuth('google')}
                                                        registerApple={() => this.handleoAuth('apple')}
                                                        registerExpress={() => this.handleCustomAuth('exp')}
                                                    />
                                                )}
                                                {availableLangs.length > 1 && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            marginTop: theme.spacing.unit * 3,
                                                            marginBottom: theme.spacing.unit * 3
                                                        }}
                                                    >
                                                        <Icon
                                                            color="inherit"
                                                            style={{ marginRight: theme.spacing.unit }}
                                                        >
                                                            language
                                                        </Icon>
                                                        <Select
                                                            value={this.context.lang}
                                                            onChange={e => this.context.setLang(e.target.value)}
                                                            data-cy="language-select"
                                                        >
                                                            {availableLangs.map(availableLang => {
                                                                return (
                                                                    <MenuItem
                                                                        value={availableLang}
                                                                        key={availableLang}
                                                                        data-cy={`language-select-${availableLang}`}
                                                                    >
                                                                        {
                                                                            {
                                                                                en: 'English (CAN)',
                                                                                fr: 'Français (CAN)'
                                                                            }[availableLang]
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                )}
                                                {!isInAppBrowser && (
                                                    <div
                                                        style={{
                                                            marginTop: theme.spacing.unit * 3
                                                        }}
                                                    >
                                                        <AppStoreLink />
                                                    </div>
                                                )}
                                            </Collapse>
                                        )}
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{
                                                marginTop: theme.spacing.unit * 3,
                                                fontSize: '0.875rem'
                                            }}
                                            align="center"
                                        >
                                            {loc('login1a', this.context.lang)}{' '}
                                            <Link
                                                color="primary"
                                                style={{
                                                    color: theme.palette.primary.main
                                                }}
                                                to="/register"
                                            >
                                                {loc('signUp', this.context.lang)}{' '}
                                            </Link>{' '}
                                        </Typography>{' '}
                                        <Collapse in={!twoFactorAuthenticationStep && !showIdentityVerification}>
                                            {Object.values(this.props.helpCenterFunctions).includes(true) && (
                                                <Typography
                                                    variant="caption"
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        marginTop: theme.spacing.unit * 2
                                                    }}
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    {loc('login3a', this.context.lang)}{' '}
                                                    <FakeLink
                                                        theme={theme}
                                                        text={loc('login3b', this.context.lang)}
                                                        onClickEvent={this.handleHelpCenter(true)}
                                                        color={theme.palette.primary.main}
                                                        data-cy="login-help-center"
                                                    />
                                                </Typography>
                                            )}
                                        </Collapse>
                                    </form>
                                </Paper>

                                {(process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'TEST') && (
                                    <Typography
                                        align="center"
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                        onClick={this.handleResetAllPasswords}
                                    >
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={process.env.REACT_APP_ENV === 'PROD'}
                                            style={{ width: '93.5%', marginBottom: theme.spacing.unit }}
                                        >
                                            RESET ALL PASSWORDS
                                        </Button>
                                    </Typography>
                                )}
                                <Dialog open={forgotPasswordDialogOpen} fullWidth>
                                    <DialogTitle>{loc('emailPasswordReset1', this.context.lang)}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {loc('emailPasswordReset2', this.context.lang)}
                                        </DialogContentText>
                                        <FormControl fullWidth>
                                            <TextField
                                                name="recoveryEmail"
                                                label={loc('email', this.context.lang)}
                                                variant="outlined"
                                                value={recoveryEmail}
                                                style={{
                                                    marginTop: theme.spacing.unit,
                                                    marginBottom: theme.spacing.unit
                                                }}
                                                onChange={this.handleRecoveryEmail}
                                            />
                                        </FormControl>
                                        <Typography
                                            color="textSecondary"
                                            variant="caption"
                                            style={{ fontSize: '1rem' }}
                                        >
                                            {loc('emailPasswordReset3', this.context.lang)}{' '}
                                            <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                                                {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                                            </a>
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="default" onClick={this.handleForgotPasswordDialog(false)}>
                                            {loc('cancel', this.context.lang)}
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={recoveryEmail === ''}
                                            onClick={this.handleRequestResetPassword}
                                        >
                                            {loc('reset', this.context.lang)}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={accountLocked} fullWidth onClose={this.redirectToHome}>
                                    <DialogTitle>{loc('failedLogin1', this.context.lang)}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {loc('failedLogin2', this.context.lang, {
                                                lockAccountUntil
                                            })}{' '}
                                            <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                                                {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                                            </a>
                                            .
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="default" onClick={this.redirectToHome}>
                                            {loc('okay', this.context.lang)}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={identityVerificationUnavailableDialogOpen}
                                    fullWidth
                                    onClose={this.handleIdentityVerificationUnavailableDialog(false)}
                                >
                                    <DialogTitle>{loc('contactUs', this.context.lang)}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {loc('identityVerificationUnavailable', this.context.lang)}{' '}
                                            <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                                                {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                                            </a>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            color="default"
                                            onClick={this.handleIdentityVerificationUnavailableDialog(false)}
                                        >
                                            {loc('okay', this.context.lang)}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={helpCenterOpen} onClose={this.handleHelpCenter(false)} maxWidth="md">
                                    <DialogTitle>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="h5">{loc('login3b', this.context.lang)}</Typography>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        {this.props.helpCenterFunctions.showHowItWorks && (
                                            <RoundSquareButton
                                                button={{
                                                    onClick: this.handleHowItWorks(true),
                                                    color: theme.palette.primary.main,
                                                    text: loc('howItWorks', this.context.lang),
                                                    'data-cy': 'registration-help-center-how-it-works'
                                                }}
                                                variant="contained"
                                                icon={'settings'}
                                                theme={theme}
                                                width={125}
                                                style={{ margin: theme.spacing.unit }}
                                            />
                                        )}
                                        {this.props.helpCenterFunctions.showFaq && (
                                            <RoundSquareButton
                                                button={{
                                                    onClick: this.handleFAQ(true),
                                                    color: theme.palette.secondary.main,
                                                    text: 'FAQ',
                                                    'data-cy': 'registration-help-center-faq'
                                                }}
                                                variant="contained"
                                                icon={'question_answer'}
                                                theme={theme}
                                                width={125}
                                                style={{ margin: theme.spacing.unit }}
                                            />
                                        )}
                                        {this.props.helpCenterFunctions.showContactUs && (
                                            <RoundSquareButton
                                                button={{
                                                    onClick: this.handleContactForm(true),
                                                    color: theme.palette.text.disabled,
                                                    text: loc('contactUs', this.context.lang),
                                                    'data-cy': 'registration-help-center-contact-us'
                                                }}
                                                variant="contained"
                                                icon={'chat'}
                                                theme={theme}
                                                width={125}
                                                style={{ margin: theme.spacing.unit }}
                                            />
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            color="default"
                                            data-cy="bulk-counter-cancel"
                                            onClick={this.handleHelpCenter(false)}
                                            style={{}}
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                {this.props.helpCenterFunctions.showHowItWorks && (
                                    <HowItWorks
                                        open={howItWorksOpen}
                                        tabIndex={!_.isNil(tabIndex) ? tabIndex : 0}
                                        onFAQ={this.handleFAQ}
                                        onClose={this.handleHowItWorks(false)}
                                        useFallbackData={useFallbackData}
                                        collectionProgramEnabled={collectionProgramEnabled}
                                    />
                                )}

                                <FAQ
                                    open={faqOpen}
                                    tabIndex={!_.isNil(tabIndex) ? tabIndex : 0}
                                    onClose={this.handleFAQ(false)}
                                    http={this.props.http}
                                    useFallbackData={useFallbackData}
                                />

                                <ContactForm
                                    specification={'HELP_CENTER'}
                                    http={this.props.http}
                                    open={contactFormOpen}
                                    onSubmitSuccess={this.handleContactSubmitSuccess}
                                    onClose={this.handleContactForm(false)}
                                />

                                <OAuthDialog
                                    message={authDialogMessage}
                                    open={showAuthDialog}
                                    http={this.props.http}
                                    oAuthUser={oAuthUser}
                                    onClose={this.handleCancelOAuthRegistration}
                                    onNext={this.handleRegisterOAuth}
                                    charityPreferred={_charity.getId(oAuthRegistrationCharity)}
                                    onCharityPreferred={this.handleOAuthRegistrationCharity}
                                />

                                <CustomSnackbar
                                    open={snackbarOpen}
                                    message={snackbarMessage}
                                    variant={snackbarVariant}
                                    onClose={this.handleSnackbarClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </ContainerWithLogo> */}
            </>
        );
    }
}

export default withWidth()(withTheme()(withRouter(Login)));

function formatAndRedactPhoneNumber(phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{2})(\d{6})(\d{2})$/);
    if (match) {
        return '(' + match[1] + '*) ***-**' + match[3];
    }
    return phoneNumber;
}

function WaitToRetry({ time }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(time));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(time));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(time)
            .diff(moment(new Date()), 'seconds')
            .toString()
    );
}
function isIncompletePhoneNumber(phoneNumber) {
    return phoneNumber.toString().length < 10;
}

function FacebookDiscontinuedDialog(props) {
    const { open, onClose, onPasswordReset } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogContent>
                <Typography variant="h6">{loc('fbDiscontinued1', lang)}</Typography>
                <Typography
                    component={Link}
                    to="#"
                    variant="h6"
                    color="primary"
                    style={{ display: 'inline', marginTop: 15 }}
                    onClick={onPasswordReset}
                >
                    {loc('emailPasswordReset1', lang)}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function attemptSocketClose(socket) {
    // Not necessary, but  no point leaving sockets open if they aren't being used
    try {
        socket.close();
    } catch (err) {}
}

function clearSessionStorageItems() {
    window.sessionStorage.removeItem('security-question');
    window.sessionStorage.removeItem('identity-verification-enabled');
    window.sessionStorage.removeItem('identity-verification-available');
    window.sessionStorage.removeItem('login-success');
    window.sessionStorage.removeItem('login-email');
    window.sessionStorage.removeItem('login-oAuthUID');
    window.sessionStorage.removeItem('login-phone');
}

function setSessionStorageItems({
    securityQuestion,
    identityVerificationEnabled,
    identityVerificationAvailable,
    loginSuccess,
    loginEmail,
    loginOAuthUID,
    loginPhone
}) {
    if (!_.isNil(securityQuestion)) {
        window.sessionStorage.setItem('security-question', securityQuestion);
    }
    if (!_.isNil(identityVerificationEnabled)) {
        window.sessionStorage.setItem('identity-verification-enabled', identityVerificationEnabled);
    }
    if (!_.isNil(identityVerificationAvailable)) {
        window.sessionStorage.setItem('identity-verification-available', identityVerificationAvailable);
    }
    if (!_.isNil(loginSuccess)) {
        window.sessionStorage.setItem('login-success', loginSuccess);
    }
    if (!_.isNil(loginEmail)) {
        window.sessionStorage.setItem('login-email', loginEmail);
    }
    if (!_.isNil(loginOAuthUID)) {
        window.sessionStorage.setItem('login-oAuthUID', loginOAuthUID);
    }
    if (!_.isNil(loginPhone)) {
        window.sessionStorage.setItem('login-phone', loginPhone);
    }
}
