import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import * as terms from 'localizations/terms';

import VERSION_FRONT from 'VERSION';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import MdiIcon from '@mdi/react';
import * as allMdiIcons from '@mdi/js';
import { Link } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import { deviceHelper } from 'utils/misc.js';
import mustache from 'mustache';
import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const HowItWorks = ({
    theme,
    fullScreen,
    open,
    numUsers,
    onClose,
    onFAQ,
    tabIndex,
    useFallbackData,
    isRegisteredUser,
    collectionProgramEnabled = false,
    collectionProgramFirst = false,
    isDriver
}) => {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [category, setCategory] = useState(0);
    const [howItWorksObj, setHowItWorksObj] = useState(null);
    const [howItWorksStep, setHowItWorksStep] = useState(null);
    const [ctgrsWithDataIndices, setCtgrsWithDataIndices] = useState([]);

    const handleFAQ = () => {
        onClose();
        onFAQ(true)();
    };

    const handleChange = e => {
        setActiveStep(0);
        setCategory(e.target.value);
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => setActiveStep(0), 200);
    };

    const getData = async () => {
        let hiwEndpoint = `/howItWorks/${lang}`;
        if (useFallbackData) {
            const version = VERSION_FRONT.replaceAll('.', '-');
            hiwEndpoint = `/howItWorks/fallback/${lang}/${version}`;
        }

        let res = await http.getJSON(hiwEndpoint);

        let howItWorksTemp = [];
        if (res.ok) {
            howItWorksTemp = res.data.howItWorks;
        } else {
            howItWorksTemp = require('./HowItWorks/how-it-works-' +
                process.env.REACT_APP_REGION_EXT.toLowerCase() +
                '.json');
        }

        if (isDriver) {
            howItWorksTemp = howItWorksTemp.filter(category => _.get(category, 'forDrivers', false));
        } else {
            howItWorksTemp = howItWorksTemp.filter(category => !_.get(category, 'forDrivers', false));
        }

        if (!collectionProgramEnabled) {
            // If Collection Program is disabled on this environment, remove the categories for Collection Program
            howItWorksTemp = howItWorksTemp.filter(category => !_.get(category, 'forCollectionProgram', false));
        } else if (collectionProgramEnabled && collectionProgramFirst) {
            // Move the Collection Program to the front (as the default) when the How It Works opens
            // First: check if there's an index for a Collection Program category (we'll take the first one we find, if any)
            const cpIndex = howItWorksTemp.findIndex(
                category => _.get(category, 'forCollectionProgram', false) === true
            );
            if (cpIndex > -1) {
                // Now pull it out and move it to the beginning
                const [cpCategory] = howItWorksTemp.splice(cpIndex, 1);
                howItWorksTemp.unshift(cpCategory);
            }
        }

        //gets boolean array that determines whether or not the category index has data to show
        const availabilityArr = howItWorksTemp.map(category => !_.isEmpty(_.get(category, 'steps')));

        let noDataCtr = 0,
            withDataIndicesTemp = [];

        // tab index is inconsiderate of data availability hence need to assign it to an index of a category containing data if possible
        for (const [categoryIdx, categoryHasData] of availabilityArr.entries()) {
            if (!categoryHasData) {
                noDataCtr++;
                if (noDataCtr === 3) {
                    // this means none of the How It Works categories have data
                    withDataIndicesTemp = [];
                    break;
                }
            }

            let adjustedIdx = Math.max(categoryIdx - noDataCtr, 0);
            withDataIndicesTemp.push(adjustedIdx);
        }

        setCtgrsWithDataIndices(withDataIndicesTemp);
        let howItWorksWithData;
        if (isRegisteredUser) {
            howItWorksWithData = howItWorksTemp
                .map(category => ({
                    ...category,
                    steps: Array.isArray(category.steps)
                        ? category.steps.filter(step => !_.get(step, 'hideFromRegisteredUsers', false))
                        : []
                }))
                .filter(category => !_.isEmpty(_.get(category, 'steps')));
        } else {
            howItWorksWithData = howItWorksTemp.filter(category => !_.isEmpty(_.get(category, 'steps')));
        }
        setHowItWorksObj(howItWorksWithData);
    };

    useEffect(() => {
        if (!open) return;

        setLoading(true);
        (async () => {
            await getData();

            setLoading(false);
        })();
    }, [lang, open]);

    useEffect(() => {
        if (_.isEmpty(ctgrsWithDataIndices)) return;

        let newCategory = 0;
        if (tabIndex === 1 || tabIndex === 3) {
            newCategory = 1;
        } else if (tabIndex === 2) {
            newCategory = 2;
        }

        if (newCategory >= ctgrsWithDataIndices.length) {
            newCategory = 0;
        }

        newCategory = ctgrsWithDataIndices[newCategory];

        setCategory(newCategory);
        setActiveStep(0);
    }, [tabIndex, ctgrsWithDataIndices]);

    useEffect(() => {
        if (_.isEmpty(howItWorksObj) || _.isEmpty(ctgrsWithDataIndices)) return;

        setHowItWorksStep(howItWorksObj[category || 0].steps[activeStep]);
    }, [howItWorksObj, category, activeStep]);

    const menuItemInnerStyle = {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    };

    if (!open) {
        return <></>;
    }

    if (loading || _.isEmpty(howItWorksObj) || _.isNil(category)) {
        return (
            <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={handleClose}>
                <DialogTitlePrimary>
                    <span style={{ marginRight: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        {loc('howItWorks', lang)}:
                    </span>
                </DialogTitlePrimary>
                {!loading && (
                    <>
                        <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                            <Typography variant="h6">{loc('howItWorksUnavailable', lang)}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={handleClose} data-cy="howitworks-close-button">
                                {loc('close', lang)}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    }

    return (
        <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={handleClose}>
            <DialogTitlePrimary>
                <span style={{ marginRight: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                    {loc('howItWorks', lang)}:
                </span>
                <Select
                    value={category}
                    onChange={handleChange}
                    style={{
                        maxWidth: '100%',
                        paddingLeft: theme.spacing.unit * 2,
                        color: 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)'
                    }}
                    data-cy="how-it-works-category-select"
                >
                    {(howItWorksObj || []).map((typeObj, i) => (
                        <MenuItem data-cy={`how-it-works-category-select-${i}`} value={i} key={i}>
                            <div style={menuItemInnerStyle}>{_.get(typeObj, 'type')}</div>
                        </MenuItem>
                    ))}
                    {/*<MenuItem value={0}>
                            <div style={menuItemInnerStyle}>Residential, Business & Commercial</div>
                        </MenuItem>
                        <MenuItem value={1}>
                            <div style={menuItemInnerStyle}>Charity & {terms.ORGANIZATION_NAME} Fundraising</div>
                        </MenuItem>
                        {JSON.parse(process.env.REACT_APP_GET_RECYCLING_SOFTWARE_OPTION_ENABLED) && (
                            <MenuItem value={2}>
                                <div style={menuItemInnerStyle}>Bottle Depots & Mobile Recycling</div>
                            </MenuItem>
                        )}*/}
                </Select>
            </DialogTitlePrimary>
            {howItWorksStep && (
                <>
                    <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                        <MobileStepper
                            variant="dots"
                            steps={howItWorksObj[category].steps.length}
                            position="static"
                            activeStep={activeStep}
                            nextButton={<div style={{ height: 42 }} />}
                            backButton={<div style={{ height: 42 }} />}
                            style={{ backgroundColor: theme.palette.background.paper }}
                        />
                        <div style={{ textAlign: 'center', marginBottom: 16 }}>
                            <MdiIcon
                                path={allMdiIcons[howItWorksStep.icon ? howItWorksStep.icon : 'mdiCircle']}
                                size={5}
                                color={theme.palette.primary.main}
                            />
                            <Typography data-cy="how-it-works-title" variant="h6">
                                {howItWorksStep.title}
                            </Typography>
                        </div>
                        <Typography data-cy="how-it-works-text" color="textSecondary" variant="subtitle1">
                            {mustache.render(_.get(howItWorksStep, 'text', ''), { totalCustomers: numUsers })}
                            {howItWorksStep.referApp === true && !deviceHelper.iOSCordova() ? (
                                <span>
                                    {' '}
                                    {loc('referAppa', lang)}{' '}
                                    <Link
                                        href={process.env.REACT_APP_APPSTORE_LINK}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={e => {
                                            e.preventDefault();
                                            window.open(process.env.REACT_APP_APPSTORE_LINK);
                                        }}
                                    >
                                        App Store
                                    </Link>{' '}
                                    {loc('referAppb', lang)}{' '}
                                    <Link
                                        href={process.env.REACT_APP_APPSTORE_LINK}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={e => {
                                            e.preventDefault();
                                            window.open(process.env.REACT_APP_GOOGLE_PLAY_LINK);
                                        }}
                                    >
                                        Google Play
                                    </Link>
                                    .
                                </span>
                            ) : (
                                ''
                            )}
                        </Typography>
                        {'instructions' in howItWorksStep ? (
                            <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                component="ul"
                                style={{ paddingTop: theme.spacing.unit * 2 }}
                                data-cy="how-it-works-instructions"
                            >
                                {howItWorksStep.instructions.map((instruction, index) => {
                                    let splitString = instruction.split('{{faq}}');
                                    return (
                                        <li key={`instruction-${index}`}>
                                            {splitString[0]}

                                            {instruction.includes('{{faq}}') ? (
                                                <span
                                                    style={{
                                                        color: theme.palette.primary.main,
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleFAQ}
                                                >
                                                    FAQ
                                                </span>
                                            ) : (
                                                ''
                                            )}

                                            {splitString[1]}
                                        </li>
                                    );
                                })}
                            </Typography>
                        ) : (
                            ''
                        )}
                    </DialogContent>
                    <DialogActions>
                        {activeStep === 0 && (
                            <Hidden smUp>
                                <Button color="primary" data-cy="InfoDialogs-HowItWorks-close" onClick={handleClose}>
                                    {loc('close', lang)}
                                </Button>
                            </Hidden>
                        )}
                        {activeStep > 0 && (
                            <Button color="primary" onClick={handleBack} data-cy="howitworks-back-button">
                                {loc('back', lang)}
                            </Button>
                        )}
                        {activeStep < howItWorksObj[category].steps.length - 1 && (
                            <Button color="primary" onClick={handleNext} data-cy="howitworks-next-button">
                                {loc('next', lang)}
                            </Button>
                        )}
                        {activeStep === howItWorksObj[category].steps.length - 1 && (
                            <Button color="primary" onClick={handleClose} data-cy="howitworks-close-button">
                                {loc('close', lang)}
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(HowItWorks));
