import React, { useContext } from 'react';

import _ from 'lodash';

import { withTheme, Typography, Paper, Divider, Grid, Icon } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { _commodity } from 'std';
import { loc } from '../../localizations/localizationHandler';

function CombinedRatingWidget(props) {
    const { theme, pickupRating, countRating, height } = props;

    const { lang } = useContext(LocalizationContext);

    const boxStyleFlex = {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2,
        flex: `calc(14% - ${theme.spacing.unit * 2}px)`,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    };

    return (
        <Paper style={{ padding: theme.spacing.unit * 2, height: height ? height : undefined }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: theme.spacing.unit / 2
                }}
            >
                <Typography variant="h6">{loc('combinedStats', lang)}</Typography>
            </div>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        local_shipping
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.goodPickups}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('pickup', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        check_circle
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.totalPickups}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('complete', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        not_interested
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.abortedCount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('aborted', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        timer
                    </Icon>
                    <Typography
                        variant="h6"
                        color="default"
                        style={{ fontWeight: 400, whiteSpace: 'nowrap', marginTop: theme.spacing.unit }}
                        data-cy="driver-total-time"
                    >
                        {'N/A'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <span style={{ whiteSpace: 'nowrap' }}> {loc('totalTime', lang)}</span> (Hr)
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        check_circle
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.goodCounts}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Accurate
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        category
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.totalCounts}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Sorted
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        assignment
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.reportedCount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Reported
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}

export default withTheme()(CombinedRatingWidget);
