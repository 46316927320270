import { useEffect, useState } from 'react';
import _ from 'lodash';

const usePermissions = ({ http, auth }) => {
    // Currently used for customers permissions
    // Can be extended to work for admins...

    const [customerPermissions, setCustomerPermissions] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchPermissions = async () => {
        setLoading(true);

        const customerPermissions = {};

        const res = await http.getJSON('/system/configuration/permissions', true, true);
        if (res.ok) {
            customerPermissions.showImpactReportInSideMenu = res.data.showImpactReportInSideMenu;
            customerPermissions.allowTaxReceiptOptOut = res.data.allowTaxReceiptOptOut;
        }

        setCustomerPermissions(customerPermissions);
        setLoading(false);
    };

    useEffect(() => {
        if (_.isEmpty(http) || _.isEmpty(auth) || !auth.isAuthenticated) {
            // http and auth are both dependencies for this hook
            return;
        }

        fetchPermissions();
    }, [auth]);

    return {
        customerPermissions
    };
};

export default usePermissions;
