import React, { useContext } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    Typography,
    Collapse,
    colors,
    Icon,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    InputAdornment,
    Chip,
    Grid
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import GMapsAutocomplete from 'components/GMapsAutocomplete';

import { getTextInput, getSwitch, getSelect, getTimePicker } from '../helperFunctions';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { DROP_OFF_TYPES, maxFileSize } from 'constants.js';
import { useState } from 'react';
import { useEffect } from 'react';
import { isAUSRegion, isCONRegion, isEXPRegion, isSTDRegion } from 'utils/misc';
import { useRef } from 'react';
import { FormControlLabel, TextField, Switch } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';

const iconColors = [
    'pink',
    'red',
    'deepOrange',
    'orange',
    'amber',
    'yellow',
    'lightGreen',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'grey',
    'black'
];

const imageFileTypes = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif'
];

const defaultAvailability = [
    { day: 'Monday', hours: '8am–6pm' },
    { day: 'Tuesday', hours: '8am–6pm' },
    { day: 'Wednesday', hours: '8am–6pm' },
    { day: 'Thursday', hours: '8am–6pm' },
    { day: 'Friday', hours: '8am–6pm' },
    { day: 'Saturday', hours: '8am–6pm' },
    { day: 'Sunday', hours: '8am–6pm' }
];

const DROP_OFF_TYPES_WITH_SERIAL_NUMS = ['Reverse Vending Machine'];
const dropLocationTabs = ['information', 'hours'];

function DropLocationForm(props) {
    const {
        http,
        google,
        dropLocation, //NOTE: will be null/undefined if creating a new one
        commodities,
        collectors,
        open,
        defaults,
        onClose,
        onSubmit,
        theme,
        fullScreen,
        onSnackbar,
        editing,
        width,
        defaultCollector,
        pinTypes
    } = props;
    const { lang } = useContext(LocalizationContext);
    const defaultDropLocationAvailability = _.get(dropLocation, 'availability', defaultAvailability);
    const [imageError, setImageError] = useState(false);
    const [collector, setCollector] = useState(
        _.find(collectors, c => c._id === _.get(dropLocation, 'collector._id', _.get(dropLocation, 'collector', '')))
    );

    const [markerFile, setMarkerFile] = useState(undefined);
    const [overrideColorToggle, setOverrideColorToggle] = useState(!_.isNil(_.get(dropLocation, 'overrideColor')));
    const [selectedTab, setSelectedTab] = useState('information');

    const isSmallScreen = isWidthDown('xs', width);

    const handleFormSubmit = values => {
        let valuesToSubmit = _.cloneDeep(values);

        const pinType = _.find(pinTypes, p => p._id.toString() === valuesToSubmit.mapPinType);
        valuesToSubmit.mapPinType = pinType ? valuesToSubmit.mapPinType : null;
        valuesToSubmit.overrideColor = overrideColorToggle ? valuesToSubmit.overrideColor : null;
        onSubmit(valuesToSubmit, markerFile);
    };
    const formik = useFormik({
        initialValues: {
            _id: _.get(dropLocation, '_id', undefined),
            name: _.get(dropLocation, 'name', ''),
            description: _.get(dropLocation, 'description', ''),
            collector: _.get(dropLocation, 'collector._id', defaultCollector || ''),
            location: _.get(dropLocation, 'location'),
            payloadAccepted: _.get(dropLocation, 'payloadAccepted', []),
            payloadRequired: _.get(dropLocation, 'payloadRequired', []),
            availability: _.get(dropLocation, 'existsAtReturnSite', false)
                ? _.get(collector, 'availability', defaultDropLocationAvailability)
                : defaultDropLocationAvailability,
            enabled: _.get(dropLocation, 'enabled', true),
            qrScanRequired: _.get(dropLocation, 'qrScanRequired', false),
            gpsRequired: _.get(dropLocation, 'gpsRequired', true),
            existsAtReturnSite: _.get(dropLocation, 'existsAtReturnSite', false),
            showNameOnMap: _.get(dropLocation, 'showNameOnMap', false),
            //nameColor: _.get(dropLocation, 'nameColor', theme.palette.primary.main),
            color: _.get(dropLocation, 'color', 'grey'),
            overrideColor: _.isNil(_.get(dropLocation, 'overrideColor'))
                ? theme.palette.primary[500]
                : _.get(dropLocation, 'overrideColor'),
            overrideMarkerUrl: !_.isNil(_.get(dropLocation, 'markerUrl')),
            markerUrl: _.get(dropLocation, 'markerUrl', undefined),
            markerFile: undefined,
            dropOffType: _.get(dropLocation, 'dropOffType', _.first(DROP_OFF_TYPES).value),
            serialNumbers: _.get(dropLocation, 'serialNumbers', []),
            serialNumber: '',
            kioskKey: _.get(dropLocation, 'kioskKey', ''),
            mapPinType: _.get(dropLocation, 'mapPinType._id', 'None')
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a name'),
            description: Yup.string().required('Your must enter a description'),
            collector: Yup.string().required('You must select a collector'),
            location: Yup.object()
                .required('You must select a location')
                .nullable()
        }),
        onSubmit: handleFormSubmit
    });

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue(e.target.name, e.target.value);
            }
        }, 100)
    );

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik, e.target.name);
    };

    const handleAcceptedPayloadChange = e => {
        const { value } = e.target;

        const newPayloadRequired = _.filter(
            value,
            v =>
                formik.getFieldProps('payloadRequired').value.includes(v) &&
                formik.getFieldProps('payloadAccepted').value.includes(v)
        );

        formik.setFieldValue('payloadAccepted', value);
        formik.setFieldValue('payloadRequired', newPayloadRequired);
    };

    const handleChangeCollector = async e => {
        const { value } = e.target;

        const collector = _.find(collectors, c => c._id === value);

        if (collector) {
            formik.setFieldValue('payloadAccepted', _.get(collector, 'payloadAccepted', []));
            formik.setFieldValue('payloadRequired', _.get(collector, 'payloadRequired', []));

            if (collector.location) formik.setFieldValue('location', collector.location);
            if (collector.name) formik.setFieldValue('name', collector.name);
            formik.setFieldValue('description', 'Bottle Depot');
        }
        setCollector(collector);
        formik.setFieldValue('collector', value);
    };

    const handleTimeChange = (dayIdx, newTime, isStartTime) => {
        let hours = newTime.hours();
        const minutes = newTime.minutes();
        const minuteString = minutes > 0 ? (minutes >= 10 ? ':' + minutes : ':' + '0' + minutes) : '';

        const timeSuffix = hours >= 12 ? 'pm' : 'am';
        hours = hours === 0 ? hours + 12 : hours > 12 ? hours - 12 : hours;

        const oldHours = formik.values.availability[dayIdx].hours;
        const split = oldHours.split('–');

        let newHours;

        if (isStartTime) {
            newHours = hours + minuteString + timeSuffix + '–' + split[1];
        } else {
            newHours = split[0] + '–' + hours + minuteString + timeSuffix;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleToggleDay = dayIdx => {
        let newHours;

        if (formik.values.availability[dayIdx].hours !== 'Closed') {
            newHours = 'Closed';
        } else if (defaultDropLocationAvailability[dayIdx].hours === 'Closed') {
            newHours = defaultAvailability[dayIdx].hours;
        } else {
            newHours = defaultDropLocationAvailability[dayIdx].hours;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleToggleAlwaysOpen = dayIdx => {
        let newHours;

        if (formik.values.availability[dayIdx].hours !== '12am–11:59pm') {
            newHours = '12am–11:59pm';
        } else if (
            defaultDropLocationAvailability[dayIdx].hours === 'Closed' ||
            defaultDropLocationAvailability[dayIdx].hours === '12am–11:59pm'
        ) {
            newHours = defaultAvailability[dayIdx].hours;
        } else {
            newHours = defaultDropLocationAvailability[dayIdx].hours;
        }

        const newAvailability = setItemAtIndex(formik.values.availability, dayIdx, 'hours', newHours);

        formik.setFieldValue('availability', newAvailability);
    };

    const handleDrop = e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                _.set(formik.values, 'markerHeight', image.height);
                _.set(formik.values, 'markerWidth', image.width);
            };
            setMarkerFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleEditSerialNumber = (updateSerialNumber, add = false) => {
        let serialNumbers = _.clone(formik.values.serialNumbers);

        if (add) {
            if (serialNumbers.includes(updateSerialNumber)) {
                onSnackbar('You cannot enter a duplicate serial number', 'error');
                return;
            }

            serialNumbers.push(updateSerialNumber);
        } else {
            serialNumbers = serialNumbers.filter(serialNumber => serialNumber != updateSerialNumber);
        }

        formik.setFieldValue('serialNumber', '');
        formik.setFieldValue('serialNumbers', serialNumbers);
    };

    useEffect(() => {
        if (formik.values.existsAtReturnSite) {
            const collector = _.find(collectors, c => c._id === formik.values.collector);
            if (_.isNil(collector)) {
                return;
            }
            formik.setFieldValue('availability', collector.availability);
            formik.setFieldValue('location', collector.location);
        }
    }, [formik.values.existsAtReturnSite, formik.values.collector]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {editing ? 'Update' : 'Create'} {editing && getSwitch(theme, 'enabled', 'Enabled', formik)}
                </div>
            </DialogTitle>
            <span>
                {' '}
                <ToggleButtonGroup
                    value={selectedTab}
                    exclusive
                    onChange={(e, selectedTab) => !_.isEmpty(selectedTab) && setSelectedTab(selectedTab)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit, display: 'flex' }}
                >
                    {dropLocationTabs.map(tab => (
                        <ToggleButton
                            key={tab}
                            value={tab}
                            style={{
                                flexGrow: 1,
                                height: 50
                            }}
                            data-cy={`dropLocation-form-${tab}-tab`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                {tab}
                                {tab === 'information' ? (
                                    <Icon
                                        style={{
                                            color: 'blue'
                                        }}
                                    >
                                        horizontal_rule
                                    </Icon>
                                ) : tab === 'hours' && !formik.values.existsAtReturnSite ? (
                                    <Icon
                                        style={{
                                            color: 'green'
                                        }}
                                    >
                                        check
                                    </Icon>
                                ) : (
                                    <Icon
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        highlight_off
                                    </Icon>
                                )}
                            </div>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </span>
            <DialogContent
                style={{
                    height: isSmallScreen ? 'auto' : '800px',
                    width: isSmallScreen ? '100%' : '600px'
                }}
            >
                <Grid container spacing={theme.spacing.unit}>
                    {selectedTab === 'information' && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                                    Drop Location Info
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                                {getSelect(
                                    theme,
                                    'collector',
                                    'Collector',
                                    collectors,
                                    formik,
                                    handleChangeCollector,
                                    '_id',
                                    'name'
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                                {getTextInput(theme, 'name', 'Name', formik)}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <GMapsAutocomplete
                                    disabled={formik.values.existsAtReturnSite}
                                    onBlur={formik.handleBlur}
                                    http={http}
                                    google={google}
                                    location={{
                                        lat: _.get(formik, 'values.location.lat'),
                                        lng: _.get(formik, 'values.location.lng')
                                    }}
                                    label="Location"
                                    placeholder="Enter an address"
                                    selectedValue={_.get(formik, 'values.location.description', '')}
                                    types={['address']}
                                    error={{
                                        fail:
                                            _.get(formik.errors, 'location', false) &&
                                            _.get(formik.touched, 'location', false),
                                        reason: 'You must enter a location'
                                    }}
                                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * -1 }}
                                    onSuggestionSelected={({ suggestion, place }) => {
                                        formik.setFieldValue('location', {
                                            description: suggestion.description,
                                            place_id: suggestion.place_id,
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng()
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {getTextInput(theme, 'description', 'Description', formik)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {getSelect(
                                    theme,
                                    'payloadAccepted',
                                    'Accepted Payloads',
                                    commodities,
                                    formik,
                                    handleAcceptedPayloadChange,
                                    '_id',
                                    `payloadInputName.${lang}`,
                                    true
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {getSelect(
                                    theme,
                                    'payloadRequired',
                                    'Required Payloads',
                                    _.filter(commodities, c => formik.values.payloadAccepted.includes(c._id)),
                                    formik,
                                    null,
                                    '_id',
                                    `payloadInputName.${lang}`,
                                    true
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl
                                    fullWidth
                                    error={
                                        _.get(formik.touched, 'dropOffType', false) &&
                                        _.get(formik.errors, 'dropOffType', false)
                                            ? true
                                            : null
                                    }
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                >
                                    <InputLabel variant="outlined">{'Drop Off Type'}</InputLabel>
                                    <Select
                                        {...formik.getFieldProps('dropOffType')}
                                        fullWidth
                                        input={
                                            <OutlinedInput
                                                label="Drop Off Type"
                                                labelWidth={'Drop Off Type'.length * theme.spacing.unit}
                                                data-cy={`${_.kebabCase('dropOffType')}-input`}
                                            />
                                        }
                                    >
                                        {DROP_OFF_TYPES.map((type, idx) => (
                                            <MenuItem
                                                data-cy={`${_.kebabCase('dropOffType')}-select-${idx}`}
                                                value={type.value}
                                                key={idx}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <Icon
                                                        style={{
                                                            color: theme.palette.primary.main,
                                                            marginRight: theme.spacing.unit * 2
                                                        }}
                                                    >
                                                        {type.icon}
                                                    </Icon>
                                                    <Typography variant="b2">{type.value}</Typography>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {_.get(formik.touched, 'dropOffType', false) &&
                                        _.get(formik.errors, 'dropOffType', false) && (
                                            <FormHelperText>{_.get(formik.errors, 'dropOffType', '')}</FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>

                            {!_.get(formik, 'values.dropOffType', '')
                                .toLowerCase()
                                .includes('app only') && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={
                                        _.get(formik, 'values.dropOffType', '')
                                            .toLowerCase()
                                            .includes('kiosk') && formik.values.kioskKey
                                            ? 6
                                            : 12
                                    }
                                >
                                    {getTextInput(theme, 'serialNumber', 'Machine Serial Numbers', formik, 'text', {
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ cursor: 'pointer', color: colors.grey[500] }}
                                            >
                                                <Icon
                                                    onClick={() =>
                                                        handleEditSerialNumber(formik.values.serialNumber, true)
                                                    }
                                                >
                                                    add_circle
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    })}
                                    <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                                        {formik.values.serialNumbers.map(serialNumber => (
                                            <Chip
                                                key={`serialNumber-chip-${serialNumber}`}
                                                label={serialNumber}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                                onDelete={() => handleEditSerialNumber(serialNumber, false)}
                                                deleteIcon={
                                                    <Icon data-cy={`serialNumber-remove-${serialNumber}`}>cancel</Icon>
                                                }
                                            />
                                        ))}
                                    </div>
                                </Grid>
                            )}

                            {_.get(formik, 'values.dropOffType', '')
                                .toLowerCase()
                                .includes('kiosk') &&
                                formik.values.kioskKey && (
                                    <Grid item xs={12} sm={6}>
                                        {getTextInput(
                                            theme,
                                            'kioskKey',
                                            'Kiosk Key',
                                            formik,
                                            'text',
                                            {},
                                            {},
                                            null,
                                            true
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12} sm={6}>
                                {getSwitch(theme, 'qrScanRequired', 'Show QR Scan Option', formik)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {_.get(formik, 'values.dropOffType', '')
                                    .toLowerCase()
                                    .includes('app only') && getSwitch(theme, 'gpsRequired', 'GPS Required', formik)}
                            </Grid>
                        </>
                    )}
                </Grid>
                {/* {getSwitch(theme, 'outOfAppCompletion', 'Prompt Customer to Complete Drop Off Outside Of App', formik)}
                {getSwitch(theme, 'accessCodeRequired', 'Access Code Required For Drop Off', formik)} */}
                {selectedTab === 'hours' && (
                    <>
                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                            {getSwitch(theme, 'existsAtReturnSite', 'Exists at Return Site', formik)}
                        </div>

                        <Collapse in={!formik.values.existsAtReturnSite}>
                            <FormControl
                                data-cy="charity-select-select-dropdown"
                                fullWidth
                                style={{ marginTop: theme.spacing.unit * 2 }}
                            >
                                <InputLabel variant="outlined" htmlFor="mapPinType">
                                    Map Pin Type
                                </InputLabel>
                                <Select
                                    {...formik.getFieldProps('mapPinType')}
                                    input={
                                        <OutlinedInput
                                            labelWidth={100}
                                            name="mapPinType"
                                            id="mapPinType"
                                            data-cy="collector-select-pin-type-dropdown"
                                        />
                                    }
                                    style={{ textAlign: 'left', backgroundColor: theme.palette.background.paper }}
                                    renderValue={value => {
                                        const pinType = _.find(pinTypes, p => p._id.toString() === value.toString());
                                        return pinType ? (
                                            <div>
                                                <img
                                                    src={pinType.url}
                                                    style={{
                                                        height: pinType.height,
                                                        width: pinType.width,
                                                        margin: 0,
                                                        padding: 0,
                                                        marginRight: theme.spacing.unit,
                                                        verticalAlign: 'middle'
                                                    }}
                                                    alt={`${pinType.type} Pin`}
                                                />
                                                <span style={{ verticalAlign: 'middle' }}>{pinType.type}</span>
                                            </div>
                                        ) : (
                                            'None (Use Default Pin)'
                                        );
                                    }}
                                >
                                    <MenuItem value={'None'}>None (Use Default Pin)</MenuItem>
                                    {pinTypes.map(pinType => (
                                        <MenuItem
                                            key={pinType.type}
                                            value={pinType._id}
                                            style={{ height: pinType.height }}
                                        >
                                            <img
                                                src={pinType.url}
                                                style={{
                                                    height: pinType.height,
                                                    width: pinType.width,
                                                    marginRight: theme.spacing.unit
                                                }}
                                                alt={`${pinType.type} Pin`}
                                            />
                                            {pinType.type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {getSwitch(theme, 'showNameOnMap', 'Show Name On Map', formik)}
                            {(isCONRegion() || isEXPRegion()) && (
                                <Collapse in={formik.values.showNameOnMap}>
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={overrideColorToggle}
                                                    onChange={e => setOverrideColorToggle(e.target.checked)}
                                                    style={{ marginRight: 0 }}
                                                />
                                            }
                                            label={<Typography>Override Name Color</Typography>}
                                        />

                                        {overrideColorToggle ? (
                                            <TextField
                                                type="color"
                                                onChange={handleChangeColorWrapper}
                                                value={formik.values.overrideColor || theme.palette.primary[500]}
                                                onBlur={formik.handleBlur}
                                                name="overrideColor"
                                                label="Primary Color"
                                                margin="normal"
                                                variant="outlined"
                                                style={{ width: '322px', marginLeft: theme.spacing.unit * 2 }}
                                            />
                                        ) : (
                                            <TextField
                                                type="color"
                                                value={theme.palette.primary[500]}
                                                onBlur={formik.handleBlur}
                                                label="Primary Default"
                                                margin="normal"
                                                variant="outlined"
                                                style={{ width: '322px', marginLeft: theme.spacing.unit * 2 }}
                                                disabled
                                            />
                                        )}
                                    </div>
                                </Collapse>
                            )}
                            {(isAUSRegion() || isSTDRegion()) && (
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={overrideColorToggle}
                                                onChange={e => setOverrideColorToggle(e.target.checked)}
                                                style={{ marginRight: 0 }}
                                            />
                                        }
                                        label={<Typography>Override Pin Color</Typography>}
                                    />

                                    {overrideColorToggle ? (
                                        <TextField
                                            type="color"
                                            onChange={handleChangeColorWrapper}
                                            value={formik.values.overrideColor || theme.palette.primary[500]}
                                            onBlur={formik.handleBlur}
                                            name="overrideColor"
                                            label="Primary Color"
                                            margin="normal"
                                            variant="outlined"
                                            style={{ width: '340px', marginLeft: theme.spacing.unit * 2 }}
                                        />
                                    ) : (
                                        <TextField
                                            type="color"
                                            value={theme.palette.primary[500]}
                                            onBlur={formik.handleBlur}
                                            label="Primary Default"
                                            margin="normal"
                                            variant="outlined"
                                            style={{ width: '340px', marginLeft: theme.spacing.unit * 2 }}
                                            disabled
                                        />
                                    )}
                                </div>
                            )}
                            <div style={{ marginTop: theme.spacing.unit * 3 }}>
                                <CustomWrapper theme={theme} title={'Availability'}>
                                    <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                                        {formik.values.availability.map((day, idx) => {
                                            const closed = _.get(day, 'hours') === 'Closed';
                                            const alwaysOpen = _.get(day, 'hours') === '12am–11:59pm';

                                            const startTime = moment.utc();
                                            const endTime = moment.utc();

                                            let timeSegments;

                                            if (!closed) {
                                                timeSegments = parseAvailability(_.get(day, 'hours'));
                                            } else {
                                                if (_.get(defaultDropLocationAvailability[idx], 'hours') === 'Closed') {
                                                    timeSegments = parseAvailability(
                                                        _.get(defaultAvailability[idx], 'hours')
                                                    );
                                                } else {
                                                    timeSegments = parseAvailability(
                                                        _.get(defaultDropLocationAvailability[idx], 'hours')
                                                    );
                                                }
                                            }

                                            startTime.hours(timeSegments.startHours);
                                            startTime.minutes(timeSegments.startMinutes);

                                            endTime.hours(timeSegments.endHours);
                                            endTime.minutes(timeSegments.endMinutes);

                                            return (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={!closed}
                                                            onChange={() => handleToggleDay(idx)}
                                                            disabled={formik.values.existsAtReturnSite}
                                                        />
                                                        <Typography
                                                            disabled={formik.values.existsAtReturnSite}
                                                            style={{
                                                                marginRight: theme.spacing.unit * 2
                                                            }}
                                                        >
                                                            {_.get(day, 'day')}:
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            maxWidth: '70%',
                                                            marginBottom: theme.spacing.unit,
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <div style={{ marginRight: theme.spacing.unit / 2 }}>
                                                            {getTimePicker(
                                                                theme,
                                                                'startTime',
                                                                'Start Time',
                                                                formik,
                                                                startTime,
                                                                time => handleTimeChange(idx, time, true),
                                                                closed || alwaysOpen || formik.values.existsAtReturnSite
                                                            )}
                                                        </div>
                                                        <div style={{ marginLeft: theme.spacing.unit / 2 }}>
                                                            {getTimePicker(
                                                                theme,
                                                                'endTime',
                                                                'End Time',
                                                                formik,
                                                                endTime,
                                                                time => handleTimeChange(idx, time, false),
                                                                closed || alwaysOpen || formik.values.existsAtReturnSite
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Checkbox
                                                                disabled={closed || formik.values.existsAtReturnSite}
                                                                checked={alwaysOpen}
                                                                onChange={() => handleToggleAlwaysOpen(idx)}
                                                                data-cy={`dropLocation-form-24hr-checkbox-${idx}`}
                                                            />
                                                            <Typography
                                                                style={{
                                                                    marginRight: theme.spacing.unit / 2,
                                                                    marginLeft: -theme.spacing.unit
                                                                }}
                                                            >
                                                                24hr
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </CustomWrapper>
                            </div>
                        </Collapse>
                        {/* {getSwitch(theme, 'overrideMarkerUrl', 'Override Pin Image', formik)}
                    <Collapse in={formik.values.overrideMarkerUrl}>
                        {_.isNil(markerFile) && _.isNil(formik.values.markerUrl) ? (
                            <FormControl fullWidth data-cy="upload-photo-input">
                                <input
                                    accept={'image/*'}
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={handleDrop}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="span"
                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </FormControl>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {!_.isNil(markerFile) ? (
                                    <img
                                        src={URL.createObjectURL(markerFile)}
                                        style={{ maxWidth: '100%' }}
                                        alt="Drop Location Marker"
                                    />
                                ) : !imageError && imageFileTypes.includes(formik.values.markerUrl.split('.').pop()) ? (
                                    <img
                                        src={formik.values.markerUrl}
                                        alt="charity-high-resolution-logo"
                                        onError={() => setImageError(true)}
                                        style={{
                                            maxWidth: '128px',
                                            maxHeight: '128px'
                                        }}
                                    />
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                            check_circle
                                        </Icon>
                                        <Typography
                                            style={{
                                                color: colors.green[500],
                                                margin: theme.spacing.unit * 2,
                                                textAlign: 'center'
                                            }}
                                            variant="subtitle2"
                                        >
                                            Image Uploaded
                                        </Typography>
                                    </div>
                                )}
                                <FormControl fullWidth data-cy="upload-photo-input">
                                    <input
                                        accept={'image/*'}
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple={false}
                                        type="file"
                                        onChange={handleDrop}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                        >
                                            Replace Image
                                        </Button>
                                    </label>
                                </FormControl>
                            </div>
                        )}
                    </Collapse> */}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <div>
                    {isEXPRegion() && (
                        <Typography variant="body2" color="error" style={{ marginRight: '10px', fontSize: '0.8rem' }}>
                            Manually updated data on this page will be overwritten on the next sync
                        </Typography>
                    )}

                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={formik.handleSubmit} data-cy="drop-location-form-submit">
                            {loc('submit', lang)}
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(DropLocationForm));

function setItemAtIndex(arr, idx, itemName, item) {
    return [
        ...arr.slice(0, idx),
        {
            ...arr[idx],
            [itemName]: item
        },
        ...arr.slice(idx + 1, arr.length)
    ];
}

function parseAvailability(availability) {
    const split = availability.split('–');

    const startTime = parseTime(split[0]);
    const endTime = parseTime(split[1]);

    return { startHours: startTime[0], startMinutes: startTime[1], endHours: endTime[0], endMinutes: endTime[1] };
}

function parseTime(time) {
    time = time.toLowerCase();

    const colonSplit = time.split(':');

    let hours = parseInt(colonSplit[0]),
        minutes = 0;

    if (colonSplit.length > 1) {
        minutes = parseInt(colonSplit[1].substring(0, 2));
    }

    if (time.includes('pm') && hours < 12) {
        hours += 12;
    }

    if (time.includes('am') && time.includes('12')) {
        hours -= 12;
    }

    return [hours, minutes];
}
function getColor(color) {
    try {
        if (_.isNil(color) || color === '') {
            return '';
        }
        return color === 'black' ? colors['grey'][900] : colors[color][600];
    } catch (e) {
        return '';
    }
}
