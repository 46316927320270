import React, { useState, useEffect, useContext } from 'react';
import { _time } from 'std';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
    formatAsCurrency,
    getCustomerFinancialStats,
    getCharityFinancialStats,
    getEnvironmentalStats,
    getQuickDropName
} from 'utils/misc';

import BaseWidget from './BaseWidget';

import {
    colors,
    Avatar,
    Icon,
    Grid,
    List,
    ListItem,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput,
    Chip,
    Menu,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';

import MDIcon from '@mdi/react';

import { withTheme } from '@material-ui/core/styles';

import { mdiBottleWine, mdiTshirtCrew, mdiDesktopClassic, mdiPiggyBank, mdiMoleculeCo2, mdiHandHeart } from '@mdi/js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import HttpContext from 'utils/contexts/HttpContext';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import useGetJSON from 'utils/hooks/useGetJSON';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

function StatsWidget(props) {
    const http = useContext(HttpContext);

    const {
        theme,
        elevated,
        customer,
        financialStats,
        environmentalStats,
        bottleDrives,
        bulks,
        redemptions,
        tips,
        childAccountBulks = {},
        childAccountTips = {},
        childAccountRedemptions = {},
        isCharity,
        charityEnabled,
        childAccountInfoDonationShow,
        showImpactReport = true,
        commodities,
        historyLoading
    } = props;
    const { lang } = useContext(LocalizationContext);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const defaultTimezone = _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [_.get(customer, 'location.timezone', defaultTimezone)],
        initStartVal: moment(_.get(customer, 'createdAt', new Date(2018, 0, 1)))
            .tz(defaultTimezone)
            .startOf('day'),
        initEndVal: _time.getEndOfDay(defaultTimezone),
        initDateWindow: 'allTime'
    });

    const { data: impactReportConfig } = useGetJSON(`/system/impactReportConfig`);

    const [accountsSelected, setAccountsSelected] = useState(isCharity ? [] : [_.get(customer, '_id')]);

    const [allLocations, setAllLocations] = useState([]); // Locations from bulks, bin fee locations
    const [locations, setLocations] = useState(allLocations); // Currently selected locations via filter

    const [binFeesByLocation, setBinFeesByLocation] = useState([]);
    const [groupedBulksByLocation, setGroupedBulksByLocation] = useState([]);

    const [selectedBottleDrives, setSelectedBottleDrives] = useState([]);

    const [filteredFinancialStats, setFilteredFinancialStats] = useState(financialStats);
    const [filteredEnvironmentalStats, setFilteredEnvironmentalStats] = useState(environmentalStats);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let recurringFees = _.get(customer, 'recurringFees', []);
        let feesByLocation = _.groupBy(recurringFees, f =>
            !_.isEmpty(_.get(f, 'address')) ? _.get(f, 'address') : undefined
        );
        let binFeesByLocation = _.mapValues(feesByLocation, fees => getActiveBinFees(fees));

        let allBulks = [...bulks];
        for (let account of _.get(customer, 'multipleAccountAccessList', [])) {
            const childBulks = childAccountBulks[_.get(account, '_id')] || [];
            allBulks.push(...childBulks);
        }

        let groupedBulksByLocation = _.groupBy(allBulks, b =>
            !_.isEmpty(_.get(b, 'pickup.location.description', ''))
                ? _.get(b, 'pickup.location.description')
                : undefined
        );

        setBinFeesByLocation(binFeesByLocation); // still required?
        setGroupedBulksByLocation(groupedBulksByLocation);

        setAllLocations(_.uniq([...Object.keys(binFeesByLocation), ...Object.keys(groupedBulksByLocation)]));
        setLocations(_.uniq([...Object.keys(binFeesByLocation), ...Object.keys(groupedBulksByLocation)]));
    }, [customer, bulks]);

    useEffect(() => {
        let selectedLocations = isCharity ? allLocations : locations;
        let allRedemptionsForAccounts = [];
        let allTipsForAccounts = [];
        let allBulksForAccounts = [];

        if (!isCharity) {
            if (accountsSelected.includes(_.get(customer, '_id'))) {
                allRedemptionsForAccounts.push(...redemptions);
                allTipsForAccounts.push(...tips);
                allBulksForAccounts.push(...bulks);
            }
            for (let account of _.get(customer, 'multipleAccountAccessList', [])) {
                if (accountsSelected.includes(account._id)) {
                    allRedemptionsForAccounts.push(...childAccountRedemptions[account._id]);
                    allTipsForAccounts.push(...childAccountTips[account._id]);
                    allBulksForAccounts.push(...childAccountBulks[account._id]);
                }
            }
        }

        const allBulksForAccountsGroupedByLocation = _.groupBy(allBulksForAccounts, b =>
            !_.isEmpty(_.get(b, 'pickup.location.description', ''))
                ? _.get(b, 'pickup.location.description')
                : undefined
        );

        const filteredRedemptions = _.filter(
            isCharity ? redemptions : allRedemptionsForAccounts,
            r =>
                moment(r.createdAt)
                    .startOf('day')
                    .isSameOrBefore(moment(endDate).startOf('day')) &&
                moment(r.createdAt)
                    .startOf('day')
                    .isSameOrAfter(moment(startDate).startOf('day'))
        );

        const { filteredFinancialStats, financialStatsGroupedByLocation } = getFilteredFinancialStats(
            isCharity ? groupedBulksByLocation : allBulksForAccountsGroupedByLocation,
            filteredRedemptions,
            isCharity ? tips : allTipsForAccounts,
            startDate,
            endDate,
            selectedLocations,
            isCharity,
            commodities
        );

        const newFilteredEnvironmentalStats = getFilteredEnvironmentalStats(
            financialStatsGroupedByLocation,
            selectedLocations,
            impactReportConfig
        );

        setFilteredFinancialStats(filteredFinancialStats);
        setFilteredEnvironmentalStats(newFilteredEnvironmentalStats);
    }, [locations.length, startDate, endDate, bulks, accountsSelected, impactReportConfig]);

    useEffect(() => {
        if (!_.isEmpty(selectedBottleDrives)) {
            const bottleDriveStats = {
                totalQuantity: 0,
                totalDonated: 0,
                totalBags: 0,
                totalClothing: 0,
                totalElectronics: 0
            };

            selectedBottleDrives.forEach(bottleDriveName => {
                const bottleDrive = _.find(bottleDrives, { name: bottleDriveName });
                if (!bottleDrive) return;

                bottleDriveStats.totalDonated += _.get(bottleDrive, 'balance', 0);
                bottleDriveStats.totalQuantity += _.get(bottleDrive, 'totalQuantity', 0);
            });

            setFilteredFinancialStats(bottleDriveStats);
            setFilteredEnvironmentalStats(getEnvironmentalStats(bottleDriveStats));
        }
    }, [selectedBottleDrives, bulks]);

    const [unfilteredFinancialStats, setUnfilteredFinancialStats] = useState([]);
    const [customerFinancialStats, setCustomerFinancialStats] = useState({});

    // For customers only. Charities will use the filteredFinancialStats
    const loadUnfilteredFinancialStats = async () => {
        if (!customer) return;

        // TODO: Remove unnecessary work for these calculations in other functions when possible if necessary
        // Same work is being done twice... ALso for environmental calculations etc...
        const res = await http.getJSON(
            `/users/financialStats/${customer._id}?startDate=${startDate.toDate()}&endDate=${endDate.toDate()}`
        );
        if (res.ok) {
            setUnfilteredFinancialStats(res.data.customerFinancialStats);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await loadUnfilteredFinancialStats();
            setIsLoading(false);
        })();
    }, [startDate, endDate]);

    useEffect(() => {
        if (_.isEmpty(locations)) {
            setCustomerFinancialStats({});
            return;
        }

        let filteredFinancialStats;
        if (locations.length == allLocations.length) {
            filteredFinancialStats = unfilteredFinancialStats;
        } else {
            filteredFinancialStats = _.filter(unfilteredFinancialStats, stat => {
                if (_.some(stat._id, location => locations.includes(location))) {
                    return true;
                }

                return false;
            });
        }

        const totals = {
            balance: 0,
            totalQuantity: 0,
            totalDonated: 0,
            totalClothing: 0,
            totalElectronics: 0,
            personalRevenue: 0
        };

        for (let total of filteredFinancialStats) {
            totals.balance += total.balance;
            totals.totalQuantity += total.totalQuantity;
            totals.totalDonated += total.totalDonated;
            totals.totalClothing += total.totalClothing;
            totals.totalElectronics += total.totalElectronics;
            totals.personalRevenue += total.personalRevenue;
        }

        setCustomerFinancialStats(totals);
    }, [unfilteredFinancialStats, locations.length]);

    return (
        <BaseWidget elevated={elevated}>
            <WidgetFilters
                lang={lang}
                theme={theme}
                timezone={timezone}
                startDate={startDate}
                endDate={endDate}
                dateWindow={dateWindow}
                handleGoBackwards={handleGoBackwards}
                handleGoForwards={handleGoForwards}
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                handleWindowSelect={handleWindowSelect}
                isCharity={isCharity}
                customer={customer}
                accountsSelected={accountsSelected}
                allLocations={allLocations}
                locations={locations}
                setLocations={setLocations}
                setAccountsSelected={setAccountsSelected}
                bottleDrives={bottleDrives}
                selectedBottleDrives={selectedBottleDrives}
                setSelectedBottleDrives={setSelectedBottleDrives}
            />

            {showImpactReport ? (
                <List style={{ paddingTop: 0 }}>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FinancialReportStatistics
                                isCharity={isCharity}
                                isSmallScreen={isSmallScreen}
                                isMediumScreen={isMediumScreen}
                                theme={theme}
                                filteredFinancialStats={filteredFinancialStats}
                                customerFinancialStats={customerFinancialStats}
                                lang={lang}
                                charityEnabled={charityEnabled}
                                childAccountInfoDonationShow={childAccountInfoDonationShow}
                                isLoading={historyLoading || isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <ImpactReportStatistics
                                theme={theme}
                                filteredEnvironmentalStats={filteredEnvironmentalStats}
                                impactReportConfig={impactReportConfig}
                                lang={lang}
                                isLoading={historyLoading || isLoading}
                            />
                        </Grid>
                    </Grid>
                </List>
            ) : (
                <List
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: isSmallScreen ? 'column' : 'row'
                    }}
                >
                    <FinancialReportStatistics
                        isCharity={isCharity}
                        isSmallScreen={isSmallScreen}
                        isMediumScreen={isMediumScreen}
                        theme={theme}
                        filteredFinancialStats={filteredFinancialStats}
                        customerFinancialStats={customerFinancialStats}
                        lang={lang}
                        charityEnabled={charityEnabled}
                        childAccountInfoDonationShow={childAccountInfoDonationShow}
                        isLoading={historyLoading || isLoading}
                    />
                </List>
            )}
        </BaseWidget>
    );
}

export default withTheme()(StatsWidget);

function WidgetFilters({
    lang,
    theme,
    timezone,
    startDate,
    endDate,
    dateWindow,
    handleGoBackwards,
    handleGoForwards,
    handleChangeStartDate,
    handleChangeEndDate,
    handleWindowSelect,
    isCharity,
    customer,
    accountsSelected,
    allLocations,
    locations,
    setLocations,
    setAccountsSelected,
    bottleDrives,
    selectedBottleDrives,
    setSelectedBottleDrives
}) {
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const handleSelectedBottleDrives = event => {
        const updatedSelectedBottleDrives = event.target.value;
        setSelectedBottleDrives(updatedSelectedBottleDrives);
    };

    const toggleLocationFilter = location => {
        if (locations.includes(location)) {
            setLocations(locations.filter(l => l !== location));
        } else {
            setLocations([...locations, location]);
        }
    };

    const allChipBackgroundColor = allLocations.length === locations.length ? '#000000' : colors.grey[300];

    return (
        <>
            <Menu
                open={filtersOpen}
                anchorEl={anchor}
                onClose={e => {
                    setFiltersOpen(false);
                    setAnchor(null);
                }}
            >
                <div>
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {loc('dateFilter', lang)}
                    </Typography>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexWrap: 'wrap',
                            padding: theme.spacing.unit / 2
                        }}
                    >
                        <DateRangePicker
                            fullWidth
                            timezone={timezone}
                            startDate={startDate}
                            endDate={endDate}
                            window={dateWindow}
                            handlePrevious={handleGoBackwards}
                            handleNext={handleGoForwards}
                            handleChangeStartDate={handleChangeStartDate}
                            handleChangeEndDate={handleChangeEndDate}
                        />
                        <Select
                            value={dateWindow}
                            onChange={handleWindowSelect}
                            style={{
                                margin: theme.spacing.unit
                            }}
                            data-cy="date-range-select"
                        >
                            <MenuItem value="today" data-cy="date-range-select-today">
                                {loc('daily', lang)}
                            </MenuItem>
                            <MenuItem value="thisIsoWeek" data-cy="date-range-select-thisIsoWeek">
                                {loc('weekly', lang)}
                            </MenuItem>
                            <MenuItem value="thisMonth" data-cy="date-range-select-thisMonth">
                                {loc('monthly', lang)}
                            </MenuItem>
                            <MenuItem value="thisQuarter" data-cy="date-range-select-thisQuarter">
                                {loc('quarterly', lang)}
                            </MenuItem>
                            <MenuItem value="allTime" data-cy="date-range-select-allTime">
                                {loc('allTime', lang)}
                            </MenuItem>
                        </Select>
                    </div>
                    {!isCharity &&
                        !_.isNil(_.get(customer, 'multipleAccountAccessList')) &&
                        !_.isEmpty(_.get(customer, 'multipleAccountAccessList')) && (
                            <div
                                style={{
                                    display: 'flex',
                                    padding: theme.spacing.unit,
                                    paddingTop: 0
                                }}
                            >
                                <div style={{ margin: theme.spacing.unit, flex: 1 }}>
                                    <Select
                                        multiple
                                        fullWidth
                                        value={accountsSelected}
                                        onChange={e => setAccountsSelected(e.target.value)}
                                        renderValue={s => `${s.length} Accounts`}
                                        data-cy="stats-filters-account-select"
                                    >
                                        <MenuItem
                                            value={_.get(customer, '_id', '')}
                                            data-cy="stats-filters-account-select-main"
                                        >
                                            My account
                                        </MenuItem>
                                        {_.get(customer, 'multipleAccountAccessList', []).map(account => {
                                            return (
                                                <MenuItem
                                                    value={account._id}
                                                    data-cy={`stats-filters-account-select-${account.name.first}-${
                                                        account.name.last
                                                    }`}
                                                >{`${_.get(account, 'name.first', '')} ${_.get(
                                                    account,
                                                    'name.last',
                                                    ''
                                                )}`}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        )}

                    {allLocations.length > 1 && !isCharity && (
                        <div style={{ padding: theme.spacing.unit }}>
                            <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {loc('locationFilter', lang)}
                            </Typography>

                            <div
                                style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    '-webkit-overflow-scrolling': 'touch'
                                }}
                                className={allLocations.length > 4 ? 'no-parent-scroll' : ''}
                            >
                                <Chip
                                    className={allLocations.length > 4 ? 'no-parent-scroll' : ''}
                                    style={{
                                        backgroundColor: allChipBackgroundColor,
                                        color: theme.palette.getContrastText(allChipBackgroundColor),
                                        margin: theme.spacing.unit / 2
                                    }}
                                    onClick={() =>
                                        allLocations.length === locations.length
                                            ? setLocations([])
                                            : setLocations(allLocations)
                                    }
                                    label={loc('dashboardStats7', lang)}
                                />
                                {allLocations.map((location, idx) => {
                                    const chipBgColor =
                                        locations.indexOf(location) !== -1 ? colors.green[300] : colors.grey[200];
                                    return (
                                        <Chip
                                            key={idx}
                                            className={allLocations.length > 4 ? 'no-parent-scroll' : ''}
                                            style={{
                                                backgroundColor: chipBgColor,
                                                color: theme.palette.getContrastText(chipBgColor),
                                                margin: theme.spacing.unit / 2
                                            }}
                                            onClick={() => toggleLocationFilter(location)}
                                            label={
                                                <span
                                                    className={allLocations.length > 4 ? 'no-parent-scroll' : ''}
                                                    style={{
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                        whiteSpace: 'normal'
                                                    }}
                                                >
                                                    {location !== 'undefined' ? location : getQuickDropName(lang)}
                                                </span>
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </Menu>
            <div style={{ textAlign: 'right' }}>
                <Button
                    onClick={e => {
                        setFiltersOpen(true);
                        setAnchor(e.currentTarget);
                    }}
                >
                    <Typography
                        data-cy="stats-filters-button"
                        style={{ display: 'inline', marginRight: theme.spacing.unit }}
                        variant="button"
                    >
                        Filters
                    </Typography>
                    <Icon>filter_list</Icon>
                </Button>
            </div>
            {!_.isEmpty(bottleDrives) && (
                <FormControl fullWidth>
                    <InputLabel variant="outlined" id="demo-mutiple-checkbox-label">
                        Bottle Drives
                    </InputLabel>
                    <Select
                        id="demo-mutiple-checkbox"
                        multiple
                        value={selectedBottleDrives}
                        onChange={handleSelectedBottleDrives}
                        input={<OutlinedInput labelWidth={90} name="tmp" id="tmp" />}
                        renderValue={selected => (selected.length > 1 ? 'Multiple Selected' : selected[0])}
                    >
                        {bottleDrives.map(bottleDrive => (
                            <MenuItem key={bottleDrive.name} value={bottleDrive.name}>
                                <Checkbox checked={selectedBottleDrives.indexOf(bottleDrive.name) > -1} />
                                <ListItemText primary={bottleDrive.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}

function FinancialReportStatistics({
    isCharity,
    isSmallScreen,
    isMediumScreen,
    theme,
    filteredFinancialStats,
    customerFinancialStats,
    lang,
    charityEnabled,
    childAccountInfoDonationShow,
    isLoading
}) {
    return (
        <>
            {isCharity && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.green['A700'] }}>
                        <MDIcon path={mdiPiggyBank} size={1} color={theme.palette.background.default} />
                    </Avatar>
                    <ListItemText
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                formatAsCurrency(_.get(filteredFinancialStats, 'totalRevenue', 0), lang)
                            )
                        }
                        secondary="Total revenue"
                        style={{ paddingRight: 0 }}
                    />
                </ListItem>
            )}

            {!isCharity && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.green['A700'] }}>
                        <MDIcon path={mdiPiggyBank} size={1} color={theme.palette.background.default} />
                    </Avatar>
                    <ListItemText
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                formatAsCurrency(-1 * _.get(customerFinancialStats, 'personalRevenue', 0), lang)
                            )
                        }
                        secondary={loc('impactReport12', lang)}
                        style={{ paddingRight: 0 }}
                        data-cy="stats-total-returns"
                    />
                </ListItem>
            )}

            {!isCharity && (charityEnabled || childAccountInfoDonationShow) && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.yellow[800] }}>
                        <MDIcon path={mdiHandHeart} size={1} color={theme.palette.background.default} />
                    </Avatar>
                    <ListItemText
                        id="stats-widget-total-donated-value"
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                formatAsCurrency(-1 * _.get(customerFinancialStats, 'totalDonated', 0), lang)
                            )
                        }
                        secondary={loc('impactReport13', lang)}
                        style={{ paddingRight: 0 }}
                    />
                </ListItem>
            )}

            {isCharity && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 4,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.amber[500] }}>
                        <Icon>sentiment_very_satisfied</Icon>
                    </Avatar>
                    <ListItemText
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                _.get(filteredFinancialStats, 'totalDonations', 0).toLocaleString()
                            )
                        }
                        secondary={loc('dashboardStats2', lang)}
                        style={{ paddingRight: 0 }}
                    />
                </ListItem>
            )}

            <ListItem
                style={{
                    maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0
                }}
            >
                <Avatar style={{ backgroundColor: colors.deepOrange[500] }}>
                    <MDIcon path={mdiBottleWine} size={1} color={theme.palette.background.default} />
                </Avatar>
                <ListItemText
                    primary={
                        isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            _.get(
                                isCharity ? filteredFinancialStats : customerFinancialStats,
                                'totalQuantity',
                                0
                            ).toLocaleString()
                        )
                    }
                    secondary={loc('impactReport14', lang)}
                    style={{ paddingRight: 0 }}
                />
            </ListItem>

            {JSON.parse(process.env.REACT_APP_CLOTHING_SHOWN) && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.blueGrey[500] }}>
                        <MDIcon path={mdiTshirtCrew} size={1} color={theme.palette.background.default} />
                    </Avatar>
                    <ListItemText
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                _.get(
                                    isCharity ? filteredFinancialStats : customerFinancialStats,
                                    'totalClothing',
                                    0
                                ).toLocaleString()
                            )
                        }
                        secondary={loc('impactReport15', lang)}
                        style={{ paddingRight: 0 }}
                    />
                </ListItem>
            )}

            {JSON.parse(process.env.REACT_APP_ELECTRONICS_SHOWN) && (
                <ListItem
                    style={{
                        maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '32%',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0
                    }}
                >
                    <Avatar style={{ backgroundColor: colors.green['A700'] }}>
                        <MDIcon path={mdiDesktopClassic} size={1} color={theme.palette.background.default} />
                    </Avatar>
                    <ListItemText
                        primary={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                _.get(
                                    isCharity ? filteredFinancialStats : customerFinancialStats,
                                    'totalElectronics',
                                    0
                                ).toLocaleString()
                            )
                        }
                        secondary={loc('impactReport16', lang)}
                        style={{ paddingRight: 0 }}
                    />
                </ListItem>
            )}
        </>
    );
}

function ImpactReportStatistics({ theme, filteredEnvironmentalStats, impactReportConfig, lang, isLoading }) {
    return (
        <>
            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <MDIcon path={mdiMoleculeCo2} size={1} color={theme.palette.background.default} />
                </Avatar>
                <ListItemText
                    primary={
                        isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            `${_.get(filteredEnvironmentalStats, 'emissions', 0).toLocaleString(undefined, {
                                maximumSignificantDigits: 3
                            })} ${_.get(impactReportConfig, 'carbonUnit', 'Kgs')}`
                        )
                    }
                    secondary={loc('impactReport4', lang)}
                    style={{ paddingRight: 0 }}
                />
            </ListItem>
            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>landscape</Icon>
                </Avatar>
                <ListItemText
                    primary={
                        isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            `${_.get(filteredEnvironmentalStats, 'landfill', 0).toLocaleString(undefined, {
                                maximumSignificantDigits: 3
                            })} ${_.get(impactReportConfig, 'landfillUnit', 'Kgs')}`
                        )
                    }
                    secondary={loc('impactReport6', lang)}
                    style={{ paddingRight: 0 }}
                />
            </ListItem>
            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>flash_on</Icon>
                </Avatar>
                <ListItemText
                    primary={
                        isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            `${_.get(filteredEnvironmentalStats, 'energy', 0).toLocaleString(undefined, {
                                maximumSignificantDigits: 3
                            })} ${_.get(impactReportConfig, 'energyUnit', 'Megajoules')}`
                        )
                    }
                    secondary={loc('impactReport5', lang)}
                    style={{ paddingRight: 0 }}
                />
            </ListItem>
            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                <Avatar style={{ backgroundColor: theme.palette.primary[400] }}>
                    <Icon>water_drop</Icon>
                </Avatar>
                <ListItemText
                    primary={
                        isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            `${_.get(filteredEnvironmentalStats, 'water', 0).toLocaleString(undefined, {
                                maximumSignificantDigits: 3
                            })} ${_.get(impactReportConfig, 'waterUnit', 'Liters')}`
                        )
                    }
                    secondary={loc('impactReport17', lang)}
                    style={{ paddingRight: 0 }}
                />
            </ListItem>
        </>
    );
}

function getFilteredFinancialStats(
    groupedBulksByLocation,
    redemptions,
    tips,
    startDate,
    endDate,
    locations,
    isCharity,
    commodities
) {
    const lastRedemption = _.last(redemptions);
    const redemptionPending = (!_.isNil(lastRedemption) && !lastRedemption.complete) || false;
    let financialStatsGroupedByLocation = {};
    if (!isCharity) {
        financialStatsGroupedByLocation = _.mapValues(groupedBulksByLocation, bks =>
            getCustomerFinancialStats(bks, commodities, redemptions, redemptionPending, tips, startDate, endDate)
        );
    } else {
        financialStatsGroupedByLocation = _.mapValues(groupedBulksByLocation, bks =>
            getCharityFinancialStats(bks, commodities, startDate, endDate)
        );
    }

    const filteredFinancialStats = _.reduce(
        financialStatsGroupedByLocation,
        (result, stats, location) => {
            if (locations.includes(location)) {
                Object.keys(stats).forEach(field => {
                    if (field === 'amountPending' || field === 'amountDonated') {
                        //these fields do not change via location
                        result[field] = !_.isNil(result[field]) ? result[field] : stats[field];
                    } else {
                        result[field] = !_.isNil(result[field]) ? result[field] + stats[field] : stats[field];
                    }
                });
            }
            return result;
        },
        {}
    );

    return { filteredFinancialStats, financialStatsGroupedByLocation };
}

function getFilteredEnvironmentalStats(financialStatsGroupedByLocation, locations, impactReportConfig) {
    const environmentalStatsGroupedByLocation = _.mapValues(financialStatsGroupedByLocation, stats =>
        getEnvironmentalStats(stats, impactReportConfig)
    );

    const filteredEnvironmentalStats = _.reduce(
        environmentalStatsGroupedByLocation,
        (result, stats, location) => {
            if (locations.includes(location)) {
                Object.keys(stats).forEach(field => {
                    result[field] = !_.isNil(result[field]) ? result[field] + stats[field] : stats[field];
                });
            }
            return result;
        },
        {}
    );

    return filteredEnvironmentalStats;
}

const getActiveBinFees = recurringFees => {
    let activeBinFees = _.filter(recurringFees, fee => {
        let { binSwapRequired, active, frequency, applyWhenBulkIsCompleted, lastDatePaid } = fee;
        const isExpired = frequency === 0 && !_.isNil(lastDatePaid);
        const isLegacyBinFee = _.get(fee, 'description', '').includes('Bin') && _.isNil(fee.binSwapRequired);

        return !isExpired && (binSwapRequired || isLegacyBinFee) && active && applyWhenBulkIsCompleted;
    });

    return activeBinFees;
};
