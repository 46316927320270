import React, { useContext } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Typography, Icon, IconButton, Link, Chip, Grid, TextField } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import CustomWrapper from '../../containers/Operators/BulkCounter/CustomWrapper';
import CustomGrid from './Components/CustomGrid';

import {
    DonationButtonDisplay,
    NewsLetterDisplay,
    TwitterDisplay,
    InstagramDisplay,
    FacebookDisplay,
    LinkedInDisplay,
    SendContactEmailDisplay,
    BlogDisplay,
    PromoCodeDisplay
} from './WhatToDoDisplays';

import charityTaskHelper from '../../helpers/charityTaskHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

function GrowthPlanTaskTemplate(props) {
    const { lang } = useContext(LocalizationContext);

    const {
        charity,
        taskIndex,
        onSnackbar,
        charityTask,
        theme,
        onSubmit,
        charityPromo,
        submitButton,

        //for social media share buttons
        subject,
        message,
        shareUrl,
        hashtags,
        onShare,
        onNativeShare,

        //for additional input
        userInput,
        orderCreated
    } = props;

    const {
        notCompletable,
        displayReferralLink,
        displayBagExamples,
        displayDoorHangerExamples,
        displayExamples,
        socialMediaHandle
    } = charityTask.task;

    const { title, whyItWorksText, exampleImgs, buttonImageName, userInputFields } = charityTask.task[lang];

    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
    let examples = exampleImgs;
    if (_.isNil(examples)) examples = [];

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        onSnackbar(loc('growthGeneral20', lang));
    };

    const url = !_.isEmpty(charity.url) ? charity.url : charity._id;
    const referralLink = `${process.env.REACT_APP_ORIGIN_URL}/${url}`;
    const urlToLink = url => (
        <Link href={url} style={{ color: theme.palette.linkColor, overflowWrap: 'break-word', wordWrap: 'break-word' }}>
            {url}
        </Link>
    );
    const referralLinkComponent = charity.approved ? (
        <Link
            href={referralLink}
            style={{ color: theme.palette.linkColor, overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={e => {
                e.preventDefault();
                handleCopyToClipboard(referralLink)();
            }}
        >
            {referralLink}
        </Link>
    ) : (
        `(${loc('growthGeneral7', lang)}.)`
    );

    const referralLinkDisplay = (
        <div style={{ marginTop: theme.spacing.unit * 2 }}>
            <CustomWrapper title={loc('growthGeneral7', lang)}>
                {charity.approved ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Link
                            href={referralLink}
                            variant="h6"
                            style={{
                                color: theme.palette.linkColor,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {referralLink}
                        </Link>

                        <IconButton onClick={handleCopyToClipboard(referralLink)}>
                            <Icon color="primary">file_copy</Icon>
                        </IconButton>
                    </div>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        {loc('growthGeneral19', lang)}
                    </Typography>
                )}
            </CustomWrapper>
        </div>
    );

    const examplesDisplay = (
        <div style={{ marginTop: theme.spacing.unit * 2 }}>
            <CustomWrapper title="Examples">
                <div style={{ padding: theme.spacing.unit }}>
                    <CustomGrid
                        contentItems={examples.map(img => {
                            return { image: img };
                        })}
                        onSnackbar={onSnackbar}
                    />
                </div>
            </CustomWrapper>
        </div>
    );

    const whatToDoDisplays = {
        donationButtonDisplay: (
            <DonationButtonDisplay
                lang={lang}
                charity={charity}
                referralLink={referralLink}
                buttonImageName={buttonImageName}
                referralLinkComponent={referralLinkComponent}
            />
        ),
        newsLetterDisplay: <NewsLetterDisplay referralLinkComponent={referralLinkComponent} />,
        twitterDisplay: (
            <TwitterDisplay
                charity={charity}
                referralLinkComponent={referralLinkComponent}
                socialMediaHandle={socialMediaHandle}
                subject={subject}
                message={message}
                shareUrl={shareUrl}
                url={url}
                urlToLink={urlToLink}
                hashtags={hashtags}
                onShare={onShare}
                onNativeShare={onNativeShare}
                lang={lang}
            />
        ),
        instagramDisplay: (
            <InstagramDisplay
                charity={charity}
                referralLinkComponent={referralLinkComponent}
                urlToLink={urlToLink} //TODO:
                examples={examples}
                socialMediaHandle={socialMediaHandle}
                subject={subject}
                message={message}
                shareUrl={shareUrl}
                hashtags={hashtags}
                onShare={onShare}
                onNativeShare={onNativeShare}
                lang={lang}
            />
        ),
        facebookDisplay: (
            <FacebookDisplay
                charity={charity}
                referralLinkComponent={referralLinkComponent}
                subject={subject}
                message={message}
                url={url}
                urlToLink={urlToLink}
                shareUrl={shareUrl}
                hashtags={hashtags}
                socialMediaHandle={socialMediaHandle}
                examples={examples}
                onNativeShare={onNativeShare}
                onShare={onShare}
                lang={lang}
            />
        ),
        linkedInDisplay: (
            <LinkedInDisplay
                charity={charity}
                subject={subject}
                message={message}
                url={url}
                referralLinkComponent={referralLinkComponent}
                urlToLink={urlToLink}
                shareUrl={shareUrl}
                hashtags={hashtags}
                examples={examples}
                socialMediaHandle={socialMediaHandle}
                onNativeShare={onNativeShare}
                onShare={onShare}
                lang={lang}
            />
        ),
        customBagsDisplay: (
            /*<SendContactEmailDisplay
                baseMessage={`${charity.name} is interested in custom bags.`}
                reason="Get custom bags"
            />*/
            <Typography variant="body1">
                {loc('growOrder3', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
            </Typography>
        ),
        customDoorHangersDisplay: (
            /*<SendContactEmailDisplay
                baseMessage={`${charity.name} is interested in custom door hangers.`}
                reason="Get custom doorhangers"
            />*/

            <Typography variant="body1">
                {loc('growOrder4', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
            </Typography>
        ),
        promoCodeDisplay: (
            <PromoCodeDisplay
                onSubmitTask={onSubmit}
                promo={charityPromo}
                charity={charity}
                submitted={
                    _.get(charityTask, 'lastSubmission.dateSubmitted') &&
                    !_.get(charityTask, 'lastSubmission.reviewed', false)
                }
            />
        ),
        eventAttendanceDisplay: <Typography variant="body1">{loc('growthEvent2', lang)}</Typography>,
        /*emailOutDirectlyDisplay: (
            <>
                <Typography variant="body1" style={{ display: 'inline', marginRight: theme.spacing.unit }}>
                    This task is automatically completed when you use the tool, get an email list ready and press here
                    to get started:
                </Typography>
                <Button variant="outlined" color="primary" onClick={onEmailOutDirectly(true)}>
                    Email Out Directly
                    <Icon>group_add</Icon>
                </Button>
                <br />
            </>
        ),*/
        newsOutletsDisplay: (
            <Typography variant="body1">
                {isEXPRegion() ? loc('growOrder5EXP', lang) : loc('growOrder5', lang)}
            </Typography>
        ),
        blogDisplay: <BlogDisplay charity={charity} referralLinkComponent={referralLinkComponent} lang={lang} />,
        posterDisplay: (
            <Typography variant="body1">
                {loc('growOrder1', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
            </Typography>
        ),
        dropBinDisplay: (
            /*<SendContactEmailDisplay
                baseMessage={`${charity.name} is interested in ordering drop bins.`}
                reason="Get a drop bin"
            />*/
            <Typography variant="body1">
                {loc('growOrder2', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
            </Typography>
        )
    };

    const whatToDoDisplay = whatToDoDisplays[charityTask.task.whatToDoDisplay];

    let submissionStatus = null;

    if (!_.get(charityTask.lastSubmission, 'reviewed')) {
        submissionStatus = (
            <div style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                <Typography variant="h6" data-cy="growth-plan-task-template-submission-status">
                    <Icon
                        style={{
                            color: colors.orange[500],
                            marginRight: theme.spacing.unit
                        }}
                    >
                        rate_review
                    </Icon>
                    <span style={{ color: colors.orange[500], fontWeight: 'bold' }}>
                        {loc('growthGeneral10', lang)}
                    </span>
                </Typography>
                <Typography variant="body2" data-cy="growth-plan-task-template-submission-status">
                    <span style={{ color: colors.orange[500] }}>{`${loc('growthGeneral6', lang)} ${locDate(
                        _.get(charityTask.lastSubmission, 'dateSubmitted'),
                        'MMM DD, YYYY',
                        lang
                    )}`}</span>
                </Typography>
            </div>
        );
    } else if (!_.get(charityTask.lastSubmission, 'rejected')) {
        submissionStatus = (
            <div style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                <Typography variant="h6" data-cy="growth-plan-task-template-submission-status">
                    <Icon
                        style={{
                            color: colors.green[500],
                            marginRight: theme.spacing.unit
                        }}
                    >
                        check_circle
                    </Icon>
                    <span style={{ color: colors.green[500], fontWeight: 'bold' }}>{loc('growthGeneral2', lang)}</span>
                </Typography>
                <Typography variant="body2" data-cy="growth-plan-task-template-submission-status">
                    <span style={{ color: colors.green[500] }}>{`${loc('growthGeneral6', lang)} ${locDate(
                        _.get(charityTask.lastSubmission, 'dateSubmitted'),
                        'MMM DD, YYYY',
                        lang
                    )}`}</span>
                </Typography>
            </div>
        );
    } else {
        submissionStatus = (
            <div style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                <Typography variant="h6" data-cy="growth-plan-task-template-submission-status">
                    <Icon
                        style={{
                            color: colors.red[500],
                            marginRight: theme.spacing.unit
                        }}
                    >
                        error
                    </Icon>
                    <span style={{ color: colors.red[500], fontWeight: 'bold' }}>{loc('growthGeneral3', lang)}</span>
                </Typography>
                <Typography variant="body2" data-cy="growth-plan-task-template-submission-status">
                    <span style={{ color: colors.red[500] }}>{`${loc('growthGeneral6', lang)} ${locDate(
                        _.get(charityTask.lastSubmission, 'dateSubmitted'),
                        'MMM DD, YYYY',
                        lang
                    )}`}</span>
                </Typography>
            </div>
        );
    }

    const lastSubmissionDisplay = (
        <CustomWrapper title={loc('submissionStatus', lang)} innerDivStyle={{ padding: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {submissionStatus}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        overflow: 'auto',
                        flexWrap: 'wrap'
                    }}
                >
                    {(charityTaskHelper.isComplete(charityTask) || charityTaskHelper.isExpired(charityTask)) && (
                        <Chip
                            color={charityTaskHelper.isComplete(charityTask) ? 'primary' : 'secondary'}
                            label={`${
                                charityTaskHelper.isComplete(charityTask)
                                    ? loc('growthGeneral5', lang)
                                    : loc('growthGeneral12', lang)
                            } ${locDate(charityTask.dateExpires, 'MMM DD, YYYY', lang)}`}
                            style={{ margin: theme.spacing.unit / 2 }}
                        />
                    )}
                </div>
            </div>
            {_.get(charityTask.lastSubmission, 'rejected') && (
                <>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        {loc('history12', lang)}:
                    </Typography>
                    <div
                        style={{ marginLeft: theme.spacing.unit }}
                        data-cy="growth-plan-task-template-rejection-reason"
                    >
                        <Typography variant="body1" color="textSecondary">
                            {_.get(charityTask.lastSubmission, 'rejectionReason')}
                        </Typography>
                    </div>
                </>
            )}
        </CustomWrapper>
    );

    return (
        <>
            <Typography variant="h4" style={{ margin: theme.spacing.unit }} align="center">
                <>
                    {title} {!notCompletable}
                </>
            </Typography>

            {!_.isNil(submitButton) && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: theme.spacing.unit * 2 }}>
                    {submitButton}
                </div>
            )}
            {charityTask.lastSubmission && lastSubmissionDisplay}

            <div style={{ textAlign: 'Center' }}>{displayReferralLink && <>{referralLinkDisplay}</>}</div>
            <div style={{ marginTop: theme.spacing.unit * 2 }}>
                <CustomWrapper title={loc('growthGeneral8', lang)}>
                    <Typography variant="body1" style={{ margin: theme.spacing.unit }}>
                        {whyItWorksText}
                    </Typography>
                </CustomWrapper>
            </div>

            {/*TODO: remove displayBagExamples and displayDoorHangerExamples flags and just use displayExamples flag */}
            <div style={{ textAlign: 'Center' }}>{displayBagExamples && examplesDisplay}</div>
            <div style={{ textAlign: 'Center' }}>{displayDoorHangerExamples && examplesDisplay}</div>
            <div style={{ textAlign: 'Center' }}>{displayExamples && examplesDisplay}</div>

            {whatToDoDisplay && (
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <CustomWrapper title={loc('growthGeneral9', lang)}>
                        <div style={{ padding: theme.spacing.unit }}>{whatToDoDisplay}</div>
                    </CustomWrapper>
                </div>
            )}
            {!_.isEmpty(userInput) && orderCreated && (
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <CustomWrapper title={loc('details', lang)}>
                        <div style={{ padding: theme.spacing.unit }}>
                            <Typography variant="body1">{userInput}</Typography>
                        </div>
                    </CustomWrapper>
                </div>
            )}
        </>
    );
}

export default withTheme()(GrowthPlanTaskTemplate);
