import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user, _time } from 'std';

import { formatAsCurrency, getCustomerFirstNameAndLastInitial, deviceHelper } from 'utils/misc';

import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';
import {
    Grid as MUIGrid,
    Divider,
    InputAdornment,
    Hidden,
    CircularProgress,
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    DialogActions,
    OutlinedInput,
    MenuItem,
    FormControl,
    InputLabel,
    Chip
} from '@material-ui/core';

import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { Grid as TableGrid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import EnhancedTable from 'components/MaterialUIExtensions/EnhancedTable';
import InvoiceDialog from './InvoiceDialog';
//import OperatorRatingWidgets from 'components/OperatorRatingWidgets/OperatorRatingWidgets';
import CombinedRatingWidget from 'components/OperatorRatingWidgets/CombinedRatingWidget';
import ComplaintsWidget from 'components/OperatorRatingWidgets/ComplaintsWidget/ComplaintsWidget';
import CommodityPickupWidget from './CommodityPickupWidget';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';

import useGetJSON from 'utils/hooks/useGetJSON';
import OperatorContext from 'utils/contexts/OperatorContext';
import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { locDate } from 'localizations/localizationHandler';

import useWindowSize from 'utils/hooks/useWindowSize';
import DateListInput from 'components/InputComponents/DateListInput';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import DriverIncidentsWidget from 'components/OperatorRatingWidgets/DriverIncidentsWidget';
import ComplimentsWidget from 'components/OperatorRatingWidgets/ComplimentsWidget';
import DateIntervalCarousal from 'components/DateTimePickersTz/DateIntervalCarousal';

function Dashboard(props) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 1180;
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const { theme, google, history, reloadOperator, collector } = props;

    // Will be used to select weekly values for stats / compliments / complaints / infractions
    const {
        startDate: startDatePicker,
        endDate: endDatePicker,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeBothDates,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initDateWindow: 'thisIsoWeek'
    });

    const {
        loading: statsLoading,
        error: statsError,
        data: { weeklyAccountingStats }
    } = useGetJSON(`/system/collectorStats/${collector._id}`);

    const { loading: invoicesLoading, error: invoicesError, data: invoices } = useGetJSON(
        `/quickbooks/getPendingInvoices/${collector._id}`,
        'invoices',
        [],
        true
    );

    const { loading: collectorRatingLoading, error: collectorRatingError, data: collectorRating } = useGetJSON(
        `/collectors/${collector._id}/rating?startDate=${startDatePicker}&endDate=${endDatePicker}`
    );

    const { loading: commoditiesLoading, error: commoditiesError, data: commodities = [] } = useGetJSON(
        '/commodities/getAllEnabledCommodities',
        'commodities'
    );

    const [trackedCommodities, setTrackedCommodities] = useState([]);
    const [allCollectorsBySkuType, setAllCollectorsBySkuTyp] = useState([]);
    const [collectorIdsSelected, setCollectorIdsSelected] = useState([collector._id]);
    const [endDate, setEndDate] = useState(
        moment().tz(_.get(collector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE))
    );

    const { loading: openBulkCountsLoading, data: openBulkCounts = [], refresh: refreshOpenBulkCounts } = useGetJSON(
        `/bulks/openBulksBreakdown?collectors=${collectorIdsSelected}&endDate=${endDate.toDate().toISOString()}`,
        'openBulksBreakdown'
    );

    const { loading: lateDriverLoading, data: lateDriverTrips } = useGetJSON(
        `/trips/getLateDriverTripsByCollectorId/${collector._id}?startDate=${startDatePicker}&endDate=${endDatePicker}`
    );

    const {
        loading: latestCommodityPickupsLoading,
        data: latestCommodityPickups = [],
        refresh: refreshLatestCommodityPickups
    } = useGetJSON(`/collectors/${collector._id}/latestCommodityPickups`, 'latestCommodityPickups');

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/collectors/commodityPickupRequestAvaliable');
            if (res.ok) {
                let collectorsBySkuType = _.get(res, 'data.collectorsBySkuType', {});
                setAllCollectorsBySkuTyp(collectorsBySkuType);
            }
        })();
    }, []);

    /*useEffect(() => {
        refreshOpenBulkCounts();
    }, [collectorIdsSelected]);*/

    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceIdSelected, setInvoiceIdSelected] = useState(null);
    const invoiceSelected = useMemo(() => _.find(invoices, i => i.Id === invoiceIdSelected), [
        invoiceIdSelected,
        invoices
    ]);

    const [rowsFinances, setRowsFinances] = useState([]);
    const [invoiceRows, setInvoiceRows] = useState([]);

    // Invoicing dataset
    const invoicesHeaders = [
        { id: 'DocNumber', label: 'Invoice number' },
        { id: 'DueDate', label: 'Due date' },
        { id: 'TotalAmt', label: 'Total amount' },
        { id: 'Balance', label: 'Balance' },
        { id: 'actions', label: 'Actions' }
    ];

    const handleOpenInvoiceDialog = invoice_id => {
        setInvoiceIdSelected(invoice_id);
        setInvoiceDialogOpen(true);
    };

    useEffect(() => {
        setInvoiceDialogOpen(false);
        setInvoiceIdSelected(null);
        setRowsFinances([]);
        setInvoiceRows([]);
        setCollectorIdsSelected([collector._id]);
    }, [collector]);

    useEffect(() => {
        if (!weeklyAccountingStats) return;
        const newRowsFinances = weeklyAccountingStats.map(weekStats => {
            // const chipBackgroundColor =
            //     theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.grey[700];
            const balance = weekStats.totalDeposits + weekStats.totalAdjustments;

            return {
                current: weekStats.belongsToCurrentWeek,
                period: `${moment(weekStats.periodStart).format('YYYY MMM DD')} – ${moment(weekStats.periodEnd).format(
                    'YYYY MMM DD'
                )}`,
                totalPickups: weekStats.totalPickups.toLocaleString(),
                totalContainers: weekStats.totalContainers.toLocaleString(),
                totalDeposits: formatAsCurrency(weekStats.totalDeposits),
                totalAdjustments:
                    weekStats.totalAdjustments >= 0 ? (
                        formatAsCurrency(weekStats.totalAdjustments)
                    ) : (
                        <span style={{ color: colors.green['A700'] }}>
                            {formatAsCurrency(weekStats.totalAdjustments)}
                        </span>
                    ),
                balance:
                    balance >= 0 ? (
                        formatAsCurrency(balance)
                    ) : (
                        <span style={{ color: colors.green['A700'] }}>{formatAsCurrency(balance)}</span>
                    )
            };
        });
        setRowsFinances(newRowsFinances);
    }, [weeklyAccountingStats]);

    useEffect(() => {
        if (!invoices) return;
        const newInvoiceRows = invoices.map(invoice => ({
            ...invoice,
            id: invoice.Id,
            DueDate: moment(invoice.DueDate).format('DD MMM YYYY'),
            TotalAmt: formatAsCurrency(invoice.TotalAmt * 100),
            Balance: (
                <span style={{ color: invoice.Balance > 0 ? colors.red['A400'] : null }}>
                    {formatAsCurrency(invoice.Balance * 100)}
                </span>
            ),
            actions: (
                <IconButton onClick={() => handleOpenInvoiceDialog(invoice.Id)}>
                    <Icon>description</Icon>
                </IconButton>
            )
        }));
        setInvoiceRows(newInvoiceRows);
    }, [invoices]);

    const [allDrivers, setAllDrivers] = useState([]);
    const [driverSummaryDate, setDriverSummaryDate] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day')
    );
    const [driverSummary, setDriverSummary] = useState({});
    const [weeklyDriverSummary, setWeeklyDriverSummary] = useState({});
    const [monthlyDriverSummary, setMonthlyDriverSummary] = useState({});
    const [startOfWeek, setStartOfWeek] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('isoWeek')
    );
    const [endOfWeek, setEndOfWeek] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .endOf('isoWeek')
    );
    const [startOfMonth, setStartOfMonth] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('month')
    );
    const [endOfMonth, setEndOfMonth] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .endOf('month')
    );

    const [driversOnVacation, setDriversOnVacation] = useState([]);
    const [driverVacationDialogOpen, setDriverVacationDialogOpen] = useState(false);
    const [driverVacationDialogDriverId, setDriverVacationDialogDriverId] = useState(
        _.first(allDrivers.map(d => d._id))
    );
    const [driverVacationDialogDays, setDriverVacationDialogDays] = useState(false);

    const [driverSummaryLoading, setDriverSummaryLoading] = useState(false);
    const [weeklyDriverSummaryLoading, setWeeklyDriverSummaryLoading] = useState(false);
    const [monthlyDriverSummaryLoading, setMonthlyDriverSummaryLoading] = useState(false);
    const [driverSummaryTab, setDriverSummaryTab] = useState('daily');

    useEffect(() => {
        if (driverSummaryTab === 'daily') {
            setDriversOnVacation(
                _.uniqBy(
                    _.filter(
                        allDrivers,
                        d =>
                            !d.banned &&
                            !d.disabled &&
                            _.get(d, 'driverVacationDays', [])
                                .map(date => moment(date).toISOString())
                                .includes(moment(driverSummaryDate).toISOString())
                    ),
                    '_id'
                )
            );
        } else {
            setDriversOnVacation([]);
        }
    }, [allDrivers, driverSummaryDate, driverSummaryTab]);

    async function getDriverSummary(startDate, endDate) {
        setDriverSummaryLoading(true);
        let driverIds = Array.from(allDrivers, driver => driver._id);
        const res = await http.postJSON(`/trips/driverSummary`, {
            startDate: startDate,
            endDate: endDate,
            driverIds: driverIds,
            collectorId: _.get(collector, '_id', ''),
            timezone: process.env.REACT_APP_REGION_TIMEZONE
        });

        if (res.ok) {
            setDriverSummary(res.data);
        }
        setDriverSummaryLoading(false);
    }

    async function getCollectorCommodities() {
        const commodityRes = await http.getJSON(`/collectors/${collector._id}/getCommodities`);
        if (commodityRes.ok) {
            let collectorCommodities = _.get(commodityRes.data, 'commodities', []);
            let list = [];
            if (
                !_.isNil(collectorCommodities) &&
                !_.isEmpty(collectorCommodities) &&
                Array.isArray(collectorCommodities)
            ) {
                collectorCommodities.forEach(c => {
                    if (c.trackInventory) {
                        list.push(c);
                    }
                    _.get(c, 'subCommodities', []).forEach(sc => {
                        if (sc.trackInventory) {
                            list.push({ ...sc, isSubCommodity: true });
                        }
                    });
                });
            }
            setTrackedCommodities(list);
        }
    }

    async function getWeeklyDriverSummary(startDate, endDate) {
        setWeeklyDriverSummary({});
        setWeeklyDriverSummaryLoading(true);
        let actualEndDate = endDate;
        if (
            _time.getDateInteger(process.env.REACT_APP_REGION_TIMEZONE, endDate) >
            _time.getDateInteger(process.env.REACT_APP_REGION_TIMEZONE, new Date())
        ) {
            actualEndDate = moment()
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .endOf('day');
        }
        let driverIds = Array.from(allDrivers, driver => driver._id);
        const res = await http.postJSON(`/trips/driverSummary`, {
            startDate: startDate,
            endDate: actualEndDate,
            driverIds: driverIds,
            collectorId: _.get(collector, '_id', ''),
            timezone: process.env.REACT_APP_REGION_TIMEZONE
        });

        if (res.ok) {
            setWeeklyDriverSummary(res.data);
        }
        setWeeklyDriverSummaryLoading(false);
    }
    async function getMonthlyDriverSummary(startDate, endDate) {
        setMonthlyDriverSummary({});
        setMonthlyDriverSummaryLoading(true);
        let actualEndDate = endDate;
        if (
            _time.getDateInteger(process.env.REACT_APP_REGION_TIMEZONE, endDate) >
            _time.getDateInteger(process.env.REACT_APP_REGION_TIMEZONE, new Date())
        ) {
            actualEndDate = moment()
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .endOf('day');
        }
        let driverIds = Array.from(allDrivers, driver => driver._id);
        const res = await http.postJSON(`/trips/driverSummary`, {
            startDate: startDate,
            endDate: actualEndDate,
            driverIds: driverIds,
            collectorId: _.get(collector, '_id', ''),
            timezone: process.env.REACT_APP_REGION_TIMEZONE
        });

        if (res.ok) {
            setMonthlyDriverSummary(res.data);
        }
        setMonthlyDriverSummaryLoading(false);
    }

    async function submitVacationDays() {
        const res = await http.postJSON(`/users/${driverVacationDialogDriverId}/submitVacationDays`, {
            vacationDays: driverVacationDialogDays.map(date => moment(date))
        });
        if (res.ok) {
            let allDriversCopy = _.cloneDeep(allDrivers);
            allDriversCopy.forEach(d => {
                if (d._id.toString() === res.data._id.toString()) {
                    d.driverVacationDays = res.data.driverVacationDays;
                }
            });
            let driversWithUpcomingVacationDays = allDriversCopy.filter(d =>
                _.get(d, 'driverVacationDays', []).some(date => moment(date).isSameOrAfter(moment(), 'date'))
            );
            let driversWithoutUpcomingVacationDays = allDriversCopy.filter(
                d => !_.get(d, 'driverVacationDays', []).some(date => moment(date).isSameOrAfter(moment(), 'date'))
            );
            let driverList = _.orderBy(driversWithUpcomingVacationDays, ['name.first', 'name.last']).concat(
                _.orderBy(driversWithoutUpcomingVacationDays, ['name.first', 'name.last'])
            );
            setAllDrivers(driverList);
            setDriverVacationDialogOpen(false);
            setDriverVacationDialogDays(
                _.get(res.data, 'driverVacationDays', []).filter(date => moment(date).isSameOrAfter(moment(), 'date'))
            );
        }
    }

    useEffect(() => {
        let currentDriver = _.find(allDrivers, d => d._id.toString() === driverVacationDialogDriverId.toString());
        setDriverVacationDialogDays(
            _.get(currentDriver, 'driverVacationDays', []).filter(date => moment(date).isSameOrAfter(moment(), 'date'))
        );
    }, [driverVacationDialogDriverId]);

    useEffect(() => {
        (async function() {
            const driverRes = await http.getJSON(
                _user.isSystemAdmin(operator) ||
                    _user.isInternalRole(operator) ||
                    (_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1)
                    ? '/users/getAllDrivers'
                    : `/users/getAllDriversIncludingBanned/${collector._id}`
            );
            if (driverRes.ok) {
                let driversWithUpcomingVacationDays = driverRes.data.drivers.filter(d =>
                    _.get(d, 'driverVacationDays', []).some(date => moment(date).isSameOrAfter(moment(), 'date'))
                );
                let driversWithoutUpcomingVacationDays = driverRes.data.drivers.filter(
                    d => !_.get(d, 'driverVacationDays', []).some(date => moment(date).isSameOrAfter(moment(), 'date'))
                );
                let driverList = _.orderBy(driversWithUpcomingVacationDays, ['name.first', 'name.last']).concat(
                    _.orderBy(driversWithoutUpcomingVacationDays, ['name.first', 'name.last'])
                );
                setAllDrivers(driverList);
                setDriverVacationDialogDriverId(_.first(driverList.map(d => d._id)));
            }
        })();
        getCollectorCommodities();
    }, []);

    useEffect(() => {
        getDriverSummary(driverSummaryDate, driverSummaryDate);
    }, [allDrivers, driverSummaryDate]);

    useEffect(() => {
        getWeeklyDriverSummary(startOfWeek, endOfWeek);
    }, [allDrivers, startOfWeek, endOfWeek]);

    useEffect(() => {
        getMonthlyDriverSummary(startOfMonth, endOfMonth);
    }, [allDrivers, startOfMonth, endOfMonth]);

    const [financesHeight, setFinancesHeight] = useState();
    const [weeklyCombinedStatisticsHeight, setWeeklyCombinedStatisticsHeight] = useState();
    const [invoicesHeight, setInvoicesHeight] = useState();
    const [biggerFinancesInvoicesHeight, setBiggerFinancesInvoicesHeight] = useState();
    const [heightDiff, setHeightDiff] = useState(0);
    const financesRef = useRef(null);
    const weeklyCombinedStatisticsRef = useRef(null);
    const invoicesRef = useRef(null);

    const handleFinancesResized = () => {
        if (!_.isNil(_.get(financesRef, 'current.offsetHeight', null))) {
            if (financesRef.current.offsetHeight !== financesHeight) {
                setFinancesHeight(financesRef.current.offsetHeight);
            }
        }
    };
    const handleWeeklyCombinedStatisticsResized = () => {
        if (!_.isNil(_.get(weeklyCombinedStatisticsRef, 'current.offsetHeight', null))) {
            if (weeklyCombinedStatisticsRef.current.offsetHeight !== weeklyCombinedStatisticsHeight) {
                setWeeklyCombinedStatisticsHeight(weeklyCombinedStatisticsRef.current.offsetHeight);
            }
        }
    };
    const handleInvoicesResized = () => {
        if (!_.isNil(_.get(invoicesRef, 'current.offsetHeight', null))) {
            if (invoicesRef.current.offsetHeight !== invoicesHeight) {
                setInvoicesHeight(invoicesRef.current.offsetHeight);
            }
        }
    };
    
    const financeObserver = mobileMode ? null : new ResizeObserver(handleFinancesResized);
    const weeklyCombinedStatisticsObserver = mobileMode
        ? null
        : new ResizeObserver(handleWeeklyCombinedStatisticsResized);
    const invoicesObserver = mobileMode ? null : new ResizeObserver(handleInvoicesResized);

    useEffect(() => {
        if (mobileMode) return;
        if (financesRef.current !== null) financeObserver.observe(financesRef.current);
        if (weeklyCombinedStatisticsRef.current !== null)
            weeklyCombinedStatisticsObserver.observe(weeklyCombinedStatisticsRef.current);
        if (invoicesRef.current !== null) invoicesObserver.observe(invoicesRef.current);
        return function cleanup() {
            if (mobileMode) return;
            financeObserver.disconnect();
            weeklyCombinedStatisticsObserver.disconnect();
            invoicesObserver.disconnect();
        };
    });

    useEffect(() => {
        let biggerHeight = Math.max(financesHeight, invoicesHeight);
        setHeightDiff(biggerHeight - invoicesHeight);
        setBiggerFinancesInvoicesHeight(biggerHeight);
    }, [invoicesHeight, financesHeight]);

    if (statsError) {
        return (
            <Typography style={{ padding: theme.spacing.unit * 2 }}>
                Unrecoverable error occurred. Cannot fetch collector stats.
            </Typography>
        );
    }

    if (collectorRatingLoading) {
        return <LinearProgress />;
    }

    return (
        <>
            <div style={{ padding: theme.spacing.unit }}>
                <CommodityPickupWidget
                    collector={collector}
                    commodities={commodities}
                    trackedCommodities={trackedCommodities}
                    reloadOperator={reloadOperator}
                    latestCommodityPickups={latestCommodityPickups}
                    history={history}
                    endDate={endDate}
                    openBulkCounts={openBulkCounts}
                    openBulkCountsLoading={openBulkCountsLoading}
                    reloadOpenBulkCounts={refreshOpenBulkCounts}
                    reloadLatestCommodityPickups={refreshLatestCommodityPickups}
                    collectorIdsSelected={collectorIdsSelected}
                    setCollectorIdsSelected={setCollectorIdsSelected}
                    allCollectorsBySkuType={allCollectorsBySkuType} // collectors with open bulks
                    setEndDate={setEndDate}
                />

                <MUIGrid container spacing={theme.spacing.unit * 2} style={{ margin: 0, width: '100%' }}>
                    <MUIGrid item xs={12}>
                        <div>
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: theme.spacing.unit,
                                    paddingBottom: theme.spacing.unit
                                }}
                            >
                                <DateIntervalCarousal
                                    startDate={startDatePicker}
                                    endDate={endDatePicker}
                                    handleGoBackwards={handleGoBackwards}
                                    handleGoForwards={handleGoForwards}
                                    theme={theme}
                                    dateInterval={'isoWeek'}
                                />
                            </Paper>
                        </div>
                    </MUIGrid>
                    <MUIGrid item xs={12}>
                        <div ref={weeklyCombinedStatisticsRef}>
                            <CombinedRatingWidget
                                countRating={collectorRating.countRating}
                                pickupRating={collectorRating.pickupRating}
                            />
                        </div>
                    </MUIGrid>
                    <MUIGrid item xs={12} md={4}>
                        <div>
                            <Paper style={{ paddingTop: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                                <Typography variant="h6" style={{ margin: theme.spacing.unit * 2, marginTop: 0 }}>
                                    Recent Compliments 
                                </Typography>
                                <Divider />
                                <ComplimentsWidget
                                    operator={operator}
                                    pickupsWithCompliments={collectorRating.pickupsWithCompliments}
                                    height={!mobileMode ? 200 : undefined}
                                />
                            </Paper>
                        </div>
                    </MUIGrid>
                    <MUIGrid item xs={12} md={4}>
                        <div>
                            <Paper style={{ paddingTop: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                                <Typography variant="h6" style={{ margin: theme.spacing.unit * 2, marginTop: 0 }}>
                                    Recent Complaints
                                </Typography>
                                <Divider />
                                <ComplaintsWidget
                                    operator={operator}
                                    history={history}
                                    pickupsWithIssues={collectorRating.pickupsWithIssues}
                                    bulksWithIssues={collectorRating.bulksWithIssues}
                                    systemComplaints={collectorRating.systemComplaints}
                                    height={!mobileMode ? 200 : undefined}
                                />
                            </Paper>
                        </div>
                    </MUIGrid>
                    <MUIGrid item xs={12} md={4}>
                        <div>
                            <Paper style={{ paddingTop: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                                <Typography variant="h6" style={{ margin: theme.spacing.unit * 2, marginTop: 0 }}>
                                    Driver Incidents
                                </Typography>
                                <Divider />
                                <DriverIncidentsWidget
                                    lateDriverTrips={lateDriverTrips.trips}
                                    loading={lateDriverLoading}
                                    height={!mobileMode ? 200 : undefined}
                                />
                            </Paper>
                        </div>
                    </MUIGrid>
                </MUIGrid>
                {/* 
                <CollectorInventoryWidget
                    collectors={[collector]}
                    commodities={commodities}
                    http={http}
                    theme={theme}
                    onSnackbar={onSnackbar}
                    reload={reloadOperator}
                /> */}
                {allDrivers.length > 0 && (
                    <Paper style={{ margin: theme.spacing.unit }}>
                        <Toolbar
                            style={{
                                paddingTop: 16,
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography variant="h6">Driver Summary</Typography>
                            <div style={{ flexDirection: 'row', display: 'flex', marginLeft: 16 }}>
                                <Hidden xsDown>
                                    <IconButton
                                        onClick={() => {
                                            let newDate = driverSummaryDate
                                                .clone()
                                                .subtract(1, 'day')
                                                .startOf('day');
                                            setDriverSummaryDate(newDate);
                                            let newStartOfWeek = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('isoWeek');
                                            let newEndOfWeek = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('isoWeek');
                                            if (!newStartOfWeek.isSame(startOfWeek)) {
                                                setStartOfWeek(newStartOfWeek);
                                            }
                                            if (!newEndOfWeek.isSame(endOfWeek)) {
                                                setEndOfWeek(newEndOfWeek);
                                            }
                                            let newStartOfMonth = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('month');
                                            let newEndOfMonth = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('month');
                                            if (!newStartOfMonth.isSame(startOfMonth)) {
                                                setStartOfMonth(newStartOfMonth);
                                            }
                                            if (!newEndOfMonth.isSame(endOfMonth)) {
                                                setEndOfMonth(newEndOfMonth);
                                            }
                                        }}
                                        disabled={false}
                                    >
                                        <Icon style={{ position: 'relative', left: 4 }}>arrow_back_ios</Icon>
                                    </IconButton>
                                </Hidden>
                                <div style={{ paddingTop: 5, display: 'flex', minWidth: 150 }}>
                                    <DatePicker
                                        autoOk
                                        data-cy="recurring-fee-dialog-start-date"
                                        disabled={false}
                                        disableFuture={true}
                                        fullWidth
                                        label=""
                                        format="ddd MMM Do"
                                        value={driverSummaryDate}
                                        invalidDateMessage={''}
                                        emptyLabel={''}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>date_range</Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={date => {
                                            setDriverSummaryDate(date);
                                            let newStartOfWeek = moment(date)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('isoWeek');
                                            let newEndOfWeek = moment(date)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('isoWeek');
                                            if (!newStartOfWeek.isSame(startOfWeek)) {
                                                setStartOfWeek(newStartOfWeek);
                                            }
                                            if (!newEndOfWeek.isSame(endOfWeek)) {
                                                setEndOfWeek(newEndOfWeek);
                                            }
                                            let newStartOfMonth = moment(date)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('month');
                                            let newEndOfMonth = moment(date)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('month');
                                            if (!newStartOfMonth.isSame(startOfMonth)) {
                                                setStartOfMonth(newStartOfMonth);
                                            }
                                            if (!newEndOfMonth.isSame(endOfMonth)) {
                                                setEndOfMonth(newEndOfMonth);
                                            }
                                        }}
                                    />
                                </div>
                                <Hidden xsDown>
                                    <IconButton
                                        onClick={() => {
                                            let newDate = driverSummaryDate
                                                .clone()
                                                .add(1, 'day')
                                                .startOf('day');
                                            setDriverSummaryDate(newDate);
                                            let newStartOfWeek = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('isoWeek');
                                            let newEndOfWeek = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('isoWeek');
                                            if (!newStartOfWeek.isSame(startOfWeek)) {
                                                setStartOfWeek(newStartOfWeek);
                                            }
                                            if (!newEndOfWeek.isSame(endOfWeek)) {
                                                setEndOfWeek(newEndOfWeek);
                                            }
                                            let newStartOfMonth = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .startOf('month');
                                            let newEndOfMonth = moment(newDate)
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .endOf('month');
                                            if (!newStartOfMonth.isSame(startOfMonth)) {
                                                setStartOfMonth(newStartOfMonth);
                                            }
                                            if (!newEndOfMonth.isSame(endOfMonth)) {
                                                setEndOfMonth(newEndOfMonth);
                                            }
                                        }}
                                        disabled={moment(new Date())
                                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                            .startOf('day')
                                            .isBefore(
                                                moment(driverSummaryDate)
                                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                    .add(1, 'day')
                                                    .startOf('day')
                                            )}
                                    >
                                        <Icon>arrow_forward_ios</Icon>
                                    </IconButton>
                                </Hidden>
                            </div>
                            <div style={{ marginTop: mobileMode ? 10 : undefined }}>
                                <Button
                                    variant={driverSummaryTab === 'daily' ? 'contained' : 'outlined'}
                                    style={{
                                        marginLeft: 10
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setDriverSummaryTab('daily');
                                    }}
                                    data-cy="daily_driver_summary"
                                >
                                    Daily
                                </Button>
                                <Button
                                    variant={driverSummaryTab === 'weekly' ? 'contained' : 'outlined'}
                                    style={{
                                        marginLeft: 10
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setDriverSummaryTab('weekly');
                                    }}
                                    data-cy="weekly_driver_summary"
                                >
                                    Weekly
                                </Button>
                                <Button
                                    variant={driverSummaryTab === 'monthly' ? 'contained' : 'outlined'}
                                    style={{
                                        marginLeft: 10
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setDriverSummaryTab('monthly');
                                    }}
                                >
                                    Monthly
                                </Button>
                            </div>
                            <div style={{ marginTop: mobileMode ? 10 : undefined, marginLeft: theme.spacing.unit * 2 }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginLeft: 10
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setDriverVacationDialogOpen(true);
                                    }}
                                    data-cy="daily_driver_summary"
                                >
                                    Submit Vacation
                                </Button>
                            </div>
                        </Toolbar>
                        {driversOnVacation.length > 0 && (
                            <div
                                style={{
                                    marginLeft: theme.spacing.unit * 3,
                                    marginRight: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                data-cy="driver-vaction-days-wrapper"
                            >
                                <Typography color="textSecondary" style={{ fontSize: '80%' }}>
                                    Vacation:{' '}
                                    {driversOnVacation.map(d => getCustomerFirstNameAndLastInitial(d)).join(', ')}
                                </Typography>
                            </div>
                        )}
                        {driverSummaryTab === 'daily' && (
                            <DriverSummaryTable
                                driverSummary={driverSummary}
                                allDrivers={allDrivers}
                                theme={theme}
                                lang={lang}
                                driverSummaryDate={driverSummaryDate}
                                setDriverSummaryDate={setDriverSummaryDate}
                                setStartOfWeek={setStartOfWeek}
                                setEndOfWeek={setEndOfWeek}
                                driverSummaryLoading={driverSummaryLoading}
                            />
                        )}
                        {driverSummaryTab === 'weekly' && (
                            <WeeklyDriverSummaryTable
                                theme={theme}
                                lang={lang}
                                allDrivers={allDrivers}
                                startOfWeek={startOfWeek}
                                endOfWeek={endOfWeek}
                                weeklyDriverSummaryLoading={weeklyDriverSummaryLoading}
                                weeklyDriverSummary={weeklyDriverSummary}
                            />
                        )}
                        {driverSummaryTab === 'monthly' && (
                            <WeeklyDriverSummaryTable
                                theme={theme}
                                lang={lang}
                                allDrivers={allDrivers}
                                startOfWeek={startOfMonth}
                                endOfWeek={endOfMonth}
                                weeklyDriverSummaryLoading={monthlyDriverSummaryLoading}
                                weeklyDriverSummary={monthlyDriverSummary}
                            />
                        )}
                    </Paper>
                )}
                <MUIGrid container spacing={theme.spacing.unit * 2} style={{ margin: 0, width: '100%' }}>
                    <MUIGrid item xs={12} md={6}>
                        <Paper
                            style={{
                                padding: theme.spacing.unit * 2,
                                'overflow-x': 'auto',
                                height: !mobileMode ? biggerFinancesInvoicesHeight : undefined
                            }}
                        >
                            <div ref={invoicesRef}>
                                {!invoicesError ? (
                                    <EnhancedTable
                                        title="Outstanding invoices"
                                        dataset={invoiceRows}
                                        headers={invoicesHeaders}
                                    />
                                ) : (
                                    <>
                                        <Toolbar justify="space-between">
                                            <Typography variant="h6">Outstanding invoices</Typography>
                                        </Toolbar>
                                        <Typography variant="body1" style={{ padding: theme.spacing.unit * 3 }}>
                                            Invoices were not loaded due to a server error. Please contact us directly
                                            for inquiries.
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </Paper>
                    </MUIGrid>
                    <MUIGrid item xs={12} md={6}>
                        <Paper
                            style={{
                                padding: theme.spacing.unit * 2,
                                height: !mobileMode ? biggerFinancesInvoicesHeight : undefined
                            }}
                        >
                            <div ref={financesRef}>
                                <Toolbar>
                                    <Typography variant="h6">Finances</Typography>
                                </Toolbar>
                                {statsLoading ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: theme.spacing.unit
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div data-cy="collector-dashboard-table">
                                        <TableGrid
                                            rows={rowsFinances}
                                            columns={[
                                                { title: 'Period (by date picked up)', name: 'period' },
                                                { title: 'Number of pickups', name: 'totalPickups' },
                                                { title: 'Number of containers', name: 'totalContainers' },
                                                { title: 'Container deposits', name: 'totalDeposits' },
                                                { title: 'Adjustments', name: 'totalAdjustments' },
                                                { title: 'Balance', name: 'balance' }
                                                // { title: 'Status', name: 'status' }
                                            ]}
                                        >
                                            <PagingState defaultPageSize={5} defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table rowComponent={TableRow} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </div>
                                )}
                            </div>
                        </Paper>
                    </MUIGrid>
                </MUIGrid>
            </div>

            {/* <Paper style={{ marginTop: theme.spacing.unit * 2 }}>
                <Toolbar>
                    <Typography variant="h6">Drivers</Typography>
                </Toolbar>
                <TableGrid
                    rows={rowsDrivers}
                    columns={[
                        { title: 'Name', name: 'name' },
                        { title: 'Counted pickups', name: 'pickupsCompleted' },
                        { title: 'Counted bags', name: 'bagsCompleted' },
                        { title: 'Counted containers', name: 'containersCompleted' },
                        { title: 'Total deposits', name: 'totalDeposits' },
                        { title: 'Total HFs', name: 'totalHFs' },
                        { title: 'Total time', name: 'totalTime' },
                        { title: 'Time/pickup', name: 'averageTimePerPickup' },
                        { title: 'HFs/hour', name: 'HFsPerHour' },
                        { title: 'Actions', name: 'actions' }
                    ]}
                >
                    <PagingState defaultPageSize={5} defaultCurrentPage={0} />
                    <IntegratedPaging />
                    <Table />
                    <TableHeaderRow />
                    <PagingPanel pageSizes={[5, 10, 25, 0]} />
                </TableGrid>
            </Paper> */}

            <InvoiceDialog
                open={invoiceDialogOpen}
                invoice={invoiceSelected}
                onClose={() => setInvoiceDialogOpen(false)}
            />
            <Dialog open={driverVacationDialogOpen} onClose={() => setDriverVacationDialogOpen(false)} fullWidth>
                <DialogTitle>Submit Vacation Days</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                        <InputLabel variant="outlined" htmlFor="receiver-filter">
                            Driver
                        </InputLabel>
                        <Select
                            data-cy="submit-vacation-dialog-driver-select"
                            value={driverVacationDialogDriverId}
                            onChange={e => setDriverVacationDialogDriverId(e.target.value)}
                            name="driverVacationDialogDriverId"
                            input={<OutlinedInput labelWidth={40} />}
                            style={{ minWidth: 132 }}
                        >
                            {allDrivers &&
                                allDrivers.length > 0 &&
                                allDrivers.map(d => {
                                    return (
                                        <MenuItem value={d._id} key={d._id}>
                                            {' '}
                                            {_.get(d, 'driverVacationDays', []).some(date =>
                                                moment(date).isSameOrAfter(moment(), 'date')
                                            ) && (
                                                <Tooltip title="This driver has upcoming vacation days">
                                                    <Icon
                                                        style={{
                                                            marginRight: theme.spacing.unit,
                                                            color: colors.grey[400]
                                                        }}
                                                    >
                                                        event_busy
                                                    </Icon>
                                                </Tooltip>
                                            )}
                                            {_.get(d, 'name.first', '')} {_.get(d, 'name.last', '')}{' '}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>

                    <DateListInput
                        values={driverVacationDialogDays.sort()}
                        onDatesChanged={dates => setDriverVacationDialogDays(dates)}
                        dateFormat="YYYY-MM-DD"
                        label="Edit Vacation Days"
                        disablePast={true}
                        renderDate={(date, onRemoveDate) => {
                            const timezone = _.get(operator, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
                            return (
                                <Chip
                                    key={moment(date)
                                        .tz(timezone)
                                        .format('MMM D, YYYY')}
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white',
                                        margin: theme.spacing.unit / 2
                                    }}
                                    label={moment(date)
                                        .tz(timezone)
                                        .format('MMM D, YYYY')}
                                    onDelete={() => onRemoveDate(date)}
                                />
                            );
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'right' }}>
                    <Button onClick={() => setDriverVacationDialogOpen(false)}>Close</Button>
                    <Button variant="contained" color="primary" onClick={() => submitVacationDays()}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(Dashboard);

function DriverSummaryTable(props) {
    const {
        driverSummary,
        allDrivers,
        theme,
        driverSummaryDate,
        setDriverSummaryDate,
        setStartOfWeek,
        setEndOfWeek,
        driverSummaryLoading,
        lang
    } = props;
    return (
        <table
            data-cy="operator-clerk-report-table-group-by-bulk"
            style={{
                display: 'block',
                'overflow-x': 'auto',
                'white-space': 'nowrap',
                paddingBottom: theme.spacing.unit
            }}
        >
            <tbody style={{ fontSize: theme.typography.caption.fontSize }}>
                <tr style={{}}>
                    <td
                        style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 9,
                            paddingBottom: 9
                        }}
                    >
                        <Typography>
                            {locDate(driverSummaryDate, 'MMM Do', lang, process.env.REACT_APP_REGION_TIMEZONE)}
                        </Typography>
                    </td>
                    {driverSummaryLoading ? (
                        <td
                            style={{
                                display: driverSummaryLoading ? '' : 'none',
                                paddingLeft: 10,
                                paddingTop: 5
                            }}
                        >
                            <CircularProgress size={22} thickness={4.8} style={{}} />
                        </td>
                    ) : (
                        allDrivers.map(driver => {
                            const driverName = _.get(driver, 'name.first', '') + ' ' + _.get(driver, 'name.last', '');
                            if (driverSummary[_.get(driver, '_id', '')] === undefined) {
                                return null;
                            }
                            return (
                                <td
                                    style={{
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        paddingTop: 9,
                                        paddingBottom: 9
                                    }}
                                >
                                    <Typography style={{ textAlign: 'center' }}>{driverName}</Typography>
                                </td>
                            );
                        })
                    )}
                </tr>
                <DriverSummaryRow
                    label={'Containers/Hour (Syn)'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Synthetic Containers/Hour')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Paid Hours'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Working Hours')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Collection Hours'}
                    data={getSummaryProps(driverSummary, allDrivers, 'collectionHours')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Excluded Hours'}
                    data={getSummaryProps(driverSummary, allDrivers, 'outOfSystemStopTime')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Lunch Hours'}
                    data={getSummaryProps(driverSummary, allDrivers, 'lunch')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Trips'}
                    data={getSummaryProps(driverSummary, allDrivers, 'trips')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Pickups'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Pickups')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Truck'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Truck')}
                    theme={theme}
                    style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}
                />
                <DriverSummaryRow
                    label={'Processor'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Processor')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Clock In'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Clock In')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Clock Out'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Clock Out')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Bags'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Bags')}
                    theme={theme}
                    hoverText={getSummaryProps(driverSummary, allDrivers, 'bagBreakdown')}
                />

                <DriverSummaryRow
                    label={'Clothing (lbs)'}
                    data={getSummaryProps(driverSummary, allDrivers, 'clothing')}
                    theme={theme}
                    warnings={getWarnings(driverSummary, allDrivers, 'clothingDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Electronics (Items)'}
                    data={getSummaryProps(driverSummary, allDrivers, 'electronics')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Containers'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Containers')}
                    theme={theme}
                    warnings={getWarnings(driverSummary, allDrivers, 'containersDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Containers/Hour'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Containers/Hour')}
                    theme={theme}
                    warnings={getWarnings(driverSummary, allDrivers, 'containersDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Containers (Syn)'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Synthetic Containers')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Infractions'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Infractions')}
                    theme={theme}
                    link={getSummaryProps(driverSummary, allDrivers, 'infractionsLink')}
                />
                <DriverSummaryRow
                    label={'Customer Complaints'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Customer Complaints')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Counter Complaints'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Counter Complaints')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'System Complaints'}
                    data={getSummaryProps(driverSummary, allDrivers, 'System Complaints')}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Compliments'}
                    data={getSummaryProps(driverSummary, allDrivers, 'Compliments')}
                    theme={theme}
                />
            </tbody>
        </table>
    );
}

function WeeklyDriverSummaryTable(props) {
    const { allDrivers, theme, startOfWeek, lang, endOfWeek, weeklyDriverSummaryLoading, weeklyDriverSummary } = props;
    let statHaveMoreThanOneDriver = Object.keys(weeklyDriverSummary).length > 2;
    return (
        <table
            data-cy="operator-clerk-report-table-group-by-bulk"
            style={{
                display: 'block',
                'overflow-x': 'auto',
                'white-space': 'nowrap',
                paddingBottom: theme.spacing.unit
            }}
        >
            <tbody style={{ fontSize: theme.typography.caption.fontSize }}>
                <tr>
                    <td
                        style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 9,
                            paddingBottom: 9
                        }}
                    >
                        <Typography>
                            {locDate(startOfWeek, 'MMM Do', lang, process.env.REACT_APP_REGION_TIMEZONE) +
                                ' to ' +
                                locDate(endOfWeek, 'MMM Do', lang, process.env.REACT_APP_REGION_TIMEZONE)}
                        </Typography>
                    </td>
                    {weeklyDriverSummaryLoading ? (
                        <td
                            style={{
                                display: weeklyDriverSummaryLoading ? '' : 'none',
                                paddingLeft: 10,
                                paddingTop: 5
                            }}
                        >
                            <CircularProgress size={22} thickness={4.8} style={{}} />
                        </td>
                    ) : (
                        <>
                            {allDrivers.map(driver => {
                                const driverName =
                                    _.get(driver, 'name.first', '') + ' ' + _.get(driver, 'name.last', '');
                                if (weeklyDriverSummary[_.get(driver, '_id', '')] === undefined) {
                                    return null;
                                }
                                return (
                                    <td
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            paddingTop: 9,
                                            paddingBottom: 9
                                        }}
                                    >
                                        <Typography style={{ textAlign: 'center' }}>{driverName}</Typography>
                                    </td>
                                );
                            })}
                            {statHaveMoreThanOneDriver && (
                                <>
                                    <td
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            paddingTop: 9,
                                            paddingBottom: 9
                                        }}
                                    >
                                        <Typography style={{ textAlign: 'center' }}>{'Avg'}</Typography>
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            paddingTop: 9,
                                            paddingBottom: 9
                                        }}
                                    >
                                        <Typography style={{ textAlign: 'center' }}>{'Total'}</Typography>
                                    </td>
                                </>
                            )}
                        </>
                    )}
                </tr>
                <DriverSummaryRow
                    label={'Containers/Hour (Syn)'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'Synthetic Containers/Hour',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Paid Hours'} // -> rename to paid hours?
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Working Hours', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Collection Hours'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'collectionHours',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Excluded Hours'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'outOfSystemStopTime',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Lunch Hours'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'lunch', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Trips'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'trips', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Pickups'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Pickups', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Bags'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Bags', statHaveMoreThanOneDriver)}
                    theme={theme}
                    hoverText={getSummaryProps(weeklyDriverSummary, allDrivers, 'bagBreakdown')}
                />

                <DriverSummaryRow
                    label={'Clothing (lbs)'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'clothing', statHaveMoreThanOneDriver)}
                    theme={theme}
                    warnings={getWarnings(weeklyDriverSummary, allDrivers, 'clothingDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Electronics (Items)'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'electronics', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Containers'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Containers', statHaveMoreThanOneDriver)}
                    theme={theme}
                    warnings={getWarnings(weeklyDriverSummary, allDrivers, 'containersDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Containers/Hour'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'Containers/Hour',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                    warnings={getWarnings(weeklyDriverSummary, allDrivers, 'containersDefaultAmountWarning')}
                />
                <DriverSummaryRow
                    label={'Containers (Syn)'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'Synthetic Containers',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Infractions'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Infractions', statHaveMoreThanOneDriver)}
                    theme={theme}
                    link={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'infractionsLink',
                        statHaveMoreThanOneDriver
                    )}
                />
                <DriverSummaryRow
                    label={'Customer Complaints'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'Customer Complaints',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Counter Complaints'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'Counter Complaints',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'System Complaints'}
                    data={getSummaryProps(
                        weeklyDriverSummary,
                        allDrivers,
                        'System Complaints',
                        statHaveMoreThanOneDriver
                    )}
                    theme={theme}
                />
                <DriverSummaryRow
                    label={'Compliments'}
                    data={getSummaryProps(weeklyDriverSummary, allDrivers, 'Compliments', statHaveMoreThanOneDriver)}
                    theme={theme}
                />
            </tbody>
        </table>
    );
}

const TableRow = ({ row, ...rest }) => (
    <Table.Row {...rest} style={{ backgroundColor: row.current ? colors.grey[200] : 'initial' }} />
);

function DriverSummaryRow(props) {
    const { label, data, theme, style, warnings, hoverText, link } = props;

    const operator = useContext(OperatorContext);
    const handleViewLink = link => {
        if (deviceHelper.isNativeApp()) {
            props.history.push(`/operators/${operator._id}/${link}`);
        } else {
            window.open(`/operators/${operator._id}/${link}`, '_blank');
        }
    };

    const linkStyle = {
        cursor: 'pointer',
        textDecoration: 'underline',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    };

    return (
        <tr>
            <td
                style={{
                    paddingLeft: 15,
                    paddingTop: 9,
                    paddingBottom: 9,
                    borderTop: `2px solid ${theme.palette.grey[200]}`
                }}
            >
                <Typography>{label}</Typography>
            </td>
            {data.map(({ cellData, data_cy }, idx) => {
                let toolTipTitle;
                if (!_.isNil(hoverText) && hoverText.length > idx) {
                    toolTipTitle = _.get(hoverText[idx], 'cellData');
                }
                return (
                    <td
                        style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 9,
                            paddingBottom: 9,
                            borderTop: `2px solid ${theme.palette.grey[200]}`
                        }}
                    >
                        {label === 'Truck' ? (
                            <Tooltip title={cellData}>
                                <Typography
                                    style={
                                        _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                            ? { ...style, textAlign: 'center' }
                                            : { ...style, textAlign: 'center', ...linkStyle }
                                    }
                                    data-cy={data_cy}
                                    onClick={
                                        _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                            ? () => {}
                                            : () => handleViewLink(_.get(link, `[${idx}].cellData`))
                                    }
                                >
                                    {!isNaN(Number(cellData)) ? numberWithCommas(Number(cellData)) : cellData}
                                </Typography>
                            </Tooltip>
                        ) : _.isNil(toolTipTitle) ? (
                            <Typography
                                style={
                                    _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                        ? { ...style, textAlign: 'center' }
                                        : { ...style, textAlign: 'center', ...linkStyle }
                                }
                                data-cy={data_cy}
                                onClick={
                                    _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                        ? () => {}
                                        : () => handleViewLink(_.get(link, `[${idx}].cellData`))
                                }
                            >
                                {!isNaN(Number(cellData)) ? numberWithCommas(Number(cellData)) : cellData}{' '}
                                {!_.isNil(warnings) && warnings[idx] && (
                                    <Tooltip title="Contains estimates from bag volume">
                                        <Icon style={{ color: 'red', fontSize: '12px' }}>error_outline</Icon>
                                    </Tooltip>
                                )}
                            </Typography>
                        ) : (
                            <Tooltip title={toolTipTitle}>
                                <Typography
                                    style={
                                        _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                            ? { ...style, textAlign: 'center' }
                                            : { ...style, textAlign: 'center', ...linkStyle }
                                    }
                                    data-cy={data_cy}
                                    onClick={
                                        _.isNil(link) || _.isNil(_.get(link, `[${idx}].cellData`))
                                            ? () => {}
                                            : () => handleViewLink(_.get(link, `[${idx}].cellData`))
                                    }
                                >
                                    {!isNaN(Number(cellData)) ? numberWithCommas(Number(cellData)) : cellData}{' '}
                                    {!_.isNil(warnings) && warnings[idx] && (
                                        <Tooltip title="Contains estimates from bag volume">
                                            <Icon style={{ color: 'red', fontSize: '12px' }}>error_outline</Icon>
                                        </Tooltip>
                                    )}
                                </Typography>
                            </Tooltip>
                        )}
                    </td>
                );
            })}
        </tr>
    );
}

function getWarnings(driverSummary, allDrivers, warningName) {
    let data = [];
    allDrivers.forEach(driver => {
        let driverId = _.get(driver, '_id', '');
        if (driverSummary[driverId] !== undefined) {
            data.push(_.get(driverSummary, `${driverId}.${warningName}`, false));
        }
    });
    return data;
}

function getSummaryProps(driverSummary, allDrivers, propName, includeAvgTotal) {
    let data = [];
    allDrivers.forEach(driver => {
        let driverId = _.get(driver, '_id', '');
        if (driverSummary[driverId] !== undefined) {
            data.push({
                cellData: driverSummary[driverId][propName],
                data_cy: `${propName}-${driver.name.first}-${driver.name.last}`
            });
        }
    });
    if (includeAvgTotal) {
        data.push({ cellData: _.get(driverSummary, `Avg.${propName}`, ''), data_cy: `${propName}-Avg` });
        data.push({ cellData: _.get(driverSummary, `Total.${propName}`, ''), data_cy: `${propName}-Total` });
    }
    return data;
}
function numberWithCommas(x) {
    if (_.isNil(x)) return '';
    return x.toLocaleString();
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}
